import React, { useState } from "react";
import { ToastNotify } from "../../../helpers/toastNotify";
import { useApiRequest } from "../../useApiRequest";
import { useAppDispatch } from "../../useReduxHook";
import {
  setLoadingState,
  setCustomerCardsForActivation,
} from "../../../react-wrappers/stores/redux/cards/slices";
import { GET_CHECK_ACCOUNT_AVAILABILITY } from "utils/apis/routes";

export const useCheckAccountAvailability = () => {
  const makeRequest = useApiRequest();
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<boolean>(false);
  const [isAvailable, setIsAvailable] = useState<boolean>(false);

  const handleCheckAccountAvailability = async (payload: {
    accountNumber: string;
    schemeCode: string;
  }) => {
    dispatch(setLoadingState(["checking-account-availability"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_CHECK_ACCOUNT_AVAILABILITY(payload),
        originType: "identity",
      });
      // console.log("response", response);
      if (response?.data?.httpStatusCode === 200) {
        setStatus(true);
        setIsAvailable(response?.data?.data?.isAvailable);
      } else {
        setIsAvailable(false);
        setStatus(false);
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };
  return {
    handleCheckAccountAvailability,
    status,
    isAvailable,
  };
};
