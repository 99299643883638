import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { Button, Card, Label } from "flowbite-react";
import React from "react";
import { getCurrencyFormat } from "utils/helperFunctions";

// import { ReactComponent as BankIcon } from "../../../../../assets/svgs/bank-building.svg";
// import CardBackgroundImage from "../../../../../assets/svgs/card-background.svg";

interface INewRecipientComponent3Props {
  recipients: any;
  accountToDebit: any;
  accounts: any;
  groupName?: string;
  setSumTotal: any;
  nipCharges: any;
  handleChange: any;
  setAccountToDebit: any;
  handleScreenChangePrev: any;
  handleScreenChangeNext: any;
  totalIsGreaterThanBalance: boolean;
}
const NewRecipientComponent3 = ({
  recipients,
  groupName,
  accountToDebit,
  accounts,
  setSumTotal,
  nipCharges,
  handleChange,
  setAccountToDebit,
  handleScreenChangePrev,
  handleScreenChangeNext,
  totalIsGreaterThanBalance,
}: INewRecipientComponent3Props) => {
  const activeAccounts = accounts.filter(
    (account: any) => account.accountStatus === "Active"
  );

  let sumTotal: number = recipients
    .map((o: any) =>
      typeof o.amount === "number" ? o.amount : o.amount.replace(/,/g, "")
    ) //Remove the commas
    .reduce((a: any, c: any) => {
      return parseFloat(a) + parseFloat(c);
    });

  setSumTotal(sumTotal);
  const getNonWemaAccounts = recipients.filter(
    (oneAccount: any) => oneAccount.bankCode !== "035"
  );

  // const charges = getNonWemaAccounts.map(oneAccount =>{
  //   oneAccount.amount
  // })
  // let amounts = [];
  const allAmounts = getNonWemaAccounts.map(
    (account: any) => account.amount.withNumberFormat
  );

  let toBePaid: any[] = [];
  const totalAmounts = allAmounts.forEach((amount: any) => {
    if (amount < 5001) {
      let charges = nipCharges
        .filter((oneCharge: any) => oneCharge.lower < 5001)
        .map((theCharge: any) => theCharge.charge);
      toBePaid.push(charges[0]);
    }
    if (amount > 5000 && amount < 50001) {
      let charges = nipCharges
        .filter(
          (oneCharge: any) => oneCharge.lower > 5000 && oneCharge.lower < 50001
        )
        .map((theCharge: any) => theCharge.charge);

      toBePaid.push(charges[0]);
    }
    if (amount >= 50001) {
      let charges = nipCharges
        .filter((oneCharge: any) => oneCharge.lower >= 50001)
        .map((theCharge: any) => theCharge.charge);

      toBePaid.push(charges[0]);
    }
  });

  const totalCharge = toBePaid.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  const totalTransactionFee = recipients
    .reduce((acc: any, curr: any) => {
      if (curr.transactionFee && curr.transactionFee !== undefined)
        return acc + curr.transactionFee;
      return acc;
    }, 0)
    .toFixed(2);

  const getOption = (type: string, accounts: any) => {
    return accounts
      ?.map((account: any, index: number) => {
        const availableBalance = getCurrencyFormat(account);
        if (account?.currency?.toLowerCase() === "usd") return;

        return {
          label: `
        ${account?.accountName} (${account.accountNo}) - 
        ${new Intl.NumberFormat("ja-JP", {
          style: "currency",
          currency: availableBalance.currency,
        })
          .format(Number(account.balance))
          .replace(
            availableBalance.currency,
            availableBalance?.unicodeCharacter || availableBalance?.currency
          )
          .trim()}
        `,
          value: account.accountNo,
          id: account.accountNo,
          raw: JSON.stringify(account),
        };
      })
      .filter((x: any) => x != undefined);
  };

  return (
    <div className="w-full flex items-center justify-center">
      <ReusableCard
        cardTitle="Multiple transfer"
        variant="md:w-[48rem] w-full"
        handleBackNavigation={handleScreenChangePrev}
        showGoBackIcon
      >
        <div className="w-full flex items-center justify-center">
          <form className="flex flex-col w-full gap-4 justify-center items-center mt-4">
            <div className="text-[#666666]">
              Complete transaction details below
            </div>
            <div className="w-full">
              <div className="text-[#666666] mb-2 text-sm font-normal mx-auto md:w-[400px] w-full">
                Transfer to
              </div>

              <Card className="box-border md:w-[400px] mx-auto w-full h-[114px] rounded-lg text-white bg-[image:url('./assets/svgs/card-background.svg')] bg-no-repeat bg-cover">
                <div className="flex flex-row justify-between items-center mt-4">
                  <div>
                    <div className="text-xs">Group</div>
                    {groupName && <div className="text-base">{groupName}</div>}
                    {!groupName && <div className="text-base">New Group</div>}
                  </div>
                  <div>
                    <div className="text-xs text-right">
                      Number of recipients
                    </div>
                    <div
                      className="text-base text-right"
                      id="num-of-recipients_text"
                    >
                      {recipients.length}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center mb-4">
                  <div>
                    <div className="text-xs">Total amount</div>
                    <div className="text-base" id="total-amount_text">
                      ₦{sumTotal.toLocaleString()}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-right">Charges</div>
                    <div className="text-base text-right">
                      <span>₦</span>
                      <span id="total-charges_text">{totalTransactionFee}</span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="w-full">
              <div className="relative lg:max-w-sm md:w-[390px] mx-auto w-full pt-3">
                <SelectDrop
                  id="accounttodebit_dropdown"
                  idList="accounttodebit"
                  variant="h-12 w-full text-sm"
                  name="accountNumberDebit"
                  label="Select account to debit"
                  placeholder="Select account to debit"
                  value={accountToDebit.accountNo}
                  options={getOption("debit", activeAccounts)}
                  onChange={(name: string, value: any) =>
                    setAccountToDebit(
                      JSON.parse(value?.raw),
                      totalTransactionFee
                    )
                  }
                  hasError={!totalIsGreaterThanBalance}
                  error={
                    accountToDebit.accountNo !== "" && totalIsGreaterThanBalance
                      ? "insufficient balance"
                      : ""
                  }
                />
              </div>
              <div className="flex flex-row justify-center items-center gap-32 mt-10">
                <Button
                  id="continue_btn"
                  type="button"
                  className="bg-primary hover:bg-transparent hover:text-primary hover:border-primary lg:max-w-sm mb-20 cursor-pointer w-[188px]  hover:bg-primary focus:ring-1 focus:ring-primary dark:bg-primary dark:hover:bg-primary dark:hover:text-white dark:focus:ring-primary dark:disabled:hover:bg-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                  onClick={handleScreenChangeNext}
                  disabled={
                    accountToDebit.accountNo === "" || totalIsGreaterThanBalance
                  }
                >
                  Continue
                </Button>
              </div>
            </div>
          </form>
        </div>
      </ReusableCard>
      {/* <Card className=" bg-white md:w-[48rem] mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <div
              id="back_btn"
              onClick={handleScreenChangePrev}
              className="cursor-pointer"
            >
              <ChevronLeftIcon className="h-6 w-6 text-[#333333]" />
            </div>
            <div className="text-[#4D4D4D] text-xl text-center  font-semibold">
              Multiple transfer
            </div>
            <div></div>
          </div>
        </div>
        <hr />
      </Card> */}
    </div>
  );
};

// {
//   showScreen === "main" ? (
//     <div>Main</div>
//   ) : showScreen == "successful" ? (
//     <div>Successful</div>
//   ) : showScreen == "failed" ? (
//     <div>Failed</div>
//   ) : (
//     <div>Pending</div>
//   );
// }
export default NewRecipientComponent3;
