import { ToastNotify } from "helpers/toastNotify";
import { useApiRequest } from "hooks/useApiRequest";
import { useAppDispatch } from "hooks/useReduxHook";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  setLoadingState,
  setRemitaWithRRR,
  useRemitaState,
} from "react-wrappers/stores/redux/remita/slices";
import { POST_VALIDATE_RRR } from "utils/apis/routes/remita";

export const useValidateWithRRR = () => {
  const makeRequest = useApiRequest();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [responseStatus, setResponseStatus] = useState({});
  const remita = useRemitaState();

  const handleValidateRRR = async (payload: any) => {
    dispatch(setLoadingState(["validate-rrr"]));
    try {
      const response = await makeRequest({
        method: "post",
        route: POST_VALIDATE_RRR,
        body: payload,
        originType: "paymentservice",
      });
      if (response?.data?.status === 200) {
        dispatch(setRemitaWithRRR(response?.data?.data));
        setResponseStatus(response?.data);
      } else {
        dispatch(setRemitaWithRRR({}));
        ToastNotify({
          type: "error",
          message: `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      navigate("/remita/payment");
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  return {
    handleValidateRRR,
    responseStatus,
  };
};
