import { useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card, Label } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { FormikButton, TextInput } from "alat-components-library";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import { paybillValidationSchema } from "utils/validations/payment";
import { usePayBillController } from "controllers/Payments/Paybill";
import {
  useCollectionsState,
  setValidationReference,
  setSummaryFields,
} from "react-wrappers/stores/redux/collections/slices";
import PageLoader from "components/Loader/PageLoader";
import { useGetCollections } from "hooks/apis/Collections/useGetCollections";
import { CollectionType } from "utils/enums/collections";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useAppDispatch } from "hooks/useReduxHook";

const CollectionSimpleForm = () => {
  // Local State
  const { simpleFormItem, activeSelectedCollectionMerchantItem, loading } =
    useCollectionsState();
  const { handlePostValidation, postValidation } = useGetCollections();
  const { pacakgeList, activeSelectedSubBill } = usePayBillController();
  const { user } = useAuthState();
  const [formToRender, setFormToRender] = useState(
    simpleFormItem?.simpleItemFields || []
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    user: { emailAddress },
  } = useAuthState();

  const initialValues: any = {};
  formToRender?.forEach((item: any) => {
    initialValues[item?.fieldName] = "";
  });

  useEffect(() => {
    setFormToRender(simpleFormItem?.simpleItemFields);
  }, [simpleFormItem]);

  useEffect(() => {
    if (postValidation?.isValid) {
      const summaryFields: any = [
        {
          fieldName: "Account Name",
          fieldValue: activeSelectedCollectionMerchantItem?.collectionName,
        },
        { fieldName: "Profile Name", fieldValue: user?.userName },
        {
          fieldName: "Biller",
          fieldValue: activeSelectedCollectionMerchantItem?.itemName,
        },
      ];
      dispatch(setSummaryFields(summaryFields));
      navigate(`/bills/collections/payment`);
    }
  }, [postValidation]);

  const handleSubmit = (payload: any) => {
    let validationItems = simpleFormItem?.simpleItemFields?.map((item: any) => {
      return { ...item, inputValue: payload[item.fieldName] };
    });
    dispatch(setValidationReference(simpleFormItem?.validationReference));
    const data = {
      endpointId: 0,
      collectionTypeId: CollectionType["Simple"],
      currentUserEmail: emailAddress,
      validationReference: simpleFormItem?.validationReference,
      fields: validationItems,
    };
    handlePostValidation(data);
  };

  const isLoading =
    loading.includes("fetch-simple-form") ||
    loading.includes("post-form-validation");

  return (
    <div className="w-full">
      {!!isLoading && (
        <PageLoader message={`Validating details, please wait...`} />
      )}
      <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              onClick={() => navigate(-1)}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            />
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="banktransfer_heading"
            >
              Collection Form Step 1
            </div>
            <div></div>
          </div>
        </div>
        <hr />
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          // validationSchema={{}}
          validateOnChange
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form>
              <div>
                <div className="flex flex-wrap justify-center gap-4 ">
                  <div className="text-alat-grey relative w-full lg:max-w-sm mb-2">
                    {formToRender &&
                      formToRender?.map((f: any) => (
                        <div
                          key={f?.fieldName}
                          className="relative w-full lg:max-w-sm mb-2"
                        >
                          <div className="mb-2 block text-left text-[#666666]">
                            <Label
                              value={`${f?.fieldName}`}
                              className="!text-[#464646] font-normal text-lg leading-[1rem]"
                            />
                          </div>
                          <div className="relative w-full lg:max-w-sm">
                            <input
                              id={`${f?.fieldName}`}
                              name={`${f?.fieldName}`}
                              type={"text"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values[f?.fieldName] ?? ""}
                              className="pl-6 w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm"
                            />

                            {touched[f?.fieldName] && errors[f?.fieldName] && (
                              <span className="text-sm text-red-600">
                                {errors[f?.fieldName]?.toString()}
                              </span>
                            )}
                            {""}
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="relative w-full  flex items-center justify-center">
                    <FormikButton
                      id="proceed"
                      labelText="Proceed"
                      containerVariant={
                        "w-full lg:max-w-sm h-[7vh] my-[5vh] rounded-md flex justify-center items-center"
                      }
                      isDisabled={!isValid && !dirty}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default CollectionSimpleForm;
