export const POST_CREATE_BUSINESS: string = `Verification/save`;
export const POST_BUSINESS_CAC_VERIFICATION: string = `Verification/business`;
export const POST_BVN_VERIFICATION: string = `Verification/bvn`;
export const POST_UNREGISTERED_BIZ_BVN_VERIFICATION: string = `Verification/validatebvnunregisteredbusiness`;
export const POST_EXISTING_USER_BVN_VERIFICATION: string = `verification/validateexistingbusinessbvn`;
export const POST_ACCOUNT_VERIFICATION: string = `Verification/verifyexistingbusiness`;
//Create Profile
export const POST_VERIFY_USER_DATA: string = `validation/customer-info`;
export const POST_CREATE_PROFILE: string = `onboarding/profile-customer`;
export const POST_CREATE_EXISTING_CUSTOMER_PROFILE: string = `onboarding/profile-existing-customer`;
export const POST_TIN_VERIFICATION: string = `Verification/tin`;
export const POST_SAVE_BUSINESS: string = `Verification/savebusiness`;
export const POST_BUSINESS_SETUP: string = `Verification/businesssetup`;
export const POST_BUSINESS_TRACKING_ID = (trackingId: string) =>
  `Verification/trackregistration?trackingId=${trackingId}`;

// Coming soon
export const POST_COMING_SOON: string = `Partnership/interestedpartnerships`;
// Validate card
export const POST_VALIDATE_CARD: string = `AlatPay/initiatecard`;
export const POST_VALIDATE_CARD_UNREG_BIZ: string = `AlatPay/unregisteredbusinessinitiatecard`;
export const POST_PAY_WITH_CARD: string = `AlatPay/processcard`;
export const POST_PAY_WITH_CARD_UNREG_BIZ: string = `AlatPay/unregisteredbusinessprocesscard`;

//Face Validation
// export const GET_FACEMATCH_STEP = (bvn: string) =>
//     `Registration/step?uniqueIdentifier=${bvn}`;
export const GET_FACEMATCH_STEP = (bvn: string) =>
  `Registration/step/identifier/${bvn}/imei/${"web"}`;
export const POST_FACEMATCH_USER_REG: string = "WebReg/user";
export const POST_FACEMATCH_SELFIE_SUBMIT: string = "WebReg/selfie";
export const POST_FACEMATCH_SELFIE_CHECK: string = "WebReg/evaluate/bvn";
export const POST_FACEMATCH_AUTHENTICATION: string =
  "Identity/authenticate/web";
// Face Validation
export const POST_FACEMATCH_VERIFICATION: string = `Verification/facematch`;
// Business Categories
export const GET_BUSINESS_CATEGORIES: string = `Business/categories`;
// Address
export const GET_ADDRESS: string = `Location`;

//Virtual Account
export const POST_VIRTUAL_ACCOUNT_REQUEST: string = `AlatPay/virtualaccount`;
export const POST_VIRTUAL_ACCOUNT_REQUEST_UNREG_BIZ: string = `AlatPay/unregisteredbusinessvirtualaccount`;

export const GET_CUSTOMER_PAID_STATUS = (
  virtualAccountNumber: string,
  correlationId: string,
  type: string
) =>
  `AlatPay/customerpaid?virtualAccountNumber=${virtualAccountNumber}&correlationId=${correlationId}&paymentType=${type === "card" ? 1 : 0}`;

// Get Referee Information
export const GET_COMPANY_INFO_BY_ID = (correlationId: string) =>
  `Business/companyInfoById/${correlationId}`;
export const GET_NONWEMA_REFEREE_INFO = (id: string) =>
  `Business/companyreferee/otherbank/${id}`;
export const GET_WEMA_REFEREE_INFO = (id: string) =>
  `Business/companyreferee/${id}`;
export const GET_ALL_REFEREES_INFO = (correlationId: string) =>
  `/Verification/getrefereedetails?correlationId=${correlationId}`;

// Verify Referee Information
export const POST_SAVE_WEMA_REFEREE = `Verification/savewemareferee`;
export const POST_SAVE_NONWEMA_REFEREE = `Verification/savereferee`;
export const POST_ACCEPT_NONWEMA_REFEREE = `Verification/acceptotherbankreferee`;
export const POST_ACCEPT_WEMA_REFEREE = `Verification/acceptwemareferee`;
export const POST_RESEND_REFEREE_EMAIL = `Verification/resendrefereeemail`;
export const POST_CHANGE_REFEREE = `Verification/changereferee`;
export const POST_VERIFY_REFEREE_ACCOUNT = (accountNumber: string) =>
  `Verification/account/${accountNumber}`;
export const POST_REJECT_REFEREE = (correlationId: string, id: string) =>
  `Verification/rejectreferee?correlationId=${correlationId}&id=${id}`;

//Get EReferee
export const GET_E_REFEREE_INFO = (requestId: string) =>
  `EReference/get-ereferencereferee-info/${requestId}`;
export const POST_REJECT_E_REFEREE = (
  eReferenceId: string,
  refereeId: string
) =>
  `EReference/reject-referee?eReferenceId=${eReferenceId}&refereeId=${refereeId}`;
export const POST_ACCEPT_E_NONWEMA_REFEREE = `EReference/accept-otherbank-referee`;
export const POST_ACCEPT_E_WEMA_REFEREE = `EReference/accept-wema-referee`;

// Countries
export const GET_LOCATIONS = `Location`;

// Get Company Info Via Email
export const GET_COMPANY_INFO_VIA_EMAIL = (email: string) =>
  `Business/companyInfo/${email}`;
