/* eslint-disable react-hooks/exhaustive-deps */
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { Form, Formik } from "formik";
import PendingActionsWrapper from "pages/PendingActions/RejectedDocuments/components/PendingActionsWrapper";
import FileUpload2 from "../../../Onboarding/Components/FileUpload";
import { signatureSchema } from "pages/PendingActions/RejectedDocuments/validation/pendingActionsValidation";
import { useLocation, useNavigate } from "react-router-dom";
import { DocumentTypeMetaData } from "utils/enums";
import { FormikButton } from "alat-components-library";
import { useEffect } from "react";
import { useFetchRejectedDocuments } from "hooks/apis/pendingActions/useFetchRejectedDocuments";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useResubmitRejectedDocuments } from "hooks/apis/pendingActions/useResubmitRejectedDocuments";
import PageLoader from "components/Loader/PageLoader";

// Checks the state before destructuring occurs
const SignatureUpload = () => {
  const location = useLocation();
  if (!location?.state?.pendingData) {
    window.location.replace("/user-dashboard");
    return <div></div>;
  }
  return <SignatureUploadInner />;
};

const SignatureUploadInner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    fetchLoading: submitLoading,
    resubmittedResponse,
    handleResubmittedDocuments,
  } = useResubmitRejectedDocuments();

  const {
    reason,
    cacRegNumber,
    pendingActionType,
    actionData,
    pendingActionId,
  } = location?.state?.pendingData;

  const {
    user: { regNumber, businessType },
  } = useAuthState();

  const previousSubmission = actionData;
  const prevSignatureUrl: string = previousSubmission;

  const handleSubmit = (values: any) => {
    const payload = values?.signatureUrl;
    const payloadString = JSON.stringify(payload);
    try {
      handleResubmittedDocuments({
        pendingAction: pendingActionType,
        pendingActionId,
        cacRegNumber,
        actionData: payloadString,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (resubmittedResponse) {
      navigate("/pending-actions/successful-resubmission", {
        state: { title: "Upload Signature" },
      });
    }
  }, [resubmittedResponse]);

  return (
    <DashboardWrapper>
      {submitLoading && <PageLoader message="Submitting Signature" />}
      <PendingActionsWrapper cardTitle="Upload Signature">
        <div className="w-full max-w-[700px] mx-auto">
          <div className=" md:w-full h-full">
            <div className="w-full px-10 mx-auto">
              <div className="bg-[#A90836]/10 text-lib-alat-dark-red p-2 rounded-md text-sm my-2">
                {reason?.[0]}
              </div>
              {/* {}children */}
              <Formik
                initialValues={{ signatureUrl: prevSignatureUrl || "" }}
                onSubmit={handleSubmit}
                validateOnBlur
                validationSchema={signatureSchema}
              >
                {({
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                  isValid,
                  touched,
                  dirty,
                  resetForm,
                  setFormikState,
                }) => (
                  <Form className="w-full flex flex-col gap-4 mb-5">
                    <FileUpload2
                      id="id-front-file_input"
                      label="Upload ID Card (Front)"
                      name="frontImageLink"
                      handleBlur={handleBlur}
                      error={touched.signatureUrl ? errors?.signatureUrl : ""}
                      DocumentType={`${DocumentTypeMetaData.signature}`}
                      DocumentName={`${DocumentTypeMetaData.signature}`}
                      CacRegNumber={cacRegNumber}
                      defaultValue={values?.signatureUrl}
                    />
                    <div>
                      <FormikButton
                        id="proceed_btn"
                        labelText="Proceed"
                        containerVariant="w-full h-10 rounded-md flex justify-center"
                        isDisabled={!isValid}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </PendingActionsWrapper>
    </DashboardWrapper>
  );
};

export default SignatureUpload;
