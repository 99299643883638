import React from "react";
import HomeWrapper from "./components/HomeWrapper";
import TermsBG from "../../assets/svgs/terms-bg.svg";
import { ReactComponent as HeroImg } from "../../assets/svgs/accept-terms-illustration.svg";
import TermsAccordion from "./components/TermsAccordion";
import { TermsAndConditionsText } from "./components/TermsComponents/TermsAndConditionsText";
const TermsAndConditions = () => {
  const scrollDown = () => {
    if (window.pageYOffset >= 0) {
      const yOffset = 400;
      const y = window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <HomeWrapper>
      <div className="-mt-14">
        <div
          className="bg-white relative py-5"
          style={{
            background: `url(${TermsBG})`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: "100%",
          }}
        >
          <div className="flex h-full">
            <div className="flex-col justify-center px-[8vw] z-[100] pt-96 md:py-56 hidden md:flex">
              <p className="text-[4.5vw] font-bold">Internet Banking</p>
              <p className="text-[4.5vw] font-bold">Terms & Conditions</p>
              <p className="text-[1.4vw] w-3/4">
                Read our terms below to learn more about your rights and
                responsibilities as an AFB customer
              </p>
              <p
                className="cursor-pointer py-10 px-10 md:block hidden"
                onClick={scrollDown}
              >
                Scroll down
              </p>
            </div>
            <div className="absolute md:right-1 md:w-[50%]">
              <HeroImg className="w-full h-full hidden md:block" />
            </div>
          </div>

          <div className="block md:hidden">
            <HeroImg className="w-full h-full block md:hidden" />
            <div className="text-center text-3xl font-bold -mt-2 px-5">
              Internet Banking Terms & Conditions
            </div>
            <p className="text-center mt-2">
              Read our terms below to learn more about your rights and
              responsibilities as an AFB customer
            </p>

            <p
              className="my-16 text-center cursor-pointer"
              onClick={() => scrollDown()}
            >
              Scroll down
            </p>
          </div>

          <div className="py-[2vw] pb-20">
            <TermsAccordion text="Internet Banking Terms & Conditions">
              <TermsAndConditionsText />
            </TermsAccordion>

            <TermsAccordion text="Privacy Policy">
              <div className="flex flex-col gap-3 py-4 pb-10 px-4">
                <p>
                  Please note that we have the utmost respect for your privacy
                  and we will always make sure the information you share with us
                  is secure as our Privacy Policy is in compliance with the Data
                  Protection Regulations issued recently to increase the
                  security of personal data.
                </p>
                <p>
                  We will use your data to send you account updates and offers.
                  It will never be sold and will not be shared with a third
                  party without your consent, except where the law compels.
                </p>
              </div>
            </TermsAccordion>
          </div>
        </div>
      </div>
    </HomeWrapper>
  );
};

export default TermsAndConditions;
