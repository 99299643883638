import BankTransferMain from "components/Payments/BankTransfer/BankTransferMain";
import { TabsRef, Tabs, Flowbite } from "flowbite-react";
import React, { useRef, useState } from "react";

const FundsTransferModalTab = ({ active, children }: any) => {
  const [activeTab, setActiveTab] = useState<number>(active);
  const tabsRef = useRef<TabsRef>(null);

  const theme = {
    tabs: {
      tablist: {
        base: "flex text-center gap-2 z-0",
        tabitem: {
          base: "flex text-center",
          variant: {
            underline: {
              base: "rounded-t-lg",
              active: {
                on: "active py-2 rounded-t-lg border-b-2 border-lib-alat-red text-lib-alat-red font-medium",
                off: "py-2 border-b-2 border-transparent text-lib-alat-black font-medium",
              },
            },
          },
        },
      },
    },
  };

  return (
    <div className="">
      <div className="w-full">
        <Flowbite theme={{ theme }}>
          <Tabs
            aria-label="Default tabs"
            // eslint-disable-next-line react/style-prop-object
            variant="underline"
            ref={tabsRef}
            onActiveTabChange={(tab: React.SetStateAction<number>) =>
              setActiveTab(tab)
            }
            className=" !border-[#E4DFDF] w-[1044px]"
          >
            <Tabs.Item
              active={activeTab === 0}
              title="Bank Transfer"
              className="mr-80"
            >
              <BankTransferMain />
            </Tabs.Item>
            <Tabs.Item
              active={activeTab === 1}
              title="Fund My Account"
              className="mr-80 !bg-purple-800"
            >
              <div className="ml-48 ">{children}</div>
            </Tabs.Item>
            <Tabs.Item active={activeTab === 2} title="Bulk Transfer">
              Bulk Transfer Screen
            </Tabs.Item>
          </Tabs>
        </Flowbite>
      </div>
      <div className="w-[245px]"></div>
    </div>
  );
};

export default FundsTransferModalTab;
