import {
  PasswordInput,
  FormikButton,
  TextInput,
} from "../../../alat-components-library";
import { Formik, Form } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LoginValidation } from "utils/validations/auth";
import { useLoginController } from "controllers/Login/LoginController";

export const LoginForm = () => {
  const navigate = useNavigate();
  const { initialValueData, handleFormSubmit } = useLoginController();
  return (
    <Formik
      initialValues={initialValueData}
      validateSchema={LoginValidation}
      onSubmit={handleFormSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        dirty,
        isValid,
      }) => (
        <Form>
          <div>
            <p className="text-[2vh] mt-[2vh] mt-[4vh]">Username</p>
            <TextInput
              id="username-input"
              name={"username"}
              placeHolder={""}
              containerVariant={"py-[1vh]"}
              variant={"border text-black w-full h-[7vh] text-[2vh] px-[3vh]"}
              handleChange={handleChange}
              handleBlur={handleBlur}
              value={values.username}
              error={errors.username}
            />
          </div>
          <div>
            <p className="text-[2vh] mt-[1vh]">Password</p>
            <PasswordInput
              id="password_input"
              // ref={passwordRef}
              name={"password"}
              idHidePwd="hidepassword_btn"
              placeHolder={""}
              containerVariant={"py-[1vh]"}
              variant={"border text-black w-full h-[7vh] text-[2vh] px-[3vh]"}
              handleChange={handleChange}
              handleBlur={handleBlur}
              value={values.password}
              error={errors.password}
              iconStyle={{
                wrapper: "bottom-[1.5vh] right-[2vh]",
                icon: "w-[3.5vh] h-[3.5vh]",
              }}
            />
          </div>
          <p
            className="text-right underline text-lib-alat-red text-[2vh] mt-[1vh] cursor-pointer"
            // onClick={() => navigate("/forgot-password/enter-username")}
            onClick={() => navigate("/forgot-password")}
            id="forgotpassword_btn"
          >
            Forgot Username or Password?
          </p>
          <FormikButton
            id="signin_btn"
            labelText={"Log in"}
            containerVariant={
              "w-full h-[7vh] my-[5vh] rounded-md flex justify-center items-center"
            }
            // isDisabled={!isValid || !dirty}
            isDisabled={false}
          />
        </Form>
      )}
    </Formik>
  );
};
