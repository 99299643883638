import React from "react";
import { ReactComponent as CancelIcon } from "../../../alat-components-library/assets/images/icons/cancel_icon.svg";
import { TextArea } from "components/Inputs/TextArea";
import { PrimaryButton } from "alat-components-library";
import { useNavigate } from "react-router-dom";

interface RejectionModalProps {
  handleRejectionRemark?: any;
  remark?: string;
  wordCount?: string;
  onClose?: any;
  handleConfirmRejection?: any;
  remarkError?: boolean;
  errorMessage?: string;
  fieldName?: string;
}
export const RejectionModal = ({
  handleRejectionRemark,
  remark,
  wordCount,
  handleConfirmRejection,
  onClose,
  errorMessage,
  fieldName,
  remarkError = false,
}: RejectionModalProps) => {
  const navigate = useNavigate();
  return (
    <div className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black/80 p-5 z-2000 overflow-hidden backdrop-filter backdrop-blur-sm">
      <div className="h-auto md:w-[768px] w-full bg-white fixed border z-1000 rounded-md py-12">
        <div className="flex flex-col justify-center items-center w-full px-6">
          <div
            id="close_btn"
            onClick={onClose}
            className="cursor-pointer flex justify-end w-full"
          >
            <CancelIcon />
          </div>
          <div className="px-10 w-full flex flex-col items-center justify-center">
            <div className="w-full flex">
              <TextArea
                rows={7}
                cols={7}
                handleChange={handleRejectionRemark}
                value={remark}
                hasError={remarkError}
                error={errorMessage}
                wordLength={wordCount}
                name={fieldName}
              />
            </div>

            <div className=" w-full flex gap-5 mt-5 py-5 ">
              <PrimaryButton
                id="returnToDashboard_btn"
                labelText={"Return to dashboard"}
                variant="font-medium text-xs"
                containerVariant="w-full rounded-md flex justify-center border border-lib-alat-red cursor-pointer bg-white hover:bg-[#f2f5fc]"
                handleClick={() => navigate("/user-dashboard")}
              />
              <PrimaryButton
                id="confirmRejection_btn"
                labelText={"Confirm Rejection"}
                handleClick={handleConfirmRejection}
                isDisabled={remarkError}
                variant={"text-white font-medium text-xs"}
                containerVariant={`w-full h-10 rounded-md flex justify-center cursor-pointer ${remarkError ? "bg-[#A9083680]" : "bg-lib-alat-red"}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
