// import { ChevronLeftIcon } from '@heroicons/react/24/solid';
// import { Card, Label } from 'flowbite-react';
// import {useNavigate } from 'react-router-dom';
// import { Formik, Form } from 'formik';
// import { FormikButton } from 'alat-components-library';
// import { StateDropdown } from 'components/Dashboard/components/StateDropdown';
// import { useCorporateDebitCardController } from 'controllers/Cards/CorporateDebitCardController';
// import { SelectDrop } from 'components/Dashboard/components/SelectDrop';
// import { getOption } from 'utils/helperFunctions';
import { useEffect, useState } from "react";
import FirstScreen from "./FirstScreen";
import { useCardState } from "react-wrappers/stores/redux/cards/slices";
import SecondScreen from "./SecondScreen";
import ThirdScreen from "./ThirdScreen";
import SuccessScreen from "./SuccessScreen";

const CorporateDebitCard = () => {
  const { activeCardRequestScreen } = useCardState();
  const [screen, setScreen] = useState(0);

  return (
    <div className="w-full h-full">
      {activeCardRequestScreen === 0 && <FirstScreen />}
      {activeCardRequestScreen === 1 && <SecondScreen />}
      {activeCardRequestScreen === 2 && <ThirdScreen />}
      {activeCardRequestScreen === 3 && (
        <SuccessScreen pageName={"Card Request"} setScreen={setScreen} />
      )}
    </div>
  );
};

export default CorporateDebitCard;
