import { Avatar, Sidebar } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { Logo } from "../../alat-components-library";
// import { AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useNotificationState } from "react-wrappers/stores/redux/notifications/slices";
import NotificationBell from "../../assets/svgs/notification-bell-icon.svg";
import User from "../../assets/svgs/profile-avatar.svg";
import { truncateString } from "../../helpers/assitFunctions";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHook";
import { profileDropDownLinks } from "../../pages/Dashboard/dropdownItems";
import { triggerLogout } from "../../react-wrappers/stores/redux/auth/assit-functions";
import {
  setAuthReset,
  useAuthState,
} from "../../react-wrappers/stores/redux/auth/slices";
import { resetProfileData } from "../../react-wrappers/stores/redux/profile/slices";
import {
  setShowMobileSidebar,
  useAppState,
} from "react-wrappers/stores/redux/app/slices";
// export interface HeaderProps {
//   title: string;
// }
const Header = ({ handleLogout }: any) => {
  const [open, setOpen] = useState(false);
  // const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { showMobileSidebar } = useAppState();
  const profileRef = useRef<HTMLDivElement>(null);
  const fullName = useAppSelector((state) => state.onboarding?.fullName);
  const { username } = useAppSelector((state) => state.onboarding);
  const handleLogoClick = () =>
    user?.role.toLowerCase() === "admin"
      ? navigate("/admin-dashboard")
      : navigate("/user-dashboard");
  const notifications = useNotificationState();

  const handleClickOutside = (event: MouseEvent) => {
    if (
      profileRef.current &&
      !profileRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        ref={profileRef}
        className="text-left bg-[#333333] text-2xl h-[80px] w-full md:px-20 px-3 flex justify-between items-center"
      >
        <div
          className="md:hidden w-8 h-6 flex flex-col justify-between cursor-pointer"
          onClick={() => dispatch(setShowMobileSidebar(!showMobileSidebar))}
        >
          <div
            className={`border-b-4 w-full rounded-full  ${showMobileSidebar ? "rotate-45 translate-y-3" : ""}`}
          ></div>
          <div
            className={`border-b-4 rounded-full ${showMobileSidebar ? "rounded-full w-0" : "w-full"}`}
          ></div>
          <div
            className={`border-b-4 w-full rounded-full  ${showMobileSidebar ? "-rotate-45 -translate-y-2" : ""}`}
          ></div>
        </div>
        <div className="flex justify-start items-center">
          <div className="cursor-pointer" onClick={handleLogoClick}>
            <Logo
              type={"afb-transparent"}
              containerVariant={""}
              variant={"w-[24vh] h-[12vh]"}
            />
          </div>
          <div className="relative border-l border-[#A6A6A6]"></div>
        </div>
        <div className="flex justify-between items-center space-x-6">
          <button
            type="button"
            onClick={() => navigate("/notifications")}
            className="relative inline-flex items-center p-1 text-sm font-medium text-center text-white"
          >
            <img
              src={NotificationBell}
              className=" cursor-pointer"
              alt="NotificationBell"
            />

            <span className="sr-only">Notifications</span>
            {notifications?.notificationsCount > 0 ? (
              <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 rounded-md -top-1 -end-2 dark:border-gray-900">
                {notifications?.notificationsCount < 9
                  ? notifications?.notificationsCount
                  : "9+"}
              </div>
            ) : (
              ""
            )}
          </button>

          <div className="relative">
            <div
              className="flex justify-start items-center space-x-2 cursor-pointer"
              id="avatar_btn"
              onClick={() => setOpen(!open)}
            >
              <Avatar
                // img="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                img={(props) => <img src={User} alt="user-pic" />}
                rounded={true}
                bordered={true}
                color="gray"
              />
              <div className="hidden md:block">
                <p className="text-sm text-white">
                  Hi, &nbsp;
                  <span id="username_text">{user?.userName || username}</span>
                </p>
                <p className="text-sm text-white" id="fullname_text">
                  {user?.fullName
                    ? truncateString(user?.fullName, 20)
                    : truncateString(fullName, 20)}
                </p>
              </div>
            </div>
            <div
              id="profiledropdown"
              className={`bg-white w-[168px] right-0 h-auto top-[60px] absolute z-10 rounded-b-md shadow-lg shadow-black-500/50 ${
                open ? "block" : "hidden"
              }`}
            >
              <ul>
                <li className="flex flex-col justify-start p-4 gap-1 ">
                  <p
                    className="text-xs font-semibold tracking-tighter text-lib-alat-black"
                    id="fullname_text"
                  >
                    {user?.fullName || fullName}
                  </p>
                  <span className="text-xs text-[#606060]">
                    Hi, &nbsp;{" "}
                    <span id="username_text">{user?.userName || username}</span>
                  </span>
                </li>
                <li className="bg-[#E4DFDF] h-[0.01rem] mx-2"></li>
                {profileDropDownLinks?.map((item: any) => (
                  <li
                    key={item.id}
                    id={item.id}
                    onClick={
                      item.logout
                        ? () => {
                            triggerLogout();
                            dispatch(setAuthReset());
                            dispatch(resetProfileData());
                          }
                        : () => navigate(item.link)
                    }
                    className="text-xs flex space-x-2 items-center hover:bg-afb-gray-one cursor-pointer p-4"
                  >
                    {item.icon}
                    <p>{item.name}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
