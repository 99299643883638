import { ToastNotify } from "../../../../helpers/toastNotify";
import { removeLoadingState } from "../../../../helpers/assitFunctions";
import axiosStatus from "utils/apis/axios/errorStatus";
// import { ToastNotify } from "../../../../helpers/toastNotify";
// import { OnboardingState } from "../interfaces";

export const triggerTransactionValidatePending = (state: any) => {
  state.loading = ["POST_TRANSACTION_VALIDATE"];
  state.isLoading = true;
};
export const triggerTransactionValidateSuccess = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_TRANSACTION_VALIDATE"
  );
  state.isLoading = false;
  state.isSuccess = true;
  state.response = payload;
};

export const triggerTransactionValidateFailed = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_TRANSACTION_VALIDATE"
  );
  state.isLoading = false;
  state.isSuccess = false;
  state.response = payload;
};

export const triggerGetTransactionStatusPending = (state: any) => {
  state.loading = ["GET_TRANSACTION_STATUS"];
  state.isLoading = true;
};
export const triggerGetTransactionStatusSuccess = (
  state: any,
  payload: any
) => {
  state.loading = removeLoadingState(state.loading, "GET_TRANSACTION_STATUS");
  state.isLoading = false;
  state.isSuccess = true;
  state.response = payload;
};

export const triggerGetTransactionStatusFailed = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "GET_TRANSACTION_STATUS");
  state.isLoading = false;
  state.isSuccess = false;
  state.response = payload;
};

export const triggerGetNameEquiryPending = (state: any) => {
  state.loading = ["GET_ACCOUNT_NAME_ENQUIRY"];
  state.isLoading = true;
};
export const triggerGetNameEquirySuccess = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "GET_ACCOUNT_NAME_ENQUIRY");
  state.isLoading = false;
  state.isSuccess = true;
  state.response = payload;
};

export const triggerGetNameEquiryFailed = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "GET_ACCOUNT_NAME_ENQUIRY");
  state.isLoading = false;
  state.isSuccess = false;
  state.response = payload;
};
export const triggerGetBeneficiaryListPending = (state: any) => {
  state.loading = ["GET_BENEFICIARY_LIST"];
  state.isLoading = true;
};
export const triggerGetBeneficiaryListSuccess = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "GET_BENEFICIARY_LIST");
  state.isLoading = false;
  state.isSuccess = true;
  state.response = payload;
};

export const triggerGetBeneficiaryListFailed = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "GET_BENEFICIARY_LIST");
  state.isLoading = false;
  state.isSuccess = false;
  state.response = payload;
};

export const triggerGetGroupListPending = (state: any) => {
  state.loading = ["GET_GROUP_LIST"];
  state.isLoading = true;
};

export const triggerGetGroupListSuccess = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "GET_GROUP_LIST");
  state.isLoading = false;
  state.isSuccess = true;
  state.response = payload;
};

export const triggerGetGroupListFailed = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "GET_GROUP_LIST");
  state.isLoading = false;
  state.isSuccess = false;
  state.response = payload;
};

export const triggerTransactionList = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_FETCH_TRANSACTION_LIST"
  );
  //Prevent no success status
  if (payload.status !== 200) return;

  if (payload?.data?.hasError) {
    ToastNotify({
      type: "error",
      message: `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  } else {
    state.pendingTransactionList = {
      ...state.pendingTransactionList,
      sortedData: payload?.data?.data?.pendingAuthorizationRequests,
      hasNextPage: payload?.data?.data?.hasNextPage,
      pageSize: payload?.data?.data?.pageSize,
      currentPage: payload?.data?.data?.pageNumber,
      count: payload?.data?.data?.count,
      totalItems: payload?.data?.data?.totalItemCount,
    };
  }
};

export const triggerTransactionDetail = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_FETCH_TRANSACTION_DETAIL"
  );
  //Prevent no success status
  if (payload.status !== 200) return;

  if (payload?.data?.hasError) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }

  state.pendingTransactionDetail = payload?.data?.data;
};

export const triggerCollectionDetail = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_FETCH_COLLECTIONS_DETAIL"
  );
  //Prevent no success status
  if (payload.status !== 200) return;

  if (payload?.data?.hasError) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }

  state.pendingCollectionsDetail = payload?.data?.data;
};
export const triggerBillsPaymentDetail = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_FETCH_BILLS_PAYMENT_DETAIL"
  );
  //Prevent no success status
  if (payload.status !== 200) return;

  if (payload?.data?.hasError) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }
  state.pendingBillPaymentDetail = payload?.data?.data;
};
export const triggerUserRequestDetails = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "GET_PENDING_USERS_REQUEST_DETAILS"
  );
  //Prevent no success status
  // if (payload.status !== 200) return;

  if (payload?.data?.hasError) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }
  state.pendingTransactionDetail = {
    ...state.pendingTransactionDetail,
    userRequest: payload?.data?.data,
  };
  // state.pendingTransactionDetail.userRequest = payload?.data?.data;
};
export const triggerSoftTokenRequestDetails = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS"
  );
  //Prevent no success status
  if (payload.status !== 200) return;

  if (payload?.data?.hasError) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }
  state.pendingTransactionDetail = {
    ...state.pendingTransactionDetail,
    tokenRequest: payload?.data?.data,
  };
  // state.pendingTransactionDetail.tokenRequest = payload?.data?.data;
};

export const triggerAcceptAndRejectTransaction = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "PUT_APROVE_OR_REJECT_TRANSACTION"
  );

  // console.log("payload", payload);
  //Prevent no success status
  // if (payload.status !== 200) return;

  if (payload?.data?.data?.hasError) {
    state.approvePendingTransactionsOrActions = {
      ...state.approvePendingTransactionsOrActions,
      successStatus: false,
    };
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  } else {
    state.approvePendingTransactionsOrActions = {
      ...state.approvePendingTransactionsOrActions,
      successStatus: true,
      successMessage: payload?.data?.responseMessage,
    };
    // state.approvePendingTransactionsOrActions.successStatus = true;
    // state.approvePendingTransactionsOrActions.successMessage = `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`;
  }
};
export const triggerReviewUserRequest = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "POST_REVIEW_USER_REQUEST");
  // console.log("payload", payload);
  // //Prevent no success status
  // if (payload.status !== 200) return;

  if (payload?.data?.data?.hasError) {
    state.reviewUserRequest = {
      ...state.reviewUserRequest,
      successStatus: false,
    };
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  } else {
    state.reviewUserRequest = {
      ...state.reviewUserRequest,
      successStatus: true,
      successMessage: payload?.data?.responseMessage,
    };
    // state.reviewUserRequest.successStatus = true;
    // state.reviewUserRequest.successMessage = `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`;
  }
};

export const triggerPostTransferTransaction = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_TRANSFER_TRANSACTION"
  );

  //Prevent no success status
  if (payload.status !== 200) return;

  if (payload?.data?.hasError) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }
};

export const triggerPutCompleteTransferTransaction = (
  state: any,
  payload: any
) => {
  state.loading = removeLoadingState(
    state.loading,
    "PUT_COMPLETE_TRANSFER_TRANSACTION"
  );

  //Prevent no success status
  if (payload.status !== 200) return;

  if (payload?.data?.hasError) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }
};

export const triggerOtpResend = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "GET_RESEND_TRANSFER_OTP");

  //Prevent no success status
  if (payload.status !== 200) return;

  if (payload?.data?.hasError) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }
};

export const triggerPostFetchAllBulkTransaction = (
  state: any,
  payload: any
) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_FETCH_ALL_BULK_TRANSFER"
  );

  //Prevent no success status
  if (payload.status !== 200) return;

  if (payload?.data?.hasError) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }
};

export const triggerPostFetchOneBulkTransaction = (
  state: any,
  payload: any
) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_FETCH_ONE_BULK_TRANSFER"
  );

  //Prevent no success status
  if (payload.status !== 200) return;

  if (payload?.data?.hasError) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }
};

export const triggerPostCreateAccountStatement = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_CREATE_ACCOUNT_STATEMENT"
  );

  // //Prevent no success status
  // if (payload.status !== 200) return;
  // console.log("payload", payload);

  if (payload?.data?.data?.hasError) {
    if (payload?.status === 401) return axiosStatus(payload);
    return ToastNotify({
      type: "error",
      message:
        payload?.status === 500
          ? "Oops! Something went wrong"
          : `${payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }
};

export const triggerBillCategoriesList = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "GET_BILL_CATEGORIES_LIST");
  //Prevent no success status
  // if (payload.status !== 200) return;

  if (payload?.data?.hasError) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.responseMessage?.title}. ${payload?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }
  state.billCategoryList = payload?.data?.data;
};

export const triggerValidateBillReference = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_VALIDATE_BILL_REFERENCE"
  );
};

export const triggerCreateBill = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "POST_CREATE_BILL");

  if (payload.status !== 200) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }
};
