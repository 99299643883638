import React from "react";
import Preloader from "../../Mics/Preloader";
import { PrimaryButtonProps } from "../types";

export const PrimaryButton = ({
  id = "",
  containerVariant = "w-32 h-10 rounded-md flex justify-center cursor-pointer bg-lib-alat-red shadow-md",
  icon,
  isDisabled = false,
  isLoading = false,
  labelText = "Submit",
  handleClick,
  variant = "font-medium text-sm",
}: PrimaryButtonProps) => {
  return (
    <div
      className={`${containerVariant} ${
        isDisabled && "bg-[#A9083680] text-[#E0B8C4] cursor-not-allowed"
      }`}
      onClick={(e: any) => {
        if (isDisabled) return false;
        return handleClick && handleClick(e);
      }}
    >
      <button
        type="button"
        className={`${variant} ${
          isDisabled && " text-[#E0B8C4]  cursor-not-allowed"
        }`}
        disabled={isDisabled}
        id={id}
      >
        {icon?.active && (
          <div className={`${icon.variant}`}>{icon.preview}</div>
        )}
        {isLoading ? <Preloader variant="w-6 h-6" /> : labelText}
      </button>
    </div>
  );
};
