import { useEffect, useRef, useState } from "react";
import {
  setCenteredModal,
  useAppState,
} from "../../react-wrappers/stores/redux/app/slices";
import {
  setCenterCoords,
  setDataForFirstTimeLogin,
  useAuthState,
} from "../../react-wrappers/stores/redux/auth/slices";
import { useAppDispatch } from "../../hooks/useReduxHook";
import { LoginType } from "../../types/auth";
import { POST_LOGIN_ACTION } from "../../react-wrappers/stores/redux/auth/actions";
import { resetOnboardingData } from "../../react-wrappers/stores/redux/onboarding/slices";
import { resetTransactionsData } from "../../react-wrappers/stores/redux/transactions/slices";
import { ToastNotify } from "helpers/toastNotify";

export const useLoginController = () => {
  // Redux State
  const appState = useAppState();
  const authState = useAuthState();
  const dispatch = useAppDispatch();
  const [coordinates, setCoordinates] = useState<{ lat: number; long: number }>(
    {
      lat: 0,
      long: 0,
    }
  );

  // Variable
  const initialValueData = {
    username: "",
    password: "",
  };

  // Local State
  // const [isActive, setIsActive] = useState<string | null>(null);
  const [triggers, setTriggers] = useState({
    title: "centeredModal",
    action: false,
  });

  // Ref
  // const passwordRef = useRef<any>();
  // console.log("test", passwordRef);

  useEffect(() => {
    // window.addEventListener("click", () => {
    //     if(passwordRef?.current !== document.activeElement) return setIsActive(null)
    // });
    dispatch(resetOnboardingData());
    dispatch(resetTransactionsData());
  }, []);

  useEffect(() => {
    // Check if geolocation is supported by the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.log("pos", position)
          setCoordinates({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
          dispatch(
            setCenterCoords({
              lat: position.coords.latitude,
              long: position.coords.longitude,
            })
          );
        },
        (error) => {
          console.error(error.message);
        }
      );
    } else {
      ToastNotify({
        type: "error",
        message: "Geolocation is not supported by your browser.",
      });
    }
  }, []);

  // Assit Functions
  // const trackFocus = (value: string) => (passwordRef?.current === document.activeElement) && setIsActive(value)

  //Handler Functions
  const handleFormSubmit = async (values: LoginType) => {
    if (values.username === "" || values.password === "") {
      return dispatch(
        setCenteredModal({
          status: "error",
          title: "Warning",
          info: "Username and password cannot be empty",
        })
      );
    }
    const payload: LoginType = {
      username: values.username,
      password: values.password,
      geoLatitude: coordinates?.lat?.toString(),
      geoLongitude: coordinates?.long?.toString(),
    };
    dispatch(
      setDataForFirstTimeLogin({
        username: values.username,
        password: values.password,
      })
    );
    dispatch(POST_LOGIN_ACTION(payload));
  };

  // const handleModalClick = (e: any) => {

  // }

  return {
    appState,
    authState,
    triggers,
    // isActive,
    // passwordRef,
    initialValueData,
    dispatch,
    // trackFocus,
    setTriggers,
    handleFormSubmit,
    // handleModalClick
  };
};
