import React from "react";

export interface PageHeaderProps {
  title: string;
}
const PageHeader = ({ title }: PageHeaderProps) => (
  <div className="text-left text-[#333333] font-bold text-2xl">{title}</div>
);

export default PageHeader;
