import React, { ReactNode, useEffect, useState } from "react";
import { BusinessDetailsContainer } from "../BusinessDetails";
import AlatPay from "../../../assets/svgs/alatpay-logo.svg";
import CenteredModal from "../../../alat-components-library/Modal/CenterModal";
import PendingIcon from "../../../assets/gifs/refresh-modal.gif";
import { ReactComponent as SuccessIcon } from "../../../assets/svgs/success-icon.svg";
import { useVirtualAccount } from "../../../hooks/apis/Onboarding/useVirtualAccount";
import { useAppDispatch, useAppSelector } from "../../../hooks/useReduxHook";
import {
  saveOnboardingData,
  saveUnregisteredBizData,
} from "../../../react-wrappers/stores/redux/onboarding/slices";
import { usePaymentStatus } from "../../../hooks/apis/Onboarding/usePaymentStatus";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useLoginController } from "controllers/Login/LoginController";
import BankTransfer from "./BankTransfer";
import CardPayment from "./CardPayment";
import { usePayWithCard } from "hooks/apis/Onboarding/usePayWithCard";
import ProccedCardPayment from "./ProccedCardPayment";
import { formatNumberToCurrency } from "../../../utils/helperFunctions";
import { useCardValidation } from "hooks/apis/Onboarding/useCardValidation";
import { useLocation } from "react-router-dom";

interface Props {
  dashboardModal?: boolean;
}

export const CheckButton = ({
  text,
  icon,
  isActive,
  onChange,
}: {
  text: string;
  icon: ReactNode;
  isActive: boolean;
  onChange: () => void;
}) => {
  return (
    <label className="block cursor-pointer relative basis-[180px] md:basis-[202px] grow">
      <input
        type="radio"
        onChange={onChange}
        name="payment"
        className="opacity-0 absolute -left-[2000px]"
      />
      <div
        className={`flex items-center gap-4 rounded-[5px] ${isActive ? "border-[#9D0F3E] bg-[#E5DADA]" : "border-[#808080]"} border p-4`}
      >
        {icon}
        <p
          className={`text-xs ${isActive ? "text-[#9D0F3E]" : "text-[#808080]"}`}
        >
          {text}
        </p>
      </div>
    </label>
  );
};
export const CardIcon = ({ bg = "#808080" }: { bg?: string }) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM18 8H2V16H18V8ZM18 6V2H2V6H18ZM12 12H16V14H12V12Z"
      fill={bg}
    />
  </svg>
);

export const TransferIcon = ({ bg = "#808080" }: { bg?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18H20V20H0V18ZM2 10H4V17H2V10ZM7 10H9V17H7V10ZM11 10H13V17H11V10ZM16 10H18V17H16V10ZM0 5L10 0L20 5V9H0V5ZM2 6.236V7H18V6.236L10 2.236L2 6.236ZM10 6C9.73478 6 9.48043 5.89464 9.29289 5.70711C9.10536 5.51957 9 5.26522 9 5C9 4.73478 9.10536 4.48043 9.29289 4.29289C9.48043 4.10536 9.73478 4 10 4C10.2652 4 10.5196 4.10536 10.7071 4.29289C10.8946 4.48043 11 4.73478 11 5C11 5.26522 10.8946 5.51957 10.7071 5.70711C10.5196 5.89464 10.2652 6 10 6Z"
      fill={bg}
    />
  </svg>
);
const PaymentContainer: React.FC<Props> = ({ dashboardModal = false }) => {
  const { handleFormSubmit } = useLoginController();
  const [successModal, setSuccessModal] = useState(false);
  const [showMastarcardModal, setShowMastercardModal] = useState(false);
  const [pendingModal, setPendingModal] = useState(false);
  const [accountGenerated, setAccountGenerated] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const [active, setActive] = useState("card");

  const onboardingState = useAppSelector((state) => state.onboarding);
  const reg_number = useAppSelector(
    (state) =>
      state.onboarding.businessPayload?.infoRequest?.businessRequest?.reg_number
  );

  const location = useLocation();
  // This is payment for unregistered business
  const paymentAmount = location.state?.amount;
  const unRegBizCorrelationId = location.state?.correlationId;

  const dispatch = useAppDispatch();
  const { user } = useAuthState();

  const {
    userVirtualAccountError,
    userVirtualAccountData,
    handleVirtualAccount,
  } = useVirtualAccount();

  const { userPaymentStatusData, handlePaymentStatus } = usePaymentStatus();
  const {
    userPayWithCardStatus,
    userPayWithCardLoading,
    userPayWithCardData,
    handlePayWithCard,
  } = usePayWithCard();

  // Tracking ID after payment
  const trackingId = userPaymentStatusData?.data?.data?.trackingId;
  const embedLink = userPaymentStatusData?.data?.data?.redirectUrl;
  const handleEmbedLink = () => {
    window.open(embedLink, "_blank");
  };
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(
      onboardingState?.unRegisteredBusiness?.paymentAccount ||
        onboardingState?.paymentAccount
    );
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  // Initiate card payment
  const {
    handleCardValidation,
    userCardValidationLoading,
    userCardValidationData,
  } = useCardValidation();

  useEffect(() => {
    if (
      !unRegBizCorrelationId &&
      !userVirtualAccountData?.data?.data?.virtualAccount &&
      !accountGenerated
    ) {
      handleVirtualAccount({
        businessRegistrationRequest: {
          reg_number:
            user?.regNumber || reg_number.replace(/[a-zA-Z]/g, "") || "",
          businessType: user?.businessType || 0,
        },
        accountType: parseInt(user?.accountType || onboardingState.accountType),
        correlationId: onboardingState.correlationId,
      });
    }
    if (
      unRegBizCorrelationId &&
      !userVirtualAccountData?.data?.data?.virtualAccount &&
      !accountGenerated
    ) {
      handleVirtualAccount({
        amount: paymentAmount,
        correlationId: unRegBizCorrelationId,
      });
    }
    if (!paymentAmount && !userVirtualAccountError) {
      setAccountGenerated(true);
      dispatch(
        saveOnboardingData({
          ...onboardingState,
          paymentAccount: userVirtualAccountData?.data?.data?.virtualAccount,
          paymentAmount: userVirtualAccountData?.data?.data?.amount,
        })
      );
    }
    if (paymentAmount && !userVirtualAccountError) {
      setAccountGenerated(true);
      dispatch(
        saveUnregisteredBizData({
          ...onboardingState.unRegisteredBusiness,
          paymentAccount: userVirtualAccountData?.data?.data?.virtualAccount,
        })
      );
    }
    // eslint-disable-next-line
  }, [userVirtualAccountData, userVirtualAccountError, paymentAmount]);

  // Validate Payment
  const validatePayment = () => {
    let transactionId;
    if (active === "card") {
      transactionId = userCardValidationData as string;
    } else if (paymentAmount) {
      transactionId = onboardingState?.unRegisteredBusiness?.paymentAccount;
    } else {
      transactionId = onboardingState?.paymentAccount;
    }

    handlePaymentStatus(
      transactionId,
      unRegBizCorrelationId || onboardingState?.correlationId,
      active
    );

    if (userPaymentStatusData?.data?.data?.paid) {
      setResetTimer(true);
      setPendingModal(false);
      setSuccessModal(true);
    } else {
      setSuccessModal(false);
      setPendingModal(true);
    }
  };

  const payWithardResponse = userPayWithCardData?.data?.data;
  useEffect(() => {
    if (payWithardResponse) {
      setShowMastercardModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payWithardResponse]);

  const amount = formatNumberToCurrency({
    number: paymentAmount ?? onboardingState?.paymentAmount ?? 0,
  });
  // Close modal and validate payment
  const closeMasterCardModal = () => {
    // Validate card payment
    validatePayment();
    setShowMastercardModal(false);
  };

  return (
    <>
      {
        <BusinessDetailsContainer containerStyle="mx-auto xs:w-full sm:w-2/3 lg:w-4/5 md:w-3/4 py-4 relative min-h-max">
          {/* master Card payment modal  */}
          {showMastarcardModal && (
            <ProccedCardPayment
              handleClose={closeMasterCardModal}
              resData={payWithardResponse}
            />
          )}
          {!showMastarcardModal && (
            <div className="flex flex-wrap md:gap-6 gap-3 p-4 md:p-6 bg-[#F9F9F9] rounded-[10px]">
              <aside className="grow basis-[180px] min-[1336px]:max-w-[202px]">
                <header>
                  <h4 className="text-base text-[#9D0F3E] pb-2 font-semibold">
                    Payment Methods
                  </h4>
                  <p className="text-sm text-[#000000]">
                    How do you want to pay?
                  </p>
                </header>
                <nav className="pt-6 flex flex-wrap gap-3">
                  <CheckButton
                    text="Pay with Card"
                    isActive={active === "card"}
                    onChange={() => setActive("card")}
                    icon={
                      <CardIcon
                        bg={active === "card" ? "#9D0F3E" : "#808080"}
                      />
                    }
                  />
                  <CheckButton
                    text="Pay with Bank Transfer"
                    onChange={() => setActive("transfer")}
                    isActive={active === "transfer"}
                    icon={
                      <TransferIcon
                        bg={active === "transfer" ? "#9D0F3E" : "#808080"}
                      />
                    }
                  />
                </nav>
              </aside>
              <div className="w-full grow basis-[338px]">
                <div className=" bg-white rounded-[5px] md:pb-6 md:pt-[14px] p-3 md:px-6">
                  <header className="pb-3 mb-5 border-b border-[#C4C4C4] flex justify-between gap-3 flex-wrap">
                    <div className="">
                      <img src={AlatPay} alt="" />
                    </div>
                    <div>
                      <p className="max-w-[130px] text-right text-sm text-[#6C6C6C]">
                        Pay{" "}
                        <span
                          id="amount_text"
                          className="text-base font-semibold text-[#A90836]"
                        >
                          {amount}
                        </span>{" "}
                        to WEMA BANK
                      </p>
                    </div>
                  </header>
                  {/* Bank transfer  */}
                  {active === "transfer" && (
                    <BankTransfer
                      payAmount={paymentAmount}
                      handleCopy={handleCopy}
                      copied={copied}
                      resetTimer={resetTimer}
                      validatePayment={validatePayment}
                    />
                  )}

                  {/* Card Payment  */}
                  {active === "card" && (
                    <CardPayment
                      payAmount={paymentAmount}
                      correlationId={unRegBizCorrelationId}
                      handleCardValidation={handleCardValidation}
                      userCardValidationLoading={userCardValidationLoading}
                      userCardValidationData={userCardValidationData}
                      userPayWithCardStatus={userPayWithCardStatus}
                      userPayWithCardLoading={userPayWithCardLoading}
                      userPayWithCardData={userPayWithCardData}
                      handlePayWithCard={handlePayWithCard}
                    />
                  )}
                </div>
                <div className="py-5 flex gap-2 items-center justify-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4659 7.66667C14.8246 7.66667 15.1685 7.80714 15.4221 8.05719C15.6757 8.30724 15.8182 8.64638 15.8182 9V15.6667C15.8182 16.0203 15.6757 16.3594 15.4221 16.6095C15.1685 16.8595 14.8246 17 14.4659 17H6.35227C5.99363 17 5.64967 16.8595 5.39607 16.6095C5.14247 16.3594 5 16.0203 5 15.6667V9C5 8.26 5.60852 7.66667 6.35227 7.66667H7.02841V6.33333C7.02841 5.44928 7.38459 4.60143 8.01859 3.97631C8.65259 3.35119 9.51248 3 10.4091 3C10.853 3 11.2927 3.08622 11.7028 3.25373C12.113 3.42125 12.4857 3.66678 12.7996 3.97631C13.1135 4.28584 13.3625 4.6533 13.5324 5.05772C13.7023 5.46214 13.7898 5.89559 13.7898 6.33333V7.66667H14.4659ZM10.4091 4.33333C9.87112 4.33333 9.35519 4.54405 8.97479 4.91912C8.59439 5.29419 8.38068 5.8029 8.38068 6.33333V7.66667H12.4375V6.33333C12.4375 5.8029 12.2238 5.29419 11.8434 4.91912C11.463 4.54405 10.9471 4.33333 10.4091 4.33333Z"
                      fill="#6C6C6C"
                    />
                    <path
                      d="M14.4659 7.66667C14.8246 7.66667 15.1685 7.80714 15.4221 8.05719C15.6757 8.30724 15.8182 8.64638 15.8182 9V15.6667C15.8182 16.0203 15.6757 16.3594 15.4221 16.6095C15.1685 16.8595 14.8246 17 14.4659 17H6.35227C5.99363 17 5.64967 16.8595 5.39607 16.6095C5.14247 16.3594 5 16.0203 5 15.6667V9C5 8.26 5.60852 7.66667 6.35227 7.66667H7.02841V6.33333C7.02841 5.44928 7.38459 4.60143 8.01859 3.97631C8.65259 3.35119 9.51248 3 10.4091 3C10.853 3 11.2927 3.08622 11.7028 3.25373C12.113 3.42125 12.4857 3.66678 12.7996 3.97631C13.1135 4.28584 13.3625 4.6533 13.5324 5.05772C13.7023 5.46214 13.7898 5.89559 13.7898 6.33333V7.66667H14.4659ZM10.4091 4.33333C9.87112 4.33333 9.35519 4.54405 8.97479 4.91912C8.59439 5.29419 8.38068 5.8029 8.38068 6.33333V7.66667H12.4375V6.33333C12.4375 5.8029 12.2238 5.29419 11.8434 4.91912C11.463 4.54405 10.9471 4.33333 10.4091 4.33333Z"
                      fill="black"
                      fillOpacity="0.2"
                    />
                  </svg>
                  <p className="text-sm text-[#000000]">
                    Secured by <span className="font-semibold">ALATPay</span>
                  </p>
                </div>
              </div>
            </div>
          )}
        </BusinessDetailsContainer>
      }

      <div className={`${!pendingModal && "hidden"}`}>
        <CenteredModal
          id="pending_modal"
          idButton1="pending-modal_btn"
          idCloseModal="close-pending_btn"
          icon={
            <div className="">
              <img src={PendingIcon} alt="" />
            </div>
          }
          title={"Thank You!"}
          info={"Your transaction is being processed"}
          btnText={"Refresh"}
          showCloseIcon
          handleClick={(e: any) => {
            validatePayment();
          }}
          handleClose={(e: any) => {
            setPendingModal(false);
          }}
        >
          <div
            className={`text-center text-sm text-lib-alat-gray-light bg-[#f5f5f5] my-3 py-3 rounded-lg`}
          >
            If you are not redirected in 30 seconds, use the refresh button
            below to manually check your transaction status
          </div>
        </CenteredModal>
      </div>

      <div className={`${!successModal && "hidden"}`}>
        <CenteredModal
          id="successful_modal"
          idButton1="successful-modal_btn"
          idCloseModal="close-successful-modal_btn"
          icon={
            <div className="">
              <SuccessIcon />
            </div>
          }
          title={paymentAmount ? "Success!" : "You are all set"}
          info={paymentAmount ? "" : "Your account is now secure"}
          btnText={paymentAmount ? "Proceed" : "Proceed to dashboard"}
          showCloseIcon
          handleClick={(e: any) => {
            if (paymentAmount) {
              handleEmbedLink();
              return;
            }
            dispatch(
              saveOnboardingData({
                ...onboardingState,
                alatPay: true,
              })
            );
            // window.location.replace("/user-dashboard");
            handleFormSubmit({
              username: onboardingState.username,
              password: onboardingState.password,
            });
          }}
          handleClose={(e: any) => {
            setSuccessModal(false);
          }}
        >
          {paymentAmount && (
            <div className="text-secondary text-[13px]/[23.9px] !-mt-2">
              Payment Successful.
              <p className="font-bold text-lib-alat-black">
                Here’s your tracking {trackingId || "__"}.
              </p>
              Kindly check your email for more information
              <div className="border-b pt-5" />
              <p className="text-[10px]/[15px] pt-5">
                Clicking 'Proceed' will redirect you to a third-party platform
                to complete your business registration
              </p>
            </div>
          )}
        </CenteredModal>
      </div>
    </>
  );
};

export default PaymentContainer;
