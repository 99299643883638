import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { useAppSelector } from "../../../../hooks/useReduxHook";
import { removeLoadingState } from "../../../../helpers/assitFunctions";
import { NotificationState } from "../interfaces";

const dataObject = {
  loading: [],
  notifications: [],
  allNotifications: [],
  unReadNotifications: [],
  readNotifications: [],
  notificationsDetails: {},
  notificationsCount: 0,
  notificationsPagination: {
    sortedData: [],
    hasNextPage: false,
    currentPage: 1,
    count: 0,
    totalItems: 0,
    pageSize: 10,
    skip: 1,
    take: 10,
  },
} as unknown as NotificationState;

export const initialState: any = { ...dataObject };

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,

  reducers: {
    resetNotificationsData: (): any => initialState,

    setLoadingState: (state: any, action: PayloadAction<any>): void => {
      state.loading = action.payload;
    },

    setAllNotifications: (state: any, action: PayloadAction<any>): void => {
      state.allNotifications = action.payload;
      state.notificationsPagination = {
        ...state.notificationsPagination,
        sortedData: [
          ...state?.notificationsPagination?.sortedData,
          ...action?.payload?.notifications,
        ],
        hasNextPage: action?.payload?.hasNextPage,
        currentPage: action?.payload?.pageNumber,
        count: action?.payload?.count,
        totalItems: action?.payload?.totalItemCount,
      };
    },

    setUnreadNotifications: (state: any, action: PayloadAction<any>): void => {
      state.unReadNotifications = action.payload;
      state.notificationsPagination = {
        ...state.notificationsPagination,
        sortedData: [
          ...state?.notificationsPagination?.sortedData,
          ...action?.payload?.notifications,
        ],
        hasNextPage: action?.payload?.hasNextPage,
        currentPage: action?.payload?.pageNumber,
        count: action?.payload?.count,
        totalItems: action?.payload?.totalItemCount,
      };
    },

    setReadNotifications: (state: any, action: PayloadAction<any>): void => {
      state.readNotifications = action.payload;
      state.notificationsPagination = {
        ...state.notificationsPagination,
        sortedData: [
          ...state?.notificationsPagination?.sortedData,
          ...action?.payload?.notifications,
        ],
        hasNextPage: action?.payload?.hasNextPage,
        currentPage: action?.payload?.pageNumber,
        count: action?.payload?.count,
        totalItems: action?.payload?.totalItemCount,
      };
    },

    setNotificationsDetails: (state: any, action: PayloadAction<any>): void => {
      state.notificationsDetails = action.payload;
    },
    setSkipAndTake: (state: NotificationState, action: PayloadAction<any>) => {
      state.notificationsPagination = {
        ...state.notificationsPagination,
        skip: action.payload?.skip,
        take: action.payload?.take,
      };
    },
    setNotificationsCount: (state: any, action: PayloadAction<any>): void => {
      state.notificationsCount = action.payload;
    },
  },
});

// Selectors
const selectNotifications = (state: RootState) => state.notifications;

// Reducers and actions
export const {
  resetNotificationsData,
  setLoadingState,
  setAllNotifications,
  setUnreadNotifications,
  setReadNotifications,
  setNotificationsDetails,
  setNotificationsCount,
  setSkipAndTake,
} = notificationSlice.actions;

//App Redux State
export const useNotificationState = () => useAppSelector(selectNotifications);

export default notificationSlice.reducer;
