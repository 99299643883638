import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AppState } from "../interfaces";
import { useAppSelector } from "../../../../hooks/useReduxHook";

const dataObject = {
  testPayload: [],
  loading: [],
  currentSort: { key: "name", order: "asc", type: "object" },
  sideBarStatus: true,
  sideBarTab: "/overview",
  showSidebarDropDown: [],
  centeredModal: {
    status: "",
    title: "",
    info: "",
  },
  searchQuery: "",
  usernameFromForgotPassword: "",
  retrieveUsernameAnswer: "",
  retrieveUsernamePin: "",
  retrieveUsernameAccount: "",
  customerId: "",
  retrieveUsernamePinAndAns: {
    pin: "",
    securityAnswer: "",
  },
  dimecData: {
    usingDimec: false,
    dimecId: "",
    dimecStep: "",
    dimecCapturedImage: "",
    correlationId: "",
  },
  softTokenAndRole: { isSoftToken: false, role: "" },
  usernameVerificationData: {
    httpStatusCode: 0,
    responseCode: 0,
    responseMessage: {
      title: "",
      actionMessage: "",
    },
    hasError: false,
    data: {
      customerId: "",
      retailCif: "",
      isAdmin: false,
      referenceCode: "",
    },
  },
  accountVerificationData: {
    httpStatusCode: 0,
    responseCode: 0,
    responseMessage: {
      title: "",
      actionMessage: "",
    },
    hasError: false,
    data: {
      customerId: "",
      customerProfileId: 0,
      retailCif: "",
      securityQuestion: "",
      securityQuestionId: 0,
      isAdmin: false,
      otpReferenceId: "",
      hasPin: false,
    },
  },
  adminResetPasswordStatus: false,
  selectedScreen: 0,
  isInIndividualRefereeView: false,
  initiatePosRequest: {
    businessName: "",
    RCNumber: "",
    bvn: "",
    tin: "",
    dateOfCommencement: "",
    businessCategory: "",
    businessAddress: "",
    stateOfOrigin: "",
    lga: "",
  },
  posRequestResponse: {
    httpStatusCode: 0,
    responseCode: 0,
    responseMessage: {
      title: "",
      actionMessage: "",
    },
    hasError: true,
    data: "",
  },
  showMobileSidebar: false,
} as AppState;

export const initialState = { ...dataObject } as AppState;

export const appSlice = createSlice({
  name: "app",
  initialState,

  reducers: {
    setAppReset: (state: AppState) => initialState,

    // setTestPayload: (state: AppState, action: PayloadAction<TestPayloadInterface[]>) => {
    //    state.testPayload = action.payload;
    // },
    // setSidebar: (state: AppState, action: PayloadAction<boolean>) => {
    //    state.sideBarStatus = action.payload
    // },
    // setSidebarTab: (state: AppState, action: PayloadAction<string>) => {
    //    state.sideBarTab = action.payload
    // },
    // setShowSidebarDropDown: (state: AppState, action: PayloadAction<string[]>) => {
    //    state.showSidebarDropDown = action.payload
    // },
    setCenteredModal: (state: AppState, action: PayloadAction<any>) => {
      state.centeredModal = action.payload;
    },

    // setSearchQuery: (state: AppState, action: PayloadAction<any>) => {
    //    state.searchQuery = action.payload
    // },
    setUsername: (state: AppState, action: PayloadAction<any>) => {
      state.usernameFromForgotPassword = action.payload;
    },
    setRetrieveUsernamePin: (state: AppState, action: PayloadAction<any>) => {
      state.retrieveUsernamePin = action.payload;
    },
    setRetrieveUsernamePinAndAns: (
      state: AppState,
      action: PayloadAction<any>
    ) => {
      state.retrieveUsernamePinAndAns = {
        ...state.retrieveUsernamePinAndAns,
        ...action.payload,
      };
    },
    setRetrieveUsernameAccount: (
      state: AppState,
      action: PayloadAction<any>
    ) => {
      state.retrieveUsernameAccount = action.payload;
    },
    setCustomerId: (state: AppState, action: PayloadAction<any>) => {
      state.customerId = action?.payload;
    },
    setSoftTokenAndRoleData: (state: AppState, action: PayloadAction<any>) => {
      state.softTokenAndRole = action?.payload;
    },
    setUsernameVerificationData: (
      state: AppState,
      action: PayloadAction<any>
    ) => {
      state.usernameVerificationData = action?.payload;
    },
    setAccountVerificationData: (
      state: AppState,
      action: PayloadAction<any>
    ) => {
      state.accountVerificationData = action?.payload;
    },
    setAdminResetPasswordStatus: (
      state: AppState,
      action: PayloadAction<any>
    ) => {
      state.adminResetPasswordStatus = action.payload;
    },
    setDimecStepAndId: (state: AppState, action: PayloadAction<any>) => {
      state.dimecData = {
        ...state.dimecData,
        dimecId: action.payload?.dimecId,
        dimecStep: action.payload?.dimecStep,
      };
    },
    setDimecCapturedImage: (state: AppState, action: PayloadAction<any>) => {
      state.dimecData = {
        ...state?.dimecData,
        dimecCapturedImage: action.payload.capturedImage,
        usingDimec: action.payload?.usingDimec,
      };
    },
    setDimecCorrelationId: (state: AppState, action: PayloadAction<any>) => {
      state.dimecData = {
        ...state?.dimecData,
        usingDimec: action.payload.usingDimec,
        correlationId: action.payload.correlationId,
      };
    },
    setSelectedScreen: (state: AppState, action: PayloadAction<any>) => {
      state.selectedScreen = action?.payload;
    },
    setRefereeView: (state: AppState, action: PayloadAction<any>) => {
      state.isInIndividualRefereeView = action?.payload;
    },
    setInitiatePosRequest: (state: AppState, action: PayloadAction<any>) => {
      state.initiatePosRequest = action?.payload;
    },
    setPosRequestResponse: (state: AppState, action: PayloadAction<any>) => {
      state.posRequestResponse = action?.payload;
    },
    setShowMobileSidebar: (state: AppState, action: PayloadAction<any>) => {
      state.showMobileSidebar = action?.payload;
    },
  },
});

// Selectors
const selectApp = (state: RootState) => state.app;

// Reducers and actions
export const {
  setAppReset,
  setUsername,
  setRetrieveUsernamePin,
  setRetrieveUsernamePinAndAns,
  setRetrieveUsernameAccount,
  // setTestPayload,
  // setSidebar,
  // setSidebarTab,
  // setShowSidebarDropDown,
  setCenteredModal,
  setSoftTokenAndRoleData,
  // setSearchQuery,
  setDimecStepAndId,
  setDimecCapturedImage,
  setDimecCorrelationId,
  setCustomerId,
  setUsernameVerificationData,
  setAccountVerificationData,
  setAdminResetPasswordStatus,
  setSelectedScreen,
  setRefereeView,
  setInitiatePosRequest,
  setPosRequestResponse,
  setShowMobileSidebar,
} = appSlice.actions;

//App Redux State
export const useAppState = () => useAppSelector(selectApp);

export default appSlice.reducer;
