import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as RefereeIcon } from "assets/svgs/red-referee-icon.svg";
import { ReactComponent as ResendIcon } from "assets/svgs/resend-icon.svg";
import { ReactComponent as EditIcon } from "assets/svgs/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete-icon.svg";
import { useNavigate } from "react-router-dom";
import CenterModal from "alat-components-library/Modal/CenterModal";
import { ReactComponent as SuccessIcon } from "assets/svgs/success-icon.svg";
import { useResendRefereeEmail } from "hooks/apis/Referee/useResendRefereeEmail";
import PageLoader from "components/Loader/PageLoader";

interface RefereeCardProps {
  id?: string;
  deleteId?: string;
  editId?: string;
  actionId?: string;
  bank: string;
  email: string;
  firstName: string;
  lastName: string;
  index: number;
  filteredReferees: any;
  reason?: Array<string>;
}

export const RefereeCard: React.FC<RefereeCardProps> = ({
  id,
  actionId,
  editId,
  deleteId,
  bank,
  firstName,
  lastName,
  email,
  index,
  filteredReferees,
  reason,
}) => {
  const { user } = useAppSelector((state) => state.auth);
  const [actions, setActions] = useState(false);
  // eslint-disable-next-line
  const [deleteAction, setDeleteAction] = useState(false);
  const actionsRef = useRef<HTMLDivElement>(null);
  const {
    resendRefereeEmailStatus,
    resendRefereeEmailLoading,
    resendRefereeEmail: postResendRefereeEmail,
  } = useResendRefereeEmail();
  const [successModal, setSuccessModal] = useState(false);
  const referees = useAppSelector((state) => state.onboarding.refereePayload);

  const infoText = bank + " | " + email;

  // Closes the modal on click outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        actionsRef.current &&
        !actionsRef.current.contains(event.target as Node)
      ) {
        setActions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [actionsRef]);

  useEffect(() => {
    if (resendRefereeEmailStatus) {
      setSuccessModal(true);
    }
  }, [resendRefereeEmailStatus]);

  return (
    <div key={index}>
      {resendRefereeEmailLoading && <PageLoader message="" />}
      <div
        className={`w-full rounded-lg h-auto py-4 border items-center my-2 transition-all duration-1000
        ${deleteAction ? "animate-bounce" : ""}`}
      >
        <div className="flex gap-2">
          <div className="my-auto">
            <RefereeIcon className="sm:h-20 sm:w-16 w-10 h-10 text-[#606060] ml-2" />
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-lg font-semibold">
              {firstName} &nbsp;{lastName}
            </p>
            <p className="text-md md:hidden">
              {infoText.length > 25 ? infoText.slice(0, 25) + "..." : infoText}
            </p>
            <p className="text-md hidden md:block">
              {infoText.length > 30 ? infoText.slice(0, 30) + "..." : infoText}
            </p>
            <div className="text-[#A90836] bg-[#FFE3E3] text-xs p-2 rounded-lg font-semibold w-max">
              {`${reason}`}
            </div>
          </div>
        </div>
      </div>

      {/*Referee Email resent Successfully*/}
      {successModal && (
        <CenterModal
          icon={<SuccessIcon />}
          title="Success!"
          info={`A referee verification link has been sent to the email (${referees[index].emailAddress}), Kindly ensure your referee responds via the link sent.`}
          handleClick={() => {
            setSuccessModal(false);
            // navigate("/user-dashboard");
          }}
          handleClose={() => {
            setSuccessModal(false);
            // navigate("/user-dashboard");
          }}
          handleClick2={() => {
            setSuccessModal(false);
            // navigate("/user-dashboard");
          }}
          btnText="Okay"
          btnText2={"Return to dashboard"}
          showCloseIcon
        />
      )}
    </div>
  );
};
