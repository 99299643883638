import * as Yup from "yup";

//Validation
export const LoginValidation = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

// export const LoginValidation = Yup.object().shape({
// email: Yup.string()
//     .email('Invalid email adderess')
//     .required('Required'),
// password: Yup.string()
//     .required('Required')
// });
