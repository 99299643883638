import React, { forwardRef, useState } from "react";
import styles from "./PasswordInput.scss";
import { InputType } from "../types";
import { ReactComponent as ErrorIcon } from "../../assets/images/icons/error-icon.svg";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { ReactComponent as TooltipIcon } from "../../assets/images/icons/tooltip-icon.svg";

export const PasswordInput = forwardRef<HTMLInputElement, InputType>(
  (
    {
      containerVariant = "w-full flex flex-col outline-none",
      error,
      handleChange,
      handleBlur,
      icon,
      id = "",
      idHidePwd = "",
      hasError = false,
      isDisabled = false,
      label,
      labelStyle = "text-sm mb-2.5",
      name,
      placeHolder = "Enter password",
      value = "",
      variant = "border w-full h-12 text-lg px-5",
      iconStyle = {
        wrapper: "bottom-[1.5vh] right-[2vh]",
        icon: "w-7 h-7",
      },
      maxLength,
      showTooltip = false,
      showExtras = false,
      extraLabel,
      toolTip,
      handleExtras,
    },
    ref
  ) => {
    // Local State
    const [localValue, setLocalValue] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [open, setOpen] = useState(false);

    // Effects
    useState(() => value !== "" && setLocalValue(value));

    return (
      <div className={`${containerVariant} ${styles.PasswordInput}}`}>
        {label && (
          <div className="flex items-center justify-between">
            <label
              htmlFor={`input-${name}`}
              className={`${labelStyle}
							${isDisabled ? "text-gray-300" : "text-black"} `}
            >
              {label}
            </label>
            {showTooltip && (
              <>
                <div className="cursor-pointer relative">
                  {open && toolTip}
                  <div
                    onMouseEnter={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                  >
                    <TooltipIcon />
                  </div>
                </div>
              </>
            )}
            {showExtras && (
              <div
                className="cursor-pointer text-lib-alat-red text-xs leading-[24px] tracking-tighter underline"
                onClick={handleExtras}
              >
                {extraLabel}
              </div>
            )}
          </div>
        )}

        <div className="relative ">
          <input
            id={id}
            ref={ref}
            name={name}
            type={passwordShown ? "text" : "password"}
            className={` 
        focus:outline-none text-sm focus:text-[#333333] text-[#A6A6A6] z-2 focus:bg-white outline-none placeholder:text-sm rounded-md
        ${icon?.active && "px-12"} 
        ${
          isDisabled
            ? `cursor-not-allowed bg-[#F7F7F7] placeholder:text-gray-300`
            : "bg-lib-alat-gray-input placeholder:text-gray-300"
        }
        ${hasError && "border-red-500 focus:border-red-500"}
        ${!error && "border-[#E4DFDF] focus:border-[#333333]"}
            } 
          ${variant}`}
            value={localValue}
            onChange={(e) => {
              setLocalValue(e?.target?.value);
              return handleChange(e);
            }}
            onBlur={handleBlur}
            placeholder={placeHolder}
            disabled={isDisabled}
            maxLength={maxLength}
          />
          <span
            className={`absolute inset-y-0 right-0 flex items-center pr-3.5 text-gray-500 cursor-pointer`}
            id={idHidePwd}
            onClick={() => setPasswordShown(!passwordShown)}
          >
            {passwordShown ? (
              <AiOutlineEyeInvisible className={iconStyle.icon} />
            ) : (
              <AiOutlineEye className={iconStyle.icon} />
            )}
          </span>
        </div>
        {hasError && (
          <p className="text-[#ff0000] text-xs h-auto pt-2 flex items-center font-normal gap-1">
            <ErrorIcon />
            {error}
          </p>
        )}
      </div>
    );
  }
);

// Important note
// This input can manage password type.
