import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TradeServicesState } from "../interfaces";
import { RootState } from "../store";
import { useAppSelector } from "../../../../hooks/useReduxHook";

const dataObject = {
  loading: [],
  applicantInfo: {
    selectedAccount: "",
    businessCategory: "",
    otherCategory: "",
    annualTurnOver: 0,
    numberOfEmployees: 0,
  },
} as TradeServicesState;

export const initialState: TradeServicesState = { ...dataObject };

export const tradeServicesSlice = createSlice({
  name: "tradeServices",
  initialState,

  reducers: {
    resetTradeServicesState: (): TradeServicesState => initialState,
    setApplicantInfo: (
      state: TradeServicesState,
      action: PayloadAction<any>
    ) => {
      state.applicantInfo = action.payload;
    },
    // setTokenAndRefreshToken: (state: AuthState, action: PayloadAction<any>) => {
    //   state.loginData.user = {
    //     ...state.loginData.user,
    //     token: action?.payload?.token,
    //     reissueToken: action?.payload?.refreshToken
    //   }
    // }
  },
});

// Selectors
const selectTradeServices = (state: RootState) => state.tradeServices;

// Reducers and actions
export const { resetTradeServicesState, setApplicantInfo } =
  tradeServicesSlice.actions;

//App Redux State
export const useTradeServicesState = () => useAppSelector(selectTradeServices);

export default tradeServicesSlice.reducer;
