import PageHeader from "../../components/reusables/Page/PageHeader";
import DashboardWrapper from "../../components/Layouts/DashboardWrapper";
import BuyairtimeTab from "components/AirtimeAndData/BuyAirtime";
import SelectedMerchantTab from "components/Collections/SelectedMerchant";
import {
  setActiveSelectedCollectionMerchant,
  useCollectionsState,
} from "react-wrappers/stores/redux/collections/slices";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "components/Collections/Reuseables/HeaderComponent";
import CollectionPaymentComponent from "components/Collections/CollectionPayment";

const CollectionPayment = () => {
  const { activeSelectedCollectionMerchant } = useCollectionsState();
  const navigate = useNavigate();

  return (
    <DashboardWrapper>
      <div className="container-fluid 2xl:container mx-auto ">
        <div className="container-fluid 2xl:container mx-auto">
          <main className="max-h-screen overflow-auto">
            <div className="">
              <div className="max-w-6xl mx-auto">
                <div className="rounded-3xl mb-5">
                  <>
                    <HeaderComponent categoryName="Payment Details" />
                    <CollectionPaymentComponent />
                  </>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default CollectionPayment;
