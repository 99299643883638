export const tableHeader = [
  {
    id: 58389090,
    title: "FROM",
  },
  {
    id: 673936367,
    title: "AMOUNT",
  },
  {
    id: 2384893993,
    title: "TYPE",
  },
  {
    id: 1293997833,
    title: "DESCRIPTION",
  },
  {
    id: 1289938398,
    title: "DATE",
  },
  {
    id: 12898477883,
    title: "STATUS",
  },
  {
    id: 128999738838,
    title: "ACTION",
  },
];

export const usersTableHeaders = [
  {
    id: 100,
    title: "TITLE",
  },
  {
    id: 101,
    title: "STAFF ID",
  },
  {
    id: 102,
    title: "MOBILE",
  },
  {
    id: 106,
    title: "MAPPED",
  },
  {
    id: 103,
    title: "STATUS",
  },
  {
    id: 104,
    title: "ROLE",
  },
  {
    id: 105,
    title: "ACTION",
  },
];

export const usersDashTableList = [
  {
    id: 9915629910,
    fromOwnerName: "Chekwube Onyekigwe Margaret",
    userName: "Cheks4ever",
    amount: "NGN 265,000",
    type: "Bulk",
    description: "MTN - Airtime Purchase",
    date: "08 Feb 2022, 11:48PM",
    status: "pending",
  },
  {
    id: 99991084893,
    fromOwnerName: "Chekwube Onyekigwe Margaret",
    userName: "Cheks4ever",
    amount: "NGN 265,000",
    type: "Bulk",
    description: "MTN - Airtime Purchase",
    date: "08 Feb 2022, 11:48PM",
    status: "pending",
  },
  {
    id: 99952563910,
    fromOwnerName: "Chekwube Onyekigwe Margaret",
    userName: "Cheks4ever",
    amount: "NGN 265,000",
    type: "Bulk",
    description: "MTN - Airtime Purchase",
    date: "08 Feb 2022, 11:48PM",
    status: "pending",
  },
  {
    id: 999910,
    fromOwnerName: "Chekwube Onyekigwe Margaret",
    userName: "Cheks4ever",
    amount: "NGN 265,000",
    type: "Bulk",
    description: "MTN - Airtime Purchase",
    date: "08 Feb 2022, 11:48PM",
    status: "active",
  },
];

export const userAccounts = [
  {
    id: 74673883883,
    accountNumber: "8938988299",
    accountType: "savings",
    status: "Active",
    balance: "583903",
    name: "Teno Phones",
  },
  {
    id: 37627647883,
    accountNumber: "893478389",
    accountType: "current",
    status: "Active",
    balance: "7893939",
    name: "Qazo IT Firm",
  },
  {
    id: 293884833883,
    accountNumber: "3837748299",
    accountType: "dollar",
    status: "Active",
    balance: "890",
    name: "House Wears",
  },
  {
    id: 1838773733883,
    accountNumber: "3083848299",
    accountType: "savings",
    status: "Active",
    balance: "4903",
    name: "Kano Declutters",
  },
  // {
  //     id: 948378833883,
  //     accountNumber: "8448384299",
  //     accountType: "dollar",
  //     status: "Active",
  //     balance: "458939",
  //     name: "Famo Bags"
  // },
  // {
  //     id: 98377474883,
  //     accountNumber: "8483835299",
  //     accountType: "Savings",
  //     status: "Active",
  //     balance: "193884774",
  //     name: "Taiko Electronics"
  // },
  // {
  //     id: 483939948883,
  //     accountNumber: "4938837499",
  //     accountType: "current",
  //     status: "Active",
  //     balance: "4839030",
  //     name: "Bendo Shoes"
  // }
];
