import { useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card, Label } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { FormikButton } from "alat-components-library";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import { paybillValidationSchema } from "utils/validations/payment";
import { usePayBillController } from "controllers/Payments/Paybill";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import PageLoader from "alat-components-library/PageLoader";

const PayBill = () => {
  // Local State
  const { loading } = usePaymentState();
  const { pacakgeList, handleSubmit, activeSelectedSubBill } =
    usePayBillController();
  const [isPackageAmountEditable, setIsPackageAmountEditable] = useState(false);

  // console.log(activeSelectedSubBill)
  const navigate = useNavigate();

  return (
    <div className="w-full">
      {!!loading.includes("POST_VALIDATE_BILL_REFERENCE") && (
        <PageLoader
          message={`Validating ${activeSelectedSubBill.identifier} details, please wait...`}
        />
      )}
      <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              onClick={() => navigate(-1)}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            />
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="banktransfer_heading"
            >
              Pay bill
            </div>
            <div></div>
          </div>
        </div>
        <hr />
        <Formik
          initialValues={{
            amount: 0,
            package: "",
            transactionId: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={paybillValidationSchema}
          validateOnChange
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form>
              <div>
                <div className="flex flex-wrap justify-center gap-4 ">
                  <div className="text-alat-grey relative w-full lg:max-w-sm mb-2">
                    {activeSelectedSubBill.requiredValidation && (
                      <div className="relative w-full mb-2">
                        <div className="mb-2 block text-left">
                          <Label
                            htmlFor="TransactionId"
                            value={activeSelectedSubBill.identifier}
                            className="!text-[#464646] font-normal text-lg leading-[1rem]"
                          />
                        </div>
                        <div className="relative w-full">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"></div>
                          <input
                            id="transactionId"
                            name="transactionId"
                            type="text"
                            className="pl-6 w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.transactionId}
                          />
                        </div>

                        {touched.transactionId && errors.transactionId && (
                          <span className="text-sm text-red-600">
                            {typeof errors?.transactionId == "string"
                              ? errors?.transactionId
                              : ""}
                          </span>
                        )}
                      </div>
                    )}

                    <div className="relative w-full lg:max-w-sm mb-2">
                      <div className="mb-2 block text-left text-[#666666]">
                        <Label
                          value="Select Package"
                          className="!text-[#464646] font-normal text-lg leading-[1rem]"
                        />
                      </div>
                      <div className="relative w-full lg:max-w-sm">
                        <StateDropdown
                          name="package"
                          id="packageSelect"
                          onChange={handleChange}
                          value={values.package}
                          options={pacakgeList}
                          placeholder="--Select--"
                          onSelectOption={(data) => {
                            // console.log(data.isAmountEditable);
                            setIsPackageAmountEditable(!data.isAmountEditable);
                            setFieldValue("amount", data.amount);
                          }}
                        />

                        {touched.package && errors.package && (
                          <span className="text-sm text-red-600">
                            {typeof errors?.package == "string"
                              ? errors?.package
                              : ""}
                          </span>
                        )}
                        {""}
                      </div>
                    </div>

                    <div className="relative w-full mb-2">
                      <div className="mb-2 block text-left">
                        <Label
                          htmlFor="amount"
                          value="Enter Amount"
                          className="!text-[#464646] font-normal text-lg leading-[1rem]"
                        />
                      </div>
                      <div className="relative w-full">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-[#666666]">₦</span>
                        </div>
                        <input
                          name="amount"
                          type="number"
                          value={values.amount.toString().replace(/^0+/, "")}
                          id="airtimeInput"
                          className={`${
                            isPackageAmountEditable
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          } pl-6 w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm`}
                          onChange={handleChange}
                          disabled={isPackageAmountEditable}
                        />
                      </div>
                      {touched.amount && errors.amount && (
                        <span className="text-sm text-red-600">
                          {typeof errors?.amount == "string"
                            ? errors?.amount
                            : ""}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="relative w-full lg:max-w-sm mb-2  flex items-center justify-center">
                    <FormikButton
                      id="proceed"
                      labelText="Proceed"
                      isDisabled={!isValid && !dirty}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default PayBill;
