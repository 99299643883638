import React from "react";
import styles from "./DefaultImage.module.scss";
import { DefaultImageProps } from "./types";

export const DefaultImage = ({
  src,
  handleChange,
  variant,
  containerVariant,
  alt,
  fallbackImageSrc = "",
}: DefaultImageProps) => {
  return (
    <div className={`${styles.DefaultImage} ${containerVariant} w-full`}>
      <img
        src={src}
        className={variant}
        onChange={handleChange}
        alt={alt}
        onError={(e: any) => (e.target.src = fallbackImageSrc)}
      />
    </div>
  );
};

export default DefaultImage;
