import { lazy } from "react";

const SmeFormQ = lazy(() =>
  import("pages/TradeServices/SmeFormQ").then(({ SmeFormQ }) => ({
    default: SmeFormQ,
  }))
);

export const tradeServicesRoutes = [
  {
    path: "/trade-services/sme-form-q/:tabPath",
    isAuthenticated: true,
    element: <SmeFormQ />,
  },
];
