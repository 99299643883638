import { ReactComponent as UserIcon } from "../../assets/svgs/user-icon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/svgs/settings-dropdown-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/svgs/logout-icon.svg";
import { ReactComponent as PenIcon } from "../../assets/svgs/pen-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svgs/delete-icon.svg";

export const tableOptions = [
  {
    id: "edit_user",
    name: "Edit user",
    link: "/profile/edit",
    icon: <PenIcon />,
  },
  {
    id: "remove_user",
    name: "Remove user",
    link: "/profile/delete",
    icon: <DeleteIcon />,
  },
];
export const profileDropDownLinks = [
  {
    id: "myprofile_btn",
    name: "My profile",
    link: "/profile",
    logout: false,
    icon: <UserIcon />,
  },
  {
    id: "mysettings_btn",
    name: "My Settings",
    link: "/profile/account-settings",
    logout: false,
    icon: <SettingsIcon />,
  },
  {
    id: "logout_btn",
    name: "Log out",
    link: "/logout",
    logout: true,
    icon: <LogoutIcon />,
  },
];
