import React, { useEffect } from "react";
import ReferenceLetterContainer from "./ReferenceLetterContainer";
import { ReactComponent as FailureIcon } from "../../../assets/svgs/failure-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";

const NonWemaRejected = () => {
  const location = useLocation();
  const companyName = location?.state?.companyName;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!companyName) {
      navigate("/signin");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <ReferenceLetterContainer>
      {/* {isUploadingBlob && <PageLoader message='' />} */}
      <div
        className="px-5 md:px-0 min-w-[50vw] max-w-[500px] mx-auto flex flex-col md:gap-[4vw] gap-10 items-center"
        id="non-wema-rejected_card"
      >
        <FailureIcon className="w-32 h-32 md:w-64 md:h-64" />
        <div className="w-full text-center gap-3 -mt-5">
          <p className="text-4xl text-center font-bold mb-4">Rejected!</p>
          <div className="text-lg text-[#606060]">
            You have successfully rejected referee verification for{" "}
            {companyName}
          </div>
        </div>
      </div>
    </ReferenceLetterContainer>
  );
};

export default NonWemaRejected;
