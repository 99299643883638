import Preloader from "alat-components-library/Mics/Preloader";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { useAppDispatch } from "hooks/useReduxHook";
import React, { useEffect, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useFetchMappedAccounts } from "hooks/apis/pendingActions/useFetchMappedAccounts";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { formatNumberToCurrency } from "utils/helperFunctions";

export interface AccountsMappedProps {
  pageTitle: string;
  currency?: string;
  handleBack: any;
}
export const AccountsMapped = ({
  pageTitle,
  currency = "NGN",
  handleBack,
}: AccountsMappedProps) => {
  const {
    pendingTransactionDetail: { userRequest },
  } = usePaymentState();
  const {
    fetchSuccess,
    fetchLoading,
    accountsMapped,
    handleFetchMappedAccounts,
  } = useFetchMappedAccounts();

  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState("");

  // const accountList: any[] = accountsMapped?.filter(
  //   (item: any) => item?.currency === currency
  // );

  const filteredList = accountsMapped?.filter((item: any) => {
    if (searchValue !== "") {
      const queryString = searchValue?.toLowerCase();
      const accountNo = item?.accountNo;
      const accountName = item?.accountName;
      const accountStatus = item?.accountStatus;
      return (
        accountNo?.includes(queryString) ||
        accountName?.toLowerCase()?.includes(queryString) ||
        accountStatus?.toLowerCase()?.includes(queryString)
      );
    }
    return item;
  });

  const memoizedList: any = useMemo(() => filteredList, [filteredList]);

  const showAccounts = (data: any) => {
    return (
      <>
        {data?.map((item: any) => (
          <div
            key={item?.accountId}
            className="bg-[#F7F7F7] flex flex-col gap-3 rounded-xl relative w-[409px] p-4"
          >
            <div className="absolute w-[62.24px] right-0 top-0 flex items-center justify-center rounded-bl-xl rounded-tr-xl bg-lib-alat-red text-white px-5 text-xs font-medium py-2 leading-[18px] tracking-tighter">
              {item?.accountStatus}
            </div>
            <p className="flex tracking-[-0.04em] text-[12.4478px] font-medium">
              {item?.accountName}
            </p>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col">
                <span className="leading-[14px] text-xs text-[#606060] font-medium tracking-[-0.02em]">
                  Avail.Balance
                </span>
                <span className="text-[19px] text-lib-alat-black tracking-[-0.02em] leading-[22px] font-semibold">
                  {formatNumberToCurrency({
                    number: item?.balance,
                    currencyCode: item?.currency,
                    precision: 2,
                  })}
                  {/* {new Intl.NumberFormat("en-NG", {
                    style: "currency",
                    currency: item?.currency,
                  }).format(item?.balance)} */}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="leading-[14px] text-xs text-[#606060] font-medium tracking-[-0.02em]">
                  Account Number
                </span>
                <span className="text-[19px] text-lib-alat-black tracking-[-0.02em] leading-[30px] font-semibold">
                  {item?.accountNo}
                </span>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  useEffect(() => {
    handleFetchMappedAccounts(userRequest?.profileUsername);
  }, []);
  return (
    <DashboardWrapper>
      <div className="lg:px-20 space-y-12 mt-5">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">{pageTitle}</h2>
        </div>
        <ReusableCard
          variant="w-[751px] mx-auto"
          cardTitle="Account Settings"
          handleBackNavigation={handleBack}
          showGoBackIcon
        >
          <div className="flex flex-col justify-center items-center space-y-8 py-5">
            <div className="relative w-[409px]">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <FiSearch className="w-5 h-5" color="#A6A6A6" />
              </div>
              <input
                onChange={(e) => setSearchValue(e.target.value)}
                type="text"
                id="account-search"
                value={searchValue}
                className="border p-2 pl-10 w-full h-10 text-sm text-gray-900 rounded-md bg-gray-50 outline-none"
                placeholder="Search for Account"
              />
            </div>
            {fetchLoading ? (
              <div className="flex justify-center w-full bg-white h-52 items-center">
                <Preloader
                  variant="w-12 h-12"
                  currentColor="#AB0B4B"
                  currentFill="#F8E8E8"
                />
              </div>
            ) : (
              <>
                {memoizedList?.length && fetchSuccess ? (
                  showAccounts(memoizedList)
                ) : (
                  <div className="h-auto w-full flex justify-center items-center ">
                    <p className="leading-4 text-lib-alat-black">
                      You do not have any accounts
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </ReusableCard>
      </div>
    </DashboardWrapper>
  );
};
