import React, { ReactNode } from "react";
import AFBLogo from "../../assets/svgs/AFBLogo.svg";
import BusinessDetailsBG from "../../assets/svgs/BusinessDetailsBG.svg";
import BackArrowIcon from "../../assets/svgs/BackArrowIcon.svg";
import CircularProgressBar from "../../components/reusables/Progress Indicators/CIrcularProgressBar";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "ErrorBoundary";

interface Props {
  subHeading?: string;
  heading?: any;
  children: ReactNode;
  containerStyle?: string;
  showProgress?: boolean;
  num?: number;
  deNum?: number;
  showBackButton?: boolean;
  isFirstTime?: boolean;
  subTextStyle?: string;
}

export const BusinessDetailsContainer: React.FC<Props> = ({
  showBackButton = true,
  isFirstTime = false,
  heading,
  subHeading,
  subTextStyle = "",
  num = 1,
  deNum = 2,
  showProgress,
  children,
  containerStyle = "mx-auto xs:w-full sm:w-2/3 md:w-3/4 py-4 relative min-h-max",
}) => {
  const navigate = useNavigate();
  return (
    <ErrorBoundary>
      <div
        className="min-h-screen max-h-full pb-5"
        style={{
          background: `url(${BusinessDetailsBG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="py-6 pt-10">
          <img src={AFBLogo} alt="" className="mx-auto" />
        </div>
        <div className="w-full xs:w-3/4 sm:w-2/3 max-w-[1500px] min-h-[60vh] lg:min-h-max lg:w-[60vw] mx-auto bg-white md:rounded-2xl py-4 px-6  relative">
          <div className="flex justify-between items-center mt-4 md:px-5">
            <div
              className={`relative cursor-pointer`}
              onClick={(e) => {
                e.preventDefault();
                isFirstTime ? navigate("/signin") : navigate(-1);
              }}
              id="back_btn"
            >
              <div className={`${!showBackButton && "hidden"}`}>
                <img src={BackArrowIcon} alt="<" />
              </div>
            </div>
            <div
              className="font-bold text-center md:pt-5 sm:pt-0 text-xl md:text-3xl 2xl:text-5xl leading-[120%] tracking-tighter"
              style={{ color: "#333333" }}
            >
              {heading}
            </div>
            <div className={`relative`}>
              <div
                className={`
                        ${!showProgress ? "hidden" : "hidden md:flex"} 
                        `}
              >
                <CircularProgressBar
                  num={num}
                  deNum={deNum}
                  size={50}
                  strokeWidth={4}
                  textFont="text-normal"
                />
              </div>
              <div
                className={`
                        ${!showProgress ? "hidden" : "flex md:hidden"}`}
              >
                <CircularProgressBar
                  num={num}
                  deNum={deNum}
                  size={35}
                  strokeWidth={2.5}
                  textFont="text-xs"
                />
              </div>
            </div>
          </div>

          <div className={containerStyle}>
            <div
              className={`${subTextStyle} text-center mb-10 2xl:text-xl text-sm`}
              style={{ color: "#606060" }}
            >
              {subHeading}
            </div>
            <div className="flex flex-col gap-3">{children}</div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
