/* eslint-disable react-hooks/exhaustive-deps */
import { FormikButton, TextInput } from "alat-components-library";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PageLoader from "components/Loader/PageLoader";
import { Form, Formik } from "formik";
import { useResubmitRejectedDocuments } from "hooks/apis/pendingActions/useResubmitRejectedDocuments";
import PendingActionsWrapper from "pages/PendingActions/RejectedDocuments/components/PendingActionsWrapper";
import { tinSchema } from "pages/PendingActions/RejectedDocuments/validation/pendingActionsValidation";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Checks the state before destructuring occurs
const TinUpload = () => {
  const location = useLocation();
  if (!location?.state?.pendingData) {
    window.location.replace("/user-dashboard");
    return <div></div>;
  }
  return <TinUploadInner />;
};

const TinUploadInner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    fetchLoading: submitLoading,
    resubmittedResponse,
    handleResubmittedDocuments,
  } = useResubmitRejectedDocuments();

  const {
    reason,
    cacRegNumber,
    pendingActionType,
    actionData,
    pendingActionId,
  } = location?.state?.pendingData;

  const previousSubmission = actionData;
  const prevTin: string = previousSubmission;

  const handleSubmit = (values: any) => {
    const payload = values?.tin;
    const payloadString = JSON.stringify(payload);
    try {
      handleResubmittedDocuments({
        pendingAction: pendingActionType,
        pendingActionId,
        cacRegNumber,
        actionData: payloadString,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (resubmittedResponse) {
      navigate("/pending-actions/successful-resubmission", {
        state: { title: "TIN" },
      });
    }
  }, [resubmittedResponse]);

  return (
    <DashboardWrapper>
      {submitLoading && <PageLoader message="Submitting TIN" />}
      <PendingActionsWrapper cardTitle="TIN">
        <div className="w-full max-w-[700px] mx-auto">
          <div className="md:w-full h-full">
            <div className="w-full px-10 mx-auto">
              <div className="bg-[#A90836]/10 text-lib-alat-dark-red p-2 rounded-md text-sm my-2">
                {reason?.[0]}
              </div>
              {/* {}children */}
              <Formik
                initialValues={{ tin: prevTin || "" }}
                onSubmit={handleSubmit}
                validateOnBlur
                validationSchema={tinSchema}
              >
                {({
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                  isValid,
                  touched,
                  dirty,
                  resetForm,
                  setFormikState,
                }) => (
                  <Form className="w-full flex flex-col gap-4 mb-5">
                    <TextInput
                      name="tin"
                      label="TIN"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values?.tin}
                      error={touched.tin && errors.tin ? errors.tin : ""}
                    />
                    <div>
                      <FormikButton
                        id="proceed_btn"
                        labelText="Proceed"
                        containerVariant="w-full h-10 rounded-md flex justify-center"
                        isDisabled={!isValid}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </PendingActionsWrapper>
    </DashboardWrapper>
  );
};

export default TinUpload;
