import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GET_ACCOUNT_CARDS_ACTION,
  POST_SUBMIT_CARD_REQUEST_ACTION,
  POST_SUBMIT_CARD_ACTIVATION_ACTION,
  GET_CARD_HOTLIST_REASON_ACTION,
  POST_SUBMIT_CARD_HOTLIST_ACTION,
  POST_SUBMIT_CARD_CHANGE_PIN_ACTION,
} from "./actions";
import { RootState } from "../store";
import { useAppSelector } from "../../../../hooks/useReduxHook";
import {
  triggerCardActivation,
  triggerCreateCardRequest,
  triggerGetCardAccounts,
  triggerGetCardHotlistReason,
  triggerCardHotlist,
  triggerCardChangePin,
} from "./assit-functions";
import { removeLoadingState } from "../../../../helpers/assitFunctions";
import { CardState } from "../interfaces";

const dataObject = {
  loading: [],
  activeCardRequestScreen: 0,
  hotlist_reasons: [],
  amount: [
    {
      cardFees: 1000,
      vat: 75,
      deliveryFee: 0,
    },
    {
      cardFees: 1000,
      vat: 75,
      deliveryFee: 1000,
    },
  ],
  cardRequestPayload: {
    deliveryOption: 1,
    totalDebitAmount: 0,
  },
  cardsForActivation: [],
  activeCards: [],
  selectedCardForActivation: {},
  selectedActiveCard: {},
  statusMessage: "",
} as unknown as CardState;

export const initialState: any = { ...dataObject };

export const cardSlice = createSlice({
  name: "cards",
  initialState,

  reducers: {
    resetCardsData: (): any => initialState,

    setLoadingState: (state: any, action: PayloadAction<any>): void => {
      state.loading = action.payload;
    },

    setActiveCardRequestScreen: (
      state: any,
      action: PayloadAction<any>
    ): void => {
      state.activeCardRequestScreen = action.payload;
    },

    setCardRequestPayload: (state: any, action: PayloadAction<any>): void => {
      state.cardRequestPayload = action.payload;
    },

    setCustomerCardsForActivation: (state: any, action: PayloadAction<any>) => {
      state.cardsForActivation = action.payload;
    },
    setSelectedCardForActivation: (state: any, action: PayloadAction<any>) => {
      state.selectedCardForActivation = action.payload;
    },
    setActiveCustomerCards: (state: any, action: PayloadAction<any>) => {
      state.activeCards = action.payload;
    },
    setSelectedActiveCard: (state: any, action: PayloadAction<any>) => {
      state.selectedActiveCard = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      //POST_SUBMIT_CARD_REQUEST_ACTION
      .addCase(POST_SUBMIT_CARD_REQUEST_ACTION.pending, (state) => {
        state.loading = ["POST_SUBMIT_CARD_REQUEST"];
      })
      .addCase(
        POST_SUBMIT_CARD_REQUEST_ACTION.fulfilled,
        (state, { payload }): void => triggerCreateCardRequest(state, payload)
      )
      .addCase(
        POST_SUBMIT_CARD_REQUEST_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_SUBMIT_CARD_REQUEST"
          );
        }
      )
      //POST_SUBMIT_CARD_ACTIVATION_ACTION
      .addCase(POST_SUBMIT_CARD_ACTIVATION_ACTION.pending, (state) => {
        state.loading = ["POST_SUBMIT_CARD_ACTIVATION"];
      })
      .addCase(
        POST_SUBMIT_CARD_ACTIVATION_ACTION.fulfilled,
        (state, { payload }): void => triggerCardActivation(state, payload)
      )
      .addCase(
        POST_SUBMIT_CARD_ACTIVATION_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_SUBMIT_CARD_ACTIVATION"
          );
        }
      )
      .addCase(GET_ACCOUNT_CARDS_ACTION.pending, (state) => {
        state.loading = ["GET_ACCOUNT_CARDS"];
      })
      .addCase(GET_ACCOUNT_CARDS_ACTION.fulfilled, (state, { payload }): void =>
        triggerGetCardAccounts(state, payload)
      )
      //GET_CARD_HOTLIST_REASON_ACTION
      .addCase(GET_CARD_HOTLIST_REASON_ACTION.pending, (state) => {
        state.loading = ["GET_CARD_HOTLIST_REASON"];
      })
      .addCase(
        GET_CARD_HOTLIST_REASON_ACTION.fulfilled,
        (state, action): void => triggerGetCardHotlistReason(state, action)
      )
      .addCase(
        GET_CARD_HOTLIST_REASON_ACTION.rejected,
        (state, action): void => {
          state.loading = removeLoadingState(
            state.loading,
            "GET_CARD_HOTLIST_REASON"
          );
        }
      )
      //POST_SUBMIT_CARD_HOTLIST_ACTION
      .addCase(POST_SUBMIT_CARD_HOTLIST_ACTION.pending, (state) => {
        state.loading = ["POST_SUBMIT_CARD_HOTLIST"];
      })
      .addCase(
        POST_SUBMIT_CARD_HOTLIST_ACTION.fulfilled,
        (state, { payload }): void => triggerCardHotlist(state, payload)
      )
      .addCase(
        POST_SUBMIT_CARD_HOTLIST_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_SUBMIT_CARD_HOTLIST"
          );
        }
      )
      //POST_SUBMIT_CARD_CHANGE_PIN_ACTION
      .addCase(POST_SUBMIT_CARD_CHANGE_PIN_ACTION.pending, (state) => {
        state.loading = ["POST_SUBMIT_CARD_CHANGE_PIN"];
      })
      .addCase(
        POST_SUBMIT_CARD_CHANGE_PIN_ACTION.fulfilled,
        (state, { payload }): void => triggerCardChangePin(state, payload)
      )
      .addCase(
        POST_SUBMIT_CARD_CHANGE_PIN_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_SUBMIT_CARD_CHANGE_PIN"
          );
        }
      );
  },
});

// Selectors
const selectCard = (state: RootState) => state.card;

// Reducers and actions
export const {
  resetCardsData,
  setLoadingState,
  setActiveCardRequestScreen,
  setCardRequestPayload,
  setCustomerCardsForActivation,
  setSelectedCardForActivation,
  setActiveCustomerCards,
  setSelectedActiveCard,
} = cardSlice.actions;

//App Redux State
export const useCardState = () => useAppSelector(selectCard);

export default cardSlice.reducer;
