import PageLoader from "components/Loader/PageLoader";
import { useFetchNotificationDetails } from "hooks/apis/Notifications/useFetchNotificationDetails";
import { useAppDispatch } from "hooks/useReduxHook";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useNotificationState } from "react-wrappers/stores/redux/notifications/slices";
import { NotificationStatusEnum, NotificationTypeEnum } from "utils/enums";
import { ReactSuspenceWrapper } from "../../alat-components-library";
import DashboardWrapper from "../../components/Layouts/DashboardWrapper";
import { PendingTransactionDetail } from "./components/PendingTransactionDetail";
import { ViewSoftTokenRequestDetails } from "./components/SoftTokenDetails";
import { ViewTransactionDetails } from "./components/TransactionsDetails";
import { ViewUserDetails } from "./components/UserDetails";
import { ViewStampedAccountStatement } from "./components/StampedAccountStatement";

export const ViewNotificationDetails = () => {
  const dispatch = useAppDispatch();
  const { loading } = useNotificationState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const correlationId = searchParams.get("correlationId");
  const notificationsType = searchParams.get("notificationsType");
  const status = searchParams.get("status");
  const {
    handleFetchUserDetails,
    handleFetchTransactionDetails,
    handleFetchSoftTokenDetails,
    handleFetchStampedStatementDetails,
  } = useFetchNotificationDetails();

  const isLoading = loading?.includes("fetch-notification-details");

  useEffect(() => {
    fetchNotificationsDetails();
  }, []);

  const fetchNotificationsDetails = () => {
    if (notificationsType == NotificationTypeEnum["Transaction"]) {
      handleFetchTransactionDetails(correlationId);
    }
    if (notificationsType == NotificationTypeEnum["StampedStatement"]) {
      handleFetchStampedStatementDetails(correlationId);
    }
    if (
      notificationsType == NotificationTypeEnum["UserCreation"] ||
      notificationsType == NotificationTypeEnum["UserModification"]
    ) {
      const payload = {
        correlationId,
        notificationType: notificationsType,
      };
      handleFetchUserDetails(payload);
    }
    if (notificationsType == NotificationTypeEnum["SoftTokenRequest"]) {
      handleFetchSoftTokenDetails(correlationId);
    }
  };
  const renderTransactionScreen = () => {
    if (
      notificationsType == NotificationTypeEnum["Transaction"] &&
      status == NotificationStatusEnum["Successful"]
    ) {
      return;
    }
  };
  return (
    <>
      {isLoading && <PageLoader message="Please wait..." />}

      <ReactSuspenceWrapper>
        <DashboardWrapper>
          {notificationsType == NotificationTypeEnum["Transaction"] &&
            status == NotificationStatusEnum["Successful"] && (
              <ViewTransactionDetails />
            )}
          {notificationsType == NotificationTypeEnum["Transaction"] &&
            status !== NotificationStatusEnum["Successful"] && (
              <PendingTransactionDetail />
            )}
          {(notificationsType == NotificationTypeEnum["UserCreation"] ||
            notificationsType == NotificationTypeEnum["UserModification"]) && (
            <ViewUserDetails />
          )}
          {notificationsType == NotificationTypeEnum["SoftTokenRequest"] && (
            <ViewSoftTokenRequestDetails />
          )}
          {notificationsType == NotificationTypeEnum["StampedStatement"] && (
            <ViewStampedAccountStatement />
          )}
        </DashboardWrapper>
      </ReactSuspenceWrapper>
    </>
  );
};
