import { useBankLists } from "hooks/apis/Payment/useBankLists";
import { useNIPCharges } from "hooks/apis/Payment/useNIPCharges";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import { useEffect, useRef, useState } from "react";
import {
  GET_BENEFICIARY_LIST_ACTION,
  GET_GROUP_LIST_ACTION,
} from "react-wrappers/stores/redux/payments/actions";
import { ISavedGroupList, SavedBeneficiaryList } from "../mutipleTransfer";
import { TabsRef } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useAccountsState } from "react-wrappers/stores/redux/accounts/slices";
import { setRegularTransferBeneficiaries } from "react-wrappers/stores/redux/payments/slices";

export const useBankListAndNIPChargeController = () => {
  // Global State
  const dispatch = useAppDispatch();

  // Ref
  const tabsRef = useRef<TabsRef>(null);

  //Local State
  const { bankLists, handleGetBankList } = useBankLists();
  const { nipCharges, handleGetNIPCharges } = useNIPCharges();
  const [savedGroupList, setSavedGroupList] = useState<ISavedGroupList[]>([]);
  const [savedBeneficiaryList, setSavedBeneficiaryList] = useState<
    SavedBeneficiaryList[]
  >([]);

  const callEndpoints = async () => {
    try {
      handleGetBankList();
      handleGetNIPCharges();
      const payload = {
        pageSize: 10,
        pageNumber: 1,
      };

      dispatch(GET_BENEFICIARY_LIST_ACTION(payload))
        // .unwrap()
        // .then((res: any) => res.data.httpStatusCode === 200 && setSavedBeneficiaryList(res?.data?.data?.beneficiaries))
        .then(
          (res: any) =>
            res.data.httpStatusCode === 200 &&
            dispatch(
              setRegularTransferBeneficiaries(
                res?.data?.data?.beneficiaries || []
              )
            )
        )
        .catch((e: any) => {});

      dispatch(GET_GROUP_LIST_ACTION())
        .unwrap()
        .then(
          (res: any) =>
            res.data.httpStatusCode === 200 &&
            setSavedGroupList(res?.data?.data?.transferGroups)
        )
        .catch((e: any) => {
          // console.log(e);
        });
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    callEndpoints();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (window.location.hash === "#1") tabsRef.current?.setActiveTab(1);
    if (window.location.hash === "#2") tabsRef.current?.setActiveTab(2);
  }, []);

  return {
    tabsRef,
    bankLists,
    nipCharges,
    savedGroupList,
    savedBeneficiaryList,
  };
};

export const useMultipleTransferTabController = () => {
  // Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authState = useAuthState();
  const accounts = useAccountsState();

  const [activeTab, setActiveTab] = useState<any>(0);

  // const userAccounts = useAppSelector((state) => state.accounts);
  const allAccounts =
    accounts?.allAccounts?.length > 0
      ? accounts?.allAccounts
      : authState?.user?.accounts;

  return {
    activeTab,
    setActiveTab,
    dispatch,
    navigate,
    authState,
    allAccounts,
  };
};
