import React, { forwardRef } from "react";
import { InputType } from "./types";
import ErrorIcon from "../../assets/svgs/error-icon.svg";
import Preloader from "../../alat-components-library/Mics/Preloader";
import { currencies } from "utils/enums";
import { moneyInputFormat } from "utils/helperFunctions";

export const AmountInput = forwardRef<HTMLInputElement, InputType>(
  (
    {
      id = "",
      autoFocus = false,
      currency = currencies?.NGN,
      containerVariant = "w-full flex flex-col",
      name,
      label,
      handleChange,
      handleBlur,
      hasError = false,
      value = "",
      error,
      placeHolder = "Enter text",
      variant = "border text-black w-full h-12 text-lg px-6",
      isDisabled = false,
      isLoading = false,
      showCurrency = true,
    },
    ref
  ) => {
    // Handle the value transformation to avoid breakage
    const handleValueChange = (event: any) => {
      // eslint-disable-next-line no-param-reassign
      const result = moneyInputFormat(event.target.value);
      // console.log("result", result)
      if (result) {
        event.target.value = result.withMoneyFormat.split(",").join("");
        handleChange(event);
      }
    };

    return (
      <div className={`${containerVariant}`}>
        {label && (
          <label
            htmlFor={`input-${name}`}
            className={`text-sm mb-2.5
              ${isDisabled ? "text-gray-300" : "text-black"}`}
          >
            {label}
          </label>
        )}
        <div className="relative">
          {showCurrency && (
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 text-[#C4C4C4] pointer-events-none">
              <span>{currency}</span>
            </div>
          )}

          <input
            id={id}
            ref={ref}
            name={name}
            type={"text"}
            className={` pl-10 
        focus:outline-none text-sm  focus:border-lib-alat-gray-input-border z-2 focus:bg-white ovtline-none placeholder:text-sm rounded-md
        ${
          isDisabled
            ? `cursor-not-allowed border-gray-100 bg-gray-50 placeholder:text-gray-300`
            : "bg-lib-alat-gray-input placeholder:text-gray-300 border-lib-alat-gray-input-border"
        }
        ${hasError && "border-red-500 focus:border-red-500"}
        ${variant}`}
            value={moneyInputFormat(value)?.withMoneyFormat}
            onChange={(e) => handleValueChange(e)}
            onBlur={handleBlur}
            placeholder={placeHolder}
            disabled={isDisabled}
            autoFocus={autoFocus}
          />
          {isLoading && (
            <div className="relative">
              <span className={`absolute text-lib-alat-dark-red`}>
                <Preloader variant="w-6 h-6" />
              </span>
            </div>
          )}
        </div>
        {hasError && (
          <div className="flex gap-2">
            <img src={ErrorIcon} alt="Error" />
            <p className="text-red-500 font-[Inter-Regular] text-xs h-auto py-1">
              {error}
            </p>
          </div>
        )}
      </div>
    );
  }
);
