import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useLoginController } from "controllers/Login/LoginController";
import { Button, Card, Label } from "flowbite-react";
import { useEffect } from "react";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { generateChargeTwo } from "utils/helperFunctions";
import { ReusableCard } from "components/reusables/Page/ReusableCard";

// import { ReactComponent as BankIcon } from "../../../../assets/svgs/bank-building.svg";

interface INewTransferComponent3Props {
  accountToDebit: any;
  beneficiaryName: any;
  accountNumber: any;
  amount: any;
  nipCharges: any;
  selectedBank: any;
  pin: any;
  setPin: any;
  isPinValidated: any;
  authToken: any;
  setAuthToken: any;
  handleSubmit: any;
  showPinError: string;
  pinError: string;
  allowSubmit: any;
  handleChange: any;
  handleScreenChangePrev: any;
  handleScreenChangeNext: any;
  handleValidate: any;
  responseCode?: any;
  selectedBeneficiary?: {
    bankCode: string;
    bankName: string;
    accountNumber: string;
    accountName: string;
    currency: string;
  };
}
const NewTransferComponent3 = ({
  accountToDebit,
  beneficiaryName,
  accountNumber,
  amount,
  nipCharges,
  selectedBank,
  pin,
  setPin,
  authToken,
  setAuthToken,
  handleSubmit,
  isPinValidated,
  showPinError,
  pinError,
  allowSubmit,
  handleChange,
  handleScreenChangePrev,
  handleScreenChangeNext,
  handleValidate,
  selectedBeneficiary,
}: // responseCode,
INewTransferComponent3Props) => {
  const { authState } = useLoginController();
  const paymentState = usePaymentState();
  let authType;

  if (amount.withNumberFormat <= 2000000) {
    authType = "pinOtp";
  }
  if (amount.withNumberFormat > 2000000) {
    if (authState?.user?.hasSoftToken) {
      authType = "softToken";
    } else {
      authType = "hardTokenPin";
    }
  }
  if (amount.withNumberFormat <= 2000000 && paymentState?.responseCode === 21) {
    if (authState?.user?.hasSoftToken) {
      authType = "softToken";
    } else {
      authType = "hardTokenPin";
    }
  }
  if (
    amount.withNumberFormat <= 2000000 &&
    authState?.user?.hasSoftToken === false &&
    authState?.user?.hasTransactionPin === false
  ) {
    authType = "hardTokenPin";
  }

  if (
    authState?.user?.hasSoftToken &&
    authState?.user?.hasTransactionPin === false
  ) {
    authType = "softToken";
  }

  const transactionFee = generateChargeTwo(amount.withNumberFormat);

  useEffect(() => {
    setPin("");
    setAuthToken("");
  }, []);

  return (
    <div className="w-full flex items-center justify-center">
      <ReusableCard
        variant="md:w-[768px] w-full"
        cardTitle="Review Transfer"
        headingId=""
        handleBackNavigation={() => {
          setPin("");
          setAuthToken("");
          handleScreenChangePrev();
        }}
        showGoBackIcon
      >
        <div className="w-full flex items-center justify-center">
          <form className="flex flex-col md:w-[400px] w-full gap-4 justify-center items-center mt-4">
            <div className="text-[#666666] mb-4">
              Complete transaction details below
            </div>
            <div className="w-full">
              <div className="text-[#666666] mb-2 text-sm font-normal">
                Transfer from
              </div>
              <Card
                className="box-border w-full h-[114px] rounded-lg dark:bg-white dark:border-[#EDEDED] border-[#EDEDED]"
                id="beneficiarydetails_card"
              >
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <div className="text-base">
                      {accountToDebit?.accountDescription}
                    </div>
                    <div className="text-xs" id="account-number_text">
                      {accountToDebit.accountNo}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-right">Balance</div>
                    <div className="text-base text-right" id="balance_text">
                      ₦{parseFloat(accountToDebit.balance).toLocaleString()}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="mt-4 w-full">
              <div className="text-[#666666] mb-2 text-sm font-normal">
                Transfer to
              </div>
              <Card className="box-border w-full h-[150px] rounded-lg text-white bg-[image:url('./assets/svgs/card-background.svg')] bg-no-repeat bg-cover">
                <div className="flex flex-row justify-between items-center">
                  <div className="">
                    <div className="text-xs">Account Name</div>
                    <div className="text-xs" id="nameofbeneficiary_text">
                      {beneficiaryName === ""
                        ? selectedBeneficiary?.accountName
                        : beneficiaryName}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-right">Amount</div>
                    <div className="text-xs text-right" id="amount_text">
                      ₦{amount.withMoneyFormat}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <div className="text-xs">Account Number</div>
                    <div className="text-xs" id="account-number_text">
                      {accountNumber === ""
                        ? selectedBeneficiary?.accountNumber
                        : accountNumber}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-right">Bank</div>
                    <div className="text-xs text-right" id="selected-bank_text">
                      {selectedBank?.bankName === ""
                        ? selectedBeneficiary?.bankName
                        : selectedBank?.bankName}
                    </div>
                  </div>
                </div>
              </Card>

              {(selectedBank?.bankCode === ""
                ? selectedBeneficiary?.bankCode !== "035"
                : selectedBank?.bankCode !== "035") && (
                <div className="text-red-600 mt-2 text-sm font-normal">
                  <span>
                    You will be charged ₦
                    <span id="transaction-fee_text">{`${transactionFee}`}</span>{" "}
                    for this transaction
                  </span>
                </div>
              )}
            </div>
            {authType === "pinOtp" && (
              <div className="relative w-full mt-2 mb-8">
                <div className="mb-2 block text-left">
                  <Label
                    htmlFor="pin"
                    value="Enter PIN"
                    className=" text-[#464646] dark:!text-[#464646] font-normal text-sm"
                  />
                </div>

                <input
                  id="pin_input"
                  name="pin"
                  type="password"
                  data-auth-type={authType}
                  className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                  value={pin}
                  onChange={handleChange}
                  maxLength={4}
                />
                {showPinError === "yes" && (
                  <div className="text-red-700">{pinError}</div>
                )}
              </div>
            )}
            {authType === "softToken" && (
              <div className="relative w-full mt-2 mb-8">
                <div className="mb-2 block text-left">
                  <Label
                    htmlFor="authToken"
                    value="Enter soft token"
                    className=" !text-[#666666] mb-2 !text-sm !font-normal"
                  />
                </div>

                <input
                  id="softToken_input"
                  name="authToken"
                  type="password"
                  data-auth-type={authType}
                  className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                  value={authToken}
                  onChange={handleChange}
                  maxLength={6}
                />
                {showPinError === "yes" && (
                  <div className="text-red-700">{pinError}</div>
                )}
              </div>
            )}
            {authType === "hardTokenPin" && (
              <div className="relative w-full mt-2 mb-8">
                <div className="mb-2 block text-left">
                  <Label
                    htmlFor="pin"
                    value="Enter hard token PIN"
                    className=" !text-[#666666] mb-2 !text-sm !font-normal"
                  />
                </div>

                <input
                  id="hardToken-pin_input"
                  name="pin"
                  type="password"
                  data-auth-type={authType}
                  className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                  value={pin}
                  onChange={handleChange}
                  maxLength={4}
                />
                {/* {showPinError === "yes" && (
                  <div className="text-red-700">{pinError}</div>
                )} */}

                <div className="mb-2 mt-6 block text-left">
                  <Label
                    htmlFor="authToken"
                    value="Enter hard token"
                    className=" !text-[#666666] mb-2 !text-sm !font-normal"
                  />
                </div>

                <input
                  id="hardToken_input"
                  name="authToken"
                  type="password"
                  data-auth-type={authType}
                  className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary "
                  value={authToken}
                  onChange={handleChange}
                  maxLength={6}
                />
              </div>
            )}
            {authType === "pinOtp" && (
              <Button
                type="button"
                className="bg-primary hover:bg-transparent hover:border-primary mb-20  w-[188px]  hover:bg-primary focus:ring-1 focus:ring-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                onClick={handleValidate}
                disabled={!allowSubmit && authType === "pinOtp"}
                id="reviewtransfercont_btn"
              >
                Continue
              </Button>
            )}
            {authType === "hardTokenPin" && (
              <Button
                type="button"
                className="bg-primary hover:bg-transparent hover:text-primary hover:border-primary mb-20 mt-10 w-[188px] hover:bg-primary focus:ring-1 focus:ring-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                onClick={handleSubmit}
                disabled={
                  !allowSubmit ||
                  !authToken ||
                  (!authState?.user?.hasSoftToken && !pin)
                }
                id="submit_btn"
              >
                Submit
              </Button>
            )}
            {authType !== "pinOtp" && authType !== "hardTokenPin" && (
              <Button
                type="button"
                className="bg-primary hover:bg-transparent hover:text-white hover:border-primary mb-20 mt-10 w-[188px] hover:bg-primary focus:ring-1 focus:ring-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                onClick={handleSubmit}
                disabled={!allowSubmit}
                id="reviewtransfercont2_btn"
              >
                Submit
              </Button>
            )}
          </form>
        </div>
      </ReusableCard>
      {/* <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <div
              id="back_btn"
              onClick={() => {
                setPin("");
                setAuthToken("");
                handleScreenChangePrev();
              }}
              className="cursor-pointer"
            >
              <ChevronLeftIcon className="h-6 w-6 text-[#333333]" />
            </div>
            <div className="text-[#4D4D4D] text-xl text-center font-semibold font-inter ">
              Review Transfer
            </div>
            <div></div>
          </div>
        </div>
        <hr />
      </Card> */}
    </div>
  );
};

// {
//   showScreen === "main" ? (
//     <div>Main</div>
//   ) : showScreen == "successful" ? (
//     <div>Successful</div>
//   ) : showScreen == "failed" ? (
//     <div>Failed</div>
//   ) : (
//     <div>Pending</div>
//   );
// }
export default NewTransferComponent3;
