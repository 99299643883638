import { useState, useEffect } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card, Label } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, validateYupSchema } from "formik";
import { FormikButton, TextInput } from "alat-components-library";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import { paybillValidationSchema } from "utils/validations/payment";
import { usePayBillController } from "controllers/Payments/Paybill";
import {
  useCollectionsState,
  setValidationReference,
  setSummaryFields,
} from "react-wrappers/stores/redux/collections/slices";
import PageLoader from "components/Loader/PageLoader";
import { useGetCollections } from "hooks/apis/Collections/useGetCollections";
import {
  CollectionDataType,
  CollectionFieldType,
  CollectionFormType,
  CollectionType,
} from "utils/enums/collections";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import * as yup from "yup";
import {
  createYupSchema,
  dynamicFormValidationSchema,
} from "./Validation/DynamicForm.Validation";
import { useAppDispatch } from "hooks/useReduxHook";

const CollectionForm = () => {
  // Local State
  const { dynamicFormItems, loading, activeSelectedCollectionMerchantItem } =
    useCollectionsState();
  const { handleFetchDynamicFormNext, handlePostValidation, postValidation } =
    useGetCollections();
  const [isPackageAmountEditable, setIsPackageAmountEditable] = useState(false);
  const [formToRender, setFormToRender] = useState(
    dynamicFormItems?.groupedEndpointResponseDetails ?? []
  );
  const [currentForm, setCurrentForm] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [summaryfields, setUpdateSummaryFields] = useState<any>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    user: { emailAddress },
  } = useAuthState();

  useEffect(() => {
    setCurrentForm(
      formToRender[currentStep]?.endpointFieldViewModels?.filter(
        (f: any) =>
          f?.dataTypeId !== CollectionDataType["PlaceHolder"] &&
          f?.fieldType !== CollectionFieldType["Response"]
      )
    );
  }, [currentStep, formToRender]);

  useEffect(() => {
    setFormToRender(dynamicFormItems?.groupedEndpointResponseDetails ?? []);
  }, [dynamicFormItems]);

  const handleSubmit = (payload: any) => {
    let validationItems = formToRender[
      currentStep
    ]?.endpointFieldViewModels?.map((item: any) => {
      return {
        ...item,
        inputValue: payload[item.fieldName],
        isSummaryField: item.isSummaryField === 1 ? true : false,
      };
    });

    let isSummaryFilter = validationItems
      ?.filter((item: any) => item.isSummaryField)
      .map((item: any) => {
        return { fieldName: item.fieldName, fieldValue: item.inputValue };
      });

    setUpdateSummaryFields((prevState: any) => [
      ...prevState,
      ...isSummaryFilter,
    ]);

    dispatch(setValidationReference(dynamicFormItems?.validationReference));

    const data = {
      endpointId: formToRender[currentStep]?.endpointId,
      collectionTypeId: CollectionType["Advanced"],
      currentUserEmail: emailAddress,
      validationReference: dynamicFormItems?.validationReference,
      fields: validationItems,
    };
    handlePostValidation(data);
  };

  useEffect(() => {
    if (postValidation?.isValid) {
      const formItem = formToRender[currentStep];
      if (formItem.hasNextStep && formToRender.length > currentStep + 1) {
        setCurrentStep(currentStep + 1);
      } else {
        dispatch(setSummaryFields(summaryfields));
        navigate(`/bills/collections/payment`);
      }
    }
  }, [postValidation]);

  const initialValues: any = {};
  formToRender[currentStep]?.endpointFieldViewModels?.forEach((item: any) => {
    initialValues[item?.fieldName] = "";
  });

  let yepSchema = currentForm?.reduce(createYupSchema, {});

  const validationSchema = yup.object().shape(yepSchema);

  const isLoading =
    loading.includes("fetch-dynamic-form") ||
    loading.includes("post-form-validation");

  return (
    <div className="w-full">
      {!!isLoading && (
        <PageLoader message={`Validating details, please wait...`} />
      )}
      <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              onClick={() => navigate(-1)}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            />
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="banktransfer_heading"
            >
              Collection Form Step {currentStep + 1}
            </div>
            <div></div>
          </div>
        </div>
        <hr />
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnChange
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form>
              <div>
                <div className="flex flex-wrap justify-center gap-4 ">
                  <div className="text-alat-grey relative w-full lg:max-w-sm mb-2">
                    {currentForm
                      ?.sort((a: any, b: any) => a.piority - b.piority)
                      ?.map((f: any) => {
                        switch (f?.dataTypeId) {
                          case CollectionDataType["List"]:
                            return (
                              <div
                                key={f?.fieldName}
                                className="relative w-full lg:max-w-sm mb-4"
                              >
                                <SelectDrop
                                  id={`${f.fieldName}`}
                                  name={`${f.fieldName}`}
                                  label={`${f.fieldName}`}
                                  idList="accountStatement-account_text"
                                  variant="h-12 w-full text-sm"
                                  placeholder="--Select--"
                                  value={values[f?.fieldName]}
                                  options={[]}
                                  onChange={handleChange}
                                  error={`${f.fieldName} is required`}
                                />
                              </div>
                            );
                          default:
                            return (
                              <div
                                key={f?.fieldName}
                                className="relative w-full lg:max-w-sm mb-4"
                              >
                                <TextInput
                                  id={`${f.fieldName}`}
                                  name={`${f.fieldName}`}
                                  type={`${CollectionFormType[f?.dataTypeId]}`}
                                  label={`${f.fieldName}`}
                                  placeHolder={""}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  value={values[f?.fieldName]}
                                  hasError={
                                    errors[f?.fieldName] &&
                                    touched[f?.fieldName]
                                  }
                                  error={`${f.fieldName} is required`}
                                />
                              </div>
                            );
                        }
                      })}
                  </div>

                  <div className="relative w-full  flex items-center justify-center">
                    <FormikButton
                      id="proceed"
                      labelText="Proceed"
                      containerVariant={
                        "w-full lg:max-w-sm h-[7vh] my-[5vh] rounded-md flex justify-center items-center"
                      }
                      isDisabled={!isValid && !dirty}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default CollectionForm;
