import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as FourUserIcon } from "../../assets/svgs/airtimeIcon.svg";
import { airtimeOTPValidationSchema } from "../AirtimeAndData/BuyAirtime/airtimeValidation/airtimeValidation";
import { FormikButton } from "alat-components-library";
import { Formik, Form } from "formik";
import { ReactComponent as RefreshIcon } from "../../assets/svgs/refresh-rounded.svg";
import {
  useAirtimeHandleRefresh,
  useAirtimeOTPRequest,
} from "hooks/apis/AirtimeAndData/useAirtimePresetAmount";
import { TransactionTypeEnum } from "utils/enums";
import PageLoader from "components/Loader/PageLoader";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { useCompleteBillPaymentWithOtp } from "hooks/apis/Payment/BillsPayment/useCompleteBillPaymentWithOtp";
import { useResendOtp } from "hooks/apis/Payment/BillsPayment/useResendOtp";
const BillsOtp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loading, validateBillResponse, otpTransactionType } =
    usePaymentState();
  const AirtimeData = location?.state?.AirtimeRequestData;

  const { handleBillPayment } = useCompleteBillPaymentWithOtp();
  const { handleResendOtp } = useResendOtp();

  const handleAirtimeOTPSubmit = (e: any) => {
    handleBillPayment(
      {
        code: e.otp,
        correlationId: validateBillResponse?.correlationId,
        // correlationId: "3fa6df8e-f27b-400c-87c6-4cac5f385233",
        transactionType:
          otpTransactionType || TransactionTypeEnum.SingleSignatoryBillPayment,
      },
      "/bills/payment/pay-bill/status"
    );

    //  navigate('/bills/payment/pay-bill/otp');
  };
  const isLoading =
    loading?.includes("completing-bills-purchase") ||
    loading?.includes("resend-otp");
  return (
    <center>
      {isLoading && <PageLoader message="" />}
      <Card className="md:w-[768px] w-full col-span-3 bg-white pb-14  dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
        <div>
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              onClick={() => navigate(-1)}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            />
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="banktransfer_heading"
            >
              Verification
            </div>
            <div></div>
          </div>
        </div>
        <hr />

        <Formik
          initialValues={{
            otp: "",
          }}
          onSubmit={handleAirtimeOTPSubmit}
          validationSchema={airtimeOTPValidationSchema}
          validateOnChange
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form>
              <div>
                <div className="flex flex-wrap justify-center gap-4 ">
                  <div className="relative w-full lg:max-w-sm mb-2">
                    <div className="flex justify-center relative w-full lg:max-w-sm mb-6">
                      <FourUserIcon fill="inherit" />
                    </div>
                    <p justify-center w-full mb-6>
                      {AirtimeData?.responseMessage?.actionMessage}
                      {/* We just sent a verification code to your mobile number
                          <em>(+2348020****01)</em> */}
                    </p>

                    <div className="lg:max-w-sm w-full mb-6 space-y-4 mt-4">
                      <div className="w-full">
                        <input
                          name="otp"
                          type="text"
                          placeholder="Enter OTP"
                          className="pl-6 md:w-[390px] w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary"
                          maxLength={6}
                          onChange={handleChange}
                          value={values.otp}
                        />
                        <span className="text-sm text-red-600">
                          {errors?.otp}
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          handleResendOtp(validateBillResponse?.correlationId);
                          // handleResendOtp("3fa6df8e-f27b-400c-87c6-4cac5f385233");
                        }}
                        className="mb-2 cursor-pointer flex items-center justify-end gap-2 text-sm ml-1 text-right text-red"
                      >
                        <RefreshIcon />
                        <p>Resend OTP</p>
                      </div>
                    </div>
                  </div>

                  <div className="relative lg:max-w-sm w-full px-3  flex items-center justify-center">
                    <FormikButton
                      id="proceed-to-Airtime_Request"
                      labelText=" Complete transfer"
                      isDisabled={!isValid || isLoading}
                      containerVariant={`w-3/4 h-[48px] rounded-md flex justify-center item-center`}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </center>
  );
};

export default BillsOtp;
