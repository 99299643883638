import FolderIcon from "../../../assets/svgs/folder-icon.svg";

export const pendingData = [
  {
    key: 1,
    currentTab: 1,
    icon: FolderIcon,
    heading: "Complete Account Setup",
    info: "You have not completed your account setup",
    clickEvent: () => alert("Go To Pending Action1"),
  },
  {
    key: 2,
    currentTab: 2,
    icon: FolderIcon,
    heading: "Complete Account Setup",
    info: "You have not completed your account setup",
    clickEvent: () => alert("Go To Pending Action1"),
  },
  {
    key: 3,
    currentTab: 3,
    icon: FolderIcon,
    heading: "Complete Account Setup",
    info: "You have not completed your account setup",
    clickEvent: () => alert("Go To Pending Action1"),
  },
];
