import { useEffect, useState } from "react";
import html2pdf from "html2pdf.js";
import { useUploadBlobToAzure } from "hooks/apis/useUploadBlobToAzure";
import { staticHtmlString } from "pages/Home/ReferenceLetter/PDFGenerator";

interface GenerateReferenceLetterHookResult {
  blobUploadError: any;
  blobFiles: any;
  blobUrl: string;
  handleUploadBlob: any;
  imageUrlToBase64: (string: string) => void;
  isUploadingBlob: boolean;
  generateReferenceLetter: (props: GeneratePDFProps) => Promise<void>;
}

interface GeneratePDFProps {
  applicantName: string;
  duration: string;
  bankName: string;
  address: string;
  accountNo: string;
  phoneNumber: string;
  refereeName: string;
  refereeAddress: string;
  signature1: any;
  signature2?: string;
  date?: string;
  CacRegNumber: string;
}

export const useGenerateReferenceLetter =
  (): GenerateReferenceLetterHookResult => {
    const [blobUrl, setBlobUrl] = useState("");

    const { blobUploadError, blobFiles, handleUploadBlob, isUploadingBlob } =
      useUploadBlobToAzure("signature", "backoffice");

    useEffect(() => {
      if (blobFiles?.url) {
        // window.open(blobFiles?.url, '_blank');
        // console.log(blobFiles?.url, "Blob url");

        setBlobUrl(blobFiles?.url);
      }
    }, [blobFiles]);

    async function imageUrlToBase64(url: string): Promise<string> {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = url;
      await new Promise((resolve) => {
        img.onload = resolve;
      });
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d")!;
      ctx.drawImage(img, 0, 0);
      const base64 = canvas.toDataURL("image/jpeg");
      return base64;
    }

    const generateReferenceLetter = async ({
      applicantName,
      duration,
      bankName,
      address,
      accountNo,
      phoneNumber,
      refereeName,
      refereeAddress,
      signature1,
      signature2,
      CacRegNumber,
    }: GeneratePDFProps) => {
      // const pdfElement = document.getElementById('pdf');
      // if (!pdfElement) return;

      // console.log("I'm in: ", signature1);
      const newDate = Date.now();
      const date = newDate.toLocaleString();
      const staticHtml = staticHtmlString({
        applicantName,
        duration,
        bankName,
        address,
        accountNo,
        phoneNumber,
        refereeName,
        refereeAddress,
        signature1,
        signature2: signature1,
        date,
      });

      const options = {
        filename: "newPDF.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2pdf: { scale: 1 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      try {
        const pdfBlob = await html2pdf()
          .set(options)
          .from(staticHtml)
          .outputPdf("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        // console.log('PDF Blob URL: ', pdfUrl);
        const refereeFormName =
          "referee_form" +
          bankName +
          Math.floor(Math.random() * 20000) +
          ".pdf";
        const FILE_NAME = CacRegNumber ? "AFB_REFEREE" : "E_REFEREE";
        await handleUploadBlob(
          new File([pdfBlob], refereeFormName, { type: "application/pdf" }),
          FILE_NAME,
          CacRegNumber,
          "PDF",
          `ACC-${accountNo || ""}+${bankName || ""}`
        );
      } catch (error) {
        console.error("Error exporting PDF: ", error);
      }
    };

    return {
      blobUploadError,
      blobFiles,
      blobUrl,
      imageUrlToBase64,
      handleUploadBlob,
      isUploadingBlob,
      generateReferenceLetter,
    };
  };

export default useGenerateReferenceLetter;
