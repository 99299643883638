import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import {
  GET_AIRTIME_DATA_BENEFICIARY_LIST,
  GET_AIRTIME_PRESET_AMOUNT,
  GET_Data_Bundle,
  GET_HANDLE_REFRESH,
  GET_Network_Providers_Name,
  POST_OTP_AIRTIME,
  POST_REQUEST_AIRTIME,
  POST_REQUEST_DATA,
} from "../../../utils/apis/routes";
import {
  AirtimeOtpPayload,
  AirtimeRequestPayload,
  BillsBeneficiaryPayload,
  DataRequestPayload,
} from "types/airtimerequest";
import { useNavigate } from "react-router-dom";
import { ToastNotify } from "helpers/toastNotify";
import axiosStatus from "utils/apis/axios/errorStatus";
import { string } from "yup";

export const usePresetAmount = () => {
  //Local States
  const [presetAmountLoading, setpresetAmountLoading] =
    useState<boolean>(false);
  const [presetAmountData, setpresetAmountData] = useState<any>([]);
  const [presetAmountError, setpresetAmountError] = useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const handlePresetAmount = async () => {
    setpresetAmountLoading(true);
    try {
      const res = await makeRequest({
        method: "get",
        route: GET_AIRTIME_PRESET_AMOUNT,
        originType: "paymentservice",
      });

      setpresetAmountData(res?.data?.data?.amounts);

      setpresetAmountLoading(false);
    } catch (error) {
      setpresetAmountError(
        "An error occured while fetching branch sol ids, please referesh and try again"
      );
      setpresetAmountLoading(false);
    }
  };

  return {
    presetAmountData,
    presetAmountLoading,
    presetAmountError,
    handlePresetAmount,
  };
};
//Api endpoint for calling Network Provider
export const useNetworkSelection = () => {
  //Local States
  const [NetworkSelectionLoading, setNetworkSelectionLoading] =
    useState<boolean>(false);
  const [NetworkSelectionData, setNetworkSelectionData] = useState<any>([]);
  const [NetworkSelectionError, setNetworkSelectionError] =
    useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleNetworkSelection = async () => {
    setNetworkSelectionLoading(true);
    try {
      const res = await makeRequest({
        method: "get",
        route: GET_Network_Providers_Name,
        originType: "paymentservice",
      });
      setNetworkSelectionData(res?.data?.data?.networkProvidersName);

      setNetworkSelectionLoading(false);
    } catch (error) {
      setNetworkSelectionError(
        "An error occured while fetching branch sol ids, please referesh and try again"
      );
      setNetworkSelectionLoading(false);
    }
  };

  return {
    NetworkSelectionData,
    NetworkSelectionLoading,
    NetworkSelectionError,
    handleNetworkSelection,
  };
};
//Api endpoint for calling airtime data submission

//Api endpoint for calling airtime request
export const useAirtimeRequest = () => {
  const navigate = useNavigate();
  //Local States
  const [AirtimeRequestLoading, setAirtimeRequestLoading] =
    useState<boolean>(false);
  const [AirtimeRequestData, setAirtimeRequestData] = useState<any>("");
  const [AirtimeRequestError, setAirtimeRequestError] = useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleAirtimeRequest = async (payload: AirtimeRequestPayload) => {
    try {
      setAirtimeRequestLoading(true);
      setAirtimeRequestData("");
      const res = await makeRequest({
        method: "POST",
        route: POST_REQUEST_AIRTIME,
        body: payload,
        originType: "identity",
      });
      setAirtimeRequestLoading(true);
      if (res.status === 200) {
        setAirtimeRequestData(res?.data);
      } else {
        if (res?.data?.data?.hasError) {
          ToastNotify({
            type: "error",
            message: res?.data?.data?.responseMessage?.title,
            position: "top-right",
          });
        } else {
          axiosStatus(res?.data);
        }
      }
      setAirtimeRequestLoading(false);
    } catch (error) {
      setAirtimeRequestLoading(false);
    }
  };

  return {
    AirtimeRequestData,
    AirtimeRequestLoading,
    AirtimeRequestError,
    handleAirtimeRequest,
  };
};
//API Call for Data
export const useDataRequest = () => {
  const navigate = useNavigate();
  //Local States
  const [DataRequestLoading, setDataRequestLoading] = useState<boolean>(false);
  const [DataRequestData, setDataRequestData] = useState<any>("");
  const [DataRequestError, setDataRequestError] = useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleDataRequest = async (payload: DataRequestPayload) => {
    try {
      setDataRequestLoading(true);
      setDataRequestData("");
      const res = await makeRequest({
        method: "POST",
        route: POST_REQUEST_DATA,
        body: payload,
        originType: "identity",
      });

      //Map the payload
      // setAirtimeRequestData(res?.data?.data);
      setDataRequestLoading(true);

      if (res.status === 200) {
        setDataRequestData(res?.data);
      } else {
        if (res?.data?.data?.hasError || res?.data?.data?.responseMessage) {
          ToastNotify({
            type: "error",
            message: res?.data?.data?.responseMessage?.title,
            position: "top-right",
          });
        } else {
          axiosStatus(res?.data);
        }
      }
      setDataRequestLoading(false);
    } catch (error) {
      setDataRequestLoading(false);
    }
  };

  return {
    DataRequestData,
    DataRequestLoading,
    DataRequestError,
    handleDataRequest,
  };
};
//API endpoint fo calling OTP services
export const useAirtimeOTPRequest = () => {
  const navigate = useNavigate();
  //Local States
  const [AirtimeOTPRequestLoading, setAirtimeOTPRequestLoading] =
    useState<boolean>(false);
  const [AirtimeOTPRequestData, setAirtimeOTPRequestData] = useState<any>([]);
  const [AirtimeOTPRequestError, setAirtimeOTPRequestError] =
    useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleAirtimeOTPRequest = async (payload: AirtimeOtpPayload) => {
    try {
      setAirtimeOTPRequestLoading(true);
      const res = await makeRequest({
        method: "PUT",
        route: POST_OTP_AIRTIME,
        body: payload,
        originType: "paymentservice",
      });
      if (res?.status === 200) {
      } else {
        setAirtimeOTPRequestError(
          "An error while submiting your request, please referesh and try again"
        );
      }
      setAirtimeOTPRequestLoading(false);
    } catch (error: any) {
      ToastNotify({
        type: "error",
        message: error,
      });
      setAirtimeOTPRequestLoading(false);
    }
  };

  return {
    AirtimeOTPRequestData,
    AirtimeOTPRequestLoading,
    AirtimeOTPRequestError,
    handleAirtimeOTPRequest,
  };
};

//API endpoint for handling Refresh
export const useAirtimeHandleRefresh = () => {
  const navigate = useNavigate();
  //Local States
  const [AirtimeHandleRefreshLoading, setAirtimeHandleRefreshLoading] =
    useState<boolean>(false);
  const [AirtimeHandleRefreshData, setAirtimeHandleRefreshData] = useState<any>(
    []
  );
  const [AirtimeHandleRefreshError, setAirtimeHandleRefreshError] =
    useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const AirtimeHandleRefresh = async (CorrelationId: string) => {
    try {
      setAirtimeHandleRefreshLoading(true);
      const res = await makeRequest({
        method: "GET",
        route: GET_HANDLE_REFRESH(CorrelationId),
        originType: "transferservice",
      });
      // console.log("refresh", res);
      if (res.status === 200) {
        setAirtimeHandleRefreshData(res?.data?.data?.transactions[0]);
      } else {
        setAirtimeHandleRefreshError(
          "An error while submiting your request, please referesh and try again"
        );
      }
      setAirtimeHandleRefreshLoading(false);
    } catch (error) {
      setAirtimeHandleRefreshLoading(false);
    }
  };

  return {
    AirtimeHandleRefreshData,
    AirtimeHandleRefreshLoading,
    AirtimeHandleRefreshError,
    AirtimeHandleRefresh,
  };
};
// API endpoint for handling data refresh
export const useDataHandleRefresh = () => {
  const navigate = useNavigate();
  //Local States
  const [DataHandleRefreshLoading, setDataHandleRefreshLoading] =
    useState<boolean>(false);
  const [DataHandleRefreshData, setDataHandleRefreshData] = useState<any>([]);
  const [DataHandleRefreshError, setDataHandleRefreshError] =
    useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const DataHandleRefresh = async (CorrelationId: string) => {
    try {
      setDataHandleRefreshLoading(true);
      const res = await makeRequest({
        method: "GET",
        route: GET_HANDLE_REFRESH(CorrelationId),
        originType: "transferservice",
      });
      if (res.status === 200) {
        setDataHandleRefreshData(res?.data?.data?.transactions[0]);
      } else {
        setDataHandleRefreshError(
          "An error while submiting your request, please referesh and try again"
        );
      }
      setDataHandleRefreshLoading(false);
    } catch (error) {
      setDataHandleRefreshLoading(false);
    }
  };

  return {
    DataHandleRefreshData,
    DataHandleRefreshLoading,
    DataHandleRefreshError,
    DataHandleRefresh,
  };
};
//API Call for data bundle
export const useDataBundle = () => {
  //Local States
  const [DataBundleLoading, setDataBundleLoading] = useState<boolean>(false);
  const [DataBundleData, setDataBundleData] = useState<any>([]);
  const [DataBundleError, setDataBundleError] = useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleDataBundle = async () => {
    setDataBundleLoading(true);
    try {
      const res = await makeRequest({
        method: "get",
        route: GET_Data_Bundle,
        originType: "paymentservice",
      });
      setDataBundleData(res?.data?.data?.dataPlans);
      setDataBundleLoading(false);
    } catch (error) {
      setDataBundleError(
        "An error occured while fetching branch sol ids, please referesh and try again"
      );
      setDataBundleLoading(false);
    }
  };

  return {
    DataBundleData,
    DataBundleLoading,
    DataBundleError,
    handleDataBundle,
  };
};

// Endpoint for saved beneficiary
export const useSavedBeneficiary = () => {
  //Local States
  const [SavedBeneficiaryLoading, setSavedBeneficiaryLoading] =
    useState<boolean>(false);
  const [SavedBeneficiaryData, setSavedBeneficiaryData] = useState<any>([]);
  const [SavedBeneficiaryError, setSavedBeneficiaryError] =
    useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleSavedBeneficiary = async (payload: BillsBeneficiaryPayload) => {
    setSavedBeneficiaryLoading(true);
    const { beneficiaryType, pageNumber, pageSize } = payload;
    try {
      const res = await makeRequest({
        method: "get",
        route: GET_AIRTIME_DATA_BENEFICIARY_LIST(
          pageSize,
          pageNumber,
          beneficiaryType
        ),
        originType: "paymentservice",
      });
      setSavedBeneficiaryData(res?.data?.data);
      // console.log(res)
      setSavedBeneficiaryLoading(false);
    } catch (error) {
      setSavedBeneficiaryError(
        "An error occured while fetching branch sol ids, please referesh and try again"
      );
      setSavedBeneficiaryLoading(false);
    }
  };

  return {
    SavedBeneficiaryData,
    setSavedBeneficiaryData,
    SavedBeneficiaryLoading,
    SavedBeneficiaryError,
    handleSavedBeneficiary,
  };
};
