import React, { ReactNode } from "react";
import HomeWrapper from "./../components/HomeWrapper";
import TermsBG from "../../../assets/svgs/terms-bg.svg";

interface Props {
  children: ReactNode;
}
const ReferenceLetterContainer: React.FC<Props> = ({ children }) => {
  return (
    <HomeWrapper>
      <div className="">
        <div
          className="bg-white relative py-10 min-h-screen mx-auto bg-fixed"
          style={{
            background: `url(${TermsBG})`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: "100%",
          }}
        >
          {children}
        </div>
      </div>
    </HomeWrapper>
  );
};

export default ReferenceLetterContainer;
