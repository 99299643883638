import { Flowbite, Tabs, TabsRef } from "flowbite-react";
import { useGetNotifications } from "hooks/apis/Notifications/useGetNotifications";
import { useAppDispatch } from "hooks/useReduxHook";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { NotificationsPayload } from "react-wrappers/stores/redux/interfaces";
import {
  setSkipAndTake,
  useNotificationState,
} from "react-wrappers/stores/redux/notifications/slices";
import { FilterInput } from "./FilterInput";
import { NotificationsList } from "./NotificationsList";

export const NotificationsTabs = () => {
  const tabsRef = useRef<TabsRef>(null);
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState<NotificationsPayload>({
    notificationState: "1",
    pageNumber: 1,
    pageSize: 10,
    from: "",
    to: "",
  });
  const {
    allNotifications,
    unReadNotifications,
    readNotifications,
    notificationsPagination,
  } = useNotificationState();
  const {
    handleFetchAllNotifications,
    handleFetchReadNotifications,
    handleFetchUnReadNotifications,
    handleMarkAllAsRead,
  } = useGetNotifications();

  const handleMarkAllASRead = () => {
    handleMarkAllAsRead();
  };

  useEffect(() => {
    fetchNotification();
  }, [query]);

  const handleViewNext = () => {
    dispatch(
      setSkipAndTake({
        skip: notificationsPagination?.skip + 10,
        take: notificationsPagination?.take + 10,
      })
    );
    setQuery({
      ...query,
      pageSize: 10,
      pageNumber: notificationsPagination?.currentPage + 1,
    });
  };

  const handleViewPrevious = () => {
    dispatch(
      setSkipAndTake({
        skip: notificationsPagination?.skip - 10,
        take: notificationsPagination?.take - 10,
      })
    );
    setQuery({
      ...query,
      pageSize: 10,
      pageNumber: notificationsPagination?.currentPage - 1,
    });
  };

  const fetchNotification = () => {
    switch (query.notificationState) {
      case "1":
        handleFetchAllNotifications(query);
        break;
      case "2":
        handleFetchUnReadNotifications(query);
        break;
      case "3":
        handleFetchReadNotifications(query);
        break;
      default:
        handleFetchAllNotifications(query);
    }
  };

  const theme = {
    tabs: {
      tablist: {
        base: "flex text-center gap-2 z-0",
        tabitem: {
          base: "flex text-center",
          variant: {
            underline: {
              base: "rounded-t-lg",
              active: {
                on: "active py-2 rounded-t-lg border-b-2 border-lib-alat-red text-lib-alat-red font-medium",
                off: "py-2 border-b-2 border-transparent text-lib-alat-black font-medium",
              },
            },
          },
        },
      },
    },
  };

  const handleChangeTab = (number: number) => {
    setQuery({
      ...query,
      pageNumber: 1,
      notificationState: (number + 1).toString(),
    });
  };

  return (
    <div className="">
      <div className="flex items-center gap-2 justify-end">
        <NavLink
          to={"#"}
          onClick={handleMarkAllASRead}
          className="text-lib-alat-dark-red"
        >
          <div className="text-xs font-bold cursor-pointer">
            <p id="see_all_accounts_text">Mark All As Read</p>
          </div>
        </NavLink>
        <FilterInput
          dropList={true ? [] : []}
          onHandleFiterSelect={(item) =>
            setQuery({ ...query, from: item.from, to: item.to })
          }
          filterValue={"Filter"}
          variant="translate3d(0px, 110px, 0px)"
        />
      </div>
      <div className="w-full">
        <Flowbite theme={{ theme }}>
          <Tabs
            aria-label="Default tabs"
            // eslint-disable-next-line react/style-prop-object
            variant="underline"
            ref={tabsRef}
            onActiveTabChange={(tab) => handleChangeTab(tab)}
            className=" !border-[#E4DFDF] w-full"
          >
            <Tabs.Item
              active={activeTab === 0}
              title="All"
              className=""
              id="requirement_tab"
            >
              <NotificationsList
                notifications={allNotifications}
                handleViewNext={handleViewNext}
                handleViewPrevious={handleViewPrevious}
              />
            </Tabs.Item>
            <Tabs.Item
              //   active={activeTab === 1}
              title="Unread"
              className="mr-80 !bg-purple-800"
              id="file_upload_tab"
            >
              <NotificationsList
                notifications={unReadNotifications}
                handleViewNext={handleViewNext}
                handleViewPrevious={handleViewPrevious}
              />
            </Tabs.Item>
            <Tabs.Item
              //   active={activeTab === 1}
              title="Read"
              className="mr-80 !bg-purple-800"
              id="file_upload_tab"
            >
              <NotificationsList
                notifications={readNotifications}
                handleViewNext={handleViewNext}
                handleViewPrevious={handleViewPrevious}
              />
            </Tabs.Item>
          </Tabs>
        </Flowbite>
      </div>
    </div>
  );
};
// dark:text-lib-alat-red dark:border-lib-alat-red text-lib-alat-red border-lib-alat-red
