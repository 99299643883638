import { ViewNotifications } from "pages/Notifications/ViewNotifications";
import { ViewNotificationDetails } from "pages/Notifications/ViewNotificationsDetails";
import { Storage } from "utils/inAppstorage";

const isProtected = Storage.getItem("afb-app-token");

export const routes = [
  {
    path: "/notifications",
    isAuthenticated: true,
    element: <ViewNotifications />,
  },
  {
    path: "/notifications/details",
    isAuthenticated: true,
    element: <ViewNotificationDetails />,
  },
];

const unProtectedRoutes: any = [];

export const notificationsRoutes = isProtected ? routes : unProtectedRoutes;
