export const SEARCH_COLLECTIONS = (searchParam: string) =>
  `v1/Collections/GetCollectionsCategories?searchParam=${searchParam}`;
export const GET_ALL_COLLECTIONS = "v1/Collections/CollectionsAndCategories";
export const GET_COLLECTION_MERCHANTS = (id: any) =>
  `v1/Collections/GetMerchantsMappedToCollections?categoryId=${id}`;
export const GET_COLLECTION_MERCHANT_ITEMS = (id: any) =>
  `v1/Collections/CollectionItems?collectionId=${id}`;
export const GET_DYNAMIC_FORM = (id: any) =>
  `v1/Collections/ValidationEndpointsWithStepGrouping?collectionItemId=${id}`;
export const GET_SIMPLE_FORM = (id: any) =>
  `v1/Collections/SimpleItemFields?collectionItemId=${id}`;
export const GET_DYNAMIC_FORM_NEXT = (payload: any) =>
  `v1/Collections/ValidationNextEndpointsWithStepGrouping?collectionItemId=${payload.collectionItemId}&lastStep=${payload?.lastStep}&validationReference=${payload?.validationReference}`;
export const POST_FORM_VALIDATION = `v1/Collections/ValidateEndpoint`;
export const SUBMIT_PAYMENT = `/transaction/validate/collections`;
