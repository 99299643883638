export const filterList = [
  {
    id: 1010299,
    value: "Last day",
  },
  {
    id: 10102100,
    value: "Last 7 days",
  },
  {
    id: 10102101,
    value: "Last 30 days",
  },
  {
    id: 10102102,
    value: "Last month",
  },
  {
    id: 10102104,
    value: "Last year",
  },
];
