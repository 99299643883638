import React from "react";
import ErrorIcon from "../../assets/icons/error-icon.svg";

interface TextAreaProps {
  cols?: number;
  rows?: number;
  value?: string;
  handleChange?: any;
  error?: string;
  hasError?: boolean;
  wordLength?: string | number;
  name?: string;
  label?: string;
  placeholder?: string;
  labelVariant?: string;
}

export const TextArea = ({
  cols,
  label = "Please provide a reason for rejection",
  rows,
  value,
  handleChange,
  hasError,
  wordLength = 0,
  name,
  error = "Please enter a reason for this rejection",
  placeholder = "Enter reject message here",
  labelVariant = "text-[22px] py-5 leading-[170%] tracking-tighter font-semibold",
}: TextAreaProps) => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className={`${labelVariant} flex justify-start w-full`}>{label}</div>
      <textarea
        name={name}
        id="rejectionReason"
        cols={cols}
        rows={rows}
        placeholder={placeholder}
        className={`${
          hasError && " border border-red-500 focus:border-red-500"
        } border focus:border-lib-alat-gray-input-border rounded-[8px] focus:outline-none bg-gray-50 w-full mx-20 p-4`}
        value={value}
        onChange={handleChange}
      />
      <div
        className={`w-full flex items-center ${
          hasError ? "justify-between" : "justify-end"
        }`}
      >
        {hasError && (
          <div className="flex justify-start gap-2">
            <img src={ErrorIcon} alt="Error" />
            <p className="text-red-500 font-[Inter-Regular] text-xs h-auto py-1">
              {error}
            </p>
          </div>
        )}
        <div className="flex justify-end">
          <p className="text-[#A6A6A6] text-sm italic leading-[30px] tracking-tighter">
            {`${wordLength}/100`}
          </p>
        </div>
      </div>
    </div>
  );
};
