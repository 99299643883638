import { redirectTo } from "../../../../utils/helperFunctions";
import { removeLoadingState } from "../../../../helpers/assitFunctions";
import { ToastNotify } from "../../../../helpers/toastNotify";
import { ProfileState } from "../interfaces";

export const triggerAdminCreatesUser = (state: ProfileState, action: any) => {
  state.loading = removeLoadingState(state.loading, "POST_ADMIN_CREATES_USER");

  // console.log("resp", action);

  if (action?.payload?.data?.hasError) {
    return ToastNotify({
      type: "error",
      message:
        action?.payload?.data?.responseMessage?.title ||
        "Cannot initiate a request, please login and try again or contact technical support",
      position: "top-right",
    });
  } else {
    state.successStatus = true;
  }
  //Prevent no success status
  if (action?.payload?.status !== 200) return;

  state.successMessage = action?.payload?.data?.data;
};

export const triggerVerifySecurityQuestion = (
  state: ProfileState,
  action: any
) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_USER_VERIFIES_SECURITY_QUESTION"
  );

  state.verifySecurityQuestion = {
    ...state.verifySecurityQuestion,
    successStatus: false,
  };

  // state.verifySecurityQuestion.successStatus = false;

  //Prevent no success status
  // if (action?.payload?.status !== 200) return;
  if (action?.payload?.data?.data?.responseCode === 3)
    return redirectTo("/logout");

  if (action?.payload?.data?.data?.httpStatusCode === 401) {
    return redirectTo("/logout");
  }

  if (
    action?.payload?.data?.data?.hasError ||
    action?.payload?.status === 400
  ) {
    state.verifySecurityQuestion = {
      ...state.verifySecurityQuestion,
      successStatus: false,
    };
    return ToastNotify({
      type: "error",
      message: `${action?.payload?.data?.data?.responseMessage?.title}. ${action?.payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }
  if (action?.payload?.data?.data?.isValid || action?.payload?.status === 200) {
    // state.verifySecurityQuestion.successStatus = true;
    state.verifySecurityQuestion = {
      ...state.verifySecurityQuestion,
      successStatus: true,
    };
  } else {
    // state.verifySecurityQuestion.successStatus = false;
    state.verifySecurityQuestion = {
      ...state.verifySecurityQuestion,
      successStatus: false,
    };
    return ToastNotify({
      type: "error",
      message: action?.payload?.data?.responseMessage?.title,
      position: "top-right",
    });
  }
};
export const triggerChangeUserPin = (state: ProfileState, action: any) => {
  state.loading = removeLoadingState(state.loading, "POST_USER_CHANGES_PIN");
  // state.changePin.successStatus = false;
  state.changePin = {
    ...state.changePin,
    successStatus: false,
  };

  if (action?.payload?.data?.data?.httpStatusCode === 401) {
    return redirectTo("/logout");
  }

  if (action?.payload?.data?.data?.hasError) {
    state.changePin = {
      ...state.changePin,
      successStatus: false,
    };
    return ToastNotify({
      type: "error",
      message:
        action?.payload?.data?.data?.responseMessage?.title ||
        "Cannot initiate a request, please login and try again or contact technical support",
      position: "top-right",
    });
  } else {
    state.changePin = {
      ...state.changePin,
      successStatus: true,
      successMessage: action?.payload?.data?.data,
    };
    // state.changePin.successStatus = true;
    // state.changePin.successMessage = action?.payload?.data?.data;
  }
};

export const triggerCreateUserPin = (state: ProfileState, action: any) => {
  state.loading = removeLoadingState(state.loading, "POST_USER_CREATES_PIN");
  state.changePin = {
    ...state.changePin,
    successStatus: false,
  };

  //Prevent no success status
  // if (action?.payload?.status !== 200) return;
  if (
    action?.payload?.data?.data?.responseCode === 3 ||
    action?.payload?.data?.data?.responseCode === 1
  )
    return redirectTo("/logout");

  if (action?.payload?.data?.data?.httpStatusCode === 401) {
    return redirectTo("/logout");
  }

  if (action?.payload?.data?.data?.hasError) {
    state.changePin = {
      ...state.changePin,
      successStatus: false,
    };
    return ToastNotify({
      type: "error",
      message: `${action?.payload?.data?.data?.responseMessage?.title}.${action?.payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  } else {
    state.changePin = {
      ...state.changePin,
      successStatus: true,
      successMessage: action?.payload?.data?.data,
    };
    // state.changePin.successStatus = true;
    // state.changePin.successMessage = action?.payload?.data?.data;
  }
};
export const triggerChangePassword = (state: ProfileState, action: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_USERS_CHANGE_PASSWORD"
  );
  state.changePassword = {
    ...state.changePassword,
    successStatus: false,
  };
  // state.changePassword.successStatus = false;

  //Prevent no success status
  // if (action?.payload?.status !== 200) return;
  if (action?.payload?.data?.data?.responseCode === 3)
    return redirectTo("/logout");

  if (action?.payload?.data?.data?.httpStatusCode === 401) {
    return redirectTo("/logout");
  }

  if (action?.payload?.data?.data?.hasError) {
    state.changePassword = {
      ...state.changePassword,
      successStatus: false,
      errorMessage: action?.payload?.data?.data?.responseMessage?.actionMessage,
    };
    // state.changePassword = {
    //    ...state.changePassword,
    //    successStatus: false,
    // }
    // state.changePassword.errorMessage =
    //    action?.payload?.data?.data?.responseMessage?.actionMessage;
    if (
      action?.payload?.data?.data?.responseMessage?.title?.toLowerCase() ===
      "invalid token"
    ) {
      return redirectTo("/profile/change-password/failed");
    }
    return ToastNotify({
      type: "error",
      message:
        action?.payload?.data?.data?.responseMessage?.title ||
        "Cannot initiate a request, please login and try again or contact technical support",
      position: "top-right",
    });
  } else {
    state.changePassword = {
      ...state.changePassword,
      successStatus: true,
      successMessage: action?.payload?.data?.data,
    };
    // state.changePassword.successStatus = true;
    // state.changePassword.successMessage = action?.payload?.data?.data;
  }
};
export const triggerRequestSoftToken = (state: ProfileState, action: any) => {
  state.loading = removeLoadingState(state.loading, "POST_REQUEST_SOFT_TOKEN");
  state.requestSoftToken = {
    ...state.requestSoftToken,
    successStatus: false,
  };
  // state.requestSoftToken.successStatus = false;

  //Prevent no success status
  // if (action?.payload?.status !== 200) return;

  if (action?.payload?.data?.httpStatusCode === 401) {
    return redirectTo("/logout");
  }

  if (action?.payload?.data?.data?.hasError) {
    state.requestSoftToken = {
      ...state.requestSoftToken,
      successStatus: false,
      title: action?.payload?.data?.responseMessage?.title,
      errorMessage: action?.payload?.data?.responseMessage?.actionMessage,
    };
    // state.requestSoftToken.successStatus = false;
    // state.requestSoftToken.errorMessage =
    //    action?.payload?.data?.responseMessage?.actionMessage;
    return ToastNotify({
      type: "error",
      message: `${action?.payload?.data?.data?.responseMessage?.title}. ${action?.payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  } else {
    state.requestSoftToken = {
      ...state.requestSoftToken,
      successStatus: true,
      title: action?.payload?.data?.responseMessage?.title,
      successMessage: action?.payload?.data?.responseMessage?.actionMessage,
    };
    // state.requestSoftToken.successStatus = true;
    // state.requestSoftToken.successMessage = action?.payload?.data?.responseMessage?.title;
  }
};

export const triggerGetAllUsers = (state: ProfileState, action: any) => {
  state.loading = removeLoadingState(state.loading, "POST_FETCH_USERS");

  // console.log(action.payload.data.payload)
  //Prevent no success status
  // if (action.payload.status !== 200) return;

  if (action?.payload?.data?.hasError) {
    ToastNotify({
      type: "error",
      message:
        action?.payload?.data?.responseMessage?.title ||
        "Oops! something wrong just, please try login and try again",
      position: "top-right",
    });
  } else {
    state.allUsers = action?.payload?.data?.data?.users;
    state.usersPagination = {
      ...state.usersPagination,
      sortedData: [
        ...state?.usersPagination?.sortedData,
        ...action?.payload?.data?.data?.users,
      ],
      hasNextPage: action?.payload?.data?.data?.hasNextPage,
      // pageSize: action?.payload?.data?.data?.pageSize,
      currentPage: action?.payload?.data?.data?.pageNumber,
      count: action?.payload?.data?.data?.count,
      totalItems: action?.payload?.data?.data?.totalItemCount,
    };
  }
};
