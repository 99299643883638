import PageHeader from "../../components/reusables/Page/PageHeader";
import FundsTransferTab from "../../components/Payments/FundsTransfer/FundsTransfer";
import DashboardWrapper from "../../components/Layouts/DashboardWrapper";

const FundsTransfer = () => {
  return (
    <DashboardWrapper>
      <div className="lg:px-20 space-y-4 mt-5">
        <div className="flex justify-between items-center px-5">
          <PageHeader title="Funds Transfer" />
        </div>
        <FundsTransferTab />
      </div>
    </DashboardWrapper>
  );
};

export default FundsTransfer;
