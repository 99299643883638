import { PendingActionEnum } from "utils/enums";
import NextIcon from "assets/icons/NextArrowIcon.png";
import { useNavigate } from "react-router-dom";

const handlePendingAction = (pendingAction: number) => {
  let pendingMessage = "";
  switch (pendingAction) {
    case PendingActionEnum.BUSINESSADDRESS:
      pendingMessage = "Business Address Rejected";
      break;
    case PendingActionEnum.CACCERTIFICATE:
      pendingMessage = "CAC Documents Rejected";
      break;
    case PendingActionEnum.IDCARD:
      pendingMessage = "I.D Card Rejected";
      break;
    case PendingActionEnum.REFEREE:
      pendingMessage = "Referee Rejected";
      break;
    case PendingActionEnum.SIGNATURE:
      pendingMessage = "Signature Rejected";
      break;
    case PendingActionEnum.TIN:
      pendingMessage = "TIN Rejected";
      break;
    default:
      pendingMessage = "";
      break;
  }
  return pendingMessage;
};

const PendingAction = ({
  pendingAction,
  index,
}: {
  pendingAction: any;
  index: number;
}) => {
  const navigate = useNavigate();

  const handleNextScreen = (action: any) => {
    if (action?.pendingActionType === PendingActionEnum.IDCARD) {
      navigate("/pending-actions/rejected-docs/id-upload", {
        state: { pendingData: pendingAction },
      });
    }
    if (action?.pendingActionType === PendingActionEnum.BUSINESSADDRESS) {
      navigate("/pending-actions/rejected-docs/businessaddress-upload", {
        state: { pendingData: pendingAction },
      });
    }
    if (action?.pendingActionType === PendingActionEnum.CACCERTIFICATE) {
      navigate("/pending-actions/rejected-docs/cac-upload", {
        state: { pendingData: pendingAction },
      });
    }
    if (action?.pendingActionType === PendingActionEnum.SIGNATURE) {
      navigate("/pending-actions/rejected-docs/signature-upload", {
        state: { pendingData: pendingAction },
      });
    }
    if (action?.pendingActionType === PendingActionEnum.TIN) {
      navigate("/pending-actions/rejected-docs/tin-upload", {
        state: { pendingData: pendingAction },
      });
    }
    if (action?.pendingActionType === PendingActionEnum.REFEREE) {
      navigate("/pending-actions/rejected-docs/referee-upload", {
        state: { pendingData: pendingAction },
      });
    }
  };

  return (
    <div
      className="border rounded-md border-[#E4DFDF] h-20 min-w-80 md:min-w-[500px] flex items-center justify-center hover:shadow-md cursor-pointer"
      key={index}
      onClick={() => handleNextScreen(pendingAction)}
    >
      <div className="flex justify-between px-5 md:px-10 w-full">
        <div className="flex flex-col justify-center">
          <p className="font-semibold">{`${index + 1}. ${handlePendingAction(
            pendingAction?.pendingActionType
          )}`}</p>
          <p className="text-xs">Tap to reupload</p>
        </div>
        <div>
          <img
            src={NextIcon}
            alt="->"
            className="rounded-full h-10 w-10 cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default PendingAction;
