import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { useAppSelector } from "../../../../hooks/useReduxHook";
import { ReactivateAccountState } from "../interfaces";
import { initialUploadPayload } from "./__test__/mocks";

const dataObject = {
  loading: [],
  validateAccount: { accountNumber: "", isInApp: false },
  uploadDoc: initialUploadPayload,
  requestResponse: null,
} as ReactivateAccountState;

export const initialState: ReactivateAccountState = { ...dataObject };

export const accountReactivationSlice = createSlice({
  name: "account-reactivation",
  initialState,
  reducers: {
    resetReactivationData: (): ReactivateAccountState => initialState,
    setLoadingState: (
      state: ReactivateAccountState,
      action: PayloadAction<any>
    ) => {
      state.loading = action.payload;
    },
    setRequestResponse: (state: any, action: PayloadAction<any>) => {
      state.requestResponse = action.payload;
    },
    setAccountNumberState: (
      state: ReactivateAccountState,
      action: PayloadAction<any>
    ) => {
      state.validateAccount = action.payload;
    },

    setDocUploadState: (
      state: ReactivateAccountState,
      action: PayloadAction<any>
    ) => {
      state.uploadDoc = { ...state.uploadDoc, ...action.payload };
    },
  },
});

// Selectors
const selectAccountReactivation = (state: RootState) =>
  state.accountReactivation;

// Reducers and actions
export const {
  resetReactivationData,
  setLoadingState,
  setDocUploadState,
  setAccountNumberState,
  setRequestResponse,
} = accountReactivationSlice.actions;

//App Redux State
export const useAccountReactivationState = () =>
  useAppSelector(selectAccountReactivation);

export default accountReactivationSlice.reducer;
