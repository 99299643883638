import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card } from "flowbite-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as BankIcon } from "../../../assets/svgs/banks-icon.svg";
import { ReactComponent as OneUserIcon } from "../../../assets/svgs/one-user-icon.svg";
import { ReactComponent as AddBeneficiaryIcon } from "../../../assets/svgs/add-beneficiary.svg";
import { ReactComponent as FourUserIcon } from "../../../assets/svgs/four-user-icon.svg";
import { resetPaymentsData } from "react-wrappers/stores/redux/payments/slices";
import { useAppDispatch } from "hooks/useReduxHook";

export interface PopUpProps {
  onGenerateReceipt: any;
}
const BankTransferMain = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // eslint-disable-next-line
  const handleGotoDashboard = () => {
    navigate("/user-dashboard");
  };
  // const [screen, setScreeen] = useState(0);
  const handleNewTransferChange = (event: any) => {
    navigate("/payments/bank-transfer#0");
  };
  const handleSavedBeneficiaryChange = (action: number, event?: any) => {
    /**
     * 0 -- view beneficiaries
     * 1 -- add beneficiaries
     */
    navigate(`/payments/bank-transfer#1`, {
      state: {
        action: action,
      },
    });
  };
  const handleMultipleTransferChange = (event: any) => {
    navigate("/payments/multiple-transfer");
  };
  useEffect(() => {
    dispatch(resetPaymentsData());
  }, []);
  return (
    <div className="w-full">
      <Card className="md:w-[768px] w-full h-auto mx-auto bg-white pb-14  dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
        <div>
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              onClick={() => navigate("/user-dashboard")}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            />
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="banktransfer_heading"
            >
              Bank Transfer
            </div>
            <div></div>
          </div>
        </div>
        <hr />
        <div className="grid md:grid-cols-3 grid-cols-1 gap-4 text-alat-grey pt-10 mx-10">
          <Card
            className="cursor-pointer hover:bg-primary hover:text-white hover:fill-white  dark:bg-white dark:border-[#E4DFDF] border-[#E4DFDF] dark:shadow-white"
            onClick={handleNewTransferChange}
            id="newtransfer_btn"
          >
            <BankIcon fill="inherit" />

            <h1 className="text-[14px]" id="newtransfer_text">
              New Transfer
            </h1>
            <h2 className="text-[12px]">Send money to a bank account</h2>
          </Card>
          <Card
            className="cursor-pointer hover:bg-primary hover:text-white hover:fill-white dark:bg-white dark:border-[#E4DFDF] border-[#E4DFDF] dark:shadow-white"
            onClick={() => handleSavedBeneficiaryChange(0)}
            id="savebeneficiaries_btn"
          >
            <OneUserIcon fill="inherit" />

            <h1 className="text-[14px]" id="savebeneficiaries_text">
              Saved Beneficiaries
            </h1>
            <h2 className="text-[12px]">
              Transfer to your saved beneficiaries
            </h2>
          </Card>
          <Card
            className="cursor-pointer hover:bg-primary hover:text-white hover:fill-white  dark:bg-white dark:border-[#E4DFDF] border-[#E4DFDF] dark:shadow-white"
            onClick={handleMultipleTransferChange}
            id="multipletransfer_btn"
          >
            <FourUserIcon fill="inherit" />

            <h1 className="text-[14px]" id="multipletransfer_text">
              Multiple transfer
            </h1>
            <h2 className="text-[12px]">
              Multiple transfer to your saved groups
            </h2>
          </Card>
          <Card
            className="cursor-pointer hover:bg-primary hover:text-white hover:fill-white dark:bg-white dark:border-[#E4DFDF] border-[#E4DFDF] dark:shadow-white"
            onClick={() => handleSavedBeneficiaryChange(1)}
            id="addbeneficiaries_btn"
          >
            <AddBeneficiaryIcon fill="inherit" />

            <h1 className="text-[14px]" id="addbeneficiaries_text">
              Add New beneficiary
            </h1>
            <h2 className="text-[12px]">Save beneficiary for later</h2>
          </Card>
        </div>
      </Card>
    </div>
  );
};

export default BankTransferMain;
