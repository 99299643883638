import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import {
  POST_VIRTUAL_ACCOUNT_REQUEST,
  POST_VIRTUAL_ACCOUNT_REQUEST_UNREG_BIZ,
} from "../../../utils/apis/routes";
import { VirtualAccountAPIPayload } from "../../../types/global";
import axiosStatus from "utils/apis/axios/errorStatus";
import { ToastNotify } from "helpers/toastNotify";

export const useVirtualAccount = () => {
  const [userVirtualAccountStatus, setVirtualAccountStatus] =
    useState<boolean>(false);
  const [userVirtualAccountLoading, setVirtualAccountLoading] =
    useState<boolean>(false);
  const [userVirtualAccountError, setVirtualAccountError] =
    useState<string>("");
  const [userVirtualAccountData, setVirtualAccountData] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const handleVirtualAccount = async (payload: VirtualAccountAPIPayload) => {
    const url = payload?.accountType
      ? POST_VIRTUAL_ACCOUNT_REQUEST
      : POST_VIRTUAL_ACCOUNT_REQUEST_UNREG_BIZ;

    try {
      setVirtualAccountLoading(true);
      const res = await makeRequest({
        method: "post",
        route: url,
        body: payload,
        originType: "onboarding",
      });
      // console.log(res)
      //Map the payload
      if (res?.status !== 200) {
        if (res?.data?.data?.hasError) {
          ToastNotify({
            autoClose: 5000,
            type: "error",
            message: res?.data?.data?.responseMessage?.title,
            position: "top-right",
          });
        } else {
          axiosStatus(res?.data);
        }
      }
      if (res?.data?.data?.virtualAccount) {
        setVirtualAccountStatus(true);
        setVirtualAccountData(res);
      } else {
        setVirtualAccountError(
          "An error occured while fetching account, please refresh and try again"
        );
      }

      setVirtualAccountLoading(false);
    } catch (error) {
      setVirtualAccountError(
        "An error occured while fetching account, please refresh and try again"
      );
      setVirtualAccountLoading(false);
    }
  };

  return {
    userVirtualAccountStatus,
    userVirtualAccountLoading,
    userVirtualAccountError,
    userVirtualAccountData,
    handleVirtualAccount,
  };
};
