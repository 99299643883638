import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { useNavigate } from "react-router-dom";

const PendingActionsWrapper = ({
  cardTitle = "Upload ID Card",
  children,
}: any) => {
  const navigate = useNavigate();
  return (
    <div className="max-w-5xl mx-auto flex">
      <ReusableCard
        variant="md:w-[751px] w-full mx-auto"
        cardTitle={cardTitle}
        handleBackNavigation={() => {
          navigate("/pending-actions/rejected-docs/pending-list");
        }}
        showGoBackIcon
      >
        <div className="space-y-8 justify-center md:w-[550px] mx-auto">
          {children}
        </div>
      </ReusableCard>
    </div>
  );
};

export default PendingActionsWrapper;
