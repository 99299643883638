import { TablesProps } from "../types";
import { FiSearch } from "react-icons/fi";
import { Table } from "flowbite-react";
import { FilterInput } from "../../Inputs";
import { filterList } from "../../utils/dumieData/filterList";
import DefaultHeader from "../DefaultHeader";
import DefaultBody from "../DefaultBody";
import Preloader from "../../Mics/Preloader";
import { PrimaryButton } from "../../Buttons/PrimaryButton";
import { Pagination } from "../../../components/Dashboard/User/Pagination";
import { ReactComponent as BackArrowIcon } from "../../assets/images/icons/BackArrowIcon.svg";
import { v4 as uuid } from "uuid";

export const TableOne = ({
  isFilter,
  searchPlaceHolder = "Enter text",
  isSearch,
  isCheckboxes,
  isPagination,
  isStriped = false,
  isHover = true,
  title = "",
  paginationData,
  onHandleCallback,
  handleBack,
  handleShowNext,
  handleShowPrevious,
  btnLabel = "Add new",
  showGoBackIcon = false,
  header,
  preloader,
  body,
  showTableHeader = false,
  handleClick,
  filterListProp,
  filterValue,
  value,
  showAddBtn = false,
  totalListCount = 0,
  paginationVariant = "bg-white h-20 w-full flex flex-row justify-between items-center px-4 rounded-b-sm",
}: TablesProps) => {
  const theme = {
    root: {
      base: "w-full text-left text-sm text-gray-500 dark:text-gray-400",
      shadow: "",
      wrapper: "relative",
    },
    head: {
      base: "group/head",
      cell: {
        base: "group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg ",
      },
    },
  };
  if (preloader) {
    return (
      <div className="flex justify-center w-full bg-white h-52 items-center">
        <Preloader
          variant="w-12 h-12"
          currentColor="#AB0B4B"
          currentFill="#F8E8E8"
        />
      </div>
    );
  }
  return (
    <div className="w-full bg-white overflow-x-auto">
      <div className="bg-white rounded-t-md w-full">
        {showTableHeader && (
          <div className="flex w-full justify-between items-center p-6 border-b">
            <div className="flex gap-5 items-center">
              {showGoBackIcon && (
                <div className="cursor-pointer" onClick={handleBack}>
                  <BackArrowIcon />
                </div>
              )}
              <p className="text-xl font-bold">{title}</p>
              {showAddBtn && (
                <PrimaryButton
                  id="add_new_btn"
                  labelText={btnLabel}
                  handleClick={handleClick}
                  variant={"text-white"}
                />
              )}
            </div>
            <div className="flex items-center justify-end space-x-6 relative">
              {isSearch && (
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <FiSearch className="w-5 h-5" color="#A6A6A6" />
                  </div>
                  <input
                    onChange={(e) =>
                      onHandleCallback &&
                      onHandleCallback("search", e?.target?.value)
                    }
                    value={value}
                    type="text"
                    id="table-search"
                    className="border p-2 pl-10 h-10 text-sm text-gray-900 rounded-md w-52 bg-gray-50 outline-none"
                    placeholder={searchPlaceHolder}
                  />
                </div>
              )}
              {isFilter && (
                <FilterInput
                  dropList={
                    filterListProp?.length ? filterListProp : filterList
                  }
                  onHandleFiterSelect={(item) =>
                    onHandleCallback && onHandleCallback("filter", item)
                  }
                  filterValue={filterValue}
                  variant="translate3d(0px, 110px, 0px)"
                />
              )}
            </div>
          </div>
        )}
        <Table hoverable={isHover} striped={isStriped} theme={{ ...theme }}>
          <Table.Head className="bg-white w-full  dark:bg-white text-[#333333] dark:text-[#333333]">
            {header ? (
              header.map((item: any, index: number) => (
                <Table.HeadCell
                  className={`px-6 py-2 ${item.classList}`}
                  key={uuid()}
                  style={{ padding: "10px 20px 10px 20px" }}
                >
                  {item.title}
                </Table.HeadCell>
              ))
            ) : (
              <DefaultHeader />
            )}
          </Table.Head>
          <Table.Body className="divide-y w-full">
            {body ? body : <DefaultBody />}
          </Table.Body>
        </Table>
        {isPagination && (
          <Pagination
            paginationVariant={paginationVariant}
            dataToShow={paginationData}
            handleNext={handleShowNext}
            handlePrev={handleShowPrevious}
          />
        )}
      </div>
    </div>
  );
};

export default TableOne;
