export const chargesList = [
  {
    id: 1,
    chargeFeeName: "InterBank1",
    transactionType: 1,
    charge: 10.75,
    lower: 0,
    upper: 5000,
  },
  {
    id: 2,
    chargeFeeName: "Interbank2",
    transactionType: 1,
    charge: 26.88,
    lower: 5001,
    upper: 50000,
  },
  {
    id: 3,
    chargeFeeName: "Interbank3",
    transactionType: 1,
    charge: 53.75,
    lower: 50001,
    upper: 0,
  },
];
