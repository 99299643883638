import { useState } from "react";
import axiosStatus from "utils/apis/axios/errorStatus";
import { ToastNotify } from "helpers/toastNotify";
import { POST_DUDCHEQUE_ACCEPTANCE } from "utils/apis/routes";
import { useApiRequest } from "hooks/useApiRequest";

export const useApproveDudCheque = () => {
  //Local States
  const [userDudApprovalStatus, setDudApprovalStatus] =
    useState<boolean>(false);
  const [userDudApprovalLoading, setDudApprovalLoading] =
    useState<boolean>(false);
  const [userDudApprovalError, setDudApprovalError] = useState<any>({
    actionMessage: "",
    title: "",
  });
  const [userDudApprovalData, setDudApprovalData] = useState<any>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleDudApproval = async (correlationId: string) => {
    // console.log(payload)
    setDudApprovalError({
      actionMessage: "",
      title: "",
    });
    setDudApprovalLoading(true);
    setDudApprovalStatus(false);
    setDudApprovalData("");
    try {
      const res = await makeRequest({
        method: "post",
        route: POST_DUDCHEQUE_ACCEPTANCE(correlationId),
        originType: "onboarding",
      });
      // console.log(res?.data?.data);
      //Map the payload
      if (res?.status !== 200) {
        if (res?.data?.data?.hasError) {
          setDudApprovalError(res?.data?.data?.responseMessage);
          ToastNotify({
            type: "error",
            message: res?.data?.data?.responseMessage?.title,
            position: "top-right",
          });
        } else {
          axiosStatus(res?.data);
        }
      } else {
        setDudApprovalStatus(true);
      }
      setDudApprovalLoading(false);
    } catch (error) {
      setDudApprovalLoading(false);
    }
  };

  return {
    userDudApprovalStatus,
    userDudApprovalLoading,
    userDudApprovalError,
    userDudApprovalData,
    handleDudApproval,
  };
};
