import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { Button, Card, Label } from "flowbite-react";
import React from "react";

// const baseURL =
// "https://wema-afbdev-apim.azure-api.net/afbidentity/api/transaction/validate";

interface INewRecipientComponent1Props {
  bankList: any;
  amount: any;
  accountNumber: any;
  accountValidated: any;
  accountValidationError: any;
  accountName: any;
  handleChange: any;
  handleBackToFundsTransfer: any;
  handleAccountValidation: any;
  handleScreenChangeToBankTransfer: any;
  handleScreenChangeNext: any;
  handleScreenChangePrev: any;
  handleAddRecipient: any;
  selectedBank: { bankCode: string; bankName: string };
}
const NewRecipientComponent1 = ({
  bankList,
  amount,
  accountNumber,
  accountValidated,
  accountValidationError,
  accountName,
  handleChange,
  handleBackToFundsTransfer,
  handleAccountValidation,
  handleScreenChangeToBankTransfer,
  handleScreenChangePrev,
  handleScreenChangeNext,
  handleAddRecipient,
  selectedBank,
}: INewRecipientComponent1Props) => {
  // const location = useLocation();
  // const navigate = useNavigate();
  const thereIsASelectedBank = selectedBank?.bankCode ? true : false;
  // console.log(accountName, "accountName")
  return (
    <div className="w-full flex items-center justify-center">
      <ReusableCard
        variant="md:w-[48rem] w-full"
        cardTitle="Multiple transfer"
        handleBackNavigation={handleScreenChangeToBankTransfer}
        showGoBackIcon
      >
        <div className="w-full flex items-center justify-center">
          <form className="flex flex-col gap-4 justify-center items-center">
            <div className="text-[#666666]">
              Complete transaction details below
            </div>
            <div className="relative w-full ">
              <div className="mb-2 block text-left text-[#666666]">
                <Label
                  value="Input or select a bank"
                  className=" !text-[#464646] font-normal text-lg leading-[1rem]"
                />
              </div>
              <div className="relative  w-full">
                <select
                  id="select-bank_input"
                  name="selectedBank"
                  className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none focus:border-primary focus:ring-primary"
                  onChange={handleChange}
                  // defaultValue={JSON.stringify({
                  //   bankCode: "9334",
                  //   bankName: "Ridwan Bank",
                  // })}
                  required
                >
                  <option hidden>-- Select --</option>
                  {bankList?.map((bank: any, index: any) => (
                    <option
                      key={index}
                      value={JSON.stringify(bank)}
                      // selected={bank.bankName === "ECOBANK"}
                      selected={
                        thereIsASelectedBank
                          ? selectedBank?.bankCode === bank.bankCode
                          : false
                      }
                    >
                      {bank.bankName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="relative w-full">
              <div className="flex flex-row justify-between">
                <div className="mb-2 text-left">
                  <Label
                    htmlFor="AccountNumber"
                    value="Account number"
                    className=" !text-[#464646] font-normal text-lg leading-[1rem]"
                  />
                </div>
                <div className="mb-2 text-right ">
                  <div
                    id="validate-account_btn"
                    className=" text-primary underline cursor-pointer font-normal text-sm"
                    onClick={handleAccountValidation}
                  >
                    Validate account
                  </div>
                </div>
              </div>

              <input
                id="account-number_input"
                name="AccountNumber"
                type="text"
                className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary"
                value={accountNumber}
                onChange={handleChange}
                minLength={10}
                maxLength={15}
                max={15}
                required
              />
              {!accountValidated && (
                <div className=" text-red-600">{accountValidationError}</div>
              )}
            </div>
            {accountValidated && (
              <div className="relative w-full space-y-2">
                <div>
                  <div className="mb-2 block text-left">
                    <Label
                      htmlFor="BeneficiaryName"
                      value="Name of beneficiary"
                      className=" !text-[#464646] font-normal text-lg leading-[1rem]"
                    />
                  </div>

                  <input
                    id="beneficiary-name_text"
                    name="BeneficiaryName"
                    type="text"
                    className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary"
                    value={accountName ? accountName : ""}
                    // onChange={handleChange}
                    disabled={true}
                  />
                </div>
                <div className="space-y-2">
                  <div className="mb-2 block text-left">
                    <Label
                      htmlFor="Amount"
                      value="Amount"
                      className=" !text-[#464646] font-normal text-lg leading-[1rem]"
                    />
                  </div>
                  <div className="relative w-full">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-[#666666] ">₦</span>
                    </div>
                    <input
                      id="amount_input"
                      name="amount"
                      type="text"
                      className="pl-6 w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary"
                      value={amount.withMoneyFormat}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <input
                  name="amount"
                  type="text"
                  className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm"
                  value={amount}
                  onChange={handleChange}
                  // disabled={true}
                /> */}
                </div>
              </div>
            )}
            {!accountValidated && (
              <div className="flex flex-row justify-center items-center mt-6">
                <Button
                  id="proceed_btn"
                  className="bg-primary hover:bg-white hover:text-white hover:border-primary lg:max-w-sm mb-20 cursor-pointer w-[188px]  focus:ring-1 focus:ring-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                  onClick={handleAccountValidation}
                  disabled={
                    accountNumber.length < 10 || selectedBank.bankCode === ""
                  }
                >
                  Proceed
                </Button>
              </div>
            )}
            {accountValidated && (
              <div className="flex flex-row gap-32 mt-4">
                <Button
                  id="add-recipient_btn"
                  type="button"
                  className="bg-primary hover:bg-transparent hover:text-white hover:border-primary lg:max-w-sm mb-20 cursor-pointer w-[188px]  hover:bg-primary focus:ring-1 focus:ring-primary  dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                  onClick={handleAddRecipient}
                  disabled={
                    amount.withMoneyFormat === "" ||
                    amount.withMoneyFormat === "0" ||
                    accountName === "" ||
                    accountName === undefined
                  }
                >
                  Add recipient
                </Button>
              </div>
            )}
          </form>
        </div>
      </ReusableCard>
      {/* <Card className=" bg-white md:w-[48rem] mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <div
              id="back_btn"
              onClick={handleScreenChangeToBankTransfer}
              className="cursor-pointer"
            >
              <ChevronLeftIcon className="h-6 w-6 text-[#333333]" />
            </div>
            <div className="text-[#4D4D4D] text-xl text-center">
              Multiple transfer
            </div>
            <div></div>
          </div>
        </div>
        <hr />
      </Card> */}
    </div>
  );
};

// {
//   showScreen === "main" ? (
//     <div>Main</div>
//   ) : showScreen == "successful" ? (
//     <div>Successful</div>
//   ) : showScreen == "failed" ? (
//     <div>Failed</div>
//   ) : (
//     <div>Pending</div>
//   );
// }
export default NewRecipientComponent1;
