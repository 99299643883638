import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import ReferenceLetterContainer from "../ReferenceLetter/ReferenceLetterContainer";
import { Form, Formik } from "formik";
import { ToastNotify } from "helpers/toastNotify";
import { FormikButton, TextInput } from "alat-components-library";
import { ReactComponent as SuccessIcon } from "../../../assets/svgs/success-icon.svg";
import FileUpload2 from "pages/Onboarding/Components/FileUpload";
import CenterModal from "alat-components-library/Modal/CenterModal";
import { RadioInput } from "components/reusables/Inputs/RadioInput/RadioInput";
import { useNavigate, useParams } from "react-router-dom";
import useGenerateDudChequeLetter from "hooks/apis/DudCheque/useGenerateDudChequeLetter";
import { useDudChequeCommitment } from "hooks/apis/DudCheque/useDudChequeCommitment";
import PageLoader from "components/Loader/PageLoader";

type Agreement = {
  acknowledged: boolean;
  committed: boolean;
  confirmed: boolean;
};
const CustomerDudCheque = ({ id }: any) => {
  const param = useParams();
  const navigate = useNavigate();

  const {
    blobUrl: commitmentLetterLink,
    imageUrlToBase64,
    isUploadingBlob,
    generateDudChequeLetter,
  } = useGenerateDudChequeLetter();

  const {
    userDudChequeCommitmentStatus,
    userDudChequeCommitmentLoading,
    handleDudChequeCommitment,
  } = useDudChequeCommitment();

  const [accepted, setAccepted] = useState(false);

  const base64String = param.id as string;
  const decodedString = atob(base64String);
  const decodedArray = decodedString.split(":");
  const bvnName = decodedArray?.length === 4 ? decodedArray[0] : "";
  const businessName = decodedArray?.length === 4 ? decodedArray[1] : "";
  const recordCount = decodedArray?.length === 4 ? decodedArray[2] : "";
  const correlationId = decodedArray?.length === 4 ? decodedArray[3] : "";
  // console.log(decodedString);
  // const decodedBuffer = Buffer.from(base64String, "base64");
  // const decodedString = decodedBuffer.toString("utf-8");

  const initialValues = {
    name: bvnName,
    businessName,
    recordCount,
    signature: "",
    acknowledged: false,
    committed: false,
    confirmed: false,
  };

  useEffect(() => {
    if (userDudChequeCommitmentStatus) {
      setAccepted(true);
    }
  }, [userDudChequeCommitmentStatus]);

  useEffect(() => {
    if (commitmentLetterLink) {
      handleDudChequeCommitment({
        commitmentLetterLink,
        correlationId,
      });
    }
  }, [commitmentLetterLink]);

  const validationSchema = Yup.object().shape({
    signature: Yup.string().required("Signature is required"),
    acknowledged: Yup.boolean().oneOf([true], "Acknowledgment is required"),
    committed: Yup.boolean().oneOf([true], "Commitment is required"),
    confirmed: Yup.boolean().oneOf([true], "Confirmation is required"),
  });
  return (
    <ReferenceLetterContainer>
      {(isUploadingBlob || userDudChequeCommitmentLoading) && (
        <PageLoader message="Submitting Form" />
      )}

      <div className="px-5 md:px-0 min-w-[50vw] max-w-[500px] mx-auto flex flex-col md:gap-[4vw] gap-10">
        <div className="flex flex-col gap-2">
          <h1 className="md:text-[3vw] text-4xl font-bold tracking-tight">
            Dud Cheque Records
          </h1>
          <p className="md:text-[1.2vw] text-sm">
            {/* NAME OF CUSTOMER: {wemaRefereeData?.companyName} */}
          </p>
        </div>

        <div className="flex flex-col gap-5">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              //   console.log("Values: ", values);
              if (values?.signature?.toLowerCase().includes("pdf")) {
                ToastNotify({
                  type: "error",
                  message: "Please Upload an image of your signature(JPEG)",
                  position: "top-right",
                });
              }

              // setBusinessName(values?.businessName);
              const signatureBase64Url = await imageUrlToBase64(
                values?.signature
              );
              // setRefereeValues(values);
              await generateDudChequeLetter({
                bvnName: values?.name,
                businessName: values?.businessName,
                signature: signatureBase64Url,
                recordCount: values?.recordCount,
              });
            }}
            validateOnChange
            validateOnBlur
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              handleBlur,
              values,
              errors,
              isValid,
              touched,
              setFieldValue,
              dirty,
              resetForm,
              handleSubmit,
            }) => (
              <Form className="flex flex-col gap-5">
                <TextInput
                  id="bvn-name_input"
                  label="Name"
                  name="name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.name}
                  // error={errors?.businessName}
                  // hasError={errors?.businessName && touched?.businessName}
                  isDisabled
                />
                <TextInput
                  id="business-name_input"
                  label="Business Name"
                  name="businessName"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.businessName}
                  // error={errors?.businessAddress}
                  // hasError={errors?.businessAddress && touched?.businessAddress}
                  isDisabled
                />
                <TextInput
                  id="number-of-records_input"
                  label="Number of Records"
                  name="recordCount"
                  handleChange={handleChange}
                  value={values.recordCount}
                  // error={errors?.bankAddress}
                  isDisabled
                />

                <FileUpload2
                  id="signature_file"
                  label="Upload Signature"
                  name="signature"
                  documentType="signature"
                  info="Document must be in JPEG and not more than 1 mb"
                  handleBlur={handleBlur}
                  error={touched?.signature ? errors?.signature : ""}
                  accept={["image/jpeg"]}
                />
                <RadioInput
                  clickEvent={() =>
                    setFieldValue("acknowledged", !values?.acknowledged)
                  }
                  checked={values?.acknowledged}
                >
                  I {bvnName} hereby acknowledge the dud cheque records flagged
                  to my name.
                </RadioInput>
                <RadioInput
                  clickEvent={() =>
                    setFieldValue("committed", !values?.committed)
                  }
                  checked={values?.committed}
                >
                  By signing this letter, I hereby commit to operating my
                  account(s) with transparency and accountability and not
                  partake in such activities as presenting dud cheques.
                </RadioInput>
                <RadioInput
                  clickEvent={() =>
                    setFieldValue("confirmed", !values?.confirmed)
                  }
                  checked={values?.confirmed}
                >
                  By signing this letter, I hereby confirm that this action will
                  not recur.
                </RadioInput>
                <div className="w-full">
                  <FormikButton
                    id="nonwema-accept_btn"
                    labelText="submit"
                    containerVariant="w-full h-12 rounded-md flex justify-center"
                    isDisabled={!isValid}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {accepted && (
        <CenterModal
          id="success_modal"
          idButton1="success-continue_btn"
          idCloseModal="success_close_btn"
          icon={<SuccessIcon />}
          title="Success!"
          info={`Commitment letter submitted`}
          btnText="Proceed to login"
          handleClick={() => navigate("/signin")}
          handleClose={() => navigate("/signin")}
        />
      )}
    </ReferenceLetterContainer>
  );
};

export default CustomerDudCheque;
