import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { TabsRef, Tabs, Flowbite } from "flowbite-react";
import TransactionHistory from "pages/Payments/TransactionHistory";
import React, { FC, useRef, useState } from "react";

import { useLocation } from "react-router-dom";

const TransactionHistoryTab: FC = () => {
  const { hash } = useLocation();
  const tab = parseInt(hash[1]);

  const [activeTab, setActiveTab] = useState<any>(tab);
  const tabsRef = useRef<TabsRef>(null);

  const theme = {
    tabs: {
      tablist: {
        base: "flex text-center gap-2 z-0",
        tabitem: {
          base: "flex text-center",
          variant: {
            underline: {
              base: "rounded-t-lg",
              active: {
                on: "active py-2 rounded-t-lg border-b-2 border-lib-alat-red text-lib-alat-red font-medium",
                off: "py-2 border-b-2 border-transparent text-lib-alat-black font-medium",
              },
            },
          },
        },
      },
    },
  };

  return (
    <DashboardWrapper>
      <div className="w-full px-4 lg:px-20">
        {/* <div>Active tab: {activeTab === 1}</div> */}
        <div className="text-2xl pt-4 font-semibold">Accounts</div>
        <Flowbite theme={{ theme }}>
          <Tabs
            aria-label="Default tabs"
            // eslint-disable-next-line react/style-prop-object
            variant="underline"
            ref={tabsRef}
            onActiveTabChange={(tab: React.SetStateAction<number>) =>
              setActiveTab(tab)
            }
            className="dark:!border-[#E4DFDF] !border-[#E4DFDF] w-full"
          >
            <Tabs.Item
              active={activeTab === 0}
              title="History"
              className="mr-80 dark:!text-primary dark:!border-primary"
            >
              <TransactionHistory />
            </Tabs.Item>
          </Tabs>
        </Flowbite>
      </div>
    </DashboardWrapper>
  );
};

export default TransactionHistoryTab;
