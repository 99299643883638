import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import { GET_MAPPED_ACCOUNTS } from "../../../utils/apis/routes";
import { ToastNotify } from "helpers/toastNotify";

export const useFetchMappedAccounts = () => {
  //Local States
  const [fetchSuccess, setFetchSuccess] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string>("");
  const [accountsMapped, setAccountsMapped] = useState<any>([]);

  //API Request
  const makeRequest = useApiRequest();

  const handleFetchMappedAccounts = async (username: string) => {
    setFetchLoading(true);
    try {
      const res = await makeRequest({
        method: "get",
        route: GET_MAPPED_ACCOUNTS(username),
        originType: "identity",
      });

      // console.log("res",res);
      //Map the payload
      if (res?.data?.data?.hasError) {
        setFetchSuccess(false);
        ToastNotify({
          type: "error",
          message: `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
          position: "top-center",
        });
      } else {
        setFetchSuccess(true);
        setAccountsMapped(res?.data?.data?.accounts);
      }
      setFetchLoading(false);
    } catch (error) {
      setFetchError(
        "An error occured while fetching branch sol ids, please referesh and try again"
      );
      setFetchLoading(false);
    }
  };

  return {
    fetchSuccess,
    fetchLoading,
    fetchError,
    accountsMapped,
    handleFetchMappedAccounts,
  };
};
