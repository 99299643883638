import React from "react";

interface Props {
  containerVariant?: string;
  title?: string;
  info?: string;
  id?: string;
  icon?: any;
  clickEvent: () => void;
}

export const ActionCard = ({
  containerVariant = "rounded-lg w-[full] h-[157px] bg-lib-alat-gray-card-one m-1 cursor-pointer border hover:border-lib-alat-dark-red",
  title = "Send Funds",
  id = "",
  info,
  icon,
  clickEvent,
}: Props) => {
  // Variable
  const iconStyle = {
    wrapper: "bg-green-500",
    icon: "w-4 h-4 text-white",
  };

  return (
    <div className={`${containerVariant}`} onClick={clickEvent} id={id}>
      <div className="py-2 flex flex-col justify-center items-center h-full text-left space-y-2">
        <div className="w-10 bg-white p-2 rounded-full">{icon}</div>
        <div className="w-10/12 -mt-2">
          <p className="text-xs font-semibold text-center pt-1.5 text-lib-alat-black">
            {title}
          </p>
          <p className="text-xs text-lib-alat-black text-center">{info}</p>
        </div>
      </div>
    </div>
  );
};
