import React, { useRef, useEffect } from "react";
import AFBLogo from "../../../assets/svgs/AFBLogoOpaque.svg";
import { FormikButton } from "../../../alat-components-library";
import { useNavigate } from "react-router-dom";
import { ListItem } from "./RefereeSetupModal";
interface Props {
  open: boolean;
  closeFunction: () => void;
}

const NonWemaRefereeSetupModal = ({ open, closeFunction }: Props) => {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeFunction();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [open]);
  return (
    <div
      className={`fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-50 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm ${!open && "hidden"}`}
    >
      <center>
        <div className="flex justify-center items-center w-full relative pt-20">
          <div
            ref={modalRef}
            className="overflow-auto max-h-[320px] h-auto md:max-h-[70vh] w-full max-w-[550px] bg-white relative border top-10 z-1000 rounded-md py-12 px-12"
          >
            <div className="flex justify-between mb-3 gap-4 items-center">
              <img src={AFBLogo} alt="AFB-logo" className=" w-36" />

              <div
                className=" text-4xl cursor-pointer"
                onClick={() => closeFunction()}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.00098 20L12.001 12M20.001 4L11.9995 12M11.9995 12L4.00098 4M12.001 12L20.001 20"
                    stroke="#606060"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="text-left max-w-[453px] mx-auto">
              <h1 className=" font-bold text-3xl">Referee Requirement</h1>
              <h1 className=" font-semibold text-lg">FOR OTHER BANKS</h1>
              <hr className="mt-[9px]" />
              <div className="flex w-full pt-7 justify-around">
                <div className="w-full">
                  <ListItem text="You cannot use your corporate account in another bank to reference your WEMA account" />
                  <ListItem text="The referee’s account can not be a salary account" />
                  <ListItem text="The referee’s account must be a corporate account" />
                  <ListItem text="The referee’s account must be active for at least 6 months and not restricted" />
                  <ListItem text="The referee must use the same signature tied to their account" />
                </div>
              </div>
              <div
                className="w-full"
                onClick={() => navigate("/referee-setup")}
              >
                <FormikButton
                  id="referee-modal-proceed_btn"
                  labelText="Proceed"
                  containerVariant="w-full h-10 rounded-md flex justify-center mx-auto mt-10"
                />
              </div>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default NonWemaRefereeSetupModal;
