import React, { useEffect, useState, useMemo } from "react";
import ReferenceLetterContainer from "./ReferenceLetterContainer";
import { FormikButton, TextInput } from "alat-components-library";
import FileUpload2 from "pages/Onboarding/Components/FileUpload";
import { Form, Formik } from "formik";
import useGenerateReferenceLetter from "hooks/apis/useGenerateReferenceLetter";
import PageLoader from "components/Loader/PageLoader";
import CenterModal from "alat-components-library/Modal/CenterModal";
import { ReactComponent as SuccessIcon } from "../../../assets/svgs/success-icon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import { useBankLists } from "hooks/apis/Payment/useBankLists";
import { useGetWemaRefereeInfo } from "hooks/apis/Referee/useGetWemaRefereeInfo";
import { wemaRefereeSchema } from "./validation/wemaRefereeSchema";
import { useAcceptNonWemaReferee } from "hooks/apis/Referee/useAcceptNonWemaReferee";
import { ToastNotify } from "helpers/toastNotify";
import { useRejectReferee } from "hooks/apis/Referee/useRejectReferee";
import { useAppDispatch } from "hooks/useReduxHook";
import { setRefereeView } from "react-wrappers/stores/redux/app/slices";

const NonWemaLetter = () => {
  const dispatch = useAppDispatch();
  const [accepted, setAccepted] = useState(false);
  // eslint-disable-next-line
  const [businessName, setBusinessName] = useState("");
  const [refereeValues, setRefereeValues] = useState<any>(null);
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id;
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const type = queryParams.get("type");
  const isIndividual = !!type ? type.toLowerCase() === "individual" : false;

  const { wemaRefereeLoading, wemaRefereeData, getWemaReferee } =
    useGetWemaRefereeInfo();

  const {
    nonWemaRefereeStatus,
    nonWemaRefereeLoading,
    nonWemaRefereeError,
    nonWemaRefereeData,
    handleNonWemaRefereeValidation,
  } = useAcceptNonWemaReferee();

  const {
    refereeRejectionStatus,
    refereeRejectionLoading,
    // refereeRejectionError,
    // refereeRejectionData,
    handleRefereeRejection,
  } = useRejectReferee();

  const initialValues = {
    businessName: "",
    businessAddress: "",
    bank: wemaRefereeData?.bank,
    bankAddress: "",
    accountNumber: "",
    lengthOfRelationship: "",
    phoneNumber1: "",
    phoneNumber2: "",
    signature: "",
  };

  // Fetches a list of banks
  const { bankLists, handleGetBankList } = useBankLists();

  // Maps it to the dropdown
  const getBanks = () =>
    bankLists?.map((bank: any) => ({
      name: bank?.bankName,
      id: bank?.bankCode,
      key: bank?.bankName,
    }));

  // handles rejection :(
  const handleRejection = async () => {
    await handleRefereeRejection({
      correlationId:
        wemaRefereeData?.correlationId || wemaRefereeData?.eReferenceId,
      id: wemaRefereeData?.id || wemaRefereeData?.refereeId,
      isEref: !!type,
    });
  };

  const {
    blobFiles,
    imageUrlToBase64,
    isUploadingBlob,
    generateReferenceLetter,
  } = useGenerateReferenceLetter();

  useEffect(() => {
    if (isIndividual) {
      dispatch(setRefereeView(true));
    } else {
      dispatch(setRefereeView(false));
    }
  }, [dispatch, isIndividual]);

  // Gets a list of all banks ##### - 0
  useEffect(() => {
    handleGetBankList("0");
    // eslint-disable-next-line
  }, []);

  // Gets the id from the url ##### - 1
  useEffect(() => {
    if (id) {
      if (type) {
        getWemaReferee(id, true);
      } else {
        getWemaReferee(id);
      }
    }
    // eslint-disable-next-line
  }, []);

  // Redirect to the WEMA Form if the referee is a wema customer #### - 2
  useEffect(() => {
    if (wemaRefereeData?.bank) {
      if (wemaRefereeData?.bank?.toUpperCase()?.includes("WEMA")) {
        navigate("/reference-letter/wema", {
          state: { wemaRefereeData, isERef: !!type, type },
        });
      }
    }
    // eslint-disable-next-line
  }, [wemaRefereeData]);

  useEffect(() => {
    if (refereeRejectionStatus) {
      navigate("/reference-letter/review/rejected", {
        state: { companyName: wemaRefereeData?.companyName },
      });
    }
  }, [refereeRejectionStatus]);

  // Once the referee Form blob URL is generated, handle the accept(Submit) function.
  useEffect(() => {
    if (blobFiles?.url) {
      // window.open(blobFiles?.url, '_blank');
      //   console.log(blobFiles?.url, "Blob url");
      handleRefereeAction(refereeValues);
    }
    // eslint-disable-next-line
  }, [blobFiles]);

  // Listens for completed status
  useEffect(() => {
    if (nonWemaRefereeStatus) {
      setAccepted(true);
    }
    // eslint-disable-next-line
  }, [nonWemaRefereeData, nonWemaRefereeError, nonWemaRefereeLoading]);

  // This function submits the referee's request
  const handleRefereeAction = useMemo(
    () => (values: any) => {
      if (blobFiles?.url) {
        const initialData = {
          businessName: values?.businessName,
          businessAddress: values?.businessAddress,
          bank: values?.bank,
          accountNumber:
            values?.accountNumber || wemaRefereeData?.refereeAccountNumber,
          relationshipDuration: values?.lengthOfRelationship,
          phoneNumber: values?.phoneNumber1,
          phoneNumber2: values?.phoneNumber2,
          signature: values?.signature,
          isRefereeResubmission: false,
        };
        const formData = {
          id: wemaRefereeData?.id || wemaRefereeData?.refereeId,
          bank: wemaRefereeData?.bank,
          // accountNumber: wemaRefereeData?.refereeAccountNumber,
          emailAddress: wemaRefereeData?.refereeEmail,
          refereeForm: blobFiles?.url,
          isRefereeResubmission: wemaRefereeData?.isRefereeResubmission,
          [type ? "eReferenceId" : "correlationId"]:
            wemaRefereeData?.correlationId || wemaRefereeData?.eReferenceId,
        };
        const refData = Object.assign(initialData, formData);

        handleNonWemaRefereeValidation(refData);
      }
    },
    // eslint-disable-next-line
    [blobFiles]
  );

  return (
    <ReferenceLetterContainer>
      {(isUploadingBlob || wemaRefereeLoading || refereeRejectionLoading) && (
        <PageLoader message="" />
      )}
      {nonWemaRefereeLoading && <PageLoader message="Submitting Form" />}
      <div className="px-5 md:px-0 min-w-[50vw] max-w-[500px] mx-auto flex flex-col md:gap-[4vw] gap-10">
        <div className="flex flex-col gap-2">
          <h1 className="md:text-[3vw] text-4xl font-bold tracking-tight">
            Referee Details
          </h1>
          <p className="md:text-[1.2vw] text-sm">
            NAME OF CUSTOMER:{" "}
            {wemaRefereeData?.companyName || wemaRefereeData?.businessName}
          </p>
        </div>
        <div className="flex flex-col gap-5">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              if (values?.signature?.toLowerCase().includes("pdf")) {
                ToastNotify({
                  type: "error",
                  message: "Please Upload an image of your signature(JPEG)",
                  position: "top-right",
                });
              }

              setBusinessName(values?.businessName);
              const signatureBase64Url = await imageUrlToBase64(
                values?.signature
              );

              setRefereeValues(values);

              await generateReferenceLetter({
                applicantName:
                  wemaRefereeData?.companyName || wemaRefereeData?.businessName,
                duration: values.lengthOfRelationship,
                bankName: wemaRefereeData?.bank,
                address: values.bankAddress,
                accountNo: values.accountNumber,
                phoneNumber: `${values.phoneNumber1} ${values.phoneNumber2}`,
                refereeName: values.businessName,
                refereeAddress: values.businessAddress,
                signature1: signatureBase64Url,
                CacRegNumber: wemaRefereeData?.cacRegNumber,
              });

              await handleRefereeAction(values);
            }}
            validateOnChange
            validateOnBlur
            validationSchema={wemaRefereeSchema(
              ((type as string) || "individual").toLowerCase()
            )}
          >
            {({
              handleChange,
              handleBlur,
              values,
              errors,
              isValid,
              touched,
              dirty,
              resetForm,
              handleSubmit,
            }) => (
              <Form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                <TextInput
                  id="businessname_input"
                  label={
                    !!isIndividual ? "Referee Name" : "Referee Business Name"
                  }
                  name="businessName"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.businessName}
                  hasError={errors?.businessName && touched?.businessName}
                />
                <TextInput
                  id="business-address_input"
                  label={
                    !!isIndividual
                      ? "Referee Address"
                      : "Referee Business Address"
                  }
                  name="businessAddress"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors?.businessAddress}
                  hasError={errors?.businessAddress && touched?.businessAddress}
                />
                <StateDropdown
                  id="referee-bank_dropdown"
                  label="Referee Bank"
                  name="bank"
                  onChange={handleChange}
                  onSelectOption={() => {}}
                  options={getBanks() || []}
                  value={wemaRefereeData?.bank}
                  error={errors?.bank}
                  // disabled
                />
                <TextInput
                  id="bank-address_input"
                  label="Referee Bank Address"
                  name="bankAddress"
                  handleChange={handleChange}
                  error={errors?.bankAddress}
                />
                <TextInput
                  id="account-number_input"
                  label="Referee Account Number"
                  name="accountNumber"
                  placeHolder="Enter your corporate account number"
                  handleChange={handleChange}
                  error={errors?.accountNumber}
                  handleBlur={handleBlur}
                  hasError={errors?.accountNumber && touched?.accountNumber}
                  maxLength={10}
                />
                <TextInput
                  id="length-of-relationship_input"
                  label="How long have you known this customer for?"
                  name="lengthOfRelationship"
                  handleChange={handleChange}
                  error={errors?.lengthOfRelationship}
                  handleBlur={handleBlur}
                  hasError={
                    errors?.lengthOfRelationship &&
                    touched?.lengthOfRelationship
                  }
                />
                <TextInput
                  id="referee-phone-1_input"
                  label="Referee Phone Number 1"
                  name="phoneNumber1"
                  handleChange={handleChange}
                  maxLength={15}
                  error={errors?.phoneNumber1}
                  handleBlur={handleBlur}
                  hasError={errors?.phoneNumber1 && touched?.phoneNumber1}
                />
                <TextInput
                  id="referee-phone-2_input"
                  label="Referee Phone Number 2 (Optional)"
                  name="phoneNumber2"
                  handleChange={handleChange}
                  maxLength={11}
                  error={errors?.phoneNumber2}
                />

                <FileUpload2
                  id="signature_file"
                  label="Signature"
                  name="signature"
                  documentType="signature"
                  info="Document must be in JPEG and not more than 1 mb"
                  handleBlur={handleBlur}
                  error={touched?.signature ? errors?.signature : ""}
                  accept={["image/jpeg"]}
                />
                <div className="w-full">
                  <FormikButton
                    id="nonwema-accept_btn"
                    labelText="submit"
                    containerVariant="w-full h-12 rounded-md flex justify-center"
                    isDisabled={!isValid}
                  />
                </div>
                <div
                  className="w-full rounded-md bg-white flex justify-center h-12 cursor-pointer border border-lib-alat-red items-center text-sm text-lib-alat-red font-semibold"
                  id="nonwema-reject_btn"
                  onClick={handleRejection}
                >
                  Reject
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {accepted && (
        <CenterModal
          id="success_modal"
          idButton1="success-continue_btn"
          idCloseModal="success_close_btn"
          icon={<SuccessIcon />}
          title="Success!"
          info={`You have successfully completed the referee verification for ${wemaRefereeData?.companyName}`}
          btnText="Thank you"
          handleClick={() => navigate("/signin")}
          handleClose={() => navigate("/signin")}
        />
      )}
    </ReferenceLetterContainer>
  );
};

export default NonWemaLetter;
