import AccountCardTwo from "alat-components-library/Cards/AccountCardOne/AccountCardTwo";
import { useAppDispatch } from "hooks/useReduxHook";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  setSelectedActiveCard,
  setSelectedCardForActivation,
  useCardState,
} from "react-wrappers/stores/redux/cards/slices";
import { CardState } from "react-wrappers/stores/redux/interfaces";
import { ReactComponent as PrevSlideBtn } from "../../../alat-components-library/assets/images/icons/back-arrow.svg";
import { ReactComponent as NextSlideBtn } from "../../../alat-components-library/assets/images/icons/next-arrow.svg";

const CardSelectionSlide = ({
  data,
  type,
}: {
  data: CardState["cardsForActivation"];
  type: "activate" | "hotlist" | "change pin";
}) => {
  const [measureWidth, setMeasureWidth] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedCardForActivation, selectedActiveCard } = useCardState();

  useEffect(() => {
    const width = Number(data?.length) * 300 + 30;
    setMeasureWidth(width);
  }, [measureWidth]);

  const scrollSlider = (direction: string) => {
    const element = document.querySelector(".overflow-x-auto") as HTMLElement;
    const distance = 500;
    const duration = 1000;
    const start = element.scrollLeft;
    const end = direction === "left" ? start - distance : start + distance;
    const startTime = performance.now();

    const easeOutCubic = (t: number, b: number, c: number, d: number) => {
      return c * ((t = t / d - 1) * t * t + 1) + b;
    };

    const scroll = (timestamp: number) => {
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easeProgress = easeOutCubic(progress, 0, 1, 1);
      const newPosition = Math.floor(easeProgress * (end - start)) + start;
      element.scrollTo({ left: newPosition });

      if (progress < 1) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };

  if (!data?.length) {
    return (
      <div className="w-full p-5">
        <p className="text-sm text-center text-[#606060]">
          You do not have a card
        </p>
      </div>
    );
  }

  return (
    <div className="w-full py-4 bg-white rounded-md">
      {data?.length <= 2 && data?.length > 0 && (
        <div className="w-full flex flex-row justify-center relative items-center overflow-x-auto">
          <div
            className={`h-48 flex flex-row justify-center  relative items-center  space-x-3`}
            style={{ width: `${measureWidth}px` }}
          >
            {(data || []).map((item: any, index: any) => {
              return (
                <AccountCardTwo
                  cardName={item?.nameOnCard}
                  cardNumber={item?.pan}
                  isActive={
                    selectedCardForActivation?.pan === item?.pan ||
                    selectedActiveCard?.pan === item?.pan
                  }
                  expiryDate={item?.expiryDate}
                  handleClick={() => {
                    if (type === "activate") {
                      if (selectedCardForActivation?.pan !== item?.pan) {
                        dispatch(setSelectedCardForActivation(item));
                      } else {
                        dispatch(setSelectedCardForActivation({}));
                      }
                    }
                    if (type !== "activate") {
                      if (selectedActiveCard?.pan !== item?.pan) {
                        dispatch(setSelectedActiveCard(item));
                      } else {
                        dispatch(setSelectedActiveCard({}));
                      }
                    }
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
      {data?.length > 2 && data?.length > 0 && (
        <div className="flex w-full space-x-2">
          <div
            className="flex items-center justify-center cursor-pointer w-1/12"
            id="leftarrow_btn"
            onClick={() => scrollSlider("left")}
          >
            <PrevSlideBtn />
          </div>
          <div className="w-10/12 h-auto item-center overflow-x-auto hide-scroller">
            <div
              className={`h-48 flex flex-row justify-start relative items-center space-x-3`}
              style={{ width: `${measureWidth}px` }}
            >
              {data?.map((item: any, index: any) => (
                <div className="w-full" key={index}>
                  <AccountCardTwo
                    cardName={item?.nameOnCard}
                    cardNumber={item?.pan}
                    isActive={
                      selectedCardForActivation?.pan === item?.pan ||
                      selectedActiveCard?.pan === item?.pan
                    }
                    expiryDate={item?.expiryDate}
                    handleClick={() => {
                      if (type === "activate") {
                        if (selectedCardForActivation?.pan !== item?.pan) {
                          dispatch(setSelectedCardForActivation(item));
                        } else {
                          dispatch(setSelectedCardForActivation({}));
                        }
                      }
                      if (type !== "activate") {
                        if (selectedActiveCard?.pan !== item?.pan) {
                          dispatch(setSelectedActiveCard(item));
                        } else {
                          dispatch(setSelectedActiveCard({}));
                        }
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            className="flex items-center justify-center cursor-pointer w-1/12 m-0"
            id="rightarrow_btn"
            onClick={() => scrollSlider("right")}
          >
            <NextSlideBtn />
          </div>
        </div>
      )}
    </div>
  );
};

export default CardSelectionSlide;
