import { PrimaryButton } from "alat-components-library";
import { useNavigate } from "react-router-dom";

import { Card } from "flowbite-react";
import { ReactComponent as PendingIcon } from "../../../../assets/svgs/pending-icon-two.svg";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { useDispatch } from "react-redux";

const SuccessScreen = ({ setActiveTab, screenName }: any) => {
  const paymentState = usePaymentState();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-8 pt-6">
      <Card className="md:w-[768px] w-full col-span-full bg-white dark:border-gray-200 mx-auto md:px-6">
        <div className="w-full p-2 break-words">
          <div className="text-center flex justify-center mt-10">
            <PendingIcon />
          </div>
          <div className="text-center text-2xl font-semibold mt-6">
            Thank You!
          </div>
          <p className="text-center w-full mb-5 text-md font-normal text-black mt-10">
            Your transaction is being processed. Reference ID:{" "}
            {paymentState?.transactionReference}
          </p>
          <div className="text-center mb-5 text-sm rounded-lg font-normal text-gray-500 light:text-gray-400 mt-10 mx-auto p-4 bg-[#F5F5F5]">
            Please check the bulk transaction history for more details
          </div>
          <div className="flex justify-center flex-row gap-4 mb-40 mt-10">
            <PrimaryButton
              labelText={"Proceed to Dashboard"}
              variant="font-medium text-xs text-white"
              containerVariant="w-[188px] h-[45px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red"
              handleClick={() => navigate("/user-dashboard")}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SuccessScreen;
