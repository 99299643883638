import { useEffect, useState } from "react";
import { BsFilter } from "react-icons/bs";
import { filterList } from "../utils/dumieData/filterList";
import { FilterInputType } from "./types";

export const FilterInput = ({
  dropList,
  variant,
  filterValue = "All",
  onHandleFiterSelect,
}: FilterInputType) => {
  // Local  State
  const [defaultText, setDefaultText] = useState(
    filterValue === "" ? "Filter" : filterValue
  );
  const [showFilterList, setShowFilterList] = useState(false);
  const [list, setlist] = useState<any>([]);

  // Effect
  useEffect(() => {
    setlist(dropList || filterList);
  }, [dropList]);

  // Functions
  const handleSelectFilter = (item: {
    id: number;
    value: number;
    label: string;
  }) => {
    // Update the default
    setDefaultText(item?.label);

    // Close filter
    setShowFilterList(!showFilterList);

    // return a callback of the selected list
    onHandleFiterSelect(item);
  };

  return (
    <div className="relative">
      <button
        id="dropdownRadioButton"
        onClick={() => setShowFilterList(!showFilterList)}
        className="inline-flex items-center text-gray-500 bg-[#F7F7F7] border outline-none bg-gray-50 font-medium rounded-md text-sm px-3 py-1.5 h-10"
        type="button"
      >
        <BsFilter className="w-4 h-4 mr-2 text-gray-400" />
        {defaultText}
        <svg
          className="w-3 h-3 ml-2"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>
      {showFilterList && (
        <div
          id="dropdownRadio"
          className="absolute z-10 w-full bg-white divide-y divide-gray-100 rounded-lg shadow right-0"
          style={{
            inset: "auto auto 0px 0px",
            transform: variant ? variant : "translate3d(0px, 150px, 0px)",
          }}
        >
          <ul
            className="space-y-1 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownRadioButton"
          >
            {list.length > 0 &&
              list?.map(
                (item: { id: any; label: any; value: any }, index: number) => (
                  <li
                    key={item.id}
                    onClick={() => handleSelectFilter(item)}
                    id={`filter_item_${index + 1}_btn`}
                  >
                    <div className="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                      <label
                        htmlFor="filter-radio-example-1"
                        className="w-full pl-1 cursor-pointer text-xs font-medium text-[#333333] rounded dark:text-[#333333] truncate"
                        id={`filter_item_${index + 1}_text`}
                      >
                        {item.label}
                      </label>
                    </div>
                  </li>
                )
              )}
          </ul>
        </div>
      )}
    </div>
  );
};
