import React from "react";
import { ReactComponent as WemaLogo } from "assets/svgs/WemaLogo.svg";

export interface PDFProps {
  businessName: string;
  bvnName: string;
  recordCount: string;
  signature: string;
}

const DudLetter: React.FC<PDFProps> = ({
  bvnName,
  businessName,
  recordCount,
  signature,
}) => {
  return (
    <div
      style={{ height: "1055px" }}
      className="relative flex flex-col justify-between text-black text-xl"
    >
      <div className="p-2 px-8 leading-10">
        <div className="w-full py-8">
          <div className="flex justify-between">
            <div></div>
            <div className="pr-10">
              <WemaLogo className="w-24 h-24" />
            </div>
          </div>
        </div>
        <div className="pt-20">
          <p className="underline underline-offset-4 text-3xl font-semibold text-center uppercase">
            COMMITMENT LETTER FOR {businessName}
          </p>
        </div>

        <p className="pt-4">
          I {bvnName} with {businessName} and {recordCount} dud cheque records
          hereby acknowledge the dud cheque records flagged to my name.
        </p>
        <p className="py-8">
          I hereby commit to operate my account(s) with transparency and
          accountability and not partake in such activities as presenting dud
          cheques.
        </p>
        <p className="pb-2">
          I hereby confirm that this action will not recur.
        </p>
        <div className="flex justify-between py-16">
          <div className="text-left w-full">
            <img
              src={signature}
              alt="signature1"
              className="border-b-2 w-full h-24"
              height="120px"
            />
            <p>{bvnName}</p>
          </div>
          <div className="text-left w-full"></div>
        </div>
      </div>
      <div className="h-96"></div>
    </div>
  );
};

export default DudLetter;
