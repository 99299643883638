// import { ChevronLeftIcon, PlusIcon } from "@heroicons/react/24/solid";
import { ReactComponent as SearchIcon } from "../../../../assets/svgs/searchIcon.svg";
import { Card } from "flowbite-react";
import React, { useEffect, useMemo, useState } from "react";

import BeneficiaryCard from "./BeneficiaryCard";
import SearchComponent from "./SearchComponent";
import AddNewBeneficiary from "./AddNewBeneficiary";
import { useAppDispatch } from "hooks/useReduxHook";
import { GET_BENEFICIARY_LIST_ACTION } from "react-wrappers/stores/redux/payments/actions";
import {
  setRegularTransferBeneficiaries,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { ToastNotify } from "helpers/toastNotify";
import axiosStatus from "utils/apis/axios/errorStatus";
import Preloader from "alat-components-library/Mics/Preloader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReusableCard } from "components/reusables/Page/ReusableCard";

export type IListAllSavedBeneficiariesProps = {
  accountNumber?: any;
  handleBackToFundsTransfer: any;
  title: string;
  handleScreenChangeNext: any;
  savedBeneficiaryList: any;
  handleSelectedBeneficiary: any;
};

type GetBeneficiariesPayload = {
  pageSize: number;
  pageNumber: number;
  searchValue: string | null;
};

const ListAllSavedBeneficiaries = ({
  title,
  handleBackToFundsTransfer,
  handleScreenChangeNext,
  // savedBeneficiaryList,
  handleSelectedBeneficiary,
}: IListAllSavedBeneficiariesProps) => {
  const dispatch = useAppDispatch();
  const payment = usePaymentState();
  const location = useLocation();
  const [word, setWord] = useState("");
  const [showScreen, setshowScreen] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  // const [trigger, setTrigger] = useState<boolean>(false);
  const [beneficiaryList, setBeneficiaryList] = useState<
    {
      bankCode: string;
      bankName: string;
      accountNumber: string;
      accountName: string;
      currency: string;
    }[]
  >([]);

  const getBeneficiaryLists = async (payload: GetBeneficiariesPayload) => {
    dispatch(GET_BENEFICIARY_LIST_ACTION(payload))
      .unwrap()
      .then((res: any) => {
        if (res?.status === 200) {
          setHasNextPage(res?.data?.data?.hasNextPage);
          setBeneficiaryList(res?.data?.data?.beneficiaries);
          dispatch(
            setRegularTransferBeneficiaries(res?.data?.data?.beneficiaries)
          );
        } else {
          ToastNotify({
            type: "error",
            message:
              res?.status === 500
                ? "Oops! Something went wrong"
                : `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
            position: "top-right",
          });
        }
      })
      .catch((error: any) => {
        ToastNotify({
          type: "error",
          message: `${axiosStatus(error)}`,
          position: "top-right",
        });
      });
  };

  useEffect(() => {
    const payload = {
      pageSize: pageSize,
      pageNumber: 1,
      searchValue: word,
    };
    getBeneficiaryLists(payload);
  }, []);

  useEffect(() => {
    if (location?.state) {
      if (location?.state?.action === 0) {
        setshowScreen(1);
      } else {
        setshowScreen(2);
      }
    } else {
      setshowScreen(1);
    }
  }, []);

  const handleWordChange = (event: { target: { name: any; value: any } }) => {
    if (event.target.name === "word") {
      setWord(event.target.value);
      if (event.target.value === "") {
        getBeneficiaryLists({
          pageSize: 10,
          pageNumber: 1,
          searchValue: event.target.value,
        });
      }
    }
  };

  const filteredsavedBeneficiaryList = useMemo(() => {
    const containsSearchWord = beneficiaryList?.some(
      (item) => item?.accountName?.toLowerCase() === word?.toLowerCase()
    );
    // console.log()
    if (hasNextPage && !containsSearchWord) {
      return beneficiaryList;
    }
    return beneficiaryList?.filter((bene: any) =>
      bene?.accountName?.toLowerCase()?.includes(word?.toLowerCase())
    );
  }, [beneficiaryList, hasNextPage, word]);

  const handleShowScreen = () => {
    setshowScreen(showScreen + 1);
  };

  const isLoading = payment?.loading?.includes("GET_BENEFICIARY_LIST");
  return (
    <div className="flex w-full items-center justify-center">
      {showScreen === 1 && (
        <ReusableCard cardTitle={title} variant="md:w-[48rem] w-full">
          <div className="w-full flex flex-col items-center justify-center">
            <form className="flex flex-col gap-4 justify-center items-center">
              {/* <div className="text-[#666666]">
                Complete transaction details below
              </div> */}
              <div className=" flex flex-row justify-center items-center gap-5">
                <SearchComponent
                  word={word}
                  handleWordChange={handleWordChange}
                  title="Search for beneficiary"
                  // size={450}
                />

                <div
                  className="border-solid w-[2.7rem] py-3.5 flex items-center justify-center border rounded-md cursor-pointer"
                  onClick={() => {
                    getBeneficiaryLists({
                      pageSize: pageSize,
                      pageNumber: 1,
                      searchValue: word,
                    });
                  }}
                >
                  <SearchIcon fill="#828282" />
                </div>
              </div>
              {isLoading ? (
                <div className="flex justify-center w-full bg-white h-52 items-center">
                  <Preloader
                    variant="w-12 h-12"
                    currentColor="#AB0B4B"
                    currentFill="#F8E8E8"
                  />
                </div>
              ) : (
                <div className="mb-5 w-full mx-auto">
                  {beneficiaryList ?? []?.length > 0 ? (
                    beneficiaryList?.map((bank: any, index: any) => (
                      <BeneficiaryCard
                        id={`beneficiary-${index + 1}_button`}
                        beneficiaryId={bank?.id}
                        key={index}
                        // bankCode={bank.bankCode}
                        bankName={bank?.bankName}
                        accountNumber={bank?.accountNumber}
                        accountName={bank?.accountName}
                        isLoading={isLoading}
                        // currency={bank.currency}
                        index={index}
                        handleSelectedBeneficiary={handleSelectedBeneficiary}
                        fetchBeneficiaries={() =>
                          getBeneficiaryLists({
                            pageSize: pageSize + 10,
                            pageNumber: 1,
                            searchValue: word,
                          })
                        }
                      />
                    ))
                  ) : (
                    <>
                      {!isLoading && (
                        <div className="w-full flex items-center justify-center ">
                          <p>No beneficiaries</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </form>
            {hasNextPage && (
              <div className="flex items-center justify-center">
                <p
                  className=" cursor-pointer text-[14px] text-lib-alat-red"
                  onClick={() => {
                    setPageSize(pageSize + 10);
                    getBeneficiaryLists({
                      pageSize: pageSize + 10,
                      pageNumber: 1,
                      searchValue: word,
                    });
                  }}
                >
                  Load More
                </p>
              </div>
            )}
          </div>
        </ReusableCard>
        // <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        //   <div>
        //     <div className="flex items-center justify-between">
        //       {/* <div
        //         id="back_btn"
        //         onClick={handleBackToFundsTransfer}
        //         className="cursor-pointer"
        //       >
        //         <ChevronLeftIcon className="h-6 w-6 text-[#333333]" />
        //       </div> */}
        //       <div></div>
        //       <div className="text-[#4D4D4D] text-[18px] font-semibold text-center">
        //         {title}
        //       </div>
        //       <div></div>
        //     </div>
        //   </div>
        //   <hr />

        // </Card>
      )}

      {showScreen === 2 && <AddNewBeneficiary />}
    </div>
  );
};

export default ListAllSavedBeneficiaries;
