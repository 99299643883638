import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Button, Card, Label } from "flowbite-react";
import React from "react";

// import { ReactComponent as BankIcon } from "../../../../assets/svgs/bank-building.svg";
import { ReactComponent as BankIcon } from "../../../../assets/svgs/bank-building.svg";
import { ReusableCard } from "components/reusables/Page/ReusableCard";

interface ISavedBeneficiaryComponent2Props {
  // selectedBank: any;
  // accountName: any;
  // accountNumber: any;
  // amount: any;
  selectedBeneficiary: any;
  setSelectedBeneficiary: any;
  handleChange: any;
  handleScreenChangePrev: any;
  handleAddBeneficiary: any;
  handleAddRecipient?: any;
}
const SavedBeneficiaryComponent2 = ({
  selectedBeneficiary,
  setSelectedBeneficiary,
  handleChange,
  handleScreenChangePrev,
  handleAddBeneficiary,
  handleAddRecipient,
}: ISavedBeneficiaryComponent2Props) => {
  return (
    <div className="w-full flex items-center justify-center">
      <ReusableCard
        variant="md:w-[48rem] w-full"
        cardTitle="New Transfer"
        handleBackNavigation={handleScreenChangePrev}
        showGoBackIcon
      >
        <div className="w-full flex items-center justify-center">
          <form className="flex w-full flex-col gap-4 justify-center items-center mt-4">
            <div className="text-[#666666]">
              Complete transaction details below
            </div>
            <div>
              <div className="text-[#666666] mb-2 text-sm font-normal">
                Transfer to
              </div>

              <Card className="box-border md:w-[400px] w-full md:h-[114px] h-auto rounded-lg text-white bg-[image:url('./assets/svgs/card-background.svg')] bg-no-repeat bg-cover">
                <div className="flex flex-row w-full justify-between items-center">
                  <div>
                    <div className="text-xs">Account Name</div>
                    <div className="text-base" id="beneficiary-name_text">
                      {selectedBeneficiary?.accountName?.toUpperCase()}
                    </div>
                  </div>
                  <div>
                    <BankIcon fill="white" />
                  </div>
                </div>
                <div className="flex flex-row w-full justify-between items-center">
                  <div>
                    <div className="text-xs">Account Number</div>
                    <div className="text-base" id="beneficiary-accNo_text">
                      {selectedBeneficiary?.accountNumber}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-right">Bank</div>
                    <div className="text-base" id="bank-name_text">
                      {selectedBeneficiary?.bankName?.toUpperCase()}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="w-full">
              <div className="relative lg:max-w-sm lg:mx-auto w-full">
                <div className="mb-2 block text-left mt-4">
                  <Label
                    htmlFor="amount"
                    value="Amount"
                    className=" text-[#464646] font-normal dark:text-[#464646] "
                  />
                </div>
                <div className="relative">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-[#666666] ">₦</span>
                  </div>
                  <input
                    id="beneficiary-amount_text"
                    name="amount"
                    type="text"
                    className="pl-6 w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm"
                    value={selectedBeneficiary?.amount}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex flex-row justify-center items-center gap-32 mt-10">
                <Button
                  id="add-recipient_btn"
                  type="button"
                  className="bg-primary hover:bg-transparent hover:border-primary lg:max-w-sm mb-20 cursor-pointer w-[188px] disabled:hover:bg-red-200  hover:bg-primary focus:ring-1 focus:ring-primary  dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary disabled:focus:ring-red-200  disabled:bg-red-200"
                  onClick={() => {
                    handleScreenChangePrev();
                    handleAddBeneficiary(selectedBeneficiary);
                  }}
                  disabled={selectedBeneficiary?.amount < 1}
                >
                  Add recipient
                </Button>
              </div>
            </div>
          </form>
        </div>
      </ReusableCard>
      {/* <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <div
              id="back_btn"
              onClick={handleScreenChangePrev}
              className="cursor-pointer"
            >
              <ChevronLeftIcon className="h-6 w-6 text-[#333333]" />
            </div>
            <div className="text-[#4D4D4D] text-xl text-center  font-semibold">
              New Transfer
            </div>
            <div></div>
          </div>
        </div>
        <hr />

        
      </Card> */}
    </div>
  );
};

// {
//   showScreen === "main" ? (
//     <div>Main</div>
//   ) : showScreen == "successful" ? (
//     <div>Successful</div>
//   ) : showScreen == "failed" ? (
//     <div>Failed</div>
//   ) : (
//     <div>Pending</div>
//   );
// }
export default SavedBeneficiaryComponent2;
