import React, { ReactNode } from "react";
import "./toggle.css";

export interface Props {
  id?: any;
  children?: ReactNode;
  handleChange?: any;
  name?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  clickEvent?: () => void;
}

export const Toggle: React.FC<Props> = ({
  id,
  checked,
  name,
  handleChange,
  defaultChecked,
}) => {
  return (
    <label className="switch ">
      <input
        id={id}
        type="checkbox"
        name={name}
        onChange={(e) => handleChange(e)}
        checked={checked}
        defaultChecked={defaultChecked}
      />
      <span className="slider round"></span>
    </label>
  );
};
