import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { Button, Card, Label } from "flowbite-react";
import React from "react";
// import axios from "axios";

import { useNavigate } from "react-router-dom";

// import { ReactComponent as BankIcon } from "../../../../assets/svgs/bank-building.svg";

interface INewTransferComponent1Props {
  bankList: any;
  selectedBank: any;
  accountNumber: any;
  accountValidated: any;
  accountValidationError: any;
  beneficiaryName: any;
  handleChange: any;
  handleBackToFundsTransfer: any;
  handleAccountValidation: any;
  handleScreenChangeNext: any;
}
const NewTransferComponent1 = ({
  bankList,
  selectedBank,
  accountNumber,
  accountValidated,
  accountValidationError,
  beneficiaryName,
  handleChange,
  handleBackToFundsTransfer,
  handleAccountValidation,
  handleScreenChangeNext,
}: INewTransferComponent1Props) => {
  // const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="w-full flex items-center justify-center">
      <ReusableCard
        variant="md:w-[48rem] w-full"
        cardTitle="New Transfer"
        headingId="newtransfer_heading"
        handleBackNavigation={handleBackToFundsTransfer}
        showGoBackIcon
      >
        <div className="flex w-full items-center justify-center">
          <form className="flex flex-col gap-4 justify-center items-center">
            <div className="text-[#666666] my-4">
              Complete transaction details below
            </div>

            <div className="relative w-full mb-2">
              <div className="mb-2 block text-left text-[#666666]">
                <Label
                  value="Input or select a bank"
                  className=" !text-[#464646] font-normal text-lg leading-[1rem]"
                />
              </div>
              <div className="relative  w-full">
                <select
                  name="selectedBank"
                  className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none focus:border-primary focus:ring-primary"
                  onChange={handleChange}
                  id="banks_dropdown"
                  required
                >
                  <option hidden>-- Select --</option>
                  {(bankList?.length > 0 ? bankList : []).map(
                    (bank: any, index: any) => (
                      <option key={index} value={JSON.stringify(bank)}>
                        {bank.bankName}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>

            <div className="relative w-full  mb-2">
              <div className="flex flex-row justify-between">
                <div className="mb-2 text-left">
                  <Label
                    htmlFor="AccountNumber"
                    value="Account number"
                    className=" !text-[#464646] font-normal text-lg leading-[1rem]"
                  />
                </div>
                <div className="mb-2 text-right ">
                  <div
                    className=" text-primary underline cursor-pointer font-normal text-sm"
                    onClick={handleAccountValidation}
                    id="validateaccount_btn"
                  >
                    Validate account
                  </div>
                </div>
              </div>

              <input
                name="AccountNumber"
                type="text"
                className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary"
                value={accountNumber}
                onChange={handleChange}
                minLength={10}
                maxLength={15}
                max={15}
                required
                id="accountnumber_input"
              />
              {!accountValidated && (
                <div className=" text-red-600">{accountValidationError}</div>
              )}
            </div>
            {accountValidated && (
              <div className="relative w-full mb-4">
                <div className="mb-2 block text-left">
                  <Label
                    htmlFor="BeneficiaryName"
                    value="Name of beneficiary"
                    className=" !text-[#464646] font-normal text-lg leading-[1rem]"
                  />
                </div>

                <input
                  id="beneficiary-name_text"
                  name="BeneficiaryName"
                  type="text"
                  className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary"
                  value={beneficiaryName}
                  // onChange={handleChange}
                  disabled={true}
                />
              </div>
            )}

            {!accountValidated && (
              <div className="flex flex-row justify-center items-center">
                <Button
                  className="bg-primary hover:bg-white hover:text-white hover:border-primary lg:max-w-sm mb-20 cursor-pointer w-[188px]  focus:ring-1 focus:ring-primary  dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                  onClick={handleAccountValidation}
                  disabled={
                    accountNumber.length < 10 || selectedBank.bankCode === ""
                  }
                  id="newtransferproceed_btn"
                >
                  Proceed
                </Button>
              </div>
            )}
            {accountValidated && (
              <div className="flex flex-row gap-2">
                {/* <Button
                type="button"
                className="!bg-white !text-primary !hover:bg-primary !hover:text-white !border-primary lg:max-w-sm mb-20 !cursor-pointer w-[188px] hover:bg-primary focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary"
                // onClick={handleSubmit}
                // disabled={
                //   amount < 100 ||
                //   accountToCredit.accountNo === "" ||
                //   accountToDebit.accountNo === "" ||
                //   pin.length < 4 ||
                //   isLoading
                // }
              >
                Add as beneficiary
              </Button> */}
                <Button
                  type="button"
                  className="bg-primary hover:bg-transparent hover:text-white hover:border-primary lg:max-w-sm mb-20 cursor-pointer w-[188px]  hover:bg-primary focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary"
                  onClick={handleScreenChangeNext}
                  disabled={
                    accountNumber === "" || selectedBank.bankName === ""
                  }
                  id="newtransfercont_btn"
                >
                  Continue
                </Button>
              </div>
            )}
          </form>
        </div>
      </ReusableCard>
      {/* <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center w-full justify-between">
            <div
              onClick={handleBackToFundsTransfer}
              className="cursor-pointer"
              id="back_btn"
            >
              <ChevronLeftIcon className="h-6 w-6 text-[#333333]" />
            </div>
            <div
              className="text-[#4D4D4D] text-xl text-center font-semibold"
              id="newtransfer_heading"
            >
              New Transfer
            </div>
            <div></div>
          </div>
        </div>
        <hr />
      </Card> */}
    </div>
  );
};

// {
//   showScreen === "main" ? (
//     <div>Main</div>
//   ) : showScreen == "successful" ? (
//     <div>Successful</div>
//   ) : showScreen == "failed" ? (
//     <div>Failed</div>
//   ) : (
//     <div>Pending</div>
//   );
// }
export default NewTransferComponent1;
