import { useApiRequest } from "../../useApiRequest";
import React, { useState } from "react";
import { useAppDispatch } from "../../useReduxHook";
import {
  setLoadingState,
  setDeleteUser,
} from "../../../react-wrappers/stores/redux/admin/slices";
import { DELETE_USER } from "../../../utils/apis/routes/admin";
import { ToastNotify } from "../../../helpers/toastNotify";

export const useDeleteUser = () => {
  const makeRequest = useApiRequest();
  const dispatch = useAppDispatch();
  const [isDeleted, setIsDeleted] = useState<Boolean>(false);

  const handleDeleteUser = async (payload: any) => {
    dispatch(setLoadingState(["delete-user"]));
    try {
      const response = await makeRequest({
        method: "delete",
        route: DELETE_USER,
        body: payload,
        originType: "identity",
      });

      // console.log("res", response);
      if (response?.status === 200) {
        setIsDeleted(true);
        dispatch(
          setDeleteUser({
            successStatus: true,
            responseMessage: response.data?.responseMessage,
          })
        );
      } else if (response?.status === 500) {
        setIsDeleted(false);
        dispatch(
          setDeleteUser({
            successStatus: false,
            respoMessage: {},
          })
        );
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        setIsDeleted(false);
        dispatch(
          setDeleteUser({
            successStatus: false,
            respoMessage: {},
          })
        );
        ToastNotify({
          type: "error",
          message: `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      setIsDeleted(false);
      dispatch(
        setDeleteUser({
          successStatus: false,
          respoMessage: {},
        })
      );
      ToastNotify({
        type: "error",
        message: `${err?.data?.responseMessage?.title}. ${err?.data?.responseMessage?.actionMessage}`,
        position: "top-right",
      });
    }
  };
  return {
    handleDeleteUser,
    isDeleted,
  };
};
