import { XMarkIcon } from "@heroicons/react/24/solid";
import { PrimaryButton } from "alat-components-library";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PageHeader from "components/reusables/Page/PageHeader";
import { Button, Card } from "flowbite-react";
import { NavLink, useNavigate } from "react-router-dom";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";

const ApproverReject = () => {
  const navigate = useNavigate();
  const { approvePendingTransactionsOrActions } = usePaymentState();

  return (
    <DashboardWrapper>
      <div className="container-fluid 2xl:container mx-auto ">
        <div className="container-fluid 2xl:container mx-auto">
          <main className="max-h-screen overflow-auto">
            <div className=" py-8">
              <div className="max-w-6xl mx-auto">
                <div className="rounded-3xl mb-5">
                  <div className="ml-4 pb-14">
                    <PageHeader title="Bills Request" />
                  </div>

                  <Card className="md:w-2/3 w-[95%] bg-white dark:border-gray-200 mx-auto">
                    <div>
                      <div className="flex mb-2 justify-center items-center">
                        <h2
                          className="text-2xl font-semibold"
                          id="requestType_text"
                        >
                          Overview
                        </h2>
                      </div>
                      <hr />
                      <div className="text-center flex justify-center mt-10">
                        <div className="flex justify-center items-center w-16 h-16 bg-green-200 rounded-full">
                          <div className="flex justify-center items-center w-10 h-10 bg-green-600 rounded-full">
                            <div className=" flex justify-center items-center w-8 h-8 bg-white rounded-full">
                              <div className=" flex justify-center items-center w-6 h-6 bg-green-600 rounded-full">
                                <XMarkIcon className="mx-auto w-6 h-6 text-white opacity-100 light:text-gray-200" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center text-3xl font-semibold mt-6">
                        {approvePendingTransactionsOrActions?.successMessage
                          ?.title ?? "Request Rejected"}
                      </div>
                      <h3 className="text-center mb-5 text-lg font-normal text-gray-500 light:text-gray-400 mt-10">
                        {approvePendingTransactionsOrActions?.successMessage
                          ?.actionMessage ??
                          "We will send this notification to the initiator"}
                      </h3>
                      <div className="w-full flex flex-col items-center justify-center space-y-5">
                        <PrimaryButton
                          id="nextPendingAction_btn"
                          containerVariant={
                            "w-1/2 h-10 rounded-md flex justify-center cursor-pointer bg-lib-alat-red"
                          }
                          labelText={"Go to the next pending action"}
                          handleClick={() => {
                            navigate("/payments/pending-transactions");
                          }}
                          variant={"text-white"}
                        />
                        <NavLink
                          to={"/user-dashboard"}
                          className="text-lib-alat-dark-red"
                        >
                          <div className="cursor-pointer">
                            <p
                              className="leading-[28px] tracking-tighter font-semibold text-sm"
                              id="returnToDashboard_btn"
                            >
                              Return to dashboard
                            </p>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default ApproverReject;
