import React, { ReactNode, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

interface Props {
  text: string;
  children?: ReactNode;
  showIcon?: boolean;
}

const TermsSubAccordion: React.FC<Props> = ({
  text,
  children,
  showIcon = true,
}) => {
  const [terms, setTerms] = useState(false);
  return (
    <>
      <div
        className={`z-[2000] bg-white rounded-md transition-[height] duration-1000 ease-in-out
            ${!terms ? "max-h-16 overflow-hidden" : "max-h-[50000px]"}`}
      >
        <div
          className="flex items-center justify-between  h-full py-5 border-t border-b px-6"
          onClick={() => (showIcon ? setTerms(!terms) : "")}
        >
          <div className="font-bold">{text}</div>
          <div>
            {showIcon && (
              <div className="w-6 flex flex-col gap-3 cursor-pointer">
                <ChevronDownIcon className={`${terms ? "" : "-rotate-90"}`} />
              </div>
            )}
          </div>
        </div>
        <div className=""></div>
        <div className="px-4 py-2 flex flex-col gap-2">{children}</div>
      </div>
    </>
  );
};

export default TermsSubAccordion;
