import { useState } from "react";
import { useApiRequest } from "hooks/useApiRequest";
import { GET_ALL_REFEREES_INFO } from "utils/apis/routes";

export const useGetReferees = () => {
  //Local States
  const [refereesLoading, setRefereesLoading] = useState<boolean>(false);
  const [refereesError, setRefereesError] = useState<string>("");
  const [refereesData, setRefereesData] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const getAllReferees = async (correlationId: string) => {
    setRefereesError("");
    setRefereesLoading(true);
    try {
      const res = await makeRequest({
        method: "get",
        route: GET_ALL_REFEREES_INFO(correlationId),
        originType: "onboarding",
      });

      if (res?.data) {
        setRefereesData(res?.data?.data);
        setRefereesError("");
      } else {
        setRefereesError("An error occured while fetching Referee data");
      }
      setRefereesLoading(false);
    } catch (error) {
      setRefereesError(
        "An error occured while fetching referee information, please refresh and try again"
      );
      setRefereesLoading(false);
    }
  };
  return {
    refereesLoading,
    refereesError,
    refereesData,
    getAllReferees,
  };
};
