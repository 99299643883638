import { useState } from "react";
import { useApiRequest } from "../../../useApiRequest";
import { GET_STAMP_STATEMENT_SUMMARY } from "../../../../utils/apis/routes";
import { ToastNotify } from "../../../../helpers/toastNotify";

export const initialValues = {
  total: 0,
  processingFee: 0,
  vatPercentage: 0,
};
export const useAccountStatementSummary = () => {
  //Local States
  const [summaryLoading, setSummaryLoading] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState(initialValues);

  //API Request
  const makeRequest = useApiRequest();

  const handleSummary = async () => {
    setSummaryData(initialValues);
    setSummaryLoading(true);
    try {
      const res = await makeRequest({
        method: "get",
        route: GET_STAMP_STATEMENT_SUMMARY,
        originType: "identity",
      });

      //Map the payload
      if (res?.status !== 200) {
        if (res?.data?.data?.hasError) {
          ToastNotify({
            type: "error",
            message: res?.data?.data?.responseMessage?.title,
            position: "top-right",
          });
        } else {
          ToastNotify({
            type: "error",
            message: "Something went wrong",
          });
        }
      }
      if (res?.status === 200) {
        setSummaryData(res?.data?.data);
      } else {
        const errMsg =
          res?.data?.responseMessage?.title ||
          "An error occured while generating summary";
        ToastNotify({
          type: "error",
          message: errMsg,
          position: "top-right",
        });
      }

      setSummaryLoading(false);
    } catch (error) {
      const errMsg = "An error occured while generating summary";
      ToastNotify({
        type: "error",
        message: errMsg,
        position: "top-right",
      });

      setSummaryLoading(false);
    }
  };

  return {
    summaryData,
    summaryLoading,
    handleSummary,
  };
};
