import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OnboardingState } from "../interfaces";
import { RootState } from "../store";
import { useAppSelector } from "../../../../hooks/useReduxHook";

const dataObject = {
  loading: [],
  dimecId: "",
  existingCustomer: false,
  newCustomer: false,
  accountNumber: "",
  existingCustomerData: {
    reg_number: "",
    businessType: 0,
    fullName: "",
    phoneNumber: "",
    cif: "",
  },
  refereeStatus: false,
  refereeAccountNumber: "",
  refereeIndex: 0,
  infoRequest: {
    taxNumber: "",
    businessRequest: {
      regNumber: "",
      businessType: 0,
    },
  },
  operationalAddress: "",
  city: "",
  state: "",
  lga: "",
  lcda: "",
  bvn: "",
  bvnData: {},
  businessCategory: "",
  businessSubCategory: "",
  businessDescription: "",
  selectedBusinessCategory: [],
  companyType: "",
  bvnStatus: false,
  tax_number: "",
  businessData: {},
  username: "",
  fullName: "",
  mobileNo: "",
  emailAddress: "",
  referralCode: "",
  password: "",
  correlationId: "",
  accountType: "",
  populateAddress: false,
  securityQuestions: {
    question: "",
    answer: "",
  },
  paymentAccount: "",
  paymentAmount: 0,
  // Save Business Data Payload
  businessPayload: {
    infoRequest: {
      tax_number: "",
      businessRequest: {
        reg_number: "",
        businessType: 0,
      },
    },
    name: "",
    phoneNumber: "",
    email: "",
    registrationDate: "",
    businessCategory: "",
    businessSubCategory: "",
    businessDescription: "",
    identificationCard: {
      idType: 0,
      idNumber: "",
      issueDate: new Date().toISOString(),
      expiryDate: new Date().toISOString(),
      frontImageLink: "",
      backImageLink: "",
      signatureLink: "",
    },
    licenseCard: {
      hasLicense: true,
      certificateOfRegistrationLink: "",
      certificateLink: "",
      registrationNumber: "",
      issueDate: new Date().toISOString(),
      expiryDate: new Date().toISOString(),
    },
  },
  contactAddress: {
    address: "",
    landmark: "",
    state: "",
    city: "",
    lga: "",
    lcda: "",
  },
  businessAddress: {
    branchRegion: "",
    branchId: "",
    preferredRegion: "",
    state: "",
    city: "",
    lga: "",
    lcda: "",
    address: "",
  },
  dimecIDRequest: {
    email: "",
    cif: "",
    phoneNumber: "",
    bvn: "",
    channel: "",
    device: 0,
  },
  licenseData: {
    hasLicense: false,
    licenseDescription: "",
    licenseType: null,
  },
  alatPay: false,
  refereePayload: [
    {
      id: "",
      bank: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      accountNumber: "",
      accepted: false,
    },
    {
      id: "",
      bank: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      accountNumber: "",
      accepted: false,
    },
  ],
  unRegisteredBusiness: {
    hasLicense: false,
    registrationType: null,
    correlationId: "",
    paymentAccount: "",
    branchRegion: "",
    businessCategory: "",
    businessSubCategory: "",
    email: "",
    preferredBranch: "",
    businessProfile: {
      isNewAccountType: null,
      accountType: "",
      CacRegNumber: "",
      contactAddress: {
        address: "",
        landmark: "",
        city: "",
        state: "",
        lga: "",
        lcda: "",
      },
    },
  },
  isNewAccountType: null,
  verifyUsername: {
    successStatus: false,
    errorMessage: "",
    responseData: {},
  },
} as OnboardingState;

export const initialState: OnboardingState = { ...dataObject };

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,

  reducers: {
    resetOnboardingData: (): OnboardingState => initialState,

    saveOnboardingData: (
      state: OnboardingState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    saveUnregisteredBizData: (
      state: OnboardingState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        unRegisteredBusiness: {
          ...state.unRegisteredBusiness,
          ...action.payload,
        },
      };
    },
    updateFirstReferee: (
      state: OnboardingState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        refereePayload: [
          {
            ...state.refereePayload[0], // copy the first item
            ...action.payload, // overwrite any properties passed in action.payload
          },
          ...state.refereePayload.slice(1), // copy the rest of the items
        ],
      };
    },
    updateSecondReferee: (
      state: OnboardingState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        refereePayload: [
          ...state.refereePayload.slice(0, 1), // copy the first item
          {
            ...state.refereePayload[1], // copy the second item
            ...action.payload, // overwrite any properties passed in action.payload
          },
          ...state.refereePayload.slice(2), // copy the rest of the items
        ],
      };
    },
    deleteReferee: (state: OnboardingState, action: PayloadAction<number>) => {
      const index = action.payload;
      const updatedReferee = {
        id: "",
        bank: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        accountNumber: "",
        accepted: false,
      };

      return {
        ...state,
        refereePayload: [
          ...state.refereePayload.slice(0, index), // copy the items before the updated element
          updatedReferee, // replace the element with the updated version
          ...state.refereePayload.slice(index + 1), // copy the items after the updated element
        ],
      };
    },
  },
});

// Reducers and actions
export const {
  resetOnboardingData,
  saveOnboardingData,
  updateFirstReferee,
  updateSecondReferee,
  deleteReferee,
  saveUnregisteredBizData,
} = onboardingSlice.actions;

//App Redux State
export default onboardingSlice.reducer;
