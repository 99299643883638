import { NotificationStatusEnum } from "utils/enums";

export const NotificationStatusBadge = ({ status }: any) => {
  const statusText = () => {
    switch (status) {
      case NotificationStatusEnum["Approved"]:
        return "APPROVED";
      case NotificationStatusEnum["Rejected"]:
        return "REJECTED";
      case NotificationStatusEnum["Pending"]:
        return "PENDING";
      case NotificationStatusEnum["Verified"]:
        return "VERIFIED";
      case NotificationStatusEnum["PendingApproval"]:
        return "PENDING APPROVAL";
      case NotificationStatusEnum["PendingVerification"]:
        return "PENDING VERIFICATION";
      default:
        return "PENDING";
    }
  };

  return (
    <p
      className={`text-center font-semibold text-[10px] leading-[13px] rounded-[6px] 
        ${
          status == NotificationStatusEnum["Approved"] &&
          `text-[#3BB54A] p-2 bg-[#E4EFE6]`
        }
            ${
              status == NotificationStatusEnum["Pending"] &&
              `text-[#FF9900] p-2 bg-[#FF99001A]`
            }
              ${
                status == NotificationStatusEnum["Rejected"] &&
                `text-[#FF0000] p-2 bg-[#FF00001A]`
              }
              ${
                status == NotificationStatusEnum["PendingApproval"] &&
                `text-[#FF9900] p-2 bg-[#FF99001A]`
              }
              ${
                status == NotificationStatusEnum["PendingVerification"] &&
                `text-[#FF9900] p-2 bg-[#FF99001A]`
              }
            `}
    >
      {statusText()}
    </p>
  );
};
