import { ToastNotify } from "helpers/toastNotify";
import { useApiRequest } from "hooks/useApiRequest";
import { useAppDispatch } from "hooks/useReduxHook";
import React, { useState } from "react";
import { setLoadingState } from "react-wrappers/stores/redux/payments/slices";
import { GET_HANDLE_REFRESH } from "utils/apis/routes";

export const usePurchaseBillStatus = () => {
  const dispatch = useAppDispatch();
  //Local States
  const [billPurchaseStatus, setBillPurchaseStatus] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const handleFetchBillPurchaseStatus = async (payload: string) => {
    try {
      dispatch(setLoadingState(["fetch-bill-purchase-status"]));
      const res = await makeRequest({
        method: "GET",
        route: GET_HANDLE_REFRESH(payload),
        originType: "transferservice",
      });
      // console.log("fetch-bill-status", res);
      if (res?.status === 200) {
        setBillPurchaseStatus(res?.data);
      } else if (res?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
        });
      } else {
        ToastNotify({
          type: "error",
          message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
        });
      }
      dispatch(setLoadingState([]));
    } catch (error) {
      dispatch(setLoadingState([]));
    }
  };
  return {
    billPurchaseStatus,
    handleFetchBillPurchaseStatus,
  };
};
