export const filterList = [
  {
    id: 1001,
    label: "All",
    value: 0,
  },
  {
    id: 1002,
    label: "LastTwentyFourHours",
    value: 1,
  },
  {
    id: 1003,
    label: "LastSevenDays",
    value: 2,
  },
  {
    id: 1004,
    label: "LastOneMonth",
    value: 3,
  },
  {
    id: 1005,
    label: "LastOneYear",
    value: 4,
  },
];
export const pendingActivityType = (type: number) => {
  switch (type) {
    case 1:
      return "Fund my account";
    case 2:
      return "Intra bank transfer";
    case 3:
      return "Intra bank transfer";
    case 4:
      return "Multiple transfer";
    case 5:
      return "Bulk transfer";
    case 6:
      return "New User Request";
    case 7:
      return "Soft Token Request";
    case 8:
      return "Review Transaction";
    case 9:
      return "User Modification";
    default:
      return "";
  }
};

export const requestTypeTitle = (type: number) => {
  switch (type) {
    case 6:
      return "New User";
    case 7:
      return "Soft Token Request";
    case 9:
      return "User Modification";
    default:
      return "";
  }
};

export const approvalOperationTypeEnum = (type: number) => {
  switch (type) {
    case 6:
      return 1;
    case 7:
      return 2;
    case 9:
      return 3;
    default:
      return 0;
  }
};

// type InitiatorRoleArgs = {
//   type: number,
//   tokenRequest,

// }

// export const initiatorRoleForUserRequests = ({ }) => {
//   switch (type) {
//     case 6:
//       return "Admin:"
//     case 7:
//       return "Soft Token Request";
//     case 9:
//       return "User Modification";
//     default:
//       return "";
//   }
// }
