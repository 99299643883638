export const DocumentTypeMetaData = {
  id: "AFB_IDCARD",
  signature: "AFB_SIGNATURE",
  driversLicense: "AFB_DRIVERSLICENSE",
  certificate: "AFB_CERTIFICATEOFREGISTRATION",
  nin: "AFB_NIN",
  nationalId: "AFB_NATIONALIDCARD",
  intlPassport: "AFB_INTLPASSPORT",
  votersCard: "AFB_VOTERSCARD",
  license: "AFB_LICENSE",
  proformaInvoice: "AFB_PROFORMA_INVOICE",
};

export enum IdType {
  DRIVERSLICENSE = 0,
  NIN = 1,
  NATIONALIDCARD = 2,
  INTLPASSPORT = 3,
  VOTERSCARD = 4,
}

export enum PendingActionEnum {
  IDCARD = 0,
  CACCERTIFICATE = 1,
  SIGNATURE = 2,
  TIN = 3,
  REFEREE = 4,
  BUSINESSADDRESS = 5,
}
