import { useNavigate } from "react-router-dom";
import { PendingActionEnum } from "utils/enums";

export const useNextScreen = () => {
  const navigate = useNavigate();
  const handleNextScreen = (actions: any) => {
    const action = actions[0];
    if (action?.pendingActionType === PendingActionEnum.IDCARD) {
      navigate("/pending-actions/rejected-docs/id-upload", {
        state: { pendingData: action },
      });
    }
    if (action?.pendingActionType === PendingActionEnum.BUSINESSADDRESS) {
      navigate("/pending-actions/rejected-docs/businessaddress-upload", {
        state: { pendingData: action },
      });
    }
    if (action?.pendingActionType === PendingActionEnum.CACCERTIFICATE) {
      navigate("/pending-actions/rejected-docs/cac-upload", {
        state: { pendingData: action },
      });
    }
    if (action?.pendingActionType === PendingActionEnum.SIGNATURE) {
      navigate("/pending-actions/rejected-docs/signature-upload", {
        state: { pendingData: action },
      });
    }
    if (action?.pendingActionType === PendingActionEnum.TIN) {
      navigate("/pending-actions/rejected-docs/tin-upload", {
        state: { pendingData: action },
      });
    }
    if (action?.pendingActionType === PendingActionEnum.REFEREE) {
      navigate("/pending-actions/rejected-docs/referee-upload", {
        state: { pendingData: action },
      });
    }
  };
  return {
    handleNextScreen,
  };
};
