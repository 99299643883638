import { createAsyncThunk } from "@reduxjs/toolkit";
import { useApiRequest } from "../../../../hooks/useApiRequest";
import {
  POST_SUBMIT_CARD_REQUEST,
  GET_ACCOUNT_CARDS,
  POST_SUBMIT_CARD_ACTIVATION,
  GET_CARD_HOTLIST_REASON,
  POST_SUBMIT_CARD_HOTLIST,
  POST_SUBMIT_CARD_CHANGE_PIN,
} from "../../../../utils/apis/routes";

export const POST_SUBMIT_CARD_REQUEST_ACTION = createAsyncThunk(
  "POST_SUBMIT_CARD_REQUEST",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_SUBMIT_CARD_REQUEST,
      body: payload,
      originType: "identity",
    });
  }
);

export const POST_SUBMIT_CARD_ACTIVATION_ACTION = createAsyncThunk(
  "POST_SUBMIT_CARD_ACTIVATION",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_SUBMIT_CARD_ACTIVATION,
      body: payload,
      originType: "identity",
    });
  }
);

export const GET_ACCOUNT_CARDS_ACTION = createAsyncThunk(
  "GET_ACCOUNT_CARDS",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "get",
      route: GET_ACCOUNT_CARDS(payload.accountNumber, payload.schemeCode),
      body: payload,
      originType: "identity",
    });
  }
);

export const GET_CARD_HOTLIST_REASON_ACTION = createAsyncThunk(
  "GET_CARD_HOTLIST_REASON",
  async () => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "get",
      route: GET_CARD_HOTLIST_REASON,
      originType: "identity",
    });
  }
);

export const POST_SUBMIT_CARD_HOTLIST_ACTION = createAsyncThunk(
  "POST_SUBMIT_CARD_HOTLIST",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_SUBMIT_CARD_HOTLIST,
      body: payload,
      originType: "identity",
    });
  }
);

export const POST_SUBMIT_CARD_CHANGE_PIN_ACTION = createAsyncThunk(
  "POST_SUBMIT_CARD_CHANGE_PIN",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_SUBMIT_CARD_CHANGE_PIN,
      body: payload,
      originType: "identity",
    });
  }
);
