import { useApiRequest } from "../../useApiRequest";
import React, { useState } from "react";
import { useAppDispatch } from "../../useReduxHook";
import {
  setLoadingState,
  setUserRequest,
} from "../../../react-wrappers/stores/redux/payments/slices";
import { POST_USER_MODIFICATION } from "../../../utils/apis/routes/admin";
import { ToastNotify } from "../../../helpers/toastNotify";

export const usePendingUserModification = () => {
  const makeRequest = useApiRequest();
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<Boolean>(false);

  const handleUserModificationDetails = async (payload: any) => {
    dispatch(setLoadingState(["modified-user-details"]));
    try {
      const response = await makeRequest({
        method: "post",
        route: POST_USER_MODIFICATION,
        body: payload,
        originType: "transferservice",
      });

      //   console.log("res", response);
      if (response?.data?.httpStatusCode === 200) {
        setStatus(true);
        dispatch(setUserRequest(response?.data?.data));
      } else if (response?.data?.httpStatusCode === 500) {
        setStatus(false);
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        setStatus(false);
        ToastNotify({
          type: "error",
          message: `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      setStatus(false);
      ToastNotify({
        type: "error",
        message: `${err?.data?.responseMessage?.title}. ${err?.data?.responseMessage?.actionMessage}`,
        position: "top-right",
      });
    }
  };
  return {
    handleUserModificationDetails,
    status,
  };
};
