import { PrimaryButton, TextInput } from "alat-components-library";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { useEffect, useState } from "react";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import {
  POST_FETCH_BILLS_PAYMENT_DETAIL_ACTION,
  PUT_APROVE_OR_REJECT_TRANSACTION_ACTION,
} from "react-wrappers/stores/redux/payments/actions";
import { useAppDispatch } from "hooks/useReduxHook";
import { ReactComponent as BackArrow } from "../../alat-components-library/assets/images/icons/back-arrow-two.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as ModalClose } from "../../alat-components-library/assets/images/icons/modal-close.svg";
import { ToastNotify } from "helpers/toastNotify";
import PageLoader from "components/Loader/PageLoader";

const BillsPaymentDetail = () => {
  const [formPayload, setFormPayload] = useState({
    transactionPin: "",
    token: "",
    remark: "",
  });
  const [rejectionModal, setRejectionModal] = useState(false);
  const [remarkMessage, setRemarkMessage] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { pendingBillPaymentDetail } = usePaymentState();
  const [load, toggleLoading] = useState(false);

  const {
    billerName,
    billerPackage,
    principalAmount,
    initiatedBy,
    initiatedByFullName,
    sourceAccountCurrency,
    sourceAccountNumber,
    totalAmount,
    totalCharge,
    type,
  } = pendingBillPaymentDetail;

  const correlationId = searchParams.get("correlationId");

  useEffect(() => {
    const payload = {
      correlationId,
      mappedAccounts: user.accounts.map((x: any) => x.accountNo),
    };

    dispatch(POST_FETCH_BILLS_PAYMENT_DETAIL_ACTION(payload));
    // eslint-disable-next-line
  }, [correlationId]);

  const handleApproveOrRejct = (approvalAction: number) => {
    if (
      user?.hasSoftToken === false &&
      (formPayload.transactionPin === "" || formPayload.token === "")
    ) {
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Please enter a pin and hard token to continue.",
      });
    }
    if (user?.hasSoftToken === true && formPayload.token === "") {
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Please enter a token to continue.",
      });
    }

    if (
      user?.hasSoftToken === false &&
      formPayload.transactionPin.length !== 4
    ) {
      //error
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Pin must be a 4 digit number.",
      });
    }

    if (formPayload.token.length !== 6) {
      //error
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Token must be a 6 digit number.",
      });
    }

    if (approvalAction === 2 && formPayload.remark === "") {
      return setRemarkMessage("Please enter a reason for rejection");
    }

    const body = {
      mappedAccounts: user.accounts.map((x: any) => x.accountNo),
      correlationId,
      isSoftToken: user?.hasSoftToken,
      approvalAction: approvalAction,
      ...formPayload,
    };

    toggleLoading(true);
    dispatch(PUT_APROVE_OR_REJECT_TRANSACTION_ACTION(body))
      .unwrap()
      .then(
        (res: {
          status: number;
          data: {
            data: { responseMessage: { title: string; actionMessage: string } };
          };
        }) => {
          if (approvalAction === 2) {
            setFormPayload({ ...formPayload, ...{ remark: "" } });
            setRemarkMessage("");
            setRejectionModal(false);
          }
          toggleLoading(false);
          if (res.status === 200) {
            setTimeout(() => {
              navigate(
                approvalAction === 1
                  ? "/payments/verify/transaction/approved"
                  : "/payments/verify/approver/reject"
              );
            }, 500);
          } else {
            ToastNotify({
              type: "error",
              message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
            });
          }
        }
      )
      .catch(() => {
        setRejectionModal(false);
        navigate("/payments/verify/transaction/reject");
      });
  };

  const DetailItem = ({ name, value }: { value: string; name: string }) => (
    <div className="gap-x-3 flex justify-between py-6 border-b items-center border-[#E2E8F0]">
      <h4 className="text-lib-alat-black text-xs">{name}</h4>
      <p className="text-sm text-lib-alat-black">{value}</p>
    </div>
  );
  return (
    <DashboardWrapper>
      {load && <PageLoader message="Please wait" />}
      <div>
        <header className="flex pt-8 pb-6 items-center gap-x-[11px]">
          <BackArrow
            id="billBack_btn"
            className="ml-5 cursor-pointer"
            onClick={() => navigate("/payments/pending-transactions")}
          />
          <h3
            className="font-semibold text-lg lg:text-2xl"
            id="reviewBillType_text"
          >
            {type}
          </h3>
        </header>
        <div className="max-w-[990px] px-6 md:px-0 shadow-[0px 4px 9px -8px #00000021] mx-auto border border-lib-alat-gray-input-border bg-white rounded-lg pb-8 md:pb-10">
          <h3 className="text-center border-b border-lib-alat-gray-input-border py-[15px] text-base md:text-lg ">
            Overview
          </h3>
          <div className="max-w-[516px] mx-auto">
            <div
              className="flex 
                pt-4 pb-8 justify-between items-center"
            >
              <h3 className="text-lg text-lib-alat-black font-normal">
                E-bills
              </h3>
              <PrimaryButton
                id="billPendingApproval_btn"
                containerVariant="w-36 h-10 rounded-bl-lg flex justify-center cursor-pointer rounded-[5px] bg-[#FFEBCC]"
                variant="font-bold text-xs text-[#F90] bg-[#FFEBCC]"
                labelText="Pending Approval"
                handleClick={() => null}
              />
            </div>
            <div className="flex bg-lib-alat-gray-input md:py-4 py-3 md:px-[28px] px-4">
              <p className="text-sm  pr-[3px] text-lib-alat-black leading-[13px]">
                Initiator:
              </p>
              <div>
                <p className="text-base text-lib-alat-black leading-[15px]">
                  {" "}
                  {initiatedByFullName &&
                    initiatedByFullName?.charAt(0)?.toUpperCase() +
                      initiatedByFullName?.slice(1)}
                </p>
                <span className="text-secondary text-sm leading-[12.92px]">
                  {initiatedBy &&
                    initiatedBy?.charAt(0)?.toUpperCase() +
                      initiatedBy?.slice(1)}
                </span>
              </div>
            </div>
            <h3 className="pt-8 pb-6 text-base">Details</h3>
            {/* Detail items */}
            <DetailItem name="BILLER NAME" value={billerName} />
            <DetailItem name="BILLER PACKAGE" value={billerPackage} />
            <DetailItem
              name="PRINCIPAL AMOUNT"
              value={`
                    ${sourceAccountCurrency} ${Number(principalAmount).toFixed(2)}
                `}
            />
            <DetailItem
              name="TRANSACTION CHARGES"
              value={Number(totalCharge).toFixed(2)}
            />
            <DetailItem
              name="TOTAL AMOUNT"
              value={`
                     ${sourceAccountCurrency}
                     ${(Number(totalAmount) + Number(totalCharge)).toFixed(2)}
                `}
            />
            <DetailItem
              name="ACCOUNT TO BE DEBITED "
              value={sourceAccountNumber}
            />
            {/* Form Action  */}
            <div className="max-w-[409px] pt-10">
              {user?.hasSoftToken && (
                <TextInput
                  id="softToken_input"
                  label="Enter soft token"
                  name="softToken"
                  type="password"
                  isPin
                  maxLength={6}
                  handleChange={(event) => {
                    setFormPayload({
                      ...formPayload,
                      token: event.target.value,
                    });
                  }}
                  variant="border text-black w-full h-10 text-lg px-5"
                  value={formPayload.token}
                />
              )}
              {!user?.hasSoftToken && (
                <>
                  <TextInput
                    id="pin_input"
                    label="Enter PIN"
                    placeHolder="Enter pin"
                    type="password"
                    isPin
                    name="pin"
                    maxLength={4}
                    handleChange={(event) => {
                      setFormPayload({
                        ...formPayload,
                        transactionPin: event.target.value,
                      });
                    }}
                    variant="border text-black w-full h-10 text-lg px-5"
                    value={formPayload.transactionPin}
                  />
                  <p className="text-right text-[#A90836] text-sm pt-4 -mb-5 cursor-pointer">
                    Don’t have token?
                  </p>
                  <TextInput
                    id="hardToken_input"
                    label="Enter hard token"
                    placeHolder="Enter hard token"
                    type="password"
                    isPin
                    name="hardToken"
                    maxLength={6}
                    handleChange={(event) => {
                      setFormPayload({
                        ...formPayload,
                        token: event.target.value,
                      });
                    }}
                    variant="border text-black w-full h-10 text-lg px-5"
                    value={formPayload.token}
                  />
                </>
              )}
            </div>
            <div className="max-w-[409px] flex gap-5 mt-10">
              <div
                id="approve_btn"
                className="w-full h-10 text-white bg-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer"
                onClick={() => handleApproveOrRejct(1)}
              >
                Approve
              </div>
              <div
                id="reject_btn"
                className="w-full h-10 text-lib-alat-dark-red bg-white border border-[#A90836] rounded-md flex justify-center items-center cursor-pointer"
                onClick={() => {
                  if (user?.hasSoftToken && formPayload.token !== "") {
                    return setRejectionModal(true);
                  }
                  if (
                    !user?.hasSoftToken &&
                    formPayload.token !== "" &&
                    formPayload.transactionPin !== ""
                  ) {
                    return setRejectionModal(true);
                  }

                  if (user?.hasSoftToken && formPayload.token === "") {
                    ToastNotify({
                      type: "error",
                      message: "Please enter token to continue",
                    });
                  }

                  if (
                    !user?.hasSoftToken &&
                    (formPayload.token === "" ||
                      formPayload.transactionPin === "")
                  ) {
                    ToastNotify({
                      type: "error",
                      message: "Please enter pin and hard token to continue.",
                    });
                  }
                }}
              >
                Reject
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Rejection reason Modal */}
      {rejectionModal && (
        <div
          className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-10 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm"
          style={{ margin: 0 }}
          id="rejection_modal"
        >
          <center className="z-10">
            <div className="flex flex-col py-5 px-10 md:w-[700px] w-full h-[560px] relative mb-4 bg-white rounded-lg relative">
              <p
                id="closeRejectionModal_btn"
                className="text-right text-xs absolute right-5 top-5 cursor-pointer"
                onClick={() => {
                  setFormPayload({ ...formPayload, ...{ remark: "" } });
                  setRejectionModal(false);
                }}
              >
                <ModalClose />
              </p>
              <div className="text-center text-lg font-bold py-5">
                Please provide a reason for rejection
              </div>
              <textarea
                name="rejectionReason"
                id="rejectionReason"
                cols={4}
                rows={7}
                maxLength={100}
                placeholder="Type your reason here.."
                className="bg-gray-50 p-4 border outline-none rounded-md"
                value={formPayload.remark}
                onChange={(event) =>
                  setFormPayload({
                    ...formPayload,
                    ...{ remark: event.target.value },
                  })
                }
              />
              <div className="mt-2 flex items-center justify-between">
                <p
                  className="text-red-500 text-sm text-left"
                  id="remarkMessage"
                >
                  {remarkMessage}
                </p>
                <label
                  className="text-right"
                  id="remarkLength"
                >{`${formPayload?.remark?.length} / 100`}</label>
              </div>
              <div className="w-full flex gap-5 mt-10 mx-auto">
                <div
                  id="returnToDashboard_btn"
                  className="w-full py-3 h-10 text-lib-alat-dark-red bg-white text-bold border border-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer text-xs"
                  onClick={() => navigate("/user-dashboard")}
                >
                  Return to dashboard
                </div>
                <div
                  id="confirmRejection_btn"
                  className="w-full py-3 h-10 text-white bg-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer text-xs"
                  onClick={() => handleApproveOrRejct(2)}
                >
                  Confirm rejection
                </div>
              </div>
            </div>
          </center>
        </div>
      )}
    </DashboardWrapper>
  );
};

export default BillsPaymentDetail;
