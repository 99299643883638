import { UserPermissionEnum } from "utils/enums/permissions";

export const ROLES = [
  { id: 1, value: "viewer", label: "Viewer" },
  { id: 2, value: "initiator", label: "Initiator" },
  { id: 3, value: "verifier", label: "Verifier" },
  // { id: 4, value: "approver", label: "Approver" },
];
export const userPermissionsData = [
  {
    name: "View Credit Transaction",
    key: "ViewCreditTransaction",
    value: UserPermissionEnum.ViewCreditTransaction,
  },
  {
    name: "View Debit Transaction",
    key: "ViewDebitTransaction",
    value: UserPermissionEnum.ViewDebitTransaction,
  },
  {
    name: "View Balance",
    key: "ViewBalance",
    value: UserPermissionEnum.ViewBalance,
  },
  {
    name: "Download Account Statement",
    key: "DownloadAccountStatement",
    value: UserPermissionEnum.DownloadAccountStatement,
  },
];
