import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  POST_FIRST_TIME_RESET_PASSWORD_ACTION,
  POST_LOGIN_ACTION,
  POST_RESET_PASSWORD_ACTION,
} from "./actions";
import { RootState } from "../store";
import { AuthState } from "../interfaces";
import { useAppSelector } from "../../../../hooks/useReduxHook";
import {
  triggerFirstTimeLogin,
  triggerLogin,
  triggerResetPassword,
} from "./assit-functions";
import { removeLoadingState } from "../../../../helpers/assitFunctions";

const dataObject = {
  loading: [],
  user: null,
  userRole: "", // soleproprietor, admin, initiator, verifier
  primaryAccountNumber: "",
  permissionRole: [""],
  token: null,
  resetAppTimer: 0,
  firstTimeLogin: {
    username: "",
    defaultPassword: "",
    responseCode: 0,
    successStatus: false,
    successMessage: "",
    errorMessage: {
      action: "",
      title: "",
    },
  },
  pagination: {
    sortedData: null,
  },
  resetPassword: {
    pin: "",
    securityQuestionId: 0,
    securityQuestionAnswer: "",
    successMessage: "",
    successStatus: false,
    errorMessage: {
      action: "",
      title: "",
    },
  },
  centerCoords: {
    lat: 0,
    long: 0,
  },
} as AuthState;

export const initialState = { ...dataObject } as AuthState;

export const authSlice = createSlice({
  name: "auth",
  initialState,

  reducers: {
    setAuthReset: (state: AuthState) => initialState,

    setUserRole: (state: AuthState, action: PayloadAction<string>) => {
      state.userRole = action.payload;
    },

    setToken: (state: AuthState, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setResetAppTimer: (state: AuthState, action: PayloadAction<any>) => {
      state.resetAppTimer = action.payload;
    },
    setDataForFirstTimeLogin: (
      state: AuthState,
      action: PayloadAction<any>
    ) => {
      state.firstTimeLogin = {
        ...state.firstTimeLogin,
        username: action.payload?.username,
        defaultPassword: action.payload?.password,
      };
    },
    setDataforResetPassword: (state: AuthState, action: PayloadAction<any>) => {
      state.resetPassword = {
        ...state.resetPassword,
        pin: action.payload.pin,
        securityQuestionId: action.payload.securityQuestionId,
        securityQuestionAnswer: action.payload.securityQuestionAnswer,
      };
    },
    setCenterCoords: (state: AuthState, action: PayloadAction<any>) => {
      state.centerCoords = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      // POST_LOGIN_ACTION
      .addCase(POST_LOGIN_ACTION.pending, (state) => {
        state.loading = ["POST_LOGIN"];
      })
      .addCase(POST_LOGIN_ACTION.fulfilled, (state, action): any =>
        triggerLogin(state, action)
      )
      .addCase(POST_LOGIN_ACTION.rejected, (state, action): void => {
        state.loading = removeLoadingState(state.loading, "POST_LOGIN");
      })

      // POST_RESET_PASSWORD_ACTION
      .addCase(POST_RESET_PASSWORD_ACTION.pending, (state) => {
        state.loading = ["POST_RESET_PASSWORD"];
      })
      .addCase(POST_RESET_PASSWORD_ACTION.fulfilled, (state, action): void =>
        triggerResetPassword(state, action)
      )
      .addCase(POST_RESET_PASSWORD_ACTION.rejected, (state, action): void => {
        state.loading = removeLoadingState(
          state.loading,
          "POST_RESET_PASSWORD"
        );
      })
      // POST_FIRST_TIME_RESET_PASSWORD
      .addCase(POST_FIRST_TIME_RESET_PASSWORD_ACTION.pending, (state) => {
        state.loading = ["POST_FIRST_TIME_RESET_PASSWORD"];
      })
      .addCase(
        POST_FIRST_TIME_RESET_PASSWORD_ACTION.fulfilled,
        (state, action): void => triggerFirstTimeLogin(state, action)
      )
      .addCase(
        POST_FIRST_TIME_RESET_PASSWORD_ACTION.rejected,
        (state, action): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_FIRST_TIME_RESET_PASSWORD"
          );
        }
      );
  },
});

// Selectors
const selectAuth = (state: RootState) => state.auth;

// Reducers and actions
export const {
  setAuthReset,
  setResetAppTimer,
  setUserRole,
  setDataForFirstTimeLogin,
  setDataforResetPassword,
  setToken,
  setCenterCoords,
} = authSlice.actions;

//App Redux State
export const useAuthState = () => useAppSelector(selectAuth);

export default authSlice.reducer;
