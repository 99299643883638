import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import { GET_CUSTOMER_PAID_STATUS } from "../../../utils/apis/routes";
import { ToastNotify } from "helpers/toastNotify";

export const usePaymentStatus = () => {
  const [userPaymentStatusStatus, setPaymentStatusStatus] =
    useState<boolean>(false);
  const [userPaymentStatusLoading, setPaymentStatusLoading] =
    useState<boolean>(false);
  const [userPaymentStatusError, setPaymentStatusError] = useState<string>("");
  const [userPaymentStatusData, setPaymentStatusData] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const handlePaymentStatus = async (
    virtualAccountNumber: string,
    correlationId: string,
    type: string
  ) => {
    try {
      setPaymentStatusLoading(true);
      const res = await makeRequest({
        method: "get",
        route: GET_CUSTOMER_PAID_STATUS(
          virtualAccountNumber,
          correlationId,
          type
        ),
        originType: "onboarding",
      });
      // console.log(res)
      //Map the payload
      if (res?.data?.data) {
        if (res?.status === 200) {
          setPaymentStatusStatus(true);
          setPaymentStatusData(res);
        } else {
          setPaymentStatusData({});
          const resMsg =
            res?.data?.data?.responseMessage?.actionMessage ||
            res?.data?.data?.responseMessage.title;
          ToastNotify({
            type: "error",
            message: resMsg,
            position: "top-right",
          });
        }
      }
      setPaymentStatusLoading(false);
      return true;
    } catch (error) {
      setPaymentStatusError(
        "An error occured while fetching payment status, please refresh and try again"
      );
      setPaymentStatusLoading(false);
    }
  };

  return {
    userPaymentStatusStatus,
    userPaymentStatusLoading,
    userPaymentStatusError,
    userPaymentStatusData,
    handlePaymentStatus,
  };
};
