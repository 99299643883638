/* eslint-disable react-hooks/exhaustive-deps */
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import FileUpload2 from "../../../Onboarding/Components/FileUpload";
import { useLocation, useNavigate } from "react-router-dom";
import PendingActionsWrapper from "pages/PendingActions/RejectedDocuments/components/PendingActionsWrapper";
import { FormikButton, TextInput } from "alat-components-library";
import { DateInput } from "components/Inputs";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import { Form, Formik } from "formik";
import { idSchema } from "pages/PendingActions/RejectedDocuments/validation/pendingActionsValidation";
import { useEffect, useState } from "react";
import { DocumentTypeMetaData, IdType } from "utils/enums";
import { useResubmitRejectedDocuments } from "hooks/apis/pendingActions/useResubmitRejectedDocuments";
import { useNextScreen } from "pages/PendingActions/RejectedDocuments/components/NextScreen";
import { useFetchRejectedDocuments } from "hooks/apis/pendingActions/useFetchRejectedDocuments";
import PageLoader from "components/Loader/PageLoader";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";

const ids = [
  { name: "Driver's License", id: IdType.DRIVERSLICENSE },
  { name: "NIN", id: IdType.NIN },
  { name: "National Id Card", id: IdType.NATIONALIDCARD },
  { name: "International Passport", id: IdType.INTLPASSPORT },
  { name: "Voters Card", id: IdType.VOTERSCARD },
];

const heightTransition = (type: boolean) =>
  `${
    type ? "max-h-[1000px]" : "max-h-0"
  } overflow-hidden transition-[max-height] duration-[400ms] transition-property-height`;

// Checks the state before destructuring occurs
const IdUpload = () => {
  const location = useLocation();
  if (!location?.state?.pendingData) {
    window.location.replace("/user-dashboard");
    return <div></div>;
  }
  return <IdUploadInner />;
};

const IdUploadInner = ({
  rejectionReason = "I.D Card didn't meet upload requirements",
}) => {
  // Local state
  // const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [maxLength, setMaxLength] = useState(50);
  const [showBackPhoto, setShowBackPhoto] = useState(false);
  const [showExpiryDate, setShowExpiryDate] = useState(false);
  const [showIssueDate, setShowIssueDate] = useState(false);
  const [selectedType, setSelectedType] = useState("Driver's License");
  const [docType, setDocType] = useState("");

  // Hooks
  const { handleNextScreen } = useNextScreen();
  const {
    user: { regNumber, businessType },
  } = useAuthState();

  const location = useLocation();
  const navigate = useNavigate();
  const {
    reason,
    cacRegNumber,
    pendingActionType,
    actionData,
    pendingActionId,
  } = location?.state?.pendingData;

  const {
    fetchLoading: submitLoading,
    resubmittedResponse,
    handleResubmittedDocuments,
  } = useResubmitRejectedDocuments();

  const { fetchLoading, rejectedDocuments, handleFetchRejectedDocuments } =
    useFetchRejectedDocuments();

  const previousSubmission = JSON.parse(actionData);
  const previousSubmissionData: {
    IDType: number;
    IDNumber: string;
    IssueDate: string;
    ExpiryDate: string;
    FrontImageLink: string;
    BackImageLink: string;
  } = previousSubmission;

  const initialData = previousSubmissionData;

  // Handles Resubmission of rejected document
  const handleSubmit = (values: any) => {
    const idType = ids.find((id) => id.name === values?.docType)?.id;
    const payload = {
      IDType: idType,
      IDNumber: values?.docNumber,
      IssueDate: values?.issueDate,
      ExpiryDate: values?.expiryDate,
      FrontImageLink: values?.frontImageLink,
      BackImageLink: values?.backImageLink,
      SignatureLink: null,
    };
    const payloadString = JSON.stringify(payload);
    try {
      handleResubmittedDocuments({
        pendingAction: pendingActionType,
        pendingActionId,
        cacRegNumber,
        actionData: payloadString,
      });
    } catch (error) {}
  };

  // Gets the name of the Selected ID Type
  const handleIdType = ids.find(
    (item) => item?.id === initialData?.IDType
  )?.name;

  // Formats the date format
  const dateFormatter = (date: any) => {
    if (date) {
      const splitDate = date?.split("T");

      if (splitDate.length > 1) {
        return splitDate[0] as string;
      }
    } else {
      return "";
    }
  };

  // Initial Values of the form based on the Response
  const initialValues = {
    idType: handleIdType ?? "",
    docNumber: initialData?.IDNumber ?? "",
    issueDate: dateFormatter(initialData?.IssueDate) ?? "",
    expiryDate: dateFormatter(initialData?.ExpiryDate) ?? "",
    frontImageLink: initialData?.FrontImageLink ?? "",
    backImageLink: initialData?.BackImageLink ?? "",
    // signatureLink: "",
  };

  //Determines the metadata name of the front paged documents
  const frontDocType =
    docType === DocumentTypeMetaData.votersCard ? `${docType}_FRONT` : docType;

  // Validation that handles maximum length of the document number; depending on tthe document type
  const handleMaxLength = (type: any) => {
    switch (type) {
      case "Voters Card":
        setMaxLength(19);
        setDocType(DocumentTypeMetaData.votersCard);
        break;
      case "International Passport":
        setMaxLength(9);
        setDocType(DocumentTypeMetaData.intlPassport);
        break;
      case "Driver's License":
        setMaxLength(13);
        setDocType(DocumentTypeMetaData.driversLicense);
        break;
      case "National Id Card":
        setMaxLength(12);
        setDocType(DocumentTypeMetaData.nationalId);
        break;
      case "NIN":
        setMaxLength(11);
        setDocType(DocumentTypeMetaData.nin);
        break;
      default:
        break;
    }
    return maxLength;
  };

  // Use Effect
  useEffect(() => {
    // Only Voter's card has back photo. This handles that condition.
    const backPhoto = selectedType === "Voters Card";
    const expiryDate =
      selectedType === "Driver's License" ||
      selectedType === "International Passport" ||
      selectedType === "National Id Card";
    const issueDate =
      selectedType === "Driver's License" ||
      selectedType === "International Passport" ||
      selectedType === "Voters Card" ||
      selectedType === "National Id Card";
    setShowBackPhoto(backPhoto);
    setShowExpiryDate(expiryDate);
    setShowIssueDate(issueDate);

    handleMaxLength(selectedType);
    // eslint-disable-next-line
  }, [selectedType]);

  // On page mount this sets the id type returned from the response
  useEffect(() => {
    setSelectedType(handleIdType ?? "");
  }, []);

  // This handles modal display and routing after successful resubmission
  useEffect(() => {
    if (resubmittedResponse) {
      navigate("/pending-actions/successful-resubmission", {
        state: { title: "Upload ID Card" },
      });
    }
  }, [resubmittedResponse]);

  return (
    <DashboardWrapper>
      {(submitLoading || fetchLoading) && (
        <PageLoader message="Submitting ID Card" />
      )}
      <PendingActionsWrapper cardTitle="Upload ID Card">
        <div className="w-full max-w-[700px] mx-auto">
          <div className="md:w-full h-full">
            <div className="w-full px-10 mx-auto">
              <div className="bg-[#A90836]/10 text-lib-alat-dark-red p-2 rounded-md text-sm my-2">
                {reason?.[0]}
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => handleSubmit(values)}
                validateOnBlur
                validationSchema={idSchema(selectedType)}
              >
                {({
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                  isValid,
                  touched,
                  dirty,
                  resetForm,
                  setFormikState,
                }) => (
                  <Form className="w-full flex flex-col gap-4 mb-5">
                    <StateDropdown
                      id="idtype_dropdown"
                      name="idType"
                      label="Select ID Card Type"
                      options={ids}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      placeholder={handleIdType ?? "-- Select an ID Type --"}
                      onSelectOption={(e) => {
                        resetForm({
                          values: {
                            idType: e.name,
                            docNumber: "",
                            issueDate: "",
                            expiryDate: "",
                            frontImageLink: "",
                            backImageLink: "",
                          },
                        });
                        setSelectedType(e.name);
                      }}
                    />
                    <TextInput
                      id="id-number_input"
                      name="docNumber"
                      label="Enter Document Number"
                      loading={false}
                      maxLength={maxLength}
                      value={values.docNumber}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.docNumber}
                      hasError={touched.docNumber && errors?.docNumber}
                    />
                    <div className="grid grid-cols-2 gap-3 w-full">
                      <DateInput
                        id="id-issue-date_input"
                        name="issueDate"
                        label="Issue date"
                        value={values?.issueDate}
                        className={`${heightTransition(showIssueDate)}`}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        error={touched.issueDate ? errors?.issueDate : ""}
                        hasError={touched.issueDate && errors.issueDate}
                      />
                      <DateInput
                        id="id-expiry-date_input"
                        name="expiryDate"
                        label="Expiry date"
                        value={values?.expiryDate}
                        className={`${heightTransition(showExpiryDate)}`}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        error={touched.expiryDate ? errors?.expiryDate : ""}
                        hasError={touched.expiryDate && errors.expiryDate}
                      />
                    </div>
                    <FileUpload2
                      id="id-front-file_input"
                      label="Upload ID Card (Front)"
                      name="frontImageLink"
                      handleBlur={handleBlur}
                      error={
                        touched.frontImageLink ? errors?.frontImageLink : ""
                      }
                      DocumentType={`${docType}`}
                      DocumentName={`${frontDocType}`}
                      CacRegNumber={cacRegNumber}
                      defaultValue={values?.frontImageLink}
                    />
                    <FileUpload2
                      id="id-back-file_input"
                      label="Upload ID Card (Back)"
                      name="backImageLink"
                      className={`${heightTransition(showBackPhoto)}`}
                      handleBlur={handleBlur}
                      error={touched.backImageLink ? errors?.backImageLink : ""}
                      DocumentType={`${docType}`}
                      DocumentName={`${docType}_BACK`}
                      CacRegNumber={cacRegNumber}
                      defaultValue={values?.backImageLink}
                    />
                    <div>
                      <FormikButton
                        id="proceed_btn"
                        labelText="Proceed"
                        containerVariant="w-full h-10 rounded-md flex justify-center"
                        isDisabled={!isValid}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </PendingActionsWrapper>
    </DashboardWrapper>
  );
};

export default IdUpload;
