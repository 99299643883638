import { ToastNotify } from "helpers/toastNotify";
import { useBankLists } from "hooks/apis/Payment/useBankLists";
import { useChangeReferee } from "hooks/apis/Referee/useChangeReferee";
import { useVerifyRefereeAccount } from "hooks/apis/Referee/useVerifyRefereeAccountNumber";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import { AccountTypes } from "../../pages/Onboarding/utils";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import {
  updateFirstReferee,
  updateSecondReferee,
} from "react-wrappers/stores/redux/onboarding/slices";

export const useRefereeFormController = () => {
  const [wemaAccount, setWemaAccount] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuthState();
  const refereeToEdit = location?.state?.refereeToEdit;
  const editData = location?.state?.editData;
  const onboardingData = useAppSelector((state) => state.onboarding);
  const referees = onboardingData.refereePayload;
  // Fetches a list of banks
  const { bankLists, handleGetBankList } = useBankLists();

  // Check if it should have single refereee
  const accountType = user?.accountType;
  const isSingleReferee =
    AccountTypes.MBASAVVY === accountType ||
    AccountTypes.MBALITE === accountType;

  // Validates the Wema bank account number
  const {
    verifyRefereeAccountLoading,
    verifyRefereeAccountData,
    verifyRefereeAccountError,
    refereeAccountValidation,
  } = useVerifyRefereeAccount();

  const {
    changeRefereeStatus,
    changeRefereeLoading,
    changeRefereeError,
    changeRefereeData,
    handleChangeReferee,
  } = useChangeReferee();

  useEffect(() => {
    if (verifyRefereeAccountError?.title) {
      setErrorModal(true);
    }
  }, [verifyRefereeAccountError]);
  useEffect(() => {
    // Fetches list of all banks
    handleGetBankList("0");
    if (isSingleReferee) {
      // Redirect to Referee list if referee details for single have been filled and it's not an edit action.
      if (referees[0].bank !== "" && !refereeToEdit) {
        navigate("/referee-list");
      }
    } else {
      // Redirect to Referee list if both referee details have been filled and it's not an edit action.
      if (
        referees[0].bank !== "" &&
        referees[1]?.bank !== "" &&
        !refereeToEdit
      ) {
        navigate("/referee-list");
      }
    }

    // If it's an edit action and the current referee bank is wema bank, set the bank type to wema bank so the user can validate a wema account.
    if (editData?.bank) {
      // console.log(editData?.bank);

      if (editData?.bank.toLowerCase().includes("wema")) {
        setWemaAccount(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (changeRefereeStatus) {
      setSuccessModal(true);
    }
  }, [changeRefereeData]);

  const handleAddReferee = (e: any) => {
    const firstRefereeInfoIsEmpty =
      onboardingData.refereePayload[0].bank === "";
    const editFirstRefereeAction = refereeToEdit === 1;

    const secondRefereeInfoIsEmpty =
      onboardingData.refereePayload[1]?.bank === "";
    const editSecondRefereeAction = refereeToEdit === 2;

    if (!wemaAccount) {
      if (firstRefereeInfoIsEmpty || editFirstRefereeAction) {
        dispatch(
          updateFirstReferee({
            bank: e?.bankName,
            firstName: e?.firstName,
            lastName: e?.lastName,
            emailAddress: e?.emailAddress,
            phoneNumber: e?.phoneNumber,
            accountNumber: "",
          })
        );
      } else if (secondRefereeInfoIsEmpty || editSecondRefereeAction) {
        dispatch(
          updateSecondReferee({
            bank: e?.bankName,
            firstName: e?.firstName,
            lastName: e?.lastName,
            emailAddress: e?.emailAddress,
            phoneNumber: e?.phoneNumber,
            accountNumber: "",
          })
        );
      }
    } else {
      if (firstRefereeInfoIsEmpty || editFirstRefereeAction) {
        if (e?.accountNumber === referees[1]?.accountNumber)
          return ToastNotify({
            type: "error",
            message: "You cannot submit duplicate referees",
            position: "top-right",
          });
        dispatch(
          updateFirstReferee({
            bank: e?.bankName,
            firstName: verifyRefereeAccountData?.firstName || "",
            lastName: verifyRefereeAccountData?.lastName,
            emailAddress: verifyRefereeAccountData?.email,
            phoneNumber: verifyRefereeAccountData?.currentPhoneNumber,
            accountNumber: e?.accountNumber,
          })
        );
      } else if (secondRefereeInfoIsEmpty || editSecondRefereeAction) {
        if (e?.accountNumber === referees[0]?.accountNumber)
          return ToastNotify({
            type: "error",
            message: "You cannot submit duplicate referees",
            position: "top-right",
          });
        dispatch(
          updateSecondReferee({
            bank: e?.bankName,
            firstName: verifyRefereeAccountData?.lastName || "",
            lastName: verifyRefereeAccountData?.lastName,
            emailAddress: verifyRefereeAccountData?.email,
            phoneNumber: verifyRefereeAccountData?.currentPhoneNumber,
            accountNumber: e?.accountNumber,
          })
        );
      }
    }
    // Referee Change After submission
    if (editData?.id) {
      if (!wemaAccount) {
        setNewEmail(e?.emailAddress);
        handleChangeReferee({
          id: editData?.id,
          bank: e?.bankName,
          firstName: e?.firstName,
          lastName: e?.lastName,
          emailAddress: e?.emailAddress,
          phoneNumber: e?.phoneNumber,
          accountNumber: "",
          correlationId: user?.correlationId,
        });
      } else {
        setNewEmail(verifyRefereeAccountData?.email);
        handleChangeReferee({
          id: editData?.id,
          bank: e?.bankName,
          firstName: verifyRefereeAccountData?.firstName || "",
          lastName: verifyRefereeAccountData?.lastName,
          emailAddress: verifyRefereeAccountData?.email,
          phoneNumber: verifyRefereeAccountData?.currentPhoneNumber,
          accountNumber: e?.accountNumber || "",
          correlationId: user?.correlationId,
        });
      }
    } else {
      navigate("/referee-list");
    }
  };

  const getBanks = () =>
    bankLists?.map((bank: any) => ({
      name: bank?.bankName,
      id: bank?.bankCode,
      key: bank?.bankName,
    }));
  return {
    editData,
    verifyRefereeAccountData,
    verifyRefereeAccountLoading,
    verifyRefereeAccountError,
    handleAddReferee,
    changeRefereeLoading,
    getBanks,
    setWemaAccount,
    wemaAccount,
    onboardingData,
    refereeAccountValidation,
    handleGetBankList,
    successModal,
    errorModal,
    setErrorModal,
    newEmail,
    navigate,
  };
};
