import * as Yup from "yup";

export const refereeValidationSchema = (wemaAccount: boolean) =>
  Yup.object().shape({
    bankName: Yup.string().required("Select a bank"),
    firstName: Yup.string().when("bankName", ([bankName], schema) => {
      return !bankName?.includes("WEMA")
        ? schema.required("Please enter firstname")
        : schema.notRequired();
    }),
    lastName: Yup.string().when("bankName", ([bankName], schema) => {
      return !bankName?.includes("WEMA")
        ? schema.required("Please enter lastname")
        : schema.notRequired();
    }),
    emailAddress: Yup.string().when("bankName", ([bankName], schema) => {
      return !bankName?.includes("WEMA")
        ? schema.required("Please enter email address")
        : schema.notRequired();
    }),
    phoneNumber: Yup.string().when("bankName", ([bankName], schema) => {
      return !bankName?.includes("WEMA")
        ? schema
            .required("Please enter phone number")
            .min(11, "Enter a valid phone number")
        : schema.notRequired();
    }),
    accountNumber: Yup.string().when("bankName", ([bankName], schema) => {
      return bankName?.includes("WEMA")
        ? schema
            .required("Please enter account number")
            .matches(/^[\d+].{9}$/, "Enter a valid account number")
        : schema.notRequired();
    }),
  });
