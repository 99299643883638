import { ResponseMessage } from "./../../../../../react-wrappers/stores/redux/interfaces";
import { moneyInputFormat } from "../../../../../utils/helperFunctions";
import { GET_ACCOUNT_NAME_ENQUIRY_ACTION } from "../../../../../react-wrappers/stores/redux/payments/actions";
import { ToastNotify } from "helpers/toastNotify";

export const handleAccountValidation = async ({
  accountNumber,
  selectedBank,
  setLoading,
  dispatch,
  accountValidated,
  accountValidationError,
  beneficiaryName,
}: any) => {
  try {
    if (accountNumber.length < 10 || selectedBank.bankCode.length < 3) {
      alert("Select a bank or input your account number");
      return;
    }
    setLoading(true);
    dispatch(
      GET_ACCOUNT_NAME_ENQUIRY_ACTION({
        accountNumber: accountNumber,
        bankCode: selectedBank.bankCode,
      })
    )
      .unwrap()
      .then((res: any) => {
        if (res?.data?.data?.hasError) {
          setLoading(false);
          accountValidated(false);
          ToastNotify({
            type: "error",
            message: res?.data?.data?.responseMessage?.title,
          });
          accountValidationError(res?.data?.data?.responseMessage?.title);
        } else {
          setLoading(false);
          accountValidated(true);
          beneficiaryName(res?.data?.data?.accountName);
        }
      })
      .catch((e: any) => {
        // console.log(e);
        setLoading(false);
        accountValidated(false);
        accountValidationError(
          "Unable to validate account, Kindly check the details and try again"
        );
      });

    // setshowScreen(7);
  } catch (e) {
    setLoading(false);
    // setshowScreen(7);
    setLoading(false);
    accountValidated(false);
    accountValidationError(
      "Unable to validate account, Kindly check the details and try again"
    );
  }
};

// export const handlePinValidation = ({
//     setIsPinValidated,
//     setAllowSubmit,
//     setShowPinError
// }: any) => {
//     setIsPinValidated(true);
//     setAllowSubmit(true);
//     setShowPinError("no");
// };

const handleChange = ({
  event,
  setAccountNumber,
  setAccountValidated,
  setSelectedBank,
  setAmount,
  setOtp,
  setPin,
  setDescription,
  setIsPinValidated,
  setAllowSubmit,
  setShowPinError,
  setAuthToken,
  setAccountToDebit,
}: any) => {
  // console.log(event.target.name, event.target.value);
  const getAuthType = event?.target?.dataset.authType;
  // console.log(getAuthType)

  if (event.target.name === "AccountNumber") {
    const value = event.target.value.replace(/\D/g, "");
    setAccountNumber(value);
    setAccountValidated(false);
  }
  if (event.target.name === "selectedBank") {
    setSelectedBank(JSON.parse(event.target.value));
    setAccountNumber("");
    setAccountValidated(false);
  }

  if (event.target.name === "amount") {
    const result = moneyInputFormat(event.target.value);
    // console.log("result", result);
    result && setAmount(result);
  }
  if (event.target.name === "otp") {
    if (event.target.value.length > 6) {
      return;
    }
    const result = event.target.value.replace(/\D/g, "");

    setOtp(result);
    // if (result.length === 4) {
    //   handlePinValidation(result);
    // }
  }
  if (event.target.name === "description") {
    setDescription(event.target.value);
  }
  if (event.target.name === "pin") {
    setShowPinError("no");
    setAllowSubmit(false);
    setIsPinValidated(false);

    const result = event.target.value.replace(/\D/g, "");

    setPin(result);
    if (result.length === 4) {
      setIsPinValidated(true);
      setShowPinError("no");
      return setAllowSubmit(true);

      // if (getAuthType !== "hardTokenPin") {
      //   setIsPinValidated(true);
      //   setAllowSubmit(true);
      //   setShowPinError("no");
      //   return handlePinValidation({
      //     setIsPinValidated,
      //     setAllowSubmit,
      //     setShowPinError,
      //   });
      // }
      // setIsPinValidated(true);
      // setShowPinError("no");
      // return setAllowSubmit(true);
    }
  }
  if (event.target.name === "authToken") {
    const result = event.target.value.replace(/\D/g, "");
    setAllowSubmit(false);
    setAuthToken(result);
    if (result.length === 6) {
      // if (getAuthType !== "hardTokenPin")
      //   handlePinValidation({
      //     setIsPinValidated,
      //     setAllowSubmit,
      //     setShowPinError,
      //   });
      setIsPinValidated(true);
      setShowPinError("no");
      return setAllowSubmit(true);
    }
    // if (result.length === 4) {
    //   handlePinValidation(result);
    // }
  }
  // if (event.target.name === "pin") {
  //   setShowPinError("no");
  //   const result = event.target.value.replace(/\D/g, "");

  //   setPin(result);
  //   if (result.length === 4) {
  //     handlePinValidation(result);
  //   }
  // }
  if (event.target.name === "selectedAccount") {
    const convertedAccountDetails = JSON.parse(event.target.value);
    setAccountToDebit(convertedAccountDetails);

    // const filterAccounts = accounts.filter(
    //   (account) => account.accountNo !== convertedAccountDetails.accountNo
    // );
    // setShowAccounts(filterAccounts);
  }
  // setFundAccount({ ...fundAccount, [event.target.name]: event.target.value });
};
