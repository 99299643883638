import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import { GET_NIP_CHARGES } from "../../../utils/apis/routes";
import {
  usePaymentState,
  setLoadingState,
  setNipChargesResponse,
} from "react-wrappers/stores/redux/payments/slices";
import axiosStatus from "utils/apis/axios/errorStatus";
import { ToastNotify } from "helpers/toastNotify";
import { useAppDispatch } from "hooks/useReduxHook";

export const useNIPCharges = () => {
  //Local States

  const [nipCharges, setNipCharges] = useState<
    {
      id: number;
      charge: number;
      chargeFeeName: string;
      lower: number;
      transactionType: number;
      upper: number;
    }[]
  >([]);

  // const [bankListLoading, setbankListLoading] = useState<boolean>(false);
  const [nipChargesError, setNipChargesError] = useState<string>("");

  //API Request
  const makeRequest = useApiRequest();
  const dispatch = useAppDispatch();

  const handleGetNIPCharges = async () => {
    dispatch(setLoadingState(["fetching-nip-charges"]));
    try {
      const res = await makeRequest({
        method: "get",
        route: GET_NIP_CHARGES,
        originType: "transferservice",
      });

      if (res?.status === 200) {
        setNipCharges(res.data.data.charges);
        dispatch(setNipChargesResponse(res.data.data.charges));
      } else if (res?.status === 500) {
        axiosStatus(res);
      } else {
        ToastNotify({
          type: "error",
          message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }

      dispatch(setLoadingState([]));
    } catch (error: any) {
      ToastNotify({
        type: "error",
        message:
          error?.response?.data?.responseMessage?.title ||
          error?.response?.data?.responseMessage?.actionMessage,
        position: "top-right",
      });
      setNipChargesError(
        "An error occured while fetching nip charges, please try again"
      );
      dispatch(setLoadingState([]));
    }
  };

  return {
    nipCharges,
    // bankListLoading,
    nipChargesError,
    handleGetNIPCharges,
  };
};
