import { Logo } from "../../alat-components-library";
import LoginVideoPreview from "../../assets/videos/login-web-version.mp4";

const VideoBg = () => {
  return (
    <div className="animate__animated animate__fadeIn w-full relative h-[100vh]">
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: "-1",
          overflow: "hidden",
        }}
        className={"overflow-hidden bg-lib-alat-red"}
      >
        <video
          autoPlay
          loop
          muted
          className="object-contain"
          width="100%"
          height="100%"
        >
          <source src={LoginVideoPreview} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="my-[5vh]">
        <div className="mx-[10vh]">
          <Logo
            type={"afb-transparent"}
            containerVariant={""}
            variant={"w-[24vh] h-[12vh]"}
          />
        </div>
        <div className="bottom-0 absolute h-[28vh] backdrop-blur-xs px-[10vh] w-full pt-10">
          <h1 className="text-white text-[4.4vh] font-bold">
            Welcome to ALAT FOR BUSINESS
          </h1>
          <small className="text-white text-[1.5vh] bottom-8 absolute">
            © 2023 ALAT by WEMA. All rights reserved.
          </small>
        </div>
      </div>
    </div>
  );
};

export default VideoBg;
