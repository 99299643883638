import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { FormikButton } from "alat-components-library";
import { TextInput } from "../../../../alat-components-library/Inputs";
import { Card } from "flowbite-react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { IntraTransferValidation } from "utils/validations/bulktransfer";
import { ReactComponent as DragAndDropIcon } from "../../../../assets/images/payment/drag-nd-drop-image.svg";
import ExcelReader from "third-party/ExcelToJSON";
import { FaTimes } from "react-icons/fa";
import { useTransactionTransferController } from "controllers/BulkTransfer/useTransactionTransferController";
import { useIntraTransferController } from "controllers/BulkTransfer/useIntraTransferController";
import DownloadTemplate from "../../../../assets/files/download-template.xlsm";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { SelectDropdown } from "components/Dashboard/components/SelectDropdown";
import { useAppDispatch } from "hooks/useReduxHook";
import {
  setBulkTranferIntra,
  setBulkTransferType,
  setValidateBulktransferAccounts,
} from "react-wrappers/stores/redux/payments/slices";
import { useMemo, useState, useEffect } from "react";
import { Toggle } from "components/reusables/Toggle/Toggle";
import PageLoader from "components/Loader/PageLoader";
import { useNIPCharges } from "hooks/apis/Payment/useNIPCharges";

const FileUploadIntra = ({ setActiveTab, screenName }: any) => {
  const [transferType, setTransferType] = useState("Wema");
  const { excelList, paymentState, handleFileUploadChange } =
    useTransactionTransferController(setActiveTab, screenName, transferType);

  const { initialValueData, handleUploadExcelSubmit, bulkTransferTypes } =
    useIntraTransferController(setActiveTab);

  const dispatch = useAppDispatch();

  const { handleGetNIPCharges } = useNIPCharges();

  // console.log(paymentState.bulkTransferPayload)

  const navigate = useNavigate();

  const handleSubText = useMemo(() => {
    if (
      transferType?.toLowerCase() === "both" ||
      paymentState?.validateBulkTransferAccounts
    ) {
      return "Maximum of 50 transactions at once";
    }
    return "Maximum of 5000 transactions at once";
  }, [paymentState?.validateBulkTransferAccounts, transferType]);

  const handleUploadText = useMemo(() => {
    if (transferType?.toLowerCase() === "wema")
      return "Download bulk transfer template to Wema bank accts";
    if (transferType?.toLowerCase() === "others")
      return "Download bulk transfer template to Other bank accts";
    return "Download bulk transfer template to Wema and other bank accts";
  }, [transferType]);

  useEffect(() => {
    if (transferType?.toLowerCase() === "both") {
      dispatch(setValidateBulktransferAccounts(true));
    }
  }, [transferType]);

  useEffect(() => {
    dispatch(setValidateBulktransferAccounts(false));
    handleGetNIPCharges();
    dispatch(setBulkTranferIntra({}));
  }, []);

  const isLoading =
    paymentState?.loading?.includes("multiple-account-name-enquiry") ||
    paymentState?.loading?.includes("fetching-nip-charges");

  return (
    <div className="flex items-center justify-center w-full">
      {isLoading && <PageLoader />}
      <ReusableCard
        variant="md:w-[768px] w-full mx-auto"
        cardTitle={"Make Transfer"}
        headingId={""}
        handleBackNavigation={() =>
          navigate("/payments/funds-transfer/bulk-transfer")
        }
        showGoBackIcon
      >
        <Formik
          initialValues={initialValueData}
          enableReinitialize={true}
          validationSchema={IntraTransferValidation}
          onSubmit={handleUploadExcelSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            dirty,
            isValid,
            setFieldValue,
          }) => (
            <Form>
              <div className="md:w-[400px] w-full mx-auto space-y-4">
                <div>
                  <SelectDropdown
                    options={bulkTransferTypes}
                    id={"selectBulkTransferTypeInput"}
                    idButton={"selectBulkTransferType"}
                    placeholder="--Select--"
                    label="Select bulk transfer type"
                    name="bulkTransferType"
                    onChange={(name: any, e: any) => {
                      // console.log("test", e);
                      setTransferType(e);
                      dispatch(setBulkTransferType(e));
                      setFieldValue("excelSheetintra", {
                        cols: [],
                        data: [],
                        file: {},
                      });
                      handleChange(e);
                    }}
                    value={values?.bulkTransferType}
                    hasError={errors?.bulkTransferType}
                    error={errors?.bulkTransferType}
                  />
                  <div className="flex gap-2 mt-1">
                    <p className="text-lib-alat-red font-[Inter-Regular] italic text-xs h-auto">
                      {handleSubText}
                    </p>
                  </div>
                </div>
                {transferType?.toLowerCase() !== "both" && (
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center justify-between">
                      <p className="text-sm text-[#464646]">
                        Validate account?
                      </p>
                      <Toggle
                        id={`validateAccount_toggle`}
                        checked={paymentState?.validateBulkTransferAccounts}
                        name={"Validate Account"}
                        handleChange={(e: any) => {
                          dispatch(
                            setValidateBulktransferAccounts(
                              !paymentState?.validateBulkTransferAccounts
                            )
                          );
                          setFieldValue("excelSheetintra", {
                            cols: [],
                            data: [],
                            file: {},
                          });
                        }}
                      />
                    </div>
                    <p className="text-xs text-lib-alat-red">
                      Please note that your account will be debited for each
                      transaction uploaded individually.
                    </p>
                  </div>
                )}

                <p className="text-sm text-[#464646]">
                  Upload Bulk transfer file
                </p>
                {values.excelSheetintra.data.length <= 0 && (
                  <ExcelReader
                    fileName="fileIntra"
                    onHandleResult={(values: any) => {
                      handleFileUploadChange(values, setFieldValue);
                      // console.log("valuesonChange", values);
                    }}
                    excelHeaders={paymentState?.bulkTransferFileHeaderOne}
                  >
                    <div className="flex justify-center">
                      <DragAndDropIcon className="w-full h-[210px]" />
                    </div>
                  </ExcelReader>
                )}
                {values.excelSheetintra.data.length > 0 && (
                  <div className="border p-4 rounded flex justify-between items-center my-3 relative">
                    <p className="text-lg truncate w-11/12">
                      {values?.excelSheetintra?.file?.name}
                    </p>
                    <FaTimes
                      onClick={() => {
                        setFieldValue("excelSheetintra", {
                          cols: [],
                          data: [],
                          file: {},
                        });
                      }}
                      className="cursor-pointer text-lg absolute right-4"
                      color="#A90836"
                    />
                  </div>
                )}
                <a
                  href={DownloadTemplate}
                  download
                  className="text-[#A90836] cursor-pointer text-xs"
                >
                  {handleUploadText}
                </a>
                <div className="my-5">
                  <TextInput
                    label="Narration"
                    name="narrationIntra"
                    value=""
                    handleChange={handleChange}
                  />
                </div>
                <div className="flex justify-center">
                  <FormikButton
                    labelText={"Proceed"}
                    containerVariant={
                      "w-2/4 h-[45px] my-[3vh] rounded-md flex justify-center"
                    }
                    isDisabled={!isValid && !dirty}
                    isLoading={isSubmitting}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ReusableCard>
    </div>
  );
};

export default FileUploadIntra;
