import { useNavigate } from "react-router-dom";
import { SetStateAction, useMemo, useState } from "react";
import SearchComponent from "components/Payments/BankTransfer/Reusables/SearchComponent";
import { useAppDispatch } from "hooks/useReduxHook";
import PageLoader from "components/Loader/PageLoader";
import {
  setActiveSelectedCollectionMerchantItem,
  useCollectionsState,
} from "react-wrappers/stores/redux/collections/slices";
import { useCollectionsController } from "controllers/Collections";
import { useGetCollections } from "hooks/apis/Collections/useGetCollections";
import { CollectionType } from "utils/enums/collections";

const SelectedMerchant = () => {
  // Local State
  const [searchTerm, setSearchTerm] = useState("");
  const { allCollectionMerchantItem, loading, billCategoryList } =
    useCollectionsState();
  const { handleFilterMerchantItems } = useCollectionsController();
  const { handleFetchSimpleForm, handleFetchDynamicForm } = useGetCollections();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Assit Function
  const handleSelected = (payload: any) => {
    if (payload?.collectionTypeId == CollectionType["Advanced"]) {
      handleFetchDynamicForm(payload.collectionItemId);
      dispatch(setActiveSelectedCollectionMerchantItem(payload));
      navigate(`/bills/collections/form`);
    }
    if (payload?.collectionTypeId == CollectionType["Simple"]) {
      handleFetchSimpleForm(payload.collectionItemId);
      dispatch(setActiveSelectedCollectionMerchantItem(payload));
      navigate(`/bills/collections/simple-form`);
    }
  };

  const listOfMerchantItems = useMemo(
    () =>
      handleFilterMerchantItems({
        merchantItems: allCollectionMerchantItem,
        searchWord: searchTerm,
      }),
    [allCollectionMerchantItem, handleFilterMerchantItems, searchTerm]
  );

  return (
    <div className="w-full">
      {!!loading.includes("fetch-collection-merchants") && (
        <PageLoader message="Loading collections" />
      )}
      <SearchComponent
        word={searchTerm}
        handleWordChange={(event: {
          target: { value: SetStateAction<string> };
        }) => setSearchTerm(event.target.value)}
        title="Search for item"
        width="w-full"
        textPosition="text-left"
      />
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 py-10">
        {listOfMerchantItems?.length > 0 &&
          listOfMerchantItems?.map((item: any) => (
            <div
              key={item.collectionItemId}
              onClick={() => handleSelected(item)}
              className="flex md:justify-start cursor-pointer justify-center items-center bg-white min-h-24 w-full boxShadow px-10 py-6 rounded-md"
            >
              <p className="text-[#333] text-sm">
                {item.collectionName} ({item.itemName})
              </p>
            </div>
          ))}
        {listOfMerchantItems?.length === 0 && "No item found"}
      </div>
    </div>
  );
};

export default SelectedMerchant;
