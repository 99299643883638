import ComplianceDudCheque from "pages/Home/DudCheque/ComplianceDudCheque";
import CustomerDudCheque from "pages/Home/DudCheque/CustomerDudCheque";

export const mailRoutes = [
  {
    path: "/compliance/dud-cheque/:id",
    isAuthenticated: false,
    element: <ComplianceDudCheque />,
  },
  {
    path: "/customer/dud-cheque/:id",
    isAuthenticated: false,
    element: <CustomerDudCheque />,
  },
];
