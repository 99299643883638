import React, { useState } from "react";
import CardLightRed from "../../../alat-components-library/assets/images/cards/card-light-red.svg";
import CardDarkPurple from "../../../alat-components-library/assets/images/cards/card-dark-purple.svg";
import CardGreyPurple from "../../../alat-components-library/assets/images/cards/card-grey-purple.svg";
import { ReactComponent as InfoIcon } from "../../../assets/svgs/info-icon.svg";

interface Props {
  containerVariant?: string;
  bgType?: string;
  handleClick?: () => void;
}

export const PendingAccountCard = ({
  containerVariant = "rounded-xl w-[293px] h-[172px]",
  bgType = "light-red", //light-red, dark-purple, grey-purple,
  handleClick,
}: Props) => {
  // Preview the desire background for card base onbg type
  let cardBg = CardLightRed;
  if (bgType === "light-red") {
    cardBg = CardLightRed;
  }
  if (bgType === "dark-purple") {
    cardBg = CardDarkPurple;
  }
  if (bgType === "grey-purple") {
    cardBg = CardGreyPurple;
  }

  // Local State
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <div
      id="pending-account_card"
      className={`${containerVariant} flex justify-center items-center mx-auto md:ml-0`}
      style={{
        background: `url(${cardBg})`,
      }}
    >
      <div className="flex flex-col justify-center items-center w-4/5 text-center">
        <InfoIcon />
        <p className="text-sm text-white font-semibold mt-1">
          We are currently setting up{" "}
        </p>
        <p className="text-sm text-white font-semibold mb-1">your account</p>
        <p className="text-[10px] text-white font-normal">
          Complete pending action for your account number to be generated.
        </p>
        <div
          className="w-32 py-2 mt-2 rounded-md flex justify-center cursor-pointer bg-white hover:bg-lib-alat-dark-red hover:text-white text-lib-alat-dark-red text-[10px]"
          onClick={handleClick}
          id="account-refresh_btn"
        >
          <div>Refresh</div>
        </div>
      </div>
    </div>
  );
};

export default PendingAccountCard;
