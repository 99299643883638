import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { PrimaryButton } from "alat-components-library";
import { useNavigate } from "react-router-dom";
import { downloadPDFFile, downloadCSVFile } from "utils/helperFunctions";
import { Card } from "flowbite-react";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/success-icon.svg";
import { ReactComponent as PdfIcon } from "../../../assets/svgs/pdf.svg";
import { ReactComponent as ExcelIcon } from "../../../assets/svgs/excel.svg";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import dayjs from "dayjs";
import { accountStatementTypeEnum } from "utils/enums";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";

const AccountStatementSuccess = () => {
  const navigate = useNavigate();
  const paymentState = usePaymentState();

  const {
    accountStatementPDFURL,
    accountStatmentType,
    accountStatementPayload,
  } = paymentState;

  const authState = useAuthState();
  const isApprover = authState?.user?.role === "Approver";
  const isInitiatorApprover = authState?.user?.role === "InitiatorApprover";
  const isSuperapprover = authState?.user?.role === "SuperApprover";
  const isSingleSignatory = authState?.user?.role === "SoleProprietor";
  const isLikeSingleSignatory =
    isSingleSignatory || isApprover || isInitiatorApprover || isSuperapprover;

  const isStamped = accountStatementPayload?.isStamped;
  const hasPdfUrl = !!accountStatementPDFURL?.statementURL;

  const isStampedSingle = isLikeSingleSignatory && isStamped;

  return (
    <DashboardWrapper>
      <div className="px-4 lg:px-20 w-full flex items-center justify-center">
        <Card className="md:w-[768px] mt-5 w-full bg-white pb-14  dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
              onClick={() => navigate("/payments/account-statement")}
            />
            <div
              className="text-[#4D4D4D] text-xl font-bold text-center"
              id="details_text"
            >
              Details
            </div>
            <div></div>
          </div>
          <hr />
          <div>
            <div
              className="text-center flex justify-center mt-10"
              id="success_icon"
            >
              <SuccessIcon />
            </div>
            <div
              className="text-center text-2xl font-semibold mt-6"
              id="success_text"
            >
              Success!
            </div>

            {/* Show if not stamped or stamped single, S/A,I/A, approver  */}
            {(isStampedSingle || !isStamped) && (
              <>
                <h3
                  className="text-center mb-5 text-md font-normal text-black mt-5"
                  id="successfully-generated_text"
                >
                  {accountStatmentType ===
                    accountStatementTypeEnum.ChequeClearance &&
                    `Cheque statement generated successfully`}
                  {accountStatmentType !==
                    accountStatementTypeEnum.ChequeClearance &&
                    hasPdfUrl &&
                    `Account statement generated successfully`}
                  {isStamped &&
                    !hasPdfUrl &&
                    "Your account statement will be sent to your email shortly."}
                </h3>
                {accountStatmentType ===
                accountStatementTypeEnum.ChequeClearance ? (
                  <div className="flex w-full justify-center flex-row mt-10 space-y-3">
                    <div className="flex gap-2 w-full justify-center items-center">
                      <PrimaryButton
                        id="download-statement_btn"
                        labelText={
                          <div className="flex gap-1 items-center">
                            <ExcelIcon />
                            <div>Download Excel</div>
                          </div>
                        }
                        variant="font-medium text-xs text-white"
                        containerVariant="md:w-1/2 w-full h-[48px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red shadow-md"
                        handleClick={() => {
                          accountStatementPDFURL &&
                            downloadCSVFile(
                              accountStatementPDFURL.csvData || "",
                              `account_statement_${dayjs().format()}.csv`
                            );
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex w-full justify-center flex-row mt-10 space-y-3">
                    <div className="flex gap-2 w-full items-center">
                      <PrimaryButton
                        id="download-statement_btn"
                        labelText={
                          isStamped && !hasPdfUrl ? (
                            "Proceed to Dashboard"
                          ) : (
                            <div className="flex gap-1 items-center">
                              <PdfIcon />
                              <div>Download PDF</div>
                            </div>
                          )
                        }
                        variant="font-medium text-xs text-white"
                        containerVariant="md:w-1/2 w-full max-w-[409px] mx-auto h-[48px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red shadow-md"
                        handleClick={() => {
                          if (hasPdfUrl) {
                            accountStatementPDFURL &&
                              downloadPDFFile(
                                accountStatementPDFURL.statementURL || "",
                                `account_statement_${dayjs().format()}`
                              );
                          }
                          // When stamped statement does not have pdf url
                          if (isStamped && !hasPdfUrl) {
                            navigate("/user-dashboard");
                          }
                        }}
                      />
                      {!isStamped && (
                        <PrimaryButton
                          id="download-statement_btn"
                          labelText={
                            <div className="flex gap-1 items-center">
                              <ExcelIcon />
                              <div>Download Excel</div>
                            </div>
                          }
                          variant="font-medium text-xs text-white"
                          containerVariant="md:w-1/2 w-full h-[48px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red shadow-md"
                          handleClick={() => {
                            accountStatementPDFURL &&
                              downloadPDFFile(
                                accountStatementPDFURL.excelStatementURL || "",
                                `account_statement_${dayjs().format()}`
                              );
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {/* For stamped mutltiple signatory requests that is not I/A, Approver or S/A  */}
            {!isLikeSingleSignatory && isStamped && (
              <>
                <h3
                  className="text-center mb-5 text-md font-normal text-black mt-5"
                  id="verification-pending_text"
                >
                  Request has been sent for verification/approval
                </h3>
                <PrimaryButton
                  id="navigate_btn"
                  labelText={
                    <div className="flex gap-1 items-center">
                      <div>Done</div>
                    </div>
                  }
                  variant="font-medium text-xs text-white"
                  containerVariant="max-w-[409px] mx-auto w-full h-[48px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red shadow-md"
                  handleClick={() => navigate("/user-dashboard")}
                />
              </>
            )}
            {/* <div className="text-center mb-5 text-sm rounded-lg font-normal text-gray-500 light:text-gray-400 mt-10 mx-10 p-4 bg-[#F5F5F5]">
                      We will send you a notification as soon as the request is approved
                    </div> */}

            {!(isStamped && !hasPdfUrl) && (
              <div className="flex w-full items-center justify-center">
                <PrimaryButton
                  id="proceed-to-dashboard_btn"
                  labelText={"Proceed to Dashboard"}
                  variant="font-medium text-xs text-lib-alat-red"
                  containerVariant="w-[409px] h-[48px] rounded-md flex justify-center cursor-pointer bg-white"
                  handleClick={() => navigate("/user-dashboard")}
                />
              </div>
            )}
          </div>
        </Card>
      </div>
    </DashboardWrapper>
  );
};

export default AccountStatementSuccess;
