import React, { ReactNode } from "react";
import checkedRadioIcon from "../../../../assets/icons/checkedRadioIcon.svg";
import defaultRadioIcon from "../../../../assets/icons/defaultRadioIcon.svg";

export interface Props {
  children?: ReactNode;
  align?: string;
  checked?: boolean;
  id?: string;
  clickEvent: () => void;
}

export const RadioInput: React.FC<Props> = ({
  checked,
  children,
  align = "",
  id = "",
  clickEvent,
}) => {
  return (
    <div className={`flex items-center ${align} w-full`}>
      <img
        src={checked ? checkedRadioIcon : defaultRadioIcon}
        alt=""
        className={`w-[2.5vh] h-[2.5vh] cursor-pointer mt-1 md:mt-0`}
        onClick={clickEvent}
        id={id}
      />
      <label
        htmlFor="checkbox"
        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-full"
      >
        {children}
      </label>
    </div>
  );
};
