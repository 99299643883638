import React from "react";
import BackArrow from "../../../../assets/svgs/BackArrow.svg";
import AccountSetupTab from "../../../../components/Dashboard/components/AccountSetupTab";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "../../../../components/Layouts/DashboardWrapper";
import { RefereeDetails } from "./RefereeForm";
// import RefereeDetails from 'components/Dashboard/components/RefereeDetails';

/** ADD A REFEREE FORM */

const RefereeSetup = () => {
  const navigate = useNavigate();

  return (
    <DashboardWrapper>
      <div className="w-full">
        <div className="flex justify-start mt-4 md:mx-16 items-center">
          <img
            src={BackArrow}
            alt="<"
            onClick={() => navigate("/user-dashboard")}
            className="cursor-pointer"
          />
          <p className="text-[#333333] font-bold md:text-2xl text-lg">
            Account Setup
          </p>
        </div>
        <div className="md:mx-20">
          <AccountSetupTab
            tabs={[
              {
                id: "referee",
                label: "Referee",
                content: <RefereeDetails />,
              },
            ]}
          />
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default RefereeSetup;
