import React from "react";
import { ReactComponent as CancelIcon } from "../../../../alat-components-library/assets/images/icons/cancel_icon.svg";
import { PrimaryButton } from "alat-components-library";

type BulkNoticeProp = {
  total: number;
  validated: number;
  notValidated: number;
  retry: number;
  onClose: () => void;
  onSubmit: () => void;
};

const BulkTransferNotice = ({
  total,
  validated,
  notValidated,
  retry,
  onClose,
  onSubmit,
}: BulkNoticeProp) => {
  return (
    <div className="fixed top-0 left-0 overflow-y-auto flex justify-center items-center w-full h-full bg-black/80 p-5 z-2000 overflow-hidden backdrop-filter backdrop-blur-sm">
      <div className="h-auto md:w-[768px] w-full bg-white fixed z-1000 rounded-[8px] p-5">
        <div className="flex items-center justify-between  px-4 py-2">
          <div
            id="close-bulk-notice_btn"
            onClick={onClose}
            className="cursor-pointer flex justify-end w-full"
          >
            <CancelIcon />
          </div>
        </div>
        <div className="w-full flex items-center justify-center">
          <div className="md:w-[409px] flex flex-col w-full space-y-4">
            <div className="text-center text-lib-alat-black ">
              <p className="font-semibold text-[22px]">
                Do you want to proceed?
              </p>
            </div>
            <div className="text-center">
              <p className="text-[16px] leading-[27.2px]">
                Some bank accounts were not successfully validated and will not
                be included in the transaction
              </p>
            </div>
            <div className="flex w-full flex-col gap-3 mb-5">
              <div className="flex w-full items-center justify-between">
                <p className="text-[16px] leading-[27.2px]">Total records</p>
                <p className="text-[16px] leading-[27.2px]">{total}</p>
              </div>
              <div className="flex w-full items-center justify-between">
                <p className="text-[16px] leading-[27.2px]">
                  Successful records
                </p>
                <p className="text-[16px] leading-[27.2px]">{validated}</p>
              </div>
              <div className="flex w-full items-center justify-between">
                <p className="text-[16px] leading-[27.2px]">
                  Unsuccessful records
                </p>
                <p className="text-[16px] leading-[27.2px]">
                  {notValidated + retry}
                </p>
              </div>
            </div>
            <div className="flex gap-3 mb-5 w-full items-center justify-center">
              <PrimaryButton
                id="cancel_btn"
                labelText={"Cancel"}
                variant="font-medium text-xs"
                containerVariant="w-1/2 rounded-md flex justify-center border border-lib-alat-red cursor-pointer bg-white py-3 hover:bg-[#f2f5fc]"
                handleClick={onClose}
              />
              <PrimaryButton
                id="submit_btn"
                labelText={"Proceed"}
                handleClick={() => {
                  onSubmit();
                  onClose();
                }}
                isDisabled={false}
                variant={"text-white font-medium text-xs"}
                containerVariant={`w-1/2 h-10 bg-lib-alat-red rounded-md flex justify-center cursor-pointer`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkTransferNotice;
