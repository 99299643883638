import { lazy } from "react";

const ValidateAccount = lazy(() =>
  import("../pages/AccountReactivation").then(({ ValidateAccount }) => ({
    default: ValidateAccount,
  }))
);
const ReactivateAccount = lazy(() =>
  import("../pages/AccountReactivation/ReactivateAccount").then(
    ({ ReactivateAccount }) => ({
      default: ReactivateAccount,
    })
  )
);

export const ACCOUNT_REACTIVATION_URL = {
  ACCOUNT_REACTIVATION: "/account-reactivation",
  VALIDATE_ACCOUNT: "/validate-account",
};

export const accountReactivationRoutes = [
  {
    path: ACCOUNT_REACTIVATION_URL.ACCOUNT_REACTIVATION,
    isAuthenticated: false,
    element: <ReactivateAccount />,
  },

  {
    path: ACCOUNT_REACTIVATION_URL.VALIDATE_ACCOUNT,
    isAuthenticated: false,
    element: <ValidateAccount />,
  },
];
