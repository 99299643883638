import React, { useRef, useEffect } from "react";
import AFBLogo from "../../../assets/svgs/AFBLogoOpaque.svg";
import { FormikButton } from "../../../alat-components-library";

interface Props {
  open: boolean;
  closeFunction: () => void;
  handleProceed: () => void;
}
export const ListItem = ({ text }: { text: string }) => (
  <div className="flex gap-[6px] pb-6">
    <div className="pt-[6px]">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="5.76471" cy="5.98834" r="5.76471" fill="#333333" />
      </svg>
    </div>
    <p className="text-lg text-[#333333]">{text}</p>
  </div>
);
const RefereeSetupModal = ({ open, handleProceed, closeFunction }: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeFunction();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [open]);
  return (
    <div
      className={`fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-50 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm ${!open && "hidden"}`}
    >
      <center>
        <div className=" flex justify-center items-center w-full relative pt-20">
          <div
            ref={modalRef}
            className="overflow-auto max-h-[320px] h-auto md:max-h-[70vh] w-full max-w-[550px] bg-white relative border top-10 z-1000 rounded-md py-12 px-12"
          >
            <div className="flex justify-between mb-3 gap-4 items-center">
              <img src={AFBLogo} alt="AFB-logo" className=" w-36" />

              <div
                className=" text-4xl cursor-pointer"
                onClick={() => closeFunction()}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.00098 20L12.001 12M20.001 4L11.9995 12M11.9995 12L4.00098 4M12.001 12L20.001 20"
                    stroke="#606060"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="text-left max-w-[453px] mx-auto">
              <h1 className=" font-bold text-3xl">Referee Requirement</h1>
              <h1 className=" font-semibold text-lg">
                FOR WEMA ACCOUNT HOLDER
              </h1>
              <hr className="mt-[9px]" />
              <div className="flex w-full pt-7 justify-around">
                <div className="w-full">
                  <ListItem text="The referee account must be active" />
                  <ListItem text="The account must have been active for 6 months" />
                  <ListItem
                    text=" The referee must be an Enterprise or Limited Liability
                      Company"
                  />
                </div>
              </div>
              <div className="w-full pb-10" onClick={handleProceed}>
                <FormikButton
                  id="referee-modal-proceed_btn"
                  labelText="Proceed"
                  containerVariant="w-full h-10 rounded-md flex justify-center mx-auto mt-10"
                />
              </div>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default RefereeSetupModal;
