import React, { useState, useEffect } from "react";
import LeftIcon from "../../../assets/svgs/left-arrow-icon.svg";
import RightIcon from "../../../assets/svgs/right-arrow-icon.svg";
import PendingCard from "./PendingCard";
import { pendingData } from "./PendingData";
import AccountSetupModal from "./AccountSetupModal";
import RefereeSetupModal from "./RefereeSetupModal";
import NonWemaRefereeSetupModal from "./NonWemaRefereeSetupModal";

const PendingActionCarousel = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [pauseScroll, setPauseScroll] = useState(false);
  const [accountModal, setAccountModal] = useState(false);
  const [refereeModal, setRefereeModal] = useState(false);
  const [refereeModal2, setRefereeModal2] = useState(false);
  const increaseCount = () => {
    let scrollTimeout;
    if (!pauseScroll) {
      scrollTimeout = setTimeout(() => {
        const currentTab = (activeTab + 1) % 4;
        setActiveTab(currentTab === 0 ? 1 : currentTab);
      }, 5000);
    } else {
      clearTimeout(scrollTimeout);
    }
  };

  const goToPrevTab = () => {
    activeTab !== 1 ? setActiveTab(activeTab - 1) : setActiveTab(3);
  };

  const goToNextTab = () => {
    const currentTab = (activeTab + 1) % (pendingData.length + 1);
    setActiveTab(currentTab === 0 ? 1 : currentTab);
  };

  useEffect(() => {
    increaseCount();
  }, [activeTab, pauseScroll]);
  return (
    <>
      <div
        className="bg-white h-80 rounded-lg p-4 relative"
        onMouseEnter={() => setPauseScroll(true)}
        onMouseLeave={() => setPauseScroll(false)}
      >
        <div className="w-full h-full pb-10 relative">
          <h4 className="text-left text-xl font-semibold">Pending Actions</h4>
          <div className="flex items-center w-full justify-between h-full gap-4">
            <img
              src={LeftIcon}
              alt="left"
              className="cursor-pointer -mt-5"
              onClick={goToPrevTab}
            />
            <div className="w-full h-full flex items-center justify-center">
              {pendingData.map((pending) => (
                <PendingCard
                  id="referee_btn"
                  key={pending.key}
                  activeTab={activeTab}
                  currentTab={pending.currentTab}
                  icon={pending.icon}
                  heading={pending.heading}
                  info={pending.info}
                  clickEvent={() => setRefereeModal(true)}
                />
              ))}
            </div>
            <img
              src={RightIcon}
              alt="right"
              className="cursor-pointer -mt-5"
              onClick={goToNextTab}
            />
          </div>
        </div>
        <div className="absolute bottom-7 w-full flex justify-around">
          <div className="flex gap-[2px] w-20 h-1">
            <div
              className={`transition-width duration-[800ms] rounded-full ${activeTab === 1 ? "w-full bg-black" : "w-7 bg-lib-alat-border-gray"}`}
            ></div>
            <div
              className={`transition-width duration-[800ms] rounded-full ${activeTab === 2 ? "w-full bg-black" : "w-7 bg-lib-alat-border-gray"}`}
            ></div>
            <div
              className={`transition-width duration-[800ms] rounded-full ${activeTab === 3 ? "w-full bg-black" : "w-7 bg-lib-alat-border-gray"}`}
            ></div>
          </div>
        </div>
      </div>
      <AccountSetupModal
        open={accountModal}
        closeFunction={() => setAccountModal(false)}
      />
      <RefereeSetupModal
        open={refereeModal}
        handleProceed={() => {
          setRefereeModal(false);
          setRefereeModal2(true);
        }}
        closeFunction={() => setRefereeModal(false)}
      />
      <NonWemaRefereeSetupModal
        open={refereeModal2}
        closeFunction={() => setRefereeModal2(false)}
      />
    </>
  );
};

export default PendingActionCarousel;
