import React from "react";
import ButtonImage from "assets/svgs/icons8-delete.svg";
interface CardProps {
  title: string;
  description: string;
  logo: string;
  onDelete: any;

  onClick: () => void;
}
const Cards: React.FC<CardProps> = ({
  title,
  description,
  logo,
  onClick,
  onDelete,
}) => {
  return (
    <div className="card" onClick={onClick}>
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "90px",
          background: "#FFFFFF",
          padding: "30px",
          boxSizing: "border-box",
          marginTop: "15px",
          width: "370px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            src={logo}
            alt={title}
            style={{
              width: "45px",
              height: "45px",
            }}
          />
          <span style={{ marginLeft: "20px" }}>
            {title} <br />
            {description}
            {onDelete}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        ></div>

        <button
          style={{
            position: "absolute",
            bottom: "20px",
            right: "20px",
            width: "60px",
            height: "50px",
            background: "white",
            borderRadius: "60%",
            border: "none",
            cursor: "pointer",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button className="delete-button" onClick={onDelete}>
            <img src={ButtonImage} alt="Delete" />{" "}
          </button>
        </button>
      </div>
    </div>
  );
};

export default Cards;
