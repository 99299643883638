import React, { useState, useEffect } from "react";
import { ReactSuspenceWrapper, TableOne } from "alat-components-library";
import { NavLink, useNavigate } from "react-router-dom";
import { UsersListBody } from "../../components/Admin/UsersListBody";
import { usersTableHeaders } from "../../utils/dummieData/usersDash";
import AccountSlide from "../../components/Dashboard/User/AccountSlide";
import DashboardWrapper from "../../components/Layouts/DashboardWrapper";
import { filterList } from "./filterList";
import { useAppDispatch } from "../../hooks/useReduxHook";
import { POST_FETCH_USERS_ACTION } from "../../react-wrappers/stores/redux/profile/actions";
import {
  resetProfileData,
  useProfileState,
  setSkipAndTake,
} from "../../react-wrappers/stores/redux/profile/slices";
import { resetAdminData } from "react-wrappers/stores/redux/admin/slices";
import { useAccountsState } from "react-wrappers/stores/redux/accounts/slices";
import PageLoader from "components/Loader/PageLoader";
import { useGetNotifications } from "hooks/apis/Notifications/useGetNotifications";

export const AdminDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { allUsers, loading, usersPagination } = useProfileState();
  const accounts = useAccountsState();
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState(1);
  const [label, setFilterLabel] = useState("");
  const { handleFetchNotificationsCount } = useGetNotifications();

  const handleCallback = (type: string, payload: any) => {
    if (type === "search") {
      setSearchValue(payload);
    } else {
      setFilterLabel(payload?.label);
      setFilterValue(payload?.value);
    }
  };
  useEffect(() => {
    dispatch(
      setSkipAndTake({
        skip: 1,
        take: 10,
      })
    );
    const timeout = setTimeout(() => {
      (searchValue !== "" || label !== "") &&
        dispatch(
          POST_FETCH_USERS_ACTION({
            searchValue: searchValue,
            requestFilter: filterValue,
            pageSize: 10,
            pageNumber: 1,
          })
        );
    }, 3000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [searchValue, label]);

  const handleViewNext = () => {
    dispatch(
      setSkipAndTake({
        skip: usersPagination?.skip + 10,
        take: usersPagination?.take + 10,
      })
    );
    dispatch(
      POST_FETCH_USERS_ACTION({
        searchValue: searchValue,
        requestFilter: filterValue,
        pageSize: 10,
        pageNumber: usersPagination?.currentPage + 1,
      })
    );
  };
  const handleViewPrevious = () => {
    dispatch(
      setSkipAndTake({
        skip: usersPagination?.skip - 10,
        take: usersPagination?.take - 10,
      })
    );
    dispatch(
      POST_FETCH_USERS_ACTION({
        searchValue: searchValue,
        requestFilter: filterValue,
        pageSize: 10,
        pageNumber: usersPagination?.currentPage - 1,
      })
    );
  };

  useEffect(() => {
    dispatch(resetProfileData());
    dispatch(resetAdminData());
    dispatch(
      POST_FETCH_USERS_ACTION({
        searchValue: "",
        requestFilter: 1,
        pageSize: 10,
        pageNumber: 1,
      })
    );
    handleFetchNotificationsCount();
    // eslint-disable-next-line
  }, []);

  return (
    <ReactSuspenceWrapper>
      <DashboardWrapper>
        {accounts?.loading?.includes("GET_ALL_ACCOUNTS") && <PageLoader />}
        <div className="lg:px-20 space-y-4 mt-5" id="dashboard_page">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold">Overview</h2>
            <NavLink
              to={"/admin-dashboard/accounts"}
              className="text-lib-alat-dark-red"
            >
              <div className="text-xs font-bold cursor-pointer">
                <p id="see_all_accounts_text">SEE ALL ACCOUNTS</p>
              </div>
            </NavLink>
          </div>

          <AccountSlide />

          <TableOne
            title="Users"
            isFilter={true}
            isSearch={true}
            isPagination={true}
            showTableHeader
            handleClick={() => {
              navigate("/create-user");
            }}
            onHandleCallback={(type, payload) => {
              if (type === "search") return handleCallback("search", payload);
              return handleCallback("filter", payload);
            }}
            header={usersTableHeaders}
            preloader={!!loading.includes("POST_FETCH_USERS")}
            body={
              <>
                <UsersListBody list={allUsers} />
              </>
            }
            filterListProp={filterList}
            filterValue={label}
            paginationData={usersPagination}
            handleShowNext={handleViewNext}
            handleShowPrevious={handleViewPrevious}
            value={searchValue}
            showAddBtn
            isCheckboxes={false}
          />
        </div>
      </DashboardWrapper>
    </ReactSuspenceWrapper>
  );
};
