import React from "react";
import { ReactSuspenceProps } from "../interfaces";
import PageLoader from "components/Loader/PageLoader";
import ErrorBoundary from "ErrorBoundary";

const ReactSuspenceWrapper = ({ children }: ReactSuspenceProps) => {
  return (
    <React.Suspense fallback={<PageLoader message="" />}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </React.Suspense>
  );
};

export default ReactSuspenceWrapper;
