import { useAppDispatch } from "hooks/useReduxHook";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { POST_FETCH_TRANSACTION_LIST_ACTION } from "react-wrappers/stores/redux/payments/actions";
import {
  usePaymentState,
  resetPaymentsData,
} from "react-wrappers/stores/redux/payments/slices";
import { handlePendingActionNavigation } from "./utils";

const PendingTransactionsCard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { pendingTransactionList } = usePaymentState();

  useEffect(() => {
    dispatch(resetPaymentsData());
    const payload = {
      mappedAccounts: user.accounts.map((x: any) => x.accountNo),
      searchValue: "",
      pendingRequestFilter: 0,
      pageSize: 10,
      pageNumber: 1,
    };
    dispatch(POST_FETCH_TRANSACTION_LIST_ACTION(payload));
  }, []);

  return (
    <div className="bg-white h-80 rounded-lg p-4 relative">
      <div className="w-full h-full pb-10 relative">
        <div className="text-[#333333] flex justify-between items-center pb-3">
          <h4 className="text-left text-lg font-semibold">Pending Actions</h4>
          <p
            id="see-all_btn"
            className="text-xs text-lib-alat-dark-red font-bold cursor-pointer"
            onClick={() => navigate("/payments/pending-transactions")}
          >
            SEE ALL
          </p>
        </div>

        <div className="flex w-full h-full gap-1 flex-col">
          <div className="border-b text-gray-400 text-xs flex items-center justify-between">
            <div className="flex justify-start w-full">
              <p>FROM</p>
            </div>
            <div className="flex justify-start w-full">
              <p className="">SUBJECT</p>
            </div>
            <div className="flex justify-start w-full">
              <p>STATUS</p>
            </div>
          </div>
          {pendingTransactionList?.sortedData
            ?.slice(0, 4)
            ?.map((item: any, index: number) => {
              return (
                <div
                  id={`pending_${index + 1}_btn`}
                  key={item?.correlationId}
                  className="border-b p-2 cursor-pointer text-[#333333] text-xs flex items-center justify-between w-full hover:bg-lib-alat-gray-card-one"
                  onClick={() => {
                    // if ([6, 7]?.includes(item?.type)) {
                    //   navigate(
                    //     `/payments/requests/details/${item?.correlationId}`
                    //   );
                    // } else {
                    //   navigate(
                    //     `/payments/transaction/details?correlationId=${item?.correlationId}`
                    //   );
                    // }
                    navigate(handlePendingActionNavigation(item));
                  }}
                >
                  <div
                    className="flex justify-start w-full"
                    id={`name_${index + 1}_text`}
                  >
                    {item?.sourceAccountName === ""
                      ? item?.initiatedBy
                      : item?.sourceAccountName}
                  </div>
                  <div
                    className="flex justify-start w-full"
                    id={`narration_${index + 1}_text`}
                  >
                    {item?.narration === "" ? item?.typeName : item?.narration}
                  </div>
                  <div className="text-[#FF9900] cursor-pointer text-[10px] text-center rounded flex justify-start w-full">
                    <div
                      style={{ background: "rgba(255, 153, 0, 0.2)" }}
                      className="border border-[#FF9900] px-2 py-1 rounded-md"
                      id={`status_${index + 1}_text`}
                    >
                      {item?.status === "" ? "Pending Approval" : item?.status}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PendingTransactionsCard;
