export const POST_VALIDATE_CUSTOMER: string = `validation/customer-info`;
export const POST_ADMIN_CREATES_USER: string = `User`;
export const POST_FETCH_USERS = `User/filter`;
export const GET_USER_SECURITY_QUESTION: string = `customer/security-questions`;
export const GET_ACCOUNT_INFORMATION = (accountNumber: string) =>
  `customer/AccountInformation?accountNumber=${accountNumber}`;
export const POST_USER_CHANGES_PIN: string = `customer-management/change-pin`;
export const POST_USER_CREATES_PIN: string = `customer-management/reset-pin`;
export const POST_USERS_CHANGE_PASSWORD: string = `customer-management/change-password`;
export const POST_USER_VERIFIES_SECURITY_QUESTION: string = `customer/verify-security-question`;
export const POST_REVIEW_USER_REQUEST: string = `User/review-operation`;
export const POST_REQUEST_SOFT_TOKEN: string = `customer-management/request-soft-token`;
export const POST_GENERATE_OTP_AND_PASSCODE: string = `v1/Otp/Generate`;
export const POST_SOFT_TOKEN_REQUEST_FOR_APPROVER: string =
  "customer-management/approver-request-soft-token";
