import { lazy } from "react";
import { Storage } from "utils/inAppstorage";

const AccountsContainer = lazy(() =>
  import("pages/Accounts/AccountsContainer").then(({ AccountsContainer }) => ({
    default: AccountsContainer,
  }))
);
const InAppReactivateAccount = lazy(() =>
  import("../pages/AccountReactivation/InAppReactivation").then(
    ({ InAppReactivateAccount }) => ({
      default: InAppReactivateAccount,
    })
  )
);

const isProtected = Storage.getItem("afb-app-token");

export const routes = [
  {
    path: "/accounts/:tabPath", // naira & fx accounts
    isAuthenticated: true,
    element: <AccountsContainer />,
  },
  {
    path: "/dashboard/account-reactivation",
    isAuthenticated: true,
    element: <InAppReactivateAccount />,
  },
];

const unProtectedRoutes: any = [];

export const accountsRoutes = isProtected ? routes : unProtectedRoutes;
