import { createAsyncThunk } from "@reduxjs/toolkit";
import { useApiRequest } from "../../../../hooks/useApiRequest";
import {
  GET_ALL_ACCOUNTS,
  POST_ACCOUNT_SETTINGS,
} from "../../../../utils/apis/routes";
import {
  AccountSettingsPayload,
  UpdateAccountSettingsPayload,
} from "../interfaces";

export const GET_ALL_ACCOUNTS_ACTION = createAsyncThunk(
  "GET_ALL_ACCOUNTS",
  async () => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "get",
      route: GET_ALL_ACCOUNTS,
      originType: "identity",
    });
  }
);

export const POST_ACCOUNT_SETTINGS_ACTION = createAsyncThunk(
  "POST_ACCOUNT_SETTINGS",
  async (payload: AccountSettingsPayload) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_ACCOUNT_SETTINGS,
      body: payload?.accountList,
      originType: "identity",
    });
  }
);

export const PUT_UPDATE_ACCOUNT_SETTINGS_ACTION = createAsyncThunk(
  "PUT_UPDATE_ACCOUNT_SETTINGS",
  async (payload: UpdateAccountSettingsPayload) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "put",
      route: POST_ACCOUNT_SETTINGS,
      body: payload,
      originType: "identity",
    });
  }
);
