import React, { useState } from "react";
import styles from "./AccountCardOne.module.scss";
import { AccountCardOneProps } from "../types";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { RiFileCopyLine } from "react-icons/ri";
import { PrimaryButton } from "../../Buttons";
import CardLightRed from "../../assets/images/cards/card-light-red.svg";
import CardDarkPurple from "../../assets/images/cards/card-dark-purple.svg";
import CardGreyPurple from "../../assets/images/cards/card-grey-purple.svg";
import Users from "../../assets/images/icons/users.svg";
import { truncateString } from "../../utils/helpers";
import { ToastNotify } from "../../../helpers/toastNotify";
import { formatNumberToCurrency } from "../../../utils/helperFunctions";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";

export const AccountCardOne = ({
  containerVariant = "rounded-xl w-[293px]",
  accountTypeTitle = "(ACTIVE)",
  title = "Eze and Sons Int’l LTD",
  availableBalance = {
    currency: "NGN",
    value: "0",
    unicodeCharacter: "₦",
  },
  ledgerBalance = {
    currency: "NGN",
    value: "0",
    unicodeCharacter: "₦",
  },
  bgType = "light-red", //light-red, dark-purple, grey-purple,
  accountNumber,
  onHandleClick,
  // textToCopy,
  numOfUsers,
  isAdmin = false,
}: AccountCardOneProps) => {
  // Variable
  const iconStyle = {
    wrapper: "bg-green-500",
    icon: "w-4 h-4 text-white",
  };

  // Preview the desire background for card base onbg type
  let cardBg = CardLightRed;
  if (bgType === "light-red") {
    cardBg = CardLightRed;
  }
  if (bgType === "dark-purple") {
    cardBg = CardDarkPurple;
  }
  if (bgType === "grey-purple") {
    cardBg = CardGreyPurple;
  }
  const isInactive = accountTypeTitle.toLowerCase() === "inactive";
  // const isInactive = false;
  // Local State
  const [passwordShown, setPasswordShown] = useState(false);

  const { user } = useAuthState();
  const canViewBalance = user?.canViewBalance;

  return (
    <div
      className={`${styles.accountCardOne} ${containerVariant} ${
        isAdmin && "space-y-4"
      }`}
      style={{
        background: `url(${cardBg})`,
      }}
    >
      <p
        className="text-[8.56187px] text-center pt-1.5 text-white"
        id="accountstatus_text"
      >
        {" "}
        {accountTypeTitle}{" "}
      </p>
      <div className={`px-4 py-2 ${isAdmin && "space-y-4"}`}>
        <p className="text-xs text-left text-white" id="accountname_text">
          {truncateString(title, 30)}
        </p>
        {!isAdmin && (
          <>
            <p className="text-xs text-left text-white">Avail. Balance</p>
            <div className="text-lg text-left text-white font-bold flex justify-start items-center space-x-1">
              {passwordShown ? (
                <div id="balance_amount">
                  {formatNumberToCurrency({
                    number: Number(availableBalance?.value),
                    currencyCode: availableBalance?.currency,
                    precision: 2,
                  })}
                  <span hidden id="balance_amount1">
                    {availableBalance.value}
                  </span>
                </div>
              ) : (
                <div>***************</div>
              )}
              <div className="relative cursor-pointer">
                <span
                  className={`${iconStyle.wrapper}`}
                  onClick={() => {
                    if (canViewBalance) {
                      setPasswordShown(!passwordShown);
                    } else {
                      ToastNotify({
                        type: "error",
                        message:
                          "You are not permitted to carry out this action. Kindly contact your admin",
                        position: "top-right",
                      });
                    }
                  }}
                  id="hidebalance_btn"
                >
                  {passwordShown ? (
                    <AiOutlineEyeInvisible className={iconStyle.icon} />
                  ) : (
                    <AiOutlineEye className={iconStyle.icon} />
                  )}
                </span>
              </div>
            </div>
            <div className="flex">
              <p className="text-xs text-left text-white">
                Ledger Balance: &nbsp;
              </p>
              {passwordShown ? (
                <p className="text-xs text-left text-white font-bold">
                  {formatNumberToCurrency({
                    number: Number(ledgerBalance?.value),
                    currencyCode: ledgerBalance?.currency,
                    precision: 2,
                  })}
                </p>
              ) : (
                <p className="text-xs text-left text-white font-bold">
                  ************
                </p>
              )}
            </div>
          </>
        )}
        <div
          className="text-lg text-left flex justify-start items-center space-x-3"
          id="copy_btn"
          // onClick={copyToClipboard}
          onClick={async () => {
            try {
              if (accountNumber) {
                await navigator.clipboard.writeText(accountNumber);
                return ToastNotify({
                  type: "success",
                  message: "Copied!",
                  position: "top-center",
                });
              }
            } catch (err) {
              return ToastNotify({
                type: "error",
                message: `${err && "Failed to copy!"}`,
                position: "top-center",
              });
            }
          }}
        >
          <p
            className="text-sm text-left text-white font-semibold"
            id="accountnumber_text"
          >
            {accountNumber}
          </p>
          <div className="relative cursor-pointer flex justify-start items-center space-x-1">
            <p className="text-[10px] text-left text-white">Tap to copy</p>
            <span>
              <RiFileCopyLine className="w-4 h-4 text-white" />
            </span>
          </div>
        </div>
        <div className="flex justify-between space-x-2 pt-2 mb-2">
          {!isInactive && (
            <>
              {isAdmin ? (
                <div className="flex items-center">
                  <img src={Users} alt={"users"} />
                  <span className="text-white text-xs leading-5">
                    Number of users: {numOfUsers}{" "}
                  </span>
                </div>
              ) : (
                availableBalance?.currency?.toLowerCase() !== "usd" && (
                  <PrimaryButton
                    labelText={"Fund account"}
                    variant="font-medium text-xs text-lib-alat-red"
                    containerVariant="w-1/2 h-8 rounded-md flex justify-center cursor-pointer bg-white hover:bg-white"
                    id="fundaccount_btn"
                    handleClick={(e) =>
                      onHandleClick && onHandleClick(e, "fund-account")
                    }
                  />
                )
              )}
            </>
          )}

          <PrimaryButton
            labelText={
              isInactive
                ? "Reactivate"
                : isAdmin
                  ? "View"
                  : "Transaction History"
            }
            variant={`${
              isInactive ? "text-[#501C5D]" : ""
            } font-medium text-xs text-lib-alat-red`}
            containerVariant={`${
              isInactive ? "w-full " : isAdmin ? "w-1/3" : "w-1/2"
            } h-8 rounded-md flex justify-center cursor-pointer bg-white hover:bg-white`}
            id={`${
              isInactive
                ? "reactivate_btn"
                : isAdmin
                  ? "view_btn"
                  : "transactionhistory_btn"
            }`}
            handleClick={(e) =>
              onHandleClick && onHandleClick(e, isAdmin ? "View" : "see more")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AccountCardOne;
