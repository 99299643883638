import { PrimaryButton } from "alat-components-library";
import { useNavigate } from "react-router-dom";
import { Card } from "flowbite-react";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/success-icon.svg";
import {
  resetCardsData,
  setActiveCardRequestScreen,
} from "react-wrappers/stores/redux/cards/slices";
import { useAppDispatch } from "hooks/useReduxHook";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useCardState } from "react-wrappers/stores/redux/cards/slices";

const SuccessScreen = ({ setScreen, pageName = " Card Activation" }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { statusMessage } = useCardState();

  return (
    <div className="w-full pt-6" id="success_screen">
      <Card className="md:w-[768px] w-full bg-white dark:border-gray-200 mx-auto px-6">
        <div>
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              onClick={() => {
                setScreen(0);
                dispatch(setActiveCardRequestScreen(2));
              }}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            />
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="banktransfer_heading"
            >
              {pageName}
            </div>
            <div></div>
          </div>
        </div>
        <hr />
        <div>
          <div className="text-center flex justify-center mt-10">
            <SuccessIcon />
          </div>
          <div className="text-center text-2xl font-semibold mt-6">
            Success!
          </div>
          <div className="text-center text-sm rounded-lg font-normal text-gray-500 light:text-gray-400 p-4">
            {statusMessage ?? "Action Successful"}
          </div>
          <div className="flex justify-center flex-row gap-4 pt-4 pb-6">
            <PrimaryButton
              id="proceed-to-dashboard_btn"
              labelText={"Okay"}
              variant="font-medium text-xs text-white"
              containerVariant="w-[409px] h-[45px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red"
              handleClick={() => {
                dispatch(resetCardsData());
                setScreen(0);
                // navigate("/user-dashboard");
              }}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SuccessScreen;
