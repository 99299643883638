import { useState } from "react";
import { POST_VERIFY_REFEREE_ACCOUNT } from "../../../utils/apis/routes";
import { useApiRequest } from "hooks/useApiRequest";
import axiosStatus from "utils/apis/axios/errorStatus";

export const useVerifyRefereeAccount = () => {
  //Local States
  const [verifyRefereeAccountStatus, setVerifyRefereeAccountStatus] =
    useState<boolean>(false);
  const [verifyRefereeAccountLoading, setVerifyRefereeAccountLoading] =
    useState<boolean>(false);
  const [verifyRefereeAccountError, setVerifyRefereeAccountError] =
    useState<any>({
      title: "",
      actionMessage: "",
    });
  const [verifyRefereeAccountData, setVerifyRefereeAccountData] = useState<any>(
    {}
  );

  //API Request
  const makeRequest = useApiRequest();

  const refereeAccountValidation = async (payload: string) => {
    // console.log(payload)
    setVerifyRefereeAccountLoading(true);
    setVerifyRefereeAccountData("");
    setVerifyRefereeAccountError({
      title: "",
      actionMessage: "",
    });

    try {
      const res = await makeRequest({
        method: "get",
        route: POST_VERIFY_REFEREE_ACCOUNT(payload),
        originType: "onboarding",
      });
      // if (res.status !== 200) {
      //     axiosStatus(res?.data);
      // }
      if (res.status === 400) {
        setVerifyRefereeAccountError({
          title: res?.data?.data?.responseMessage?.title,
          actionMessage: res?.data?.data?.responseMessage?.actionMessage,
        });
      }
      if (res.status === 200) {
        setVerifyRefereeAccountData(res?.data?.data);
        setVerifyRefereeAccountStatus(true);
      }

      // console.log(res)
      //Map the payload

      setVerifyRefereeAccountLoading(false);
    } catch (error) {
      // console.log("The account error is: ", error);
      setVerifyRefereeAccountError(
        "An error occured while verifying account number"
      );
      setVerifyRefereeAccountLoading(false);
    }
  };

  return {
    verifyRefereeAccountStatus,
    verifyRefereeAccountLoading,
    verifyRefereeAccountError,
    verifyRefereeAccountData,
    refereeAccountValidation,
  };
};
