import { useState } from "react";
import { POST_CHANGE_REFEREE } from "../../../utils/apis/routes";
import { ChangeRefereePayload } from "../../../types/global";
import { useApiRequest } from "hooks/useApiRequest";
import { ToastNotify } from "helpers/toastNotify";
import axiosStatus from "utils/apis/axios/errorStatus";

export const useChangeReferee = () => {
  //Local States
  const [changeRefereeStatus, setChangeRefereeStatus] =
    useState<boolean>(false);
  const [changeRefereeLoading, setChangeRefereeLoading] =
    useState<boolean>(false);
  const [changeRefereeError, setChangeRefereeError] = useState<string>("");
  const [changeRefereeData, setChangeRefereeData] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const handleChangeReferee = async (payload: ChangeRefereePayload) => {
    // console.log(payload)
    setChangeRefereeLoading(true);
    try {
      const res = await makeRequest({
        method: "post",
        route: POST_CHANGE_REFEREE,
        body: payload,
        originType: "onboarding",
      });
      // console.log(res)
      //Map the payload
      if (res?.data?.data && res.status === 200) {
        setChangeRefereeData(res?.data?.data);
        setChangeRefereeStatus(true);
      }
      if (res?.status !== 200) {
        if (res?.data?.data?.hasError) {
          ToastNotify({
            type: "error",
            message: res?.data?.data?.responseMessage?.title,
            position: "top-right",
          });
        } else {
          axiosStatus(res?.data);
        }
      }
      setChangeRefereeLoading(false);
    } catch (error) {
      setChangeRefereeError(
        "An error occured while changing referee, please refresh and try again"
      );
      setChangeRefereeLoading(false);
    }
  };

  return {
    changeRefereeStatus,
    changeRefereeLoading,
    changeRefereeError,
    changeRefereeData,
    handleChangeReferee,
  };
};
