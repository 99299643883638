import { ToastNotify } from "helpers/toastNotify";
import { useApiRequest } from "hooks/useApiRequest";
import { useState } from "react";
import { TransactionReceiptByCorrelationIdPayload } from "types/global";
import axiosStatus from "utils/apis/axios/errorStatus";
import { GET_TRANSACTION_RECEIPT_BY_CORRELATION_ID } from "utils/apis/routes";
// import { IncomingWebhook } from "@slack/webhook"

const url = `${process.env.REACT_APP_SLACK_WEBHOOK_URL}`;

// const webhook = new IncomingWebhook(url);

export const useTransactionReceiptByCorrelationId = () => {
  //Local States
  const [
    transactionReceiptByCorrelationId,
    setTransactionReceiptByCorrelationId,
  ] = useState<any>([]);
  const [
    transactionReceiptByCorrelationIdLoading,
    setTransactionReceiptByCorrelationIdLoading,
  ] = useState<boolean>(false);
  const [
    transactionReceiptByCorrelationIdError,
    setTransactionReceiptByCorrelationIdError,
  ] = useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const slackCall = async (url: string, data: any) => {
    const response = await fetch(`${url}`, {
      method: "POST",
      body: JSON.stringify({ ...data }),
    });
    return response;
  };

  const getTransactionReceiptByCorrelationId = async (
    payload: TransactionReceiptByCorrelationIdPayload
  ) => {
    try {
      setTransactionReceiptByCorrelationIdLoading(true);
      const res = await makeRequest({
        method: "get",
        route: GET_TRANSACTION_RECEIPT_BY_CORRELATION_ID(payload),
        originType: "transferservice",
      });

      // console.log("res", res);
      //Map the payload
      if (res?.status === 200) {
        setTransactionReceiptByCorrelationId(res.data.data);
      } else if (res?.status === 406 || res?.data?.httpStatusCode === 406) {
        await slackCall(url, {
          text: `Error occurred when generating receipt via transfer. CorrelationId: ${payload?.correlationId}. Date created: ${new Date()}`,
          channel: "test-afb",
        });
        ToastNotify({
          type: "error",
          message:
            res?.status === 500
              ? "Something went wrong"
              : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      } else {
        setTransactionReceiptByCorrelationIdError(
          res?.data?.data?.responseMessage?.title ||
            "An error occured while fetching transaction Receipt, please refresh and try again"
        );

        // await slackCall(url, {
        //   text: `Error occurred when generating receipt via transfer. CorrelationId: ${payload?.correlationId}`,
        //   channel: "test-afb",
        // });
        ToastNotify({
          type: "error",
          message:
            res?.status === 500
              ? "Oops! Something went wrong"
              : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }

      setTransactionReceiptByCorrelationIdLoading(false);
    } catch (error) {
      axiosStatus(error);
      setTransactionReceiptByCorrelationIdError(
        "An error occured while fetching transaction Receipt, please refresh and try again"
      );
      setTransactionReceiptByCorrelationIdLoading(false);
    }
  };

  return {
    transactionReceiptByCorrelationId,
    transactionReceiptByCorrelationIdLoading,
    transactionReceiptByCorrelationIdError,
    getTransactionReceiptByCorrelationId,
  };
};
