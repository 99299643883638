import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import {
  FormikButton,
  PasswordInput,
  TextInput,
} from "alat-components-library";
import PageLoader from "components/Loader/PageLoader";
import { useCardActivation } from "controllers/Cards/CardActivation";
import { Card } from "flowbite-react";
import { Form, Formik } from "formik";
import { cardActivationValidationSchema } from "pages/Cards/validations/cardActivation.validations";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useCardState } from "react-wrappers/stores/redux/cards/slices";

const ActivationScreen = ({ setScreen }: any) => {
  const navigate = useNavigate();
  const auth = useAuthState();
  const { loading } = useCardState();

  // Local State
  const { handleSubmit } = useCardActivation(setScreen);

  const authType = () => {
    if (auth?.user?.hasTransactionPin) return "pin";
    if (auth?.user?.hasSoftToken && !auth?.user?.hasTransactionPin)
      return "soft";
    return "hard";
  };

  const isLoading =
    loading.includes("GET_ACCOUNT_CARDS") ||
    loading?.includes("POST_SUBMIT_CARD_ACTIVATION");

  return (
    <div className="w-full">
      {isLoading && (
        <PageLoader message={`Processing card details, please wait...`} />
      )}

      <Card className=" bg-white md:w-[58rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div className="flex items-center justify-between">
          <ChevronLeftIcon
            onClick={() => setScreen(0)}
            id="back_btn"
            className="h-6 w-6 text-[#333333] cursor-pointer"
          />
          <div
            className="text-[#4D4D4D] text-xl text-center"
            id="banktransfer_heading"
          >
            Details
          </div>
          <div></div>
        </div>
        <hr />
        <Formik
          initialValues={{
            cardNumber: "",
            cardPin: "",
            confirmCardPin: "",
            softToken: "",
            hardTokenOtp: "",
            hardTokenPin: "",
            pin: "",
          }}
          onSubmit={handleSubmit}
          validateOnChange
          validationSchema={cardActivationValidationSchema(authType())}
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form>
              <div>
                <div className="flex flex-wrap justify-center gap-4 ">
                  <div className="text-alat-grey relative w-full lg:max-w-sm mb-2 pb-5">
                    <p className="text-sm py-4 text-[#606060] text-center">
                      Fill the details below to activate your card
                    </p>
                    {/* <div className="relative w-full lg:max-w-sm ">
                      <SelectDrop
                        id="accounttodebit_dropdown"
                        idList="accounttodebit"
                        variant="h-12 w-full text-sm"
                        name="accountNumberDebit"
                        label="Select account to debit"
                        placeholder="Select account to debit"
                        value={accountToDebit.accountNo}
                        options={getOption('debit', activeAccounts)}
                        onChange={(name: string, value: any) =>
                          setAccountToDebit(JSON.parse(value?.raw))
                        }
                        error={accSelectErrorMessage}
                        hasError={accountToDebit?.accountNo === ''}
                      />
                    </div> */}
                    <div className="relative w-full lg:max-w-sm my-4">
                      <TextInput
                        id="card_number"
                        type="text"
                        label="Enter Card Number"
                        name="cardNumber"
                        placeHolder="Enter Card Number"
                        value={values.cardNumber}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        hasError={errors?.cardNumber && touched?.cardNumber}
                        error={errors?.cardNumber}
                        autoComplete="off"
                        maxLength={19}
                      />
                    </div>
                    <div className="relative w-full lg:max-w-sm my-4">
                      <TextInput
                        id="card_pin"
                        label="Set 4 Digit Card PIN"
                        name="cardPin"
                        type={"password"}
                        placeHolder="Set 4 Digit Card PIN"
                        value={values.cardPin}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        hasError={errors?.cardPin && touched?.cardPin}
                        error={errors?.cardPin}
                        autoComplete="off"
                        maxLength={4}
                      />
                    </div>
                    <div className="relative w-full lg:max-w-sm my-4">
                      <TextInput
                        id="confirm_pin"
                        label="Confirm PIN"
                        name="confirmCardPin"
                        type={"password"}
                        placeHolder="Confirm PIN"
                        value={values.confirmCardPin}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        hasError={
                          errors?.confirmCardPin && touched?.confirmCardPin
                        }
                        error={errors?.confirmCardPin}
                        autoComplete="off"
                        maxLength={4}
                      />
                    </div>
                    {authType() === "pin" && (
                      <PasswordInput
                        name={"pin"}
                        label={"Enter PIN"}
                        placeHolder={""}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.pin}
                        hasError={errors?.pin && touched?.pin}
                        error={errors?.pin}
                        iconStyle={{
                          wrapper: "",
                          icon: "w-[3.5vh] h-[3.5vh]",
                        }}
                        maxLength={4}
                      />
                    )}
                    {authType() === "soft" && (
                      <div className="py-4">
                        <TextInput
                          id="token_input"
                          name={"softToken"}
                          type={"password"}
                          label={"Enter Soft Token"}
                          placeHolder={""}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.softToken}
                          hasError={errors?.softToken && touched?.softToken}
                          error={errors?.softToken}
                          maxLength={6}
                        />
                      </div>
                    )}

                    {authType() === "hard" && (
                      <>
                        <div className="py-4">
                          <PasswordInput
                            name={"hardTokenPin"}
                            label={"Enter Hard Token PIN"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.hardTokenPin}
                            hasError={
                              errors?.hardTokenPin && touched?.hardTokenPin
                            }
                            error={errors?.hardTokenPin}
                            iconStyle={{
                              wrapper: "",
                              icon: "w-[3.5vh] h-[3.5vh]",
                            }}
                            maxLength={4}
                          />
                        </div>
                        <div className="py-4">
                          <TextInput
                            id="token_input"
                            name={"hardTokenOtp"}
                            type={"password"}
                            label={"Enter Hard Token OTP"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.hardTokenOtp}
                            hasError={
                              errors?.hardTokenOtp && touched?.hardTokenOtp
                            }
                            error={errors?.hardTokenOtp}
                            maxLength={6}
                          />
                        </div>
                      </>
                    )}
                    <div className="relative w-full py-6 mb-2  flex items-center justify-center">
                      <FormikButton
                        id="proceed"
                        labelText="Proceed"
                        isDisabled={!isValid && !dirty}
                        containerVariant={`w-full h-[48px] rounded-md flex justify-center item-center`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default ActivationScreen;
