import * as Yup from "yup";
export const airtimeValidationSchema = (authType: any, showInput: boolean) =>
  Yup.object().shape({
    pin: Yup.lazy((value) => {
      switch (authType) {
        case "pinOtp":
          return Yup.string().required("Kindly provide either Pin or Token");
        case "softToken":
          return Yup.string();
        case "hardTokenPin":
          return Yup.string().required("Hard Token is required");
        default:
          return Yup.string();
      }
    }),
    authToken: Yup.lazy((value) => {
      switch (authType) {
        case "pinOtp":
          return Yup.string();
        case "softToken":
          return Yup.string().required("Soft Token is required");
        case "hardTokenPin":
          return Yup.string().required("Hard Token is required");
        default:
          return Yup.string();
      }
    }),
    beneficiary: Yup.lazy((value) => {
      if (showInput) {
        return Yup.string().required("Enter Alias");
      } else {
        return Yup.string();
      }
    }),
  });
export const airtimeDataValidationSchema = () =>
  Yup.object().shape({
    amount: Yup.number()
      .required("Amount required")
      .moreThan(0, "Amount required"),
    phonenumber: Yup.string()
      .trim()
      .matches(/^\d{11}$/, "Phone number must be 11 digits")
      .required("Kindly Enter Phone number"),
    networkProvidersName: Yup.string().required("Kindly Select Network"),
  });

export const airtimeOTPValidationSchema = () =>
  Yup.object().shape({
    otp: Yup.string()
      .required("Kindly provide OTP sent")
      .length(6, "OTP must be 6 digits"),
  });

export const dataValidationSchema = () =>
  Yup.object().shape({
    amount: Yup.number()
      .required("Amount required")
      .moreThan(0, "Amount required"),
    phonenumber: Yup.string()
      .trim()
      .matches(/^\d{11}$/, "Phone number must be 11 digits")
      .required("Phone number is required"),
    networkbundle: Yup.string().required("Kindly Select Bundle"),
    networkProvidersName: Yup.string().required("Kindly Select Network"),
  });
