import React, { useState } from "react";
import styles from "./AccountCardOne.module.scss";
import CardLightRed from "../../../assets/svgs/Cards/card-bg.svg";
import { CardState } from "react-wrappers/stores/redux/interfaces";
import { ReactComponent as VerveIcon } from "../../../assets/svgs/Cards/verve-icon.svg";
import { useAppDispatch } from "hooks/useReduxHook";
import { setSelectedCardForActivation } from "react-wrappers/stores/redux/cards/slices";

export const AccountCardTwo = ({
  containerVariant = "rounded-xl w-[320.784px] h-[188.754px] cursor-pointer",
  cardName,
  cardNumber,
  isActive,
  handleClick,
  expiryDate,
}: {
  containerVariant?: string;
  cardName: string;
  cardNumber: string;
  isActive: boolean;
  handleClick: () => void;
  expiryDate: string;
}) => {
  const [selectedCard, setSelectedCard] = useState<string>("");
  const dispatch = useAppDispatch();
  // Preview the desire background for card base onbg type
  let cardBg = CardLightRed;
  const isDev = window.location.origin === "https://afbweb.azurewebsites.net";
  let formattedDate = isDev
    ? expiryDate?.split(" ")?.[0]?.split("/")
    : expiryDate?.split("");
  // if (bgType === "verve") cardBg = VerveCardType;

  return (
    <>
      <div
        className={`${styles.accountCardOne} ${containerVariant} ${
          isActive ? "border-2 border-lib-alat-black" : "border-0"
        } `}
        onClick={() => {
          handleClick();
        }}
        style={{
          background: `url(${cardBg})`,
        }}
      >
        <div className="w-full flex flex-col gap-2 text-white p-3">
          <div className="flex flex-col w-full gap-1">
            <p className="font-normal text-white text-[10.42px] leading-[21.83px]">
              Card Name
            </p>
            <p className="font-bold text-[17.57px] leading-[21.27px]">
              {cardName}
            </p>
          </div>
          <div className="flex flex-col w-full gap-1">
            <p className="font-normal text-white text-[10.42px] leading-[21.83px]">
              Card Number
            </p>
            <p className="font-bold text-[17.57px] leading-[21.27px]">
              {cardNumber}
            </p>
          </div>
          <div className="w-full flex items-end justify-between">
            <div className="flex flex-col w-full gap-1">
              <p className="font-normal text-white text-[10.42px] leading-[21.83px]">
                Valid Thru
              </p>
              <p className="font-bold text-[17.57px] leading-[21.27px]">
                {isDev
                  ? `${formattedDate?.[0]} / ${formattedDate?.[2]?.slice(2)}`
                  : `${formattedDate?.[2]} ${formattedDate?.[3]} / ${formattedDate?.[0]} ${formattedDate?.[1]}`}
              </p>
            </div>
            <VerveIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountCardTwo;
