import { ToastNotify } from "helpers/toastNotify";
import { useApiRequest } from "hooks/useApiRequest";
import { useState } from "react";
import { TransactionReceiptPayload } from "types/global";
import { GET_TRANSACTION_RECEIPT } from "utils/apis/routes";
import axiosStatus from "utils/apis/axios/errorStatus";

export const useTransactionReceipt = () => {
  //Local States
  const [transactionReceipt, setTransactionReceipt] = useState<any>([]);
  const [transactionReceiptLoading, setTransactionReceiptLoading] =
    useState<boolean>(false);
  const [transactionReceiptError, setTransactionReceiptError] =
    useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const getTransactionReceipt = async (payload: TransactionReceiptPayload) => {
    setTransactionReceiptError("");
    try {
      setTransactionReceiptLoading(true);
      const res = await makeRequest({
        method: "get",
        route: GET_TRANSACTION_RECEIPT(payload),
        originType: "transferservice",
      });

      // console.log("res", res);
      //Map the payload
      if (res?.status === 200) {
        setTransactionReceipt(res.data.data);
      } else {
        setTransactionReceiptError(
          res?.data?.data?.responseMessage?.title ||
            "An error occured while fetching transaction Receipt, please refresh and try again"
        );
        ToastNotify({
          type: "error",
          message:
            res?.status === 500
              ? "Oops! Something went wrong"
              : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      setTransactionReceiptLoading(false);
    } catch (error) {
      axiosStatus(error);
      setTransactionReceiptError(
        "An error occured while fetching transaction Receipt, please refresh and try again"
      );
      setTransactionReceiptLoading(false);
    }
  };

  return {
    transactionReceipt,
    transactionReceiptLoading,
    transactionReceiptError,
    getTransactionReceipt,
  };
};
