import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import { GET_ACCOUNT_NAME_ENQUIRY } from "../../../utils/apis/routes";
import axiosStatus from "utils/apis/axios/errorStatus";
import { ToastNotify } from "../../../helpers/toastNotify";
import {
  usePaymentState,
  setBulkTranferIntra,
  setLoadingState,
  setBulkTransferPayloadReferenceIntra,
  setTriggerMultipleAcctEnquiryAgain,
} from "react-wrappers/stores/redux/payments/slices";
import { useAppDispatch } from "hooks/useReduxHook";
import { ExcelSheetData } from "controllers/BulkTransfer/useIntraTransferController";

export const useAccountValidation = () => {
  //Local States
  const [acctStatus, setAcctStatus] = useState<boolean>(false);
  //   const [acctStatusLoading, setAcctStatusLoading] = useState<boolean>(false);
  const [acctStatusError, setAcctStatusError] = useState<string>("");

  //API Request
  const makeRequest = useApiRequest();
  const paymentState = usePaymentState();
  const dispatch = useAppDispatch();

  const handleAccountValidation = async (
    payload: {
      oldAccountNumber: string;
      newAccountNumber: string;
      bankCode: string;
      amount: number;
    },
    type: string
    // excelData?: ExcelSheetData
  ) => {
    // console.log("payload", payload);
    try {
      dispatch(setLoadingState(["validating-account"]));
      const res = await makeRequest({
        method: "get",
        route: GET_ACCOUNT_NAME_ENQUIRY(
          payload.newAccountNumber,
          payload.bankCode
        ),
        originType: "transferservice",
      });

      if (res?.status === 200) {
        setAcctStatus(true);
        if (type === "bulk") {
          const dynamicExcelList = () => {
            let list: ExcelSheetData = [];
            paymentState?.bulkTransferPayloadIntra?.transferDestinations?.map(
              (item: {
                rowId: number;
                beneficiaryAccountNumber: string;
                beneficiaryName: string;
                beneficiaryBankName: string;
                beneficiaryBankCode: string;
                amount: number;
                beneficiaryCurrency: string;
                narration: string;
                transactionFee: number;
                refCode: string;
                status?: string;
              }) => {
                return list?.push({
                  rowId: item?.rowId,
                  beneficiaryAccountNumber:
                    item?.beneficiaryAccountNumber === payload?.oldAccountNumber
                      ? payload?.newAccountNumber?.trim()
                      : item?.beneficiaryAccountNumber?.trim(),
                  beneficiaryName:
                    item?.beneficiaryAccountNumber === payload?.oldAccountNumber
                      ? res?.data?.data?.accountName?.trim()
                      : item?.beneficiaryName?.trim(),
                  beneficiaryBankName: item?.beneficiaryBankName?.trim(),
                  beneficiaryBankCode: item?.beneficiaryBankCode?.trim(),
                  amount:
                    item?.beneficiaryAccountNumber ===
                      payload?.oldAccountNumber && payload?.amount > 0
                      ? payload?.amount
                      : item?.amount,
                  beneficiaryCurrency:
                    item?.beneficiaryAccountNumber === payload?.oldAccountNumber
                      ? res?.data?.data?.currency?.trim()
                      : item?.beneficiaryCurrency,
                  narration: item?.narration,
                  transactionFee: item?.transactionFee ?? 0,
                  refCode: item?.refCode,
                  status:
                    item?.beneficiaryAccountNumber === payload?.oldAccountNumber
                      ? "Successful"
                      : item?.status,
                });
              }
            );
            return list;
          };
          // console.log("list", dynamicExcelList())
          dispatch(
            setBulkTranferIntra({
              ...paymentState.bulkTransferPayloadIntra,
              transferDestinations: dynamicExcelList(),
            })
          );
          // Update the reference Bulk Transfer Payload
          dispatch(
            setBulkTransferPayloadReferenceIntra({
              ...paymentState.bulkTransferPayloadIntra,
              transferDestinations: dynamicExcelList(),
            })
          );
        }
      } else if (res?.status === 500) {
        axiosStatus(res);
      } else {
        ToastNotify({
          type: "error",
          message: Object.keys(res?.data?.data?.responseMessage)?.length
            ? `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`
            : "Something went wrong",
        });
      }

      dispatch(setLoadingState([]));
    } catch (error: any) {
      setAcctStatusError(
        `${error?.response?.data?.responseMessage?.title}. ${error?.response?.data?.responseMessage?.actionMessage}`
      );

      dispatch(setLoadingState([]));
    }
  };

  return {
    acctStatus,
    // acctStatusLoading,
    acctStatusError,
    handleAccountValidation,
  };
};
