import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountState, SelectedAccountsPayload } from "../interfaces";
import { RootState } from "../store";
import { useAppSelector } from "../../../../hooks/useReduxHook";
import {
  GET_ALL_ACCOUNTS_ACTION,
  POST_ACCOUNT_SETTINGS_ACTION,
  PUT_UPDATE_ACCOUNT_SETTINGS_ACTION,
} from "./actions";
import { removeLoadingState } from "../../../../helpers/assitFunctions";
import {
  triggerGetAccountSettings,
  triggerGetAllAccounts,
  triggerUpdateAccountSettings,
} from "./assit-functions";

export const SelectedAccountsList = [
  {
    accountId: "",
    currency: "",
    balance: 0,
    schemeType: "",
    accountDescription: "",
    schemeCode: "",
    accountStatus: "",
    accountName: "",
    accountNo: "",
    numberOfUsers: 0,
  },
];
const dataObject = {
  loading: [],
  successMessage: "",
  errorMessage: "",
  allAccounts: [],
  accountSettings: {
    selectedAccounts: [],
    selectedDomAccounts: [],
    accounts: null,
    successMessage: "",
    errorMessage: "",
    successStatus: false,
  },
  updateAccountSettings: {
    successMessage: "",
    successStatus: false,
    errorMessage: "",
    numberOfVerifiers: 0,
    numberOfApprovals: 0,
    transactionLimit: 0,
    isTransactionAllowed: false,
    accounts: null,
  },
  successStatus: false,
  selectedTab: 0,
} as AccountState;

export const initialState: AccountState = { ...dataObject };

export const accountSlice = createSlice({
  name: "account",
  initialState,

  reducers: {
    resetAccountsData: (): AccountState => initialState,

    setSelectedTab: (state: AccountState, action: PayloadAction<any>) => {
      state.selectedTab = action.payload;
    },
    saveAccountsSelected: (state: AccountState, action: PayloadAction<any>) => {
      state.accountSettings.selectedAccounts = [
        ...state?.accountSettings?.selectedAccounts,
        ...action.payload,
      ];
    },
    saveDomAccountsSelected: (
      state: AccountState,
      action: PayloadAction<any>
    ) => {
      state.accountSettings.selectedDomAccounts = [
        ...state?.accountSettings?.selectedDomAccounts,
        ...action.payload,
      ];
    },
    removeAccount: (state: AccountState, action: PayloadAction<any>) => {
      state.accountSettings.selectedAccounts =
        state.accountSettings.selectedAccounts.filter(
          (account: any) => account?.accountId !== action.payload
        );
    },
    removeDomAccount: (state: AccountState, action: PayloadAction<any>) => {
      state.accountSettings.selectedDomAccounts =
        state.accountSettings.selectedDomAccounts.filter(
          (account: any) => account?.accountId !== action.payload
        );
    },
  },
  extraReducers: (builder) => {
    builder
      //GET_ALL_ACCOUNTS_ACTION
      .addCase(GET_ALL_ACCOUNTS_ACTION.pending, (state) => {
        state.loading = ["GET_ALL_ACCOUNTS"];
      })
      .addCase(GET_ALL_ACCOUNTS_ACTION.fulfilled, (state, action): void =>
        triggerGetAllAccounts(state, action)
      )
      .addCase(GET_ALL_ACCOUNTS_ACTION.rejected, (state, action): void => {
        state.loading = removeLoadingState(state.loading, "GET_ALL_ACCOUNTS");
      })
      //POST_ACCOUNT_SETTINGS
      .addCase(POST_ACCOUNT_SETTINGS_ACTION.pending, (state) => {
        state.loading = ["POST_ACCOUNT_SETTINGS"];
      })
      .addCase(POST_ACCOUNT_SETTINGS_ACTION.fulfilled, (state, action): void =>
        triggerGetAccountSettings(state, action)
      )
      .addCase(POST_ACCOUNT_SETTINGS_ACTION.rejected, (state, action): void => {
        state.loading = removeLoadingState(
          state.loading,
          "POST_ACCOUNT_SETTINGS"
        );
      })
      //PUT_UPDATE_ACCOUNT_SETTINGS
      .addCase(PUT_UPDATE_ACCOUNT_SETTINGS_ACTION.pending, (state) => {
        state.loading = ["PUT_UPDATE_ACCOUNT_SETTINGS"];
      })
      .addCase(
        PUT_UPDATE_ACCOUNT_SETTINGS_ACTION.fulfilled,
        (state, action): void => triggerUpdateAccountSettings(state, action)
      )
      .addCase(
        PUT_UPDATE_ACCOUNT_SETTINGS_ACTION.rejected,
        (state, action): void => {
          state.loading = removeLoadingState(
            state.loading,
            "PUT_UPDATE_ACCOUNT_SETTINGS"
          );
        }
      );
  },
});

// Selectors
const selectAccount = (state: RootState) => state.accounts;

// Reducers and actions
export const {
  resetAccountsData,
  setSelectedTab,
  saveAccountsSelected,
  saveDomAccountsSelected,
  removeAccount,
  removeDomAccount,
} = accountSlice.actions;

//App Redux State
export const useAccountsState = () => useAppSelector(selectAccount);

export default accountSlice.reducer;
