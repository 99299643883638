export enum AccountTypes {
  MBASAVVY = "11011",
  MBALITE = "12011",
  MBACLASSIC = "13011",
  MBAPREMIUM = "14011",
  MBAPLATINUM = "15011",
  MYBUSINESSACCOUNT = "10011",
  MYBUSINESSACCOUNTPLUS = "10042",
  WEMATREASURENONINDIVIDUAL = "62002",
  WEMATREASUREBUSINESS = "62003",
}
export interface IAccountTypesData {
  description: string[];
  name: string;
  key: string;
  value: AccountTypes;
  accntType: accntType;
}
export type accntType = "oldType" | "newType";
export const accountTypesData: IAccountTypesData[] = [
  {
    name: "My Business Account",
    key: "MYBUSINESSACCOUNT",
    value: AccountTypes.MYBUSINESSACCOUNT,
    accntType: "oldType",
    description: [
      "N10,000 opening balance",
      "N10,000 minimum operating balance",
      "Zero Account Maintenance Fee (AMF) charge",
      "N200million maximum monthly debit turnover",
      "AMF will apply on the total monthly transaction at N1/mille if the operating balance goes below N100,000 at anytime within a month.",
      "For monthly debit turnover above N200million , AMF of N1/mille will apply on the excess.",
      "Access secured and unsecured Business loans",
    ],
  },
  {
    name: "My Business Account Plus",
    key: "MYBUSINESSACCOUNTPLUS",
    value: AccountTypes.MYBUSINESSACCOUNTPLUS,
    accntType: "oldType",
    description: [
      "N100,000 opening balance.",
      "N100,000 minimum operating balance",
      "Zero Account Maintenance Fee charge.",
      "No monthly maximum debit turnover limit.",
      "No interest.",
      "AMF will apply on the total monthly transaction at N1/mille if the operating balance goes below N100,000 at anytime within a month.",
      "Access secured and unsecured Business loans",
    ],
  },
  {
    name: "WEMA Treasure Account (Non-Individual)",
    key: "WEMATREASURENONINDIVIDUAL",
    value: AccountTypes.WEMATREASURENONINDIVIDUAL,
    accntType: "oldType",
    description: [
      "N10,000 opening balance",
      "N10,000 minimum operating balance",
      "Interest rate 0.575%",
      "Allows lodgement of cheque and dividend warrants into accounts",
      "Allows withdrawals below minimum operating balance but customer will lose interest for the month concerned",
      "Personalised cheque book for over -the-counter withdrawals for self and 3rd party across WEMA Bank",
    ],
  },
  {
    name: "WEMA Treasure Account (Business)",
    key: "WEMATREASUREBUSINESS",
    value: AccountTypes.WEMATREASUREBUSINESS,
    accntType: "oldType",
    description: [
      "N100,000 opening balance",
      "N100,000 minimum operating balance",
      "NGN 35 million monthly maximum debit turnover limit",
      "Allows lodgement of cheque and dividend warrants into accounts",
      "Personalised cheque book for over -the-counter withdrawals for self and 3rd party across WEMA Bank",
    ],
  },
  {
    name: "MBA Savvy Sole Proprietor",
    key: "MBASAVVY",
    accntType: "newType",
    value: AccountTypes.MBASAVVY,
    description: [
      "N2,000 opening and operating balance.",
      "N1million maximum monthly debit turnover.",
      "Zero Account Maintenance Fee (AMF) charge.",
      "For monthly debit turnover above N1million, 3% penal charge will apply on the excess.",
    ],
  },
  {
    name: "MBA Lite Sole Proprietor",
    key: "MBALITE",
    accntType: "newType",
    value: AccountTypes.MBALITE,
    description: [
      "N10,000 opening and operating balance.",
      "N4million maximum monthly debit turnover.",
      "Zero Account Maintenance Fee (AMF) charge.",
      "For monthly debit turnover above N4million, 3% penal charge will apply on the excess.",
    ],
  },
  {
    name: "MBA Classic",
    key: "MBACLASSIC",
    accntType: "newType",
    value: AccountTypes.MBACLASSIC,
    description: [
      "N15,000 opening and operating balance.",
      "N15million maximum monthly debit turnover.",
      "N3,500 monthly Fixed charge.",
      "For monthly debit turnover above N15million, 1.15% penal charge will apply on the excess.",
    ],
  },
  {
    name: "MBA Premium",
    key: "MBAPREMIUM",
    accntType: "newType",
    value: AccountTypes.MBAPREMIUM,
    description: [
      "N45,000 opening and operating balance.",
      "N50million maximum monthly debit turnover.",
      "N10,000 monthly Fixed charge.",
      "For monthly debit turnover above N15million, 1.15% penal charge will apply on the excess.",
    ],
  },
  {
    name: "MBA Platinum",
    key: "MBAPLATINUM",
    accntType: "newType",
    value: AccountTypes.MBAPLATINUM,
    description: [
      "N90,000 opening and operating balance.",
      "N100million maximum monthly debit turnover.",
      "N30,000 monthly Fixed charge.",
      "For monthly debit turnover above N15million, 1.15% penal charge will apply on the excess.",
    ],
  },
];

export const handleInputDisplay = (
  name: string,
  licenseType?: null | number
) => {
  if (name === "issueDate") {
    return licenseType !== 5 ? true : false;
  }
  return licenseType !== 4 && licenseType !== 5 ? true : false;
};

export const handleLicenseNumber = (license?: null | number) => {
  const checks = {
    isNumeric: false,
    length: undefined,
    allowSpecialChar: true,
  };
  switch (license) {
    case 3: // PHARMACEUTICAL LICENCE FROM PSN
      return { isNumeric: true, length: 7, allowSpecialChar: false };
    case 4: // SCUML
      return { ...checks, length: 12, allowSpecialChar: false };
    case 5: // CBN OPERATING
      return { ...checks, length: 15 };
    case 1: // SEC
    case 2: // EXPORT
      return { ...checks, allowSpecialChar: false };
    case 9: // NAFDAC
      return { ...checks, length: 8 };
    default:
      return checks;
  }
};
