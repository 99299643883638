import { useState } from "react";
import {
  POST_RESEND_REFEREE_EMAIL,
  POST_SAVE_WEMA_REFEREE,
} from "../../../utils/apis/routes";
import { ResendRefereeEmailPayload } from "../../../types/global";
import { useApiRequest } from "hooks/useApiRequest";

export const useResendRefereeEmail = () => {
  //Local States
  const [resendRefereeEmailStatus, setResendRefereeEmailStatus] =
    useState<boolean>(false);
  const [resendRefereeEmailLoading, setResendRefereeEmailLoading] =
    useState<boolean>(false);
  const [resendRefereeEmailError, setResendRefereeEmailError] =
    useState<string>("");
  const [resendRefereeEmailData, setResendRefereeEmailData] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const resendRefereeEmail = async (payload: ResendRefereeEmailPayload) => {
    // console.log(payload)
    setResendRefereeEmailLoading(true);
    try {
      const res = await makeRequest({
        method: "post",
        route: POST_RESEND_REFEREE_EMAIL,
        body: payload,
        originType: "onboarding",
      });
      // console.log(res)
      //Map the payload
      if (res?.data?.data && res.status === 200) {
        setResendRefereeEmailData(res?.data?.data);
        setResendRefereeEmailStatus(true);
      }
      setResendRefereeEmailLoading(false);
    } catch (error) {
      setResendRefereeEmailError(
        "An error occured while fetching branch sol ids, please referesh and try again"
      );
      setResendRefereeEmailLoading(false);
    }
  };

  return {
    resendRefereeEmailStatus,
    resendRefereeEmailLoading,
    resendRefereeEmailError,
    resendRefereeEmailData,
    resendRefereeEmail,
  };
};
