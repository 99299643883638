import { TabsRef, Tabs, Flowbite } from "flowbite-react";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmOtp from "./BuyAirtime/ConfirmOtp";
import ConfirmDataOtp from "./BuyAirtime/ConfirmDataOtp";

const ConfirmOtpTab = () => {
  const navigate = useNavigate();
  const activeTab = window.location.pathname;
  const tabsRef = useRef<TabsRef>(null);
  // const theme = {
  //   theme: {
  //     tab: {
  //       tablist: {
  //         tabitem: {
  //           styles: {
  //             underline: {
  //               active: {
  //                 on: "text-primary rounded-t-lg border-b-2 border-primary focus:outline-none",
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //   },
  // };
  const theme = {
    tabs: {
      tablist: {
        base: "flex text-center gap-2 z-0",
        tabitem: {
          base: "flex text-center",
          variant: {
            underline: {
              base: "rounded-t-lg",
              active: {
                on: "active py-2 rounded-t-lg border-b-2 border-lib-alat-red text-lib-alat-red font-medium",
                off: "py-2 border-b-2 border-transparent text-lib-alat-black font-medium",
              },
            },
          },
        },
      },
    },
  };

  return (
    <div className="">
      <div className="w-full">
        <Flowbite theme={{ theme }}>
          <Tabs
            aria-label="Default tabs"
            // eslint-disable-next-line react/style-prop-object
            variant="underline"
            ref={tabsRef}
            onActiveTabChange={(tab: React.SetStateAction<number>) => {
              let url = "/Airtime/BuyAirtime";
              if (tab === 1) url = "/Data/BuyNewData";

              navigate(url);
            }}
            className=" !border-[#E4DFDF] w-full"
          >
            <Tabs.Item
              active={activeTab.includes("/Airtime")}
              title="Buy Airtime"
              className="mr-80"
              id="buyairtime_tab"
            >
              <ConfirmOtp />
            </Tabs.Item>
            <Tabs.Item
              active={activeTab.includes("/Data")}
              title="Buy Data"
              id="buydata_tab"
            >
              <ConfirmDataOtp />
            </Tabs.Item>
          </Tabs>
        </Flowbite>
      </div>
      {/* <div className="w-full"></div> */}
    </div>
  );
};

export default ConfirmOtpTab;
