import { useAppDispatch } from "hooks/useReduxHook";
import { useState } from "react";
import FileUpload from "./general/FileUploadIntra";
import TabScreens from "./general/TabScreens";

const IntraTransfer = () => {
  // Redux State
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState("upload-intra-tab"); // upload-tab, excel-list-tab, select-account-tab

  return (
    <>
      {activeTab === "upload-intra-tab" && (
        <FileUpload setActiveTab={setActiveTab} screenName="intra" />
      )}
      <TabScreens
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        screenName="intra"
      />
    </>
  );
};

export default IntraTransfer;
