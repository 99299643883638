import { ToastNotify } from "helpers/toastNotify";
import { useAppDispatch } from "hooks/useReduxHook";
import { useNavigate } from "react-router-dom";
import { POST_VALIDATE_BILL_REFERENCE_ACTION } from "react-wrappers/stores/redux/payments/actions";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { useCollectionsState } from "react-wrappers/stores/redux/collections/slices";

export const useCollectionsController = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeSelectedSubBill } = useCollectionsState();

  const pacakgeList = activeSelectedSubBill?.packages?.map((arr: any) => {
    // console.log(arr);
    return {
      ...arr,
      label: arr.name,
    };
  });

  const handleSubmit = (payload: any) => {
    const packageData = activeSelectedSubBill.packages.find(
      (item: any) => item.name === payload.package
    );

    //Validate transaction Id if applied
    if (payload.transactionId !== "") {
      const data = {
        identifier: payload.transactionId,
        packageId: packageData.id,
      };
      //Call API to Validate the refrence or transaction ID
      dispatch(POST_VALIDATE_BILL_REFERENCE_ACTION(data))
        .unwrap()
        .then((res: any) => {
          if (res.status === 400) {
            return ToastNotify({
              type: "error",
              message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
              position: "top-right",
            });
          }
          if (res.status === 200) {
            navigate("/bills/payment/pay-bill-confirmation", {
              state: {
                payload: {
                  ...payload,
                  referenceData: res.data.data,
                  package: packageData,
                },
              },
            });
          }
        });
    }

    if (payload.transactionId === "") {
      navigate("/bills/payment/pay-bill-confirmation", {
        state: {
          payload: {
            ...payload,
            referenceData: null,
            package: packageData,
          },
        },
      });
    }
  };

  const handleFilterCollections = ({
    collections,
    searchWord,
  }: {
    collections: any[];
    searchWord: string;
  }) => {
    return collections?.filter((item: any) =>
      item?.categoryName?.toLowerCase().includes(searchWord?.toLowerCase())
    );
  };
  const handleFilterMerchants = ({
    merchants,
    searchWord,
  }: {
    merchants: any;
    searchWord: string;
  }) => {
    return merchants?.filter((item: any) =>
      item?.collectionName?.toLowerCase().includes(searchWord?.toLowerCase())
    );
  };

  const handleFilterMerchantItems = ({
    merchantItems,
    searchWord,
  }: {
    merchantItems: any;
    searchWord: string;
  }) => {
    return merchantItems?.filter((item: any) =>
      item?.collectionName?.toLowerCase().includes(searchWord?.toLowerCase())
    );
  };

  return {
    activeSelectedSubBill,
    pacakgeList,
    handleSubmit,
    handleFilterCollections,
    handleFilterMerchants,
    handleFilterMerchantItems,
  };
};
