import { PrimaryButton, TextInput } from "alat-components-library";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { useEffect, useState } from "react";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import {
  POST_FETCH_COLLECTIONS_DETAIL_ACTION,
  PUT_APROVE_OR_REJECT_TRANSACTION_ACTION,
} from "react-wrappers/stores/redux/payments/actions";
import { formatNumberToCurrency } from "utils/helperFunctions";
import { useAppDispatch } from "hooks/useReduxHook";
import { ReactComponent as BackArrow } from "../../alat-components-library/assets/images/icons/back-arrow-two.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import { toast } from "react-toastify";
import { ReactComponent as ModalClose } from "../../alat-components-library/assets/images/icons/modal-close.svg";
import { ToastNotify } from "helpers/toastNotify";
import PageLoader from "components/Loader/PageLoader";

const CollectionDetail = () => {
  const [formPayload, setFormPayload] = useState({
    transactionPin: "",
    token: "",
    remark: "",
  });
  const [rejectionModal, setRejectionModal] = useState(false);
  const [remarkMessage, setRemarkMessage] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { pendingCollectionsDetail } = usePaymentState();
  const [openBeneficiaryModal, setBeneficiaryModal] = useState(false);
  const [load, toggleLoading] = useState(false);

  const {
    categoryName,
    collectionItemName,
    collectionName,
    initiatedBy,
    initiatedByFullName,
    merchantName,
    sourceAccountBankCode,
    sourceAccountBankName,
    sourceAccountCurrency,
    sourceAccountName,
    sourceAccountNumber,
    totalAmount,
    totalCharge,
    type,
  } = pendingCollectionsDetail;

  const correlationId = searchParams.get("correlationId");

  useEffect(() => {
    const payload = {
      correlationId,
      mappedAccounts: user.accounts.map((x: any) => x.accountNo),
    };

    dispatch(POST_FETCH_COLLECTIONS_DETAIL_ACTION(payload));
    // eslint-disable-next-line
  }, [correlationId]);

  const handleApproveOrRejct = (approvalAction: number) => {
    if (
      user?.hasSoftToken === false &&
      (formPayload.transactionPin === "" || formPayload.token === "")
    ) {
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Please enter a pin and hard token to continue.",
      });
    }
    if (user?.hasSoftToken === true && formPayload.token === "") {
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Please enter a token to continue.",
      });
    }

    if (
      user?.hasSoftToken === false &&
      formPayload.transactionPin.length !== 4
    ) {
      //error
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Pin must be a 4 digit number.",
      });
    }

    if (formPayload.token.length !== 6) {
      //error
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Token must be a 6 digit number.",
      });
    }

    if (approvalAction === 2 && formPayload.remark === "") {
      return setRemarkMessage("Please enter a reason for rejection");
    }

    const body = {
      mappedAccounts: user.accounts.map((x: any) => x.accountNo),
      correlationId,
      isSoftToken: user?.hasSoftToken,
      approvalAction: approvalAction,
      ...formPayload,
    };

    toggleLoading(true);
    dispatch(PUT_APROVE_OR_REJECT_TRANSACTION_ACTION(body))
      .unwrap()
      .then((res) => {
        // console.log("res", res);
        if (approvalAction === 2) {
          setFormPayload({ ...formPayload, ...{ remark: "" } });
          setRemarkMessage("");
          setRejectionModal(false);
        }
        toggleLoading(false);
        if (res.status === 200) {
          setTimeout(() => {
            navigate(
              approvalAction === 1
                ? "/payments/verify/transaction/approved"
                : "/payments/verify/approver/reject"
            );
          }, 500);
        } else {
          ToastNotify({
            type: "error",
            message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
          });
        }
      })
      .catch(() => {
        setRejectionModal(false);
        navigate("/payments/verify/transaction/reject");
      });
  };
  return (
    <DashboardWrapper>
      {load && <PageLoader message="Please wait" />}
      <div className="lg:px-20 space-y-4 mt-5">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-semibold" id="request-type_text">
            Bills Request
          </h2>
        </div>
        <div className="flex items-center justify-center">
          <ReusableCard
            cardTitle="Overview"
            variant="w-[990px]"
            handleBackNavigation={() =>
              navigate("/payments/pending-transactions")
            }
            showGoBackIcon
          >
            <div className="w-full flex flex-col items-center justify-center px-10 py-3 space-y-4">
              <div className="w-full flex items-center justify-between p-3">
                <div className="font-medium text-lg">
                  <p
                    className="leading-[22px] tracking-tighter text-lib-alat-black"
                    id="requestType_text"
                  >
                    Collections
                  </p>
                </div>
                <div className=" bg-[#FFA41C]/[.15] p-2">
                  {user?.role?.toLowerCase() === "verifier" ? (
                    <p className="text-sm text-[#FF9900] leading-[9px] font-semibold  tracking-tighter">
                      Pending verification
                    </p>
                  ) : (
                    <p className="text-sm text-[#FF9900] leading-[9px] font-semibold  tracking-tighter">
                      Pending Approval
                    </p>
                  )}
                </div>
              </div>
              <div className="w-full flex bg-[#F7F7F7] p-3">
                <div className="flex items-center gap-2">
                  <div>
                    <p
                      className="text-sm leading-[13px] tracking-tighter font-medium text-lib-alat-black"
                      id="initiator-role_text"
                    >
                      Initiator:
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p
                      className="text-lib-alat-black text-[17px] leading-[30px] tracking-tighter font-medium"
                      id="initiator-fullname_text"
                    >
                      {initiatedByFullName}
                    </p>
                    <p
                      className="text-[#606060] text-sm leading-[13px] tracking-tighter"
                      id="initiated-by_text"
                    >
                      {initiatedBy}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex-col space-y-4">
                <p
                  className="tracking-tighter font-medium text-base leading-[19px] text-lib-alat-black"
                  id="requestType_text"
                >
                  Details
                </p>

                <div className="w-full flex-col">
                  <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                    <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                      TRANSACTION TYPE
                    </p>
                    <p
                      className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                      id="name_text"
                    >
                      {type}
                    </p>
                  </div>
                  <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                    <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                      COLLECTION NAME
                    </p>
                    <p
                      className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                      id="profile-identifier_text"
                    >
                      {collectionName}
                    </p>
                  </div>
                  <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                    <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                      COLLECTION ITEM
                    </p>
                    <p
                      className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                      id="phone_text"
                    >
                      {collectionItemName}
                    </p>
                  </div>
                  <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                    <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                      MERCHANT NAME
                    </p>
                    <p
                      className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                      id="phone_text"
                    >
                      {merchantName}
                    </p>
                  </div>
                  <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                    <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                      PRINCIPAL AMOUNT
                    </p>
                    <p
                      className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                      id="email_text"
                    >
                      {totalAmount}
                    </p>
                  </div>
                  <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                    <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                      TRANSACTION CHARGES
                    </p>
                    <p
                      className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                      id="email_text"
                    >
                      {totalCharge}
                    </p>
                  </div>
                  <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                    <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                      TOTAL AMOUNT
                    </p>
                    <p
                      className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                      id="email_text"
                    >
                      {totalCharge + totalAmount}
                    </p>
                  </div>
                  <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                    <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                      ACCOUNT TO BE DEBITED
                    </p>
                    <p
                      className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                      id="email_text"
                    >
                      {sourceAccountNumber}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-white w-full mt-14">
                <div className="md:w-72 w-full  mt-10">
                  {user?.hasSoftToken && (
                    <TextInput
                      id="softToken_input"
                      label="Enter soft token"
                      name="softToken"
                      type="password"
                      isPin
                      maxLength={6}
                      handleChange={(event) => {
                        setFormPayload({
                          ...formPayload,
                          token: event.target.value,
                        });
                      }}
                      variant="border text-black w-full h-10 text-lg px-5"
                      value={formPayload.token}
                    />
                  )}
                  {!user?.hasSoftToken && (
                    <>
                      <TextInput
                        id="pin_input"
                        label="Enter PIN"
                        placeHolder="Enter pin"
                        type="password"
                        isPin
                        name="pin"
                        maxLength={4}
                        handleChange={(event) => {
                          setFormPayload({
                            ...formPayload,
                            transactionPin: event.target.value,
                          });
                        }}
                        variant="border text-black w-full h-10 text-lg px-5"
                        value={formPayload.transactionPin}
                      />
                      <p className="text-right text-[#A90836] text-sm pt-4 -mb-5 cursor-pointer">
                        Don’t have token?
                      </p>
                      <TextInput
                        id="hardToken_input"
                        label="Enter hard token"
                        placeHolder="Enter hard token"
                        type="password"
                        isPin
                        name="hardToken"
                        maxLength={6}
                        handleChange={(event) => {
                          setFormPayload({
                            ...formPayload,
                            token: event.target.value,
                          });
                        }}
                        variant="border text-black w-full h-10 text-lg px-5"
                        value={formPayload.token}
                      />
                    </>
                  )}
                </div>
                <div className="md:w-72 w-full flex gap-5 mt-10">
                  <div
                    id="approve_btn"
                    className="w-full h-10 text-white bg-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer"
                    onClick={() => handleApproveOrRejct(1)}
                  >
                    Approve
                  </div>
                  <div
                    id="reject_btn"
                    className="w-full h-10 text-lib-alat-dark-red bg-white border border-[#A90836] rounded-md flex justify-center items-center cursor-pointer"
                    onClick={() => {
                      if (user?.hasSoftToken && formPayload.token !== "") {
                        return setRejectionModal(true);
                      }
                      if (
                        !user?.hasSoftToken &&
                        formPayload.token !== "" &&
                        formPayload.transactionPin !== ""
                      ) {
                        return setRejectionModal(true);
                      }

                      if (user?.hasSoftToken && formPayload.token === "") {
                        ToastNotify({
                          type: "error",
                          message: "Please enter token to continue.",
                        });
                      }

                      if (
                        !user?.hasSoftToken &&
                        (formPayload.token === "" ||
                          formPayload.transactionPin === "")
                      ) {
                        ToastNotify({
                          type: "error",
                          message:
                            "Please enter pin and hard token to continue.",
                        });
                      }
                    }}
                  >
                    Reject
                  </div>
                </div>
              </div>
            </div>
          </ReusableCard>
          {/* Rejection reason Modal */}
          {rejectionModal && (
            <div
              className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-10 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm"
              style={{ margin: 0 }}
              id="rejection_modal"
            >
              <center className="z-10">
                <div className="flex flex-col py-5 px-10 md:w-[700px] w-full h-[560px] relative mb-4 bg-white rounded-lg relative">
                  <p
                    id="closeRejectionModal_btn"
                    className="text-right text-xs absolute right-5 top-5 cursor-pointer"
                    onClick={() => {
                      setFormPayload({ ...formPayload, ...{ remark: "" } });
                      setRejectionModal(false);
                    }}
                  >
                    <ModalClose />
                  </p>
                  <div className="text-center text-lg font-bold py-5">
                    Please provide a reason for rejection
                  </div>
                  <textarea
                    name="rejectionReason"
                    id="rejectionReason"
                    cols={4}
                    rows={7}
                    maxLength={100}
                    placeholder="Type your reason here.."
                    className="bg-gray-50 p-4 border outline-none rounded-md"
                    value={formPayload.remark}
                    onChange={(event) =>
                      setFormPayload({
                        ...formPayload,
                        ...{ remark: event.target.value },
                      })
                    }
                  />
                  <div className="mt-2 flex items-center justify-between">
                    <p
                      className="text-red-500 text-sm text-left"
                      id="remarkMessage"
                    >
                      {remarkMessage}
                    </p>
                    <label
                      className="text-right"
                      id="remarkLength"
                    >{`${formPayload?.remark?.length} / 100`}</label>
                  </div>
                  <div className="w-full flex gap-5 mt-10 mx-auto">
                    <div
                      id="returnToDashboard_btn"
                      className="w-full py-3 h-10 text-lib-alat-dark-red bg-white text-bold border border-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer text-xs"
                      onClick={() => navigate("/user-dashboard")}
                    >
                      Return to dashboard
                    </div>
                    <div
                      id="confirmRejection_btn"
                      className="w-full py-3 h-10 text-white bg-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer text-xs"
                      onClick={() => handleApproveOrRejct(2)}
                    >
                      Confirm rejection
                    </div>
                  </div>
                </div>
              </center>
            </div>
          )}
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default CollectionDetail;
