import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfileState } from "../interfaces";
import { RootState } from "../store";
import { useAppSelector } from "../../../../hooks/useReduxHook";
import {
  POST_ADMIN_CREATES_USER_ACTION,
  POST_USER_VERIFIES_SECURITY_QUESTION_ACTION,
  POST_USER_CHANGES_PIN_ACTION,
  POST_USERS_CHANGE_PASSWORD_ACTION,
  POST_FETCH_USERS_ACTION,
  POST_USER_CREATES_PIN_ACTION,
  POST_REQUEST_SOFT_TOKEN_ACTION,
} from "./actions";
import { removeLoadingState } from "../../../../helpers/assitFunctions";
import {
  triggerAdminCreatesUser,
  triggerGetAllUsers,
  triggerChangeUserPin,
  triggerVerifySecurityQuestion,
  triggerChangePassword,
  triggerCreateUserPin,
  triggerRequestSoftToken,
} from "./assit-functions";

const dataObject = {
  loading: [],
  successMessage: "",
  errorMessage: "",
  successStatus: false,
  allUsers: [],
  allSelected: false,
  createUser: {
    accountsToMap: [],
  },
  usersPagination: {
    sortedData: [],
    hasNextPage: false,
    currentPage: 1,
    count: 0,
    totalItems: 0,
    pageSize: 10,
    skip: 1,
    take: 10,
  },
  verifySecurityQuestion: {
    questionId: 0,
    answer: "",
    successStatus: false,
    successMessage: "",
  },
  changePin: {
    oldPin: "",
    newPin: "",
    successStatus: false,
    successMessage: "",
    errorMessage: "",
  },
  changePassword: {
    successStatus: false,
    successMessage: "",
    errorMessage: "",
  },
  requestSoftToken: {
    successStatus: false,
    successMessage: "",
    errorMessage: "",
    activeRequestTokenScreen: "token-request-screen",
    dimecData: {
      dimecId: "",
      dimecStep: "",
      dimecCapturedImage: "",
      correlationId: "",
    },
    fundingSource: {
      accountName: "",
      accountNumber: "",
    },
  },
  selectedTab: 0,
  requestType: "request",
} as ProfileState;

export const initialState: ProfileState = { ...dataObject };

export const profileSlice = createSlice({
  name: "profile",
  initialState,

  reducers: {
    resetProfileData: (): ProfileState => initialState,

    setAllSelected: (state: ProfileState, action: PayloadAction<any>) => {
      state.allSelected = action.payload;
    },

    saveAccountsSelected: (state: ProfileState, action: PayloadAction<any>) => {
      state.createUser.accountsToMap = action.payload;
    },
    setSelectedTab: (state: ProfileState, action: PayloadAction<any>) => {
      state.selectedTab = action.payload;
    },
    setRequestType: (state: ProfileState, action: PayloadAction<any>) => {
      state.requestType = action.payload;
    },
    setSkipAndTake: (state: ProfileState, action: PayloadAction<any>) => {
      state.usersPagination = {
        ...state.usersPagination,
        skip: action.payload?.skip,
        take: action.payload?.take,
      };
    },
    setTokenRequestStatus: (
      state: ProfileState,
      action: PayloadAction<any>
    ) => {
      state.requestSoftToken = {
        ...state.requestSoftToken,
        title: action.payload.title,
        successMessage: action.payload?.successMessage,
        successStatus: action.payload?.successStatus,
      };
    },
    setDimecStepAndIdForTokenRequest: (
      state: ProfileState,
      action: PayloadAction<any>
    ) => {
      state.requestSoftToken.dimecData = {
        ...state.requestSoftToken.dimecData,
        dimecId: action.payload?.dimecId,
        dimecStep: action.payload?.dimecStep,
      };
    },
    setDimecCapturedImageForTokenRequest: (
      state: ProfileState,
      action: PayloadAction<any>
    ) => {
      state.requestSoftToken.dimecData = {
        ...state.requestSoftToken?.dimecData,
        dimecCapturedImage: action.payload.capturedImage,
      };
    },
    setDimecCorrelationIdForTokenRequest: (
      state: ProfileState,
      action: PayloadAction<any>
    ) => {
      state.requestSoftToken.dimecData = {
        ...state.requestSoftToken?.dimecData,
        correlationId: action.payload.correlationId,
      };
    },
    setActiveRequestTokenScreen: (
      state: ProfileState,
      action: PayloadAction<any>
    ) => {
      state.requestSoftToken.activeRequestTokenScreen = action.payload;
    },
    setFundingSource: (state: ProfileState, action: PayloadAction<any>) => {
      state.requestSoftToken.fundingSource = {
        ...state.requestSoftToken.fundingSource,
        accountName: action.payload.accountName,
        accountNumber: action.payload.accountNumber,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // POST_ADMIN_CREATES_USER
      .addCase(POST_ADMIN_CREATES_USER_ACTION.pending, (state) => {
        state.loading = ["POST_ADMIN_CREATES_USER"];
      })
      .addCase(
        POST_ADMIN_CREATES_USER_ACTION.fulfilled,
        (state, action): void => triggerAdminCreatesUser(state, action)
      )
      .addCase(
        POST_ADMIN_CREATES_USER_ACTION.rejected,
        (state, action): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_ADMIN_CREATES_USER"
          );
        }
      )
      // POST_USER_CHANGES_PIN
      .addCase(POST_USER_CHANGES_PIN_ACTION.pending, (state) => {
        state.loading = ["POST_USER_CHANGES_PIN"];
      })
      .addCase(POST_USER_CHANGES_PIN_ACTION.fulfilled, (state, action): void =>
        triggerChangeUserPin(state, action)
      )
      .addCase(POST_USER_CHANGES_PIN_ACTION.rejected, (state, action): void => {
        state.loading = removeLoadingState(
          state.loading,
          "POST_USER_CHANGES_PIN"
        );
      })
      // POST_USER_CREATES_PIN
      .addCase(POST_USER_CREATES_PIN_ACTION.pending, (state) => {
        state.loading = ["POST_USER_CREATES_PIN"];
      })
      .addCase(POST_USER_CREATES_PIN_ACTION.fulfilled, (state, action): void =>
        triggerCreateUserPin(state, action)
      )
      .addCase(
        POST_USER_CREATES_PIN_ACTION.rejected,
        (state, payload): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_USER_CREATES_PIN"
          );
        }
      )
      // POST_USERS_CHANGE_PASSWORD
      .addCase(POST_USERS_CHANGE_PASSWORD_ACTION.pending, (state) => {
        state.loading = ["POST_USERS_CHANGE_PASSWORD"];
      })
      .addCase(
        POST_USERS_CHANGE_PASSWORD_ACTION.fulfilled,
        (state, action): void => triggerChangePassword(state, action)
      )
      .addCase(
        POST_USERS_CHANGE_PASSWORD_ACTION.rejected,
        (state, action): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_USERS_CHANGE_PASSWORD"
          );
        }
      )
      //  POST_USER_VERIFIES_SECURITY_QUESTION
      .addCase(POST_USER_VERIFIES_SECURITY_QUESTION_ACTION.pending, (state) => {
        state.loading = [" POST_USER_VERIFIES_SECURITY_QUESTION"];
      })
      .addCase(
        POST_USER_VERIFIES_SECURITY_QUESTION_ACTION.fulfilled,
        (state, action): void => {
          // console.log("test", action)
          triggerVerifySecurityQuestion(state, action);
        }
      )
      .addCase(
        POST_USER_VERIFIES_SECURITY_QUESTION_ACTION.rejected,
        (state, action): void => {
          state.loading = removeLoadingState(
            state.loading,
            " POST_USER_VERIFIES_SECURITY_QUESTION"
          );
        }
      )
      //  POST_REQUEST_SOFT_TOKEN_ACTION
      .addCase(POST_REQUEST_SOFT_TOKEN_ACTION.pending, (state) => {
        state.loading = ["POST_REQUEST_SOFT_TOKEN"];
      })
      .addCase(
        POST_REQUEST_SOFT_TOKEN_ACTION.fulfilled,
        (state, action): void => triggerRequestSoftToken(state, action)
      )
      .addCase(
        POST_REQUEST_SOFT_TOKEN_ACTION.rejected,
        (state, action): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_REQUEST_SOFT_TOKEN"
          );
        }
      )
      //POST_FETCH_USERS_ACTION
      .addCase(POST_FETCH_USERS_ACTION.pending, (state) => {
        state.loading = ["POST_FETCH_USERS"];
      })
      .addCase(POST_FETCH_USERS_ACTION.fulfilled, (state, action): void =>
        triggerGetAllUsers(state, action)
      )
      .addCase(POST_FETCH_USERS_ACTION.rejected, (state, action): void => {
        state.loading = removeLoadingState(state.loading, "POST_FETCH_USERS");
      });
  },
});

// Selectors
const selectProfile = (state: RootState) => state.profile;

// Reducers and actions
export const {
  resetProfileData,
  saveAccountsSelected,
  setAllSelected,
  setSelectedTab,
  setRequestType,
  setDimecStepAndIdForTokenRequest,
  setDimecCapturedImageForTokenRequest,
  setDimecCorrelationIdForTokenRequest,
  setSkipAndTake,
  setFundingSource,
  setTokenRequestStatus,
  setActiveRequestTokenScreen,
} = profileSlice.actions;

//App Redux State
export const useProfileState = () => useAppSelector(selectProfile);

export default profileSlice.reducer;
