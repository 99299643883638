import React, { useState } from "react";
import { ReactComponent as AFBLogo } from "../../../assets/svgs/AFBLogo.svg";
import { ReactComponent as AlatLogo } from "../../../assets/svgs/alat-logo.svg";
import { useNavigate } from "react-router-dom";
import { useAppState } from "react-wrappers/stores/redux/app/slices";

// const navData = [
//     {
//         link: "#features",
//         text: "Features"
//     },
//     {
//         link: "#company",
//         text: "Company"
//     },
//     {
//         link: "#faq",
//         text: "FAQ"
//     },
//     {
//         link: "#help",
//         text: "Help"
//     }
// ]
const Navbar = () => {
  const { isInIndividualRefereeView } = useAppState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      className={`z-[3000] lg:px-24 bg-[#000000] fixed w-full transition-[max-height] duration-500 ease-in-out 
        ${
          !open
            ? "md:max-h-24 max-h-[72px] overflow-hidden"
            : "max-h-[70vh] overflow-auto"
        }`}
    >
      <div className="flex flex-col lg:mx-auto">
        <div className={`flex py-4 lg:py-3 items-center justify-between`}>
          <div className={`${open ? "invisible" : "visible"}`}>
            {isInIndividualRefereeView ? (
              <AlatLogo
                className={`h-10 pl-0 md:ml-4 md:h-16 lg:h-14 lg:ml-0 transition-all duration-200`}
              />
            ) : (
              <AFBLogo
                className={`h-10 pl-0 md:ml-4 md:h-16 lg:h-14 lg:ml-0 transition-all duration-200`}
              />
            )}
          </div>
          {/* <div className="lg:flex hidden gap-5 lg:ml-8 text-sm items-center mr-auto p-3">
            <div
              className="text-white cursor-pointer hover:text-lib-alat-red"
              onClick={() => window.location.replace("https://online.alat.ng")}
            >
              Personal
            </div>
            <div
              className="bg-white rounded-lg hover:text-white  hover:bg-lib-alat-red text-lib-alat-red px-2 py-2 font-semibold cursor-pointer"
              onClick={() => navigate("/")}
            >
              Business
            </div>
          </div> */}
          <div className={`lg:flex items-center ml-10 hidden`}>
            <div className="flex gap-10">
              {/* {
                                navData.map((nav, index) => {
                                    return (
                                        <div className='flex text-sm font-normal cursor-pointer items-center text-center text-white hover:text-[#A90836]' key={index}>
                                           <NavLink to={nav.link}>{nav.text}</NavLink>
                                        </div>
                                    )
                                })
                            } */}
            </div>

            <div className="flex justify-between gap-2 h-full w-72">
              <div
                className="text-white flex justify-center items-center font-semibold text-sm text-center w-full hover:text-[#A90836] cursor-pointer"
                onClick={() => navigate("/signin")}
              >
                Sign in
              </div>
              <div
                className="bg-[#A90836] rounded-lg py-3 px-1 text-center text-white font-normal text-sm w-full cursor-pointer"
                onClick={() => navigate("/signup")}
              >
                Open an account
              </div>
            </div>
          </div>

          <div
            className="lg:hidden w-8 h-6 mr-6 flex flex-col justify-between ml-auto"
            onClick={() => setOpen(!open)}
          >
            <div
              className={`border-b-4 w-full rounded-full transition-all duration-300
                            ${open ? "rotate-45 translate-y-3" : ""}`}
            ></div>
            <div
              className={`border-b-4 transition-all duration-300 rounded-full
                            ${open ? "rounded-full w-0" : "w-full"}`}
            ></div>
            <div
              className={`border-b-4 w-full rounded-full transition-all duration-300  
                            ${open ? "-rotate-45 -translate-y-2" : ""}`}
            ></div>
          </div>
        </div>
        <div className="lg:hidden">
          <div className="pb-10 px-6">
            {/* {
                            navData.map((nav, index) => {
                                return (
                                    <div className='border-b border-[#606060] py-4 text-lg text-white cursor-pointer hover:text-lib-alat-dark-red' onClick={() => setOpen(false)}
                                    key={index}>
                                        <NavLink to="#red">{nav.text}</NavLink>
                                    </div>
                                )
                            })
                        } */}
          </div>
          <div className="bg-white flex justify-between gap-2 px-10 h-full py-2">
            <div
              className="text-lib-alat-dark-red flex justify-center items-center font-semibold text-sm text-center w-full"
              onClick={() => navigate("/signin")}
            >
              Sign in
            </div>
            <div
              className="bg-lib-alat-dark-red rounded-lg py-4 text-center text-white font-semibold text-sm w-full"
              onClick={() => navigate("/signup")}
            >
              Open an account
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
