import {
  setAllBulkTransactionList,
  setBulkHistoryFilter,
  setOneBulkTransactionID,
  setOneBulkTransactionList,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { TableOne } from "alat-components-library";
import { Pagination } from "components/Dashboard/User/Pagination";
import { ShowAllBulkTransactionBody } from "./ShowAllBulkTransactionBody";
import PageLoader from "components/Loader/PageLoader";
import { useEffect } from "react";
import { useAppDispatch } from "hooks/useReduxHook";
import { POST_FETCH_ALL_BULK_TRANSFER_ACTION } from "react-wrappers/stores/redux/payments/actions";

export const TableHeaders = [
  { id: 1, title: "Description", classList: "text-[12px] w-[140px]" },
  { id: 2, title: "Date", classList: "text-[12px] w-[32px] text-left" },
  { id: 3, title: "Amount (₦‎)", classList: "text-[12px] w-[120px] text-left" },
];

const AllBulkTransactionTable = () => {
  const paymentState = usePaymentState();
  const { allBulkTransactionList, bulkHistoryFilter, oneBulkTransactionID } =
    usePaymentState();
  const dispatch = useAppDispatch();
  useEffect(() => {
    !oneBulkTransactionID &&
      dispatch(setBulkHistoryFilter({ ...bulkHistoryFilter, reference: "" }));
  }, [oneBulkTransactionID]);

  const handleMenuChange = (value: any) => {
    // console.log(value)
  };

  const handleViewNext = (payload: any) => {
    dispatch(
      setBulkHistoryFilter({
        ...bulkHistoryFilter,
        pageSize: bulkHistoryFilter?.pageSize,
        pageNumber: bulkHistoryFilter?.pageNumber + 1,
        skip: bulkHistoryFilter?.skip + 10,
        take: bulkHistoryFilter?.take + 10,
      })
    );

    dispatch(
      POST_FETCH_ALL_BULK_TRANSFER_ACTION({
        ...bulkHistoryFilter,
        pageSize: bulkHistoryFilter?.pageSize,
        pageNumber: bulkHistoryFilter?.pageNumber + 1,
      })
    )
      .unwrap()
      .then((res: any) => {
        // Redirect to the success screen
        if (res.status === 200) {
          // if(oneBulkTransactionID) {
          //     dispatch(setOneBulkTransactionID(values.reference))
          //     return dispatch(setOneBulkTransactionList(res.data.data))
          // }
          dispatch(setAllBulkTransactionList(res.data.data));
        }
      });
  };

  const handleViewPrev = (payload: any) => {
    // console.log(payload)
    dispatch(
      setBulkHistoryFilter({
        ...bulkHistoryFilter,
        pageSize: bulkHistoryFilter?.pageSize,
        pageNumber: bulkHistoryFilter?.pageNumber - 1,
        skip: bulkHistoryFilter?.skip - 10,
        take: bulkHistoryFilter?.take - 10,
      })
    );

    dispatch(
      POST_FETCH_ALL_BULK_TRANSFER_ACTION({
        ...bulkHistoryFilter,
        pageSize: bulkHistoryFilter?.pageSize,
        pageNumber: bulkHistoryFilter?.pageNumber - 1,
      })
    )
      .unwrap()
      .then((res: any) => {
        // Redirect to the success screen
        if (res.status === 200) {
          // if(oneBulkTransactionID) {
          //     dispatch(setOneBulkTransactionID(values.reference))
          //     return dispatch(setOneBulkTransactionList(res.data.data))
          // }
          dispatch(setAllBulkTransactionList(res.data.data));
        }
      });
  };

  return (
    <>
      {paymentState.loading.includes("POST_FETCH_ALL_BULK_TRANSFER") && (
        <PageLoader message="Loading bulks transactions..." />
      )}
      {paymentState.loading.includes("POST_FETCH_ONE_BULK_TRANSFER") && (
        <PageLoader message="Loading bulk transactions..." />
      )}
      <div className="flex justify-center pt-6">
        <div className="w-full pb-14">
          <div className="bg-white dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
            <div className="flex items-center justify-center px-4 py-2">
              <div className="text-[#4D4D4D] text-lg font-bold text-center">
                Transactions
              </div>
            </div>
            <hr />
            <TableOne
              header={TableHeaders}
              paginationData={allBulkTransactionList?.bulkTransferSummaries}
              handleShowNext={handleViewNext}
              handleShowPrevious={handleViewPrev}
              isStriped
              isHover={false}
              body={
                <ShowAllBulkTransactionBody
                  list={allBulkTransactionList?.bulkTransferSummaries}
                  handleMenuChange={handleMenuChange}
                />
              }
            />
          </div>
          {allBulkTransactionList &&
            allBulkTransactionList?.bulkTransferSummaries?.length > 0 && (
              <Pagination
                paginationVariant={
                  "h-20 w-full flex flex-row justify-between items-center px-4"
                }
                dataToShow={{
                  sortedData: allBulkTransactionList?.bulkTransferSummaries,
                  hasNextPage: allBulkTransactionList?.hasNextPage,
                  currentPage: allBulkTransactionList?.pageNumber,
                  totalItems: allBulkTransactionList?.totalItemCount,
                  count: 10,
                  skip: bulkHistoryFilter?.skip,
                  take: bulkHistoryFilter?.take,
                }}
                handleNext={handleViewNext}
                handlePrev={handleViewPrev}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default AllBulkTransactionTable;
