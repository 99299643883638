import { useEffect, useCallback, useState } from "react";

export const useWindowSize = () => {
  const isClient = typeof window === "object";

  const getSize = useCallback(
    () => ({
      height: isClient ? window.innerHeight : null,
      width: isClient ? window.innerWidth : null,
    }),
    [isClient]
  );

  const [windowSize, setWindowSize] = useState(getSize);
  const handleResize = () => {
    if (!isClient) return false;
    return setWindowSize(getSize());
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [getSize, isClient]);

  return windowSize;
};
