import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { FormikButton, TextInput } from "alat-components-library";
import { SelectDropdown } from "components/Dashboard/components/SelectDropdown";
import { Card } from "flowbite-react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { SelectAccountValidation } from "utils/validations/bulktransfer";
import { formatNumberToCurrency, getOption } from "utils/helperFunctions";
import { useTransactionTransferController } from "controllers/BulkTransfer/useTransactionTransferController";
import { useAppSelector } from "hooks/useReduxHook";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { useState } from "react";
import { useAccountsState } from "react-wrappers/stores/redux/accounts/slices";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";

const SelectAccount = ({ setActiveTab, screenName }: any) => {
  const {
    paymentState,
    authState,
    excelList,
    totalTransferAmount,
    totalTransferCharges,
    handleSelectAccountSubmit,
  } = useTransactionTransferController(setActiveTab, screenName);
  const userAccounts = useAccountsState();
  const { bulkTransferType, validateBulkTransferAccounts } = usePaymentState();
  const allAccounts =
    userAccounts?.allAccounts?.length > 0
      ? userAccounts.allAccounts
      : authState?.user?.accounts;
  const navigate = useNavigate();
  const [accountSelected, setAccountSelected] = useState<any>({});
  let authType: string;

  if (Number(totalTransferAmount) <= 2000000) {
    authType = "pinOtp";
  }
  if (Number(totalTransferAmount) > 2000000) {
    if (authState?.user?.hasSoftToken) {
      authType = "softToken";
    } else {
      authType = "hardTokenPin";
    }
  }
  if (
    Number(totalTransferAmount) <= 2000000 &&
    paymentState?.responseCode === 21
  ) {
    if (authState?.user?.hasSoftToken) {
      authType = "softToken";
    } else {
      authType = "hardTokenPin";
    }
  }
  if (
    Number(totalTransferAmount) <= 2000000 &&
    authState?.user?.hasSoftToken === false &&
    authState?.user?.hasTransactionPin === false
  ) {
    authType = "hardTokenPin";
  }

  if (
    authState?.user?.hasSoftToken &&
    authState?.user?.hasTransactionPin === false
  ) {
    authType = "softToken";
  }
  const debitTypes = validateBulkTransferAccounts
    ? [
        {
          id: 1,
          label: "Multiple Debits",
          value: "Multiple Debits",
        },
      ]
    : [
        {
          id: 0,
          label: "Single Debit",
          value: "Single Debit",
        },
        {
          id: 1,
          label: "Multiple Debits",
          value: "Multiple Debits",
        },
      ];

  return (
    <div className="grid grid-cols-8 pt-6 ">
      {/* <div className="col-span-1"></div> */}
      <Card className="md:w-[768px] w-full mx-auto col-span-full bg-white pb-14  dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
        <Formik
          initialValues={{
            account: "",
            softToken: "",
            hardToken: "",
            transactionHardTokenPin: "",
            transactionALATPin: "",
            debitType: validateBulkTransferAccounts
              ? "Multiple Debits"
              : "Single Debit",
          }}
          validationSchema={SelectAccountValidation(
            accountSelected.balance,
            totalTransferAmount,
            totalTransferCharges
          )}
          onSubmit={(values) =>
            handleSelectAccountSubmit(values, accountSelected)
          }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            dirty,
            isValid,
            setFieldValue,
          }) => (
            <Form>
              <div className="flex items-center justify-between pb-4">
                <ChevronLeftIcon
                  className="h-6 w-6 text-[#333333] cursor-pointer"
                  onClick={() => {
                    // console.log(!!values?.account?.accountNo)
                    // if (!!accountSelected?.accountNo === false)
                    setActiveTab(`excel-list-${screenName}-tab`);
                    setFieldValue("account", "");
                  }}
                />
                <div className="text-[#4D4D4D] text-xl font-bold text-center">
                  {!!accountSelected.accountNo
                    ? "Review Transfer"
                    : "Bulk Transfer"}
                </div>
                <div></div>
              </div>
              <hr />
              <div className="md:w-[400px] w-full mx-auto">
                <h3 className="text-[#4D4D4D] text-md text-center my-5">
                  Complete transaction details below
                </h3>
                {!!accountSelected.accountNo && (
                  <>
                    <div className="text-[#666666] mb-2 text-sm font-normal">
                      Transfer from
                    </div>
                    <div
                      className="w-full h-[114px] flex justify-between items-center px-4 rounded-lg"
                      style={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.06)" }}
                    >
                      <div>
                        <p className="text-[10px]">
                          {" "}
                          {accountSelected?.accountDescription}{" "}
                        </p>
                        <p className="text-xs">{accountSelected?.accountNo}</p>
                      </div>
                      <div>
                        <p className="text-[10px]"> Balance </p>
                        <p className="text-xs text-right">
                          {formatNumberToCurrency({
                            currencyCode: "NGN",
                            number: accountSelected?.balance,
                          })}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <div className="text-[#666666] mb-2 mt-4 text-sm font-normal">
                    Transfer to
                  </div>
                  <Card className="box-border md:w-[400px] w-full h-[114px] rounded-lg text-white bg-[image:url('./assets/svgs/card-background.svg')] bg-no-repeat bg-cover">
                    <div className="flex flex-row justify-between items-center">
                      <div>
                        <div className="text-[10px]">Transaction type</div>
                        <div className="text-xs">Bulk</div>
                      </div>
                      <div>
                        <div className="text-[10px]">Number of recipients</div>
                        <div className="text-xs text-right">
                          {excelList.totalItems}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                      <div>
                        <div className="text-[10px]">Total amount</div>
                        <div className="text-xs">
                          {formatNumberToCurrency({
                            currencyCode: "NGN",
                            number: totalTransferAmount,
                          })}
                        </div>
                      </div>
                      <div>
                        <div className="text-[10px]">Charges</div>
                        <div className="text-xs text-right">
                          {formatNumberToCurrency({
                            currencyCode: "NGN",
                            number: totalTransferCharges,
                          })}
                        </div>
                      </div>
                    </div>
                  </Card>
                  {!!accountSelected.accountNo && (
                    <p className="text-left text-[11px] mt-3 italic text=[#333333]">
                      Please note the total amount to be debited will be for
                      only beneficiaries whose account details pass bank account
                      number validation
                    </p>
                  )}
                </div>

                <div className="mt-5 space-y-2">
                  <SelectDrop
                    variant="h-12 w-full text-sm"
                    name="debitType"
                    label="Debit Type"
                    placeholder="--Select--"
                    value={values?.debitType}
                    options={debitTypes}
                    onChange={(name: string, value: any) => {
                      setFieldValue("debitType", value.value);
                    }}
                    hasError={touched.debitType && errors.debitType}
                    error={errors.debitType}
                  />
                  {values?.debitType?.toLowerCase() === "multiple debits" && (
                    <span className="text-lib-alat-red italic leading-5 text-[11px]">
                      Please be aware that your account will be debited for each
                      transaction uploaded individually.
                    </span>
                  )}
                </div>
                <div className="mt-5">
                  <SelectDrop
                    variant="h-12 w-full text-sm"
                    name="account"
                    label="Select Account To Debit"
                    placeholder="--Select--"
                    value={accountSelected?.accountNo}
                    options={getOption(
                      "debit",
                      userAccounts?.allAccounts || authState.user.accounts
                    )}
                    onChange={(name: string, value: any) => {
                      // console.log("test", value)
                      setFieldValue("account", value.value);
                      setAccountSelected(JSON.parse(value.raw));
                    }}
                    hasError={touched.account && errors.account}
                    error={errors.account}
                  />
                </div>
                {/* )
                                } */}
                <div className="mt-5">
                  {authType === "softToken" && (
                    <TextInput
                      label="Enter soft token"
                      name="softToken"
                      type="password"
                      isPin
                      handleChange={(event) => {
                        setFieldValue("softToken", event.target.value);
                        // console.log(event.target.value, "Soft Token")
                        // console.log(values.softToken)
                      }}
                      variant="border text-black w-full h-10 text-lg px-5"
                      value={values.softToken}
                      maxLength={6}
                    />
                  )}
                  {authType === "pinOtp" && (
                    <TextInput
                      label="Enter PIN"
                      placeHolder="Enter pin"
                      type="password"
                      isPin
                      name="transactionALATPin"
                      handleChange={(event) =>
                        setFieldValue("transactionALATPin", event.target.value)
                      }
                      variant="border text-black w-full h-10 text-lg px-5"
                      value={values.transactionALATPin}
                      maxLength={4}
                    />
                  )}
                  {authType === "hardTokenPin" && (
                    <div className="space-y-4">
                      <TextInput
                        label="Enter pin"
                        placeHolder="Enter hard token pin"
                        type="password"
                        isPin
                        name="transactionHardTokenPin"
                        handleChange={(event) =>
                          setFieldValue(
                            "transactionHardTokenPin",
                            event.target.value
                          )
                        }
                        variant="border text-black w-full h-10 text-lg px-5"
                        value={values.transactionHardTokenPin}
                        maxLength={4}
                      />
                      {/* <p className="text-right text-[#A90836] text-sm pt-4 -mb-5 cursor-pointer">
                          Don’t have token?
                        </p> */}
                      <TextInput
                        label="Enter hard token"
                        placeHolder="Enter hard token"
                        type="password"
                        isPin
                        name="hardToken"
                        handleChange={(event) =>
                          setFieldValue("hardToken", event.target.value)
                        }
                        variant="border text-black w-full h-10 text-lg px-5"
                        value={values.hardToken}
                        maxLength={6}
                      />
                    </div>
                  )}
                </div>
                <div className="flex justify-center">
                  <FormikButton
                    labelText={"Continue"}
                    containerVariant={
                      "w-2/4 h-[45px] my-[3vh] rounded-md flex justify-center"
                    }
                    isDisabled={
                      !isValid &&
                      !dirty &&
                      !(
                        authState?.user?.hasSoftToken &&
                        !values.transactionHardTokenPin
                      )
                    }
                    isLoading={
                      !!paymentState.loading.includes(
                        "POST_TRANSFER_TRANSACTION"
                      )
                    }
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default SelectAccount;
