import * as Yup from "yup";
const containsNumbersRegex = /^\d+$/;

export const pendingRequestsSchema = Yup.object({
  token: Yup.string()
    .trim("Token cannot contain spaces")
    .required("Please enter your current Token")
    .matches(containsNumbersRegex, {
      message: "Your token should only contain numbers",
      excludeEmptyString: true,
    })
    .test(
      "token",
      "Your token cannot be less than 6 digits",
      (value) => value?.length >= 6
    ),
});

export const pendingFxValidationSchema = (usingSoftToken: boolean) =>
  Yup.object({
    softToken: usingSoftToken
      ? Yup.string()
          .trim("Token cannot contain spaces")
          .required("Please enter your current Token")
          .matches(containsNumbersRegex, {
            message: "Your token should only contain numbers",
            excludeEmptyString: true,
          })
          .test(
            "token",
            "Your token cannot be less than 6 digits",
            (value) => value?.length >= 6
          )
      : Yup.string().notRequired(),
    hardToken: usingSoftToken
      ? Yup.string().notRequired()
      : Yup.string()
          .trim("Token cannot contain spaces")
          .required("Please enter your current Token")
          .matches(containsNumbersRegex, {
            message: "Your token should only contain numbers",
            excludeEmptyString: true,
          })
          .test(
            "token",
            "Your token cannot be less than 6 digits",
            (value) => value?.length >= 6
          ),
    transactionPin: usingSoftToken
      ? Yup.string().notRequired()
      : Yup.string()
          .trim("PIN cannot contain spaces")
          .required("Please enter your hard token PIN")
          .length(4, "PIN must be 4 digits")
          .matches(containsNumbersRegex, {
            message: "Your PIN should only contain numbers",
            excludeEmptyString: true,
          })
          .test(
            "pin",
            "Your PIN can only contain numbers",
            (value) => !Number.isNaN(Number(value))
          ),
  });
