import React, { useEffect, useState } from "react";
import {
  useNetworkSelection,
  usePresetAmount,
  useAirtimeRequest,
  useDataBundle,
  useSavedBeneficiary,
} from "hooks/apis/AirtimeAndData/useAirtimePresetAmount";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useLocation, useNavigate } from "react-router-dom";
import { BillsBeneficiaryPayload } from "types/airtimerequest";
import { BeneficiaryTypesEnum } from "utils/enums";

export const useAirtimeController = () => {
  const authState = useAuthState();
  const {
    presetAmountData,
    presetAmountLoading,
    presetAmountError,
    handlePresetAmount,
  } = usePresetAmount();
  const accounts = authState.user?.accounts;
  useEffect(() => {
    handlePresetAmount();
  }, []);
  const setPresetAmount = (amount: any) => {
    const airtimeInput: HTMLInputElement = document.getElementById(
      "airtimeInput"
    ) as HTMLInputElement;
    // console.log(airtimeInput);
    airtimeInput.value = amount;
  };
  const activeAccounts = accounts.filter(
    (account: any) => account.accountStatus === "inactive"
  );

  return {
    presetAmountData,
    presetAmountLoading,
    presetAmountError,
    handlePresetAmount,
    setPresetAmount,
    accounts,
    activeAccounts,
  };
};
export const useNetworkSelectionController = () => {
  const {
    NetworkSelectionData,
    NetworkSelectionLoading,
    NetworkSelectionError,
    handleNetworkSelection,
  } = useNetworkSelection();
  // console.log(useNetworkSelection);
  useEffect(() => {
    handleNetworkSelection();
  }, []);

  return {
    NetworkSelectionData,
    NetworkSelectionLoading,
    NetworkSelectionError,
    handleNetworkSelection,
  };
};

export const useAirtimeRequestController = () => {
  const {
    AirtimeRequestData,
    AirtimeRequestLoading,
    AirtimeRequestError,
    handleAirtimeRequest,
  } = useAirtimeRequest();

  //   const handle

  return {
    AirtimeRequestData,
    AirtimeRequestLoading,
    AirtimeRequestError,
    handleAirtimeRequest,
  };
};

export const useDataBundleController = () => {
  // Local states
  const [selectedBundle, setSelectedBundle] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState([]);

  // use Effects
  const navigate = useNavigate();
  const {
    DataBundleData,
    DataBundleLoading,
    DataBundleError,
    handleDataBundle,
  } = useDataBundle();

  useEffect(() => {
    handleDataBundle();
  }, []);

  // Variables

  // Functions
  const getNetworkOptions = () => {
    const newArray =
      DataBundleData?.length > 0
        ? DataBundleData?.map((arr: any, index: any) => {
            // console.log(arr);
            return {
              ...arr,
              id: arr?.id,
              label: arr?.networkProvider,
              name: arr?.networkProvider,
              dataPackages: arr?.dataPackages,
            };
          })
        : [];
    // console.log(newArray);
    return newArray;
  };
  const getBundleOptions = () => {
    const newArray =
      selectedNetwork?.length > 0
        ? selectedNetwork?.map((arr: any, index: any) => {
            // console.log(arr);
            return {
              ...arr,
              id: arr?.id,
              label: arr?.name,
              value: arr?.name,
            };
          })
        : [];
    // console.log(newArray);
    return newArray;
  };
  const getBeneficiaryBundles = (beneficiaryNetwork: string) => {
    const beneficiaryNet =
      DataBundleData?.length > 0
        ? DataBundleData?.filter((arr: any, index: number) => {
            return arr.networkProvider?.includes(beneficiaryNetwork);
          })
        : [];

    const newArray =
      beneficiaryNet?.length > 0
        ? beneficiaryNet[0]?.dataPackages?.map((arr: any, index: any) => {
            return {
              ...arr,

              id: arr?.id,

              label: arr?.name,

              value: arr?.name,
            };
          })
        : [];
    //   console.log(newArray);
    //   console.log(beneficiaryNet);
    return newArray;
  };
  const location = useLocation();
  const beneficiary = location?.state?.beneficiary;
  function handleDataRequest(airtimeData: any) {
    navigate("/Data/DataPurchase", {
      state: {
        airtimeData: {
          ...airtimeData,
          savedBeneficiary: beneficiary?.alias,
        },
      },
    });
  }

  // console.log(useNetworkSelection);

  return {
    navigate,
    selectedNetwork,
    setSelectedNetwork,
    selectedBundle,
    setSelectedBundle,
    getBundleOptions,
    getNetworkOptions,
    handleDataRequest,
    DataBundleData,
    DataBundleLoading,
    DataBundleError,
    handleDataBundle,
    getBeneficiaryBundles,
  };
};

export const useSavedBeneficiaryController = () => {
  const {
    SavedBeneficiaryData,
    SavedBeneficiaryLoading,
    SavedBeneficiaryError,
    setSavedBeneficiaryData,
    handleSavedBeneficiary,
  } = useSavedBeneficiary();

  const location = useLocation();
  const url = location?.pathname;
  const beneficiaryType = (pathUrl: string) => {
    if (pathUrl.includes("/Bills/Data")) {
      return BeneficiaryTypesEnum.Data;
    }
    if (pathUrl.includes("/Bills/Airtime")) {
      return BeneficiaryTypesEnum.Airtime;
    }
    return BeneficiaryTypesEnum.Airtime;
  };

  const getSavedBeneficiaryPayload: BillsBeneficiaryPayload = {
    beneficiaryType: beneficiaryType(url),
    pageNumber: "1",
    pageSize: "1000",
  };

  useEffect(() => {
    handleSavedBeneficiary(getSavedBeneficiaryPayload);
  }, [beneficiaryType(url)]);
  //   const handle
  const navigate = useNavigate();

  const handleAirtimeBeneficiary = (beneficiary: any) => {
    navigate("/Airtime/NewAirtime", { state: { beneficiary } });
  };

  const handleDataBeneficiary = (beneficiary: any) => {
    navigate("/Data/NewDataPuchase", { state: { beneficiary } });
  };

  return {
    SavedBeneficiaryData,
    setSavedBeneficiaryData,
    SavedBeneficiaryLoading,
    SavedBeneficiaryError,
    handleSavedBeneficiary,
    handleAirtimeBeneficiary,
    handleDataBeneficiary,
  };
};
