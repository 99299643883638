import { ReactComponent as BackArrow } from "alat-components-library/assets/images/icons/back-arrow-two.svg";
import { Textarea } from "flowbite-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useNotificationState } from "react-wrappers/stores/redux/notifications/slices";
import { NotificationStatusEnum } from "utils/enums";
import { NotificationStatusBadge } from "./NotificationStatusBadge";
import { ApproverBadge } from "./approverBadge";
import {
  handleAmount,
  ListItem,
} from "pages/Payments/AccountStatement/PendingStatementDetail";
import dayjs from "dayjs";

export const ViewStampedAccountStatement = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const { notificationsDetails } = useNotificationState();

  const requestAccName =
    `${notificationsDetails?.requestAccountName}`.toLowerCase();
  const requestAccNumber = notificationsDetails?.requestAccountNumber;
  const startDate = notificationsDetails?.startDate;
  const endDate = notificationsDetails?.endDate;
  const amount = Number(notificationsDetails?.processingFee || 0);
  const totalAmount = Number(notificationsDetails?.totalAmount || 0);
  const vat = Number(notificationsDetails?.vat || 0);
  const debitAcctName =
    `${notificationsDetails?.debitAccountName}`.toLowerCase();
  const debitAcctNumber = notificationsDetails?.debitAccountNumber;
  const remarks = notificationsDetails?.remark;
  const approversNames = notificationsDetails?.approversNames || [];
  const verifiersNames = notificationsDetails?.verifiersNames || [];

  const isApprover = !!approversNames?.length;
  const adminName = isApprover ? approversNames.join() : verifiersNames.join();

  const handleDate = (dateString: string) => {
    if (!dateString) return "";
    const [date] = dateString.split(" ");
    const newDate = new Date(dateString);
    const isInvalid = String(newDate) === "Invalid Date";

    if (isInvalid) {
      return date;
    }
    return dayjs(newDate).format("DD/MM/YYYY");
  };

  return (
    <div className="lg:px-20 space-y-4 mt-5">
      <div className="flex items-center">
        <h2 className="text-2xl font-bold">Account Statement Request</h2>
      </div>
      <div className="mx-auto bg-white md:pb-20 pb-10 md:px-10 px-2 pt-5">
        <div className="flex py-2 justify-between items-center">
          <BackArrow
            id="back_btn"
            className="ml-5 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h2 className="text-xl">Overview</h2>
          <div></div>
        </div>
        <hr />

        <div className="space-y-8">
          <div className="md:w-[671px] w-full mx-auto flex-col">
            <div className="w-full flex items-center justify-between p-3">
              <p className="text-md font-semibold leading-[21.96px] tracking-[2%] text-lib-alat-black">
                Account Statement
              </p>
              <NotificationStatusBadge status={status} />
            </div>

            {status == NotificationStatusEnum["Approved"] ? (
              <ApproverBadge
                label={isApprover ? "Approver:" : "Verifier:"}
                approverFullName=""
                approverUserName={adminName}
              />
            ) : status == NotificationStatusEnum["Rejected"] ? (
              <ApproverBadge
                label={isApprover ? "Approver:" : "Verifier:"}
                approverFullName=""
                approverUserName={adminName}
              />
            ) : (
              ""
            )}

            <div className="w-full mb-5 flex items-center justify-between p-3">
              <p className="text-md font-semibold leading-[21.96px] tracking-[2%] text-lib-alat-black">
                Transaction Summary
              </p>
            </div>
            <ListItem
              classname="border-t px-3"
              name="STATEMENT OF ACCOUNT"
              value={
                <>
                  <p>{requestAccNumber}</p>
                  <p className="capitalize">{requestAccName}</p>
                </>
              }
            />

            <ListItem
              classname="px-3"
              name="START DATE"
              value={handleDate(startDate)}
            />
            <ListItem
              classname="px-3"
              name="END DATE"
              value={handleDate(endDate)}
            />
            <ListItem
              classname="px-3"
              name="AMOUNT "
              value={handleAmount(amount)}
            />
            <ListItem classname="px-3" name="VAT" value={handleAmount(vat)} />
            <ListItem
              classname="px-3"
              name="TOTAL"
              value={handleAmount(totalAmount)}
            />
            <ListItem
              classname="px-3"
              name="ACCOUNT TO DEBIT"
              value={
                <>
                  <p>{debitAcctNumber}</p>
                  <p className="capitalize">{debitAcctName}</p>
                </>
              }
            />

            {/* <div className="w-full border-b border-b-[#E2E8F0] flex items-center justify-between p-3">
              <p className="text-[12.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                EMAIL
              </p>
              <p
                className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                id="email_text"
              >
                {notificationsDetails?.email}
              </p>
            </div> */}

            {status == NotificationStatusEnum["Rejected"] && (
              <div>
                <hr className="h-px  bg-gray-300 border-1 dark:bg-gray-700" />

                <p className="text-md leading-[21.96px] tracking-[2%] mt-2 p-3">
                  REASON FOR REJECTION
                </p>

                <Textarea
                  disabled
                  value={remarks}
                  rows={4}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
