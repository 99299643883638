import SuccessScreen from "components/Cards/CardActivation/SuccessScreen";
import HotListScreen from "components/Cards/CardHotListScreen";
import CardSelection from "components/Cards/CardSelection";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PageHeader from "components/reusables/Page/PageHeader";
import { useAppDispatch } from "hooks/useReduxHook";
import { useEffect, useState } from "react";
import { GET_CARD_HOTLIST_REASON_ACTION } from "react-wrappers/stores/redux/cards/actions";
import { useCardState } from "react-wrappers/stores/redux/cards/slices";

const CardHotList = () => {
  const [screen, setScreen] = useState(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GET_CARD_HOTLIST_REASON_ACTION());
  }, []);

  return (
    <DashboardWrapper>
      <div className="container-fluid 2xl:container mx-auto ">
        <div className="container-fluid 2xl:container mx-auto">
          <main className="max-h-screen overflow-auto">
            <div className="pr-6 py-8">
              <div className="max-w-6xl mx-auto">
                <div className="rounded-3xl mb-5">
                  <div className="ml-4 pt-4 pb-20">
                    <PageHeader title="Hotlist Card" />
                  </div>
                  <div className="w-full">
                    {screen === 0 && (
                      <CardSelection
                        setScreen={setScreen}
                        isActiveCards
                        type={"hotlist"}
                      />
                    )}
                    {screen === 1 && <HotListScreen setScreen={setScreen} />}
                    {screen === 2 && (
                      <SuccessScreen
                        pageName={"Card Hotlist"}
                        setScreen={setScreen}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default CardHotList;
