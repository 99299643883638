import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Button, Card, Label } from "flowbite-react";
import React, { useMemo, useState } from "react";

import { ReactComponent as BankIcon } from "../../../../assets/svgs/bank-building.svg";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { generateChargeTwo, getCurrencyFormat } from "utils/helperFunctions";
import CenteredModal from "alat-components-library/Modal/CenterModal";
import { ReactComponent as PendingIcon } from "alat-components-library/assets/images/modals/warning-error-icon.svg";
import { ReusableCard } from "components/reusables/Page/ReusableCard";

// import CardBackgroundImage from "../../../../assets/svgs/card-background.svg";

interface INewTransferComponent2Props {
  accountToDebit: any;
  accounts: any;
  selectedBank: any;
  beneficiaryName: any;
  accountNumber: any;
  amount: any;
  description: any;
  handleChange: any;
  setAccountToDebit: any;
  selectedBeneficiary?: {
    bankCode: string;
    bankName: string;
    accountNumber: string;
    accountName: string;
    currency: string;
  };
  handleScreenChangePrev: any;
  handleScreenChangeNext: any;
}
const NewTransferComponent2 = ({
  accountToDebit,
  accounts,
  selectedBank,
  beneficiaryName,
  accountNumber,
  amount,
  description,
  handleChange,
  setAccountToDebit,
  handleScreenChangePrev,
  handleScreenChangeNext,
  selectedBeneficiary,
}: INewTransferComponent2Props) => {
  // console.log("selectedBeneficiary", selectedBeneficiary);
  // console.log("beneficiary", beneficiaryName);
  const activeAccounts = accounts?.filter(
    (account: any) => account?.accountStatus === "Active"
  );

  const transactionFee = useMemo(
    () => generateChargeTwo(amount?.withNumberFormat),
    [amount]
  );

  const totalAmountToDebit = useMemo(() => {
    if (selectedBank?.bankCode !== "035")
      return transactionFee + amount?.withNumberFormat;
    return amount.withNumberFormat;
  }, [amount?.withNumberFormat, selectedBank?.bankCode, transactionFee]);

  const getOption = (type: string, accounts: any) => {
    return accounts
      ?.map((account: any, index: number) => {
        const availableBalance = getCurrencyFormat(account);
        // console.log("Available Balance: ", availableBalance);
        if (account?.currency?.toLowerCase() === "usd") return;

        return {
          label: `
        ${account?.accountName} (${account.accountNo}) - 
        ${new Intl.NumberFormat("ja-JP", {
          style: "currency",
          currency: availableBalance.currency,
        })
          .format(Number(account.balance))
          .replace(
            availableBalance.currency,
            availableBalance?.unicodeCharacter || availableBalance?.currency
          )
          .trim()}
        `,
          value: account.accountNo,
          id: account.accountNo,
          raw: JSON.stringify(account),
        };
      })
      .filter((x: any) => x !== undefined);
  };
  return (
    <div className="w-full flex items-center justify-center">
      <ReusableCard
        variant="md:w-[768px] w-full"
        cardTitle="New Transfer"
        headingId="newtransfer_heading"
        handleBackNavigation={handleScreenChangePrev}
        showGoBackIcon
      >
        <div className="w-full flex items-center justify-center">
          <form className="flex md:w-[400px] w-full flex-col gap-4 justify-center items-center mt-4">
            <div className="text-[#666666]">
              Complete transaction details below
            </div>
            <div className="w-full mx-auto">
              <div className="text-[#666666] mb-2 text-sm font-normal">
                Transfer to
              </div>

              <div className="box-border w-full mx-auto h-auto rounded-lg md:space-y-4 text-white bg-[image:url('./assets/svgs/card-background.svg')] bg-no-repeat bg-cover p-4">
                <div className="flex flex-row justify-between items-center gap-4">
                  <div>
                    <div className="text-xs">Account Name</div>
                    <div className="text-base" id="nameofbeneficiary_text">
                      {beneficiaryName === ""
                        ? selectedBeneficiary?.accountName?.toUpperCase()
                        : beneficiaryName?.toUpperCase()}
                    </div>
                  </div>
                  <div>
                    <BankIcon fill="white" />
                  </div>
                </div>
                <div className="flex flex-row w-full justify-between items-center gap-4">
                  <div>
                    <div className="text-xs">Account Number</div>
                    <div className="text-base" id="acc-number_text">
                      {accountNumber === ""
                        ? selectedBeneficiary?.accountNumber
                        : accountNumber}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-right">Bank</div>
                    <div
                      className="text-base text-right"
                      id="selected-bank_text"
                    >
                      {selectedBank?.bankName === ""
                        ? selectedBeneficiary?.bankName?.toUpperCase()
                        : selectedBank?.bankName?.toUpperCase()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(selectedBank?.bankCode === ""
              ? selectedBeneficiary?.bankCode !== "035"
              : selectedBank?.bankCode !== "035") && (
              <span className="text-red-600 text-sm font-normal text-left w-full">
                You will be charged ₦
                <span id="transaction-fee_text">{`${transactionFee}`}</span> for
                this a transaction
              </span>
            )}
            <div className="w-full mx-auto">
              <div className="relative w-full pt-3">
                <SelectDrop
                  id="accounttodebit_dropdown"
                  idList="accounttodebit"
                  variant="h-12 w-full text-sm"
                  name="accountNumberDebit"
                  label="Select account to debit"
                  placeholder="Select account to debit"
                  value={accountToDebit?.accountNo}
                  options={getOption("debit", activeAccounts)}
                  onChange={(name: string, value: any) =>
                    setAccountToDebit(JSON.parse(value?.raw))
                  }
                />
                {totalAmountToDebit > accountToDebit?.balance && (
                  <div className="text-sm text-red-800" id="insufficient_text">
                    Insufficient Balance
                  </div>
                )}
              </div>
              <div className="relative w-full">
                <div className="mb-2 block text-left mt-4">
                  <Label
                    htmlFor="amount"
                    value="Amount to transfer"
                    className="!text-[#464646] dark:text-[#464646] font-normal text-lg leading-[1rem]"
                  />
                </div>
                <div className="relative">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-[#666666] ">₦</span>
                  </div>
                  <input
                    id="amount_input"
                    name="amount"
                    type="text"
                    className="pl-6 w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary"
                    value={amount?.withMoneyFormat}
                    onChange={handleChange}
                  />
                </div>
                {/* {parseFloat(amount.replace(/,/g, "")) > 1000000 && (
                  <div className="text-red-800 text-xs mt-2">
                    Your maximum transaction limit is ₦1,000,000
                  </div>
                )} */}
              </div>
              <div className="relative w-full mt-3">
                <div className="mb-2 block text-left">
                  <Label
                    htmlFor="description"
                    value="Payment description (Optional)"
                    className=" !text-[#464646] dark:text-[#464646] font-normal text-lg leading-[1rem]"
                  />
                </div>

                <input
                  id="description_input"
                  name="description"
                  type="text"
                  className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary"
                  value={description}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-row justify-center items-center gap-32 mt-10">
                <Button
                  type="button"
                  className="bg-primary hover:bg-transparent hover:border-primary lg:max-w-sm mb-20 cursor-pointer w-[188px]  hover:bg-primary focus:ring-1 focus:ring-primary  dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                  onClick={() => {
                    handleScreenChangeNext();
                  }}
                  disabled={
                    amount?.withNumberFormat < 1 ||
                    accountToDebit?.accountNo === "" ||
                    totalAmountToDebit > accountToDebit?.balance
                  }
                  id="newtransfercont_btn1"
                >
                  Continue
                </Button>
              </div>
            </div>
          </form>
        </div>
      </ReusableCard>
      {/* <Card className=" bg-white md:w-[48rem] mx-auto w-full dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center w-full justify-between">
            <div
              onClick={handleScreenChangePrev}
              className="cursor-pointer"
              id="back_btn"
            >
              <ChevronLeftIcon className="h-6 w-6 text-[#333333]" />
            </div>
            <div
              className="text-[#4D4D4D] text-xl text-center  font-semibold"
              id="newtransfer_heading"
            >
              New Transfer
            </div>
            <div></div>
          </div>
        </div>
        <hr />
      </Card> */}
      {/* {showModal && (
        <CenteredModal
          icon={<PendingIcon />}
          title={"Missing Parameters"}
          info={
            "Please make sure that account number, account name and bank are not empty."
          }
          btnText={"Okay"}
          // showCloseIcon
          handleClick={(e) => {
            setShowModal(false);
            handleScreenChangePrev();
          }}
          handleClose={(e: any) => setShowModal(false)}
        />
      )} */}
    </div>
  );
};

// {
//   showScreen === "main" ? (
//     <div>Main</div>
//   ) : showScreen == "successful" ? (
//     <div>Successful</div>
//   ) : showScreen == "failed" ? (
//     <div>Failed</div>
//   ) : (
//     <div>Pending</div>
//   );
// }
export default NewTransferComponent2;
