import React from "react";
import TestUser from "../../../assets/svgs/profile-avatar.svg";
import { truncateString } from "helpers/assitFunctions";
import { ReactComponent as RightIcon } from "../../../assets/icons/right-icon.svg";
import {
  adminProfileMenuLists,
  userProfileMenuLists,
} from "../utils/menuLinks";
import { useNavigate } from "react-router-dom";

interface ProfileMenuProps {
  children?: React.ReactNode;
  user: any;
}

export const ProfileMenu = ({ user }: ProfileMenuProps) => {
  const navigate = useNavigate();
  const isAdmin = user?.role?.toLowerCase() === "admin";
  const profileMenu = isAdmin ? adminProfileMenuLists : userProfileMenuLists;
  return (
    <div
      className="bg-white flex flex-col gap-3 flex-initial md:w-[418px] w-full items-center justify-center p-[24px] border border-[#E4DFDF] rounded-lg"
      id="profile-menu_card"
    >
      <div className="flex flex-col items-center justify-center gap-2">
        <img
          src={TestUser}
          className="rounded-full w-[76px] h-[76px]"
          alt="profile-pic"
        />
        <p
          className="font-medium text-lg tracking-tighter leading-[140%] text-lib-alat-black"
          id="tab-fullname_text"
        >
          {truncateString(user?.fullName, 25)}
        </p>
        <p
          className="text-sm leading-[170%] tracking-tight"
          id="tab-username_text"
        >
          {user?.userName}
        </p>
      </div>
      {/* <div
        className="bg-white w-full border border-lib-alat-red rounded-lg flex items-center justify-between cursor-pointer"
        // onClick={() => navigate("/profile/refer-and-earn")}
      >
        <div className="flex flex-col p-3">
          <span className="font-semibold text-sm leading-[170%] tracking-tight text-lib-alat-black">
            Refer and Earn.
          </span>
          <span className="text-lib-alat-black leading-[170%] tracking-tight text-sm">
            up to NGN 1,000,000
          </span>
        </div>
        <GiftIcon />
      </div> */}
      {profileMenu?.map(
        (item: any) =>
          item?.roles?.includes(user?.role?.toLowerCase()) && (
            <div
              id={`${item?.name.replaceAll(" ", "-")}_btn`}
              key={item?.id}
              className="bg-white w-full rounded-lg flex items-center justify-between border border-lib-alat-gray-input-border p-3 cursor-pointer"
              onClick={() => {
                navigate(`${item?.route}`);
              }}
            >
              <div className="flex items-center gap-2 text-lib-alat-red">
                {item?.icon}
                <div className="flex flex-col justify-start">
                  <span
                    className="font-semibold text-sm leading-[170%] tracking-tight text-lib-alat-black"
                    id={`${item?.name.replaceAll(" ", "-")}_text`}
                  >
                    {item?.name}
                  </span>
                  <span className="text-lib-alat-black leading-[160%] tracking-tight text-[10px]">
                    {item?.sub}
                  </span>
                </div>
              </div>
              <RightIcon />
            </div>
          )
      )}
    </div>
  );
};
