import { TabsRef, Tabs, CustomFlowbiteTheme, Flowbite } from "flowbite-react";
import React, { useRef, useState } from "react";

import { useLocation } from "react-router-dom";

interface ProfileTabWrapperProps {
  children: React.ReactNode;
  title: string;
}

export const ProfileTabs = ({ children, title }: ProfileTabWrapperProps) => {
  const { hash } = useLocation();
  const tab = parseInt(hash[1]);

  const [activeTab, setActiveTab] = useState<any>(tab);
  const tabsRef = useRef<TabsRef>(null);

  const theme = {
    tabs: {
      tablist: {
        base: "flex text-center gap-2 z-0",
        tabitem: {
          base: "flex text-center",
          variant: {
            underline: {
              base: "rounded-t-lg",
              active: {
                on: "active py-2 rounded-t-lg border-b-2 border-lib-alat-red text-lib-alat-red font-medium",
                off: "py-2 border-b-2 border-transparent text-lib-alat-black font-medium",
              },
            },
          },
        },
      },
    },
  };

  return (
    <Flowbite theme={{ theme }}>
      <Tabs
        aria-label="Default tabs"
        // eslint-disable-next-line react/style-prop-object
        variant="underline"
        ref={tabsRef}
        onActiveTabChange={(tab: React.SetStateAction<number>) =>
          setActiveTab(tab)
        }
        className="dark:!border-[#E1E1E1] !border-[#E1E1E1] w-full mx-auto "
      >
        <Tabs.Item
          active={activeTab === 0}
          title={title}
          className="dark:text-lib-alat-red dark:border-lib-alat-red text-lib-alat-red border-lib-alat-red"
        >
          {children}
        </Tabs.Item>
      </Tabs>
    </Flowbite>
  );
};
// dark:text-lib-alat-red dark:border-lib-alat-red text-lib-alat-red border-lib-alat-red
