import React, { useState, useEffect } from "react";
import PosCard from "../../../assets/svgs/promotion-card.svg";

const PromotionsCard = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [pauseScroll, setPauseScroll] = useState(false);
  const increaseCount = () => {
    let scrollTimeout;
    if (!pauseScroll) {
      scrollTimeout = setTimeout(() => {
        const currentTab = (activeTab + 1) % 4;
        setActiveTab(currentTab === 0 ? 1 : currentTab);
      }, 5000);
    } else {
      clearTimeout(scrollTimeout);
    }
  };

  // const goToPrevTab = () => {
  //     activeTab !==1  ? setActiveTab(activeTab - 1) : setActiveTab(3);
  // }

  // const goToNextTab = () => {
  //     const currentTab = (activeTab+1) % 4;
  //     setActiveTab(currentTab === 0 ? 1 : currentTab);
  // }

  useEffect(() => {
    increaseCount();
    // eslint-disable-next-line
  }, [activeTab, pauseScroll]);
  return (
    <div
      className="h-80 rounded-lg relative w-full"
      onMouseEnter={() => setPauseScroll(true)}
      onMouseLeave={() => setPauseScroll(false)}
    >
      <div
        className="w-full h-full rounded-lg"
        style={{ background: `url(${PosCard})`, backgroundSize: "cover" }}
      >
        {/* <div className="p-5 pt-10 text-white w-full h-full rounded-lg backdrop-brightness-50">
                        <p className="text-2xl text-left font-bold">#Think Play, Audacious Folks</p>
                        <p className="text-sm text-left font-semibold">Do much more with the virtual dollar Card feature today.</p>
                    </div> */}
      </div>
      {/* <div className="absolute bottom-7 w-full flex justify-around">
                <div className="flex gap-[2px] w-20 h-1">
                    <div className={`transition-width duration-500 rounded-full ${activeTab === 1 ? "w-full bg-lib-alat-dark-red" : "w-7 bg-lib-alat-border-gray"}`}></div>
                    <div className={`transition-width duration-500 rounded-full ${activeTab === 2 ? "w-full bg-lib-alat-dark-red" : "w-7 bg-lib-alat-border-gray"}`}></div>
                    <div className={`transition-width duration-500 rounded-full ${activeTab === 3 ? "w-full bg-lib-alat-dark-red" : "w-7 bg-lib-alat-border-gray"}`}></div>
                </div>
            </div> */}
    </div>
  );
};

export default PromotionsCard;
