import { lazy } from "react";
import NonWemaLetter from "pages/Home/ReferenceLetter/NonWemaLetter";
import NonWemaRejected from "pages/Home/ReferenceLetter/NonWemaRejected";
import PDFGenerator from "pages/Home/ReferenceLetter/PDFGenerator";
import WemaRefereeForm from "pages/Home/ReferenceLetter/WemaRefereeForm";
import PageNotFound from "pages/Dashboard/PageNotFound";

const ExistingSecurityQuestions = lazy(() =>
  import("../pages/Onboarding/ExistingCustomerSignup/SecurityQuestions").then(
    ({ ExistingSecurityQuestions }) => ({
      default: ExistingSecurityQuestions,
    })
  )
);

const ComingSoonCard = lazy(() =>
  import("pages/Onboarding/ComingSoonCard").then(({ ComingSoonCard }) => ({
    default: ComingSoonCard,
  }))
);
const BusinessTrackingId = lazy(() =>
  import("pages/Onboarding/UnregisteredBuisness/BusinessTrackingId").then(
    ({ BusinessTrackingId }) => ({
      default: BusinessTrackingId,
    })
  )
);
const BusinessSetup = lazy(() =>
  import("pages/Onboarding/UnregisteredBuisness/BusinessSetup").then(
    ({ BusinessSetup }) => ({
      default: BusinessSetup,
    })
  )
);
const UnregBizPaymentSummary = lazy(() =>
  import("pages/Onboarding/UnregisteredBuisness/PaymentSummary").then(
    ({ PaymentSummary }) => ({
      default: PaymentSummary,
    })
  )
);
const ValidateCAC = lazy(() =>
  import("pages/Onboarding/ValidateCAC").then(({ ValidateCAC }) => ({
    default: ValidateCAC,
  }))
);

const BusinessAddress = lazy(() =>
  import("pages/Onboarding/BusinessAddress").then(({ BusinessAddress }) => ({
    default: BusinessAddress,
  }))
);

const BusinessType = lazy(() =>
  import("pages/Onboarding/BusinessType").then(({ BusinessType }) => ({
    default: BusinessType,
  }))
);

const VerifyBVN = lazy(() =>
  import("pages/Onboarding/VerifyBVN").then(({ VerifyBVN }) => ({
    default: VerifyBVN,
  }))
);

const CreateProfile = lazy(() =>
  import("pages/Onboarding/CreateProfile").then(({ CreateProfile }) => ({
    default: CreateProfile,
  }))
);

const SecurityQuestions = lazy(() =>
  import("pages/Onboarding/SecurityQuestions").then(
    ({ SecurityQuestions }) => ({
      default: SecurityQuestions,
    })
  )
);
const BusinessTrackingFlow = lazy(() =>
  import("pages/Onboarding/UnregisteredBuisness/BusinessTrackingFlow").then(
    ({ BusinessTrackingFlow }) => ({
      default: BusinessTrackingFlow,
    })
  )
);
const BusinessAccountType = lazy(() =>
  import(
    "pages/Onboarding/UnregisteredBuisness/completRegistration/AccountType"
  ).then(({ AccountType }) => ({
    default: AccountType,
  }))
);
const BusinessSecurityQuestions = lazy(() =>
  import(
    "pages/Onboarding/UnregisteredBuisness/completRegistration/SecurityQuestions"
  ).then(({ SecurityQuestions }) => ({
    default: SecurityQuestions,
  }))
);
const BusinessVerifyBVN = lazy(() =>
  import(
    "pages/Onboarding/UnregisteredBuisness/completRegistration/VerifyBVN"
  ).then(({ VerifyBVN }) => ({
    default: VerifyBVN,
  }))
);
const BusinessContactAddress = lazy(() =>
  import(
    "pages/Onboarding/UnregisteredBuisness/completRegistration/ContactAddress"
  ).then(({ ContactAddress }) => ({
    default: ContactAddress,
  }))
);
const BusinessCreateProfile = lazy(() =>
  import(
    "pages/Onboarding/UnregisteredBuisness/completRegistration/CreateProfile"
  ).then(({ CreateProfile }) => ({
    default: CreateProfile,
  }))
);
const AccountType = lazy(() =>
  import("pages/Onboarding/AccountType").then(({ AccountType }) => ({
    default: AccountType,
  }))
);

const TakeSelfie = lazy(() =>
  import("pages/Onboarding/TakeSelfie").then(({ TakeSelfie }) => ({
    default: TakeSelfie,
  }))
);

const VerifyTin = lazy(() =>
  import("pages/Onboarding/VerifyTin").then(({ VerifyTin }) => ({
    default: VerifyTin,
  }))
);

const CompletePayment = lazy(() =>
  import("pages/Onboarding/CompletePayment").then(({ CompletePayment }) => ({
    default: CompletePayment,
  }))
);
const PendingReview = lazy(() =>
  import("../pages/Onboarding/PendingReview").then(({ PendingReview }) => ({
    default: PendingReview,
  }))
);
const UploadId = lazy(() =>
  import("pages/Onboarding/UploadId").then(({ UploadId }) => ({
    default: UploadId,
  }))
);

const UploadLicense = lazy(() =>
  import("pages/Onboarding/UploadLicense").then(({ UploadLicense }) => ({
    default: UploadLicense,
  }))
);

const PaymentSummary = lazy(() =>
  import("pages/Onboarding/PaymentSummary").then(({ PaymentSummary }) => ({
    default: PaymentSummary,
  }))
);

const VerifyIdentity = lazy(() =>
  import("pages/Onboarding/VerifyIdentity").then(({ VerifyIdentity }) => ({
    default: VerifyIdentity,
  }))
);

const ContactAddress = lazy(() =>
  import("pages/Onboarding/ContactAddress").then(({ ContactAddress }) => ({
    default: ContactAddress,
  }))
);

const VerifyExistingBVN = lazy(() =>
  import("pages/Onboarding/ExistingCustomerSignup/VerifyExistingBVN").then(
    ({ VerifyExistingBVN }) => ({
      default: VerifyExistingBVN,
    })
  )
);

const AccountNumberVerification = lazy(() =>
  import(
    "pages/Onboarding/ExistingCustomerSignup/AccountNumberVerification"
  ).then(({ AccountNumberVerification }) => ({
    default: AccountNumberVerification,
  }))
);

const ProfileExistingCustomer = lazy(() =>
  import("pages/Onboarding/ExistingCustomerSignup/CreateProfile").then(
    ({ ProfileExistingCustomer }) => ({
      default: ProfileExistingCustomer,
    })
  )
);

export const ONBOARDING_URL = {
  CAC_VALIDATION: "/signup/validate-cac",
  BUSINESS_TYPE: "/signup/business-type",
  BUSINESS_ADDRESS: "/signup/business-address",
  BVN_VERIFICATION: "/signup/verify-bvn",
  COMING_SOON: "/signup/coming-soon",
  BUSINESS_SETUP: "/signup/business-setup",
  BUSINESS_TRACKING_ID: "/signup/business_tracking",
  BUSINESS_TRACKING: "/signup/business/business-tracking",
  BUSINESS_INFORMATION: "/signup/business-information",
  SECURITY_QUESTIONS: "/signup/security-questions",
  PROFILE_CUSTOMER: "/signup/create-profile",
  ACCOUNT_TYPE: "/signup/account-type",
  TAKE_SELFIE: "/signup/take-selfie",
  COMPLETE_PAYMENT: "/signup/complete-payment",
  PENDING_REVIEW: "/signup/pending-review",
  UPLOAD_ID: "/signup/upload-id",
  UPLOAD_LICENSE: "/signup/upload-license",
  PAYMENT_SUMMARY: "/signup/payment-summary",
  BUSINESS_PAYMENT_SUMMARY: "/signup/unregistered-business/payment-summary",
  VERIFY_IDENTITY: "/signup/verify-identity",
  CONTACT_ADDRESS: "/signup/contact-address",
  UNREGISTERED_BUSINESS: {
    CREATE_PROFILE: "/signup/business/create-profile",
    BUSINESS_TYPE: "/signup/business/business-type",
    BUSINESS_ADDRESS: "/signup/business/contact-address",
    BVN_VERIFICATION: "/signup/business/verify-bvn",
    SECURITY_QUESTIONS: "/signup/business/security-questions",
  },
  EXISTING: {
    VALIDATE_ACCOUNTNUMBER: "/signup/account-validation",
    SECURITY_QUESTIONS: "/signup/existinguser/security-questions",
    CREATE_PROFILE: "/signup/existinguser/create-profile",
    VERIFY_IDENTITY: "/signup/existinguser/verify-identity",
    BVN_VERIFICATION: "/signup/existinguser/verify-bvn",
    TAKE_SELFIE: "/signup/existinguser/take-selfie",
  },
};

export const onboardingRoutes = [
  {
    path: ONBOARDING_URL.UNREGISTERED_BUSINESS.BVN_VERIFICATION,
    isAuthenticated: false,
    element: <BusinessVerifyBVN />,
  },
  {
    path: ONBOARDING_URL.UNREGISTERED_BUSINESS.BUSINESS_ADDRESS,
    isAuthenticated: false,
    element: <BusinessContactAddress />,
  },
  {
    path: ONBOARDING_URL.UNREGISTERED_BUSINESS.BUSINESS_TYPE,
    isAuthenticated: false,
    element: <BusinessAccountType />,
  },
  {
    path: ONBOARDING_URL.BUSINESS_TRACKING,
    isAuthenticated: false,
    element: <BusinessTrackingFlow />,
  },
  {
    path: ONBOARDING_URL.UNREGISTERED_BUSINESS.SECURITY_QUESTIONS,
    isAuthenticated: false,
    element: <BusinessSecurityQuestions />,
  },
  {
    path: ONBOARDING_URL.UNREGISTERED_BUSINESS.CREATE_PROFILE,
    isAuthenticated: false,
    element: <BusinessCreateProfile />,
  },
  {
    path: ONBOARDING_URL.CAC_VALIDATION,
    isAuthenticated: false,
    element: <ValidateCAC />,
  },
  {
    path: ONBOARDING_URL.BUSINESS_TYPE,
    isAuthenticated: false,
    element: <BusinessType />,
  },
  {
    path: ONBOARDING_URL.EXISTING.VALIDATE_ACCOUNTNUMBER,
    isAuthenticated: false,
    element: <AccountNumberVerification />,
  },
  {
    path: ONBOARDING_URL.EXISTING.BVN_VERIFICATION,
    isAuthenticated: false,
    element: <VerifyExistingBVN />,
  },
  {
    path: ONBOARDING_URL.BUSINESS_TRACKING_ID,
    isAuthenticated: false,
    element: <BusinessTrackingId />,
  },
  {
    path: ONBOARDING_URL.BUSINESS_SETUP,
    isAuthenticated: false,
    element: <BusinessSetup />,
  },
  {
    path: ONBOARDING_URL.BUSINESS_PAYMENT_SUMMARY,
    isAuthenticated: false,
    element: <UnregBizPaymentSummary />,
  },

  {
    path: ONBOARDING_URL.EXISTING.SECURITY_QUESTIONS,
    isAuthenticated: false,
    element: <ExistingSecurityQuestions />,
  },
  {
    path: ONBOARDING_URL.EXISTING.CREATE_PROFILE,
    isAuthenticated: false,
    element: <ProfileExistingCustomer />,
  },
  {
    path: ONBOARDING_URL.BUSINESS_ADDRESS,
    isAuthenticated: false,
    element: <BusinessAddress />,
  },
  {
    path: ONBOARDING_URL.BVN_VERIFICATION,
    isAuthenticated: false,
    element: <VerifyBVN />,
  },
  {
    path: ONBOARDING_URL.COMING_SOON,
    isAuthenticated: false,
    element: <ComingSoonCard />,
  },
  {
    path: ONBOARDING_URL.BUSINESS_INFORMATION,
    isAuthenticated: false,
    element: <VerifyTin />,
  },
  {
    path: ONBOARDING_URL.SECURITY_QUESTIONS,
    isAuthenticated: false,
    element: <SecurityQuestions />,
  },

  {
    path: ONBOARDING_URL.PROFILE_CUSTOMER,
    isAuthenticated: false,
    element: <CreateProfile />,
  },
  {
    path: ONBOARDING_URL.ACCOUNT_TYPE,
    isAuthenticated: false,
    element: <AccountType />,
  },
  {
    path: ONBOARDING_URL.TAKE_SELFIE,
    isAuthenticated: false,
    element: <TakeSelfie />,
  },

  {
    path: ONBOARDING_URL.PENDING_REVIEW,
    isAuthenticated: true,
    element: <PendingReview />,
  },
  {
    path: ONBOARDING_URL.COMPLETE_PAYMENT,
    isAuthenticated: true,
    element: <CompletePayment />,
  },
  {
    path: ONBOARDING_URL.UPLOAD_ID,
    isAuthenticated: false,
    element: <UploadId />,
  },
  {
    path: ONBOARDING_URL.UPLOAD_LICENSE,
    isAuthenticated: false,
    element: <UploadLicense />,
  },
  {
    path: ONBOARDING_URL.PAYMENT_SUMMARY,
    isAuthenticated: false,
    element: <PaymentSummary />,
  },
  {
    path: ONBOARDING_URL.VERIFY_IDENTITY,
    isAuthenticated: false,
    element: <VerifyIdentity />,
  },
  {
    path: ONBOARDING_URL.CONTACT_ADDRESS,
    isAuthenticated: false,
    element: <ContactAddress />,
  },
  {
    path: "/reference-letter/wema",
    isAuthenticated: false,
    element: <WemaRefereeForm />,
  },
  {
    path: "/reference-letter/review/:id",
    isAuthenticated: false,
    element: <NonWemaLetter />,
  },
  {
    path: "/reference-letter/review/rejected",
    isAuthenticated: false,
    element: <NonWemaRejected />,
  },
  {
    path: "/reference-letter/pdf",
    isAuthenticated: false,
    element: <PDFGenerator />,
  },
  // Not found
  {
    path: "*",
    isAuthenticated: false,
    element: <PageNotFound />,
  },
];
