import { useApiRequest } from "../../useApiRequest";
import { useState } from "react";
import { useAppDispatch } from "../../useReduxHook";
import {
  setLoadingState,
  setRequestResponse,
} from "../../../react-wrappers/stores/redux/accountReactivation/slices";
import { ToastNotify } from "../../../helpers/toastNotify";
import { UserAccountNumber } from "../../../react-wrappers/stores/redux/interfaces";
import {
  GET_VERIFY_ACCOUNT_INSIDE_APP,
  GET_VERIFY_ACCOUNT_OUTSIDE_APP,
} from "../../../utils/apis/routes";
import { initialRes } from "../../../react-wrappers/stores/redux/accountReactivation/__test__/mocks";

export const useVerifyAccountNumber = () => {
  const makeRequest = useApiRequest();
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<Boolean>(false);
  const [responseData, setResponseData] = useState(initialRes);

  const handleUserAcountVerification = async (payload: UserAccountNumber) => {
    const { accountNumber, isInApp = false } = payload;
    const VERIFICATION_KEY = isInApp
      ? "GET_VERIFY_ACCOUNT_INSIDE_APP"
      : "GET_VERIFY_ACCOUNT_OUTSIDE_APP";
    const API_URL = isInApp
      ? GET_VERIFY_ACCOUNT_INSIDE_APP(accountNumber)
      : GET_VERIFY_ACCOUNT_OUTSIDE_APP(accountNumber);

    dispatch(setLoadingState([VERIFICATION_KEY]));
    setResponseData(initialRes);
    setStatus(false);
    try {
      const response = await makeRequest({
        method: "get",
        route: API_URL,
        originType: "identity",
      });
      if (response?.data?.httpStatusCode === 200) {
        const resData = response?.data?.data;
        setStatus(true);
        dispatch(setRequestResponse(resData));
        setResponseData(resData);
      } else if (response?.data?.httpStatusCode === 500) {
        setStatus(false);
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        const resData = response?.data?.data;
        setStatus(false);
        setResponseData(resData);
      }
    } catch (err: any) {
      setStatus(false);
      const msgTitle = err?.data?.responseMessage?.title;
      const msgAction = err?.data?.responseMessage?.actionMessage;
      const resMsg =
        msgTitle || msgAction
          ? `${msgTitle}. ${msgAction}`
          : "Something went wrong while validating account";

      return ToastNotify({
        type: "error",
        message: resMsg,
        position: "top-right",
      });
    } finally {
      dispatch(setLoadingState([]));
    }
  };

  return {
    handleUserAcountVerification,
    status,
    responseData,
  };
};
