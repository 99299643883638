import { useState, useEffect } from "react";
import { BusinessDetailsContainer } from "./BusinessDetails";
import { FormikButton, TextInput } from "alat-components-library";
import { Form, Formik } from "formik";
import { DateInput } from "components/Inputs";
import FileUpload2 from "./Components/FileUpload";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import { idUploadSchema } from "./Validation/onboardingValidation";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import { saveOnboardingData } from "react-wrappers/stores/redux/onboarding/slices";
import { useNavigate } from "react-router-dom";
import { DocumentTypeMetaData, IdType } from "utils/enums";
import { ONBOARDING_URL } from "routers/onboardingRoutes";

export const initialValues = {
  idType: "",
  docNumber: "",
  issueDate: "",
  expiryDate: "",
  frontImageLink: "",
  backImageLink: "",
  signatureLink: "",
};

const ids = [
  { name: "Driver's License", id: IdType.DRIVERSLICENSE },
  { name: "NIN", id: IdType.NIN },
  { name: "National Id Card", id: IdType.NATIONALIDCARD },
  { name: "International Passport", id: IdType.INTLPASSPORT },
  { name: "Voters Card", id: IdType.VOTERSCARD },
];

export const handleDate = (date: any) => {
  if (date === "") {
    return null;
  }
  return new Date(date).toISOString();
};

export const UploadId = () => {
  // Local state
  const [showBackPhoto, setShowBackPhoto] = useState(false);
  const [showExpiryDate, setShowExpiryDate] = useState(false);
  const [showIssueDate, setShowIssueDate] = useState(false);
  const [selectedType, setSelectedType] = useState("Driver's License");
  const [selectedTypeId, setSelectedTypeId] = useState(0);
  const [docType, setDocType] = useState("");
  const [maxLength, setMaxLength] = useState(50);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const businessPayload = useAppSelector(
    (state) => state.onboarding.businessPayload
  );

  const handleMaxLength = (type: any) => {
    switch (type) {
      case "Voters Card":
        setMaxLength(19);
        setDocType(DocumentTypeMetaData.votersCard);
        break;
      case "International Passport":
        setMaxLength(9);
        setDocType(DocumentTypeMetaData.intlPassport);
        break;
      case "Driver's License":
        setMaxLength(13);
        setDocType(DocumentTypeMetaData.driversLicense);
        break;
      case "National Id Card":
        setMaxLength(12);
        setDocType(DocumentTypeMetaData.nationalId);
        break;
      case "NIN":
        setMaxLength(11);
        setDocType(DocumentTypeMetaData.nin);
        break;
      default:
        break;
    }
    return maxLength;
  };

  const frontDocType =
    docType === DocumentTypeMetaData.votersCard ? `${docType}_FRONT` : docType;

  useEffect(() => {
    const backPhoto = selectedType === "Voters Card";
    const expiryDate =
      selectedType === "Driver's License" ||
      selectedType === "International Passport" ||
      selectedType === "National Id Card";
    const issueDate =
      selectedType === "Driver's License" ||
      selectedType === "International Passport" ||
      selectedType === "Voters Card" ||
      selectedType === "National Id Card";
    setShowBackPhoto(backPhoto);
    setShowExpiryDate(expiryDate);
    setShowIssueDate(issueDate);

    handleMaxLength(selectedType);
    // eslint-disable-next-line
  }, [selectedType]);

  const heightTransition = (type: boolean) =>
    `${
      type ? "max-h-[1000px]" : "max-h-0"
    } overflow-hidden transition-[max-height] duration-[400ms] transition-property-height`;
  return (
    <BusinessDetailsContainer
      heading="Upload I.D Card"
      subHeading="Upload your I.D Card and signature"
      num={2}
      deNum={9}
      showProgress
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          dispatch(
            saveOnboardingData({
              businessPayload: {
                ...businessPayload,
                identificationCard: {
                  idType: selectedTypeId,
                  idNumber: values?.docNumber,
                  issueDate: showIssueDate
                    ? handleDate(values?.issueDate)
                    : null,
                  expiryDate: showExpiryDate
                    ? handleDate(values?.expiryDate)
                    : null,
                  frontImageLink: values?.frontImageLink,
                  backImageLink: showBackPhoto ? values?.backImageLink : "",
                  signatureLink: values?.signatureLink,
                },
              },
            })
          );
          navigate(ONBOARDING_URL.UPLOAD_LICENSE);
        }}
        validateOnBlur
        validationSchema={idUploadSchema(selectedType)}
      >
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          isValid,
          touched,
          dirty,
          resetForm,
          setFormikState,
        }) => (
          <Form className="w-full flex flex-col gap-4 mb-5">
            <StateDropdown
              id="idtype_dropdown"
              name="idType"
              label="Select ID Card Type"
              options={ids}
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder="-- Select an ID Type --"
              onSelectOption={(e) => {
                resetForm({
                  values: {
                    idType: e.name,
                    docNumber: "",
                    issueDate: "",
                    expiryDate: "",
                    frontImageLink: "",
                    backImageLink: "",
                    signatureLink: "",
                  },
                });
                setSelectedType(e.name);
                setSelectedTypeId(e.id);
              }}
            />
            <TextInput
              id="id-number_input"
              name="docNumber"
              label="Enter Document Number"
              loading={false}
              // handleChange={(e) => {
              //   handleChange(e);
              //   console.log("Formik values: ", values);
              //   console.log("Formik errors: ", errors);
              // }}
              maxLength={maxLength}
              value={values.docNumber}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors?.docNumber}
              hasError={touched.docNumber && errors?.docNumber}
            />
            <div className="grid grid-cols-2 gap-3 w-full">
              <DateInput
                id="id-issue-date_input"
                name="issueDate"
                label="Issue date"
                className={`${heightTransition(showIssueDate)}`}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={touched.issueDate ? errors?.issueDate : ""}
                hasError={touched.issueDate && errors.issueDate}
              />
              <DateInput
                id="id-expiry-date_input"
                name="expiryDate"
                label="Expiry date"
                className={`${heightTransition(showExpiryDate)}`}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={touched.expiryDate ? errors?.expiryDate : ""}
                hasError={touched.expiryDate && errors.expiryDate}
              />
            </div>
            <FileUpload2
              id="id-front-file_input"
              label="Upload ID Card (Front)"
              name="frontImageLink"
              handleBlur={handleBlur}
              error={touched.frontImageLink ? errors?.frontImageLink : ""}
              DocumentType={`${docType}`}
              DocumentName={`${frontDocType}`}
              CacRegNumber={`BN${businessPayload.infoRequest.businessRequest.reg_number}`}
            />
            <FileUpload2
              id="id-back-file_input"
              label="Upload ID Card (Back)"
              name="backImageLink"
              className={`${heightTransition(showBackPhoto)}`}
              handleBlur={handleBlur}
              error={touched.backImageLink ? errors?.backImageLink : ""}
              DocumentType={`${docType}`}
              DocumentName={`${docType}_BACK`}
              CacRegNumber={`BN${businessPayload.infoRequest.businessRequest.reg_number}`}
            />
            <FileUpload2
              id="signature-file_input"
              label="Upload Signature"
              name="signatureLink"
              handleBlur={handleBlur}
              DocumentType={DocumentTypeMetaData.signature}
              DocumentName="SIGNATURE"
              error={touched.signatureLink ? errors?.signatureLink : ""}
              CacRegNumber={`BN${businessPayload.infoRequest.businessRequest.reg_number}`}
            />

            <div>
              <FormikButton
                id="proceed_btn"
                labelText="Proceed"
                containerVariant="w-full h-10 rounded-md flex justify-center"
                isDisabled={!isValid || !dirty}
              />
            </div>
          </Form>
        )}
      </Formik>
      {/* <PendingAccountCard
            /> */}
    </BusinessDetailsContainer>
  );
};
