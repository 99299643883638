import React, { ReactNode, useState } from "react";

interface Props {
  text: string;
  children: ReactNode;
}

const TermsAccordion: React.FC<Props> = ({ text, children }) => {
  const [terms, setTerms] = useState(false);
  return (
    <div
      className={`z-[3000] mb-5 shadow-xl bg-white rounded-md md:mx-[6vw] transition-[height] duration-1000 ease-in-out
            ${!terms ? "max-h-16 overflow-hidden" : "max-h-[50000px]"}`}
    >
      {/* <div className={`h-full shadow-2xl`}> */}
      <div
        className="flex items-center justify-between px-8 h-full py-5"
        onClick={() => setTerms(!terms)}
      >
        <div className="font-bold">{text}</div>
        <div className="w-6 flex flex-col gap-3 cursor-pointer">
          <div
            className={`border-b-[3px] border-black duration-300 transition-all
                ${!terms ? "rotate-90 translate-y-2" : "hidden"}`}
          ></div>
          <div
            className={`border-b-[3px] border-black duration-300 transition-all
                ${!terms ? "-translate-y-[7px]" : ""}`}
          ></div>
        </div>
      </div>
      <div className="border-t-2 mx-4"></div>
      <div className="px-4">{children}</div>
      {/* </div> */}
    </div>
  );
};

export default TermsAccordion;
