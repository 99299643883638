import * as Yup from "yup";

const nameValidation: { [key: string]: string } = {
  individual: "Enter your name",
  corporate: "Enter your business name",
};

export const wemaRefereeSchema = (type: string) =>
  Yup.object().shape({
    businessName: Yup.string().required(nameValidation[type]),
    bank: Yup.string(),
    bankAddress: Yup.string().required("Enter your bank address"),
    accountNumber: Yup.string()
      .matches(/^\d*$/, "Enter a valid Account number")
      .test(
        "Not Null",
        "Account number must be an 10 digit number",
        (value) => value !== "" && value?.length === 10
      )
      .required("Enter a valid Account number"),
    lengthOfRelationship: Yup.string().required("Enter length of relationship"),
    phoneNumber1: Yup.string()
      .matches(/^\d*$/, "Enter a valid phone number")
      .required("Enter your phone number"),
    phoneNumber2: Yup.string(),
    signature: Yup.string().required("Upload an image of your Signature"),
  });
