import PendingActions from "pages/Dashboard/PendingActions";
import RefereeSetup from "pages/Dashboard/PendingActions/RefereeSetup";
import { RefereeList } from "pages/Dashboard/PendingActions/RefereeSetup/RefereeList";
import ErrorPage from "pages/ErrorPage";

import SuccessPage from "pages/SuccessPage";

export const otherRoutes = [
  {
    path: "/success",
    isAuthenticated: true,
    element: <SuccessPage />,
  },
  {
    path: "/error",
    isAuthenticated: true,
    element: <ErrorPage />,
  },
  {
    path: "/pending-actions",
    isAuthenticated: false,
    element: <PendingActions />,
  },
  {
    path: "/referee-setup",
    isAuthenticated: true,
    element: <RefereeSetup />,
  },
  {
    path: "/referee-list",
    isAuthenticated: true,
    element: <RefereeList />,
  },
];
