import { useEffect, useRef, useState } from "react";
import { ReactComponent as AfbLogo } from "assets/svgs/transactions/afb-receipt-logo.svg";
import ReceiptBg from "assets/svgs/transactions/receipt-bg.svg";
import { useTransactionReceipt } from "hooks/apis/Payment/useTransactionReceipt";
import {
  formatNumberToCurrency,
  moneyInputFormat,
} from "utils/helperFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";
import PageLoader from "components/Loader/PageLoader";
import { toWords } from "number-to-words";
import { useTransactionReceiptByCorrelationId } from "hooks/apis/Payment/useTransactionReceiptByCorrelationId";
import { ToastNotify } from "helpers/toastNotify";
import dayjs from "utils/dayjsLib";

const TransactionReceipt = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("location", location?.state);
  const payload = location?.state?.payload;
  // console.log("state", payload.transaction);
  const bulkPayload = location?.state?.bulkPayload;
  const bulkTransactionData = bulkPayload
    ? {
        sourceBankName: bulkPayload?.sourceAccountBankName,
        sourceAccountName: bulkPayload?.sourceAccountName,
        destinationAccountNumber: bulkPayload?.destinationAccountNumber,
        destinationBankName: bulkPayload?.destinationBankName,
        destinationAccountName: bulkPayload?.destinationAccountName,
        narration: bulkPayload?.narration,
        reference: bulkPayload?.reference,
        date: bulkPayload?.dateCreated,
        amount: bulkPayload?.amount,
        amountInWord: bulkPayload?.amountInWord,
      }
    : "";

  const {
    transactionReceipt: transactionReceiptData,
    transactionReceiptLoading,
    transactionReceiptError,
    getTransactionReceipt,
  } = useTransactionReceipt();

  const {
    transactionReceiptByCorrelationId,
    transactionReceiptByCorrelationIdLoading,
    transactionReceiptByCorrelationIdError,
    getTransactionReceiptByCorrelationId,
  } = useTransactionReceiptByCorrelationId();

  useEffect(() => {
    handleTransactionReceipt();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !location?.state?.payload?.correlationId &&
      !location?.state?.payload?.transaction &&
      location?.state?.bulkPayload?.status !== "Success"
    ) {
      ToastNotify({
        type: "error",
        message: "Receipt Unavailable",
        position: "top-right",
      });
      navigate(-1);
    }
  }, []);

  useEffect(
    () => {
      if (
        !!transactionReceiptByCorrelationIdError ||
        !!transactionReceiptError
      ) {
        navigate(-1);
      }
    },
    // eslint-disable-next-line
    [
      transactionReceiptError,
      transactionReceiptByCorrelationIdError,
      transactionReceiptLoading,
      transactionReceiptByCorrelationIdLoading,
    ]
  );

  const transactionReceipt = payload?.correlationId
    ? transactionReceiptByCorrelationId
    : payload?.transaction
      ? payload.transaction
      : bulkTransactionData;

  useEffect(() => {
    if (bulkPayload) {
      if (bulkPayload?.status === "Success") {
        handleDownloadPDF();
        setLoading(false);
      } else {
        // navigate(-1);
      }
    } else if (transactionReceipt?.amount) {
      handleDownloadPDF();
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [transactionReceipt?.amount]);

  const handleTransactionReceipt = async () => {
    if (!!payload?.correlationId) {
      await getTransactionReceiptByCorrelationId(payload);
    } else if (!!payload?.transaction) {
      // await getTransactionReceipt(payload);
    } else if (bulkPayload?.status && bulkPayload?.status !== "Success") {
      // ToastNotify({
      //   type: "error",
      //   message: "Receipt Unavailable",
      //   position: "top-right",
      // });
    }
  };

  /// Print

  const contentRef = useRef(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadPDF = async () => {
    if (isDownloading) return;
    setIsDownloading(true);

    const contentElement = contentRef.current;

    const pdfBlob = await html2pdf()
      .set({
        margin: [0, 0, 0, 0],
        filename: "mydocument.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all"] },
        elementHandler: function (element: any, renderer: any) {
          if (element === contentElement) {
            return true;
          }
          return false;
        },
      })
      .from(contentElement)
      .outputPdf("blob");

    const pdfUrl = URL.createObjectURL(pdfBlob);

    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl;
    downloadLink.download =
      transactionReceipt?.receiverName?.toLowerCase() === "suspense account"
        ? `TransactionReceipt-${transactionReceipt?.type}.pdf`
        : `TransactionReceipt-${transactionReceipt?.destinationAccountName ?? transactionReceipt?.recieverName}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    navigate(-1);
    setIsDownloading(false);
  };
  const receiptLoader =
    (transactionReceiptLoading || transactionReceiptByCorrelationIdLoading) &&
    (!transactionReceipt || !transactionReceiptError);

  const showBeneficiaryDetails =
    !transactionReceipt?.narration?.toLowerCase()?.includes("airtime") ||
    !transactionReceipt?.narration?.toLowerCase()?.includes("data") ||
    !transactionReceipt?.narration?.toLowerCase()?.includes("bills");

  return (
    <div className="bg-black/50 w-full min-h-screen lg:px-36 px-10 overflow-y-auto">
      {(loading || receiptLoader) && <PageLoader message="Loading Receipt" />}
      <div
        className="w-full bg-white min-h-screen pb-20"
        ref={contentRef}
        style={{ backgroundImage: `url(${ReceiptBg})` }}
      >
        <div className="px-10 py-5 w-full flex justify-between items-center">
          <AfbLogo className="w-16 h-16" />
          <div className="text-[#A90836] text-2xl font-semibold">
            Transaction Receipt
          </div>
          <div className="w-20"></div>
        </div>
        <div className="w-full bg-[#F7F7F7] px-10 py-5 mt-0 flex items-center">
          <div className="grid grid-cols-12">
            <div className="col-span-4 text-base font-normal text-[#A90836]">
              Transaction Date:{" "}
            </div>
            <div className="col-span-1"></div>
            <div className="col-span-7 text-base">
              {/* {dayjs(transactionReceipt?.date).format("DD MMM YYYY")} */}
              {transactionReceipt?.date?.includes("T")
                ? dayjs(transactionReceipt?.date).format("DD MMM YYYY")
                : transactionReceipt?.date}
            </div>
          </div>
        </div>
        <div className="mx-10 border-b-2 border-[#333333] text-lg py-5 pb-5 text-[#A90836]">
          Transaction Details
        </div>

        <div className="w-full px-10 flex flex-col gap-2 py-4">
          <div className="grid grid-cols-12">
            <div className="col-span-4 text-[16px] text-[#A90836]">
              Transaction Amount:{" "}
            </div>
            <div className="col-span-7 text-lg">
              {/* {(transactionReceipt?.currency ??
                transactionReceipt?.currencyCode) +
                " " +
                moneyInputFormat(transactionReceipt.amount || 0)
                  ?.withMoneyFormat} */}
              {formatNumberToCurrency({
                number: transactionReceipt?.amount ?? 0,
                currencyCode:
                  transactionReceipt?.currency ??
                  transactionReceipt?.currencyCode,
              })}
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-4 text-[16px] text-[#A90836]">
              Amount in words:{" "}
            </div>
            <div className="col-span-7 text-lg capitalize">
              {/* {toWords(transactionReceipt?.amount ?? 0) + " naira only."} */}
              {transactionReceipt?.amountInWords ??
                toWords(transactionReceipt?.amount ?? 0) + " naira only."}
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-4 text-[16px] text-[#A90836]">
              Reference Number:{" "}
            </div>
            <div className="col-span-7 text-lg break-all">
              {transactionReceipt?.referenceId !== undefined
                ? transactionReceipt?.referenceId
                : transactionReceipt?.reference ?? transactionReceipt?.tranId}
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-4 text-[16px] text-[#A90836]">
              Purpose of Transfer:{""}
            </div>
            <div className="col-span-7 text-lg">
              {transactionReceipt?.narration}
            </div>
          </div>
        </div>

        {showBeneficiaryDetails && (
          <div className="w-full">
            <div className="mx-10 border-b-2 border-[#333333] text-lg py-5 pb-5 text-[#A90836]">
              Beneficiary Details
            </div>

            <div className="w-full px-10 flex flex-col gap-2 py-4">
              <div className="grid grid-cols-12">
                <div className="col-span-4 text-[16px] text-[#A90836]">
                  Name:{" "}
                </div>
                <div className="col-span-7 text-lg">
                  {transactionReceipt?.destinationAccountName ??
                    transactionReceipt?.recieverName}
                </div>
              </div>
              <div className="grid grid-cols-12">
                <div className="col-span-4 text-[16px] text-[#A90836]">
                  Bank:{" "}
                </div>
                <div className="col-span-7 text-lg">
                  {transactionReceipt?.destinationBankName ??
                    transactionReceipt?.destinationBank}
                </div>
              </div>
              <div className="grid grid-cols-12">
                <div className="col-span-4 text-[16px] text-[#A90836]">
                  Account Number:{" "}
                </div>
                <div className="col-span-7 text-lg">
                  {transactionReceipt?.destinationAccountNumber}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mx-10 border-b-2 border-[#333333] text-lg py-5 pb-5 text-[#A90836]">
          Sender Details
        </div>

        <div className="w-full px-10 flex flex-col gap-2 py-2">
          <div className="grid grid-cols-12">
            <div className="col-span-4 text-[16px] text-[#A90836]">
              Account Name:{" "}
            </div>
            <div className="col-span-7 text-lg">
              {transactionReceipt?.sourceAccountName ??
                transactionReceipt?.sender}
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-4 text-[16px] text-[#A90836]">Bank: </div>
            <div className="col-span-7 text-lg">
              {transactionReceipt?.sourceBankName ?? "Wema Bank"}
            </div>
          </div>
        </div>

        <div className="w-full text-left text-xs bg-[#F7F7F7] px-10 py-5 mt-10">
          <p>Disclaimer</p>
          <p>
            Your transfer has been successful and the beneficiary's account will
            be credited. However, this does not serve as confirmation of credit
            into the beneficiary’s account. Due to the nature of the Internet,
            transactions may be subject to interruption, transmission blackout,
            delayed transmission and incorrect data transmission. The Bank is
            not liable for malfunctions in communications facilities not within
            its control that may affect the accuracy or timeliness of messages
            and transactions you send. All transactions are subject to
            verification and our normal fraud checks
          </p>
        </div>
      </div>
    </div>
  );
};

export default TransactionReceipt;
