import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { ReactComponent as ComingSoon } from "assets/svgs/Cards/coming-soon.svg";
import React from "react";

export const ComingSoonView = ({ title }: { title: string }) => {
  return (
    <div className="w-full flex items-center justify-center">
      <ReusableCard
        cardTitle={title}
        showGoBackIcon={false}
        variant={"md:w-[851px] w-full"}
      >
        <div className="md:w-[409px] w-full mx-auto flex items-center justify-center">
          <ComingSoon />
        </div>
      </ReusableCard>
    </div>
  );
};
