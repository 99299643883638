// eslint-disable
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { Form, Formik } from "formik";
import PendingActionsWrapper from "pages/PendingActions/RejectedDocuments/components/PendingActionsWrapper";
import { BusinessAddressSchema } from "pages/PendingActions/RejectedDocuments/validation/pendingActionsValidation";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikButton, TextInput } from "alat-components-library";
import { useEffect, useState } from "react";
import { useAppSelector } from "hooks/useReduxHook";
import { useAddress } from "hooks/apis/FetchLocation/useAddress";
import {
  StateDropdown,
  Option,
} from "components/Dashboard/components/StateDropdown";
import { useResubmitRejectedDocuments } from "hooks/apis/pendingActions/useResubmitRejectedDocuments";
import PageLoader from "components/Loader/PageLoader";

// Checks the state before destructuring occurs
const BusinessAddressUpload = () => {
  const location = useLocation();

  if (!location?.state?.pendingData) {
    window.location.replace("/user-dashboard");
    return <div></div>;
  }

  return <BusinessAddressUploadInner />;
};

const BusinessAddressUploadInner = () => {
  const [selectedState, setSelectedState] = useState({ id: 100, name: "" });
  const [selectedLga, setSelectedLga] = useState({ id: 20 });

  const [loading, setLoading] = useState(false);

  const {
    fetchLoading: submitLoading,
    resubmittedResponse,
    handleResubmittedDocuments,
  } = useResubmitRejectedDocuments();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    reason,
    cacRegNumber,
    pendingActionType: pendingAction,
    actionData,
    pendingActionId,
  } = location?.state?.pendingData;

  const previousSubmission = JSON.parse(actionData);
  const prevAddressData: {
    Address: string;
    State: string;
    LGA: string;
    City: string;
    LCDA: string;
    BranchRegion: string;
    PreferredBranch: string;
  } = previousSubmission;

  // ######

  const { addressData, handleAddress } = useAddress();
  const { city, state, lga, lcda } = useAppSelector(
    (state) => state.onboarding
  );

  useEffect(() => {
    handleAddress();
    // eslint-disable-next-line
  }, []);

  const getStates = () =>
    addressData?.alatLocationResponse?.countryModel?.stateList?.map(
      (option: any) => ({
        name: option?.name,
        id: option?.id,
        key: option?.id,
      })
    );
  const getLGAs = () =>
    addressData?.alatLocationResponse?.countryModel?.lgaList
      .filter((lga: any) => lga?.stateId === selectedState?.id)
      ?.map((option: any) => ({
        name: option?.name,
        id: option?.lgaId,
        key: option?.lgaId,
      }));
  const getCities = () =>
    addressData?.alatLocationResponse?.countryModel?.cityList
      .filter((city: any) => city?.stateId === selectedState?.id)
      ?.map((option: any) => ({
        name: option?.name,
        id: option?.id,
        key: option?.id,
      }));
  const getLCDAs = () =>
    addressData?.alatLocationResponse?.countryModel?.lcdaList
      .filter((lcda: any) => lcda?.lgaId === selectedLga?.id)
      ?.map((option: any) => ({
        name: option?.name,
        id: option?.lcdaId,
        key: option?.lcdaId,
      }));

  const handleSelectedState = (option: Option) => {
    setSelectedState(option);
  };

  const handleSelectedLga = (option: Option) => {
    setSelectedLga(option);
  };

  useEffect(() => {
    if (resubmittedResponse) {
      navigate("/pending-actions/successful-resubmission", {
        state: { title: "Business Address" },
      });
    }
  }, [resubmittedResponse]);

  const splitAddress = prevAddressData?.Address?.split(":");
  const buildingNumber = splitAddress?.length === 4 ? splitAddress[0] : "";
  const street = splitAddress?.length === 4 ? splitAddress[1] : "";
  const town = splitAddress?.length === 4 ? splitAddress[2] : "";
  const landmark = splitAddress?.length === 4 ? splitAddress[3] : "";

  return (
    <DashboardWrapper>
      {(loading || submitLoading) && (
        <PageLoader message="Submitting Business Address" />
      )}
      <PendingActionsWrapper cardTitle="Business Address">
        <div className="w-full max-w-[700px] mx-auto">
          <div className="md:w-full h-full">
            <div className="w-full px-10 mx-auto">
              <div className="bg-[#A90836]/10 text-lib-alat-dark-red p-2 rounded-md text-sm my-2">
                {reason?.[0]}
              </div>
              <Formik
                initialValues={{
                  operationalAddress: prevAddressData?.Address || "",
                  city: prevAddressData?.City || "",
                  state: prevAddressData?.State || "",
                  lga: prevAddressData?.LGA || "",
                  lcda: prevAddressData?.LCDA || "",
                  branchRegion: prevAddressData?.BranchRegion || "",
                  preferredBranch: prevAddressData?.PreferredBranch || "",
                  town: town || "",
                  street: street || "",
                  buildingNumber: buildingNumber || "",
                  landmark: landmark || "",
                }}
                onSubmit={async (values) => {
                  setLoading(true);
                  const address = `${values?.buildingNumber.replaceAll(":", "")}:${values?.street.replaceAll(":", "")}:${values?.town.replaceAll(":", "")}:${values?.landmark.replaceAll(":", "")}`;
                  const updatedActionData = JSON.stringify({
                    Address: address,
                    State: values?.state,
                    City: values?.city,
                    LGA: values?.lga,
                    LCDA: values?.lcda,
                    BranchRegion: values?.branchRegion,
                    PreferredBranch: values?.preferredBranch,
                  });
                  const payload = {
                    actionData: updatedActionData,
                    pendingAction,
                    pendingActionId,
                    cacRegNumber,
                  };

                  try {
                    handleResubmittedDocuments(payload);
                  } catch (error) {}
                  setLoading(false);
                }}
                validateOnChange
                validationSchema={BusinessAddressSchema}
              >
                {({
                  handleChange,
                  handleBlur,
                  errors,
                  isSubmitting,
                  isValid,
                  values,
                }) => (
                  <Form className="flex flex-col gap-5">
                    <TextInput
                      id="building_number_input"
                      label="Building Number"
                      name="buildingNumber"
                      placeHolder="Building Number"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.buildingNumber}
                      error={
                        typeof errors?.buildingNumber === "string"
                          ? errors.buildingNumber
                          : ""
                      }
                      // isDisabled={populateAddress}
                    />
                    <TextInput
                      id="street_input"
                      label="Street"
                      name="street"
                      placeHolder="Street name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.street}
                      error={
                        typeof errors?.street === "string" ? errors.street : ""
                      }
                      // isDisabled={populateAddress}
                    />
                    <StateDropdown
                      id="state_dropdown"
                      label="State"
                      placeholder="--Select State--"
                      options={getStates() || []}
                      name="state"
                      value={values.state || state}
                      onChange={handleChange}
                      onSelectOption={handleSelectedState}
                      // disabled={populateAddress}
                    />
                    <StateDropdown
                      id="city_dropdown"
                      label="City"
                      placeholder="--Select City--"
                      options={getCities() || []}
                      name="city"
                      value={values.city || city}
                      onChange={handleChange}
                      onSelectOption={() => {}}
                      // disabled={populateAddress}
                    />

                    <TextInput
                      id="town_input"
                      label="Town"
                      name="town"
                      placeHolder=""
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.town}
                      error={
                        typeof errors?.town === "string" ? errors.town : ""
                      }
                      // isDisabled={populateAddress}
                    />

                    <StateDropdown
                      id="lga_dropdown"
                      label="Select LGA"
                      placeholder="--Select LGA--"
                      options={getLGAs() || []}
                      name="lga"
                      value={values.lga || lga}
                      onChange={handleChange}
                      onSelectOption={handleSelectedLga}
                      // disabled={populateAddress}
                    />

                    <div
                      className={`${
                        selectedState?.name !== "Lagos State" ? "hidden" : ""
                      }`}
                    >
                      <StateDropdown
                        id="lcda_dropdown"
                        label="LCDA"
                        placeholder="--Select LCDA--"
                        options={getLCDAs() || []}
                        name="lcda"
                        value={values.lcda || lcda}
                        onChange={handleChange}
                        onSelectOption={() => null}
                        // disabled={populateAddress}
                      />
                    </div>
                    <TextInput
                      id="landmark_input"
                      label="Landmark"
                      name="landmark"
                      placeHolder=""
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.landmark}
                      error={
                        typeof errors?.landmark === "string"
                          ? errors.landmark
                          : ""
                      }
                      // isDisabled={populateAddress}
                    />
                    <FormikButton
                      id="proceed_btn"
                      labelText={"Proceed"}
                      containerVariant={
                        "w-full my-4 h-12 rounded-md flex justify-center"
                      }
                      isDisabled={!isValid}
                      isLoading={isSubmitting}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </PendingActionsWrapper>
    </DashboardWrapper>
  );
};

export default BusinessAddressUpload;
