import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { FormikButton } from "alat-components-library";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import { useCorporateDebitCardController } from "controllers/Cards/CorporateDebitCardController";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { formatNumberToCurrency, getOption } from "utils/helperFunctions";
import {
  setActiveCardRequestScreen,
  useCardState,
} from "react-wrappers/stores/redux/cards/slices";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import { useAddress } from "hooks/apis/FetchLocation/useAddress";
import { useState } from "react";
import {
  useBranches,
  useCities,
  useGetStates,
  useLgas,
} from "hooks/apis/FetchLocation/useBankLocation";
import {
  PasswordInput,
  TextInput,
} from "../../../alat-components-library/Inputs";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import PageLoader from "components/Loader/PageLoader";

const ThirdScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { amount, cardRequestPayload, loading } = useCardState();
  const auth = useAuthState();
  const { handleFinalSubmit } = useCorporateDebitCardController();
  const authType = () => {
    if (auth?.user?.hasTransactionPin) return "pin";
    if (auth?.user?.hasSoftToken && !auth?.user?.hasTransactionPin)
      return "soft";
    return "hard";
  };

  return (
    <div className="w-full">
      {loading.includes("POST_SUBMIT_CARD_REQUEST") && <PageLoader />}
      <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              onClick={() => {
                dispatch(setActiveCardRequestScreen(1));
              }}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            />
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="banktransfer_heading"
            >
              Payment Summary
            </div>
            <div></div>
          </div>
        </div>
        <hr />
        <Formik
          initialValues={{
            softToken: "",
            hardTokenOtp: "",
            hardTokenPin: "",
            pin: "",
          }}
          onSubmit={handleFinalSubmit}
          validateOnChange
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
            resetForm,
          }) => (
            <Form>
              <div>
                <div className="flex flex-wrap justify-center gap-4 ">
                  <div className="text-alat-grey relative w-full lg:max-w-sm mb-2 pb-5">
                    <div className="bg-white h-56 rounded-lg border px-4 pt-1 mt-4">
                      <div className="border-b py-4 flex justify-between items-center">
                        <p className="text-sm">Card Fee</p>
                        <p className="text-sm">
                          {formatNumberToCurrency({
                            number:
                              amount[cardRequestPayload.deliveryOption - 1]
                                .cardFees,
                            precision: 2,
                          })}
                        </p>
                      </div>
                      <div className="border-b py-4 flex justify-between items-center">
                        <p className="text-sm">Delivery</p>
                        <p className="text-sm">
                          {formatNumberToCurrency({
                            number:
                              amount[cardRequestPayload.deliveryOption - 1]
                                .deliveryFee,
                            precision: 2,
                          })}
                        </p>
                      </div>
                      <div className="border-b py-4 flex justify-between items-center">
                        <p className="text-sm">VAT</p>
                        <p className="text-sm">
                          {formatNumberToCurrency({
                            number:
                              amount[cardRequestPayload.deliveryOption - 1].vat,
                            precision: 2,
                          })}
                        </p>
                      </div>
                      <div className="py-4 flex justify-between items-center">
                        <p className="text-sm">Total Charge</p>
                        <p className="text-sm">
                          {formatNumberToCurrency({
                            number: cardRequestPayload.totalDebitAmount,
                            precision: 2,
                          })}
                        </p>
                      </div>
                    </div>
                    <div className="py-4 space-y-4">
                      {authType() === "pin" && (
                        <PasswordInput
                          name={"pin"}
                          label={"Enter PIN"}
                          placeHolder={""}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.pin}
                          hasError={errors?.pin && touched?.pin}
                          error={errors?.pin}
                          iconStyle={{
                            wrapper: "",
                            icon: "w-[3.5vh] h-[3.5vh]",
                          }}
                          maxLength={4}
                        />
                      )}
                      {authType() === "soft" && (
                        <TextInput
                          id="token_input"
                          name={"softToken"}
                          type={"password"}
                          label={"Enter Soft Token"}
                          placeHolder={""}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.softToken}
                          hasError={errors?.softToken && touched?.softToken}
                          error={errors?.softToken}
                          maxLength={6}
                        />
                      )}
                      {authType() === "hard" && (
                        <>
                          <PasswordInput
                            name={"hardTokenPin"}
                            label={"Enter Hard Token PIN"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.hardTokenPin}
                            hasError={
                              errors?.hardTokenPin && touched?.hardTokenPin
                            }
                            error={errors?.hardTokenPin}
                            iconStyle={{
                              wrapper: "",
                              icon: "w-[3.5vh] h-[3.5vh]",
                            }}
                            maxLength={4}
                          />
                          <TextInput
                            id="token_input"
                            name={"hardTokenOtp"}
                            type={"password"}
                            label={"Enter Hard Token OTP"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.hardTokenOtp}
                            hasError={
                              errors?.hardTokenOtp && touched?.hardTokenOtp
                            }
                            error={errors?.hardTokenOtp}
                            maxLength={6}
                          />
                        </>
                      )}
                    </div>
                    <div className="relative w-full py-6 mb-2  flex items-center justify-center">
                      <FormikButton
                        id="proceed"
                        labelText="Proceed"
                        isDisabled={!isValid && !dirty}
                        containerVariant={`w-full h-[48px] rounded-md flex justify-center item-center`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default ThirdScreen;
