import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoansState } from "../interfaces";
import { RootState } from "../store";
import { useAppSelector } from "../../../../hooks/useReduxHook";

const dataObject = {
  loading: [],
  prequalifiedLoanAmount: 0,
  loanRequestId: "",
  prequalifiedLoansList: {
    gender: "string",
    prequalifiedLoans: [],
  },
  selectedPrequalifiedLoan: {
    maxAmount: 0,
    minAmount: 0,
    intrRatePerMonth: 0,
    tenor: 0,
    charges: 0,
  },
  newLoansFormValues: {
    amount: 0,
    estimatedMonthlyPayment: 0,
  },
  selectedLoanDocument: {
    title: "",
    htmlUrl: "",
  },
  selectedGoBackRoute: "",
  smeLoanOptions: {
    annualIncome: [],
    typeOfFacility: [],
    freqOfRepayment: [],
    maritalStatus: [],
    idType: [],
  },
  guarantorsFormValues: {
    title: "",
    firstName: "",
    lastName: "",
    nextOfKin: "",
    maritalStatus: "",
    nextOfKinResidentialAddress: "",
    nextOfKinRelationship: "",
  },
  professionalInformationFormValues: {
    placeOfEmployment: "",
    annualIncome: "",
    employerAddress: "",
    phoneNumber: "",
    position: "",
    lengthOfTimeWithPresentEmployer: "",
    previousEmployer: "",
    homeAddress: "",
    ownProperty: "",
    isRented: "",
    isMortgaged: "",
    howlongLivingThere: "",
  },
  borrowersInfoFormValues: {
    wemaBankBranchAccountIsResident: "",
    accountNumber: "",
    creditAvailed: "",
    facilityType: "",
    intendedTenor: "",
    facilityPurpose: "",
    repaymentSource: "",
    frequencyOfRepayment: "",
    securityProposed: "",
    ownershipofSecurityProposed: "",
    relationshipOfBorrower: "",
    idCardType: "",
    documentNumber: "",
    idCardUrl: "",
    issueDate: "",
    expiryDate: "",
  },
  assetsDataFormValues: {
    scheduleOfRealEstatesOwned: "",
    scheduleOfSecuritiesOwned: "",
    scheduleOfAutomobileOwned: "",
    scheduleOfRecievables: "",
    scheduleOfSecuritiesPledgedAsCollateral: "",
  },
  personalFinancialInfoFormValues: {
    income: 0,
    basicSalary: 0,
    bank: "",
    allowances: "",
    allowanceValue: 0,
    otherIncome: "",
    otherIncomeValue: 0,
    others: "",
    othersValue: 0,
    automobileValue: 0,
    totalIncome: 0,
    taxes: 0,
    personalExpenses: 0,
    othersExpenses: "",
    otherExpensesValue: 0,
    totalExpenses: 0,
    netIncome: 0,
    totalLiabilityNetworth: 0,
  },
  statementOfWorthResponse: {
    httpStatusCode: 0,
    responseCode: 0,
    responseMessage: {
      title: "",
      actionMessage: "",
    },
    hasError: false,
    data: {
      loanRequestId: "",
      purposeReference: "",
    },
  },
  disbursementResponse: {
    httpStatusCode: 0,
    responseCode: 0,
    responseMessage: {
      title: "",
      actionMessage: "",
    },
    hasError: false,
    data: {
      loanRequestId: "",
      purposeReference: "",
    },
  },
  disbursementWithOtpResponse: {
    httpStatusCode: 0,
    responseCode: 0,
    responseMessage: {
      title: "",
      actionMessage: "",
    },
    hasError: false,
    data: {
      loanRequestId: "",
      purposeReference: "",
    },
  },
  loanHistory: [],
  liquidationResponse: {
    httpStatusCode: 0,
    responseCode: 0,
    responseMessage: {
      title: "",
      actionMessage: "",
    },
    hasError: false,
    data: {
      purposeReference: "",
      loanAccount: "",
      debitAccount: "",
    },
  },
  liquidationWithOtpResponse: {
    httpStatusCode: 0,
    responseCode: 0,
    responseMessage: {
      title: "",
      actionMessage: "",
    },
    hasError: false,
    data: {
      purposeReference: "",
      loanAccount: "",
      debitAccount: "",
    },
  },
  docsAccepted: [],
  loanRepaymentSchedule: {
    totalPrincipal: 0,
    totalInterest: 0,
    totalRepayment: 0,
    monthlySchedule: [],
    loanCurrency: "string",
  },
} as LoansState;

export const initialState: LoansState = { ...dataObject };

export const loansSlice = createSlice({
  name: "loans",
  initialState,

  reducers: {
    resetLoansState: (): LoansState => initialState,
    setLoadingState: (state: LoansState, action: PayloadAction<any>): void => {
      state.loading = action.payload;
    },
    setPrequalifiedLoanAmount: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.prequalifiedLoanAmount = action.payload;
    },
    setPrequalifiedLoansList: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.prequalifiedLoansList = action.payload;
    },
    setSelectedPrequalifiedLoans: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.selectedPrequalifiedLoan = action.payload;
    },
    setNewLoansFormValues: (state: LoansState, action: PayloadAction<any>) => {
      state.newLoansFormValues = action.payload;
    },
    setSmeLoanOptions: (state: LoansState, action: PayloadAction<any>) => {
      state.smeLoanOptions = action.payload;
    },
    setSelectedLoanDocument: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.selectedLoanDocument = action.payload;
    },
    setSelectedGoBackRoute: (state: LoansState, action: PayloadAction<any>) => {
      state.selectedGoBackRoute = action.payload;
    },
    setGuarantorsFormValues: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.guarantorsFormValues = action.payload;
    },
    setProfessionalInfoFormValues: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.professionalInformationFormValues = action.payload;
    },

    setBorrowersInfoFormValues: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.borrowersInfoFormValues = action.payload;
    },
    setAssetsDataFormValues: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.assetsDataFormValues = action.payload;
    },
    setPersonalFinancialInfoFormValues: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.personalFinancialInfoFormValues = action.payload;
    },
    setStatementOfWorthResponse: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.statementOfWorthResponse = action.payload;
    },
    setDocsAccepted: (state: LoansState, action: PayloadAction<any>) => {
      state.docsAccepted = action.payload;
    },
    setDisbursementResponse: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.disbursementResponse = action.payload;
    },
    setDisbursementWithOtpResponse: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.disbursementWithOtpResponse = action.payload;
    },
    setLiquidationResponse: (state: LoansState, action: PayloadAction<any>) => {
      state.liquidationResponse = action.payload;
    },
    setLiquidationResponseWithOtp: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.liquidationWithOtpResponse = action.payload;
    },
    setLoanHistory: (state: LoansState, action: PayloadAction<any>) => {
      state.loanHistory = action.payload;
    },
    setLoanRequestId: (state: LoansState, action: PayloadAction<any>) => {
      state.loanRequestId = action.payload;
    },
    setLoanRepaymentSchedule: (
      state: LoansState,
      action: PayloadAction<any>
    ) => {
      state.loanRepaymentSchedule = action.payload;
    },
  },
});

// Selectors
const selectLoans = (state: RootState) => state.loans;

// Reducers and actions
export const {
  resetLoansState,
  setLoadingState,
  setPrequalifiedLoanAmount,
  setPrequalifiedLoansList,
  setSelectedPrequalifiedLoans,
  setNewLoansFormValues,
  setSelectedLoanDocument,
  setSelectedGoBackRoute,
  setGuarantorsFormValues,
  setProfessionalInfoFormValues,
  setAssetsDataFormValues,
  setBorrowersInfoFormValues,
  setPersonalFinancialInfoFormValues,
  setStatementOfWorthResponse,
  setDocsAccepted,
  setDisbursementResponse,
  setLiquidationResponse,
  setDisbursementWithOtpResponse,
  setLiquidationResponseWithOtp,
  setLoanRequestId,
  setLoanHistory,
  setSmeLoanOptions,
  setLoanRepaymentSchedule,
} = loansSlice.actions;

//App Redux State
export const useLoansState = () => useAppSelector(selectLoans);

export default loansSlice.reducer;
