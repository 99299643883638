import { ReactComponent as BackArrow } from "alat-components-library/assets/images/icons/back-arrow-two.svg";
import { Textarea } from "flowbite-react";
import { useAppDispatch } from "hooks/useReduxHook";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useNotificationState } from "react-wrappers/stores/redux/notifications/slices";
import { NotificationStatusEnum } from "utils/enums";
import { NotificationStatusBadge } from "./NotificationStatusBadge";
import { ApproverBadge } from "./approverBadge";

export const PendingTransactionDetail = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, userRole } = useAuthState();
  const { notificationsDetails } = useNotificationState();
  const status = searchParams.get("status");
  const isApprover =
    user?.role?.toLowerCase() === "approver" ||
    user?.role?.toLowerCase() === "initiatorapprover";

  return (
    <div className="lg:px-20 space-y-4 mt-5">
      <div className="flex items-center">
        <h2 className="text-2xl font-bold">Funds Transfer Request</h2>
      </div>
      <div className="md:px-20">
        <div className="bg-white w-full mx-auto h-[1000px] mt-14">
          <div className="flex py-2 justify-between items-center">
            <BackArrow
              id="back_btn"
              className="ml-5 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <h2 className="text-xl">Overview</h2>
            <div></div>
          </div>
          <hr />

          <div className="flex px-10 gap-5 pl-10 my-10">
            <div className="w-full flex items-center justify-between p-3">
              <p className="text-md font-semibold leading-[21.96px] tracking-[2%] text-lib-alat-black">
                {notificationsDetails?.transactionType}
              </p>
              <NotificationStatusBadge status={status} />
            </div>
          </div>
          {status == NotificationStatusEnum["PendingApproval"] ? (
            <ApproverBadge
              label={isApprover ? "Initiator:" : "Verifier:"}
              approverFullName={
                isApprover
                  ? notificationsDetails?.initiatorFullname
                  : notificationsDetails?.verifiersNames?.map((n: string) => n)
              }
              approverUserName={
                isApprover
                  ? notificationsDetails?.initiatorUsername
                  : notificationsDetails?.verifierUserName
              }
            />
          ) : status !== NotificationStatusEnum["PendingVerification"] &&
            notificationsDetails?.approversNames?.length > 0 ? (
            <ApproverBadge
              label={isApprover ? "Initiator:" : "Approver:"}
              approverFullName={
                isApprover
                  ? notificationsDetails?.initiatorFullname
                  : notificationsDetails?.approversNames?.map((n: string) => n)
              }
              approverUserName={
                isApprover
                  ? notificationsDetails?.initiatorUsername
                  : notificationsDetails?.approverUserName
              }
            />
          ) : status !== NotificationStatusEnum["PendingVerification"] &&
            notificationsDetails?.approversNames?.length == 0 ? (
            <ApproverBadge
              label={isApprover ? "Initiator:" : "Verifier:"}
              approverFullName={
                isApprover
                  ? notificationsDetails?.initiatorFullname
                  : notificationsDetails?.verifiersNames?.map((n: string) => n)
              }
              approverUserName={
                isApprover
                  ? notificationsDetails?.initiatorUsername
                  : notificationsDetails?.approverUserName
              }
            />
          ) : (
            ""
          )}

          <hr />
          <div className="pl-10 text-lg text-[#333333] font-semibold my-10">
            Beneficiary Details
          </div>

          <div className="flex gap-10 p-3 px-10 rounded">
            <table className="w-[500px]">
              <tbody className="text-sm">
                <tr>
                  <td className="text-lib-alat-dark-red">Name:</td>
                  <td className=" py-2" id="groupname_text">
                    {notificationsDetails?.destinationAccountName}
                  </td>
                </tr>
                <tr>
                  <td className="text-lib-alat-dark-red">Bank:</td>
                  <td className=" py-2" id="numberOfRecipients_text">
                    {notificationsDetails?.destinationBankName}
                  </td>
                </tr>
                <tr>
                  <td className="text-lib-alat-dark-red">Account Number:</td>
                  <td className=" py-2" id="numberOfRecipients_text">
                    {notificationsDetails?.destinationAccountNumber}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr />
          <div className="px-10 py-10">
            <h3 className="text-lg text-[#333333] font-semibold">
              Transaction Summary
            </h3>
          </div>
          <div className="border mb-5 w-11/12 mx-auto rounded-lg overflow-x-auto">
            <table className=" w-full px-20 mx-auto">
              <tbody>
                <tr className="border-b bg-gray-100 rounded-t-lg">
                  <td className="px-4 py-2">Transaction type</td>
                  <td className="px-4 py-2">Account to be debited</td>
                  <td className="px-4 py-2">Principal Amount</td>
                  <td className="px-4 py-2">Transaction Charges</td>
                  <td className="px-4 py-2">Total Amount</td>
                </tr>
                <tr>
                  <td className="px-4 py-2" id="transaction-type_text">
                    {notificationsDetails?.transactionType}
                  </td>
                  <td className="px-4 py-2" id="sourceAccountNumber_text">
                    {notificationsDetails?.sourceAccountNumber}
                  </td>
                  <td className="px-4 py-2" id="totalAmount_text">
                    {notificationsDetails?.currency}{" "}
                    {Number(notificationsDetails?.amount).toFixed(2)}
                  </td>
                  <td className="px-4 py-2" id="totalCharge_text">
                    {notificationsDetails?.currency}{" "}
                    {Number(notificationsDetails?.transactionFee).toFixed(2)}
                  </td>
                  <td className="px-4 py-2" id="totalAmountAndCharge_text">
                    {notificationsDetails?.currency}{" "}
                    {(
                      Number(notificationsDetails?.amount) +
                      Number(notificationsDetails?.transactionFee)
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          {status == NotificationStatusEnum["Rejected"] && (
            <div className="px-10 py-10">
              <p className="text-md leading-[21.96px] tracking-[2%] mt-2 p-3">
                REASON FOR REJECTION
              </p>

              <Textarea
                disabled
                value={notificationsDetails?.remark}
                rows={4}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
