import { Button, Card } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as FourUserIcon } from "assets/svgs/Illustration.svg";
import Spinners from "components/Spinner/Spinner";
import { useAirtimeHandleRefresh } from "hooks/apis/AirtimeAndData/useAirtimePresetAmount";
import { useEffect } from "react";
import { TransactionStatusEnum } from "utils/enums";
import PageLoader from "components/Loader/PageLoader";

const ConfirmOtp = () => {
  const {
    AirtimeHandleRefreshData,
    AirtimeHandleRefreshLoading,
    AirtimeHandleRefresh,
  } = useAirtimeHandleRefresh();
  const location = useLocation();
  const correlationId = location.state?.AirtimeRequestData?.data?.correlationId;
  const navigate = useNavigate();
  const handleRefresh: any = () => {
    AirtimeHandleRefresh(correlationId);
  };
  useEffect(() => {
    if (AirtimeHandleRefreshData?.status === TransactionStatusEnum.Success) {
      return navigate("/Airtime/ConfirmTrans", {
        state: { AirtimeHandleRefreshData, correlationId },
      });
    }
    if (AirtimeHandleRefreshData?.status === TransactionStatusEnum.Failed) {
      return navigate("/Airtime/FailedTrans", {
        state: { AirtimeHandleRefreshData },
      });
    }
    if (AirtimeHandleRefreshData?.status === TransactionStatusEnum.Processing) {
      handleRefresh();
    }
  }, [AirtimeHandleRefreshLoading, AirtimeHandleRefreshData]);
  const handleGotoDashboard = () => {
    navigate("/user-dashboard");
  };

  return (
    <div className="w-full">
      <Card className="bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <hr />
        <div className="flex flex-col items-center">
          {AirtimeHandleRefreshLoading && <PageLoader />}
          <div className="w-full lg:max-w-sm mb-2">
            <div className="flex justify-center mb-2">
              <FourUserIcon fill="inherit" />
            </div>
            <h5 className="text-center text-orange font-bold text-2xl mb-1">
              Thank You!
            </h5>
            <p className="text-center font-bold text-lg mb-1">
              Your transaction is being processed.
            </p>
            <div className="bg-gray-200 p-2 rounded-md text-center mb-2">
              <p className="text-sm">
                If you are not redirected in 20 seconds, use the refresh button
                below to manually check for your transaction status.
              </p>
            </div>
            <div className="flex items-center justify-center mt-2 space-x-2">
              <Button
                id="proceed-to-dashboard_btn"
                onClick={handleRefresh}
                className="w-1/2 bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary hover:border hover:border-primary text-white"
              >
                Refresh
              </Button>
              <Button
                id="proceed-to-dashboard_btn"
                onClick={handleGotoDashboard}
                className="w-1/2 bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary hover:border hover:border-primary text-white"
              >
                Proceed to Dashboard
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default ConfirmOtp;
