import { PrimaryButton } from "alat-components-library";
import { useNavigate } from "react-router-dom";
import { Card } from "flowbite-react";
import { ReactComponent as ErrorIcon } from "../../../../assets/icons/error-two-icon.svg";

const ErrorScreen = ({ setActiveTab, screenName }: any) => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-8 pt-6" id="error_screen">
      <div className="col-span-1"></div>
      <Card className="w-[768px] bg-white dark:border-gray-200 mx-auto px-6">
        <div>
          <div className="text-center flex justify-center mt-10">
            <ErrorIcon />
          </div>
          <div className="text-center text-2xl font-semibold mt-6">
            Transaction Failed
          </div>
          <h3 className="text-center text-md font-normal text-black mt-10">
            Please try again
          </h3>
          <div className="flex justify-center flex-row gap-4 mb-40 mt-10">
            <PrimaryButton
              id="return-to-dashboard_btn"
              labelText={"Return to Dashboard"}
              variant="font-medium text-xs text-lib-alat-red"
              containerVariant="w-[188px] h-[45px] rounded-md flex justify-center cursor-pointer bg-white"
              handleClick={() => navigate("/user-dashboard")}
            />
            <PrimaryButton
              id="try-again_btn"
              labelText={"Try again"}
              variant="font-medium text-xs text-white"
              containerVariant="w-[188px] h-[45px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red"
              handleClick={() => setActiveTab(`upload-${screenName}-tab`)}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ErrorScreen;
