import { FormikButton, TextInput } from "alat-components-library";
import { ReactComponent as PendingIcon } from "assets/svgs/pending-icon.svg";
import { ReactComponent as SuccessIcon } from "assets/svgs/success-icon.svg";
import { Formik, Form } from "formik";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import CenterModal from "alat-components-library/Modal/CenterModal";
import PageLoader from "components/Loader/PageLoader";
import { toast } from "react-toastify";
import { refereeValidationSchema } from "../../validation/pendingActionsValidation";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PendingActionsWrapper from "../../components/PendingActionsWrapper";
import { useRefereeFormController } from "controllers/PendingActions/useRefereeFormController";
import { ToastNotify } from "helpers/toastNotify";

export const RefereeForm = () => {
  const {
    editData,
    verifyRefereeAccountLoading,
    verifyRefereeAccountError,
    handleAddReferee,
    getBanks,
    setWemaAccount,
    wemaAccount,
    onboardingData,
    refereeAccountValidation,
    changeRefereeLoading,
    verifyRefereeAccountData,
    successModal,
    errorModal,
    setErrorModal,
    newEmail,
    navigate,
  } = useRefereeFormController();

  const handleAccountValidation = (e: any) => {
    if (e.target.value.length === 10) {
      if (
        e.target.value === onboardingData?.refereePayload[0].accountNumber ||
        e.target.value === onboardingData?.refereePayload[1].accountNumber
      ) {
        if (editData?.accountNumber) {
          refereeAccountValidation(e?.target?.value);
        } else {
          ToastNotify({
            type: "error",
            message: "You can not use the same referee twice",
          });
        }
      } else {
        refereeAccountValidation(e?.target?.value);
      }
    }
  };
  return (
    <DashboardWrapper>
      <PendingActionsWrapper cardTitle="Rejected Referees">
        <div className="w-full h-full">
          {verifyRefereeAccountLoading && (
            <PageLoader message="verifying account" />
          )}
          {changeRefereeLoading && (
            <PageLoader message="Updating Referee Information" />
          )}
          <div className="w-full">
            <div className="md:w-[409px] w-full mx-auto py-5">
              <p className="text-center pb-7">
                Your referees can either be an Enterprise or Limited Liability
                Company
              </p>
              <div className="px-2 md:px-0">
                <Formik
                  initialValues={{
                    bankName: editData?.bank || "",
                    firstName: editData?.firstName || "",
                    lastName: editData?.lastName || "",
                    emailAddress: editData?.emailAddress || "",
                    phoneNumber: editData?.phoneNumber || "",
                    accountNumber: editData?.accountNumber || "",
                  }}
                  onSubmit={handleAddReferee}
                  validationSchema={refereeValidationSchema}
                  validateOnChange
                >
                  {({
                    handleChange,
                    handleBlur,
                    errors,
                    touched,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    dirty,
                    values,
                    setFieldValue,
                  }) => (
                    <Form className="flex flex-col gap-4 pb-5">
                      <StateDropdown
                        id="referee-bankname_dropdown"
                        name="bankName"
                        label="Referee bank"
                        placeholder="-- Select Your Referee's Bank --"
                        options={getBanks()}
                        onChange={handleChange}
                        onSelectOption={(e) => {
                          e?.id.toLowerCase().includes("035")
                            ? setWemaAccount(true)
                            : setWemaAccount(false);
                        }}
                        error={errors.bankName}
                        value={values?.bankName}
                      />

                      <div
                        className={`flex flex-col gap-4 transition-all duration-500
                                          ${
                                            wemaAccount
                                              ? "max-h-0 overflow-hidden"
                                              : "max-h-[1000px]"
                                          }`}
                      >
                        <TextInput
                          id="firstname_input"
                          name="firstName"
                          handleChange={handleChange}
                          label="Referee First Name"
                          placeHolder="Enter your referee's firtname"
                          error={
                            typeof errors.firstName === "string"
                              ? errors.firstName
                              : ""
                          }
                          hasError={touched.firstName && errors.firstName}
                          value={values?.firstName}
                        />
                        <TextInput
                          id="lastname_input"
                          name="lastName"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          label="Referee Last Name"
                          placeHolder="Enter your referee's lastname"
                          error={
                            typeof errors.lastName === "string"
                              ? errors.lastName
                              : ""
                          }
                          hasError={touched.lastName && errors.lastName}
                          value={values?.lastName}
                        />
                        <TextInput
                          id="email_input"
                          name="emailAddress"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          label="Referee Email Address"
                          placeHolder="Enter your referee's email address"
                          error={
                            typeof errors.emailAddress === "string"
                              ? errors.emailAddress
                              : ""
                          }
                          hasError={touched.emailAddress && errors.emailAddress}
                          value={values?.emailAddress}
                        />
                        <TextInput
                          id="phone_input"
                          name="phoneNumber"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          label="Referee Phone Number"
                          placeHolder="Enter your referee's phone number"
                          maxLength={11}
                          error={
                            typeof errors.phoneNumber === "string"
                              ? errors.phoneNumber
                              : ""
                          }
                          hasError={touched.phoneNumber && errors.phoneNumber}
                          value={values?.phoneNumber}
                        />
                      </div>

                      <div
                        className={`transition-all duration-500
                                          ${
                                            !wemaAccount
                                              ? "max-h-0 overflow-hidden"
                                              : "max-h-[1000px]"
                                          }`}
                      >
                        <TextInput
                          id="accountnumber_input"
                          name="accountNumber"
                          handleBlur={handleBlur}
                          handleChange={(e) => {
                            // console.log("The errors: ", errors);
                            handleChange(e);
                            handleAccountValidation(e);
                          }}
                          placeHolder="Enter your referee's account number"
                          label="Account Number"
                          minLength={10}
                          maxLength={10}
                          error={
                            typeof errors.accountNumber === "string"
                              ? errors.accountNumber
                              : ""
                          }
                          hasError={
                            touched.accountNumber && errors.accountNumber
                          }
                          value={values?.accountNumber}
                          success={verifyRefereeAccountData?.lastName}
                        />
                      </div>
                      <FormikButton
                        id="proceed_btn"
                        labelText={editData?.id ? "Submit" : "Proceed"}
                        containerVariant="w-full h-12 rounded-md flex justify-center"
                        isDisabled={
                          !isValid ||
                          verifyRefereeAccountLoading ||
                          (wemaAccount && !verifyRefereeAccountData?.lastName)
                        }
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>

          {/*Success Info Account*/}
          {errorModal && (
            <CenterModal
              icon={<PendingIcon />}
              title={verifyRefereeAccountError?.title}
              info={verifyRefereeAccountError?.actionMessage}
              handleClick={() => setErrorModal(false)}
              handleClose={() => setErrorModal(false)}
              handleClick2={() => setErrorModal(false)}
              btnText="Okay"
              showCloseIcon
            />
          )}

          {/*Success Info Account*/}
          {successModal && (
            <CenterModal
              icon={<SuccessIcon />}
              title="Success!"
              info={`A referee verification link has been sent to the email ${newEmail}, Kindly ensure your referee responds via the link sent.`}
              handleClick={() => navigate("/referee-list")}
              handleClose={() => navigate("/referee-list")}
              handleClick2={() => navigate("/referee-list")}
              btnText="Okay"
              btnText2={"Return to dashboard"}
              showCloseIcon
            />
          )}
        </div>
      </PendingActionsWrapper>
    </DashboardWrapper>
  );
};
