import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card } from "flowbite-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as BankIcon } from "../../../assets/svgs/banks-icon.svg";
import { ReactComponent as OneUserIcon } from "../../../assets/svgs/one-user-icon.svg";
import { ReactComponent as FourUserIcon } from "../../../assets/svgs/four-user-icon.svg";
import { useAppDispatch } from "hooks/useReduxHook";
import { resetPaymentsData } from "react-wrappers/stores/redux/payments/slices";
import { ReusableCard } from "components/reusables/Page/ReusableCard";

export interface PopUpProps {
  onGenerateReceipt: any;
}

const BulkTransferMain = ({ tabsRef }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetPaymentsData());
  }, []);
  return (
    <div className="flex w-full items-center justify-center">
      <ReusableCard
        variant="md:w-[768px] w-full"
        cardTitle="Bulk Transfer"
        handleBackNavigation={() => tabsRef.current?.setActiveTab(0)}
        showGoBackIcon
      >
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 text-[#464646] p-3">
          <Card
            className="cursor-pointer md:w- hover:bg-primary hover:text-white hover:fill-white  dark:bg-white dark:border-[#E4DFDF] border-[#E4DFDF] dark:shadow-white"
            onClick={() => navigate("/payments/bulk-transfer/make-transfer")}
          >
            <BankIcon fill="inherit" />

            <h1 className="text-[14px]">Make Transfer</h1>
            <h2 className="text-[12px]">
              Make single and multiple money transfers
            </h2>
          </Card>

          <Card
            className="cursor-pointer hover:bg-primary hover:text-white hover:fill-white  dark:bg-white dark:border-[#E4DFDF] border-[#E4DFDF] dark:shadow-white"
            onClick={() =>
              navigate("/payments/bulk-transfer/transaction-history")
            }
          >
            <FourUserIcon fill="inherit" />

            <h1 className="text-[14px]">Check for transaction history</h1>
            <h2 className="text-[12px]">Confirm status of past transactions</h2>
          </Card>
        </div>
      </ReusableCard>
    </div>
  );
};

export default BulkTransferMain;
