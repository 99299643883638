import React, { useState } from "react";
import { ToastNotify } from "../../../helpers/toastNotify";
import { useApiRequest } from "../../useApiRequest";
import { useAppDispatch } from "../../useReduxHook";
import {
  setLoadingState,
  setCustomerCardsForActivation,
  setActiveCustomerCards,
} from "../../../react-wrappers/stores/redux/cards/slices";
import { GET_ALL_CARDS } from "utils/apis/routes";
import { CardState } from "react-wrappers/stores/redux/interfaces";

export const useGetCustomerCards = () => {
  const makeRequest = useApiRequest();
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<boolean>(false);

  const handleFetchCustomerCards = async () => {
    dispatch(setLoadingState(["getting-customer-cards"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_ALL_CARDS,
        originType: "identity",
      });
      // console.log("response", response);
      if (response?.data?.httpStatusCode === 200) {
        setStatus(true);
        dispatch(
          setCustomerCardsForActivation(
            response?.data?.data?.cards?.filter(
              (card: CardState["selectedCardForActivation"]) =>
                card?.cardStatus?.toLowerCase() === "inactive"
            )
          )
        );
        dispatch(
          setActiveCustomerCards(
            response?.data?.data?.cards?.filter(
              (card: CardState["selectedCardForActivation"]) =>
                card?.cardStatus?.toLowerCase() === "active"
            )
          )
        );
      } else {
        dispatch(setCustomerCardsForActivation([]));
        dispatch(setActiveCustomerCards([]));
        setStatus(false);
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.responseMessage?.title}. ${response?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };
  return {
    handleFetchCustomerCards,
    status,
  };
};
