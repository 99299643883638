import { useState } from "react";
import axiosStatus from "utils/apis/axios/errorStatus";
import { ToastNotify } from "helpers/toastNotify";
import { POST_DUDCHEQUE_REJECTION } from "utils/apis/routes";
import { useApiRequest } from "hooks/useApiRequest";

export const useRejectDudCheque = () => {
  //Local States
  const [userDudRejectionStatus, setDudRejectionStatus] =
    useState<boolean>(false);
  const [userDudRejectionLoading, setDudRejectionLoading] =
    useState<boolean>(false);
  const [userDudRejectionError, setDudRejectionError] = useState<any>({
    actionMessage: "",
    title: "",
  });
  const [userDudRejectionData, setDudRejectionData] = useState<any>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleDudRejection = async (correlationId: string) => {
    // console.log(payload)
    setDudRejectionError({
      actionMessage: "",
      title: "",
    });
    setDudRejectionLoading(true);
    setDudRejectionStatus(false);
    setDudRejectionData("");
    try {
      const res = await makeRequest({
        method: "post",
        route: POST_DUDCHEQUE_REJECTION(correlationId),
        originType: "onboarding",
      });
      // console.log(res?.data?.data);
      //Map the payload
      if (res?.status !== 200) {
        if (res?.data?.data?.hasError) {
          setDudRejectionError(res?.data?.data?.responseMessage);
          ToastNotify({
            type: "error",
            message: res?.data?.data?.responseMessage?.title,
            position: "top-right",
          });
        } else {
          axiosStatus(res?.data);
        }
      } else {
        setDudRejectionStatus(true);
      }
      setDudRejectionLoading(false);
    } catch (error) {
      setDudRejectionLoading(false);
    }
  };

  return {
    userDudRejectionStatus,
    userDudRejectionLoading,
    userDudRejectionError,
    userDudRejectionData,
    handleDudRejection,
  };
};
