import {
  PrimaryButton,
  ReactSuspenceWrapper,
} from "../../alat-components-library";
import DashboardWrapper from "../../components/Layouts/DashboardWrapper";
import React from "react";
import { ProfileTabs } from "./components/ProfileTabs";
import { useAuthState } from "../../react-wrappers/stores/redux/auth/slices";
import { ProfileMenu } from "./components/ProfileMenu";
import TestUser from "../../assets/svgs/profile-avatar.svg";
import { useNavigate } from "react-router-dom";

export const ViewProfile = () => {
  const auth = useAuthState();
  const navigate = useNavigate();
  return (
    <ReactSuspenceWrapper>
      <DashboardWrapper>
        <div className="lg:px-20 space-y-4 mt-5">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold">Profile Management</h2>
          </div>
          <ProfileTabs title={"My Profile"}>
            <div className="flex items-start gap-10 space-y-4">
              <ProfileMenu user={auth?.user} />

              <div
                className="bg-white flex-initial md:w-[752px] w-full rounded-lg"
                id="my-profile_card"
              >
                <div className="w-full bg-white rounded-t-md border-b">
                  <div className="flex items-center justify-center p-3">
                    <h6 className="text-lg font-medium tracking-tight leading-[126.5%]">
                      My Profile
                    </h6>
                  </div>
                </div>

                <div
                  className="w-full bg-white rounded-b-md p-5 flex flex-col items-center justify-center space-y-5"
                  style={{
                    boxShadow: "0px 4px 9px -8px rgba(0, 0, 0, 0.13)",
                  }}
                >
                  <div className="flex items-center flex-col gap-3">
                    <div>
                      <img
                        src={TestUser}
                        className="rounded-full w-[76px] h-[76px]"
                        alt="profile-pic"
                      />
                    </div>
                    <PrimaryButton
                      id="change-pic_btn"
                      labelText={"Change Picture"}
                      variant="font-medium text-xs text-white"
                      containerVariant="w-full rounded-md flex justify-center border border-lib-alat-red cursor-pointer bg-lib-alat-red p-2"
                      handleClick={() => navigate("")}
                    />
                  </div>
                  <div className="text-center space-y-2">
                    <p
                      className="font-medium text-lg tracking-tighter leading-[140%] text-lib-alat-black"
                      id="fullname_text"
                    >
                      {auth?.user?.fullName}
                    </p>
                    <p
                      className="text-sm leading-[170%] tracking-tight"
                      id="username_text"
                    >
                      {auth?.user?.userName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ProfileTabs>
        </div>
      </DashboardWrapper>
    </ReactSuspenceWrapper>
  );
};
