import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  setAllCollectionMerchantItem,
  setAllCollectionMerchants,
  setAllCollections,
  setDynamicFormItems,
  setLoadingState,
  setPostValidationItem,
  setSimpleFormItems,
} from "react-wrappers/stores/redux/collections/slices";
import { setValidateBillResponse } from "react-wrappers/stores/redux/payments/slices";
import {
  GET_ALL_COLLECTIONS,
  GET_COLLECTION_MERCHANTS,
  GET_COLLECTION_MERCHANT_ITEMS,
  GET_DYNAMIC_FORM,
  GET_DYNAMIC_FORM_NEXT,
  GET_SIMPLE_FORM,
  POST_FORM_VALIDATION,
  SUBMIT_PAYMENT,
} from "utils/apis/routes/collections";
import { TransactionTypeEnum } from "utils/enums";
import { ToastNotify } from "../../../helpers/toastNotify";
import { useApiRequest } from "../../useApiRequest";
import { useAppDispatch } from "../../useReduxHook";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";

export const useGetCollections = () => {
  const makeRequest = useApiRequest();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [postValidation, setPostvalidation] = useState<any>();
  const [collectionMerchants, setColectionMerchants] = useState<any>([]);
  const [collectionMerchantItems, setColectionMerchantItems] = useState<any>(
    []
  );
  const [simpleForm, setSimpleForm] = useState<any>([]);
  const [dynamicForm, setDynamicForm] = useState<any>([]);
  const [collections, setCollections] = useState<any>([]);
  const [collectionPayment, setCollectionPayment] = useState<any>();
  const { userRole } = useAuthState();

  const handleFetchCollections = async () => {
    dispatch(setLoadingState(["fetch-collections"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_ALL_COLLECTIONS,
        originType: "paymentservice",
      });
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setAllCollections(response?.data?.data?.categories));
        setCollections(response?.data?.data?.categories);
      } else {
        dispatch(setAllCollections({}));
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  const handleFetchCollectionMerchants = async (id: any) => {
    dispatch(setLoadingState(["fetch-collection-merchants"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_COLLECTION_MERCHANTS(id),
        originType: "paymentservice",
      });
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setAllCollectionMerchants(response?.data?.data?.merchants));
        setColectionMerchants(response?.data?.data?.merchants);
      } else {
        dispatch(setAllCollectionMerchants({}));
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  const handleFetchCollectionMerchantItems = async (id: any) => {
    dispatch(setLoadingState(["fetch-collection-merchants"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_COLLECTION_MERCHANT_ITEMS(id),
        originType: "paymentservice",
      });
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setAllCollectionMerchantItem(response?.data?.data?.items));
        setColectionMerchantItems(response?.data?.data?.items);
      } else {
        dispatch(setAllCollectionMerchantItem({}));
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  const handleFetchDynamicForm = async (id: any) => {
    dispatch(setLoadingState(["fetch-dynamic-form"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_DYNAMIC_FORM(id),
        originType: "paymentservice",
      });
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setDynamicFormItems(response?.data?.data));
        setDynamicForm(response?.data?.data);
      } else {
        dispatch(setDynamicFormItems({}));
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  const handleFetchDynamicFormNext = async (payload: any) => {
    dispatch(setLoadingState(["fetch-dynamic-form"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_DYNAMIC_FORM_NEXT(payload),
        originType: "paymentservice",
      });
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setDynamicFormItems(response?.data?.data));
        setDynamicForm(response?.data?.data);
      } else {
        dispatch(setDynamicFormItems({}));
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  const handleFetchSimpleForm = async (id: any) => {
    dispatch(setLoadingState(["fetch-simple-form"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_SIMPLE_FORM(id),
        originType: "paymentservice",
      });
      // debugger;
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setSimpleFormItems(response?.data?.data));
        setSimpleForm(response?.data?.data);
      } else {
        dispatch(setSimpleFormItems({}));
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  const handlePostValidation = async (payload: any) => {
    dispatch(setLoadingState(["post-form-validation"]));
    try {
      const response = await makeRequest({
        method: "post",
        route: POST_FORM_VALIDATION,
        body: payload,
        originType: "paymentservice",
      });
      // debugger;
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setPostValidationItem(response?.data?.data));
        setPostvalidation(response?.data?.data);
        if (response?.data?.data?.isValid === false) {
          ToastNotify({
            type: "error",
            message: "Invalid Details",
            position: "top-right",
          });
        }
      } else {
        dispatch(setPostValidationItem({}));
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  const handleSubmitPayment = async (payload: any, isPinOtp: boolean) => {
    dispatch(setLoadingState(["submit-payment"]));
    try {
      const response = await makeRequest({
        method: "post",
        route: SUBMIT_PAYMENT,
        body: payload,
        originType: "identity",
      });
      if (response?.data?.httpStatusCode === 200) {
        dispatch(setValidateBillResponse(response?.data?.data));
        setCollectionPayment(response?.data?.data);
        // redirect to the otp validation screen
        if (isPinOtp)
          return navigate(`/bills/payment/pay-bill/otp`, {
            state: {
              AirtimeRequestData: response.data,
            },
          });

        if (userRole?.toLowerCase() === "initiator") {
          return navigate("/bills/collections/success");
        }
        return navigate("/bills/payment/pay-bill/status");
      } else {
        dispatch(setValidateBillResponse({}));
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  return {
    handleFetchCollections,
    handleFetchCollectionMerchants,
    handleFetchCollectionMerchantItems,
    handleFetchDynamicForm,
    handleFetchSimpleForm,
    handleFetchDynamicFormNext,
    handlePostValidation,
    handleSubmitPayment,
    collections,
    collectionMerchants,
    collectionMerchantItems,
    dynamicForm,
    simpleForm,
    postValidation,
    collectionPayment,
  };
};
