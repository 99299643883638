import React, { ReactNode, useEffect } from "react";
import { ReactSuspenceWrapper } from "../../../alat-components-library";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useLocation, useSearchParams } from "react-router-dom";
import { setRefereeView } from "react-wrappers/stores/redux/app/slices";
import { useAppDispatch } from "hooks/useReduxHook";

interface Props {
  children: ReactNode;
}
const HomeWrapper: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Set referee view value
    if (location.pathname.includes("reference-letter")) {
      const type = searchParams.get("type");
      if (type && type.toLowerCase() === "individual") {
        dispatch(setRefereeView(true));
      } else {
        dispatch(setRefereeView(false));
      }
    }
  }, [dispatch, location.pathname, searchParams]);

  return (
    <ReactSuspenceWrapper>
      <Navbar />
      <div className="lg:pt-20 md:pt-24 pt-20 w-full">{children}</div>
      <Footer />
    </ReactSuspenceWrapper>
  );
};

export default HomeWrapper;
