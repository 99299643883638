import {
  setBulkHistoryFilter,
  setOneBulkTransactionID,
  setOneBulkTransactionList,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { TableOne } from "alat-components-library";
import { Pagination } from "components/Dashboard/User/Pagination";
import PageLoader from "components/Loader/PageLoader";
import { ShowOneBulkTransactionBody } from "./ShowOneBulkTransationBody";
import { ReactComponent as DownloadIcon } from "../../../../assets/svgs/download-cloud-two.svg";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { POST_FETCH_ONE_BULK_TRANSFER_ACTION } from "react-wrappers/stores/redux/payments/actions";
import { useAppDispatch } from "hooks/useReduxHook";
import { useExportBulkTransactions } from "hooks/apis/Payment/useExportBulkTransactions";
import dayjs from "utils/dayjsLib";
import { downloadPDFFile } from "utils/helperFunctions";

export const TableHeaders = [
  { id: 1, title: "Description", classList: "text-[12px] w-[200px]" },
  { id: 2, title: "Date", classList: "text-[12px] w-[100px] text-left" },
  { id: 3, title: "Amount (₦‎)", classList: "text-[12px] w-[120px] text-left" },
  { id: 4, title: "Status", classList: "text-[12px] w-[32px] text-left" },
  { id: 5, title: "", classList: "text-[12px] w-[120px] text-left" },
];

const OneBulkTransactionTable = () => {
  const paymentState = usePaymentState();
  const dispatch = useAppDispatch();

  const { oneBulkTransactionList, bulkHistoryFilter, oneBulkTransactionID } =
    usePaymentState();
  // console.log(oneBulkTransactionList, "live")

  const {
    handleExportBulkTransferSummary,
    exportBulkData,
    exportBulkLoading,
    exportBulkStatus,
  } = useExportBulkTransactions();

  const handleMenuChange = (value: any) => {
    // console.log(value)
  };

  const handleViewNext = () => {
    dispatch(
      setBulkHistoryFilter({
        ...bulkHistoryFilter,
        pageSize: bulkHistoryFilter?.pageSize,
        pageNumber: bulkHistoryFilter?.pageNumber + 1,
        skip: bulkHistoryFilter?.skip + 10,
        take: bulkHistoryFilter?.take + 10,
      })
    );
    const payload = {
      startDate: bulkHistoryFilter?.startDate,
      endDate: bulkHistoryFilter?.endDate,
      accountNumber: bulkHistoryFilter?.accountNumber,
      reference: oneBulkTransactionID,
      pageSize: bulkHistoryFilter?.pageSize,
      pageNumber: bulkHistoryFilter?.pageNumber + 1,
    };

    dispatch(POST_FETCH_ONE_BULK_TRANSFER_ACTION(payload))
      .unwrap()
      .then((res: any) => {
        // Redirect to the success screen
        if (res.status === 200) {
          dispatch(setOneBulkTransactionID(oneBulkTransactionID));
          dispatch(setOneBulkTransactionList(res.data.data));
        }
      });
  };

  const handleViewPrev = () => {
    dispatch(
      setBulkHistoryFilter({
        ...bulkHistoryFilter,
        pageSize: bulkHistoryFilter?.pageSize,
        pageNumber: bulkHistoryFilter?.pageNumber - 1,
        skip: bulkHistoryFilter?.skip - 10,
        take: bulkHistoryFilter?.take - 10,
      })
    );
    const payload = {
      startDate: bulkHistoryFilter?.startDate,
      endDate: bulkHistoryFilter?.endDate,
      accountNumber: bulkHistoryFilter?.accountNumber,
      reference: oneBulkTransactionID,
      pageSize: bulkHistoryFilter?.pageSize,
      pageNumber: bulkHistoryFilter?.pageNumber - 1,
    };

    dispatch(POST_FETCH_ONE_BULK_TRANSFER_ACTION(payload))
      .unwrap()
      .then((res: any) => {
        // Redirect to the success screen
        if (res.status === 200) {
          dispatch(setOneBulkTransactionID(oneBulkTransactionID));
          dispatch(setOneBulkTransactionList(res.data.data));
        }
      });
  };

  useEffect(() => {
    if (exportBulkStatus) {
      downloadPDFFile(
        exportBulkData?.url,
        `bulk_transfer_summary_${dayjs().format("DD/MM/YYYY")}`
      );
    }
  }, [exportBulkData?.url, exportBulkStatus]);

  const isLoading =
    paymentState.loading.includes("POST_FETCH_ALL_BULK_TRANSFER") ||
    paymentState.loading.includes("POST_FETCH_ONE_BULK_TRANSFER") ||
    exportBulkLoading;

  return (
    <>
      {isLoading && <PageLoader />}
      <div className="flex justify-center pt-6">
        <div className="w-full pb-14">
          <div className="bg-white dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
            <div className="flex items-center justify-between px-4 py-2">
              <ChevronLeftIcon
                className="h-6 w-6 text-[#333333] cursor-pointer"
                onClick={async () => {
                  dispatch(setOneBulkTransactionID(null));
                  dispatch(setOneBulkTransactionList(null));
                }}
              />
              <div className="text-[#4D4D4D] text-xs font-bold text-center">
                {oneBulkTransactionID
                  ? `Bulk Transfer - ${oneBulkTransactionID}`
                  : "Transactions"}
              </div>

              <div
                onClick={() =>
                  handleExportBulkTransferSummary({
                    accountNumber: bulkHistoryFilter?.accountNumber,
                    reference: bulkHistoryFilter?.reference,
                  })
                }
                className="bg-lib-alat-red cursor-pointer w-[95px] flex justify-center h-[29px] text-white text-xs items-center space-x-2 rounded-md"
              >
                <DownloadIcon className="#ffffff" />
                <span>Export</span>
              </div>
            </div>
            <hr />
            <TableOne
              header={TableHeaders}
              paginationData={
                oneBulkTransactionList?.bulkTransferSummaryDetails
              }
              handleShowNext={handleViewNext}
              handleShowPrevious={handleViewPrev}
              isStriped
              isHover={false}
              body={
                <ShowOneBulkTransactionBody
                  list={oneBulkTransactionList?.bulkTransferSummaryDetails}
                  handleMenuChange={handleMenuChange}
                />
              }
            />
          </div>
          {oneBulkTransactionList &&
            oneBulkTransactionList?.bulkTransferSummaryDetails?.length > 0 && (
              <Pagination
                paginationVariant={
                  "h-20 w-full flex flex-row justify-between items-center px-4"
                }
                dataToShow={{
                  sortedData:
                    oneBulkTransactionList?.bulkTransferSummaryDetails,
                  hasNextPage: oneBulkTransactionList?.hasNextPage,
                  currentPage: oneBulkTransactionList?.pageNumber,
                  totalItems: oneBulkTransactionList?.totalItemCount,
                  count: 10,
                  skip: 1,
                  take: 10,
                }}
                handleNext={handleViewNext}
                handlePrev={handleViewPrev}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default OneBulkTransactionTable;
