import { useNavigate } from "react-router-dom";
import DashboardWrapper from "../../components/Layouts/DashboardWrapper";
import HeaderComponent from "components/Collections/Reuseables/HeaderComponent";
import PageLoader from "components/Loader/PageLoader";
import { useState, useEffect } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card, Label } from "flowbite-react";
import { Formik, Form, validateYupSchema } from "formik";
import { FormikButton, TextInput } from "alat-components-library";
import { useRemitaState } from "react-wrappers/stores/redux/remita/slices";
import { useValidateWithRRR } from "hooks/apis/Remita/useValidateWithRRR";
import { validateRRRValidationSchema } from "./validations/validateRRR.validations";

const WithRRR = () => {
  const navigate = useNavigate();
  const { loading } = useRemitaState();
  const { handleValidateRRR } = useValidateWithRRR();
  const isLoading = loading.includes("validate-rrr");
  const initialValues: any = {};

  return (
    <DashboardWrapper>
      {!!isLoading && (
        <PageLoader message={`Validating details, please wait...`} />
      )}
      <div className="container-fluid 2xl:container mx-auto ">
        <div className="container-fluid 2xl:container mx-auto">
          <main className="max-h-screen overflow-auto">
            <div className="">
              <div className="max-w-6xl mx-auto">
                <div className="rounded-3xl mb-5">
                  <HeaderComponent categoryName="With RRR" />
                  <div className="w-full">
                    {!!isLoading && (
                      <PageLoader
                        message={`Validating details, please wait...`}
                      />
                    )}
                    <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
                      <div>
                        <div className="flex items-center justify-between">
                          <div
                            className="text-[#4D4D4D] text-xl text-center"
                            id="banktransfer_heading"
                          >
                            Remita RRR
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <hr />
                      <Formik
                        initialValues={initialValues}
                        onSubmit={handleValidateRRR}
                        validationSchema={validateRRRValidationSchema}
                        validateOnChange
                      >
                        {({
                          handleChange,
                          handleBlur,
                          errors,
                          touched,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                          dirty,
                          values,
                          setFieldValue,
                        }) => (
                          <Form>
                            <div>
                              <div className="flex flex-wrap justify-center gap-4 ">
                                <div className="text-alat-grey relative w-full lg:max-w-sm mb-2">
                                  <div className="relative w-full lg:max-w-sm mb-4">
                                    <TextInput
                                      id={`rrr`}
                                      name={`rrr`}
                                      type={`text`}
                                      label={`RRR Code`}
                                      placeHolder={"Enter RRR code"}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      value={values?.rrr}
                                      hasError={errors?.rrr && touched?.rrr}
                                      error={`RRR Code is required`}
                                    />
                                  </div>
                                </div>

                                <div className="relative w-full  flex items-center justify-center">
                                  <FormikButton
                                    id="proceed"
                                    labelText="Proceed"
                                    containerVariant={
                                      "w-full lg:max-w-sm h-[7vh] my-[5vh] rounded-md flex justify-center items-center"
                                    }
                                    isDisabled={!isValid && !dirty}
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default WithRRR;
