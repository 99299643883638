import { useState, useEffect } from "react";
import { usePaymentStatus } from "hooks/apis/Onboarding/usePaymentStatus";
import { useVirtualAccount } from "hooks/apis/Onboarding/useVirtualAccount";

import CenteredModal from "../../../../alat-components-library/Modal/CenterModal";
import PendingIcon from "../../../../assets/gifs/refresh-modal.gif";
import { ReactComponent as SuccessIcon } from "../../../../assets/svgs/success-icon.svg";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import { useNavigate } from "react-router-dom";
import AlatPay from "../../../../assets/svgs/alatpay-logo.svg";

import { saveOnboardingData } from "react-wrappers/stores/redux/onboarding/slices";
import PageLoader from "../../../../components/Loader/PageLoader";
import BankTransfer from "./BankTransfer";
import {
  CardIcon,
  CheckButton,
  TransferIcon,
} from "pages/Onboarding/CompletePayment/PaymentContainer";
import { usePayWithCard } from "../../../../hooks/apis/Onboarding/usePayWithCard";
import CardPayment from "../../../../pages/Onboarding/CompletePayment/CardPayment";
import { formatNumberToCurrency } from "utils/helperFunctions";
import { useCardValidation } from "../../../../hooks/apis/Onboarding/useCardValidation";
import ProccedCardPayment from "pages/Onboarding/CompletePayment/ProccedCardPayment";

export const CompletePaymentModal = () => {
  const [successModal, setSuccessModal] = useState(false);
  const [pendingModal, setPendingModal] = useState(false);
  const [accountGenerated, setAccountGenerated] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const [active, setActive] = useState("card");
  const [showMastarcardModal, setShowMastercardModal] = useState(false);

  const navigate = useNavigate();
  const onboardingState = useAppSelector((state) => state.onboarding);
  const reg_number = useAppSelector(
    (state) =>
      state.onboarding.businessPayload?.infoRequest?.businessRequest?.reg_number
  );
  const dispatch = useAppDispatch();
  const { user } = useAuthState();

  const {
    userVirtualAccountError,
    userVirtualAccountData,
    handleVirtualAccount,
  } = useVirtualAccount();

  const {
    userPaymentStatusData,
    handlePaymentStatus,
    userPaymentStatusLoading,
  } = usePaymentStatus();

  // Initiate card payment
  const {
    handleCardValidation,
    userCardValidationLoading,
    userCardValidationData,
  } = useCardValidation();

  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(onboardingState?.paymentAccount);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  // Validate Payment
  const validatePayment = () => {
    let transactionId =
      active === "card"
        ? (userCardValidationData as string)
        : onboardingState?.paymentAccount;
    handlePaymentStatus(transactionId, user?.correlationId, active);

    if (userPaymentStatusData?.data?.data?.paid) {
      setResetTimer(true);
      setPendingModal(false);
      setSuccessModal(true);
    } else {
      setSuccessModal(false);
      setPendingModal(true);
    }
  };

  const {
    userPayWithCardStatus,
    userPayWithCardLoading,
    userPayWithCardData,
    handlePayWithCard,
  } = usePayWithCard();

  useEffect(() => {
    if (
      !userVirtualAccountData?.data?.data?.virtualAccount &&
      !accountGenerated
    ) {
      handleVirtualAccount({
        businessRegistrationRequest: {
          reg_number:
            user?.regNumber || reg_number.replace(/[a-zA-Z]/g, "") || "",
          businessType: user?.businessType || 0,
        },
        accountType: parseInt(user?.accountType || onboardingState.accountType),
        correlationId: user?.correlationId || "",
      });
    }
    if (!userVirtualAccountError) {
      setAccountGenerated(true);
      dispatch(
        saveOnboardingData({
          paymentAccount: userVirtualAccountData?.data?.data?.virtualAccount,
          paymentAmount: userVirtualAccountData?.data?.data?.amount,
        })
      );
    }
    // eslint-disable-next-line
  }, [userVirtualAccountData]);

  const payWithardResponse = userPayWithCardData?.data?.data;
  useEffect(() => {
    if (payWithardResponse) {
      setShowMastercardModal(true);
    }
  }, [payWithardResponse]);

  // Close modal and validate payment
  const closeMasterCardModal = () => {
    // Validate card payment
    validatePayment();
    setShowMastercardModal(false);
  };
  const amount = formatNumberToCurrency({
    number: onboardingState?.paymentAmount ?? 0,
  });
  return (
    <div
      className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-95 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm"
      style={{ margin: 0 }}
    >
      {userPaymentStatusLoading && <PageLoader message="" />}
      {/* <center className="z-10">
        <div className="flex justify-center items-center w-96 rounded-xl p-10 relative mb-4 bg-white">
          <div className="w-full"> */}
      {/* master Card payment modal  */}
      {showMastarcardModal && (
        <div className="max-w-[868px] mx-auto  md:gap-6  p-4 md:p-6 bg-[#F9F9F9] rounded-[10px] w-full">
          <ProccedCardPayment
            handleClose={closeMasterCardModal}
            resData={payWithardResponse}
          />
        </div>
      )}
      {!showMastarcardModal && (
        <div className="max-w-[868px] mx-auto  flex flex-wrap md:gap-6 gap-3 p-4 md:p-6 bg-[#F9F9F9] rounded-[10px]">
          <aside className="grow basis-[180px] min-[1336px]:max-w-[202px]">
            <header>
              <h4 className="text-base text-[#9D0F3E] pb-2 font-semibold">
                Payment Methods
              </h4>
              <p className="text-sm text-[#000000]">How do you want to pay?</p>
            </header>
            <nav className="pt-6 flex flex-wrap gap-3">
              <CheckButton
                text="Pay with Card"
                isActive={active === "card"}
                onChange={() => setActive("card")}
                icon={
                  <CardIcon bg={active === "card" ? "#9D0F3E" : "#808080"} />
                }
              />
              <CheckButton
                text="Pay with Bank Transfer"
                onChange={() => setActive("transfer")}
                isActive={active === "transfer"}
                icon={
                  <TransferIcon
                    bg={active === "transfer" ? "#9D0F3E" : "#808080"}
                  />
                }
              />
            </nav>
          </aside>
          <div className="w-full grow basis-[338px] max-h-[80vh] overflow-auto">
            <div className=" bg-white rounded-[5px] md:pb-6 md:pt-[14px] p-3 md:px-6">
              <header className="pb-3 mb-5 border-b border-[#C4C4C4] flex justify-between gap-3 flex-wrap">
                <div className="">
                  <img src={AlatPay} alt="" />
                </div>
                <div>
                  <p className="max-w-[130px] text-right text-sm text-[#6C6C6C]">
                    Pay{" "}
                    <span
                      id="amount_text"
                      className="text-base font-semibold text-[#A90836]"
                    >
                      {amount}
                    </span>{" "}
                    to WEMA BANK
                  </p>
                </div>
              </header>
              {/* Bank transfer  */}
              {active === "transfer" && (
                <BankTransfer
                  handleCopy={handleCopy}
                  copied={copied}
                  resetTimer={resetTimer}
                  validatePayment={validatePayment}
                />
              )}
              {/* Card Payment  */}
              {active === "card" && (
                <CardPayment
                  handleCardValidation={handleCardValidation}
                  userCardValidationLoading={userCardValidationLoading}
                  userCardValidationData={userCardValidationData}
                  userPayWithCardStatus={userPayWithCardStatus}
                  userPayWithCardLoading={userPayWithCardLoading}
                  userPayWithCardData={userPayWithCardData}
                  handlePayWithCard={handlePayWithCard}
                />
              )}
            </div>
            <div className="py-5 flex gap-2 items-center justify-center">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.4659 7.66667C14.8246 7.66667 15.1685 7.80714 15.4221 8.05719C15.6757 8.30724 15.8182 8.64638 15.8182 9V15.6667C15.8182 16.0203 15.6757 16.3594 15.4221 16.6095C15.1685 16.8595 14.8246 17 14.4659 17H6.35227C5.99363 17 5.64967 16.8595 5.39607 16.6095C5.14247 16.3594 5 16.0203 5 15.6667V9C5 8.26 5.60852 7.66667 6.35227 7.66667H7.02841V6.33333C7.02841 5.44928 7.38459 4.60143 8.01859 3.97631C8.65259 3.35119 9.51248 3 10.4091 3C10.853 3 11.2927 3.08622 11.7028 3.25373C12.113 3.42125 12.4857 3.66678 12.7996 3.97631C13.1135 4.28584 13.3625 4.6533 13.5324 5.05772C13.7023 5.46214 13.7898 5.89559 13.7898 6.33333V7.66667H14.4659ZM10.4091 4.33333C9.87112 4.33333 9.35519 4.54405 8.97479 4.91912C8.59439 5.29419 8.38068 5.8029 8.38068 6.33333V7.66667H12.4375V6.33333C12.4375 5.8029 12.2238 5.29419 11.8434 4.91912C11.463 4.54405 10.9471 4.33333 10.4091 4.33333Z"
                  fill="#6C6C6C"
                />
                <path
                  d="M14.4659 7.66667C14.8246 7.66667 15.1685 7.80714 15.4221 8.05719C15.6757 8.30724 15.8182 8.64638 15.8182 9V15.6667C15.8182 16.0203 15.6757 16.3594 15.4221 16.6095C15.1685 16.8595 14.8246 17 14.4659 17H6.35227C5.99363 17 5.64967 16.8595 5.39607 16.6095C5.14247 16.3594 5 16.0203 5 15.6667V9C5 8.26 5.60852 7.66667 6.35227 7.66667H7.02841V6.33333C7.02841 5.44928 7.38459 4.60143 8.01859 3.97631C8.65259 3.35119 9.51248 3 10.4091 3C10.853 3 11.2927 3.08622 11.7028 3.25373C12.113 3.42125 12.4857 3.66678 12.7996 3.97631C13.1135 4.28584 13.3625 4.6533 13.5324 5.05772C13.7023 5.46214 13.7898 5.89559 13.7898 6.33333V7.66667H14.4659ZM10.4091 4.33333C9.87112 4.33333 9.35519 4.54405 8.97479 4.91912C8.59439 5.29419 8.38068 5.8029 8.38068 6.33333V7.66667H12.4375V6.33333C12.4375 5.8029 12.2238 5.29419 11.8434 4.91912C11.463 4.54405 10.9471 4.33333 10.4091 4.33333Z"
                  fill="black"
                  fillOpacity="0.2"
                />
              </svg>
              <p className="text-sm text-[#000000]">
                Secured by <span className="font-semibold">ALATPay</span>
              </p>
            </div>

            <div className={`${!pendingModal && "hidden"} z-[99999]`}>
              <CenteredModal
                icon={
                  <div className="">
                    <img src={PendingIcon} alt="" />
                  </div>
                }
                title={"Thank You!"}
                info={"Your transaction is being processed"}
                btnText={"Refresh"}
                showCloseIcon
                handleClick={(e: any) => {
                  validatePayment();
                }}
                handleClose={(e: any) => {
                  setPendingModal(false);
                }}
              >
                <div
                  className={`text-center text-sm text-lib-alat-gray-light bg-[#f5f5f5] my-3 py-3 rounded-lg`}
                >
                  If you are not redirected in 30 seconds, use the refresh
                  button below to manually check your transaction status
                </div>
              </CenteredModal>
            </div>
          </div>
        </div>
      )}
      <div className={`${!successModal && "hidden"} z-[99999]`}>
        <CenteredModal
          icon={
            <div className="">
              <SuccessIcon />
            </div>
          }
          title={"You are all set"}
          info={"Your account is now secure"}
          btnText={"Proceed to dashboard"}
          showCloseIcon
          handleClick={(e: any) => {
            dispatch(
              saveOnboardingData({
                ...onboardingState,
                alatPay: true,
              })
            );
            navigate("/user-dashboard");
          }}
          handleClose={(e: any) => {
            setSuccessModal(false);
            navigate("/user-dashboard");
          }}
        ></CenteredModal>
      </div>
    </div>
  );
};
