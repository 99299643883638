import React from "react";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { ReactComponent as SuccessIcon } from "../../../alat-components-library/assets/images/modals/success-icon.svg";
import { PrimaryButton, ReactSuspenceWrapper } from "alat-components-library";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/useReduxHook";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { pendingActivityType } from "pages/Payments/components/utils";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";

type ApprovedScreenProps = {
  data: any;
  id: any;
};

export const ApprovedScreen = ({ data, id }: ApprovedScreenProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userRole } = useAuthState();
  const { pendingTransactionList } = usePaymentState();

  const requestType = pendingTransactionList?.sortedData?.find(
    (item: any) => item?.correlationId === id
  )?.type;

  return (
    <ReactSuspenceWrapper>
      <DashboardWrapper>
        <div className="lg:px-20 space-y-4 mt-5">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold" id="requestType_text">
              {pendingActivityType(requestType)}
            </h2>
          </div>
          <div className="flex items-center justify-center">
            <ReusableCard
              variant="w-[751px] mx-auto"
              cardTitle={
                requestType === 8 || requestType === 7 ? "" : "Success"
              }
            >
              <div className="flex flex-col justify-center items-center w-full px-6 space-y-8 py-6">
                <SuccessIcon />
                <div className="space-y-4 flex flex-col justify-center items-center md:px-20">
                  <h2 className="text-xl font-semibold tracking-tighter text-center leading-6">
                    {data?.successMessage?.title}
                  </h2>
                  <p
                    className="text-sm text-[#606060] text-center"
                    id="successMessage_text"
                  >
                    {data?.successMessage?.actionMessage}
                  </p>
                  {requestType === 8 &&
                    ["verifier"]?.includes(userRole?.toLowerCase()) && (
                      <p
                        // id={"TransctionSuccessText"}
                        className="text-[14px] text-[#606060] leading-[16.94px] tracking-[-1.1%] text-center bg-[#F5F5F5] p-5"
                      >
                        We will send you a notification as soon as the request
                        is approved
                      </p>
                    )}
                </div>

                <div className="w-full flex flex-col items-center justify-center space-y-5">
                  <PrimaryButton
                    id="nextPendingAction_btn"
                    containerVariant={
                      "px-6 h-10 rounded-md flex justify-center cursor-pointer bg-lib-alat-red"
                    }
                    labelText={"Go to the next pending action"}
                    handleClick={() => {
                      navigate("/payments/pending-transactions");
                    }}
                    variant={"text-white"}
                  />
                  <NavLink
                    to={"/user-dashboard"}
                    className="text-lib-alat-dark-red"
                  >
                    <div className="cursor-pointer">
                      <p
                        className="leading-[28px] tracking-tighter font-semibold text-sm"
                        id="returnToDashboard_btn"
                      >
                        Return to dashboard
                      </p>
                    </div>
                  </NavLink>
                </div>
              </div>
            </ReusableCard>
          </div>
        </div>
      </DashboardWrapper>
    </ReactSuspenceWrapper>
  );
};
