import React, { useState } from "react";
import {
  generateChargeTwo,
  moneyInputFormat,
} from "../../utils/helperFunctions";
import {
  TransactionResponseStatusEnum,
  TransactionTypeEnum,
} from "../../utils/enums";
import {
  IRecipient,
  ISavedGroupList,
  TransactionType,
} from "../../components/Payments/BankTransfer/MultipleTransfer/mutipleTransfer";
import {
  useBankListAndNIPChargeController,
  useMultipleTransferTabController,
} from "../../components/Payments/BankTransfer/MultipleTransfer/controllers/MutipleTransferTabController";
import { ToastNotify } from "../../helpers/toastNotify";
import {
  GET_ACCOUNT_NAME_ENQUIRY_ACTION,
  GET_TRANSACTION_STATUS_ACTION,
  POST_TRANSACTION_VALIDATE_ACTION,
  PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION,
} from "../../react-wrappers/stores/redux/payments/actions";
import {
  setResponseCode,
  setTransferResponseMessage,
} from "react-wrappers/stores/redux/payments/slices";

export const useMultipleTransferController = () => {
  const {
    activeTab,
    setActiveTab,
    dispatch,
    authState,
    navigate,
    allAccounts,
  } = useMultipleTransferTabController();
  const {
    tabsRef,
    bankLists,
    nipCharges,
    savedGroupList,
    savedBeneficiaryList,
  } = useBankListAndNIPChargeController();
  const [isLoading, setisLoading] = useState(false);
  const [showScreen, setShowScreen] = useState(1);
  const [amount, setAmount] = useState({
    withMoneyFormat: "0",
    withNumberFormat: 0,
  });
  const [accountNumber, setAccountNumber] = useState("");
  const [accountValidated, setAccountValidated] = useState(false);
  const [accountValidationError, setAccountValidationError] = useState("");
  const [accountName, setaccountName] = useState("");
  const [selectedBank, setSelectedBank] = useState({
    bankCode: "",
    bankName: "",
  });
  const [recipients, setRecipients] = useState<IRecipient[]>([]);
  const [otp, setOtp] = useState("");
  const [otpMessage, setOtpMessage] = useState("");
  const [authToken, setAuthToken] = useState("");
  // eslint-disable-next-line
  const [description, setDescription] = useState("");
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [showPinError, setShowPinError] = useState("no");
  const [pin, setPin] = useState("");
  const [accountToDebit, setAccountToDebit] = useState({
    accountId: "",
    currency: "",
    balance: 0,
    accountName: "",
    accountNo: "",
  });
  const [recipientMinMaxError, setRecipientMinMaxError] = useState("");
  const [isPinValidated, setIsPinValidated] = useState(false);
  const [pinError, setPinError] = useState("");
  const [correlationId, setCorrelationId] = useState("");
  const [editRecipientIndex, setEditRecipientIndex] = useState(0);
  const [sumTotal, setSumTotal] = useState(0);
  const [totalIsGreaterThanBalance, setTotalIsGreaterThanBalance] =
    useState(false);
  const [transaction, setTransaction] = useState<TransactionType[]>([]);

  const handlePinValidation = async (pin: any, token: string) => {
    setShowPinError("no");
    setIsPinValidated(true);
    setAllowSubmit(true);
    // try {
    //   const body = {
    //     pin,
    //     token,
    //     authorizationType: !token ? 1 : 2,
    //     isSoftToken: authState?.user?.hasSoftToken,
    //   };
    //   dispatch(POST_VALIDATE_PIN_ACTION(body))
    //     .unwrap()
    //     .then((res: any) => {
    //       if (res.status === 200) {
    //         // console.log(res);
    //         setShowPinError("no");
    //         setIsPinValidated(true);
    //         setAllowSubmit(true);
    //       }
    //       if (res.status === 401 || res.status === 400) {
    //         setIsPinValidated(false);
    //         setShowPinError("yes");
    //       }
    //     })
    //     .catch((e: any) => {
    //       setShowPinError("yes");
    //       setIsPinValidated(false);
    //       setPinError(e.response.data.responseMessage.title);
    //     });
    // } catch (e: any) {
    //   setShowPinError("yes");
    //   setIsPinValidated(false);
    //   setPinError(e.response.data.responseMessage.title);
    // }
  };

  const handleChange = (event: {
    target: { name: any; value: any; dataset: any };
  }) => {
    // console.log(event.target.name, event.target.value);
    const getAuthType = event?.target?.dataset.authType;

    if (event.target.name === "AccountNumber") {
      const value = event.target.value.replace(/\D/g, "");
      setAccountNumber(value);
      setAccountValidated(false);
      setAccountValidationError("");
    }
    if (event.target.name === "selectedBank") {
      setSelectedBank(JSON.parse(event.target.value));
      setAccountNumber("");
      setAccountValidated(false);
    }

    if (event.target.name === "amount") {
      const result = moneyInputFormat(event.target.value);
      result && setAmount(result);
    }

    if (event.target.name === "otp") {
      if (event.target.value.length > 6) {
        return;
      }
      const result = event.target.value.replace(/\D/g, "");

      setOtp(result);
      // if (result.length === 4) {
      //   handlePinValidation(result);
      // }
    }
    if (event.target.name === "description") {
      setDescription(event.target.value);
    }
    if (event.target.name === "pin") {
      setAllowSubmit(false);
      setShowPinError("no");
      setAllowSubmit(false);
      const result = event.target.value.replace(/\D/g, "");

      setPin(result);
      if (result.length === 4) {
        if (getAuthType !== "hardTokenPin") {
          return handlePinValidation(result, "");
        }
        setIsPinValidated(true);
        setShowPinError("no");
        return setAllowSubmit(true);
      }
    }
    if (event.target.name === "authToken") {
      const result = event.target.value.replace(/\D/g, "");

      setAllowSubmit(false);

      setAuthToken(result);
      if (result.length === 6) {
        // if (getAuthType !== "hardTokenPin") handlePinValidation("", result);
        // setIsPinValidated(true);
        // setShowPinError("no");
        // return setAllowSubmit(true);
        if (getAuthType !== "hardTokenPin") {
          handlePinValidation("", result);
        } else {
          setIsPinValidated(true);
          setShowPinError("no");
          return setAllowSubmit(true);
        }
      }
      // if (result.length === 4) {
      //   handlePinValidation(result);
      // }
    }
    if (event.target.name === "selectedAccount") {
      setTotalIsGreaterThanBalance(false);
      const convertedAccountDetails = JSON.parse(event.target.value);

      setAccountToDebit(convertedAccountDetails);
      if (convertedAccountDetails.balance < sumTotal) {
        setTotalIsGreaterThanBalance(true);
      }
      // const filterAccounts = accounts.filter(
      //   (account) => account.accountNo !== convertedAccountDetails.accountNo
      // );
      // setShowAccounts(filterAccounts);
    }
    // setFundAccount({ ...fundAccount, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();
    const transferDestinations = recipients.map((one) => {
      // console.log(one, "Single Transaction");
      const transactionFee = one?.transactionFee;
      const singleAmount = moneyInputFormat(one?.amount ?? 0)?.withNumberFormat;
      return {
        beneficiaryAccountNumber: one?.accountNumber,
        beneficiaryName: one?.accountName,
        accountName: one?.accountName,
        beneficiaryBankName: one?.bankName,
        transactionFee: transactionFee,
        beneficiaryBankCode: one?.bankCode,
        amount: singleAmount,
        beneficiaryCurrency: accountToDebit?.currency,
        narration: "",
      };
    });

    const body = {
      sourceAccountNumber: accountToDebit.accountNo,
      sourceAccountName: accountToDebit.accountName,
      sourceAccountCurrencyCode: accountToDebit.currency,
      transactionType: ["initiator", "initiatorapprover"]?.includes(
        authState.userRole?.toLowerCase()
      )
        ? TransactionTypeEnum.MultipleSignatoryMultipleTransfer
        : TransactionTypeEnum.SingleSignatoryMultipleTransfer,
      transferDestinations: transferDestinations,
      multipleTransferGroupName: "",
      transactionPin: pin,
      token: authToken,
      isSoftToken: authState?.user?.hasSoftToken,
      createMultipleTransferGroup: false,
    };

    try {
      setisLoading(true);
      dispatch(POST_TRANSACTION_VALIDATE_ACTION(body))
        .unwrap()
        .then((res: any) => {
          if (res?.data?.data?.responseCode === 21) {
            dispatch(setResponseCode(res?.data?.data?.responseCode));
          }
          // console.log("handle-submit-res", res);
          setCorrelationId(res.data.data.correlationId);
          setisLoading(false);
          // setShowScreen(6);
          dispatch(
            setTransferResponseMessage({
              title: res?.data?.responseMessage?.title,
              actionMessage: res?.data?.responseMessage?.actionMessage,
            })
          );

          if (authState.userRole !== "initiator") {
            // console.log("no cap");
            if (!res.data.data?.hasError) {
              setShowScreen(6);
            } else {
              ToastNotify({
                type: "error",
                message:
                  res?.status >= 500
                    ? `Oops! Something went wrong`
                    : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
              });
            }
          } else {
            // console.log("there is a success");
            if (!res.data.data?.hasError) {
              setShowScreen(10);
            } else {
              ToastNotify({
                type: "error",
                message:
                  res?.status >= 500
                    ? `Oops! Something went wrong`
                    : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
              });
            }
          }
        })
        .catch((e: any) => {
          // console.log(e);
          setisLoading(false);
          setShowScreen(8);
        });

      // setshowScreen(7);
    } catch (e) {
      setisLoading(false);
      setShowScreen(7);
    }
  };

  const handleBackToRecipients = () => {
    tabsRef.current?.setActiveTab(0);
    setShowScreen(2);
  };

  const handleScreenChangeNext = () => {
    if (showScreen === 2 && (recipients.length < 2 || recipients.length > 10)) {
      if (recipients.length < 2) {
        // alert("Add minimum of 2 recipients");
        setRecipientMinMaxError(
          "The number of recipients cannot be less than 2"
        );
      }
      if (recipients.length > 10) {
        // alert("The maximum of recipients you can add is 10");
        setRecipientMinMaxError(
          "The number of recipients cannot be more than 10"
        );
      }
      return;
    }

    setShowScreen(showScreen + 1);
  };
  const handleScreenChangePrev = () => {
    setTotalIsGreaterThanBalance(false);
    setRecipientMinMaxError("");
    // const screenToChange = showScreen++
    if (showScreen === 2) {
      //clear everything
      setAccountNumber("");
      setAccountValidated(false);
      setSelectedBank({
        bankCode: "",
        bankName: "",
      });
    }
    if (showScreen === 3) {
      //clear everything
      setAccountToDebit({
        accountId: "",
        currency: "",
        balance: 0,
        accountName: "",
        accountNo: "",
      });
    }
    if (showScreen === 4) {
      //clear everything
      setAccountToDebit({
        accountId: "",
        currency: "",
        balance: 0,
        accountName: "",
        accountNo: "",
      });
      setPin("");
      setIsPinValidated(false);
      setShowPinError("no");
    }
    setShowScreen(showScreen - 1);
  };
  const handleRefreshTrasaction = async () => {
    try {
      setisLoading(true);
      dispatch(GET_TRANSACTION_STATUS_ACTION(correlationId))
        .unwrap()
        .then((res: any) => {
          // console.log("status-res", res);
          setisLoading(false);
          if (res.status === 200) {
            if (res?.data?.data === null) {
              ToastNotify({
                type: "error",
                message: `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
              });
            } else {
              const isEmpty = res?.data?.data?.transactions?.length;
              const isPendingFound = res?.data.data.transactions.filter(
                (item: any) =>
                  Number(item.status) === TransactionResponseStatusEnum.Pending
              );
              const isSuccessFound = res?.data.data.transactions.filter(
                (item: any) =>
                  Number(item.status) === TransactionResponseStatusEnum.Success
              );

              if (isPendingFound?.length < 1) {
                dispatch(
                  setTransferResponseMessage({
                    title:
                      isSuccessFound?.length > 0
                        ? "Your transaction was successful"
                        : "Your transaction failed",
                    actionMessage: "",
                  })
                );
              }

              if (isPendingFound?.length > 0) {
                ToastNotify({
                  type: "error",
                  message:
                    res?.data?.data === null
                      ? `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`
                      : res?.data?.data?.transactions?.[0]?.statusMessage ?? "",
                });
              }

              if (isPendingFound.length > 0 || isSuccessFound.length > 0) {
                setTransaction(res?.data?.data?.transactions);
                return setShowScreen(isPendingFound.length > 0 ? 6 : 7);
              }
              if (isEmpty < 1) {
                setShowScreen(6);
                ToastNotify({
                  type: "error",
                  message: res?.data?.data?.transactions?.length
                    ? res?.data?.data?.transactions?.[0]?.statusMessage ?? ""
                    : `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
                });
              } else {
                setShowScreen(8);
              }
            }
          } else {
            ToastNotify({
              type: "error",
              message:
                res?.status >= 500
                  ? "Oops! Something went wrong"
                  : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
            });
          }
        })
        .catch((e: any) => {
          setisLoading(false);
          setShowScreen(8);
        });
    } catch (e) {
      setisLoading(false);
      // console.log(e);
      setShowScreen(8);
    }
  };
  const handleAccountValidation = async () => {
    try {
      if (accountNumber.length < 10 || selectedBank.bankCode.length < 3) {
        alert("Select a bank or input your account number");
        return;
      }
      setisLoading(true);
      dispatch(
        GET_ACCOUNT_NAME_ENQUIRY_ACTION({
          accountNumber: accountNumber,
          bankCode: selectedBank.bankCode,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.data?.data?.hasError) {
            setisLoading(false);
            setAccountValidated(false);
            setAccountValidationError(res?.data?.data?.responseMessage.title);
            ToastNotify({
              type: "error",
              message: res?.data?.data?.responseMessage?.title,
            });
          } else {
            setisLoading(false);
            setAccountValidated(true);
            setaccountName(res.data.data.accountName);
          }
        })
        .catch((e: any) => {
          // console.log(e);
          setisLoading(false);
          setAccountValidated(false);
          setAccountValidationError(
            "Unable to validate account, Kindly check the details and try again"
          );
        });

      // setshowScreen(7);
    } catch (e) {
      setisLoading(false);
      setAccountValidated(false);
      setAccountValidationError(
        "Unable to validate account, Kindly check the details and try again"
      );
    }
  };
  const handleAddRecipient = () => {
    if (editRecipientIndex > 0) {
      // setRecipients((oldRecipients) => [...oldRecipients, newRecipient]);
      let transactionFee = 0;
      // console.log(transactionFee, "Transaction Fee1");
      if (selectedBank.bankCode !== "035") {
        transactionFee = generateChargeTwo(amount.withNumberFormat);
      }

      const newRecipient = {
        referenceId: editRecipientIndex,
        accountName: accountName,
        amount: amount.withNumberFormat,
        bankName: selectedBank.bankName,
        bankCode: selectedBank.bankCode,
        transactionFee,
        accountNumber: accountNumber,
      };
      const index = recipients.findIndex(
        (rec) => rec.referenceId === editRecipientIndex
      );
      const copiedRecipients = [...recipients]; // important to create a copy, otherwise you'll modify state outside of setState call
      copiedRecipients[index] = newRecipient;
      setRecipients(copiedRecipients);
      setEditRecipientIndex(0);
    } else {
      let transactionFee = 0;
      // console.log(transactionFee, "Transaction Fee2");
      if (selectedBank.bankCode !== "035") {
        transactionFee = generateChargeTwo(amount.withNumberFormat);
      }

      const newRecipient = {
        referenceId: recipients.length + 1,
        accountName: accountName,
        amount: amount.withNumberFormat,
        bankName: selectedBank.bankName,
        bankCode: selectedBank.bankCode,
        transactionFee: transactionFee,
        accountNumber: accountNumber,
      };
      // const addRecipient = recipients.concat(newRecipient)
      //check if recipient is already added
      const recipientExists = recipients.find(
        (one) => one.accountNumber === accountNumber
      );

      if (!recipientExists) {
        setRecipients((oldRecipients) => [...oldRecipients, newRecipient]);
      } else {
        alert("This recipient has already been added");
      }
    }

    setShowScreen(2);
    setaccountName("");
    setAmount({
      withMoneyFormat: "",
      withNumberFormat: 0,
    });
    setSelectedBank({ bankCode: "", bankName: "" });
    setAccountNumber("");
    setAccountValidated(false);
  };
  const handleAddBeneficiary = (recipient: any) => {
    let transactionFee = 0;
    // console.log(recipient, "Recipient");
    // console.log(recipient, "Transaction Fee3");
    if (recipient?.bankCode !== "035") {
      const tAmount = moneyInputFormat(recipient.amount)?.withNumberFormat;
      transactionFee = generateChargeTwo(tAmount ?? 0);
    }

    const newRecipient = {
      referenceId: recipients.length + 1,
      accountName: recipient.accountName,
      amount: recipient.amount,
      bankName: recipient.bankName,
      bankCode: recipient.bankCode,
      transactionFee,
      accountNumber: recipient.accountNumber,
    };

    // // const addRecipient = recipients.concat(newRecipient)
    // //check if recipient is already added
    const recipientExists = recipients.find(
      (one) => one.accountNumber === newRecipient.accountNumber
    );

    if (!recipientExists) {
      setRecipients((oldRecipients) => [...oldRecipients, newRecipient]);
    } else {
      alert("This beneficiary has already been added");
    }

    tabsRef.current?.setActiveTab(0);
    setShowScreen(2);
    // setaccountName("");
    // setAmount("");
    // setSelectedBank({ bankCode: "", bankName: "" });
    // setAccountNumber("");
    // setAccountValidated(false);
  };
  const handleAddGroup = (group: any) => {
    const existingRecipients = [...recipients];

    const formatNewRecipients = group.transferGroupDetails.map(
      (group: any, index: number) => {
        let transactionFee = 0;
        if (group?.bankCode !== "035") {
          transactionFee = generateChargeTwo(parseFloat(group?.amount) || 0);
        }
        return {
          referenceId: recipients.length + index + 1,
          accountName: group.accountName,
          amount: `${group.amount}`,
          bankName: group.bankName,
          bankCode: group.bankCode,
          transactionFee,
          accountNumber: group.accountNumber,
        };
      }
    );

    //check if the list already has one of the recipients of the select group
    let itExist;
    existingRecipients.forEach((item) => {
      //  formatNewRecipients.some((new) => new.accountNumber)
      itExist = formatNewRecipients.some(
        (arrVal: any) => item.accountNumber === arrVal.accountNumber
      );
    });
    if (!itExist) {
      const newRecipientsList = existingRecipients.concat(formatNewRecipients);
      setRecipients(newRecipientsList);
      tabsRef.current?.setActiveTab(0);
      setShowScreen(2);
    } else {
      tabsRef.current?.setActiveTab(0);
      setShowScreen(2);
      alert("A member of this group has already been added");
    }

    // console.log(checkDuplicates());
  };
  const handleEditRecipient = (recipient: IRecipient) => {
    const result = moneyInputFormat(recipient.amount);
    result && setAmount(result);

    setShowScreen(1);
    setRecipientMinMaxError("");
    setShowPinError("no");
    setIsPinValidated(false);
    setAccountNumber(recipient.accountNumber);
    setaccountName(recipient.accountName);
    setSelectedBank({
      bankCode: recipient.bankCode,
      bankName: recipient.bankName,
    });
    setAccountValidated(true);
    setEditRecipientIndex(recipient.referenceId);
    // handleRemoveRecipient(recipient.index);
    // const newRecipient = {
    //   index: recipient.index,
    //   name: recipient.name,
    //   amount: recipient.amount,
    //   bankName: recipient.bankName,
    //   bankCode: recipient.bankCode,
    //   accountNumber: recipient.accountNumber,
    // };
    // setRecipients((oldRecipients) => [...oldRecipients, newRecipient]);
    // const index = recipients.findIndex((rec) => rec.index === recipient.index);
    // const copiedRecipients = [...recipients]; // important to create a copy, otherwise you'll modify state outside of setState call
    // copiedRecipients[index] = newRecipient;
    // // this.setState({ employees });
    // console.log(newRecipient);
    // console.log(index);
    // console.log(copiedRecipients[index]);
    // console.log(copiedRecipients);
  };
  const handleRemoveRecipient = (referenceId: any) => {
    // console.log(referenceId);
    setRecipients((current) =>
      current.filter((recipient) => recipient.referenceId !== referenceId)
    );
  };
  const handleTryAgain = () => {
    setPin("");
    setOtp("");
    setAllowSubmit(false);
    setShowScreen(4);
    // setAccountNumber("");
    // setSelectedBank({
    //   bankCode: "",
    //   bankName: "",
    // });
    // setRecipients([]);
    // setAmount("0");
    // setSumTotal(0);
    // setaccountName("");
    // setAccountToDebit({
    //   accountId: "",
    //   currency: "",
    //   balance: 0,
    //   accountName: "",
    //   accountNo: "",
    // });
    // setDescription("");
  };
  const handleTransactionBreakdown = () => {
    setShowScreen(9);
  };
  const handlePrev = () => {
    setShowScreen(7);
  };

  const handleValidate = async (event: { preventDefault: () => void }) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();

    const transferDestinations = recipients.map((one) => {
      const transactionFee = one?.transactionFee || 0;
      return {
        beneficiaryAccountNumber: one?.accountNumber,
        beneficiaryName: one?.accountName,
        accountName: one?.accountName,
        beneficiaryBankName: one?.bankName,
        transactionFee,
        beneficiaryBankCode: one?.bankCode,
        amount: moneyInputFormat(one?.amount)?.withNumberFormat,
        beneficiaryCurrency: accountToDebit?.currency,
        narration: "",
      };
    });

    const body = {
      sourceAccountNumber: accountToDebit?.accountNo,
      sourceAccountName: accountToDebit?.accountName,
      sourceAccountCurrencyCode: accountToDebit?.currency,
      transactionType: ["initiator", "initiatorapprover"]?.includes(
        authState.userRole?.toLowerCase()
      )
        ? TransactionTypeEnum.MultipleSignatoryMultipleTransfer
        : TransactionTypeEnum.SingleSignatoryMultipleTransfer,

      transferDestinations: transferDestinations,
      multipleTransferGroupName: "",
      createMultipleTransferGroup: false,
      transactionPin: pin,
      token: authToken,
      isSoftToken: authState?.user?.hasSoftToken,
    };

    try {
      setisLoading(true);
      dispatch(POST_TRANSACTION_VALIDATE_ACTION(body))
        .unwrap()
        .then((res: any) => {
          if (res?.data?.data?.responseCode === 21) {
            dispatch(setResponseCode(res?.data?.data?.responseCode));
          }
          setisLoading(false);
          if (res.status === 200) {
            setCorrelationId(res.data.data.correlationId);
            setOtpMessage(res?.data?.responseMessage?.actionMessage);
            // console.log(res);
            // console.log(res.data.data.correlationId);
            if (
              res?.data?.data?.correlationId &&
              amount.withNumberFormat < 2000000
            ) {
              setShowScreen(5);
            }
          } else {
            ToastNotify({
              type: "error",
              message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
            });
          }
        })
        .catch((e: any) => {
          // console.log(e);
          setisLoading(false);
          // setshowScreen(7);
        });

      // setshowScreen(7);
    } catch (e) {
      setisLoading(false);
    }
  };
  const handleOtpValidationSubmit = async (values: any) => {
    // console.log(values);
    const payload = {
      transactionType: ["initiator", "initiatorapprover"]?.includes(
        authState.userRole?.toLowerCase()
      )
        ? TransactionTypeEnum.MultipleSignatoryMultipleTransfer
        : TransactionTypeEnum.SingleSignatoryMultipleTransfer,
      code: otp,
      correlationId: correlationId,
    };
    // console.log(payload);
    dispatch(PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION(payload))
      .unwrap()
      .then((res: any) => {
        // console.log("test", res);
        dispatch(
          setTransferResponseMessage({
            title: res?.data?.responseMessage?.title,
            actionMessage: res?.data?.responseMessage?.actionMessage,
          })
        );
        // Redirect to the success screen
        if (
          res?.data?.httpStatusCode === 200 &&
          authState.userRole !== "initiator"
        )
          setShowScreen(6);
        if (
          res?.data?.httpStatusCode === 200 &&
          authState.userRole === "initiator"
        )
          setShowScreen(10);
        if (res.status !== 200) {
          return ToastNotify({
            type: "error",
            message:
              res?.status >= 500
                ? `Oops! Something went wrong`
                : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
          });
        }
      })
      .catch((error: any) => {
        // console.log(error);
        // redirect to the error screen
        setShowScreen(7);
      });
  };
  return {
    isLoading,
    showScreen,
    amount,
    accountNumber,
    accountValidated,
    accountValidationError,
    accountName,
    selectedBank,
    recipients,
    otp,
    otpMessage,
    authToken,
    description,
    allowSubmit,
    showPinError,
    pin,
    accountToDebit,
    recipientMinMaxError,
    isPinValidated,
    pinError,
    correlationId,
    editRecipientIndex,
    sumTotal,
    totalIsGreaterThanBalance,
    transaction,
    activeTab,
    setActiveTab,
    dispatch,
    authState,
    navigate,
    allAccounts,
    tabsRef,
    bankLists,
    nipCharges,
    savedGroupList,
    savedBeneficiaryList,
    handlePinValidation,
    handleChange,
    handleSubmit,
    handleBackToRecipients,
    handleScreenChangeNext,
    handleScreenChangePrev,
    handleRefreshTrasaction,
    handleAccountValidation,
    handleAddRecipient,
    handleAddBeneficiary,
    handleAddGroup,
    handleEditRecipient,
    handleRemoveRecipient,
    handleTryAgain,
    handleTransactionBreakdown,
    handlePrev,
    handleValidate,
    handleOtpValidationSubmit,
    setSumTotal,
    setPinError,
    setTotalIsGreaterThanBalance,
    setAccountToDebit,
    setPin,
    setAuthToken,
    setOtp,
  };
};
