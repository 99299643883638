import React, { forwardRef, useEffect, useState } from "react";
import { InputType } from "./types";
import { ReactComponent as ErrorIcon } from "../../alat-components-library/assets/images/icons/error-icon.svg";
import { ReactComponent as SuccessIcon } from "../../alat-components-library/assets/images/icons/success-icon.svg";
import LoadingIcon from "../../alat-components-library/assets/images/icons/loading-icon.png";
import { ReactComponent as TooltipIcon } from "../../alat-components-library/assets/images/icons/tooltip-icon.svg";
import { GrFormClose } from "react-icons/gr";
import dayjs from "dayjs";

export const DateInput = forwardRef<HTMLInputElement, InputType>(
  (
    {
      autoFocus = false,
      containerVariant = "w-full flex flex-col",
      className = "",
      name,
      label,
      handleChange,
      handleBlur,
      value = "",
      id = "",
      error,
      loading,
      success,
      placeHolder = "Select date",
      variant = "border w-full h-12 text-lg px-5",
      icon = {
        active: false,
        variant: "",
        preview: null,
      },
      isDisabled = false,
      toolTip,
      showTooltip = false,
      isMaxBlocked = false,
      hasError = false,
      showClearIcon = true,
    },
    ref
  ) => {
    // Local State
    const [localValue, setLocalValue] = useState("");
    const [open, setOpen] = useState(false);
    const [today, setToday] = useState("");

    // console.log("date-value", value)

    // Effects
    useEffect(() => {
      if (value !== "") {
        setLocalValue(value);
      }
    }, [value]);
    useEffect(() => {
      getTodayDate();
    }, []);

    const getTodayDate = () => {
      let date = dayjs().format("YYYY-MM-DD");
      setToday(date);
    };

    return (
      <div className={`${containerVariant} ${className} relative flex-col`}>
        {label && (
          <div className="flex items-center justify-between">
            <label
              htmlFor={`input-${name}`}
              className={`text-sm mb-2.5
							${isDisabled ? "text-gray-300" : "text-black"}`}
            >
              {label}
            </label>
            {showTooltip && (
              <>
                <div className="cursor-pointer relative">
                  {open && toolTip}
                  <div
                    onMouseEnter={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                  >
                    <TooltipIcon />
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <div className="relative">
          <input
            ref={ref}
            name={name}
            type="date"
            className={`focus:outline-none  text-[#333333] text-sm z-2 focus:bg-white outline-none placeholder:text-[#A6A6A6] placeholder:text-sm rounded-md
            ${icon?.active && "px-12"} 
            ${
              isDisabled
                ? `cursor-not-allowed border-gray-100 bg-gray-50 placeholder:text-gray-300`
                : "bg-lib-alat-gray-input placeholder:text-gray-300 border-lib-alat-gray-input-border"
            }
            ${hasError ? "border border-[#FF0000] focus:border-[#ff0000]" : "focus:border-lib-alat-gray-input-border border-lib-alat-gray-input-border"}
            ${success && !hasError && "border-[#3BB54A] focus:border-[#3BB54A]"}
            ${variant}`}
            id={id}
            value={localValue}
            onChange={(e) => {
              // console.log(e, "uuu")
              setLocalValue(e?.target?.value);
              return handleChange(e);
            }}
            onBlur={handleBlur}
            placeholder={placeHolder}
            disabled={isDisabled}
            autoFocus={autoFocus}
            max={isMaxBlocked ? today : ""}
          />
          {localValue && showClearIcon && (
            <div
              className="cursor-pointer absolute top-0 right-0"
              onClick={() => {
                setLocalValue("");
                return handleChange(null);
              }}
            >
              <GrFormClose className="text-lg p-[2px] bg-[#e6e6e6] rounded-xl rounded-tl-none " />
            </div>
          )}
        </div>
        {hasError && (
          <div className="text-[#ff0000] text-xs h-auto pt-2 flex items-center font-normal gap-1">
            <ErrorIcon />
            <p>{error}</p>
          </div>
        )}
        {loading && (
          <div
            className={`w-6 h-6 absolute right-4 animate-spin ${
              hasError ? "bottom-[35%]" : "bottom-[16%]"
            }`}
          >
            <img src={LoadingIcon} alt="" />
          </div>
        )}
        {success && !hasError && (
          <div
            className={`w-4 h-4 absolute right-4 ${
              hasError ? "bottom-[43%]" : "bottom-[25%]"
            }`}
          >
            <SuccessIcon />
          </div>
        )}
      </div>
    );
  }
);
