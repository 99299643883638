import AccountSlide from "../../components/Dashboard/User/AccountSlide";
import QuickActions from "../../components/Dashboard/components/QuickActions";
import PromotionsCard from "../../components/Dashboard/components/PromotionsCard";
import DashboardWrapper from "../../components/Layouts/DashboardWrapper";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import PendingTransactionsCard from "components/Dashboard/components/PendingTransactionsCard";
import { quickActionTwo } from "utils/constant/quickAction";
import PendingDashboardCard from "pages/PendingActions/RejectedDocuments/components/PendingDashboardCard";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { useGetNotifications } from "hooks/apis/Notifications/useGetNotifications";
import { useAppDispatch } from "hooks/useReduxHook";
import { resetTransactionsData } from "react-wrappers/stores/redux/transactions/slices";

export const UserDashboard = () => {
  const { userRole } = useAuthState();
  const dispatch = useAppDispatch();
  const { handleFetchNotificationsCount } = useGetNotifications();

  useEffect(() => {
    handleFetchNotificationsCount();
    dispatch(resetTransactionsData());
  }, []);

  return (
    <DashboardWrapper>
      <div className="px-4 lg:px-20" id="dashboard_page">
        <div className="flex mt-4 justify-between items-center">
          <h2 className="text-2xl font-bold">Overview</h2>
          <NavLink
            to={"/user-dashboard/accounts"}
            className="text-lib-alat-dark-red"
          >
            <div className="text-xs font-bold cursor-pointer">
              <p id="see_all_accounts_text">SEE ALL ACCOUNTS</p>
            </div>
          </NavLink>
        </div>
        <AccountSlide />
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2 mt-4 align-stretch">
          <PendingDashboardCard />
          {[
            "verifier",
            "approver",
            "initiatorapprover",
            "superapprover",
          ]?.includes(userRole?.toLowerCase()) && <PendingTransactionsCard />}
          <QuickActions quickActionList={quickActionTwo} />
          {/* <NotificationsCard /> */}
          <PromotionsCard />
        </div>
      </div>
    </DashboardWrapper>
  );
};
