import React from "react";
interface Props {
  id?: string;
  activeTab: number;
  currentTab: number;
  icon: string;
  heading: string;
  info: string;
  clickEvent: () => void;
}
const PendingCard = ({
  id,
  activeTab,
  currentTab,
  icon,
  heading,
  info,
  clickEvent,
}: Props) => {
  return (
    <div
      className={`relative h-[180px] w-auto rounded-2xl border-2 border-lib-alat-dark-red cursor-pointer flex items-center px-2 lg:px-10 gap-6 ${activeTab === currentTab ? "" : "hidden"} transition-all duration-[800ms] ease-in-out`}
      onClick={clickEvent}
      id={id}
    >
      <img src={icon} alt="Folder" className="w-[50px] md:lg:w-[100px]" />
      <div className="w-3/4">
        <h4 className="text-lg font-semibold">{heading}</h4>
        <p className="text-gray-500 md:block hidden">{info}</p>
      </div>
    </div>
  );
};

export default PendingCard;
