import PageHeader from "../../components/reusables/Page/PageHeader";
import DashboardWrapper from "../../components/Layouts/DashboardWrapper";
import CollectionFormComponent from "components/Collections/CollectionForm";
import { useCollectionsState } from "react-wrappers/stores/redux/collections/slices";
import BillConfirmationTab from "components/BillsPayment/BillConfirmation";
import { useLocation, useNavigate } from "react-router-dom";
// import OtpVerification from 'components/Bills/Airtime/BuyAirtime/OtpVerification';
// import ConfirmDataOtp from 'components/Bills/Airtime/BuyAirtime/ConfirmDataOtp';
import BillsOtp from "components/BillsPayment/BillsOtp";
import { ProcessingBillPaymentScreen } from "components/BillsPayment/ProcessingBillPaymentScreen";
import CollectionSimpleFormComponent from "components/Collections/CollectionSimpleForm";
import HeaderComponent from "components/Collections/Reuseables/HeaderComponent";
import { SuccessfulCollection } from "components/Collections/SuccessfulCollection";

const CollectionForm = () => {
  const { activeSelectedCollectionMerchantItem } = useCollectionsState();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <DashboardWrapper>
      <div className="container-fluid 2xl:container mx-auto ">
        <div className="container-fluid 2xl:container mx-auto">
          <main className="max-h-screen overflow-auto">
            <div className="">
              <div className="max-w-6xl mx-auto">
                <div className="rounded-3xl mb-5">
                  {/* {activeSelectedCollectionMerchantItem && ( */}
                  <>
                    <HeaderComponent
                      categoryName={
                        location.pathname === "/bills/collections/success"
                          ? `Payment Details`
                          : `Collection Form Step`
                      }
                    />

                    {location.pathname === "/bills/collections/form" && (
                      <CollectionFormComponent />
                    )}
                    {location.pathname === "/bills/collections/simple-form" && (
                      <CollectionSimpleFormComponent />
                    )}
                    {location.pathname === "/bills/collections/success" && (
                      <SuccessfulCollection />
                    )}
                  </>
                  {/* )} */}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default CollectionForm;
