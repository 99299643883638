import { useState } from "react";
import axiosStatus from "utils/apis/axios/errorStatus";
import { ToastNotify } from "helpers/toastNotify";
import { POST_DUDCHEQUE_COMMITMENT } from "utils/apis/routes";
import { useApiRequest } from "hooks/useApiRequest";

type DudChequeCommitmentValidationPayload = {
  correlationId: string;
  commitmentLetterLink: string;
};
export const useDudChequeCommitment = () => {
  //Local States
  const [userDudChequeCommitmentStatus, setDudChequeCommitmentStatus] =
    useState<boolean>(false);
  const [userDudChequeCommitmentLoading, setDudChequeCommitmentLoading] =
    useState<boolean>(false);
  const [userDudChequeCommitmentError, setDudChequeCommitmentError] =
    useState<any>({
      actionMessage: "",
      title: "",
    });
  const [userDudChequeCommitmentData, setDudChequeCommitmentData] =
    useState<any>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleDudChequeCommitment = async (
    payload: DudChequeCommitmentValidationPayload
  ) => {
    // console.log(payload)
    setDudChequeCommitmentError({
      actionMessage: "",
      title: "",
    });
    setDudChequeCommitmentLoading(true);
    setDudChequeCommitmentStatus(false);
    setDudChequeCommitmentData("");
    try {
      const res = await makeRequest({
        method: "post",
        route: POST_DUDCHEQUE_COMMITMENT,
        body: payload,
        originType: "onboarding",
      });
      // console.log(res?.data?.data);
      //Map the payload
      if (res?.status !== 200) {
        if (res?.data?.data?.hasError) {
          setDudChequeCommitmentError(res?.data?.data?.responseMessage);
          ToastNotify({
            type: "error",
            message: res?.data?.data?.responseMessage?.title,
            position: "top-right",
          });
        } else {
          axiosStatus(res?.data);
        }
      } else {
        setDudChequeCommitmentStatus(true);
      }
      setDudChequeCommitmentLoading(false);
    } catch (error) {
      setDudChequeCommitmentLoading(false);
    }
  };

  return {
    userDudChequeCommitmentStatus,
    userDudChequeCommitmentLoading,
    userDudChequeCommitmentError,
    userDudChequeCommitmentData,
    handleDudChequeCommitment,
  };
};
