import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card, Label } from "flowbite-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { FormikButton } from "alat-components-library";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import { useCorporateDebitCardController } from "controllers/Cards/CorporateDebitCardController";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { SelectDropdown } from "components/Dashboard/components/SelectDropdown";
import { getOption } from "utils/helperFunctions";
import { useCheckAccountAvailability } from "hooks/apis/Cards/useCheckAccountAvailability";
import { useCardState } from "react-wrappers/stores/redux/cards/slices";
import PageLoader from "components/Loader/PageLoader";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import ErrorIcon from "assets/icons/error-icon.svg";

const FirstScreen = () => {
  const navigate = useNavigate();
  const { loading } = useCardState();
  const auth = useAuthState();
  const [accountError, setAccountError] = useState<string>("");

  // Local State
  const {
    accountToDebit,
    accSelectErrorMessage,
    activeAccounts,
    deleveryOptionList,
    handleSubmit,
    setAccountToDebit,
  } = useCorporateDebitCardController();

  const { handleCheckAccountAvailability, isAvailable } =
    useCheckAccountAvailability();

  return (
    <div className="w-full">
      {loading.includes("checking-account-availability") && <PageLoader />}
      <ReusableCard
        cardTitle="Corporate Debit Cards"
        variant="md:w-[48rem] w-full mx-auto bg-white"
        handleBackNavigation={() => navigate(-1)}
        showGoBackIcon
      >
        <Formik
          initialValues={{
            deliveryOption: {
              id: 0,
              label: "",
              name: "",
            },
          }}
          onSubmit={handleSubmit}
          validateOnChange
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form>
              <div>
                <div className="flex flex-wrap justify-center gap-4 ">
                  <div className="text-alat-grey relative w-full lg:max-w-sm mb-2 pb-5">
                    <p className="text-sm py-4 text-[#606060] pl-2">
                      Please select account to link card
                    </p>
                    <div className="relative w-full lg:max-w-sm ">
                      {/* <SelectDrop
                        id="accounttodebit_dropdown"
                        idList="accounttodebit"
                        variant="h-12 w-full text-sm"
                        name="accountNumberDebit"
                        label="Select account to debit"
                        placeholder="Select account to debit"
                        value={accountToDebit.accountNo}
                        options={getOption("debit", activeAccounts)}
                        onChange={(name: string, value: any) => {
                          console.log("value", value);
                          setAccountToDebit(JSON.parse(value?.raw));
                          handleCheckAccountAvailability({
                            accountNumber: JSON.parse(value?.raw)?.accountNo,
                            schemeCode: JSON.parse(value?.raw)?.schemeCode,
                          });
                        }}
                        error={accSelectErrorMessage}
                        hasError={accountToDebit?.accountNo === ""}
                      /> */}

                      <SelectDropdown
                        // touched={() => {}}
                        options={getOption("debit", activeAccounts)}
                        placeholder="--Select--"
                        label="Select Account to debit"
                        name="accountNumberDebit"
                        onChange={(name: any, e: any) => {
                          handleChange(e);
                          let selectedAccount = auth?.user?.accounts?.find(
                            (x: any) => x?.accountNo === e
                          );
                          if (selectedAccount?.balance < 1000) {
                            setAccountError("Insufficient funds");
                          } else {
                            setAccountError("");
                            setAccountToDebit(selectedAccount);
                            handleCheckAccountAvailability({
                              accountNumber: selectedAccount?.accountNo,
                              schemeCode: selectedAccount?.schemeCode,
                            });
                          }
                        }}
                        value={accountToDebit.accountNo}
                        hasError={accountError !== ""}
                        error={accountError}
                      />
                      {/* {accountError !== "" && (
                        <div className="flex gap-2 mt-1">
                          <img src={ErrorIcon} alt="Error" />
                          <p className="text-red-500 font-[Inter-Regular] text-xs h-auto">
                            {accountError}
                          </p>
                        </div>
                      )} */}
                    </div>
                    <p className="text-sm py-4 text-[#A90836] italic">
                      You’ll be charged ₦1,000 + 7.5% VAT for this card
                    </p>
                    <div className="relative w-full lg:max-w-sm mb-2 pt-2">
                      <div className="mb-2 block text-left text-[#666666]">
                        <Label
                          value="Select Delivery Option"
                          className="!text-[#464646] font-normal text-lg leading-[1rem]"
                        />
                      </div>
                      <div className="relative w-full lg:max-w-sm">
                        <StateDropdown
                          name="deliveryOption"
                          id="deliveryOptionSelect"
                          onChange={handleChange}
                          value={values.deliveryOption.label}
                          options={deleveryOptionList}
                          placeholder="--Select--"
                          onSelectOption={(data) => {
                            // console.log(data)
                            // console.log(data.isAmountEditable);
                            setFieldValue("deliveryOption", data);
                          }}
                        />

                        {touched.deliveryOption && errors.deliveryOption && (
                          <span className="text-sm text-red-600">
                            {typeof errors?.deliveryOption == "string"
                              ? errors?.deliveryOption
                              : ""}
                          </span>
                        )}
                        {""}
                      </div>
                    </div>
                    <div className="relative w-full py-6 mb-2  flex items-center justify-center">
                      <FormikButton
                        id="proceed"
                        labelText="Proceed"
                        isDisabled={
                          (!isValid && !dirty) ||
                          !isAvailable ||
                          accountError !== ""
                        }
                        containerVariant={`w-full h-[48px] rounded-md flex justify-center item-center`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ReusableCard>
    </div>
  );
};

export default FirstScreen;
