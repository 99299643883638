import { Card, Button } from "flowbite-react";
import React from "react";
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/success-icon.svg";

export const BeneficiarySuccess = ({ cta }: { cta: () => void }) => {
  return (
    <div>
      <div className="mt-10 flex items-center justify-center">
        <Card className="md:w-[768px] w-full bg-white dark:border-gray-200 dark:bg-white">
          <div>
            <div className="flex justify-center items-center mt-10">
              <SuccessIcon />
            </div>
            <div className="text-center text-3xl font-semibold text-green-600 mt-6">
              Successful
            </div>

            <div>
              <h3 className="text-center mb-5 text-lg font-normal text-gray-500 light:text-gray-400 mt-10">
                Beneficiary saved successfully
              </h3>
            </div>

            <div className="flex justify-center flex-row gap-4 mb-40 mt-10">
              <Button
                color="failure"
                onClick={() => cta()}
                className="text-white w-[188px] bg-primary hover:bg-primary focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary"
              >
                Done
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
