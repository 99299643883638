import { TableOne } from "alat-components-library";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { useEffect, useState } from "react";
import { PendingTransactionsBody } from "./components/PendingTransactionsBody";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import {
  usePaymentState,
  resetPaymentsData,
  setSkipAndTake,
} from "react-wrappers/stores/redux/payments/slices";
import { POST_FETCH_TRANSACTION_LIST_ACTION } from "react-wrappers/stores/redux/payments/actions";
import { useAppDispatch } from "hooks/useReduxHook";
import { useNavigate } from "react-router-dom";
import { filterList } from "./components/utils";

export const PendingTransactionsHeaders = [
  { id: 1, title: "from" },
  { id: 2, title: "amount" },
  { id: 3, title: "type" },
  { id: 4, title: "description" },
  { id: 5, title: "date" },
  { id: 6, title: "status" },
];

const PendingTransactions = () => {
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState(0);
  const [label, setFilterLabel] = useState("");

  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { loading, pendingTransactionList } = usePaymentState();
  const navigate = useNavigate();

  const handleCallback = (type: string, payload: any) => {
    if (type === "search") {
      setSearchValue(payload);
    } else {
      setFilterLabel(payload?.label);
      setFilterValue(payload?.value);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        POST_FETCH_TRANSACTION_LIST_ACTION({
          mappedAccounts: user.accounts.map((x: any) => x.accountNo),
          searchValue: searchValue,
          pendingRequestFilter: filterValue,
          pageSize: 10,
          pageNumber: 1,
        })
      );
    }, 3000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [searchValue, filterValue]);

  useEffect(() => {
    dispatch(resetPaymentsData());
    const payload = {
      mappedAccounts: user.accounts.map((x: any) => x.accountNo),
      searchValue: "",
      pendingRequestFilter: 0,
      pageSize: 10,
      pageNumber: 1,
    };
    dispatch(POST_FETCH_TRANSACTION_LIST_ACTION(payload));
    // eslint-disable-next-line
  }, []);
  const handleViewNext = () => {
    dispatch(
      setSkipAndTake({
        skip: pendingTransactionList?.skip + 10,
        take: pendingTransactionList?.take + 10,
      })
    );
    dispatch(
      POST_FETCH_TRANSACTION_LIST_ACTION({
        mappedAccounts: user.accounts.map((x: any) => x.accountNo),
        searchValue: searchValue,
        pendingRequestFilter: filterValue,
        pageSize: 10,
        pageNumber: pendingTransactionList?.currentPage + 1,
      })
    );
  };
  const handleViewPrevious = () => {
    dispatch(
      setSkipAndTake({
        skip: pendingTransactionList?.skip - 10,
        take: pendingTransactionList?.take - 10,
      })
    );
    dispatch(
      POST_FETCH_TRANSACTION_LIST_ACTION({
        mappedAccounts: user.accounts.map((x: any) => x.accountNo),
        searchValue: searchValue,
        pendingRequestFilter: filterValue,
        pageSize: 10,
        pageNumber: pendingTransactionList?.currentPage - 1,
      })
    );
  };

  return (
    <DashboardWrapper>
      <div className="mt-10 w-full flex items-center justify-center">
        <div className="md:w-[1232px] w-full">
          <TableOne
            header={PendingTransactionsHeaders}
            handleBack={() => navigate("/user-dashboard")}
            showGoBackIcon
            isPagination
            // isSearch
            showTableHeader
            isFilter={false}
            isCheckboxes
            searchPlaceHolder="Search for transaction"
            onHandleCallback={handleCallback}
            title="Pending Actions"
            preloader={loading.includes("POST_FETCH_TRANSACTION_LIST")}
            paginationData={pendingTransactionList}
            handleShowNext={handleViewNext}
            handleShowPrevious={handleViewPrevious}
            value={searchValue}
            filterListProp={filterList}
            filterValue={label}
            body={
              <PendingTransactionsBody
                list={pendingTransactionList?.sortedData}
              />
            }
          />
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default PendingTransactions;
