import React, { useEffect, useRef, useState } from "react";
import { Table } from "flowbite-react";
import { formatNumberToCurrency } from "utils/helperFunctions";
import { ReactComponent as ThreeDotMenu } from "../../../../assets/icons/threedot-menu.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete-icon.svg";
import { ReactComponent as RefreshIcon } from "../../../../assets/svgs/Refresh-icon.svg";
import SelectDropDown from "alat-components-library/SelectInput/DropDown/TableDropDown";

export const ShowExcelListTableBody = ({
  list,
  handleMenuChange,
  hideDrop,
  showThreeDots = true,
  validateAccounts,
}: any) => {
  // console.log("validateAccounts", validateAccounts);

  const handleOptions = (status: string) => {
    if (status?.toLowerCase() === "successful" && validateAccounts) {
      return [
        {
          id: 2,
          value: "remove",
          label: "REMOVE",
          icon: (
            <>
              <DeleteIcon />
            </>
          ),
        },
      ];
    }
    if (status?.toLowerCase() === "failed" && validateAccounts) {
      return [
        {
          id: 1,
          value: "edit",
          label: "EDIT",
          icon: (
            <>
              <EditIcon />
            </>
          ),
        },
        {
          id: 3,
          value: "remove",
          label: "REMOVE",
          icon: (
            <>
              <DeleteIcon />
            </>
          ),
        },
      ];
    }
    if (!validateAccounts)
      return [
        {
          id: 1,
          value: "edit",
          label: "EDIT",
          icon: (
            <>
              <EditIcon />
            </>
          ),
        },
        {
          id: 3,
          value: "remove",
          label: "REMOVE",
          icon: (
            <>
              <DeleteIcon />
            </>
          ),
        },
      ];

    return [
      {
        id: 1,
        value: "edit",
        label: "EDIT",
        icon: (
          <>
            <EditIcon />
          </>
        ),
      },
      {
        id: 2,
        value: "retry",
        label: "RETRY",
        icon: (
          <>
            <RefreshIcon />
          </>
        ),
      },
      {
        id: 3,
        value: "remove",
        label: "REMOVE",
        icon: (
          <>
            <DeleteIcon />
          </>
        ),
      },
    ];
  };

  return (
    <>
      {list?.length > 0 ? (
        <>
          {list?.map((item: any, index: number) => (
            <Table.Row
              onClick={() => null}
              key={index + item?.beneficiaryAccountNumber}
              className={`${
                index % 2 === 0 ? "bg-[#F7F7F7]" : "bg-white"
              } h-14 hover:bg-afb-gray-one dark:hover:bg-afb-gray-one cursor-pointer`}
            >
              <Table.Cell
                className={` ${
                  index % 2 === 0 ? "bg-[#F7F7F7]" : "bg-white"
                } text-lib-alat-black text-[10px] sticky left-0 top-0`}
              >
                {item?.beneficiaryAccountNumber}
              </Table.Cell>
              <Table.Cell
                className="text-lib-alat-black text-[10px]"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                {item?.beneficiaryName}
              </Table.Cell>
              <Table.Cell
                className="text-lib-alat-black text-[10px]"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                {item?.beneficiaryBankName}
              </Table.Cell>
              <Table.Cell
                className="text-lib-alat-black text-[10px]"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                {item?.narration || "-"}
              </Table.Cell>
              {/* <Table.Cell
                className="text-lib-alat-black text-[10px]"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                {item?.refCode || "-"}
              </Table.Cell> */}
              <Table.Cell
                className="text-lib-alat-black text-[10px]"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                {formatNumberToCurrency({
                  currencyCode: "NGN",
                  number: item?.amount,
                })}
              </Table.Cell>
              {/* <Table.Cell
                className="text-lib-alat-black text-[10px]"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                {item?.refCode || "-"}
              </Table.Cell> */}
              {validateAccounts && (
                <Table.Cell
                  className="text-[#FF0000] text-[12px]"
                  style={{ padding: "10px 20px 10px 20px" }}
                >
                  {item.status === "Failed" && (
                    <div
                      className="w-[144.12px] h-[29.02px] flex justify-center items-center text-[#FF0000] rounded-md"
                      style={{ background: "rgba(255, 0, 0, 0.2)" }}
                    >
                      Failed
                    </div>
                  )}
                  {item.status === "Retry" && (
                    <div
                      className="w-[144.12px] h-[29.02px] flex justify-center items-center text-[#FF9900] rounded-md"
                      style={{ background: "rgba(255, 153, 0, 0.2)" }}
                    >
                      Retry
                    </div>
                  )}
                  {item.status === "Successful" && (
                    <div
                      className="w-[144.12px] h-[29.02px] flex justify-center items-center text-[#3BB54A] rounded-md"
                      style={{ background: "rgba(59, 181, 74, 0.2)" }}
                    >
                      Success
                    </div>
                  )}
                </Table.Cell>
              )}
              {showThreeDots && (
                <Table.Cell
                  className=""
                  style={{ padding: "10px 10px 10px 10px" }}
                >
                  {/* <div onClick={(e) => e.stopPropagation()}>
                    <ThreeDotMenu
                      className="text-lib-alat-black w-4 h-4"
                      onClick={() => {
                        if (show === index + item?.beneficiaryAccountNumber)
                          setShow(null);
                        setShow(index + item?.beneficiaryAccountNumber);
                      }}
                    />
                    {!hideDrop && (
                      <SelectDropDown
                        hideInput
                        variant="drop-shadow-sm border-gray-light text-custom-gray-five -mr-2"
                        containerVariant="bg-primary-app-purple flex justify-end items-center w-full bg-red-500 -mt-6 z-[1000]"
                        onHandleChange={(_, value) => {
                          console.log("value", value);
                          setAction(value);
                          handleMenuChange(item, JSON.parse(value));
                        }}
                        options={handleOptions(item?.status)}
                        iconVariant="text-white"
                        optionHeight={"h-auto top-8 w-52 right-0 rounded-lg"}
                        inlineStyle={{ zIndex: 999 }}
                        show={show === index + item?.beneficiaryAccountNumber}
                        setShow={setShow}
                      />
                    )}
                  </div> */}
                  <div className="w-full flex items-center gap-5">
                    {handleOptions(item?.status)?.map((el: any) => (
                      <div
                        title={el?.label}
                        className="cursor-pointer"
                        key={el?.id}
                        onClick={() => handleMenuChange(item, el)}
                      >
                        {el?.icon}
                      </div>
                    ))}
                  </div>
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </>
      ) : (
        <>
          <div className="h-20  flex justify-center items-center ">
            <div className="text-center text-sm w-full absolute left-0 ">
              <div className="flex flex-col items-center gap-3">
                <p className="leading-4 text-[#C4C4C4]">
                  You do not have any transaction list
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
