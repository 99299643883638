// export function capitalizeFLetter(string: string = ""): string {
//   return string && string[0]?.toUpperCase() + string.slice(1);
// }

// export const sortObjectArrayAsc = (key: string) => (a: any, b: any) =>
//   a[key].localeCompare(b[key]);

// export const sortObjectArrayDesc = (key: string) => (a: any, b: any) =>
//   b[key].localeCompare(a[key]);

// export const sortNumberAsc = (key: string) => (a: any, b: any) =>
//   a[key] - b[key];
// export const sortNumberDesc = (key: string) => (a: any, b: any) =>
//   b[key] - a[key];

// export const sortObjectItem = (key: string, order: string, type: string) => {
//   if (type === "object" && order === "asc") return sortObjectArrayAsc(key);
//   if (type === "object" && order === "desc") return sortObjectArrayDesc(key);
//   if (type === "number" && order === "asc") return sortNumberAsc(key);
//   if (type === "number" && order === "desc") return sortNumberDesc(key);
// };

//Loadin State Helper Function
export const removeLoadingState = (
  loading: string[],
  type: string
): string[] => {
  return loading.filter((l) => !l.includes(type));
};

export const getLoadingState = (loading: string[], type: string): boolean => {
  return loading.includes(type);
};

// export const isAdmin = (data: string | boolean) => (data ? true : false);

export const truncateString = (str: string, num: number) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str?.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str?.slice(0, num) + "...";
};

// export const mapCurrency = (currency: string, amount: number) => {
//   return new Intl.NumberFormat(currency === "NGN" ? "en-NG" : "en-US", { style: "currency", currency: currency }).format(
//     amount
//   );
// };
// export function disableScroll() {
//   // Get the current page scroll position
//   let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
//   let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,

//   // if any scroll is attempted, set this to the previous value
//   window.onscroll = function() {
//       window.scrollTo(scrollLeft, scrollTop);
//   };
// }

// export function enableScroll() {
//     window.onscroll = function() {};
// }

// export const changeURL = (newPath: string) => {

//   const nextURL =  window.location.origin + newPath

//   // This will create a new entry in the browser's history, reloading afterwards
//   window.location.href = window.location.origin + newPath;

//   // This will replace the current entry in the browser's history, reloading afterwards
//   window.location.assign(nextURL);

//   // This will replace the current entry in the browser's history, reloading afterwards
//   window.location.replace(nextURL);
// }
