import { jwtDecode, JwtPayload } from "jwt-decode";
import { Storage } from "./inAppstorage";
import { AxiosRequestHeaders, Method } from "axios";

export type OriginType =
  | "identity"
  | "onboarding"
  | "dimec"
  | "transferservice"
  | "solependingactions"
  | "adhocservice"
  | "ereference"
  | "collectservices"
  | "paymentservice"
  | undefined;
export type MakeRequestType = {
  method: Method;
  route: string;
  body?: object[] | object;
  originType: OriginType;
  headers?: AxiosRequestHeaders;
};

export const HOST_ORIGIN: string =
  typeof window !== "undefined" ? window.location.origin : "";
export const HOST_PATH_NAME: string =
  typeof window !== "undefined" ? window.location.pathname : "";

export const noTokenUrl: string[] = ["/"];

//Domain Insertion
const prodDomains: string[] = [""];
const prepodDomains: string[] = [""];

//Swagger url
export const swagger =
  "https://wema-afbdev-apim.azure-api.net/afbidentity/ussdsupport/swagger/index.html";

const urlCheck = (originType: OriginType, urlList: any) => {
  if (originType === "identity") {
    return urlList.one;
  }
  if (originType === "onboarding") {
    return urlList.two;
  }
  if (originType === "dimec") {
    return urlList.three;
  }
  if (originType === "transferservice") {
    return urlList.four;
  }
  if (originType === "solependingactions") {
    return urlList.five;
  }
  if (originType === "adhocservice") {
    return urlList.six;
  }
  if (originType === "ereference") {
    return urlList.seven;
  }
  if (originType === "collectservices") {
    return urlList.eight;
  }
  if (originType === "paymentservice") {
    return urlList.nine;
  }
};

export const getConfig = (originType: OriginType) => {
  if (prodDomains.includes(HOST_ORIGIN)) {
    const url = urlCheck(originType, {
      one: `${process.env.REACT_APP_API_ORIGIN}/afbidentity`,
      two: `${process.env.REACT_APP_API_ORIGIN}/afbonboarding`,
      three: `${process.env.REACT_APP_API_ORIGIN}/dimec`,
      four: `${process.env.REACT_APP_API_ORIGIN}/afbtransferservice`,
      five: `${process.env.REACT_APP_API_ORIGIN}/soleproprietorpa`,
      six: `${process.env.REACT_APP_API_ORIGIN}/afbadhocservice`,
      seven: `${process.env.REACT_APP_API_ORIGIN}/ereference`,
      eight: `${process.env.REACT_APP_API_ORIGIN}/digitalcollectservices`,
      nine: `${process.env.REACT_APP_API_ORIGIN}/afbpaymentservice`,
    });

    return { API_HOST: `${url}/api/` };
  } else if (prepodDomains.includes(HOST_ORIGIN)) {
    const url = urlCheck(originType, {
      one: `${process.env.REACT_APP_API_ORIGIN}/afbidentity`,
      two: `${process.env.REACT_APP_API_ORIGIN}/afbonboarding`,
      three: `${process.env.REACT_APP_API_ORIGIN}/dimec`,
      four: `${process.env.REACT_APP_API_ORIGIN}/afbtransferservice`,
      five: `${process.env.REACT_APP_API_ORIGIN}/soleproprietorpa`,
      six: `${process.env.REACT_APP_API_ORIGIN}/afbadhocservice`,
      seven: `${process.env.REACT_APP_API_ORIGIN}/ereference`,
      eight: `${process.env.REACT_APP_API_ORIGIN}/digitalcollectservices`,
      nine: `${process.env.REACT_APP_API_ORIGIN}/afbpaymentservice`,
    });

    return { API_HOST: `${url}/api/` };
  } else {
    const url = urlCheck(originType, {
      one: `${process.env.REACT_APP_API_ORIGIN}/afbidentity`,
      two: `${process.env.REACT_APP_API_ORIGIN}/afbonboarding`,
      three: `${process.env.REACT_APP_API_ORIGIN}/dimec`,
      four: `${process.env.REACT_APP_API_ORIGIN}/afbtransferservice`,
      five: `${process.env.REACT_APP_API_ORIGIN}/soleproprietorpa`,
      six: `${process.env.REACT_APP_API_ORIGIN}/afbadhocservice`,
      seven: `${process.env.REACT_APP_API_ORIGIN}/ereference`,
      eight: `${process.env.REACT_APP_API_ORIGIN}/digitalcollectservices`,
      nine: `${process.env.REACT_APP_API_ORIGIN}/afbpaymentservice`,
    });
    return { API_HOST: `${url}/api/` };
  }
};

// export const getToken = () => !noTokenUrl.includes(HOST_PATH_NAME) ? Storage.getItem("vencru-app-user-token") : null;
export const getToken = () =>
  typeof window !== "undefined" &&
  !noTokenUrl.includes(window.location.pathname) &&
  !!Storage.getItem("afb-app-token")
    ? Storage.getItem("afb-app-token")
    : "";

export const getAxiosConfig = ({
  method,
  route,
  body,
  headers,
  originType = "identity",
}: MakeRequestType) => ({
  method,
  baseURL: `${getConfig(originType).API_HOST}`,
  url: route,
  data: body,
  headers: {
    ...headers,
    Authorization: `Bearer ${getToken()}`,
    "Ocp-Apim-Subscription-Key": "6f36d1d1bb9446929fc827194f908cb5",
  },
});

if (HOST_PATH_NAME === "/") localStorage.clear();

export interface IDecode extends JwtPayload {
  "alat-customer-id": string;
  unique_name: string;
}
// export const getUser:IDecode  = () => jwtDecode(Storage.getItem("afb-app-token"))
