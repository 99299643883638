import { ApproverBadgeType } from "./types";

export const ApproverBadge = ({
  label = "",
  approverFullName = "",
  approverUserName = "",
}: ApproverBadgeType) => {
  return (
    <div className="flex gap-5 p-3 px-10 rounded  my-5 bg-[#F7F7F7]">
      <p className="text-lib-alat-black text-[12.92px] font-semibold">
        {label}
      </p>
      <div>
        <p className="text-md font-semibold leading-[21.96px] tracking-[2%] text-lib-alat-black">
          {approverFullName}
        </p>
        <p className="text-[12.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
          {approverUserName}
        </p>
      </div>
    </div>
  );
};
