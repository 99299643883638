import { Table } from "flowbite-react";

export const DefaultHeader = () => {
  return (
    <>
      <Table.HeadCell>Product name</Table.HeadCell>
      <Table.HeadCell>Color</Table.HeadCell>
      <Table.HeadCell>Category</Table.HeadCell>
      <Table.HeadCell>Price</Table.HeadCell>
      <Table.HeadCell></Table.HeadCell>
    </>
  );
};

export default DefaultHeader;
