import { useEffect, useState, useMemo } from "react";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { Card, Label } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import { useNavigate } from "react-router-dom";
import {
  formatNumberToCurrency,
  getCurrencyFormat,
} from "utils/helperFunctions";
// import CardBackgroundImage from "../../../../assets/svgs/card-background.svg";
import {
  FormikButton,
  PasswordInput,
  TextInput,
} from "alat-components-library";
import PageLoader from "components/Loader/PageLoader";
import { Form, Formik } from "formik";
import { ToastNotify } from "helpers/toastNotify";
import { useGetCollections } from "hooks/apis/Collections/useGetCollections";
import { GET_ALL_ACCOUNTS_ACTION } from "react-wrappers/stores/redux/accounts/actions";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useCollectionsState } from "react-wrappers/stores/redux/collections/slices";
import {
  setValidateBillResponse,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { TransactionTypeEnum } from "utils/enums";
import { useLoginController } from "controllers/Login/LoginController";
import { collectionPaymentValidationSchema } from "./Validation/collectionPayment.validations";
import { setOtpTransactionType } from "react-wrappers/stores/redux/payments/slices";
import {
  getOption,
  getAuthType,
  moneyInputFormat,
  generateChargeTwo,
} from "utils/helperFunctions";
import { CollectionCommissionType } from "utils/enums/collections";

const CollectionPaymentComponent = () => {
  const dispatch = useAppDispatch();
  const {
    activeSelectedCollectionMerchantItem,
    activeSelectedCollectionMerchant,
    loading,
    validationReference,
    summaryFields,
  } = useCollectionsState();
  const { user } = useAuthState();
  const auth = useAuthState();
  const navigate = useNavigate();
  const userAccounts = useAppSelector((state) => state.accounts);
  const { handleFetchSimpleForm, handleFetchDynamicForm, handleSubmitPayment } =
    useGetCollections();
  const { authState } = useLoginController();
  const { responseCode } = usePaymentState();
  const [amount, setAmount] = useState({
    withMoneyFormat: "0",
    withNumberFormat: 0,
  });

  const authType = useMemo(() => {
    if (amount.withNumberFormat > 2000000) {
      if (authState?.user?.hasSoftToken) {
        return "softToken";
      } else {
        return "hardTokenPin";
      }
    }
    if (auth?.user?.hasTransactionPin) return "pinOtp";
    if (auth?.user?.hasSoftToken && !auth?.user?.hasTransactionPin)
      return "softToken";
    return "hardTokenPin";
  }, [amount]);

  const transactionFee = useMemo(() => {
    let fee = 0;
    if (activeSelectedCollectionMerchantItem?.commissionApplicable) {
      if (
        activeSelectedCollectionMerchantItem?.commissionType ===
        CollectionCommissionType["Flat"]
      ) {
        return activeSelectedCollectionMerchantItem?.commission;
      }
      if (
        activeSelectedCollectionMerchantItem?.commissionType ===
        CollectionCommissionType["Percentage"]
      ) {
        return (
          amount.withNumberFormat *
          (activeSelectedCollectionMerchantItem?.commission / 100)
        );
      }
    }
    return fee;
  }, [amount]);

  const handleAmountChange = (event: {
    target: { name: any; value: any; dataset: any };
  }) => {
    if (event.target.name === "amount") {
      const result = moneyInputFormat(event.target.value);
      result && setAmount(result);
    }
  };

  const getOption = (type: string, accounts: any) => {
    return accounts
      ?.map((account: any, index: number) => {
        const availableBalance = getCurrencyFormat(account);
        if (account?.currency?.toLowerCase() === "usd") return;

        return {
          label: `
        ${account?.accountName} (${account.accountNo}) - 
        ${new Intl.NumberFormat("ja-JP", {
          style: "currency",
          currency: availableBalance.currency,
        })
          .format(Number(account.balance))
          .replace(
            availableBalance.currency,
            availableBalance?.unicodeCharacter || availableBalance?.currency
          )
          .trim()}
        `,
          value: account.accountNo,
          id: account.accountNo,
          raw: JSON.stringify(account),
        };
      })
      .filter((x: any) => x !== undefined);
  };

  useEffect(() => {
    dispatch(GET_ALL_ACCOUNTS_ACTION());
  }, []);

  const handleSubmit = (payload: any) => {
    let totalAmount = amount?.withNumberFormat;
    if (
      payload?.account?.accountNo &&
      totalAmount + transactionFee > payload?.account?.balance
    ) {
      return ToastNotify({
        type: "error",
        message: `Insufficient Balance`,
      });
    }

    const authTypeForOTp = getAuthType(totalAmount, responseCode, authState);

    const apiPayload = {
      sourceAccountNumber: payload?.account?.accountNo,
      sourceAccountName: payload?.account?.accountName,
      sourceAccountCurrencyCode: payload?.account?.currency,
      narration: "",
      transactionType:
        auth.userRole?.toLowerCase() === "soleproprietor"
          ? TransactionTypeEnum.SingleSignatoryCollectionPayment
          : TransactionTypeEnum.MultipleSignatoryCollectionPayment,
      transactionPin: payload?.pin || payload?.hardTokenPin,
      token: payload?.softToken || payload?.hardTokenOtp,
      isSoftToken: authType === "softToken",
      isValidSingleSignatoryCustomer:
        auth.userRole?.toLowerCase() === "soleproprietor",
      collectionTransactionDestinations: [
        {
          categoryName: activeSelectedCollectionMerchant?.categoryName,
          merchantName: activeSelectedCollectionMerchant?.merchantName,
          collectionName: activeSelectedCollectionMerchantItem?.collectionName,
          collectionItemName: activeSelectedCollectionMerchantItem?.itemName,
          validationReference: validationReference,
          amount: totalAmount,
          transactionFee: transactionFee,
          categoryId: activeSelectedCollectionMerchant?.categoryId,
          merchantId: activeSelectedCollectionMerchant?.merchantId,
          collectionId: activeSelectedCollectionMerchantItem?.collectionId,
          collectionItemId:
            activeSelectedCollectionMerchantItem?.collectionItemId,
        },
      ],
    };

    dispatch(setOtpTransactionType(apiPayload.transactionType));
    handleSubmitPayment(apiPayload, authTypeForOTp === "pinOtp");
  };

  const isLoading =
    userAccounts?.loading?.includes("GET_ALL_ACCOUNTS") ||
    loading.includes("submit-payment");
  let validationSchema = collectionPaymentValidationSchema(authType);

  return (
    <div>
      {isLoading && <PageLoader message="Please wait ..." />}

      <div className="w-full">
        <Card className=" bg-white md:w-[48rem] mx-auto w-full dark:bg-white dark:border-transparent border-transparent">
          <div>
            <div className="flex items-center w-full justify-center">
              <div
                className="text-[#4D4D4D] text-xl text-center  font-semibold"
                id="newtransfer_heading"
              >
                Confirmation
              </div>
              <div></div>
            </div>
          </div>
          <hr />
          <Formik
            initialValues={{
              account: {
                accountNo: "",
              },
              amount: activeSelectedCollectionMerchantItem?.collectionAmount,
              softToken: "",
              hardTokenOtp: "",
              hardTokenPin: "",
              pin: "",
            }}
            onSubmit={handleSubmit}
            validateOnChange
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              handleBlur,
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              isValid,
              dirty,
              values,
              setFieldValue,
            }) => (
              <Form>
                <div className="flex flex-col gap-4 justify-center items-center mt-4">
                  <div className="w-full mx-auto">
                    <div className="box-border w-full mx-auto h-auto rounded-lg md:space-y-4 text-white bg-[image:url('./assets/svgs/card-background.svg')] bg-no-repeat bg-cover p-4">
                      <div className="flex [&>*:nth-child(even)]:text-right flex-row flex-wrap justify-between items-center gap-4">
                        {summaryFields
                          .filter((b: any) => b.fieldValue)
                          .map((item: any) => (
                            <div key={item?.fieldName} className="w-1/3">
                              <div className="text-xs">{item.fieldName}</div>
                              <div
                                className="text-base"
                                id="nameofbeneficiary_text"
                              >
                                {item.fieldValue?.toUpperCase()}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="w-full mx-auto">
                    <div className="relative w-full pt-3">
                      <SelectDrop
                        id="accountStatement-account_dropdown"
                        idList="accountStatement-account_text"
                        variant="h-12 w-full text-sm"
                        name="accountNumberDebit"
                        label="Select Account"
                        placeholder="--Select--"
                        value={values?.account?.accountNo}
                        options={getOption(
                          "debit",
                          userAccounts?.allAccounts || []
                        )}
                        onChange={(name: string, value: any) =>
                          setFieldValue("account", JSON.parse(value.raw))
                        }
                      />
                    </div>
                    <div className="relative w-full">
                      <div className="mb-2 block text-left mt-4">
                        <Label
                          htmlFor="amount"
                          value="Amount to transfer"
                          className="!text-[#464646] dark:text-[#464646] font-normal text-lg leading-[1rem]"
                        />
                      </div>
                      <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-[#666666] ">₦</span>
                        </div>
                        <input
                          id="amount_input"
                          name="amount"
                          type="text"
                          className="pl-6 w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary"
                          value={amount?.withMoneyFormat}
                          onChange={handleAmountChange}
                        />
                      </div>
                    </div>
                    {authType === "pinOtp" && (
                      <div className="py-4">
                        <PasswordInput
                          name={"pin"}
                          label={"Enter PIN"}
                          placeHolder={""}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.pin}
                          hasError={errors?.pin && touched?.pin}
                          error={errors?.pin}
                          iconStyle={{
                            wrapper: "",
                            icon: "w-[3.5vh] h-[3.5vh]",
                          }}
                          maxLength={4}
                        />
                      </div>
                    )}
                    {authType === "softToken" && (
                      <div className="py-4">
                        <TextInput
                          id="token_input"
                          name={"softToken"}
                          type={"password"}
                          label={"Enter Soft Token"}
                          placeHolder={""}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.softToken}
                          hasError={errors?.softToken && touched?.softToken}
                          error={errors?.softToken}
                          maxLength={6}
                        />
                      </div>
                    )}

                    {authType === "hardTokenPin" && (
                      <>
                        <div className="py-4">
                          <PasswordInput
                            name={"hardTokenPin"}
                            label={"Enter Hard Token PIN"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.hardTokenPin}
                            hasError={
                              errors?.hardTokenPin && touched?.hardTokenPin
                            }
                            error={errors?.hardTokenPin}
                            iconStyle={{
                              wrapper: "",
                              icon: "w-[3.5vh] h-[3.5vh]",
                            }}
                            maxLength={4}
                          />
                        </div>
                        <div className="py-4">
                          <TextInput
                            id="token_input"
                            name={"hardTokenOtp"}
                            type={"password"}
                            label={"Enter Hard Token OTP"}
                            placeHolder={""}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.hardTokenOtp}
                            hasError={
                              errors?.hardTokenOtp && touched?.hardTokenOtp
                            }
                            error={errors?.hardTokenOtp}
                            // maxLength={6}
                          />
                        </div>
                      </>
                    )}
                    {transactionFee > 0 && (
                      <p className="text-lib-alat-red">
                        You will be charged with a fee of &nbsp;
                        {formatNumberToCurrency({ number: transactionFee })}
                        &nbsp; for this transaction
                      </p>
                    )}
                    <div className="relative w-full py-6 mb-2  flex items-center justify-center">
                      <FormikButton
                        id="proceed"
                        labelText="Proceed"
                        isDisabled={!isValid && !dirty}
                        containerVariant={`w-full h-[48px] rounded-md flex justify-center item-center`}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    </div>
  );
};

// {
//   showScreen === "main" ? (
//     <div>Main</div>
//   ) : showScreen == "successful" ? (
//     <div>Successful</div>
//   ) : showScreen == "failed" ? (
//     <div>Failed</div>
//   ) : (
//     <div>Pending</div>
//   );
// }
export default CollectionPaymentComponent;
