import { ToastNotify } from "../../../helpers/toastNotify";
import { useApiRequest } from "../../useApiRequest";
import { useAppDispatch } from "../../useReduxHook";
import React, { useState } from "react";
import { POST_MULTIPLE_ACCOUNT_NAME_ENQUIRY } from "../../../utils/apis/routes";
import {
  setBulkMultipleAccountNameEnquiry,
  setBulkMultipleAccountNameEnquiryStatusResponse,
  setBulkTranferIntra,
  setLoadingState,
  setTriggerMultipleAcctEnquiryAgain,
  usePaymentState,
} from "../../../react-wrappers/stores/redux/payments/slices";
import { ExcelSheetData } from "controllers/BulkTransfer/useIntraTransferController";

type Response = {
  data: string;
  httpStatusCode: number;
  hasError: boolean;
  responseMessage: {
    title: string;
    actionMessage: string;
  };
  responseCode: number;
};

export const useMultipleAccountNameEnquiry = ({ setActive }: any) => {
  const makeRequest = useApiRequest();
  const dispatch = useAppDispatch();
  const paymentState = usePaymentState();
  const [enqData, setEnqData] = useState<Response>({
    data: "",
    httpStatusCode: 0,
    hasError: false,
    responseMessage: {
      title: "",
      actionMessage: "",
    },
    responseCode: 0,
  });
  const [enqSuccessStatus, setEnqSuccessStatus] = useState<boolean>(false);
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMultipleAccountNameEnquiry = async (payload: any, data: any) => {
    // console.log("test", data)
    dispatch(setLoadingState(["multiple-account-name-enquiry"]));
    try {
      const response = await makeRequest({
        method: "post",
        route: POST_MULTIPLE_ACCOUNT_NAME_ENQUIRY,
        body: payload,
        originType: "transferservice",
      });

      // console.log("response", response);
      if (response?.status === 200) {
        setEnqSuccessStatus(true);
        let enqStatus = response?.data?.data?.accountEnquiredResponse;
        const dynamicExcelList = () => {
          let list: ExcelSheetData = [];
          enqStatus?.map(
            (item: {
              rowId: number;
              acctNum: string;
              bankCode: string;
              acctName: string;
              bank: string;
              desc: string;
              curr: string;
              refCode: string;
              amount: number;
              fee: number;
              status: string;
            }) => {
              return list?.push({
                rowId: item.rowId,
                beneficiaryAccountNumber: item?.acctNum?.trim(),
                beneficiaryName: item?.acctName?.trim(),
                beneficiaryBankName: item?.bank?.trim(),
                beneficiaryBankCode: item?.bankCode?.trim(),
                amount: item?.amount,
                beneficiaryCurrency: item?.curr?.trim(),
                narration: item?.desc?.trim(),
                transactionFee: item?.fee ?? 0,
                refCode: item?.refCode?.trim(),
                status: item?.status?.trim(),
              });
            }
          );
          return list;
        };

        const payload = {
          ...data,
          transferDestinations: dynamicExcelList(),
        };
        dispatch(
          setBulkTranferIntra({
            ...paymentState?.bulkTransferPayloadIntra,
            ...payload,
          })
        );

        dispatch(
          setBulkMultipleAccountNameEnquiryStatusResponse(
            response?.data?.data?.accountEnquiredResponse
          )
        );
        setActive(`excel-list-intra-tab`);
      } else if (response?.status === 500) {
        // dispatch(setTriggerMultipleAcctEnquiryAgain(false));
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        // dispatch(setTriggerMultipleAcctEnquiryAgain(false));
        setEnqSuccessStatus(false);
        ToastNotify({
          type: "error",
          message: Object.keys(response?.data?.data?.responseMessage)?.length
            ? `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`
            : "Something went wrong",
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      setEnqSuccessStatus(false);
      // setActive("new-transfer-failure");
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message: "Something went wrong",
          position: "top-right",
        });
      }
    }
  };

  return {
    handleMultipleAccountNameEnquiry,
    enqSuccessStatus,
    enqData,
  };
};
