import * as Yup from "yup";

export const idSchema = (idType: any) =>
  Yup.object({
    // enum IdType {
    //   DRIVERSLICENSE = 0,
    //   NIN = 1,
    //   NATIONALIDCARD = 2,
    //   INTLPASSPORT = 3,
    //   VOTERSCARD = 4,
    // }
    docNumber: Yup.lazy((value) => {
      switch (idType) {
        case "NIN":
          return Yup.string()
            .matches(/^\d{11}$/, "NIN must be 11 digits long")
            .required("NIN is required");
        case "National Id Card":
          return Yup.string()
            .matches(
              /^\d+$/,
              "National Id Card number must contain only digits"
            )
            .max(12, "National Id number")
            .required("National Id Card is required");
        case "International Passport":
          return Yup.string().matches(
            /^[a-zA-Z0-9]{9}$/,
            "International Passport number must be 9 digits long"
          );
        case "Voters Card":
          return Yup.string().matches(
            /^[a-zA-Z0-9]{19}$/,
            "Voters card number must be 19 digits long"
          );
        case "Driver's License":
          return Yup.string()
            .matches(
              /^[a-zA-Z0-9]+$/,
              "Driver's license number can only contain letters or numbers"
            )
            .max(13, "Driver's license has a maximum length of 13")
            .required("Driver's license number is required");
        default:
          return Yup.string();
      }
    }),

    issueDate: Yup.lazy((value) => {
      if (
        idType === "Driver's License" ||
        idType === "International Passport" ||
        idType === "National Id Card" ||
        idType === "Voters Card"
      ) {
        return Yup.date()
          .required("Please enter the issue date")
          .max(new Date(), "Issue date must be before or equal to today");
      }

      return Yup.string().notRequired();
    }),

    expiryDate: Yup.lazy((value) => {
      if (
        idType === "Driver's License" ||
        idType === "International Passport" ||
        idType === "National Id Card"
        // ||
        // idType === "Voters Card"
      ) {
        return Yup.date()
          .required("Please enter the expiry date")
          .min(new Date(), "Expiry date must be after or equal to today");
      }

      return Yup.string().notRequired();
    }),

    frontImageLink: Yup.string().required(
      "Please upload the front side of the ID card"
    ),

    backImageLink: Yup.lazy((value) => {
      if (idType === "Voters Card") {
        return Yup.string().required(
          "Please upload the back side of the ID card"
        );
      }

      return Yup.string().notRequired();
    }),
  });

export const signatureSchema = () =>
  Yup.object({
    signatureUrl: Yup.string().required(
      "Please upload a photo of your Signature"
    ),
  });

export const cacSchema = () =>
  Yup.object({
    cacUrl: Yup.string().required("Please upload a photo of your CAC"),
  });

export const tinSchema = () =>
  Yup.object({
    tin: Yup.string().required("Please enter your Tax Identification Number"),
  });

export const BusinessAddressSchema = Yup.object({
  city: Yup.string()
    .test("Not Null", "Enter a valid city", (value) => value !== "")
    .required("Please enter a valid city"),
  state: Yup.string()
    .test("Not Null", "Enter a valid state", (value) => value !== "")
    .required("Please enter a valid state"),
  lga: Yup.string()
    .test("Not Null", "Enter a valid lga", (value) => value !== "")
    .required("Please enter a valid lga"),
  town: Yup.string()
    .test("Not Null", "Enter a valid town", (value) => value !== "")
    .required("Town is required"),
  street: Yup.string().required("Street is required"),
  landmark: Yup.string()
    .test("Not Null", "Enter a valid landmark", (value) => value !== "")
    .required("Landmark is required"),
  buildingNumber: Yup.string()
    .test("Not Null", "Building number can only be a valid number", (value) =>
      /^[0-9]+$/.test(value as string)
    )
    .required("Building number is required"),
});

export const refereeValidationSchema = (wemaAccount: boolean) =>
  Yup.object().shape({
    bankName: Yup.string().required("Select a bank"),
    firstName: Yup.string().when("bankName", ([bankName], schema) => {
      return !bankName?.includes("WEMA")
        ? schema.required("Please enter firstname")
        : schema.notRequired();
    }),
    lastName: Yup.string().when("bankName", ([bankName], schema) => {
      return !bankName?.includes("WEMA")
        ? schema.required("Please enter lastname")
        : schema.notRequired();
    }),
    emailAddress: Yup.string().when("bankName", ([bankName], schema) => {
      return !bankName?.includes("WEMA")
        ? schema.required("Please enter email address")
        : schema.notRequired();
    }),
    phoneNumber: Yup.string().when("bankName", ([bankName], schema) => {
      return !bankName?.includes("WEMA")
        ? schema
            .required("Please enter phone number")
            .min(11, "Enter a valid phone number")
        : schema.notRequired();
    }),
    accountNumber: Yup.string().when("bankName", ([bankName], schema) => {
      return bankName?.includes("WEMA")
        ? schema
            .required("Please enter account number")
            .matches(/^[\d+].{9}$/, "Enter a valid account number")
        : schema.notRequired();
    }),
  });
