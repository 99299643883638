import { ReactComponent as BackArrow } from "alat-components-library/assets/images/icons/back-arrow-two.svg";
import { Textarea } from "flowbite-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useNotificationState } from "react-wrappers/stores/redux/notifications/slices";
import { NotificationStatusEnum, NotificationTypeEnum } from "utils/enums";
import { UserPermissionEnum } from "utils/enums/permissions";
import { NotificationStatusBadge } from "./NotificationStatusBadge";
import { ApproverBadge } from "./approverBadge";
import { userPermissionsData } from "pages/Admin/utils";

export const ViewUserDetails = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const { user, userRole } = useAuthState();
  const notificationsType = searchParams.get("notificationsType");
  const permissionsNotToEditDownloadStatement = [
    "Approver",
    "InitiatorApprover",
  ];
  const { notificationsDetails } = useNotificationState();

  const isApprover =
    user?.role?.toLowerCase() === "approver" ||
    user?.role?.toLowerCase() === "initiatorapprover";
  const isAdmin = user?.role?.toLowerCase() === "admin";

  return (
    <div className="lg:px-20 space-y-4 mt-5">
      <div className="flex items-center">
        <h2 className="text-2xl font-bold">
          User{" "}
          {notificationsType == NotificationTypeEnum["UserCreation"]
            ? "Creation"
            : "Modification"}
        </h2>
      </div>
      <div className="mx-auto bg-white md:pb-20 pb-10 md:px-10 px-2 pt-5">
        <div className="flex py-2 justify-between items-center">
          <BackArrow
            id="back_btn"
            className="ml-5 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h2 className="text-xl">Overview</h2>
          <div></div>
        </div>
        <hr />

        <div className="space-y-8">
          <div className="md:w-[671px] w-full mx-auto flex-col">
            <div className="w-full flex items-center justify-between p-3">
              <p className="text-md font-semibold leading-[21.96px] tracking-[2%] text-lib-alat-black">
                User{" "}
                {notificationsType == NotificationTypeEnum["UserCreation"]
                  ? "Creation"
                  : "Modification"}
              </p>
              <NotificationStatusBadge status={status} />
            </div>

            {!isAdmin &&
              status !== NotificationStatusEnum["PendingVerification"] && (
                <ApproverBadge
                  label={isApprover ? "Admin:" : "Approver:"}
                  approverFullName={
                    isApprover
                      ? notificationsDetails?.requestInitiatorFullName
                      : notificationsDetails?.approver?.fullName
                  }
                  approverUserName={
                    isApprover
                      ? notificationsDetails?.requestInitiatorUsername
                      : notificationsDetails?.approver?.username
                  }
                />
              )}

            {isAdmin &&
              (status == NotificationStatusEnum["Rejected"] ||
                status == NotificationStatusEnum["Approved"]) && (
                <ApproverBadge
                  label={isApprover ? "Admin:" : "Approver:"}
                  approverFullName={
                    isApprover
                      ? notificationsDetails?.requestInitiatorFullName
                      : notificationsDetails?.approver?.fullName
                  }
                  approverUserName={
                    isApprover
                      ? notificationsDetails?.requestInitiatorUsername
                      : notificationsDetails?.approver?.username
                  }
                />
              )}

            <div className="w-full mb-5 flex items-center justify-between p-3">
              <p className="text-md font-semibold leading-[21.96px] tracking-[2%] text-lib-alat-black">
                User Details
              </p>
            </div>
            <div className="w-full border-b border-b-[#E2E8F0] flex items-center justify-between p-3">
              <p className="text-[12.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                FULL NAME:
              </p>
              <p
                className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                id="transaction-type_text"
              >
                {notificationsDetails?.customerDetails?.fullName}
              </p>
            </div>
            <div className="w-full border-b border-b-[#E2E8F0] flex items-center justify-between p-3">
              <p className="text-[12.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                USERNAME:
              </p>
              <p
                className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                id="transaction-type_text"
              >
                {notificationsDetails?.customerDetails?.username}
              </p>
            </div>
            <div className="w-full border-b border-b-[#E2E8F0] flex items-center justify-between p-3">
              <p className="text-[12.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                EMAIL:
              </p>
              <p
                className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                id="email_text"
              >
                {notificationsDetails?.customerDetails?.email}
              </p>
            </div>
            <div className="w-full border-b border-b-[#E2E8F0] flex items-center justify-between p-3">
              <p className="text-[12.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                PHONE NUMBER:
              </p>
              <p
                className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                id="phone-number_text"
              >
                {notificationsDetails?.customerDetails?.mobileNo}
              </p>
            </div>
            <div className="w-full border-b border-b-[#E2E8F0] flex items-center justify-between p-3">
              <p className="text-[12.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                ROLE:
              </p>
              <div className="flex items-center gap-3">
                <p
                  className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                  id="role_text"
                >
                  {notificationsDetails?.customerDetails?.role}
                </p>
              </div>
            </div>
            <div className="w-full border-b border-b-[#E2E8F0] flex items-center justify-between p-3">
              <p className="text-[12.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                TRANSACTION LIMIT:
              </p>
              <p
                className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                id="one-time-limit_text"
              >
                {new Intl.NumberFormat("en-US").format(
                  Number(notificationsDetails?.dailyTransferLimit)
                )}
              </p>
            </div>
            <div className="w-full border-b border-b-[#E2E8F0] flex items-center justify-between p-3">
              <p className="text-[12.92px] font-semibold p-2 rounded-[6px] text-lib-alat-dark-red bg-[#A908361A] leading-[21.96px] tracking-[2%]">
                ACCOUNTS MAPPED
              </p>
              <p
                className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                id="staff-id_text"
              >
                {notificationsDetails?.mappedAccountsCount ??
                  notificationsDetails?.mappedAccounts?.length}
              </p>
            </div>
            {!permissionsNotToEditDownloadStatement.includes(
              notificationsDetails?.customerDetails?.role ?? "Viewer"
            ) && (
              <div>
                <hr className="h-px  bg-gray-300 border-1 dark:bg-gray-700" />

                <p className="font-bold text-md leading-[21.96px] tracking-[2%] mt-4 mb-8 p-3">
                  PERMISSIONS
                </p>

                {/* <div className="w-full border-b border-b-[#E2E8F0] flex items-center justify-between p-3">
                  <p className="text-[12.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                    Download Account Statement
                  </p>
                  <p
                    className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                    id="staff-id_text"
                  >
                    {notificationsDetails?.userPermissions?.includes(
                      UserPermissionEnum["DownloadAccountStatement"]
                    )
                      ? "YES"
                      : "NO"}
                  </p>
                </div> */}
                {userPermissionsData.map((permission) => (
                  <div
                    key={permission.key}
                    className="w-full border-b py-4 border-b-[#E2E8F0] flex items-center justify-between p-3"
                  >
                    <p className="text-[12.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                      {permission.name}
                    </p>
                    <p
                      className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                      id="staff-id_text"
                    >
                      {notificationsDetails?.userPermissions?.includes(
                        permission.value
                      )
                        ? "YES"
                        : "NO"}
                    </p>
                  </div>
                ))}
              </div>
            )}
            {status == NotificationStatusEnum["Rejected"] && (
              <div>
                <hr className="h-px  bg-gray-300 border-1 dark:bg-gray-700" />

                <p className="text-md leading-[21.96px] tracking-[2%] mt-2 p-3">
                  REASON FOR REJECTION
                </p>

                <Textarea
                  disabled
                  value={notificationsDetails?.rejectionReason}
                  rows={4}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
