import React from "react";
import { ComingSoonView } from "./Components/ComingSoonView";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PageHeader from "components/reusables/Page/PageHeader";

export const CardControl = () => {
  return (
    <DashboardWrapper>
      <div className="container-fluid 2xl:container mx-auto ">
        <div className="container-fluid 2xl:container mx-auto">
          <main className="max-h-screen overflow-auto">
            <div className="py-8">
              <div className="max-w-6xl mx-auto">
                <div className="rounded-3xl mb-5">
                  <div className="ml-4">
                    <PageHeader title="Card Control" />
                  </div>
                  <ComingSoonView title={"Card Control"} />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardWrapper>
  );
};
