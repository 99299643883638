import React, { FC } from "react";

interface CircularProgressBarProps {
  num: number;
  deNum: number;
  size?: number;
  strokeWidth?: number;
  textFont?: string;
}

const CircularProgressBar: FC<CircularProgressBarProps> = ({
  num,
  deNum = 100,
  size = 200,
  strokeWidth = 10,
  textFont = "text-2xl",
}) => {
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = circumference - (num / deNum) * circumference;

  return (
    <div className="relative">
      <svg
        className="block mx-auto"
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
      >
        <circle
          className="stroke-current text-[#FAF0F3]"
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="stroke-current text-lib-alat-dark-red"
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={progressOffset}
          transform={`rotate(-90 ${center} ${center})`}
          strokeLinecap="round"
        />
        <text
          className={`fill-current text-lib-alat-dark-red font-bold ${textFont}`}
          x={center}
          y={center}
          textAnchor="middle"
          dominantBaseline="central"
        >
          {`${num}/${deNum}`}
        </text>
      </svg>
    </div>
  );
};

export default CircularProgressBar;
