import { ToastNotify } from "helpers/toastNotify";
import { useApiRequest } from "hooks/useApiRequest";
import { useAppDispatch } from "hooks/useReduxHook";
import React, { useState } from "react";
import { setLoadingState } from "react-wrappers/stores/redux/payments/slices";
import { GET_RESEND_TRANSFER_OTP } from "utils/apis/routes";

export const useResendOtp = () => {
  const dispatch = useAppDispatch();
  const makeRequest = useApiRequest();
  const [newOtpResponse, setNewOtpResponse] = useState<{
    title: string;
    actionMessage: string;
  }>({
    title: "",
    actionMessage: "",
  });

  const handleResendOtp = async (correlationId: string) => {
    dispatch(setLoadingState(["resend-otp"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_RESEND_TRANSFER_OTP(correlationId),
        originType: "transferservice",
      });
      // console.log("resend-otp", response);
      if (response?.data?.httpStatusCode === 200) {
        setNewOtpResponse(response?.data?.responseMessage);
        ToastNotify({
          type: "success",
          message: `${response?.data?.responseMessage?.title}. ${response?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      } else if (response?.data?.data?.httpStatusCode === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message: `${response?.data?.data?.responseMessage?.title}. ${response?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }

      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      ToastNotify({
        type: "error",
        message: err?.response?.data?.message || err?.response?.data?.title,
        position: "top-right",
      });
    }
  };
  return {
    handleResendOtp,
    newOtpResponse,
  };
};
