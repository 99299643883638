import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import {
  FormikButton,
  PrimaryButton,
  TableOne,
  TextInput,
} from "alat-components-library";
import { useTransactionTransferController } from "controllers/BulkTransfer/useTransactionTransferController";
import {
  setBulkTranferIntra,
  setBulkTranferInter,
  // setBulkListPageNumber,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { ShowExcelListTableBody } from "./ShowExcelListTableBody";
import { useMemo, useState, useEffect } from "react";
import { ReactComponent as ModalClose } from "../../../../alat-components-library/assets/images/icons/modal-close.svg";
import { Form, Formik } from "formik";
import { editExcelValidation } from "utils/validations/bulktransfer";
import { useExcelModification } from "controllers/BulkTransfer/useExcelModification";
import { Pagination } from "components/Dashboard/User/Pagination";
import { useAccountNameEnquiryStatus } from "hooks/apis/Payment/useAccountNameEnquiryStatus";
import PageLoader from "components/Loader/PageLoader";
import {
  ExcelSheetData,
  useIntraTransferController,
} from "controllers/BulkTransfer/useIntraTransferController";
import { useMultipleAccountNameEnquiry } from "hooks/apis/Payment/useMultipleAccountNameEnquiry";
import BulkTransferNotice from "../Modals/BulkTransferNotice";
import { useAccountValidation } from "hooks/apis/Payment/useAccountValidation";
import { useAppDispatch } from "hooks/useReduxHook";

export const PendingTransactionsHeadersWithValidation = [
  {
    id: 1,
    title: "Account Number",
    classList: "text-[10px] bg-white sticky left-0 top-0 z-[2]",
  },
  { id: 2, title: "Account Name", classList: "text-[10px]" },
  { id: 3, title: "Bank", classList: "text-[10px] " },
  { id: 4, title: "Description", classList: "text-[10px]" },
  // { id: 5, title: "Ref code", classList: "text-[10px]  w-[90px]" },
  { id: 6, title: "Amount", classList: "text-[10px]" },
  { id: 7, title: "Status", classList: "text-[10px]" },
  { id: 8, title: "Actions", classList: "text-[10px]" },
];

export const PendingTransactionsHeadersWithoutValidation = [
  {
    id: 1,
    title: "Account Number",
    classList: "text-[10px] bg-white sticky left-0 top-0",
  },
  { id: 2, title: "Account Name", classList: "text-[10px]" },
  { id: 3, title: "Bank", classList: "text-[10px]" },
  { id: 4, title: "Description", classList: "text-[10px]" },
  // { id: 5, title: "Ref code", classList: "text-[10px]  w-[90px]" },
  { id: 6, title: "Amount", classList: "text-[10px]" },
  { id: 8, title: "Actions", classList: "text-[10px]" },
];

const ShowExcelList = ({ setActiveTab, screenName }: any) => {
  const [show, setShow] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const paymentState = usePaymentState();
  const [activeTransaction, setActiveTransaction] = useState(null);

  const requiredHeaders = paymentState?.validateBulkTransferAccounts
    ? PendingTransactionsHeadersWithValidation
    : PendingTransactionsHeadersWithoutValidation;

  const { excelList, dispatch, handleViewNext, handleViewPrev } =
    useTransactionTransferController(setActiveTab, screenName);

  const { initialValueData, handleExcelEditSubmit, handleDeleteExcel } =
    useExcelModification(
      activeTransaction,
      setEditModal,
      setDeleteModal,
      screenName
    );

  // const { handleMultipleAccountNameEnquiry } = useMultipleAccountNameEnquiry({
  //   setActive: setActiveTab,
  // });
  const { handleAccountValidation } = useAccountValidation();

  const handleMenuChange = (payload: any, selectItem: any) => {
    // console.log("payloadMenuChange", payload);
    // console.log("selectedItem", selectItem);
    if (selectItem.value === "edit") setEditModal(true);
    if (selectItem.value === "remove") setDeleteModal(true);
    if (selectItem.value === "retry") {
      // dispatch(setBulkListPageNumber(excelList?.currentPage))
      return handleAccountValidation(
        {
          oldAccountNumber: payload?.beneficiaryAccountNumber,
          newAccountNumber: payload?.beneficiaryAccountNumber,
          bankCode: payload?.beneficiaryBankCode,
          amount: Number(payload?.amount) ?? 0,
        },
        "bulk"
      );
    }
    setActiveTransaction(payload);
  };

  const isLoading =
    paymentState?.loading?.includes("validating-account") ||
    paymentState?.loading?.includes("multiple-account-name-enquiry");

  const successfulRecords =
    paymentState?.bulkTransferPayloadIntra?.transferDestinations?.filter(
      (x: any) => x?.status?.toLowerCase() === "successful"
    );
  const failedRecords =
    paymentState?.bulkTransferPayloadIntra?.transferDestinations?.filter(
      (x: any) => x?.status?.toLowerCase() === "failed"
    );
  const retryRecords =
    paymentState?.bulkTransferPayloadIntra?.transferDestinations?.filter(
      (x: any) => x?.status?.toLowerCase() === "retry"
    );

  const handleShowNext = () => {
    if (successfulRecords?.length && failedRecords?.length)
      return setShowNotice(true);
    if (successfulRecords?.length && retryRecords?.length)
      return setShowNotice(true);
    return setActiveTab(`select-account-${screenName}-tab`);
  };
  return (
    <>
      {isLoading && <PageLoader message="Validating" />}
      <div className="grid grid-cols-8 pt-6" onClick={() => setShow(null)}>
        <div className="md:w-[995px] w-full col-span-full mx-auto pb-14">
          <div className="bg-white dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
            <div className="flex items-center justify-between px-4 py-2">
              <ChevronLeftIcon
                className="h-5 w-5 text-[#333333] cursor-pointer"
                onClick={() => setActiveTab(`upload-${screenName}-tab`)}
              />
              <div className="text-[#4D4D4D] text-md font-normal text-center">
                Bulk Transfer
              </div>
              <div></div>
            </div>
            <hr />
            <TableOne
              header={requiredHeaders}
              searchPlaceHolder="Search for transaction"
              paginationData={{ ...excelList }}
              handleShowNext={handleViewNext}
              handleShowPrevious={handleViewPrev}
              isStriped
              isHover={false}
              body={
                <ShowExcelListTableBody
                  // list={excelList.sortedData}
                  list={excelList.sortedData}
                  handleMenuChange={handleMenuChange}
                  validateAccounts={paymentState?.validateBulkTransferAccounts}
                  show={show}
                  setShow={setShow}
                  showThreeDots={true}
                />
              }
            />
          </div>
          <Pagination
            paginationVariant={
              "h-20 w-full flex flex-row justify-between items-center px-4"
            }
            dataToShow={{ ...excelList }}
            handleNext={handleViewNext}
            handlePrev={handleViewPrev}
          />
          <div className="flex justify-center space-x-7">
            <PrimaryButton
              labelText={"Proceed"}
              containerVariant={
                "w-52 h-[45px] my-[3vh] rounded-md flex justify-center cursor-pointer shadow-md bg-lib-alat-red text-white"
              }
              handleClick={() => handleShowNext()}
            />
            <PrimaryButton
              labelText={"Cancel"}
              containerVariant={
                "w-52 h-[45px] my-[3vh] rounded-md flex justify-center cursor-pointer bg-white shadow-md text-lib-alat-red"
              }
              handleClick={() => {
                if (screenName === "intra") dispatch(setBulkTranferIntra({}));
                // if(screenName === "inter") dispatch(setBulkTranferInter({}))
                setActiveTab(`upload-${screenName}-tab`);
              }}
            />
          </div>
        </div>
      </div>
      {editModal && (
        <div
          className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-10 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm"
          style={{ margin: 0 }}
        >
          <center className="z-10">
            <div className="flex flex-col py-5 px-10 w-[700px] h-[560px] relative mb-4 bg-white rounded-lg overflow-y-scroll">
              <p
                className="text-right text-xs absolute right-5 top-5 cursor-pointer"
                onClick={() => {
                  setEditModal(false);
                }}
              >
                <ModalClose />
              </p>
              <h3 className="text-[#4D4D4D] text-lg text-left my-3">
                Update Transaction
              </h3>
              <hr />
              <Formik
                initialValues={initialValueData}
                enableReinitialize={true}
                validateSchema={editExcelValidation}
                onSubmit={(values: any, { setSubmitting }: any) =>
                  handleExcelEditSubmit(values, setSubmitting)
                }
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  dirty,
                  isValid,
                  setFieldValue,
                }) => (
                  <Form>
                    <div className="w-[400px] mx-auto">
                      <div className="my-5">
                        <TextInput
                          label="Beneficiary Name"
                          name="beneficiaryName"
                          value={values?.beneficiaryName}
                          handleChange={handleChange}
                        />
                      </div>
                      <div className="my-5">
                        <TextInput
                          label="Beneficiary Account Number"
                          name="beneficiaryAccountNumber"
                          value={values?.beneficiaryAccountNumber}
                          handleChange={handleChange}
                          minLength={10}
                          maxLength={15}
                        />
                      </div>
                      {/* <div className="my-5">
                                            <TextInput
                                                label="Beneficiary Bank Name"
                                                name="beneficiaryBankName"
                                                value={values?.beneficiaryBankName}
                                                handleChange={handleChange}
                                            />
                                        </div> */}
                      <div className="my-5">
                        <TextInput
                          label="Amount"
                          name="amount"
                          value={values?.amount}
                          handleChange={handleChange}
                        />
                      </div>
                      <div className="my-5">
                        <TextInput
                          label="Ref Code"
                          name="refCode"
                          value={values?.refCode}
                          handleChange={handleChange}
                        />
                      </div>
                      <textarea
                        name="narration"
                        id="narration"
                        cols={4}
                        rows={7}
                        maxLength={100}
                        placeholder="Type your description here..."
                        className="bg-gray-50 p-4 border outline-none rounded-md w-full"
                        value={values?.narration}
                        onChange={handleChange}
                      />
                      <div className="flex justify-center">
                        <FormikButton
                          labelText={"Update"}
                          containerVariant={
                            "w-2/4 h-[45px] my-[3vh] rounded-md flex justify-center"
                          }
                          isDisabled={!isValid && !dirty}
                          isLoading={isSubmitting}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </center>
        </div>
      )}
      {deleteModal && (
        <div
          className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-10 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm"
          style={{ margin: 0 }}
        >
          <center className="z-10">
            <div className="flex flex-col py-5 px-10 w-[500px] h-[220px] relative mb-4 bg-white rounded-lg overflow-y-scroll">
              <p
                className="text-right text-xs absolute right-5 top-5 cursor-pointer"
                onClick={() => setDeleteModal(false)}
              >
                <ModalClose />
              </p>
              <h3 className="text-[#4D4D4D] text-lg text-left my-3">
                Remove Transaction
              </h3>
              <hr />
              <p className="text-[#4D4D4D] text-md text-center my-3">
                Are you sure you want to remove this transaction
              </p>
              <div className="w-full flex gap-5 mt-5 mx-auto">
                <div
                  className="w-full py-3 h-10 text-lib-alat-dark-red bg-white text-bold border border-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer text-xs"
                  onClick={() => setDeleteModal(false)}
                >
                  Cancel
                </div>
                <div
                  className="w-full py-3 h-10 text-white bg-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer text-xs"
                  onClick={() =>
                    activeTransaction && handleDeleteExcel(activeTransaction)
                  }
                >
                  Remove
                </div>
              </div>
            </div>
          </center>
        </div>
      )}
      {showNotice && (
        <BulkTransferNotice
          total={
            paymentState?.bulkTransferPayloadIntra?.transferDestinations?.length
          }
          validated={successfulRecords?.length ?? 0}
          notValidated={failedRecords?.length ?? 0}
          retry={retryRecords?.length ?? 0}
          onClose={() => setShowNotice(false)}
          onSubmit={() => {
            dispatch(
              setBulkTranferIntra({
                ...paymentState?.bulkTransferPayloadIntra,
                transferDestinations: successfulRecords,
              })
            );
            setActiveTab(`select-account-${screenName}-tab`);
          }}
        />
      )}
    </>
  );
};

export default ShowExcelList;
