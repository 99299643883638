import PageHeader from "../../components/reusables/Page/PageHeader";
import DashboardWrapper from "../../components/Layouts/DashboardWrapper";
import BulkTransferTab from "components/Payments/BulkTransfer.tsx/BulkTransferTab";

const BulkTransfer = () => {
  return (
    <DashboardWrapper>
      <div className="lg:px-20 space-y-4 mt-5">
        <div className="flex justify-between items-center px-5">
          <PageHeader title="Bulk Transfer" />
        </div>
        <BulkTransferTab />
      </div>
    </DashboardWrapper>
  );
};

export default BulkTransfer;
