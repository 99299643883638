import BackArrow from "../../../../assets/svgs/BackArrow.svg";
import AccountSetupTab from "../../../../components/Dashboard/components/AccountSetupTab";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "../../../../components/Layouts/DashboardWrapper";

import Referees from "pages/Dashboard/PendingActions/RefereeSetup/Referees";

/** RENDERS A LIST OF REFEREES AND EDIT OPTIONS */

export const RefereeList = () => {
  const navigate = useNavigate();

  return (
    <DashboardWrapper>
      <div className="w-full">
        <div className="flex justify-start mt-4 md:mx-16 items-center">
          <img
            src={BackArrow}
            alt="<"
            onClick={() => navigate("/user-dashboard")}
            className="cursor-pointer"
          />
          <p className="text-[#333333] font-bold md:text-2xl text-lg">
            Account Setup
          </p>
        </div>
        <div className="md:mx-20 w-screen">
          <AccountSetupTab
            tabs={[
              {
                id: "referee",
                label: "Referee",
                content: <Referees />,
              },
              // {
              //     id: 'documentation',
              //     label: 'Business Details and Documentation',
              //     content: 'Business Details and Documentation content',
              // },
            ]}
          />
        </div>
      </div>
    </DashboardWrapper>
  );
};
