import * as Yup from "yup";
import { handleLicenseNumber } from "../utils";

export const BusinessAddressSchema = Yup.object({
  city: Yup.string()
    .test("Not Null", "Enter a valid city", (value) => value !== "")
    .required("Please enter a valid city"),
  state: Yup.string()
    .test("Not Null", "Enter a valid state", (value) => value !== "")
    .required("Please enter a valid state"),
  lga: Yup.string()
    .test("Not Null", "Enter a valid lga", (value) => value !== "")
    .required("Please enter a valid lga"),
  town: Yup.string()
    .test("Not Null", "Enter a valid town", (value) => value !== "")
    .required("Town is required"),
  street: Yup.string().required("Street is required"),
  landmark: Yup.string()
    .test("Not Null", "Enter a valid landmark", (value) => value !== "")
    .required("Landmark is required"),
  buildingNumber: Yup.string().required("Building number is required"),
  // lcda: Yup.string()
  //   .test("Not Null", "Enter a valid lcda", value => value !== "")
  //   .required("Please enter a valid lcda"),
});

export const ComingSoonSchema = Yup.object({
  preferredBranch: Yup.string()
    .test("Not Null", "Enter a valid preferred branch", (value) => value !== "")
    .required("Please enter a valid preferred branch"),
  branchRegion: Yup.string()
    .test("Not Null", "Enter a valid branch region", (value) => value !== "")
    .required("Please enter a valid  branch region"),
  phoneNumber: Yup.string()
    .test("Not Null", "Invalid phone number", (value) =>
      /^[0-9]+$/.test(value as string)
    )
    .required("Street is required"),
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Email is required"),
});
export const ContactAddressSchema = Yup.object({
  contactAddress: Yup.string()
    .test(
      "Not Null",
      "Enter a valid address",
      (value) => value !== "" || value.length >= 2
    )
    .required("Please enter a valid address"),
  landmark: Yup.string()
    .test(
      "Not Null",
      "Enter a valid address",
      (value) => value !== "" || value.length >= 2
    )
    .required("Please enter a landmark"),
  city: Yup.string()
    .test("Not Null", "Enter a valid city", (value) => value !== "")
    .required("Please enter a valid city"),
  state: Yup.string()
    .test("Not Null", "Enter a valid state", (value) => value !== "")
    .required("Please enter a valid state"),
  lga: Yup.string()
    .test("Not Null", "Enter a valid lga", (value) => value !== "")
    .required("Please enter a valid lga"),
  // lcda: Yup.string()
  //   .test("Not Null", "Enter a valid lcda", value => value !== "")
  //   .required("Please enter a valid lcda"),
});

export const BusinessSetupSchema = (subCategory: boolean) =>
  Yup.object({
    registrationType: Yup.string().trim().required("Select registration type"),
    preferredBranch: Yup.string()
      .test(
        "Not Null",
        "Enter a valid preferred branch",
        (value) => value !== ""
      )
      .required("Please enter a valid preferred branch"),
    branchRegion: Yup.string()
      .test("Not Null", "Enter a valid branch region", (value) => value !== "")
      .required("Please enter a valid  branch region"),
    email: Yup.string()
      .trim()
      .email("Invalid email address")
      .required("Email is required"),
    businessCategory: Yup.string().required("Select a business category"),
    businessSubCategory: Yup.string().test(
      "businessSubCategory",
      "Please enter the SubCategory",
      function (value) {
        if (subCategory && !value) {
          return this.createError({
            message: "Please select the SubCategory",
            path: "businessSubCategory",
          });
        }
        return true;
      }
    ),
  });
export const TrackingIdSchema = Yup.object({
  trackingId: Yup.string()
    .required("Enter a tracking ID")
    .test(
      "Not Null",
      "Invalid tracking ID",
      (value) => !!value && value.length >= 3
    )
    .required("Please enter tracking ID"),
});
export const CreateProfileSchema = (
  emailExists: string,
  usernameExists: string,
  phoneNumberExists: string,
  emailStatus: boolean,
  phonenumberStatus: boolean,
  usernameStatus: boolean
) =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Invalid email address")
      .test("Email Check", `${emailExists}`, () => emailStatus === false)
      .required("Email is required"),
    phone: Yup.string()
      .trim()
      .matches(/^\d{11}$/, "Phone number must be 11 digits")
      .test(
        "Phone number Check",
        `${phoneNumberExists}`,
        () => phonenumberStatus === false
      )
      .required("Phone number is required"),
    username: Yup.string()
      .trim()
      .test(
        "Username Check",
        `${usernameExists}`,
        () => usernameStatus === false
      )
      .required("Username is required"),
    password: Yup.string()
      .trim()
      .matches(
        /^(?=.*[0-9!@#$%^&*]).{8,}$/,
        "Password must be at least 8 characters long and contain at least one symbol or number"
      )
      .required("Enter a password"),
    confirmPassword: Yup.string()
      .trim()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm your password"),
    referralCode: Yup.string(),
  });

export const CreateExistingCustomerSchema = (
  emailExists: string,
  usernameExists: string,
  emailStatus: boolean,
  usernameStatus: boolean
) =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Invalid email address")
      .test("Email Check", `${emailExists}`, () => emailStatus === false)
      .required("Email is required"),
    username: Yup.string()
      .trim()
      .test(
        "Username Check",
        `${usernameExists}`,
        () => usernameStatus === false
      )
      .required("Username is required"),
    password: Yup.string()
      .trim()
      .matches(
        /^(?=.*[0-9!@#$%^&*]).{8,}$/,
        "Password must be at least 8 characters long and contain at least one symbol or number"
      )
      .required("Enter a password"),
    confirmPassword: Yup.string()
      .trim()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm your password"),
  });

export const cacSchema = Yup.object().shape({
  reg_number: Yup.string()
    .matches(/^[0-9]+$/, "Registration number can only contain numbers")
    .min(5, "Registration number should be at least 5 digits long")
    .max(8, "Registration number cannot exceed 8 digits")
    .required("Registration number is required"),
  businessType: Yup.number().not([null]).required("Select a Business Type"),
});

export const bvnSchema = Yup.object().shape({
  bvn: Yup.string()
    .matches(/^\d*$/, "Enter a valid BVN")
    .test(
      "Not Null",
      "BVN must be an 11 digit number",
      (value) => value !== "" && value?.length === 11
    )
    .required("Enter a valid BVN"),
  firstName: Yup.string().trim().required("First Name is required"),
  lastName: Yup.string().trim().required("Last Name is required"),
  middleName: Yup.string().trim().notRequired(),
});

export const accountNumberSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .matches(/^\d*$/, "Enter a valid Account number")
    .test(
      "Not Null",
      "Account number must be an 10 digit number",
      (value) => value !== "" && value?.length === 10
    )
    .required("Enter a valid Account number"),
});

export const SecurityQuestionsSchema = Yup.object().shape({
  question: Yup.string().required("Question is required"),
  answer: Yup.string().required("Answer is required"),
});
export const MultipleSecurityQuestionsSchema = Yup.object().shape({
  question1: Yup.string().required("Please select your security question 1"),
  question2: Yup.string().required("Please select your security question 2"),
  question3: Yup.string().required("Please select your security question 3"),
  answer1: Yup.string().required("Please answer your security question 1"),
  answer2: Yup.string().required("Please answer your security question 2"),
  answer3: Yup.string().required("Please answer your security question 3"),
});
export const cardPaymentSchema = () =>
  Yup.object().shape({
    cardNumber: Yup.string()
      .required("Enter your card number")
      .test("cardNumber", "Invalid card number", function (value) {
        return value.length === 16;
      }),
    expiry: Yup.string()
      .required("Expiration is required")
      .test("expiry", "Invalid date", function (value) {
        return value.length === 5;
      }),
    securityCode: Yup.string()
      .required("CVV is required")
      .test("securityCode", "Invalid CVV", function (value) {
        return value.length === 3;
      }),
  });
export const verificationSchema = (subCategory: boolean) =>
  Yup.object().shape({
    name: Yup.string()
      .required("Enter your business name")
      .min(3, "Enter a valid business name"),
    reg_number: Yup.string()
      .required("Registration number is required")
      .min(6, "CAC registration number should be at least 6 digits"),
    taxNumber: Yup.string().required("Tax number is required"),
    businessEmail: Yup.string()
      .required("Email number is required")
      .email("Enter a valid email address"),
    businessPhoneNumber: Yup.string()
      .required("Phone number is required")
      // eslint-disable-next-line no-useless-escape
      .matches(
        /^(\+\d{1,3}\s?)?[\d\s\-()\[\]]{7,18}$/,
        "Enter a valid phone number"
      ), // allws international format
    registrationDate: Yup.date()
      .required("Registration date is required")
      .max(new Date(), "Registration date must be before or equal to today"),
    businessDescription: Yup.string()
      .min(8, "Description should be at least 8 characters long.")
      .max(100, "Description should be at most 100 characters")
      .required("Enter your Business' description"),
    businessCategory: Yup.string().required("Select a business category"),
    businessSubCategory: Yup.string().test(
      "businessSubCategory",
      "Please enter the SubCategory",
      function (value) {
        if (subCategory && !value) {
          return this.createError({
            message: "Please select the SubCategory",
            path: "businessSubCategory",
          });
        }
        return true;
      }
    ),
  });

export const idUploadSchema = (idType: any) =>
  Yup.object({
    // enum IdType {
    //   DRIVERSLICENSE = 0,
    //   NIN = 1,
    //   NATIONALIDCARD = 2,
    //   INTLPASSPORT = 3,
    //   VOTERSCARD = 4,
    // }
    docNumber: Yup.lazy((value) => {
      switch (idType) {
        case "NIN":
          return Yup.string()
            .matches(/^\d{11}$/, "NIN must be 11 digits long")
            .required("NIN is required");
        case "National Id Card":
          return Yup.string()
            .matches(
              /^\d+$/,
              "National Id Card number must contain only digits"
            )
            .max(12, "National Id number")
            .required("National Id Card is required");
        case "International Passport":
          return Yup.string().matches(
            /^[a-zA-Z0-9]{9}$/,
            "International Passport number must be 9 digits long"
          );
        case "Voters Card":
          return Yup.string().matches(
            /^[a-zA-Z0-9]{19}$/,
            "Voters card number must be 19 digits long"
          );
        case "Driver's License":
          return Yup.string()
            .matches(
              /^[a-zA-Z0-9]+$/,
              "Driver's license number can only contain letters or numbers"
            )
            .max(13, "Driver's license has a maximum length of 13")
            .required("Driver's license number is required");
        default:
          return Yup.string();
      }
    }),

    issueDate: Yup.lazy((value) => {
      if (
        idType === "Driver's License" ||
        idType === "International Passport" ||
        idType === "National Id Card" ||
        idType === "Voters Card"
      ) {
        return Yup.date()
          .required("Please enter the issue date")
          .max(new Date(), "Issue date must be before or equal to today");
      }

      return Yup.string().notRequired();
    }),

    expiryDate: Yup.lazy((value) => {
      if (
        idType === "Driver's License" ||
        idType === "International Passport" ||
        idType === "National Id Card"
        // ||
        // idType === "Voters Card"
      ) {
        return Yup.date()
          .required("Please enter the expiry date")
          .min(new Date(), "Expiry date must be after or equal to today");
      }

      return Yup.string().notRequired();
    }),

    frontImageLink: Yup.string().required(
      "Please upload the front side of the ID card"
    ),

    backImageLink: Yup.lazy((value) => {
      if (idType === "Voters Card") {
        return Yup.string().required(
          "Please upload the back side of the ID card"
        );
      }

      return Yup.string().notRequired();
    }),

    signatureLink: Yup.string().required("Please upload your signature"),
  });
export const idSchema = (idType: any) =>
  Yup.object({
    // enum IdType {
    //   DRIVERSLICENSE = 0,
    //   NIN = 1,
    //   NATIONALIDCARD = 2,
    //   INTLPASSPORT = 3,
    //   VOTERSCARD = 4,
    // }
    docNumber: Yup.lazy((value) => {
      switch (idType) {
        case "NIN":
          return Yup.string()
            .matches(/^\d{11}$/, "NIN must be 11 digits long")
            .required("NIN is required");
        case "National Id Card":
          return Yup.string()
            .matches(
              /^\d+$/,
              "National Id Card number must contain only digits"
            )
            .max(12, "National Id number")
            .required("National Id Card is required");
        case "International Passport":
          return Yup.string().matches(
            /^[a-zA-Z0-9]{9}$/,
            "International Passport number must be 9 digits long"
          );
        case "Voters Card":
          return Yup.string().matches(
            /^[a-zA-Z0-9]{19}$/,
            "Voters card number must be 19 digits long"
          );
        case "Driver's License":
          return Yup.string()
            .matches(
              /^[a-zA-Z0-9]+$/,
              "Driver's license number can only contain letters or numbers"
            )
            .max(13, "Driver's license has a maximum length of 13")
            .required("Driver's license number is required");
        default:
          return Yup.string();
      }
    }),

    issueDate: Yup.lazy((value) => {
      if (
        idType === "Driver's License" ||
        idType === "International Passport" ||
        idType === "National Id Card" ||
        idType === "Voters Card"
      ) {
        return Yup.date()
          .required("Please enter the issue date")
          .max(new Date(), "Issue date must be before or equal to today");
      }

      return Yup.string().notRequired();
    }),

    expiryDate: Yup.lazy((value) => {
      if (
        idType === "Driver's License" ||
        idType === "International Passport" ||
        idType === "National Id Card"
        // ||
        // idType === "Voters Card"
      ) {
        return Yup.date()
          .required("Please enter the expiry date")
          .min(new Date(), "Expiry date must be after or equal to today");
      }

      return Yup.string().notRequired();
    }),

    frontImageLink: Yup.string().required(
      "Please upload the front side of the ID card"
    ),

    backImageLink: Yup.lazy((value) => {
      if (idType === "Voters Card") {
        return Yup.string().required(
          "Please upload the back side of the ID card"
        );
      }

      return Yup.string().notRequired();
    }),
  });

export const licenseSchema = (licenseDescription: any, licenseType: any) =>
  Yup.object({
    hasLicense: Yup.string().required("Select an Option"),
    registrationNumber: Yup.string().when(
      "hasLicense",
      ([hasLicense], schema) => {
        const { length } = handleLicenseNumber(licenseType);
        return hasLicense === "YES"
          ? schema
              .required("Enter your registration number")
              .test(
                "length",
                `${licenseDescription} must be ${length} characters`,
                (val: string) => {
                  if (val && !length) {
                    return true;
                  }
                  return !!(val && val.length === length);
                }
              )
          : schema.notRequired();
      }
    ),
    issueDate: Yup.string().when("hasLicense", ([hasLicense], schema) => {
      const isRequired = hasLicense === "YES" && licenseType !== 5;
      return isRequired
        ? Yup.date()
            .required("Enter the issue date")
            .max(new Date(), "Issue date must be before or equal to today")
        : schema.notRequired();
    }),
    expiryDate: Yup.string().when("hasLicense", ([hasLicense], schema) => {
      const isRequired =
        hasLicense === "YES" && licenseType !== 4 && licenseType !== 5;
      if (isRequired) {
        return Yup.date()
          .required("Enter the expiry date")
          .min(new Date(), "Your ID has expired, kindly upload a valid ID");
      } else {
        return schema.notRequired();
      }
    }),
    certificateLink: Yup.string().when("hasLicense", ([hasLicense], schema) => {
      return hasLicense === "YES"
        ? schema.required(`Upload the ${licenseDescription} certificate`)
        : schema.notRequired();
    }),
    certificateOfRegistrationLink: Yup.string().required(
      "Upload an image of the certificate of registration"
    ),
  });
