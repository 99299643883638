export enum CollectionFieldType {
  Request = 1,
  Response = 2,
  Request_And_Response = 3,
}

export enum CollectionPriorities {
  Compulsory = 1,
  Not_Compulsory = 2,
}

export enum CollectionDataType {
  Alphabet = 1,
  Number = 2,
  Alphanumeric = 3,
  Email_Format = 4,
  Date_Format = 5,
  List = 6,
  Button = 7,
  PlaceHolder = 8,
}

export enum CollectionFormType {
  "text" = 1,
  "number" = 2,
  "others" = 3,
  "email" = 4,
  "date" = 5,
}

export enum CollectionHashingType {
  None = 1,
  SHA512 = 2,
  SHA256 = 3,
}

export enum CollectionBearer {
  Merchant = 1,
  Customer = 2,
}

export enum CollectionCommissionType {
  Flat = 1,
  Percentage = 2,
}

export enum CollectionType {
  Simple = 1,
  Advanced = 2,
}
