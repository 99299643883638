import React from "react";
import { ReactComponent as BackArrowIcon } from "../../../assets/svgs/BackArrowIcon.svg";

interface ReusableCardProps {
  id?: any;
  headingId?: string;
  handleBackNavigation?: any;
  showGoBackIcon?: boolean;
  cardTitle: string;
  variant?: string;
  children: React.ReactNode;
  className?: string;
  pageCount?: number;
  totalPageCount?: number;
  showPageCount?: boolean;
}
export const ReusableCard = ({
  id = "",
  headingId,
  handleBackNavigation,
  showGoBackIcon,
  cardTitle,
  variant,
  children,
  className = "w-full bg-white mt-5 rounded-t-md border-b",
  pageCount,
  totalPageCount,
  showPageCount = false,
}: ReusableCardProps) => {
  return (
    <div className={variant} id={id}>
      {showGoBackIcon ? (
        <div className={className}>
          <div className="flex items-center justify-between p-3">
            <div
              id="back_btn"
              className="cursor-pointer"
              onClick={handleBackNavigation}
            >
              <BackArrowIcon />
            </div>
            <h6 className="text-lg font-medium">{cardTitle}</h6>
            {showPageCount ? (
              <p className="font-medium text-lg tracking-[-2%] text-lib-alat-black">
                {`${pageCount}/${totalPageCount}`}
              </p>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={`${className.split("border-b")?.[0]} ${cardTitle !== "" && "border-b"}`}
        >
          <div className="flex items-center justify-center p-3">
            <h6 id={headingId} className="text-lg font-medium">
              {cardTitle}
            </h6>
          </div>
        </div>
      )}
      <div
        className="w-full bg-white rounded-b-md p-5"
        style={{ boxShadow: "0px 4px 9px -8px rgba(0, 0, 0, 0.13)" }}
      >
        {children}
      </div>
    </div>
  );
};
