import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { useAppSelector } from "../../../../hooks/useReduxHook";
import { Account, AdminState } from "../interfaces";

const dataObject = {
  loading: [],
  userDetails: {
    customerDetails: {
      id: 0,
      fullName: "",
      email: "",
      profileStatus: "",
      mobileNo: "",
      lockStatus: "",
      staffId: "",
      mappedStatus: "",
      role: "",
      customerId: "",
      username: "",
    },
    mappedAccounts: [],
    notMappedAccount: [],
    mappedAccountsCount: 0,
    notMappedAccountsCount: 0,
    dailyTransferLimit: 0,
    oneTimeTransferLimit: 0,
    userPermissions: [],
  },
  editUserPayload: {
    fullName: "string",
    username: "string",
    phoneNumber: "string",
    email: "string",
    staffId: "string",
    role: "string",
    accountsToUnMap: ["string"],
    newAccountsToMap: ["string"],
    accountLimit: {
      accounts: ["string"],
      oneTimeLimit: 0,
      dailyTransferLimit: 0,
    },
  },
  mappedNairaAccounts: [],
  mappedDomAccounts: [],
  unmappedNairaAccounts: [],
  unmappedDomAccounts: [],
  updateUserDetails: {
    successStatus: false,
    responseMessage: {},
  },
  deleteUser: {
    successStatus: false,
    responseMessage: {},
  },
  areAccountsMapped: false,
} as AdminState;

export const initialState: AdminState = { ...dataObject };

export const adminSlice = createSlice({
  name: "admin",
  initialState,

  reducers: {
    resetAdminData: (): AdminState => initialState,
    setLoadingState: (state: AdminState, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
    setUserDetails: (state: AdminState, action: PayloadAction<any>) => {
      state.userDetails = action.payload;
    },
    setUpdateUserDetails: (state: AdminState, action: PayloadAction<any>) => {
      state.updateUserDetails = action.payload;
    },
    setDeleteUser: (state: AdminState, action: PayloadAction<any>) => {
      state.deleteUser = action.payload;
    },
    setSelectedMappedNairaAccounts: (
      state: AdminState,
      action: PayloadAction<any>
    ) => {
      state.mappedNairaAccounts = action.payload;
    },
    setSelectedMappedDomAccounts: (
      state: AdminState,
      action: PayloadAction<any>
    ) => {
      state.mappedDomAccounts = action.payload;
    },
    setSelectedUnmappedNairaAccounts: (
      state: AdminState,
      action: PayloadAction<any>
    ) => {
      state.unmappedNairaAccounts = action.payload;
    },
    setSelectedUnmappedDomAccounts: (
      state: AdminState,
      action: PayloadAction<any>
    ) => {
      state.unmappedDomAccounts = action.payload;
    },
    removeMappedNairaAccount: (
      state: AdminState,
      action: PayloadAction<any>
    ) => {
      state.mappedNairaAccounts = state.mappedNairaAccounts.filter(
        (account: Account) => account?.accountId !== action.payload
      );
    },
    removeMappedDomAccount: (state: AdminState, action: PayloadAction<any>) => {
      state.mappedNairaAccounts = state.mappedDomAccounts.filter(
        (account: Account) => account?.accountId !== action.payload
      );
    },
    removeUnmappedNairaAccount: (
      state: AdminState,
      action: PayloadAction<any>
    ) => {
      state.unmappedNairaAccounts = state.unmappedNairaAccounts.filter(
        (account: Account) => account?.accountId !== action.payload
      );
    },
    removeUnmappedDomAccount: (
      state: AdminState,
      action: PayloadAction<any>
    ) => {
      state.unmappedDomAccounts = state.unmappedDomAccounts.filter(
        (account: Account) => account?.accountId !== action.payload
      );
    },

    setEditUserPayload: (state: AdminState, action: PayloadAction<any>) => {
      state.editUserPayload = action.payload;
    },
    setAreAccountsMapped: (state: AdminState, action: PayloadAction<any>) => {
      state.areAccountsMapped = action.payload;
    },
  },
});

// Selectors
const selectAdmin = (state: RootState) => state.admin;

// Reducers and actions
export const {
  resetAdminData,
  setLoadingState,
  setUserDetails,
  setUpdateUserDetails,
  setDeleteUser,
  setSelectedMappedNairaAccounts,
  setSelectedMappedDomAccounts,
  setSelectedUnmappedNairaAccounts,
  setSelectedUnmappedDomAccounts,
  removeMappedNairaAccount,
  removeUnmappedNairaAccount,
  removeMappedDomAccount,
  removeUnmappedDomAccount,
  setEditUserPayload,
  setAreAccountsMapped,
} = adminSlice.actions;

//App Redux State
export const useAdminState = () => useAppSelector(selectAdmin);

export default adminSlice.reducer;
