import React, { useRef, useEffect } from "react";
import AFBLogo from "../../../assets/svgs/AFBLogoOpaque.svg";
import { FormikButton } from "../../../alat-components-library";
import { useNavigate } from "react-router-dom";
interface Props {
  open: boolean;
  closeFunction: () => void;
}

const AccountSetupModal = ({ open, closeFunction }: Props) => {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeFunction();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [open]);
  return (
    <div
      className={`absolute h-full overflow-hidden left-0 w-full top-0 z-[1000] backdrop-blur-sm bg-black/80 ${!open && "hidden"}`}
    >
      <div className="flex justify-center items-center w-full">
        <div
          ref={modalRef}
          className="h-auto w-4/5 bg-white fixed border top-10 z-1000 rounded-md py-12 px-12"
        >
          <img src={AFBLogo} alt="AFB-logo" className="ml-5 mb-2 w-32" />
          <div className="w-full">
            <h1 className="text-center font-bold text-3xl">ACCOUNT SETUP</h1>
            <hr className="mt-5" />
            <div className="flex w-full overflow-hidden pt-7">
              <div className="w-[49%] px-7">
                <h1 className="font-semibold text-xl">
                  Business Ownership Details
                </h1>
                <p className="text-[#606060] text-sm mb-10">
                  For Business Ownership details the customer is expected to
                  provide the following information about the sole proprietor
                </p>
                <ul className="list-disc list-inside text-[#333333] text-[16px]">
                  <li className="flex items-center">
                    <span className="inline-block w-2 h-2 bg-black rounded-full mr-2"></span>
                    <span>Two Referees</span>
                  </li>
                  <li className="flex items-center">
                    <span className="inline-block w-2 h-2 bg-black rounded-full mr-2"></span>
                    <span>SCUML Certificate (If applicable)</span>
                  </li>
                  <li className="flex items-center">
                    <span className="inline-block w-2 h-2 bg-black rounded-full mr-2"></span>
                    <span>CBN Registration License (if applicable)</span>
                  </li>
                  <li className="flex items-center">
                    <span className="inline-block w-2 h-2 bg-black rounded-full mr-2"></span>
                    <span>Certificate of Registration</span>
                  </li>
                </ul>
              </div>
              <div className="h-auto w-[2%] border border-gray-400 border-y-0 border-l-0"></div>
              <div className="w-[49%] px-7">
                <h1 className="font-semibold text-xl">
                  Business Details and Documentation
                </h1>
                <p className="text-[#606060] text-sm mb-10">
                  For Business Details and Documentation, the customer is
                  required to provide the following documentation
                </p>
                <ul className="list-disc list-inside text-[16px] text-[#333333]">
                  <li className="flex items-center">
                    <span className="inline-block w-2 h-2 bg-black rounded-full mr-2"></span>
                    <span>Two Referees</span>
                  </li>
                  <li className="flex items-center">
                    <span className="inline-block w-2 h-2 bg-black rounded-full mr-2"></span>
                    <span>SCUML Certificate (If applicable)</span>
                  </li>
                  <li className="flex items-center">
                    <span className="inline-block w-2 h-2 bg-black rounded-full mr-2"></span>
                    <span>CBN Registration License (if applicable)</span>
                  </li>
                  <li className="flex items-center">
                    <span className="inline-block w-2 h-2 bg-black rounded-full mr-2"></span>
                    <span>Certificate of Registration</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full" onClick={() => navigate("/account-setup")}>
              <FormikButton
                labelText="Proceed"
                containerVariant="w-5/12 h-10 rounded-md flex justify-center mx-auto mt-10"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSetupModal;
