import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RemitaState } from "../interfaces";
import { RootState } from "../store";
import { useAppSelector } from "../../../../hooks/useReduxHook";

const dataObject = {
  loading: [],
  withRRR: {
    responseStatus: 0,
    isValidated: true,
    description: "",
    amount: 0,
    charges: 0,
    total: 0,
    isAlreadyPaidFor: true,
    billerName: "",
    productName: "",
    name: "",
    eail: "",
    phone: "",
    rrrCode: "",
    bankCharge: 0,
    message: "",
    frequency: "",
    payerAccountNumber: "",
    maxNoOfDebits: 0,
    isMandateRRR: true,
    startDate: "",
    endDate: "",
  },
} as RemitaState;

export const initialState: RemitaState = { ...dataObject };

export const remitaSlice = createSlice({
  name: "remita",
  initialState,

  reducers: {
    resetRemitastate: (): RemitaState => initialState,

    setLoadingState: (state: RemitaState, action: PayloadAction<any>): void => {
      state.loading = action.payload;
    },

    setRemitaWithRRR: (state: any, action: PayloadAction<any>): void => {
      state.collectionsCategoryList = action.payload;
    },
  },
});

// Selectors
const selectRemita = (state: RootState) => state.remita;

// Reducers and actions
export const { setLoadingState, resetRemitastate, setRemitaWithRRR } =
  remitaSlice.actions;

//App Redux State
export const useRemitaState = () => useAppSelector(selectRemita);

export default remitaSlice.reducer;
