import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Button, Card, Label } from "flowbite-react";
import React, { useEffect, useState } from "react";

import { PaymentsProps } from "../types";

import ResponseStatus from "../ResponseStatus";
import Spinner from "../../Spinner/Spinner";

// import useApiRequest from "../../../utils/hooks/useApiRequest";
import { POST_TRANSACTION_VALIDATE_ACTION } from "../../../react-wrappers/stores/redux/payments/actions";
import { useAppDispatch } from "hooks/useReduxHook";

import {
  TransactionResponseStatusEnum,
  // TransactionStatusEnum,
  TransactionTypeEnum,
} from "../../../utils/enums";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { GET_TRANSACTION_STATUS_ACTION } from "react-wrappers/stores/redux/payments/actions";
import { useNavigate } from "react-router-dom";
import { getCurrencyFormat, moneyInputFormat } from "utils/helperFunctions";
import {
  setTransferResponseMessage,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { GET_ALL_ACCOUNTS_ACTION } from "react-wrappers/stores/redux/accounts/actions";
import { useAccountsState } from "react-wrappers/stores/redux/accounts/slices";
import PageLoader from "components/Loader/PageLoader";
import { ToastNotify } from "helpers/toastNotify";

const FundMyAccount = ({ tabsRef }: PaymentsProps) => {
  const navigate = useNavigate();
  const authState = useAuthState();
  const { fundMyAcctSelectedAccout } = usePaymentState();
  const dispatch = useAppDispatch();
  // console.log("test", fundMyAcctSelectedAccout)

  // const accounts = authState.user?.accounts;
  const accounts = useAccountsState();
  // const inActiveAccounts = accounts.filter(
  //   (account) => account.accountStatus === "Inactive"
  // );
  const [accountToDebit, setAccountToDebit] = useState({
    accountId: "",
    currency: "",
    balance: 0,
    accountName: "",
    accountNo: "",
  });
  const [accountToCredit, setAccountToCredit] = useState({
    accountId: "",
    currency: "",
    balance: 0,
    accountName: "",
    accountNo: "",
  });
  const [amount, setAmount] = useState({
    withMoneyFormat: "0",
    withNumberFormat: 0,
  });

  const [narration, setNarration] = useState("");
  // const [enableSubmit, setEnableSubmit] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [showScreen, setshowScreen] = useState("main");
  const [correlationId, setCorrelationId] = useState("");
  const [fromAcctSlide, setFromAcctSlide] = useState(false);

  const activeAccounts = accounts?.allAccounts?.filter(
    (account: any) => account.accountStatus === "Active"
  );

  // useEffect
  // console.log(accounts)

  useEffect(() => {
    if (fundMyAcctSelectedAccout) {
      // Update the credit state for selected account
      setAccountToCredit({ ...fundMyAcctSelectedAccout });
      //set Account list base on currency
      return setFromAcctSlide(true);
    }

    // setAccountToCredit({
    //   ...{
    //     accountId: "",
    //     currency: "",
    //     balance: 0,
    //     accountName: "",
    //     accountNo: "",
    //   },
    // });
    // setFromAcctSlide(false)
  }, [fundMyAcctSelectedAccout]);

  useEffect(() => {
    // console.log(accountToCredit.accountNo, accountToDebit.accountNo);
    if (
      accountToCredit.accountNo !== "" &&
      accountToDebit.accountNo !== "" &&
      accountToCredit.accountNo === accountToDebit.accountNo
    ) {
      // console.log("run here");
      setAccountToCredit({
        accountId: "",
        currency: "",
        balance: 0,
        accountName: "",
        accountNo: "",
      });
    }
  }, [accountToDebit.accountNo, accountToCredit.accountNo]);

  useEffect(() => {
    dispatch(GET_ALL_ACCOUNTS_ACTION());
  }, []);

  const handleChange = (event: { target: { name: any; value: any } }) => {
    if (event.target.name === "amount") {
      const result = moneyInputFormat(event.target.value);
      result && setAmount(result);
    }
    if (event.target.name === "narration") {
      setNarration(event.target.value);
    }

    // setFundAccount({ ...fundAccount, [event.target.name]: event.target.value });
  };

  const handleSelectDebitAccount = (event: any) => {
    // console.log(event);
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();

    const body = {
      sourceAccountNumber: accountToDebit?.accountNo,
      sourceAccountName: accountToDebit?.accountName,
      sourceAccountCurrencyCode: accountToDebit?.currency,
      transactionType: ["initiator", "initiatorapprover"]?.includes(
        authState?.userRole?.toLowerCase()
      )
        ? TransactionTypeEnum.MultipleSignatorySelfTransfer
        : TransactionTypeEnum.SingleSignatorySelfTransfer,
      transferDestinations: [
        {
          beneficiaryAccountNumber: accountToCredit?.accountNo,
          beneficiaryName: accountToCredit?.accountName,
          beneficiaryBankName: "WEMA",
          beneficiaryBankCode: "035",
          amount: amount?.withNumberFormat,
          beneficiaryCurrency: accountToCredit?.currency,
          narration: narration,
        },
      ],
      multipleTransferGroupName: "",
      transactionPin: "",
      createMultipleTransferGroup: false,
    };
    try {
      setisLoading(true);
      dispatch(POST_TRANSACTION_VALIDATE_ACTION(body))
        .unwrap()
        .then((res: any) => {
          // console.log("res", res);
          alert(
            `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`
          );
          setisLoading(false);
          dispatch(
            setTransferResponseMessage({
              title: res?.data?.responseMessage?.title,
              actionMessage: res?.data?.responseMessage?.actionMessage,
            })
          );
          setCorrelationId(res?.data?.data?.correlationId);
          if (res?.status === 200 && authState.userRole !== "initiator") {
            setshowScreen("pending");
          } else if (
            res?.status === 200 &&
            authState.userRole === "initiator"
          ) {
            navigate("/payments/initiator/transaction/approved");
            // setshowScreen("successful");
          } else if (res?.status === 404) {
            ToastNotify({
              type: "error",
              message: "Oops! Something went wrong",
            });
          } else {
            ToastNotify({
              type: "error",
              message:
                res?.status === 500
                  ? "Oops! Something went wrong"
                  : `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
            });
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
    } catch (e) {
      // console.log(e);

      setisLoading(false);
      if (authState?.userRole !== "initiator") {
        setshowScreen("failed");
      } else {
        //dispatch to state

        //redirect to error
        navigate("/errror");
      }
    }
  };

  const handleTryAgain = () => {
    setisLoading(false);
    setAccountToCredit({
      accountId: "",
      currency: "",
      balance: 0,
      accountName: "",
      accountNo: "",
    });
    setAccountToDebit({
      accountId: "",
      currency: "",
      balance: 0,
      accountName: "",
      accountNo: "",
    });
    setAmount({
      withMoneyFormat: "",
      withNumberFormat: 0,
    });
    setNarration("");
    setCorrelationId("");
    setshowScreen("main");
  };

  const handleRefreshTrasaction = async () => {
    try {
      setisLoading(true);
      dispatch(GET_TRANSACTION_STATUS_ACTION(correlationId))
        .unwrap()
        .then((res: any) => {
          alert(
            `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`
          );
          setisLoading(false);
          if (res.status === 200) {
            if (
              res?.data?.data?.transactions?.[0]?.status ===
              TransactionResponseStatusEnum.Pending
            ) {
              ToastNotify({
                type: "error",
                message:
                  res?.data?.data === null
                    ? `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`
                    : res?.data?.data?.transactions?.[0]?.statusMessage ?? "",
              });
            } else {
              dispatch(
                setTransferResponseMessage({
                  title: res?.data?.data?.transactions?.[0]?.statusMessage,
                  actionMessage: "",
                })
              );
              if (
                res?.data?.data?.transactions?.[0]?.status ===
                TransactionResponseStatusEnum.Success
              ) {
                setshowScreen("successful");
              }
              if (
                res?.data?.data?.transactions?.[0]?.status ===
                TransactionResponseStatusEnum.Failed
              ) {
                setshowScreen("failed");
              }
            }
          } else {
            ToastNotify({
              type: "error",
              message:
                res?.status >= 500
                  ? "Oops! Something went wrong"
                  : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
            });
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
    } catch (e) {
      setisLoading(false);
      // console.log(e);
    }
  };
  const handleGenerateReceipt = () => {
    navigate("/payments/transaction-receipt", {
      state: { payload: { correlationId, version: "1" } },
    });
  };
  const handleSetActiveTab = () => {
    // setActiveTab(0);
    tabsRef.current?.setActiveTab(0);
    //Clear selected accounts
    setAccountToCredit({
      ...{
        accountId: "",
        currency: "",
        balance: 0,
        accountName: "",
        accountNo: "",
      },
    });
    setAccountToDebit({
      accountId: "",
      currency: "",
      balance: 0,
      accountName: "",
      accountNo: "",
    });
  };

  const getOption = (type: string, accounts: any) => {
    return accounts
      ?.map((account: any, index: number) => {
        const availableBalance = getCurrencyFormat(account);
        if (account?.currency?.toLowerCase() === "usd") return;
        if (
          type === "debit" &&
          fundMyAcctSelectedAccout &&
          accountToCredit &&
          account?.accountNo === accountToCredit?.accountNo
        )
          return;
        if (
          type === "credit" &&
          account?.accountNo === accountToDebit?.accountNo
        )
          return;

        return {
          label: `
        ${account?.accountName} (${account?.accountNo}) - 
        ${new Intl.NumberFormat("ja-JP", {
          style: "currency",
          currency: availableBalance?.currency,
        })
          .format(Number(account?.balance))
          .replace(
            availableBalance?.currency,
            availableBalance?.unicodeCharacter || availableBalance?.currency
          )
          .trim()}
        `,
          value: account?.accountNo,
          id: account?.accountNo,
          raw: JSON.stringify(account),
        };
      })
      .filter((x: any) => x != undefined);
  };

  useEffect(() => {
    if (window.location.href?.includes("fund-my-account")) {
      setAmount({
        withMoneyFormat: "",
        withNumberFormat: 0,
      });
      setNarration("");
    }
  }, [window.location.href]);

  return (
    <div>
      {isLoading ? <PageLoader /> : null}
      {showScreen === "main" && (
        <Card className="md:w-[768px] w-full mx-auto bg-white dark:border-white dark:bg-white">
          <div>
            <div className="flex items-center justify-between">
              <div>
                <ChevronLeftIcon
                  className="h-6 w-6 text-[#333333] cursor-pointer"
                  onClick={handleSetActiveTab}
                />
              </div>
              <div className="text-[#4D4D4D] text-center text-xl font-medium">
                Fund my account
              </div>
              <div></div>
            </div>
          </div>
          <hr />

          <form className="flex w-full flex-col justify-center items-center">
            <div className="mb-6 mx-auto md:w-[390px] w-full">
              <div className="relative md:w-[390px] w-full pt-3">
                <SelectDrop
                  id="accounttodebit_dropdown"
                  idList="accounttodebit"
                  variant="h-12 w-full text-sm"
                  name="accountNumberDebit"
                  label="Select account to debit"
                  placeholder="Select account to debit"
                  value={accountToDebit?.accountNo}
                  options={getOption("debit", activeAccounts)}
                  onChange={(name: string, value: any) =>
                    setAccountToDebit(JSON.parse(value?.raw))
                  }
                />
              </div>
              <div className="relative md:w-[390px] w-full pt-5">
                <SelectDrop
                  variant="h-12 w-full text-sm"
                  name="accountNumberCredit"
                  label="Select account to credit"
                  placeholder="Select account to credit"
                  value={accountToCredit?.accountNo}
                  options={getOption("credit", accounts?.allAccounts)}
                  onChange={(name: string, value: any) =>
                    setAccountToCredit(JSON.parse(value?.raw))
                  }
                  disabled={!!fundMyAcctSelectedAccout}
                />
              </div>
            </div>

            <div className="relative  lg:max-w-sm md:w-[390px] w-full mb-6">
              <div className="mb-2 block text-left">
                <Label
                  htmlFor="amount"
                  value="Amount"
                  className="!text-[#464646] font-normal text-lg leading-[1rem]"
                />
              </div>
              <div className="relative w-full">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-[#666666] ">₦</span>
                </div>
                <input
                  name="amount"
                  type="text"
                  className="pl-6 md:w-[390px] w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm"
                  value={amount.withMoneyFormat}
                  onChange={handleChange}
                />
              </div>
              {amount?.withNumberFormat > accountToDebit?.balance && (
                <div className="text-sm text-red-600">Insufficient Balance</div>
              )}
            </div>
            <div className="relative md:w-[390px] w-full lg:max-w-sm">
              <div className="mb-2 block text-left">
                <Label
                  htmlFor="narration"
                  value="Narration (optional)"
                  className=" !text-[#464646] font-normal text-lg leading-[1rem]"
                />
              </div>

              <input
                name="narration"
                type="text"
                className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm mb-8"
                value={narration}
                onChange={handleChange}
              />
            </div>

            <Button
              type="button"
              className="bg-primary hover:bg-transparent text-white hover:border-primary lg:max-w-sm mb-20 w-[188px] mt-5 hover:bg-primary focus:ring-1 focus:ring-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
              onClick={handleSubmit}
              disabled={
                accountToCredit.accountNo === "" ||
                accountToDebit.accountNo === "" ||
                amount.withNumberFormat < 1 ||
                isLoading ||
                amount.withNumberFormat > accountToDebit.balance
              }
            >
              Fund account
            </Button>
          </form>
        </Card>
      )}
      {showScreen !== "main" && (
        <div className="">
          <ResponseStatus
            type={showScreen}
            onGenerateReceipt={() => null}
            correlationId={correlationId}
            onRetry={handleTryAgain}
            onRefresh={handleRefreshTrasaction}
          />
        </div>
      )}
    </div>
  );
};

export default FundMyAccount;
