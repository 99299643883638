import { TwoSplitColumnProps } from "../interfaces";
import Pane from "./Pane";

const TwoSplitColumn = ({
  children,
  leftVariant = "",
  rightVariant = "",
}: TwoSplitColumnProps) => {
  const [left, right] = children;

  return (
    <section className="w-full 2.5xl:container flex md:flex-row mx-auto 2.5xl:px-[250px] 3xl:px-[600px]">
      <Pane variant={leftVariant}>{left}</Pane>
      <Pane variant={rightVariant}>{right}</Pane>
    </section>
  );
};

export default TwoSplitColumn;
