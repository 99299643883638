import { FormikButton, TextInput } from "alat-components-library";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { SelectDropdown } from "components/Dashboard/components/SelectDropdown";
import { DateInput } from "components/Inputs";
import dayjs from "dayjs";
import { Formik, Form } from "formik";
import { ToastNotify } from "helpers/toastNotify";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import { useEffect } from "react";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import {
  POST_FETCH_ALL_BULK_TRANSFER_ACTION,
  POST_FETCH_ONE_BULK_TRANSFER_ACTION,
} from "react-wrappers/stores/redux/payments/actions";
import {
  setAllBulkTransactionList,
  setBulkHistoryFilter,
  setOneBulkTransactionID,
  setOneBulkTransactionList,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { formatNumberToCurrency, getOption } from "utils/helperFunctions";
import { filterHistoryValidation } from "utils/validations/bulktransfer";

const FilterHistoryTable = () => {
  const authState = useAuthState();
  const dispatch = useAppDispatch();
  const { oneBulkTransactionID, bulkHistoryFilter } = usePaymentState();
  const userAccounts = useAppSelector((state) => state.accounts);

  useEffect(() => {
    if (oneBulkTransactionID) {
      const payload = {
        ...bulkHistoryFilter,
        reference: oneBulkTransactionID,
      };
      dispatch(POST_FETCH_ONE_BULK_TRANSFER_ACTION(payload))
        .unwrap()
        .then((res: any) => {
          // Redirect to the success screen
          res.status === 200 &&
            dispatch(setOneBulkTransactionList(res.data.data));
        });
    }
  }, [oneBulkTransactionID]);

  const handleSubmit = async (values: any) => {
    // console.log(values?.account?.accountNo);
    if (values?.account?.accountNo === "") {
      return ToastNotify({
        type: "error",
        message: "Please select an account number to continue",
        position: "top-right",
      });
      // return toast.error("Please select an account number to continue")
    }
    if (values.startDate !== "" && values.endDate !== "") {
      const date1 = dayjs(values.startDate);
      const date2 = dayjs(values.endDate);
      const todayDate = dayjs();
      if (date1 > date2) {
        return ToastNotify({
          type: "error",
          message: "Start date cannot be greater than end date",
          position: "top-right",
        });
        // return toast.error("Start date cannot be greater than end date");
      }
      if (date1 > todayDate) {
        return ToastNotify({
          type: "error",
          message: "Start date cannot be greater than today",
          position: "top-right",
        });
      }
    }
    if (values.startDate === "" && values.endDate !== "") {
      return ToastNotify({
        type: "error",
        message: "Start date is required to continue",
        position: "top-right",
      });
    }

    if (values.endDate === "" && values.startDate !== "") {
      return ToastNotify({
        type: "error",
        message: "End date is required to continue",
        position: "top-right",
      });
    }

    dispatch(
      setBulkHistoryFilter({
        ...bulkHistoryFilter,
        ...values,
        accountNumber: values?.account?.accountNo,
        skip: 1,
        take: 10,
        pageSize: 10,
        pageNumber: 1,
      })
    );

    let reference = values.reference;
    if (oneBulkTransactionID && values.reference === "")
      reference = oneBulkTransactionID;

    const payload = {
      ...values,
      reference: reference,
      pageNumber: 1,
      pageSize: 10,
      accountNumber: values?.account?.accountNo,
    };

    let routes = POST_FETCH_ALL_BULK_TRANSFER_ACTION(payload);
    if (oneBulkTransactionID)
      routes = POST_FETCH_ONE_BULK_TRANSFER_ACTION(payload);

    dispatch(routes)
      .unwrap()
      .then((res: any) => {
        // Redirect to the success screen
        if (res.status === 200) {
          if (oneBulkTransactionID) {
            dispatch(setOneBulkTransactionID(values.reference));
            return dispatch(setOneBulkTransactionList(res.data.data));
          }
          dispatch(setAllBulkTransactionList(res.data.data));
        }
      });
  };

  return (
    <div className="w-full bg-white md:h-[256px] h-auto">
      <h3 className="text-center text-bold py-2">Filter Transactions</h3>

      <Formik
        initialValues={bulkHistoryFilter}
        enableReinitialize={true}
        validateSchema={filterHistoryValidation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          dirty,
          isValid,
          setFieldValue,
        }) => (
          <Form>
            <div className="flex md:flex-row flex-col items-center gap-2 justify-between space-x-2 w-full px-10 mt-5 relative">
              <div className="md:w-4/12 w-full">
                <SelectDrop
                  variant="h-12 w-full text-sm"
                  name="accountNumberDebit"
                  label="Select Account"
                  placeholder="--Select--"
                  value={values?.account?.accountNo}
                  options={getOption(
                    "debit",
                    userAccounts?.allAccounts || authState.user.accounts
                  )}
                  onChange={(name: string, value: any) =>
                    setFieldValue("account", JSON.parse(value.raw))
                  }
                />
              </div>
              <div className="md:w-4/12 w-full">
                <TextInput
                  label="Reference ID"
                  name="reference"
                  type="text"
                  handleChange={(event) =>
                    setFieldValue("reference", event.target.value)
                  }
                  variant="border text-black w-full h-[48px] text-lg px-5 truncate"
                  value={values.reference}
                />
              </div>
              <div className="flex justify-between items-center space-x-2 w-full">
                <DateInput
                  name="startDate"
                  label="Start Date"
                  variant="border text-black w-full h-[48px] text-lg px-5"
                  handleChange={(e) => {
                    if (e) return handleChange(e);
                    setFieldValue("startDate", "");
                    dispatch(
                      setBulkHistoryFilter({
                        ...bulkHistoryFilter,
                        startDate: "",
                      })
                    );
                  }}
                  value={values.startDate}
                  containerVariant="w-1/2"
                />
                <DateInput
                  name="endDate"
                  label="End Date"
                  variant="border text-black w-full h-[48px] text-lg px-5"
                  handleChange={(e) => {
                    if (e) return handleChange(e);
                    setFieldValue("endDate", "");
                    dispatch(
                      setBulkHistoryFilter({
                        ...bulkHistoryFilter,
                        endDate: "",
                      })
                    );
                  }}
                  value={values.endDate}
                  containerVariant="w-1/2"
                />
              </div>
            </div>
            <div className="flex justify-end px-8">
              <FormikButton
                labelText={"Display records"}
                containerVariant={
                  "w-2/4 h-[45px] my-[3vh] rounded-md flex justify-center"
                }
                isDisabled={!isValid && !dirty}
                isLoading={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FilterHistoryTable;
