import { ToastNotify } from "helpers/toastNotify";
import { useAppDispatch } from "hooks/useReduxHook";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import {
  GET_ACCOUNT_CARDS_ACTION,
  POST_SUBMIT_CARD_ACTIVATION_ACTION,
  POST_SUBMIT_CARD_REQUEST_ACTION,
} from "react-wrappers/stores/redux/cards/actions";
import {
  useCardState,
  setCardRequestPayload,
  setActiveCardRequestScreen,
} from "react-wrappers/stores/redux/cards/slices";
import { POST_VALIDATE_BILL_REFERENCE_ACTION } from "react-wrappers/stores/redux/payments/actions";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { TransactionTypeEnum } from "utils/enums";

export const useCardActivation = (setScreen: any) => {
  const location = useLocation();
  const authState = useAuthState();
  const { amount, cardRequestPayload } = useCardState();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAuthState();
  const accounts = authState.user?.accounts;
  const airtimeData = location?.state?.airtimeData;
  const { selectedCardForActivation } = useCardState();

  const [cards, setCards] = useState([
    {
      name: "string",
      maskedPan: "string",
      expiryDate: "12/06/2025",
      cardStatus: "activated",
      cardRouteId: 20,
      linkedAccounts: ["string"],
    },
  ]);

  const [accountToDebit, setAccountToDebit] = useState<any>({
    accountId: "",
    currency: "",
    balance: 0,
    accountName: "",
    accountNo: "",
    schemeCode: "",
  });

  useEffect(() => {
    if (accountToDebit.accountNo !== "" && accountToDebit.schemeCode !== "") {
      // console.log("enter");
      dispatch(
        GET_ACCOUNT_CARDS_ACTION({
          accountNumber: accountToDebit.accountNo,
          schemeCode: accountToDebit.schemeCode,
        })
      )
        .unwrap()
        .then((res: any) => {
          // console.log(res);
        }); //setCards(res.data.data.cards)
    }
  }, [accountToDebit.accountNo, accountToDebit.schemeCode]);

  const deleveryOptionList = [
    {
      id: 1,
      label: "Branch Pick up",
      name: "Branch Pick up",
    },
    {
      id: 2,
      label: "Office Delivery",
      name: "Office Delivery",
    },
  ];

  const activeAccounts = accounts?.filter(
    (account: any) => account.accountStatus === "Active"
  );

  const handleAccountError = (airtimeAmount: number, accountBalance: any) => {
    return accountBalance.accountNo !== "" &&
      airtimeAmount > accountBalance.balance
      ? "insufficient balance"
      : "";
  };

  const accSelectErrorMessage = useMemo(
    () => handleAccountError(airtimeData?.amount, accountToDebit),
    [airtimeData?.amount, accountToDebit?.accountNo]
  );

  const handleSubmit = (values: any) => {
    // console.log(values);
    const data = {
      accountNumber: selectedCardForActivation?.linkedAccounts?.[0],
      newPin: values.cardPin,
      confirmNewPin: values.confirmCardPin,
      expiryDate: selectedCardForActivation?.expiryDate,
      fullPan: `${values.cardNumber}`,
      cardRouteId: selectedCardForActivation?.cardRouteId,
      isSoftToken: values?.softToken === "" ? false : true,
      transactionPin: values.pin !== "" ? values.pin : values.hardTokenPin,
      token: values?.softToken !== "" ? values.softToken : values.hardTokenOtp,
    };
    // console.log("data", data);

    //Call API to Validate the refrence or transaction ID
    dispatch(POST_SUBMIT_CARD_ACTIVATION_ACTION(data))
      .unwrap()
      .then((res: any) => {
        if (res.status === 200) {
          setScreen(2);
        }
      });
  };

  return {
    accountToDebit,
    accSelectErrorMessage,
    activeAccounts,
    deleveryOptionList,
    handleSubmit,
    setAccountToDebit,
  };
};
