import { useEffect, useState } from "react";
import { BsFilter } from "react-icons/bs";
import { FilterInputType } from "./types";
import { ReactComponent as ModalClose } from "alat-components-library/assets/images/icons/modal-close.svg";
import { Form, Formik } from "formik";
import { FormikButton } from "alat-components-library";
import { NotificationFilerDetailValidation } from "utils/validations/notification";
import { DateInput } from "components/Inputs";

export const FilterInput = ({
  dropList,
  variant,
  filterValue = "All",
  onHandleFiterSelect,
}: FilterInputType) => {
  // Local  State
  const [defaultText, setDefaultText] = useState(
    filterValue === "" ? "Filter" : filterValue
  );
  const [showFilterList, setShowFilterList] = useState(false);
  const [list, setlist] = useState<any>([]);

  // Effect
  useEffect(() => {
    setlist(dropList);
  }, [dropList]);

  // Functions
  const handleSelectFilter = (item: { from: string; to: string }) => {
    setShowFilterList(!showFilterList);
    // return a callback of the selected list
    onHandleFiterSelect(item);
  };

  return (
    <div className="relative">
      <button
        id="dropdownRadioButton"
        onClick={() => setShowFilterList(!showFilterList)}
        className="inline-flex items-center text-gray-500 bg-[#F7F7F7] border outline-none bg-gray-50 font-medium rounded-md text-sm px-3 py-1.5 h-10"
        type="button"
      >
        <BsFilter className="w-4 h-4 mr-2 text-gray-400" />
        {defaultText}
        <svg
          className="w-3 h-3 ml-2"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>
      {showFilterList && (
        <div
          className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-10 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm"
          style={{ margin: 0 }}
        >
          <center className="z-10">
            <div className="flex flex-col py-5 px-5 md:w-[700px] w-full h-[560px] relative mb-4 bg-white rounded-lg overflow-scroll">
              <div className=" text-lg font-bold py-5">Filter</div>
              <p
                id="close-modal_btn"
                className="text-right text-xs absolute right-5 top-5 cursor-pointer"
                onClick={() => {
                  setShowFilterList(false);
                }}
              >
                <ModalClose />
              </p>

              <Formik
                initialValues={{ from: "", to: "" }}
                validateSchema={NotificationFilerDetailValidation}
                onSubmit={handleSelectFilter}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  dirty,
                  isValid,
                }) => (
                  <Form>
                    <div className="md:w-[400px] w-full mx-auto space-y-4">
                      <h3
                        className="text-[#4D4D4D] text-md text-center my-5"
                        id="complete_text"
                      >
                        Complete transaction details below
                      </h3>
                      <p>Date Range</p>
                      <div className="w-full">
                        <DateInput
                          id="startDate_input"
                          name="from"
                          label=""
                          variant="border text-black w-full h-[48px] text-lg px-5"
                          handleChange={(e) => e && handleChange(e)}
                          value={values?.from}
                        />
                      </div>
                      <div className="flex justify-between  items-center gap-2">
                        <DateInput
                          id="endDate_input"
                          name="to"
                          label=""
                          variant="border text-black w-full h-[48px] text-lg px-5"
                          handleChange={(e) => e && handleChange(e)}
                          value={values.to}
                        />
                      </div>

                      <div className="flex justify-center">
                        <FormikButton
                          id="send_btn"
                          labelText={"Apply"}
                          containerVariant={
                            "w-full h-[45px] my-[3vh] rounded-md flex justify-center"
                          }
                          isDisabled={!isValid && !dirty}
                          isLoading={false}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </center>
        </div>
      )}
    </div>
  );
};
