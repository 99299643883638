import React, { useEffect } from "react";
import Circle from "../../assets/svgs/loading.png";
import AlatLogo from "../../assets/svgs/processing-logo.png";

interface Props {
  message?: string;
}

const PageLoader: React.FC<Props> = ({ message = "Please wait" }) => {
  useEffect(() => {
    const { body } = document;

    body.classList.add("overflow-hidden");
    return () => {
      body.classList.remove("overflow-hidden");
    };
  }, []);

  return (
    <div
      className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-95 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm"
      style={{ margin: 0 }}
    >
      <center className="z-10">
        <div className="flex justify-center items-center w-42 h-42 relative mb-4">
          <img
            src={Circle}
            className="w-24 animate-spin max-w-full"
            alt="circle"
          />
          <img
            src={AlatLogo}
            className="w-12 animate-pulse absolute max-w-full"
            alt="logo"
          />
        </div>
        <p className="text-white text-base loading-dot">{message}</p>
      </center>
    </div>
  );
};

export default PageLoader;
