import { Button, Card } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as FourUserIcon } from "assets/svgs/successScreen.svg";
import { useAirtimeController } from "controllers/AirtimeAndData/AirtimeController";
import Spinners from "components/Spinner/Spinner";
import PageLoader from "components/Loader/PageLoader";
interface IConfirmTransProps {
  amount: any;
}
const ConfirmTrans = ({ amount }: IConfirmTransProps) => {
  const location = useLocation();
  const airtimeData = location?.state?.AirtimeHandleRefreshData;
  const correlationId = location?.state?.correlationId;
  const { presetAmountLoading } = useAirtimeController();
  const navigate = useNavigate();
  const handleGotoDashboard = () => {
    navigate("/user-dashboard");
  };
  const handleGenerateReceipt = () => {
    navigate("/payments/transaction-receipt", {
      state: { payload: { correlationId, version: "1" } },
    });
  };
  return (
    <div>
      <div>
        <div className="grid grid-cols-8">
          <div className="col-span-1"></div>
          <Card className="w-[768px] col-span-3 bg-white pb-14  dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
            <div>
              <div className="flex items-center justify-between">
                <div
                  className="text-[#4D4D4D] text-xl text-center"
                  id="banktransfer_heading"
                ></div>
                <div></div>
              </div>
            </div>
            <hr />
            <div>
              {presetAmountLoading ? <PageLoader /> : null}
              <div className="flex flex-wrap justify-center gap-4 ">
                <div className="flex justify-center relative w-full lg:max-w-sm mb-2">
                  <FourUserIcon fill="inherit" />
                </div>
                <h5
                  className="flex justify-center relative w-full lg:max-w-sm mb-1"
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                >
                  Transaction Successful!
                </h5>
                <p
                  className="flex justify-center relative w-full lg:max-w-sm mb-1"
                  style={{ fontSize: "16px" }}
                >
                  You just bought ₦{airtimeData?.amount} Airtime.
                </p>

                <div className="relative lg:max-w-sm w-full flex items-center justify-center mt-2">
                  <Button
                    id="proceed-to-dashboard_btn"
                    onClick={handleGotoDashboard}
                    className="hover:text-white dark:hover:!text-white dark:hover:bg-primary w-[188px] bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:border-primary focus:text-white"
                  >
                    Return to dashboard
                  </Button>

                  <div style={{ marginLeft: "12px" }}></div>

                  <Button
                    id="proceed-to-dashboard_btn"
                    onClick={handleGenerateReceipt}
                    className="hover:text-white dark:hover:!text-white dark:hover:bg-primary w-[188px] bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:border-primary focus:text-white"
                  >
                    Generate Receipt
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
    //  <div className="w-full">
    //    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center">
    //      <Card className="w-full bg-white pb-14 dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
    //        <div>
    //          <div className="flex items-center justify-between">
    //            <div
    //              className="text-[#4D4D4D] text-xl text-center"
    //              id="banktransfer_heading"
    //            ></div>
    //            <div></div>
    //          </div>
    //        </div>
    //        <hr />
    //        <div>
    //          {presetAmountLoading ? <Spinners /> : null}
    //          <div className="flex flex-wrap justify-center gap-4">
    //            <div className="flex justify-center relative w-full lg:max-w-sm mb-2">
    //              <FourUserIcon fill="inherit" />
    //            </div>
    //            <h5
    //              className="flex justify-center relative w-full lg:max-w-sm mb-1"
    //              style={{
    //                color: 'green',
    //                fontWeight: 'bold',
    //                fontSize: '22px',
    //              }}
    //            >
    //              Transaction Successful!
    //            </h5>
    //            <p
    //              className="flex justify-center relative w-full lg:max-w-sm mb-1"
    //              style={{ fontSize: '16px' }}
    //            >
    //              You just bought ₦{airtimeData?.amount} Airtime.
    //            </p>

    //            <div className="relative lg:max-w-sm w-full flex items-center justify-center mt-2">
    //              <Button
    //                id="proceed-to-dashboard_btn"
    //                onClick={handleGotoDashboard}
    //                className="hover:text-white dark:hover:!text-white dark:hover:bg-primary w-[188px] bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:border-primary focus:text-white"
    //              >
    //                Return to dashboard
    //              </Button>

    //              <div style={{ marginLeft: '12px' }}></div>

    //              <Button
    //                id="proceed-to-dashboard_btn"
    //                onClick={handleGenerateReceipt}
    //                className="hover:text-white dark:hover:!text-white dark:hover:bg-primary w-[188px] bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:border-primary focus:text-white"
    //              >
    //                Generate Receipt
    //              </Button>
    //            </div>
    //          </div>
    //        </div>
    //      </Card>
    //    </div>
    //  </div>
  );
};

export default ConfirmTrans;
