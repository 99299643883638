import CenterModal from "alat-components-library/Modal/CenterModal";
import { useAppDispatch } from "hooks/useReduxHook";
import React from "react";
import { triggerLogout } from "react-wrappers/stores/redux/auth/assit-functions";
import { setAuthReset } from "react-wrappers/stores/redux/auth/slices";
import { resetOnboardingData } from "react-wrappers/stores/redux/onboarding/slices";
import { resetProfileData } from "react-wrappers/stores/redux/profile/slices";

const PendingDudChequesModal = () => {
  const dispatch = useAppDispatch();
  const logout = () => {
    triggerLogout();
    dispatch(setAuthReset());
    dispatch(resetProfileData());
    dispatch(resetOnboardingData());
  };
  return (
    <CenterModal
      title="Some Dud Cheque records linked to the provided BVN"
      info="Kindly check your email for more information"
      handleClose={logout}
      handleClick={logout}
      btnText="Okay"
    />
  );
};

export default PendingDudChequesModal;
