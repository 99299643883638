import React, { useEffect } from "react";

// import { banks } from "../../../../demo-data/data";
import ListAllSavedBeneficiaries from "../Reusables/ListAllSavedBeneficiaries";
// import NewTransferComponent1 from "../Reusables/NewTransferComponent1";
import NewTransferComponent2 from "../Reusables/NewTransferComponent2";
import NewTransferComponent3 from "../Reusables/NewTransferComponent3";
import OTPVerification from "../Reusables/OTPVerification";
import ResponseStatus from "components/Payments/ResponseStatus";
import SuccessScreen from "../Reusables/SuccessScreen";
import PageLoader from "components/Loader/PageLoader";
import useSavedBeneficiary from "../../../../controllers/SavedBeneficiary";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { useNIPCharges } from "hooks/apis/Payment/useNIPCharges";
const SavedBeneficiary = ({ tabsRef }: any) => {
  const {
    isLoading,
    // options,
    showScreen,
    accountNumber,
    accountToDebit,
    selectedBank,
    savedBeneficiaryList,
    beneficiaryName,
    // selectedBeneficiary,
    allAccounts,
    amount,
    description,
    showPinError,
    pinError,
    // nipCharges,
    correlationId,
    otp,
    otpMessage,
    setOtp,
    pin,
    setPin,
    authToken,
    setAuthToken,
    isPinValidated,
    allowSubmit,
    // responseCode,
    handleSubmit,
    handleChange,
    handleValidate,
    handleRefreshTrasaction,
    handleGenerateReceipt,
    handleTryAgain,
    handleOtpValidationSubmit,
    handleBackToNewTransfer,
    handleScreenChangeNext,
    handleScreenChangePrev,
    setAccountToDebit,
    // handleBackToFundsTransfer,
    handleSelectedBeneficiary,
  } = useSavedBeneficiary(tabsRef);
  const { selectedTransferBeneficiary } = usePaymentState();
  const { nipCharges, handleGetNIPCharges } = useNIPCharges();

  useEffect(() => {
    handleGetNIPCharges();
  }, []);

  return (
    <div>
      {isLoading && <PageLoader message="" />}
      {showScreen === 1 && (
        <ListAllSavedBeneficiaries
          title="Transfer to saved beneficiary"
          accountNumber={accountNumber}
          // handleChange={handleChange}
          handleBackToFundsTransfer={handleBackToNewTransfer}
          handleScreenChangeNext={handleScreenChangeNext}
          savedBeneficiaryList={savedBeneficiaryList}
          handleSelectedBeneficiary={handleSelectedBeneficiary}
        />
      )}
      {showScreen === 2 && (
        <NewTransferComponent2
          accountToDebit={accountToDebit}
          selectedBeneficiary={selectedTransferBeneficiary}
          selectedBank={selectedBank}
          beneficiaryName={beneficiaryName}
          accountNumber={accountNumber}
          accounts={allAccounts}
          amount={amount}
          description={description}
          handleChange={handleChange}
          setAccountToDebit={setAccountToDebit}
          handleScreenChangePrev={handleScreenChangePrev}
          handleScreenChangeNext={handleScreenChangeNext}
        />
      )}
      {showScreen === 3 && (
        <NewTransferComponent3
          selectedBeneficiary={selectedTransferBeneficiary}
          showPinError={showPinError}
          pinError={pinError}
          accountToDebit={accountToDebit}
          beneficiaryName={beneficiaryName}
          accountNumber={accountNumber}
          amount={amount}
          nipCharges={nipCharges}
          selectedBank={selectedBank}
          pin={pin}
          setPin={setPin}
          authToken={authToken}
          setAuthToken={setAuthToken}
          isPinValidated={isPinValidated}
          handleChange={handleChange}
          allowSubmit={allowSubmit}
          handleScreenChangePrev={handleScreenChangePrev}
          handleScreenChangeNext={handleScreenChangeNext}
          handleSubmit={handleSubmit}
          handleValidate={handleValidate}
          // responseCode={responseCode}
        />
      )}
      {showScreen === 4 && (
        <OTPVerification
          // accountToDebit={accountToDebit}
          // beneficiaryName={beneficiaryName}
          // accountNumber={accountNumber}
          // amount={amount}
          // selectedBank={selectedBank}
          otpMsg={otpMessage}
          otp={otp}
          setOtp={setOtp}
          handleChange={handleChange}
          isLoading={isLoading}
          handleScreenChangePrev={handleScreenChangePrev}
          // handleScreenChangeNext={handleScreenChangeNext}
          handleSubmit={handleSubmit}
          correlationId={correlationId}
          handleOtpValidationSubmit={handleOtpValidationSubmit}
        />
      )}
      {showScreen > 4 && (
        <div className="">
          <ResponseStatus
            type={
              showScreen === 5
                ? "pending"
                : showScreen === 6
                  ? "successful"
                  : showScreen === 7
                    ? "failed"
                    : "nothing"
            }
            onRefresh={handleRefreshTrasaction}
            onGenerateReceipt={handleGenerateReceipt}
            amount={amount.withMoneyFormat}
            recipient={selectedTransferBeneficiary?.accountName}
            saveBeneficiary={true}
            accountNumber={accountNumber}
            bankCode={selectedBank.bankCode}
            bankName={selectedBank.bankName}
            pin={pin}
            correlationId={correlationId}
            onRetry={handleTryAgain}
          />
        </div>
      )}
      {showScreen === 8 && <SuccessScreen />}
      {/* {showScreen === 5 && (
        <div className="ml-56">
          <PaymentPending onRefresh={handleRefreshTrasaction} />
        </div>
      )}
      {showScreen === 6 && (
        <div className="ml-56">
          <PaymentSuccessful
            onGenerateReceipt={handleGenerateReceipt}
            amount={amount}
            recipient={beneficiaryName}
            saveBeneficiary={true}
            accountNumber={accountNumber}
            bankCode={selectedBank.bankCode}
            bankName={selectedBank.bankName}
            pin={pin}
            correlationId={correlationId}
          />
        </div>
      )}
      {showScreen === 7 && (
        <div className="ml-56">
          <PaymentFailed onRetry={handleTryAgain} />
        </div>
      )} */}
    </div>
  );
};

export default SavedBeneficiary;
