import * as Yup from "yup";

const containsNumbersRegex = /^\d+$/;
export const addNewBeneficiarySchema = ({
  hasPin,
  hasSoftToken,
  useHardToken,
}: {
  hasPin: boolean;
  hasSoftToken: boolean;
  useHardToken: boolean;
}) => {
  return Yup.object({
    accountNumber: Yup.string()
      .required("Please enter your account number")
      .length(10, "Your account number must be 10 digits"),
    softToken: hasSoftToken
      ? Yup.string()
          .trim("Token cannot contain spaces")
          .required("Please enter your current soft token")
          .matches(containsNumbersRegex, {
            message: "Your token should only contain numbers",
            excludeEmptyString: true,
          })
          .test(
            "token",
            "Your token cannot be less than 6 digits",
            (value) => value?.length >= 6
          )
      : Yup.string().notRequired(),
    hardTokenOtp: useHardToken
      ? Yup.string()
          .trim("Hard token otp cannot contain spaces")
          .required("Please enter your current hard token otp")
          .matches(containsNumbersRegex, {
            message: "Your token should only contain numbers",
            excludeEmptyString: true,
          })
          .test(
            "token",
            "Your hard token otp cannot be less than 6 digits",
            (value) => value?.length >= 6
          )
      : Yup.string().notRequired(),
    hardTokenPin: useHardToken
      ? Yup.string()
          .trim("Hard token pin cannot contain spaces")
          .required("Please enter your hard token PIN")
          .length(4, "PIN must be 4 digits")
          .matches(containsNumbersRegex, {
            message: "Your hard token PIN should only contain numbers",
            excludeEmptyString: true,
          })
          .test(
            "pin",
            "Your Current hard token PIN can only contain numbers",
            (value) => !Number.isNaN(Number(value))
          )
      : Yup.string().notRequired(),
    pin: hasPin
      ? Yup.string()
          .trim("AFB PIN cannot contain spaces")
          .required("Please enter your AFB PIN")
          .length(4, "AFB PIN must be 4 digits")
          .matches(containsNumbersRegex, {
            message: "Your AFB PIN should only contain numbers",
            excludeEmptyString: true,
          })
          .test(
            "pin",
            "Your Current AFB PIN can only contain numbers",
            (value) => !Number.isNaN(Number(value))
          )
      : Yup.string().notRequired(),
    alias: Yup.string().notRequired(),
  });
};
