import React, { FC } from "react";

interface CircularProgressBarProps {
  num?: number;
  deNum?: number;
  size?: number;
  strokeWidth?: number;
  textFont?: string;
  percent?: number;
  pathColor?: string;
  circleColor?: string;
  textColor?: string;
}

export const CircularProgressBar: FC<CircularProgressBarProps> = ({
  num,
  deNum,
  size = 200,
  strokeWidth = 10,
  textFont = "text-2xl",
  percent,
  pathColor = "text-[#FAF0F3]",
  circleColor = "text-lib-alat-dark-red",
  textColor = "text-lib-alat-dark-red",
}) => {
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset =
    circumference -
    (percent ? percent / 100 : num && deNum ? num / deNum : 0) * circumference;

  return (
    <div className="relative">
      <svg
        className="block mx-auto"
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
      >
        <circle
          className={`stroke-current ${pathColor}`}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className={`stroke-current ${circleColor}`}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={progressOffset}
          transform={`rotate(-90 ${center} ${center})`}
          strokeLinecap="round"
        />
        <text
          className={`fill-current ${textColor} font-bold ${textFont}`}
          x={center}
          y={center}
          textAnchor="middle"
          dominantBaseline="central"
        >
          {percent || percent === 0
            ? `${percent}%`
            : num && deNum
              ? `${num}/${deNum}`
              : ""}
        </text>
      </svg>
    </div>
  );
};
