import { useState } from "react";
import {
  POST_ACCEPT_E_NONWEMA_REFEREE,
  POST_ACCEPT_NONWEMA_REFEREE,
} from "../../../utils/apis/routes";
import { AcceptOtherBankRefereePayload } from "../../../types/global";
import { useApiRequest } from "hooks/useApiRequest";
import { ToastNotify } from "helpers/toastNotify";

export const useAcceptNonWemaReferee = () => {
  //Local States
  const [nonWemaRefereeStatus, setNonWemaRefereeStatus] =
    useState<boolean>(false);
  const [nonWemaRefereeLoading, setNonWemaRefereeLoading] =
    useState<boolean>(false);
  const [nonWemaRefereeError, setNonWemaRefereeError] = useState<string>("");
  const [nonWemaRefereeData, setNonWemaRefereeData] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const handleNonWemaRefereeValidation = async (
    payload: AcceptOtherBankRefereePayload
  ) => {
    setNonWemaRefereeLoading(true);
    setNonWemaRefereeStatus(false);

    const API_URL = payload?.eReferenceId
      ? POST_ACCEPT_E_NONWEMA_REFEREE
      : POST_ACCEPT_NONWEMA_REFEREE;
    const REQUEST_TYPE = payload?.eReferenceId ? "ereference" : "onboarding";

    try {
      const res = await makeRequest({
        method: "post",
        route: API_URL,
        body: payload,
        originType: REQUEST_TYPE,
      });
      // console.log(res)
      //Map the payload
      if (res?.data) {
        setNonWemaRefereeData(res?.data);
        if (res?.status === 200) {
          setNonWemaRefereeStatus(true);
        } else if (res?.status === 400) {
          const resMsg =
            res?.data?.data?.responseMessage?.actionMessage ||
            res?.data?.data?.responseMessage.title;

          ToastNotify({
            type: "error",
            message: resMsg,
            position: "top-right",
          });
        }
      }
      setNonWemaRefereeLoading(false);
    } catch (error) {
      setNonWemaRefereeError(
        "An error occured while fetching branch sol ids, please referesh and try again"
      );
      setNonWemaRefereeLoading(false);
    }
  };

  return {
    nonWemaRefereeStatus,
    nonWemaRefereeLoading,
    nonWemaRefereeError,
    nonWemaRefereeData,
    handleNonWemaRefereeValidation,
  };
};
