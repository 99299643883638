import { Storage } from "utils/inAppstorage";
import { AdminDashboard, UserDashboard } from "../pages";
import { lazy } from "react";

const ViewAllAccounts = lazy(() =>
  import("pages/Dashboard/ViewAllAccounts").then(({ ViewAllAccounts }) => ({
    default: ViewAllAccounts,
  }))
);

const ViewAccountManager = lazy(() =>
  import("pages/Dashboard/ViewAccountManager").then(
    ({ ViewAccountManager }) => ({ default: ViewAccountManager })
  )
);

const ViewAllAccountsAdmin = lazy(() =>
  import("pages/Dashboard/ViewAllAccountsAdmin").then(
    ({ ViewAllAccountsAdmin }) => ({
      default: ViewAllAccountsAdmin,
    })
  )
);

const isProtected = Storage.getItem("afb-app-token");

const protectedRoutes: any = [
  {
    path: "/user-dashboard",
    isAuthenticated: true,
    element: <UserDashboard />,
  },
  {
    path: "/admin-dashboard",
    isAuthenticated: true,
    element: <AdminDashboard />,
  },
  {
    path: "/user-dashboard/accounts",
    isAuthenticated: true,
    element: <ViewAllAccounts />,
  },
  {
    path: "/dashboard/account-manager",
    isAuthenticated: true,
    element: <ViewAccountManager />,
  },
  {
    path: "/admin-dashboard/accounts",
    isAuthenticated: true,
    element: <ViewAllAccountsAdmin />,
  },
];

const unProtectedRoutes: any = [];

export const dashboardRoutes = isProtected
  ? protectedRoutes
  : unProtectedRoutes;
