import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import { GET_ADDRESS } from "../../../utils/apis/routes";

export const useAddress = () => {
  //Local States
  const [addressLoading, setAddressLoading] = useState<boolean>(false);
  const [addressData, setaddressData] = useState<any>({});
  const [addressError, setAddressError] = useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleAddress = async () => {
    setAddressLoading(true);
    try {
      const res = await makeRequest({
        method: "get",
        route: GET_ADDRESS,
        originType: "onboarding",
      });
      // console.log(res)
      //Map the payload
      setaddressData(res?.data?.data);
      setAddressLoading(false);
    } catch (error) {
      setAddressError(
        "An error occured while fetching branch sol ids, please referesh and try again"
      );
      setAddressLoading(false);
    }
  };

  return {
    addressData,
    addressLoading,
    addressError,
    handleAddress,
  };
};
