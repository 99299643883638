export type AccountNumberValidationPayload = {
  accountNumber: string;
};

export type BVNValidationAPIPayload = {
  bvn: string;
  cacRegNumber?: string;
  businessRequest?: {
    reg_number: string;
    businessType: number;
  };
};

export type ValidateCardNumber = {
  cardNumber: string;
  correlationId?: string;
  businessRegistrationRequest?: {
    reg_number?: string;
    businessType: number;
  };
};
export interface IPayWithCard extends ValidateCardNumber {
  accountType?: number | string;
  correlationId: string;
  cardMonth: string;
  cardYear: string;
  securityCode: string;
  amount: number;
  transactionId: string;
}
export type TINValidationAPIPayload = {
  tax_number: string;
  businessRequest: {
    reg_number: string;
    businessType: number;
  };
};

export type BusinessCACValidationAPIPayload = {
  reg_number: string;
  businessType: number | any;
};

export type FacematchUserRegAPIPayload = {
  email: string;
  cif: string;
  phoneNumber: string;
  bvn: string;
  channel: string;
  device: number;
};

export type FacematchSelfieSubmitAPIPayload = {
  email: string;
  cif: string;
  phoneNumber: string;
  bvn: string;
  channel: string;
  device: number;
};

export type FacematchVerificationAPIPayload = {
  bvn: string;
  dimecid: string;
};

export type CreateExistingProfilePayload = {
  username?: string;
  fullName?: string;
  mobileNo?: string;
  emailAddress?: string;
  password?: string;
  correlationId?: string;
  cif?: string;
  securityQuestions?: SecurityQuestion[];
};

export type BusinessSetupPayload = {
  email: string;
  registrationType: number;
  businessCategory: string;
  businessSubCategory: string;
  branchRegion: string;
  preferredBranch: string;
};
export type CreateProfilePayload = {
  username?: string;
  fullName?: string;
  mobileNo?: string;
  emailAddress?: string;
  referralCode?: string;
  password?: string;
  correlationId?: string;
  accountType?: string;
  securityQuestions?: SecurityQuestion[];
  businessAddress?: BusinessAddress;
  contactAddress?: ContactAddress;
};

export type BusinessAddress = {
  address?: string;
  state?: string;
  city?: string;
  lga?: string;
  lcda?: string;
  branchRegion?: string;
  preferredBranch?: string;
};

export type ContactAddress = {
  address?: string;
  state?: string;
  city?: string;
  lga?: string;
  lcda?: string;
  nearestLandmark?: string;
};

export type SecurityQuestion = {
  question?: string;
  answer?: string;
};

export type VirtualAccountAPIPayload = {
  businessRegistrationRequest?: {
    reg_number: string;
    businessType: number;
  };
  accountType?: number;
  amount?: number;
  correlationId: string;
};

type CompanyInfoValidationRequestPayload = {
  adminCif?: string;
  staffId?: string;
};

export type UserDetailsValidationPayload = {
  phonenumber?: string;
  email?: string;
  username?: string;
  companyInfoValidationRequest?: CompanyInfoValidationRequestPayload;
  // companyInfoValidationRequest: {
  //   adminCif: "",
  //   staffId: ""
  // }
};

// Referee Verification //

export type SaveWemaRefereePayload = {
  correlationId: string;
  accountNumber: string;
};

export type SaveNonWemaRefereePayload = {
  correlationId: string;
  referees: Array<OtherRefereePayload>;
};

export type OtherRefereePayload = {
  bank: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  accountNumber: string;
};

export type AcceptOtherBankRefereePayload = {
  id: number;
  correlationId?: string;
  eReferenceId?: string;
  businessName: string;
  businessAddress: string;
  bank: string;
  accountNumber: string;
  relationshipDuration: string;
  phoneNumber: string;
  phoneNumber2: string;
  signature: string;
  emailAddress: string;
  refereeForm: string;
  isRefereeResubmission: boolean;
};

export type AcceptWemaRefereePayload = {
  id: number;
  correlationId?: string;
  eReferenceId?: string;
  emailAddress: string;
  isERef: boolean;
  isRefereeResubmission: boolean;
};

export type ResendRefereeEmailPayload = {
  id: any;
  correlationId: string;
};

export type ChangeRefereePayload = {
  id: any;
  correlationId: string;
  bank: string;
  accountNumber: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
};

export type TransactionHistoryPayload = {
  accountNumber: string;
  from: Date | string;
  to: Date | string;
  page: number;
  pageSize: string;
  transactionOperation: number;
  currencyCode?: string;
};

export type TransactionReceiptPayload = {
  transReference: string;
  accountNumber: string;
  version: string;
};

export type TransactionReceiptByCorrelationIdPayload = {
  correlationId: string;
  version: string;
};
// End of Referee Verification //

export enum OnboardingStageEnum {
  BvnValidation = 4,
  AlatPay = 5,
  Refree = 6,
  PendingVerification = 7,
  Completed = 8,
  DudChequeFailed = 10,
  PendingLicenseReview = 11,
  LicenseReviewFailed = 12,
}
