import { TrashIcon } from "@heroicons/react/24/solid";
import { Card } from "flowbite-react";
import React from "react";

// import { ReactComponent as BankIcon } from "../../../../assets/svgs/bank-building.svg";
import { ReactComponent as BankIcon } from "../../../../assets/svgs/bank-building.svg";
interface IGroupCardProps {
  groupName: string;
  key: any;
  referenceId: any;
  transferGroupDetails: any;
  // currency: any;
  handleSelectedBeneficiary: any;
}

const GroupCard = ({
  groupName,
  key,
  // bankCode,
  referenceId,
  transferGroupDetails,
  // currency,
  handleSelectedBeneficiary,
}: IGroupCardProps) => {
  return (
    <div
      id={`saved-group-${key}_btn`}
      className="cursor-pointer mb-5"
      // onClick={handleSelectedBeneficiary(bankName)}
      onClick={() => handleSelectedBeneficiary(referenceId)}
    >
      <Card className="box-border md:w-[450px] w-full h-[114px] rounded-lg dark:!border-[#E4DFDF] dark:bg-white shadow-white hover:text-white hover:bg-primary fill-primary hover:fill-white">
        <div className="flex flex-row justify-center items-center">
          <div className="mr-auto w-12">
            <div className="">
              <BankIcon fill="inherit" />
            </div>
          </div>
          <div className="md:w-96 w-full">
            <div className="flex flex-row mb-2">
              <div
                className="text-base text-left font-medium mr-auto"
                id={`saved-group-name-${key}_text`}
              >
                {groupName}
              </div>
              <div className="w-5 ml-auto mt-5">
                <TrashIcon fill="black" />
              </div>
            </div>
            <div className="flex flex-row">
              <div className="text-[10px] text-left font-normal mr-auto">
                <span id={`saved-group-number-${key}_text`}>
                  {transferGroupDetails.length}
                </span>
                <span>Recipients</span>
              </div>
              {/* <div className="text-[10px] font-normal ml-auto">something</div> */}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default GroupCard;
