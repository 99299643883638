import { TabsRef, Tabs, Flowbite } from "flowbite-react";
import React, { FC, useRef, useState } from "react";

import NewTransfer from "./BankTransfer/NewTransfer/NewTransfer";
import SavedBeneficiary from "./BankTransfer/NewTransfer/SavedBeneficiary";
import MultipleTransferMain from "./BankTransfer/MultipleTransferMain";

import { useLocation, useNavigate } from "react-router-dom";

const BankTransferTab: FC = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const tab = parseInt(hash[1]);

  const [activeTab, setActiveTab] = useState<any>(tab);
  const tabsRef = useRef<TabsRef>(null);
  const theme = {
    tabs: {
      tablist: {
        base: "flex text-center gap-5 z-0",
        tabitem: {
          base: "flex text-center",
          variant: {
            underline: {
              base: "rounded-t-lg",
              active: {
                on: "active py-2 rounded-t-lg border-b-2 border-lib-alat-red text-lib-alat-red font-medium",
                off: "py-2 border-b-2 border-transparent text-lib-alat-black font-medium",
              },
            },
          },
        },
      },
    },
  };
  return (
    <div className="w-full">
      {/* <div>Active tab: {activeTab === 1}</div> */}
      <Flowbite theme={{ theme }}>
        <Tabs
          aria-label="Default tabs"
          // eslint-disable-next-line react/style-prop-object
          variant="underline"
          ref={tabsRef}
          onActiveTabChange={(tab: React.SetStateAction<number>) =>
            setActiveTab(tab)
          }
          className="dark:!border-[#E4DFDF] !border-[#E4DFDF] w-full"
        >
          <Tabs.Item
            active={activeTab === 0}
            title="New Transfer"
            className="dark:!text-primary dark:!border-primary"
            id="new-transfer_tab"
          >
            <NewTransfer />
          </Tabs.Item>
          <Tabs.Item
            active={activeTab === 1}
            title="Saved beneficiary"
            className=""
            id="saved-beneficiary_tab"
          >
            <SavedBeneficiary tabsRef={tabsRef} />
          </Tabs.Item>
          <Tabs.Item
            active={activeTab === 2}
            title="Multiple transfer"
            id="multiple-transfer_tab"
          >
            <MultipleTransferMain tabsRef={tabsRef} />
          </Tabs.Item>
        </Tabs>
      </Flowbite>
    </div>
  );
};

export default BankTransferTab;
