import ErrorScreen from "./ErrorScreen";
import OtpValidation from "./OtpValidation";
import SelectAccount from "./SelectAccount";
import ShowExcelList from "./ShowExcelList";
import SuccessScreen from "./SuccessScreen";
import SuccessScreenSP from "./SuccessScreenSP";

const TabScreens = ({ activeTab, setActiveTab, screenName }: any) => {
  return (
    <div>
      {activeTab === `excel-list-${screenName}-tab` && (
        <ShowExcelList setActiveTab={setActiveTab} screenName={screenName} />
      )}
      {activeTab === `select-account-${screenName}-tab` && (
        <SelectAccount setActiveTab={setActiveTab} screenName={screenName} />
      )}
      {activeTab === `otp-${screenName}-tab` && (
        <OtpValidation setActiveTab={setActiveTab} screenName={screenName} />
      )}
      {activeTab === `success-${screenName}-tab` && (
        <SuccessScreen setActiveTab={setActiveTab} screenName={screenName} />
      )}
      {activeTab === `sp-success-${screenName}-tab` && (
        <SuccessScreenSP setActiveTab={setActiveTab} screenName={screenName} />
      )}
      {activeTab === `error-${screenName}-tab` && (
        <ErrorScreen setActiveTab={setActiveTab} screenName={screenName} />
      )}
    </div>
  );
};

export default TabScreens;
