import { CheckIcon } from "@heroicons/react/24/solid";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PageHeader from "components/reusables/Page/PageHeader";
import { Button, Card } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import FundsTransferModalTab from "./FundTransferModalTab";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";

const InitiatorApprove = () => {
  const navigate = useNavigate();
  const paymentState = usePaymentState();
  // console.log("test", paymentState?.transferResponseMessage);

  return (
    <DashboardWrapper>
      <main className="max-h-screen overflow-auto">
        <div className="w-full mx-auto">
          <div className="rounded-3xl mb-5 mt-5 space-y-8">
            <div className="ml-4">
              <PageHeader title="Funds Transfer" />
            </div>
            <Card className="md:w-[768px] w-full bg-white dark:border-gray-200 mx-auto">
              <div>
                <div className="text-center flex justify-center mt-10">
                  <div className="flex justify-center items-center w-16 h-16 bg-green-200 rounded-full">
                    <div className="flex justify-center items-center w-10 h-10 bg-green-600 rounded-full">
                      <div className=" flex justify-center items-center w-8 h-8 bg-white rounded-full">
                        <div className=" flex justify-center items-center w-6 h-6 bg-green-600 rounded-full">
                          <CheckIcon className="mx-auto w-6 h-6 text-white opacity-100 light:text-gray-200" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="text-center text-3xl font-semibold mt-6">
                          Transaction request sent
                        </div> */}
                <h3 className="text-center mb-5 text-lg font-normal text-green-600 mt-10">
                  {paymentState?.transferResponseMessage?.actionMessage}
                </h3>
                <div className="text-center mb-5 text-lg font-normal text-gray-500 mt-10 mx-10 p-3 bg-[#F5F5F5]">
                  {paymentState?.transferResponseMessage?.title ??
                    "We will send you a notification as soon as the request is approved"}
                </div>
                <div className="flex justify-center flex-row gap-4 mb-40 mt-10">
                  <Button
                    onClick={() => navigate("/user-dashboard")}
                    className="text-white border-primary border  md:w-[188px] w-full bg-primary hover:bg-primary focus:ring-1   "
                  >
                    Return to dashboard
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </main>
    </DashboardWrapper>
  );
};

export default InitiatorApprove;
