import React from "react";
import { useAppSelector } from "hooks/useReduxHook";
import CopyIcon from "../../../assets/svgs/copy-icon.svg";
import Timer from "components/Onboarding/Timer";
import { FormikButton } from "alat-components-library";
import { IBankTransfer } from "../Components/CompletePaymentModal/BankTransfer";
import { formatNumberToCurrency } from "../../../utils/helperFunctions";

const BankTransfer = ({
  handleCopy,
  copied,
  resetTimer,
  payAmount,
  validatePayment,
}: IBankTransfer) => {
  const onboardingState = useAppSelector((state) => state.onboarding);

  const amount = formatNumberToCurrency({
    number: payAmount ?? onboardingState?.paymentAmount ?? 0,
  });

  return (
    <div className="w-full flex flex-col justify-center gap-4">
      <div className="bg-[#f5f5f5] h-24 flex flex-col justify-center rounded-xl">
        <h3 className="text-center text-2xl font-bold text-lib-alat-dark-red">
          Transfer &nbsp;
          <span id="payment-amount_text">{amount}</span>
        </h3>
        <p className="text-center text-lg text-lib-alat-gray-light font-semibold">
          to WEMA BANK
        </p>
      </div>
      <div className="mt-3">
        <div className="h-12 flex justify-around rounded-lg border border-lib-alat-dark-red text-center">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={handleCopy}
            id="copy-accountNo_btn"
          >
            <h3 className="text-xl font-semibold" id="payment-account_text">
              {onboardingState?.unRegisteredBusiness?.paymentAccount ||
                onboardingState?.paymentAccount}
            </h3>
            <p className="cursor-pointer">
              {copied ? "Copied!" : "Tap to copy"}
            </p>
            <img src={CopyIcon} alt="copy" className="cursor-pointer" />
          </div>
        </div>
        <p className="text-lib-alat-gray-light text-center mt-2 mb-3">
          Expires in <Timer reset={resetTimer} min={30} start={true} />
        </p>
        <p className="mt-3 text-lib-alat-dark-red italic">
          Please be informed that this is only a virtual account and NOT your
          corporate account number
        </p>
      </div>
      <div className="w-full" onClick={validatePayment}>
        <FormikButton
          id="confirm-payment_btn"
          labelText="I've sent the money"
          containerVariant="w-full text-center h-12 rounded-md flex justify-center mb-3"
        />
      </div>
    </div>
  );
};

export default BankTransfer;
