import FilterHistoryTable from "./general/FilterHistoryBox";
import AllBulkTransactionTable from "./general/AllBulkTransactionTable";
import OneBulkHistoryTable from "./general/OneBulkHistoryTable";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";

const TransactionHistory = () => {
  const { oneBulkTransactionID } = usePaymentState();

  return (
    <div>
      <FilterHistoryTable />
      {oneBulkTransactionID ? (
        <OneBulkHistoryTable />
      ) : (
        <AllBulkTransactionTable />
      )}
    </div>
  );
};

export default TransactionHistory;
