import React, { FC, useEffect, useState } from "react";

interface TimerProps {
  reset?: boolean;
  start?: boolean;
  pause?: boolean;
  min?: number;
  sec?: number;
  onTimerEnd?: () => void;
}

const Timer: FC<TimerProps> = ({
  reset,
  min = 30,
  sec = 0,
  start = false,
  pause = false,
  onTimerEnd,
}) => {
  const [currentMin, setCurrentMin] = useState<number>(min);
  const [currentSec, setCurrentSec] = useState<number>(sec);

  useEffect(() => {
    let startTimeStr = localStorage.getItem("timerStartTime_v2");
    let startTime: number;

    if (startTimeStr) {
      startTime = parseInt(startTimeStr);
    } else {
      startTime = new Date().getTime() + (currentMin * 60 + currentSec) * 1000;
      localStorage.setItem("timerStartTime_v2", startTime.toString());
    }

    let intervalId: NodeJS.Timeout;

    if (reset) {
      setCurrentMin(min);
      setCurrentSec(sec);
      localStorage.removeItem("timerStartTime_v2");
    } else if (start && !pause) {
      intervalId = setInterval(() => {
        const now = new Date().getTime();
        const distance = startTime - now;
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(intervalId);
          if (onTimerEnd) {
            onTimerEnd();
          }
          localStorage.removeItem("timerStartTime_v2");
        } else {
          setCurrentMin(minutes);
          setCurrentSec(seconds);
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [reset, min, sec, start, pause, onTimerEnd, currentMin, currentSec]);

  return (
    <span>
      {`${currentMin < 10 ? "0" + currentMin : currentMin}:${currentSec < 10 ? "0" + currentSec : currentSec}`}
    </span>
  );
};

export default Timer;
