import { useEffect, useState } from "react";
import { AccountCardOne } from "../../../alat-components-library";
import { ReactComponent as PrevSlideBtn } from "../../../alat-components-library/assets/images/icons/back-arrow.svg";
import { ReactComponent as NextSlideBtn } from "../../../alat-components-library/assets/images/icons/next-arrow.svg";
import { useAuthState } from "../../../react-wrappers/stores/redux/auth/slices";
import { getCountryCode } from "../../../utils/helperFunctions";
import PendingAccountCard from "../components/PendingAccountType";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/useReduxHook";
import { GET_ALL_ACCOUNTS_ACTION } from "../../../react-wrappers/stores/redux/accounts/actions";
import {
  setFundMyAcctSelectedAccout,
  usePaymentState,
} from "../../../react-wrappers/stores/redux/payments/slices";
import { ToastNotify } from "../../../helpers/toastNotify";
import { useVerifyAccountNumber } from "../../../hooks/apis/AccountReactivation/useVerifyAccount";
import { UserAccountNumber } from "../../../react-wrappers/stores/redux/interfaces";
import CenteredModal from "../../../alat-components-library/Modal/CenterModal";
import { ReactComponent as WarningIcon } from "../../../alat-components-library/assets/images/modals/warning-error-icon.svg";
import PageLoader from "../../../components/Loader/PageLoader";
import { useAccountReactivationState } from "../../../react-wrappers/stores/redux/accountReactivation/slices";
import { ACCOUNT_REACTIVATION_URL } from "routers/accountReactivationRoutes";

const AccountSlide = () => {
  const [errorModal, setErrorModal] = useState(false);
  const [measureWidth, setMeasureWidth] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux State
  const { user, userRole } = useAuthState();
  const { fundMyAcctSelectedAccout } = usePaymentState();
  const userAccounts = useAppSelector((state) => state.accounts);
  const isAdmin = user?.role?.toLowerCase() === "admin";

  // ACCOUNT VERIFICATION
  const { handleUserAcountVerification, status, responseData } =
    useVerifyAccountNumber();
  const { loading } = useAccountReactivationState();
  const isVerifyingAccount = loading?.includes("GET_VERIFY_ACCOUNT_INSIDE_APP");
  const handleAccountVerification = (values: UserAccountNumber) => {
    handleUserAcountVerification(values);
  };
  const resMsg = responseData?.responseMessage;
  useEffect(() => {
    if (responseData?.responseMessage?.title) {
      setErrorModal(true);
    }
  }, [responseData?.responseMessage]);

  // Open docunment modal
  useEffect(() => {
    if (status) {
      navigate("/dashboard/account-reactivation");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    const width = Number(user?.accounts?.length) * 300 + 30;
    setMeasureWidth(width);
  }, [measureWidth]);

  useEffect(() => {
    fundMyAcctSelectedAccout && dispatch(setFundMyAcctSelectedAccout(null));
    dispatch(GET_ALL_ACCOUNTS_ACTION());
  }, []);

  const scrollSlider = (direction: string) => {
    const element = document.querySelector(".overflow-x-auto") as HTMLElement;
    const distance = 500;
    const duration = 1000;
    const start = element.scrollLeft;
    const end = direction === "left" ? start - distance : start + distance;
    const startTime = performance.now();

    const easeOutCubic = (t: number, b: number, c: number, d: number) => {
      return c * ((t = t / d - 1) * t * t + 1) + b;
    };

    const scroll = (timestamp: number) => {
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easeProgress = easeOutCubic(progress, 0, 1, 1);
      const newPosition = Math.floor(easeProgress * (end - start)) + start;
      element.scrollTo({ left: newPosition });

      if (progress < 1) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };

  const handleClick = (e: any, type: any, index: number, item: any) => {
    // console.log(userRole);
    // Update a accout state
    if (type?.toLowerCase() === "view") {
      return ToastNotify({
        type: "error",
        message: "This feature is coming soon",
        position: "top-right",
      });
    }
    if (type === "see more") {
      navigate("/payments/transaction-history", { state: index });
    }
    if (type === "fund-account") {
      // Update the state
      if (
        userRole !== "initiator" &&
        userRole !== "soleproprietor" &&
        userRole !== "initiatorapprover"
      ) {
        return ToastNotify({
          type: "error",
          message: "This account role is not enabled to fund account",
          position: "top-right",
        });
      }
      dispatch(setFundMyAcctSelectedAccout(item));
      navigate("/payments/funds-transfer/fund-my-account");
    }
  };
  const accounts =
    userAccounts?.allAccounts?.length > 0
      ? userAccounts.allAccounts
      : user?.accounts;

  return (
    <>
      {isVerifyingAccount && <PageLoader message="Validating Account Number" />}

      <div
        className="w-full h-[272px] bg-white mt-5 rounded-md"
        style={{ boxShadow: "0px 4px 9px -8px rgba(0, 0, 0, 0.13)" }}
      >
        <div className="border-b h-14 w-11/12 mx-auto text-xs flex justify-start items-center font-bold border-[#E4DFDF]">
          YOUR ACCOUNT(S)
        </div>
        {(accounts === null || accounts?.length < 1) && (
          <div className="flex items-center h-48 mx-6">
            <PendingAccountCard
              handleClick={() => {
                dispatch(GET_ALL_ACCOUNTS_ACTION());
              }}
            />
          </div>
        )}

        {accounts?.length <= 3 && accounts?.length > 0 && (
          <div className="mt-[13px] w-full flex flex-row justify-start relative items-center overflow-x-auto">
            <div
              className={`h-48 flex flex-row justify-center  relative items-center  space-x-3`}
              style={{ width: `${measureWidth}px` }}
            >
              {(accounts || []).map((item: any, index: any) => {
                let availableBalance: any = {
                  currency: "NGN",
                  unicodeCharacter: "₦",
                };

                let ledgerBalance: any = {
                  currency: "NGN",
                  unicodeCharacter: "₦",
                };

                let background = "light-purple";

                if (
                  item?.accountDescription?.toLowerCase()?.includes("current")
                ) {
                  background = "dark-purple";
                }

                if (item?.currency?.toLowerCase() === "usd") {
                  background = "grey-purple";
                  availableBalance = {
                    currency: getCountryCode("united states")?.currency_code,
                    unicodeCharacter: undefined,
                  };
                  ledgerBalance = {
                    currency: getCountryCode("united states")?.currency_code,
                    unicodeCharacter: undefined,
                  };
                }
                return (
                  <AccountCardOne
                    key={item?.accountId}
                    title={item?.accountName}
                    accountNumber={item?.accountNo}
                    availableBalance={{
                      value: item?.balance,
                      currency: item?.currency,
                    }}
                    ledgerBalance={{
                      value: item?.ledgerBalance,
                      currency: item?.currency,
                    }}
                    onHandleClick={(e, btnType) => {
                      const isInactive =
                        item?.accountStatus?.toUpperCase() === "INACTIVE";
                      const accountNumber = item?.accountNo;
                      if (isInactive) {
                        handleAccountVerification({
                          accountNumber,
                          isInApp: true,
                        });
                      } else {
                        handleClick(e, btnType, index, item);
                      }
                    }}
                    bgType={background}
                    numOfUsers={item?.numberOfUsers}
                    isAdmin={isAdmin}
                    accountTypeTitle={item?.accountStatus?.toUpperCase()}
                    // textToCopy={item.accountNo}
                  />
                );
              })}
            </div>
          </div>
        )}
        {accounts?.length > 3 && accounts?.length > 0 && (
          <div className="flex w-full space-x-2 px-6">
            <div
              className="flex items-center justify-center cursor-pointer w-1/12"
              id="leftarrow_btn"
              onClick={() => scrollSlider("left")}
            >
              <PrevSlideBtn />
            </div>
            <div className="w-10/12 h-auto item-center overflow-x-auto hide-scroller px-0">
              <div
                className={`h-48 flex flex-row justify-start relative items-center space-x-3`}
                style={{ width: `${measureWidth}px` }}
              >
                {(accounts || []).map((item: any, index: any) => {
                  let availableBalance: any = {
                    currency: "NGN",
                    unicodeCharacter: "₦",
                  };

                  let ledgerBalance: any = {
                    currency: "NGN",
                    unicodeCharacter: "₦",
                  };

                  let background = "light-purple";

                  if (
                    item?.accountDescription?.toLowerCase()?.includes("current")
                  ) {
                    background = "dark-purple";
                  }

                  if (item?.currency?.toLowerCase() === "usd") {
                    background = "grey-purple";
                    availableBalance = {
                      currency: getCountryCode("united states")?.currency_code,
                      unicodeCharacter: undefined,
                    };
                    ledgerBalance = {
                      currency: getCountryCode("united states")?.currency_code,
                      unicodeCharacter: undefined,
                    };
                  }

                  return (
                    <div className="w-full" key={index}>
                      <AccountCardOne
                        key={item?.accountId}
                        title={item?.accountName}
                        accountNumber={item?.accountNo}
                        availableBalance={{
                          value: item?.balance,
                          currency: item?.currency,
                        }}
                        ledgerBalance={{
                          value: item?.ledgerBalance,
                          currency: item?.currency,
                        }}
                        onHandleClick={(e, btnType) => {
                          const isInactive =
                            item?.accountStatus?.toUpperCase() === "INACTIVE";
                          const accountNumber = item?.accountNo;
                          if (isInactive) {
                            handleAccountVerification({
                              accountNumber,
                              isInApp: true,
                            });
                          } else {
                            handleClick(e, btnType, index, item);
                          }
                        }}
                        bgType={background}
                        numOfUsers={item?.numberOfUsers}
                        isAdmin={isAdmin}
                        accountTypeTitle={item?.accountStatus?.toUpperCase()}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className="flex items-center justify-center cursor-pointer w-1/12 m-0"
              id="rightarrow_btn"
              onClick={() => scrollSlider("right")}
            >
              <NextSlideBtn />
            </div>
          </div>
        )}
        {/* Account verification modal */}
        {errorModal && (
          <CenteredModal
            id="account-error_modal"
            idTitle="account-error-title_text"
            idInfo="account-error-info_text"
            idButton1="account-error-proceed_btn"
            idCloseModal="account-error-close-modal_btn"
            icon={<WarningIcon />}
            variant="w-full h-10 md:h-12 text-sm font-semibold rounded-md bg-lib-alat-red hover:bg-lib-alat-red-hover text-white flex mt-3  justify-center items-center"
            title={resMsg?.title || "Unable to Validate Account Number"}
            info={resMsg?.actionMessage || ""}
            btnText="Okay"
            showCloseIcon
            handleClick={() => setErrorModal(false)}
            handleClose={() => {
              setErrorModal(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default AccountSlide;
