import PageHeader from "../../components/reusables/Page/PageHeader";
import DashboardWrapper from "../../components/Layouts/DashboardWrapper";
import BuyairtimeTab from "components/AirtimeAndData/BuyAirtime";

const BuyAirtime = () => {
  return (
    <DashboardWrapper>
      <div className="container-fluid 2xl:container mx-auto ">
        <div className="container-fluid 2xl:container mx-auto">
          <main className="max-h-screen overflow-auto">
            <div className=" py-8">
              <div className="max-w-6xl mx-auto">
                <div className="rounded-3xl mb-5">
                  <div className="ml-4">
                    <PageHeader title="Airtime & Data" />
                  </div>
                  <BuyairtimeTab />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default BuyAirtime;
