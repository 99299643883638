import { TabsRef, Tabs, Flowbite } from "flowbite-react";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import IntraTransfer from "./IntraTransfer";
import InterTransfer from "./InterTransfer";
import TransactionHistory from "./TransactionHistory";

const BulkTransferTab = () => {
  const navigate = useNavigate();
  //Gte path url
  const activeTab = window.location.pathname;
  // const [successPopup, setSuccessPopup] = useState(false);
  // const [failedPopup, setFailedPopup] = useState(false);
  // const [processingPopup, setProcessingPopup] = useState(false);
  const tabsRef = useRef<TabsRef>(null);

  const theme = {
    tabs: {
      tablist: {
        base: "flex text-center gap-5 z-0",
        tabitem: {
          base: "flex text-center",
          variant: {
            underline: {
              base: "rounded-t-lg",
              active: {
                on: "active py-2 rounded-t-lg border-b-2 border-lib-alat-red text-lib-alat-red font-medium",
                off: "py-2 border-b-2 border-transparent text-lib-alat-black font-medium",
              },
            },
          },
        },
      },
    },
  };
  return (
    <>
      {/* <div>Active tab: {activeTab}</div> */}
      <div className="w-full">
        <Flowbite theme={{ theme }}>
          <Tabs
            aria-label="Default tabs"
            // eslint-disable-next-line react/style-prop-object
            variant="underline"
            ref={tabsRef}
            onActiveTabChange={(tab: React.SetStateAction<number>) => {
              // console.log(tab);
              let origin = "/payments/bulk-transfer/";
              let url = "make-transfer";
              if (tab === 1) url = "transaction-history";
              // if(tab === 2) url = "transaction-history"
              navigate(origin + url);
            }}
            className=" !border-[#E4DFDF] w-full"
          >
            <Tabs.Item
              active={activeTab === "/payments/bulk-transfer/make-transfer"}
              title="Make Transfer"
              className=""
            >
              <IntraTransfer />
            </Tabs.Item>
            {/* <Tabs.Item
                active={activeTab === "/payments/bulk-transfer/inter-transfer"}
                title="Transfer to Other bank accounts"
                className=" !bg-purple-800"
              >
                <InterTransfer />
              </Tabs.Item> */}
            <Tabs.Item
              active={
                activeTab === "/payments/bulk-transfer/transaction-history"
              }
              title="History"
            >
              <TransactionHistory />
            </Tabs.Item>
          </Tabs>
        </Flowbite>
      </div>
    </>
  );
};

export default BulkTransferTab;
