export const initialRes = {
  httpStatusCode: 0,
  responseCode: 0,
  responseMessage: {
    title: "",
    actionMessage: "",
  },
  hasError: true,
  data: null,
};
export const initialUploadPayload = {
  accountReactivationLetterLink: "",
  accountNumber: "",
  accountName: "",
  isSoleProprietor: undefined,
  phoneNumber: "",
};
export const initialState = {
  loading: [],
  validateAccount: { accountNumber: "", isInApp: false },
  uploadDoc: initialUploadPayload,
  requestResponse: null,
};
