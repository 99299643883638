import { useNavigate } from "react-router-dom";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import SearchComponent from "components/Payments/BankTransfer/Reusables/SearchComponent";
import {
  setActiveSelectedCollection,
  useCollectionsState,
} from "react-wrappers/stores/redux/collections/slices";
import { useAppDispatch } from "hooks/useReduxHook";
import { GET_BILL_CATEGORIES_LIST_ACTION } from "react-wrappers/stores/redux/payments/actions";
import PageLoader from "alat-components-library/PageLoader";
import { usePayBillController } from "controllers/Payments/Paybill";
import { useGetCollections } from "hooks/apis/Collections/useGetCollections";
import { useCollectionsController } from "controllers/Collections";

const Collections = () => {
  // Local State
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { allCollectionMerchants, loading, collectionsCategoryList } =
    useCollectionsState();

  const { handleFilterCollections } = useCollectionsController();
  const { handleFetchCollections, handleFetchCollectionMerchants } =
    useGetCollections();

  useEffect(() => {
    handleFetchCollections();
  }, []);

  const listOfCollections = useMemo(
    () =>
      handleFilterCollections({
        collections: collectionsCategoryList,
        searchWord: searchTerm,
      }),
    [collectionsCategoryList, searchTerm, handleFilterCollections]
  );

  const handleSelected = (payload: any) => {
    // Update the active bill state
    // handleFetchCollectionMerchants(payload.id);
    dispatch(setActiveSelectedCollection(payload));
    // redirect to selected bill screen
    navigate(`/bills/collections/select-category`);
  };

  return (
    <div className="w-full">
      {!!loading.includes("fetch-collections") && (
        <PageLoader message="Loading collections" />
      )}
      <SearchComponent
        word={searchTerm}
        handleWordChange={(event: {
          target: { value: SetStateAction<string> };
        }) => setSearchTerm(event.target.value)}
        title="Search collections"
        width="w-full"
        textPosition="text-left"
      />
      <div className="grid md:grid-cols-3 grid-cols-2 gap-6 py-10">
        {listOfCollections?.length > 0 &&
          listOfCollections?.map((item: any) => (
            <div
              key={item?.categoryId}
              onClick={() => handleSelected(item)}
              className="flex md:justify-left justify-center cursor-pointer items-center bg-white h-24 w-full boxShadow px-10 py-6 rounded-md"
            >
              <p className="text-[#333]">{item?.categoryName}</p>
            </div>
          ))}
        {listOfCollections?.length === 0 && "No catgeory found"}
      </div>
    </div>
  );
};

export default Collections;
