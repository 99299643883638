import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as FourUserIcon } from "assets/svgs/airtimeIcon.svg";
import { airtimeOTPValidationSchema } from "./airtimeValidation/airtimeValidation";
import { useAirtimeController } from "controllers/AirtimeAndData/AirtimeController";
import Spinners from "components/Spinner/Spinner";
import { FormikButton } from "alat-components-library";
import { Formik, Form } from "formik";
import { ReactComponent as RefreshIcon } from "assets/svgs/refresh-rounded.svg";
import { useAirtimeOTPRequest } from "hooks/apis/AirtimeAndData/useAirtimePresetAmount";
import { TransactionTypeEnum } from "utils/enums";
import PageLoader from "components/Loader/PageLoader";

const OtpVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const AirtimeData = location?.state?.AirtimeRequestData;
  const {
    AirtimeOTPRequestData,
    AirtimeOTPRequestLoading,
    AirtimeOTPRequestError,
    handleAirtimeOTPRequest,
  } = useAirtimeOTPRequest();
  const handleAirtimeOTPSubmit = (e: any) => {
    handleAirtimeOTPRequest({
      code: e.otp,
      correlationId: AirtimeData?.data?.correlationId,
      transactionType: TransactionTypeEnum.SingleSignatoryAirtimePurchase,
    });
    navigate("/Bills/ConfirmOtp");
  };
  return (
    <center>
      {AirtimeOTPRequestLoading && <PageLoader message="" />}
      <Card className="md:w-[768px] w-full col-span-3 bg-white pb-14  dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
        <div>
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              onClick={() => navigate(-1)}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            />
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="banktransfer_heading"
            >
              Verification
            </div>
            <div></div>
          </div>
        </div>
        <hr />

        <Formik
          initialValues={{
            otp: "",
          }}
          onSubmit={handleAirtimeOTPSubmit}
          validationSchema={airtimeOTPValidationSchema}
          validateOnChange
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form>
              <div>
                <div className="flex flex-wrap justify-center gap-4 ">
                  <div className="relative w-full lg:max-w-sm mb-2">
                    <div className="flex justify-center relative w-full lg:max-w-sm mb-6">
                      <FourUserIcon fill="inherit" />
                    </div>
                    <p justify-center w-full mb-6>
                      {AirtimeData?.responseMessage?.actionMessage}
                      {/* We just sent a verification code to your mobile number
                          <em>(+2348020****01)</em> */}
                    </p>

                    <div className="lg:max-w-sm w-full mb-6 space-y-4 mt-4">
                      <div className="w-full">
                        <input
                          name="otp"
                          type="text"
                          placeholder="Enter OTP"
                          className="pl-6 md:w-[390px] w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary"
                          maxLength={6}
                          onChange={handleChange}
                          value={values.otp}
                        />
                        <span className="text-sm text-red-600">
                          {errors?.otp}
                        </span>
                      </div>
                      <div className="mb-2 flex items-center justify-end gap-2 text-sm ml-1 text-right text-red">
                        <RefreshIcon />
                        <p>Resend OTP</p>
                      </div>
                    </div>
                  </div>

                  <div className="relative lg:max-w-sm w-full  flex items-center justify-center">
                    <FormikButton
                      id="proceed-to-Airtime_Request"
                      labelText=" Complete transfer"
                      isDisabled={!isValid}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </center>
  );
};

export default OtpVerification;
