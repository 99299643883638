export const GET_AIRTIME_PRESET_AMOUNT: string = `v1/Airtime/PresetAmounts`;
export const GET_Network_Providers_Name: string = `v1/Airtime/NetworkProvidersName`;
export const POST_REQUEST_AIRTIME: string = `transaction/validate/airtimedata`;
export const POST_REQUEST_DATA: string = `transaction/validate/airtimedata`;
export const POST_OTP_AIRTIME: string = `v1/Bill/CompleteWebTransansactionWithOtp`;
export const GET_HANDLE_REFRESH = (CorrelationId: string) =>
  `v1/Transfer/StatusByCorrelationId/${CorrelationId}`;
export const GET_Data_Bundle: string = `/v1/Airtime/DataPlans`;
export const GET_AIRTIME_DATA_BENEFICIARY_LIST = (
  pageSize: string,
  pageNumber: string,
  beneficiaryType: number
) =>
  `v1/Beneficiary/AirtimeAndDataBeneficiaries?pageSize=${pageSize}&pageNumber=${pageNumber}&beneficiaryType=${beneficiaryType}`;
