import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Button, Card, Label } from "flowbite-react";
import React, { useEffect } from "react";
import { useLoginController } from "controllers/Login/LoginController";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { ReusableCard } from "components/reusables/Page/ReusableCard";

// eslint-disable-next-line
const baseURL =
  "https://wema-afbdev-apim.azure-api.net/afbidentity/api/transaction/validate";

interface INewTransferComponent3Props {
  recipients: any;
  accountToDebit: any;
  accountName: any;
  accountNumber: any;
  sumTotal: any;
  selectedBank: any;
  pin: any;
  setPin: any;
  isPinValidated: any;
  showPinError: any;
  allowSubmit: any;
  handleChange: any;
  handleScreenChangePrev: any;
  handleScreenChangeNext: any;
  authToken: any;
  setAuthToken: any;
  pinError: string;
  handleSubmit: any;
  handleValidate: any;
  nipCharges: any;
}
const NewTransferComponent3 = ({
  recipients,
  accountToDebit,
  accountName,
  accountNumber,
  sumTotal,
  selectedBank,
  pin,
  setPin,
  isPinValidated,
  showPinError,
  allowSubmit,
  handleChange,
  handleScreenChangePrev,
  handleScreenChangeNext,
  authToken,
  setAuthToken,
  pinError,
  handleSubmit,
  handleValidate,
  nipCharges,
}: INewTransferComponent3Props) => {
  // const location = useLocation();
  const paymentState = usePaymentState();

  const { authState } = useLoginController();
  // const hasSoftToken = true;
  let authType;
  // parseFloat(amount.replace(/,/g, ""));
  if (sumTotal <= 2000000) {
    authType = "pinOtp";
  }
  if (sumTotal > 2000000) {
    if (authState?.user?.hasSoftToken) {
      authType = "softToken";
    } else {
      authType = "hardTokenPin";
    }
  }
  if (sumTotal <= 2000000 && paymentState?.responseCode === 21) {
    if (authState?.user?.hasSoftToken) {
      authType = "softToken";
    } else {
      authType = "hardTokenPin";
    }
  }

  if (
    sumTotal <= 2000000 &&
    authState?.user?.hasSoftToken === false &&
    authState?.user?.hasTransactionPin === false
  ) {
    authType = "hardTokenPin";
  }

  if (
    authState?.user?.hasSoftToken &&
    authState?.user?.hasTransactionPin === false
  ) {
    authType = "softToken";
  }

  const getNonWemaAccounts = recipients.filter(
    (oneAccount: any) => oneAccount.bankCode !== "035"
  );

  const allAmounts = getNonWemaAccounts.map(
    (account: any) => account.amount.withNumberFormat
  );

  // CLears the token input on page load.
  useEffect(() => {
    setAuthToken("");
    setPin("");
  }, []);

  let toBePaid: any[] = [];
  //   // eslint-disable-next-line
  //   const totalAmounts = allAmounts.forEach((amount: any) => {
  //     if (amount < 5001) {
  //       let charges = nipCharges
  //         .filter((oneCharge: any) => oneCharge.lower < 5001)
  //         .map((theCharge: any) => theCharge.charge);
  //       toBePaid.push(charges[0]);
  //     }
  //     if (amount > 5000 && amount < 50001) {
  //       let charges = nipCharges
  //         .filter(
  //           (oneCharge: any) => oneCharge.lower > 5000 && oneCharge.lower < 50001
  //         )
  //         .map((theCharge: any) => theCharge.charge);

  //       toBePaid.push(charges[0]);
  //     }
  //     if (amount >= 50001) {
  //       let charges = nipCharges
  //         .filter((oneCharge: any) => oneCharge.lower >= 50001)
  //         .map((theCharge: any) => theCharge.charge);

  //       toBePaid.push(charges[0]);
  //     }
  //   });
  // // eslint-disable-next-line
  //   const totalCharge = toBePaid.reduce((accumulator, value) => {
  //     return accumulator + value;
  //   }, 0);

  const totalTransactionFee = recipients
    .reduce(
      (acc: any, curr: any) =>
        curr.transactionFee && curr.transactionFee !== undefined
          ? acc + curr.transactionFee
          : acc,
      0
    )
    .toFixed(2);

  return (
    <div className="w-full flex items-center justify-center">
      <ReusableCard
        variant="md:w-[48rem] w-full"
        cardTitle="Review Transfer"
        handleBackNavigation={() => {
          setPin("");
          handleScreenChangePrev();
        }}
        showGoBackIcon
      >
        <div className="flex w-full items-center justify-center">
          <form className="flex w-full flex-col gap-4 justify-center items-center mt-4">
            <div className="text-[#666666] mb-4">
              Complete transaction details below
            </div>
            <div className="w-full">
              <div className="text-[#666666] mb-2 text-sm font-normal md:w-[400px] w-full mx-auto">
                Transfer from
              </div>
              <Card className="box-border md:w-[400px] mx-auto w-full h-[114px] rounded-lg dark:bg-white dark:border-[#EDEDED] border-[#EDEDED]">
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <div className="text-base">
                      {accountToDebit?.accountDescription}
                    </div>
                    <div className="text-xs" id="source-account_text">
                      {accountToDebit.accountNo}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-right">Balance</div>
                    <div className="text-base">
                      <span>₦</span>
                      <span id="source-account-bal_text">
                        {parseFloat(accountToDebit.balance).toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="mt-4 w-full">
              <div className="text-[#666666] mb-2 text-sm font-normal md:w-[400px] w-full mx-auto">
                Transfer to
              </div>
              <Card className="box-border md:w-[400px] mx-auto w-full h-[114px] rounded-lg text-white bg-[image:url('./assets/svgs/card-background.svg')] bg-no-repeat bg-cover">
                <div className="flex w-full flex-row justify-between items-center">
                  <div>
                    <div className="text-xs">Group</div>
                    <div className="text-base">New Group</div>
                  </div>
                  <div>
                    <div className="text-xs text-right">
                      Number of recipients
                    </div>
                    <div
                      className="text-base text-right"
                      id="number-of-recipients_text"
                    >
                      {recipients.length}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <div className="text-xs">Total amount</div>
                    <div className="text-base">
                      <span>N</span>
                      <span id="total-amount_text">
                        {sumTotal.toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-right">Charges</div>
                    <div className="text-base text-right">
                      <span>₦</span>
                      <span id="charges_text">{totalTransactionFee}</span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            {/* <div className="relative w-full lg:max-w-sm mt-2 mb-8">
              <div className="mb-2 block text-left">
                <Label
                  htmlFor="pin"
                  value="Enter PIN"
                  className=" text-[#464646] dark:!text-[#464646] font-normal text-sm"
                />
              </div>

              <input
                name="pin"
                type="password"
                className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                value={pin}
                onChange={handleChange}
                maxLength={4}
              />
              {showPinError === "yes" && (
                <div className="text-red-700">PIN is incorrect</div>
              )}
            </div>
            <Button
              type="button"
              className="bg-primary hover:bg-transparent hover:text-primary hover:border-primary lg:max-w-sm mb-20  w-[188px] hover:bg-primary focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary"
              onClick={handleScreenChangeNext}
              disabled={!allowSubmit}
            >
              Continue
            </Button> */}
            {authType === "pinOtp" && (
              <div className="relative w-full lg:max-w-sm mt-2 mb-8">
                <div className="mb-2 block text-left">
                  <Label
                    htmlFor="pin"
                    value="Enter PIN"
                    className=" text-[#464646] dark:!text-[#464646] font-normal text-sm"
                  />
                </div>

                <input
                  id="pin_input"
                  name="pin"
                  type="password"
                  data-auth-type={authType}
                  className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                  value={pin}
                  onChange={handleChange}
                  maxLength={4}
                />
                {showPinError === "yes" && (
                  <div className="text-red-700">{pinError}</div>
                )}
              </div>
            )}
            {authType === "softToken" && (
              <div className="relative w-full lg:max-w-sm mt-2 mb-8">
                <div className="mb-2 block text-left">
                  <Label
                    htmlFor="authToken"
                    value="Enter soft token"
                    className=" !text-[#666666] mb-2 !text-sm !font-normal"
                  />
                </div>

                <input
                  id="soft-token_input"
                  name="authToken"
                  type="password"
                  data-auth-type={authType}
                  className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                  value={authToken}
                  onChange={handleChange}
                  maxLength={6}
                />
                {showPinError === "yes" && (
                  <div className="text-red-700">{pinError}</div>
                )}
              </div>
            )}
            {authType === "hardTokenPin" && (
              <div className="relative w-full lg:max-w-sm mt-2 mb-8">
                <div className="mb-2 block text-left">
                  <Label
                    htmlFor="pin"
                    value="Enter hard token PIN"
                    className=" !text-[#666666] mb-2 !text-sm !font-normal"
                  />
                </div>

                <input
                  id="hard-token-pin_input"
                  name="pin"
                  type="password"
                  data-auth-type={authType}
                  className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                  value={pin}
                  onChange={handleChange}
                  maxLength={4}
                />
                {/* {showPinError === "yes" && (
                  <div className="text-red-700">{pinError}</div>
                )} */}

                <div className="mb-2 mt-6 block text-left">
                  <Label
                    htmlFor="authToken"
                    value="Enter hard token"
                    className=" !text-[#666666] mb-2 !text-sm !font-normal"
                  />
                </div>

                <input
                  id="hard-token_input"
                  name="authToken"
                  type="password"
                  data-auth-type={authType}
                  className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                  value={authToken}
                  onChange={handleChange}
                  maxLength={6}
                />
              </div>
            )}
            {authType === "pinOtp" && (
              <Button
                id="continue_btn"
                type="button"
                className="bg-primary hover:bg-transparent hover:border-primary lg:max-w-sm mb-20  w-[188px] hover:bg-primary focus:ring-1 focus:ring-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                onClick={handleValidate}
                disabled={!allowSubmit && authType === "pinOtp"}
              >
                Continue
              </Button>
            )}

            {authType === "hardTokenPin" && (
              <Button
                id="submit_btn"
                type="button"
                className="bg-primary hover:bg-transparent hover:text-primary hover:border-primary lg:max-w-sm mb-20  w-[188px] hover:bg-primary focus:ring-1 focus:ring-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                onClick={handleSubmit}
                disabled={
                  !allowSubmit ||
                  !authToken ||
                  (!authState?.user?.hasSoftToken && !pin)
                }
              >
                Submit
              </Button>
            )}
            {authType !== "pinOtp" && authType !== "hardTokenPin" && (
              <Button
                id="submit_btn"
                type="button"
                className="bg-primary hover:bg-transparent hover:text-primary hover:border-primary lg:max-w-sm mb-20  w-[188px] hover:bg-primary focus:ring-1 focus:ring-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                onClick={handleSubmit}
                disabled={!allowSubmit}
              >
                Submit
              </Button>
            )}
          </form>
        </div>
      </ReusableCard>
      {/* <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <div
              id="back_btn"
              onClick={() => {
                setPin("");
                handleScreenChangePrev();
              }}
              className="cursor-pointer"
            >
              <ChevronLeftIcon className="h-6 w-6 text-[#333333]" />
            </div>
            <div className="text-[#4D4D4D] text-xl text-center font-semibold font-inter "></div>
            <div></div>
          </div>
        </div>
        <hr />
      </Card> */}
    </div>
  );
};

// {
//   showScreen === "main" ? (
//     <div>Main</div>
//   ) : showScreen == "successful" ? (
//     <div>Successful</div>
//   ) : showScreen == "failed" ? (
//     <div>Failed</div>
//   ) : (
//     <div>Pending</div>
//   );
// }
export default NewTransferComponent3;
