import { ToastNotify } from "../../../helpers/toastNotify";

export const responseMessageHandler = (response: any) => {
  return (
    response?.data?.description ||
    response?.data?.Message ||
    response?.data?.responseMessage?.title ||
    response?.data?.responseMessage?.actionMessage ||
    "Oops, something unexpected just happened, please refresh and try again"
    // `${response?.data?.responseMessage?.title}. ${response?.data?.responseMessage?.actionMessage}`
  );
};

const axiosStatus = (response: any): void => {
  switch (response?.status) {
    case 400:
      ToastNotify({
        type: "error",
        message:
          responseMessageHandler(response) ||
          "Oops, something unexpected just happened, please refresh and try again",
        position: "top-right",
      });
      break;
    case 403:
      ToastNotify({
        type: "warning",
        message:
          responseMessageHandler(response) ||
          "Oops, something unexpected just happened, please refresh and try again",
        position: "top-right",
      });
      break;
    case 404:
      ToastNotify({
        type: "warning",
        message: "Route Not found",
        position: "top-right",
      });
      break;
    case 401:
      ToastNotify({
        type: "error",
        message: "Invalid access or token has expired, redirecting to login",
        position: "top-right",
      });

      setTimeout(() => {
        localStorage.clear();
        window.location.replace("/logout");
      }, 1000);
      break;
    default:
      ToastNotify({
        type: "error",
        message: `An unexpected error occured, please check internet connection, refresh and try again`,
        position: "top-right",
      });
      setTimeout(() => {
        // localStorage.clear();
        // router.push("/");
      }, 1000);
  }
};

export default axiosStatus;
