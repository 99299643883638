import React from "react";
import PageHeader from "../../components/reusables/Page/PageHeader";
// import FundsTransferTab from "../../components/Payments/FundsTransferTab";
import MultipleTransferTab from "../../components/Payments/BankTransfer/MultipleTransfer/Index";
import DashboardWrapper from "../../components/Layouts/DashboardWrapper";

const MultipleTransfer = () => {
  return (
    <DashboardWrapper>
      <div className="lg:px-20 space-y-4 mt-5">
        <div className="flex justify-between items-center px-5">
          <PageHeader title="Multiple Transfer" />
        </div>
        <MultipleTransferTab />
      </div>
    </DashboardWrapper>
  );
};

export default MultipleTransfer;
