import { useState } from "react";
import {
  POST_ACCEPT_E_WEMA_REFEREE,
  POST_ACCEPT_WEMA_REFEREE,
} from "../../../utils/apis/routes";
import { AcceptWemaRefereePayload } from "../../../types/global";
import { useApiRequest } from "hooks/useApiRequest";
import { ToastNotify } from "helpers/toastNotify";

export const useAcceptWemaReferee = () => {
  //Local States
  const [wemaRefereeStatus, setWemaRefereeStatus] = useState<boolean>(false);
  const [wemaRefereeLoading, setWemaRefereeLoading] = useState<boolean>(false);
  const [wemaRefereeError, setWemaRefereeError] = useState<string>("");
  const [wemaRefereeData, setWemaRefereeData] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const handleWemaRefereeValidation = async (
    payload: AcceptWemaRefereePayload
  ) => {
    // console.log(payload)
    setWemaRefereeLoading(true);
    const REQUEST_TYPE = payload?.isERef ? "ereference" : "onboarding";

    try {
      const API_URL = payload.isERef
        ? POST_ACCEPT_E_WEMA_REFEREE
        : POST_ACCEPT_WEMA_REFEREE;
      const res = await makeRequest({
        method: "post",
        route: API_URL,
        body: payload,
        originType: REQUEST_TYPE,
      });
      // console.log(res)
      if (res?.data) {
        setWemaRefereeData(res?.data);
        if (res?.status === 200) {
          setWemaRefereeStatus(true);
        } else if (res?.status === 400) {
          const resMsg = res?.data?.data?.actionMessage;
          ToastNotify({
            type: "error",
            message: resMsg,
            position: "top-right",
          });
        }
      }
      setWemaRefereeLoading(false);
    } catch (error) {
      setWemaRefereeError("An error occured while treating referee request.");
      setWemaRefereeLoading(false);
    }
  };

  return {
    wemaRefereeStatus,
    wemaRefereeLoading,
    wemaRefereeError,
    wemaRefereeData,
    handleWemaRefereeValidation,
  };
};
