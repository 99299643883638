import { useEffect, useState } from "react";
import html2pdf from "html2pdf.js";
import { useUploadBlobToAzure } from "hooks/apis/useUploadBlobToAzure";
import { staticHtmlString } from "pages/Home/DudCheque/DudLetterPdfGenerator";

interface GenerateDudChequeLetterHookResult {
  blobUploadError: any;
  blobFiles: any;
  blobUrl: string;
  handleUploadBlob: any;
  imageUrlToBase64: (string: string) => void;
  isUploadingBlob: boolean;
  generateDudChequeLetter: (props: GeneratePDFProps) => Promise<void>;
}

interface GeneratePDFProps {
  bvnName: string;
  businessName: string;
  recordCount: string;
  signature: any;
}

export const useGenerateDudChequeLetter =
  (): GenerateDudChequeLetterHookResult => {
    const [blobUrl, setBlobUrl] = useState("");

    const { blobUploadError, blobFiles, handleUploadBlob, isUploadingBlob } =
      useUploadBlobToAzure("signature", "backoffice");

    useEffect(() => {
      if (blobFiles?.url) {
        // window.open(blobFiles?.url, '_blank');
        // console.log(blobFiles?.url, "Blob url");

        setBlobUrl(blobFiles?.url);
      }
    }, [blobFiles]);

    async function imageUrlToBase64(url: string): Promise<string> {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = url;
      await new Promise((resolve) => {
        img.onload = resolve;
      });
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d")!;
      ctx.drawImage(img, 0, 0);
      const base64 = canvas.toDataURL("image/jpeg");
      return base64;
    }

    const generateDudChequeLetter = async ({
      bvnName,
      businessName,
      recordCount,
      signature,
    }: GeneratePDFProps) => {
      const staticHtml = staticHtmlString({
        bvnName,
        businessName,
        recordCount,
        signature,
      });

      const options = {
        filename: "newPDF.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2pdf: { scale: 1 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      try {
        const pdfBlob = await html2pdf()
          .set(options)
          .from(staticHtml)
          .outputPdf("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        // console.log('PDF Blob URL: ', pdfUrl);
        const currentTimestamp = new Date().getTime();
        const refereeFormName = `dud_cheque_${currentTimestamp}.pdf`;
        await handleUploadBlob(
          new File([pdfBlob], refereeFormName, { type: "application/pdf" }),
          "AFB_DUDCHEQUE_LETTER",
          "",
          "PDF",
          ""
        );
      } catch (error) {
        console.error("Error exporting PDF: ", error);
      }
    };

    return {
      blobUploadError,
      blobFiles,
      blobUrl,
      imageUrlToBase64,
      handleUploadBlob,
      isUploadingBlob,
      generateDudChequeLetter,
    };
  };

export default useGenerateDudChequeLetter;
