import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import { GET_BANK_LIST } from "../../../utils/apis/routes";

export const useBankLists = () => {
  //Local States
  const [bankLists, setBankLists] = useState<
    { bankCode: string; bankName: string }[]
  >([]);
  const [bankListLoading, setbankListLoading] = useState<boolean>(false);
  const [bankListError, setbankListError] = useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleGetBankList = async (type?: string) => {
    try {
      setbankListLoading(true);
      const res = await makeRequest({
        method: "get",
        route: GET_BANK_LIST(type),
        originType: "transferservice",
      });

      //Map the payload
      setBankLists(res.data.data.banks);
      setbankListLoading(false);
    } catch (error) {
      setbankListError(
        "An error occured while fetching bank list, please referesh and try again"
      );
      setbankListLoading(false);
    }
  };

  return {
    bankLists,
    bankListLoading,
    bankListError,
    handleGetBankList,
  };
};
