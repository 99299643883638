import PendingReferee from "assets/svgs/pending-referee-action.svg";
import PendingActionCard from "components/Dashboard/components/PendingActionCard";
import PendingActionCarousel from "components/Dashboard/components/PendingActionCarousel";
import PendingDocumentsCard from "components/Dashboard/components/PendingDocumentsCard";
import PageLoader from "components/Loader/PageLoader";
import { useFetchRejectedDocuments } from "hooks/apis/pendingActions/useFetchRejectedDocuments";
import { useAppSelector } from "hooks/useReduxHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { OnboardingStageEnum } from "types/global";

const PendingDashboardCard = () => {
  const navigate = useNavigate();
  const pendingActions = useAppSelector((state) => state.pendingActions);
  const { user } = useAuthState();
  const {
    // fetchSuccess,
    fetchLoading,
    rejectedDocuments,
    handleFetchRejectedDocuments,
  } = useFetchRejectedDocuments();

  const isSoleProprietor = user?.role === "SoleProprietor";
  const onboardingData = useAppSelector((state: any) => state.onboarding);
  const refereeInfoRequired =
    (user?.onboardingStage === OnboardingStageEnum.Refree &&
      !onboardingData.refereeStatus) ||
    (onboardingData?.alatPay && !onboardingData.refereeStatus);
  const noRefereeAction =
    user?.onboardingStage === OnboardingStageEnum.PendingVerification;
  const pendingLicenseReview =
    user?.onboardingStage === OnboardingStageEnum.PendingLicenseReview;
  const licenseReviewFailed =
    user?.onboardingStage === OnboardingStageEnum.LicenseReviewFailed;

  const hasRejectedDocuments = rejectedDocuments?.length > 0;
  const hasPendingAction =
    licenseReviewFailed ||
    pendingLicenseReview ||
    noRefereeAction ||
    refereeInfoRequired ||
    hasRejectedDocuments;

  useEffect(() => {
    if (isSoleProprietor) {
      handleFetchRejectedDocuments({
        regNumber: user?.regNumber,
        businessType: user?.businessType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSoleProprietor]);

  return (
    <>
      {fetchLoading && <PageLoader message="" />}
      <div className={hasPendingAction ? "block" : "hidden"}>
        {noRefereeAction && (
          <PendingActionCard
            pendingActionId="refereeChange_btn"
            icon={PendingReferee}
            heading="No Action from Referee"
            info="Your referees are yet to respond to your request."
            handleClick={() => navigate("/referee-list")}
          />
        )}
        {pendingLicenseReview && (
          <PendingActionCard
            pendingActionId="licensePending_btn"
            icon={PendingReferee}
            heading="License Pending"
            info="Your License Certificate is pending review. Kindly check your email for more information."
            handleClick={() => {}}
          />
        )}
        {licenseReviewFailed && (
          <PendingActionCard
            pendingActionId="licenseFailed_btn"
            icon={PendingReferee}
            heading="License Rejected"
            info="Your License was rejected. Kindly check your email for more information."
            handleClick={() => {}}
          />
        )}
        {refereeInfoRequired && !noRefereeAction && <PendingActionCarousel />}
        {hasRejectedDocuments && !noRefereeAction && !refereeInfoRequired && (
          <PendingDocumentsCard
            count={rejectedDocuments?.length}
            handleRejectedDocuments={() => {
              navigate("/pending-actions/rejected-docs/pending-list", {
                state: { rejectedDocuments },
              });
            }}
          />
        )}
      </div>
    </>
  );
};

export default PendingDashboardCard;
