import React, { useEffect, useRef } from "react";

const ProccedCardPayment = ({
  resData,
  handleClose,
}: {
  resData: string;
  handleClose: () => void;
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (resData && iframeRef.current) {
      const iframeDoc =
        iframeRef.current?.contentDocument ||
        iframeRef.current?.contentWindow?.document;
      iframeDoc?.open();
      iframeDoc?.write(resData);
      iframeDoc?.close();
    }
  }, [resData]);

  return (
    <div>
      <header className="flex justify-end pb-4">
        <span
          onClick={handleClose}
          className="cursor-pointer inline-flex items-center text-lib-alat-dark-red h-[24px] w-[24px] justify-center rounded-full border-[1.5px] border-lib-alat-dark-red "
        >
          x
        </span>
      </header>
      <iframe
        ref={iframeRef}
        className="rounded-lg outline-none shadow-none border p-2"
        title="Response Content"
        style={{ width: "100%", height: "100%", minHeight: 400 }}
      />
    </div>
  );
};

export default ProccedCardPayment;
