import { TabsRef, Tabs, Flowbite } from "flowbite-react";
import React, { useRef } from "react";
// import Payments from "./Payments";
// import accounts from "../../demo-data/data";
// import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import FundMyAccount from "./FundMyAccount";

// import Spinners from "./FundsTransfer/Spinner";
import { useNavigate } from "react-router-dom";
import BankTransferMain from "../BankTransfer/BankTransferMain";
import BulkTransferMain from "../BulkTransfer.tsx";
import { setFundMyAcctSelectedAccout } from "react-wrappers/stores/redux/payments/slices";
import { useAppDispatch } from "hooks/useReduxHook";

const FundsTransferTab = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //Gte path url
  const activeTab = window.location.pathname;
  // const [successPopup, setSuccessPopup] = useState(false);
  // const [failedPopup, setFailedPopup] = useState(false);
  // const [processingPopup, setProcessingPopup] = useState(false);
  const tabsRef = useRef<TabsRef>(null);
  const theme = {
    tabs: {
      tablist: {
        base: "flex text-center gap-5 z-0",
        tabitem: {
          base: "flex text-center",
          variant: {
            underline: {
              base: "rounded-t-lg",
              active: {
                on: "active py-2 rounded-t-lg border-b-2 border-lib-alat-red text-lib-alat-red font-medium",
                off: "py-2 border-b-2 border-transparent text-lib-alat-black font-medium",
              },
            },
          },
        },
      },
    },
  };

  // console.log(activeTab);
  return (
    <div className="">
      {/* <div>Active tab: {activeTab}</div> */}
      <div className="w-full">
        <Flowbite theme={{ theme }}>
          <Tabs
            aria-label="Default tabs"
            // eslint-disable-next-line react/style-prop-object
            variant="underline"
            ref={tabsRef}
            onActiveTabChange={(tab: React.SetStateAction<number>) => {
              // console.log(tab);
              let url = "/payments/funds-transfer/bank-transfer";
              if (tab === 1) url = "/payments/funds-transfer/fund-my-account";
              if (tab === 2) url = "/payments/funds-transfer/bulk-transfer";
              dispatch(setFundMyAcctSelectedAccout(null));
              navigate(url);
            }}
            className=" !border-[#E4DFDF] w-full"
          >
            <Tabs.Item
              active={activeTab === "/payments/funds-transfer/bank-transfer"}
              title="Bank Transfer"
              className=""
              id="banktransfer_tab"
            >
              <BankTransferMain />
            </Tabs.Item>
            <Tabs.Item
              active={activeTab === "/payments/funds-transfer/fund-my-account"}
              title="Fund My Account"
              className="!bg-purple-800"
              id="fundmyaccount_tab"
            >
              {/* <div className="ml-48 ">
                
              </div> */}
              <FundMyAccount tabsRef={tabsRef} />
            </Tabs.Item>
            <Tabs.Item
              active={activeTab === "/payments/funds-transfer/bulk-transfer"}
              title="Bulk Transfer"
              id="bulktransfer_tab"
            >
              <BulkTransferMain tabsRef={tabsRef} />
            </Tabs.Item>
          </Tabs>
        </Flowbite>
      </div>
      {/* <div className="w-[245px]"></div> */}
    </div>
  );
};

export default FundsTransferTab;
