import { useState } from "react";
import { POST_SAVE_NONWEMA_REFEREE } from "../../../utils/apis/routes";
import { SaveNonWemaRefereePayload } from "../../../types/global";
import { useApiRequest } from "hooks/useApiRequest";
import { ToastNotify } from "helpers/toastNotify";
import axiosStatus from "utils/apis/axios/errorStatus";

export const useSaveNonWemaReferee = () => {
  //Local States
  const [saveNonWemaRefereeStatus, setSaveNonWemaRefereeStatus] =
    useState<boolean>(false);
  const [saveNonWemaRefereeLoading, setSaveNonWemaRefereeLoading] =
    useState<boolean>(false);
  const [saveNonWemaRefereeError, setSaveNonWemaRefereeError] =
    useState<string>("");
  const [saveNonWemaRefereeData, setSaveNonWemaRefereeData] = useState<any>("");

  //API Request
  const makeRequest = useApiRequest();

  const saveWemaRefereeValidation = async (
    payload: SaveNonWemaRefereePayload
  ) => {
    // console.log(payload)
    setSaveNonWemaRefereeStatus(false);
    setSaveNonWemaRefereeLoading(true);
    try {
      const res = await makeRequest({
        method: "post",
        route: POST_SAVE_NONWEMA_REFEREE,
        body: payload,
        originType: "onboarding",
      });
      // console.log(res)
      //Map the payload
      if (res.status === 200) {
        setSaveNonWemaRefereeData(res?.data?.data);
        setSaveNonWemaRefereeStatus(true);
      } else {
        setSaveNonWemaRefereeStatus(false);

        if (res?.data?.data?.hasError) {
          ToastNotify({
            type: "error",
            message: res?.data?.data?.responseMessage?.title,
            position: "top-right",
          });
        } else {
          axiosStatus(res?.data);
        }

        // ToastNotify({
        //     type: "error",
        //     message: "An error occured while saving referees. Please try again"
        // })
        // setSaveNonWemaRefereeError("An error occured while saving referees. Please try again")
      }
      setSaveNonWemaRefereeLoading(false);
    } catch (error) {
      setSaveNonWemaRefereeError(
        "An error occured while fetching branch sol ids, please referesh and try again"
      );
      setSaveNonWemaRefereeLoading(false);
    }
  };

  return {
    saveNonWemaRefereeStatus,
    saveNonWemaRefereeLoading,
    saveNonWemaRefereeError,
    saveNonWemaRefereeData,
    saveWemaRefereeValidation,
  };
};
