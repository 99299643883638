import { useNavigate } from "react-router-dom";
import { SetStateAction, useMemo, useState } from "react";
import SearchComponent from "components/Payments/BankTransfer/Reusables/SearchComponent";
import { useAppDispatch } from "hooks/useReduxHook";
import {
  setActiveSelectedSubBill,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { usePayBillController } from "controllers/Payments/Paybill";

const SelectedBill = () => {
  // Local State
  const [searchTerm, setSearchTerm] = useState("");
  const { activeSelectedBill } = usePaymentState();
  const { handleFilterBillers } = usePayBillController();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Assit Function
  const handleSelected = (payload: any) => {
    // Update the active bill state
    dispatch(setActiveSelectedSubBill(payload));
    // redirect to selected bill screen
    navigate(`/bills/payment/pay-bill`);
  };

  const listOfBillers = useMemo(
    () =>
      handleFilterBillers({
        billers: activeSelectedBill?.billers,
        searchWord: searchTerm,
      }),
    [activeSelectedBill?.billers, handleFilterBillers, searchTerm]
  );

  return (
    <div className="w-full">
      <SearchComponent
        word={searchTerm}
        handleWordChange={(event: {
          target: { value: SetStateAction<string> };
        }) => setSearchTerm(event.target.value)}
        title="Search biller"
        width="w-full"
        textPosition="text-left"
      />
      <div className="grid md:grid-cols-3 grid-cols-2 gap-6 py-10">
        {listOfBillers?.length > 0 &&
          listOfBillers?.map((item: any) => (
            <div
              key={item.id}
              onClick={() => handleSelected(item)}
              className="flex md:justify-start cursor-pointer justify-center items-center bg-white h-24 w-full boxShadow px-10 py-6 rounded-md"
            >
              <p className="text-[#333]">{item.name}</p>
            </div>
          ))}
        {listOfBillers?.length === 0 && "No biller founnd"}
      </div>
    </div>
  );
};

export default SelectedBill;
