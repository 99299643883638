import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import {
  FormikButton,
  PrimaryButton,
  TableOne,
  TextInput,
} from "alat-components-library";
import { useTransactionTransferController } from "controllers/BulkTransfer/useTransactionTransferController";
import {
  setBulkTranferIntra,
  setBulkTranferInter,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { useEffect, useState } from "react";
import { ReactComponent as ModalClose } from "../../alat-components-library/assets/images/icons/modal-close.svg";
import { Form, Formik } from "formik";
import { editExcelValidation } from "utils/validations/bulktransfer";
import { useExcelModification } from "controllers/BulkTransfer/useExcelModification";
import { Pagination } from "components/Dashboard/User/Pagination";
import { ShowExcelListTableBody } from "components/Payments/BulkTransfer.tsx/general/ShowExcelListTableBody";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { ReactComponent as BackArrowIcon } from "../../alat-components-library/assets/images/icons/BackArrowIcon.svg";
import { useNavigate } from "react-router-dom";

export const PendingTransactionsHeaders = [
  {
    id: 1,
    title: "Account Number",
    classList: "text-[10px] w-[140px] sticky left-0 top-0 bg-white",
  },
  { id: 2, title: "Account Name", classList: "text-[10px] w-[32px]" },
  { id: 3, title: "Bank", classList: "text-[10px] w-[120px]" },
  { id: 4, title: "Description", classList: "text-[10px] w-[120px]" },
  // { id: 5, title: "Ref code", classList: "text-[10px]  w-[90px]" },
  { id: 6, title: "Amount", classList: "text-[10px]" },
  // { id: 7, title: "", classList: "text-[10px]" },
];

const RecipentList = ({ setActiveTab, screenName }: any) => {
  const { pendingTransactionDetail } = usePaymentState();
  const navigate = useNavigate();

  const { handleViewNext, handleViewPrev } = useTransactionTransferController(
    setActiveTab,
    screenName
  );

  const { beneficiaryDetails } = pendingTransactionDetail;
  const [newBeneficiaryDetails, setNewBeneficiaryDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(1);
  const [take, setTake] = useState(10);

  useEffect(() => {
    setNewBeneficiaryDetails(beneficiaryDetails?.slice(skip - 1, take));
  }, [beneficiaryDetails, skip, take]);

  const handleNextView = () => {
    setCurrentPage(currentPage + 1);
    setSkip(skip + 10);
    setTake(take + 10);
    setNewBeneficiaryDetails(beneficiaryDetails?.slice(skip - 1, take));
  };
  const handlePrevView = () => {
    setCurrentPage(currentPage - 1);
    setSkip(skip - 10);
    setTake(take - 10);

    setNewBeneficiaryDetails(beneficiaryDetails?.slice(skip - 1, take));
  };

  return (
    <DashboardWrapper>
      <div className="flex p-5 flex-col space-y-8">
        <div className="flex gap-5 items-center">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          >
            <BackArrowIcon />
          </div>
          <p className="text-xl font-bold">Review recipients</p>
        </div>
        <div className="md:w-[768px] w-full mx-auto pb-14">
          <div className="bg-white dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
            <div className="flex items-center justify-between px-4 py-2">
              <div></div>
              <div className="text-[#4D4D4D] text-md font-normal text-center">
                Bulk Transfer
              </div>
              <div></div>
            </div>
            <hr />
            <TableOne
              header={PendingTransactionsHeaders}
              searchPlaceHolder="Search for transaction"
              paginationData={{
                sortedData: newBeneficiaryDetails,
                hasNextPage: false,
                currentPage: currentPage,
                count: 10,
                totalItems: beneficiaryDetails.length,
                pageSize: 10,
                skip: skip,
                take: take,
              }}
              handleShowNext={handleNextView}
              handleShowPrevious={handlePrevView}
              isStriped
              isHover={false}
              body={
                <ShowExcelListTableBody
                  list={newBeneficiaryDetails.map((x: any) => {
                    return {
                      ...x,
                      ...{
                        beneficiaryAccountNumber: x.accountNumber,
                        beneficiaryName: x.accountName,
                        beneficiaryBankName: x.bankName,
                      },
                    };
                  })}
                  handleMenuChange={() => null}
                  showThreeDots={false}
                />
              }
            />
          </div>
          <Pagination
            paginationVariant={
              "h-20 w-full flex flex-row justify-between items-center px-4"
            }
            dataToShow={{
              sortedData: newBeneficiaryDetails,
              hasNextPage: false,
              currentPage: currentPage,
              count: 10,
              totalItems: beneficiaryDetails.length,
              pageSize: 10,
              skip: skip,
              take: take,
            }}
            handleNext={handleNextView}
            handlePrev={handlePrevView}
          />
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default RecipentList;
