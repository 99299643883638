import { useState } from "react";

interface TabProps {
  id: string;
  label: string;
  content: any;
}

interface TabsProps {
  tabs: TabProps[];
}

const AccountSetupTab = ({ tabs }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [activeTabContent, setActiveTabContent] = useState(tabs[0].id);

  const handleClick = (tabId: string) => {
    setActiveTab(tabId);
    setActiveTabContent(tabId);
  };

  return (
    <div className="mb-4 w-full">
      <ul
        className="flex flex-wrap text-sm font-medium text-center border-b-2"
        id="accountSetupTab"
        data-tabs-toggle="#accountTabContent"
        role="tablist"
      >
        {tabs.map((tab) => (
          <li className="mr-2" key={tab.id} role="presentation">
            <button
              className={`-mb-[2px] inline-block px-0 py-4 border-b-2 rounded-t-lg transition-all duration-500 ${
                activeTab === tab.id
                  ? "border-lib-alat-dark-red text-lib-alat-dark-red"
                  : "border-transparent"
              }`}
              id={`${tab.id}-tab`}
              data-tabs-target={`#${tab.id}`}
              type="button"
              role="tab"
              aria-controls={tab.id}
              aria-selected={activeTab === tab.id}
              onClick={() => handleClick(tab.id)}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
      <div id="accountTabContent">
        {tabs.map((tab) => (
          <div
            className={`md:px-4 pt-4 rounded-lg transition-all duration-500 ${
              activeTabContent === tab.id
                ? "block opacity-100 h-auto"
                : "opacity-0 h-0 hidden"
            }`}
            id={tab.id}
            role="tabpanel"
            aria-labelledby={`${tab.id}-tab`}
            key={tab.id}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountSetupTab;
