import { TrashIcon } from "@heroicons/react/24/solid";
import { Card } from "flowbite-react";
import React, { useEffect, useState } from "react";

import { ReactComponent as BankIcon } from "../../../../assets/svgs/bank-building.svg";
import CenteredModal from "alat-components-library/Modal/CenterModal";
import { setCenteredModal } from "react-wrappers/stores/redux/app/slices";
import { useAppDispatch } from "hooks/useReduxHook";
import { useDeleteBeneficiaries } from "hooks/apis/Payment/useDeleteBeneficiaries";
import { GET_BENEFICIARY_LIST_ACTION } from "react-wrappers/stores/redux/payments/actions";
import PageLoader from "components/Loader/PageLoader";
interface IBeneficiaryCardProps {
  id?: string;
  beneficiaryId?: number;
  index: any;
  // bankCode: any;
  bankName: any;
  accountNumber: any;
  accountName: any;
  // currency: any;
  handleSelectedBeneficiary: any;
  isLoading?: boolean;
  fetchBeneficiaries?: any;
}

const BeneficiaryCard = ({
  id,
  index,
  beneficiaryId,
  // bankCode,
  bankName,
  accountNumber,
  accountName,
  isLoading,
  // currency,
  handleSelectedBeneficiary,
  fetchBeneficiaries,
}: IBeneficiaryCardProps) => {
  const dispatch = useAppDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const {
    handleDeleteBeneficiary,
    deleteBeneficiaryLoading,
    deleteBeneficiaryStatus,
    // deleteBeneficiaryData,
  } = useDeleteBeneficiaries();

  useEffect(() => {
    if (deleteBeneficiaryStatus) {
      setShowDeleteModal(false);
      fetchBeneficiaries();
    }
  }, [deleteBeneficiaryStatus, fetchBeneficiaries]);

  return (
    <>
      {deleteBeneficiaryLoading && <PageLoader />}
      <div
        id={`beneficiary-${index + 1}_btn`}
        className="cursor-pointer mb-5 w-full"
        // onClick={handleSelectedBeneficiary(bankName)}
      >
        <Card className="box-border md:w-[450px] mx-auto w-full h-[114px] rounded-lg dark:!border-[#E4DFDF] dark:bg-white shadow-white hover:text-white hover:bg-primary fill-primary hover:fill-white">
          <div className="flex w-full justify-between items-center">
            <div
              onClick={() => {
                handleSelectedBeneficiary(accountNumber);
              }}
              className="flex items-center gap-3"
            >
              <div className="">
                <BankIcon fill="inherit" />
              </div>
              <div className="flex flex-col gap-3">
                <p
                  className="text-base text-left font-medium"
                  id={`account-name-${index + 1}_text`}
                >
                  {accountName?.toUpperCase()}
                </p>
                <p
                  className="text-[10px] text-left font-normal"
                  id={`saved-beneficiary-accNo-${index + 1}_text`}
                >
                  {accountNumber}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-3 items-end">
              <div
                className="w-5"
                onClick={() => {
                  setShowDeleteModal(true);
                }}
              >
                <TrashIcon fill="black" />
              </div>
              <p
                className="text-[10px] font-normal"
                id={`saved-beneficiary-bankName-${index + 1}_text`}
              >
                {bankName?.toUpperCase()}
              </p>
            </div>
          </div>
        </Card>
      </div>
      {showDeleteModal && (
        <CenteredModal
          id="delete_modal"
          idButton1="modal-proceed_btn"
          idCloseModal="modal-close_btn"
          title={`Are you sure you want to delete this beneficiary?`}
          info={""}
          btnText={"Proceed"}
          btnText2={"Cancel"}
          handleClick={() => {
            handleDeleteBeneficiary({
              id: beneficiaryId ?? 0,
            });
            dispatch(
              setCenteredModal({
                status: "",
                title: "",
                info: "",
              })
            );
          }}
          handleClick2={() => {
            setShowDeleteModal(false);
            dispatch(
              setCenteredModal({
                status: "",
                title: "",
                info: "",
              })
            );
          }}
          handleClose={(e: any) => {
            ("");
            setShowDeleteModal(false);
            dispatch(
              setCenteredModal({
                status: "",
                title: "",
                info: "",
              })
            );
          }}
          showCloseIcon
        />
      )}
    </>
  );
};
export default BeneficiaryCard;
