import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Button, Card } from "flowbite-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { formatNumberToCurrency } from "utils/helperFunctions";

export interface PopUpProps {
  onRefresh?: any;
  //   type: string;
  onGenerateReceipt?: any;
  amount?: number | string;
  recipient?: any;
  saveBeneficiary?: boolean;
  saveGroup?: boolean;
  bankCode?: any;
  bankName?: any;
  accountNumber?: any;
  currency?: any;
  pin?: any;
  correlationId?: string;
  onRetry?: any;
  transaction?: any;
  handlePrev?: any;
}
const TransactionBreakdown = ({
  onRefresh,
  //   type,
  onGenerateReceipt,
  amount = 0,
  recipient = null,
  saveBeneficiary = false,
  saveGroup = false,
  bankCode,
  bankName,
  accountNumber,
  currency = "NGN",
  pin,
  correlationId,
  onRetry,
  transaction,
  handlePrev,
}: PopUpProps) => {
  const navigate = useNavigate();
  // const handleGotoDashboard = () => {
  //   navigate("/user-dashboard");
  // };
  // const baseURL = "https://wema-afbdev-apim.azure-api.net";
  const baseURL = process.env.REACT_APP_API_ORIGIN;

  const token: any = localStorage.getItem("afb-app-token");
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  // eslint-disable-next-line
  const [toggle, setToggle] = useState(false);
  // const [transfersModal, setTransfersModal] = useState(false);
  // eslint-disable-next-line
  const [saveBeneficiaryError, setSaveBeneficiaryError] = useState("");
  // const handleSaveBeneficiaryToggle = () => {
  //   setToggle(true);
  //   handleSaveBeneficiary();
  // };
  // eslint-disable-next-line
  const handleSaveGroupToggle = () => {
    setToggle(true);
    handleSaveGroup();
  };
  // eslint-disable-next-line
  const handleSaveBeneficiary = async () => {
    // prevents the submit button from refreshing the page

    // setisLoading(true);
    const body = {
      beneficiaryId: 0,
      beneficiaryType: 3,
      beneficiaryManagementAction: 1,
      currencyCategory: 1,
      alias: "",
      bankCode: bankCode,
      bankName: bankName,
      accountNumber: accountNumber,
      accountName: recipient,
      currency: currency,
      transactionPin: pin,
    };
    try {
      // const res = await makeRequest.post(validateTransfer, body);
      const res = await axios.post(
        `${baseURL}/afbtransferservice/api/v1/Beneficiary/AddBeneficiary`,
        body,
        options
      );

      setToggle(true);
      if (res.data.httpStatusCode === 200) {
        if (res.data.data) {
          // setshowScreen(2);
        } else {
          // setshowScreen(3);
        }
      } else {
        // setshowScreen(2);
      }
      // setisLoading(false);
      // setshowScreen(7);
    } catch (e: any) {
      // setisLoading(false);
      // setshowScreen(3);

      if (e.response.status === 400) {
        setSaveBeneficiaryError(e.response.data.responseMessage.title);
      }
    }
  };
  const handleSaveGroup = async () => {
    // prevents the submit button from refreshing the page

    // setisLoading(true);
    const body = {
      correlationId: correlationId,
      groupName: "New Group",
    };
    try {
      // setisLoading(false);
      // const res = await makeRequest.post(validateTransfer, body);
      const res = await axios.post(
        `${baseURL}/afbtransferservice/api/v1/Transfer/AddMultiplieTransferGroup`,
        body,
        options
      );

      setToggle(true);
      if (res.data.httpStatusCode === 200) {
        if (res.data.data) {
          // setshowScreen(2);
        } else {
          // setshowScreen(3);
        }
      } else {
        // setshowScreen(2);
      }
      // setisLoading(false);
      // setshowScreen(7);
    } catch (e: any) {
      // setisLoading(false);
      // setshowScreen(3);

      if (e.response.status === 400) {
        setSaveBeneficiaryError(e.response.data.responseMessage.title);
      }
    }
  };
  return (
    <div>
      <div>
        <div></div>
        <div>
          <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
            <div className="flex flex-col py-5 px-5 w-full relative mb-4 bg-white rounded-lg">
              <div className="flex items-center justify-between">
                <div
                  id="back_btn"
                  onClick={handlePrev}
                  className="cursor-pointer"
                >
                  <ChevronLeftIcon className="h-6 w-6 text-[#333333]" />
                </div>
                <div className="text-[#4D4D4D] text-xl text-center mb-5 font-semibold font-inter ">
                  Transaction Breakdown
                </div>
                <div></div>
              </div>
              <div className="w-full mx-auto rounded-lg">
                <table className="table w-full overflow-x-auto text-xs font-semibold text-center py-2 p-1 px-4">
                  <thead>
                    <tr>
                      <th className="py-2 pl-4 text-left font-normal text-sm">
                        #
                      </th>
                      <th className="py-2 text-left font-normal text-sm">
                        Recepients
                      </th>
                      <th className="py-2 text-left font-normal text-sm">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transaction?.map((x: any, index: any) => (
                      <tr
                        className="text-[#333333] odd:bg-gray-100 even:bg-white"
                        key={index}
                      >
                        <td className="py-5 font-light text-sm px-4 text-left">
                          {index + 1}
                        </td>
                        <td className="py-5 font-light text-sm px-4 text-left">
                          <span
                            className="text-black"
                            id={`destination-acc-${index + 1}-name_text`}
                          >
                            {x?.destinationAccountName}
                          </span>
                          <br />
                          <span className="text-black">
                            {formatNumberToCurrency({
                              currencyCode: "NGN",
                              number: x?.amount,
                            })}
                          </span>
                          <br />
                          <span className="text-black">
                            {x.destinationBankName} -{" "}
                            {x.destinationAccountNumber}
                          </span>
                        </td>
                        <td
                          className="py-5 font-light text-sm px-4 text-left break-all"
                          id={`destination-acc-${index + 1}-status_text`}
                        >
                          {x.status === 5 && (
                            <span className="text-red-500">Failed</span>
                          )}
                          {x.status === 1 && (
                            <span className="text-yellow-500">Pending</span>
                          )}
                          {x.status === 11 && (
                            <span className="text-green-500">Success</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* buttons */}
              <div className="flex justify-center flex-row gap-4 mb-40 mt-10">
                {/* <Button
                  color="gray"
                  onClick={onRefresh}
                  className="hover:text-white dark:hover:!text-primary dark:hover:bg-white dark:border-gray-200 dark:text-primary border-primary text-primary  w-[188px] bg-primary hover:bg-primary focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary focus:text-primary focus:bg-white"
                >
                  Refresh
                </Button> */}
                <Button
                  id="proceed-to-dashboard_btn"
                  onClick={() => navigate("/user-dashboard")}
                  className="hover:text-white dark:hover:!text-white dark:hover:bg-primary  w-[188px] bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:boder-primary  focus:text-white"
                >
                  Proceed to Dashboard
                </Button>
              </div>
            </div>
          </Card>

          <div></div>
        </div>
      </div>
    </div>
  );
};

export default TransactionBreakdown;
