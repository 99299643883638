import { useEffect, useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import {
  GET_STATES,
  GET_CITIES,
  GET_LGAS,
  GET_BRANCHES,
} from "../../../utils/apis/routes";
import { ToastNotify } from "helpers/toastNotify";

// export const GET_STATES = `card/states`;
// export const GET_CITIES = `card/cities`;
// export const GET_LGAS = `card/lgas`;
// export const GET_BRANCHES = `card/branches`;

export const useGetStates = () => {
  //Local States
  const [statesLoading, setStatesLoading] = useState<boolean>(false);
  const [statesData, setStatesData] = useState<any>([]);
  const [statesError, setStatesError] = useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleStates = async () => {
    setStatesLoading(true);
    try {
      const res = await makeRequest({
        method: "get",
        route: GET_STATES,
        originType: "identity",
      });
      //Map the payload
      // console.log("res-states", res)
      if (res?.status === 200) {
        setStatesData(res?.data?.data?.states);
      } else {
        ToastNotify({
          type: "error",
          message:
            res?.status === 500
              ? "Oops! Something went wrong"
              : `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
        });
      }

      setStatesLoading(false);
    } catch (error) {
      setStatesError(
        "An error occured while fetching states, please referesh and try again"
      );
      setStatesLoading(false);
    }
  };

  useEffect(() => {
    handleStates();
  }, []);

  return {
    statesData,
    statesLoading,
    statesError,
    handleStates,
  };
};

export const useCities = (stateId: string | null) => {
  //Local States
  const [citiesLoading, setCitiesLoading] = useState<boolean>(false);
  const [citiesData, setCitiesData] = useState<any>({});
  const [citiesError, setCitiesError] = useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleCities = async (stateId: string) => {
    setCitiesLoading(true);
    try {
      const res = await makeRequest({
        method: "get",
        route: GET_CITIES(stateId),
        originType: "identity",
      });
      //Map the payload
      if (res?.status === 200) {
        setCitiesData(res?.data?.data?.cities);
      } else {
        ToastNotify({
          type: "error",
          message:
            res?.status === 500
              ? "Oops! Something went wrong"
              : `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
        });
      }
      setCitiesLoading(false);
    } catch (error) {
      setCitiesError(
        "An error occured while fetching branch sol ids, please referesh and try again"
      );
      setCitiesLoading(false);
    }
  };

  useEffect(() => {
    stateId && handleCities(stateId);
  }, [stateId]);

  return {
    citiesData,
    citiesLoading,
    citiesError,
    handleCities,
  };
};

export const useLgas = (stateId: string | null) => {
  //Local States
  const [lgasLoading, setLgasLoading] = useState<boolean>(false);
  const [lgasData, setLgasData] = useState<any>({});
  const [lgasError, setLgasError] = useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleLgas = async (stateId: string) => {
    setLgasLoading(true);
    try {
      const res = await makeRequest({
        method: "get",
        route: GET_LGAS(stateId),
        originType: "identity",
      });
      //Map the payload
      if (res?.status === 200) {
        setLgasData(res?.data?.data.localGovernments);
      } else {
        ToastNotify({
          type: "error",
          message:
            res?.status === 500
              ? "Oops! Something went wrong"
              : `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
        });
      }

      setLgasLoading(false);
    } catch (error) {
      setLgasError(
        "An error occured while fetching LGA, please referesh and try again"
      );
      setLgasLoading(false);
    }
  };

  useEffect(() => {
    stateId && handleLgas(stateId);
  }, [stateId]);

  return {
    lgasData,
    lgasLoading,
    lgasError,
    handleLgas,
  };
};

export const useBranches = (stateId: string | null) => {
  //Local States
  const [branchesLoading, setBranchesLoading] = useState<boolean>(false);
  const [branchesData, setBranchesData] = useState<any>([]);
  const [branchesError, setBranchesError] = useState<string>("");

  //API Request
  const makeRequest = useApiRequest();

  const handleBranches = async (stateId: string) => {
    setBranchesLoading(true);
    try {
      const res = await makeRequest({
        method: "get",
        route: GET_BRANCHES(stateId),
        originType: "identity",
      });
      //Map the payload
      if (res?.status === 200) {
        setBranchesData(res?.data?.data?.branches);
      } else {
        ToastNotify({
          type: "error",
          message:
            res?.status === 500
              ? "Oops! Something went wrong"
              : `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
        });
      }

      setBranchesLoading(false);
    } catch (error) {
      setBranchesError(
        "An error occured while fetching branches, please referesh and try again"
      );
      setBranchesLoading(false);
    }
  };

  useEffect(() => {
    stateId && handleBranches(stateId);
  }, [stateId]);

  return {
    branchesData,
    branchesLoading,
    branchesError,
    handleBranches,
  };
};
