import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card } from "flowbite-react";
import React, { useState } from "react";

import GroupCard from "./GroupCard";
import SearchComponent from "./SearchComponent";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
// import { ISavedGroupList } from "../MultipleTransfer/SavedGroup";
interface TransferGroupDetail {
  accountNumber: string;
  accountName: string;
  bankName: string;
  bankCode: string;
  amount: number;
  referenceId: string;
}
export interface ISavedGroupList {
  groupName: string;
  referenceId: string;
  transferGroupDetails: TransferGroupDetail[];
}
export type IListAllSavedGroupsProps = {
  accountNumber?: any;
  handleBackToFundsTransfer: any;
  title: string;
  handleScreenChangeNext: any;
  savedGroupList: ISavedGroupList[];
  handleSelectedGroup: any;
};

const ListAllSavedGroups = ({
  title,
  handleBackToFundsTransfer,
  handleScreenChangeNext,
  savedGroupList,
  handleSelectedGroup,
}: IListAllSavedGroupsProps) => {
  // const location = useLocation();

  const [word, setWord] = useState("");

  const handleWordChange = (event: { target: { name: any; value: any } }) => {
    // console.log(event.target.name, event.target.value);

    if (event.target.name === "word") {
      setWord(event.target.value);
    }
  };

  // const filteredsavedGroupList = savedGroupList.filter((bene: any) =>
  //   bene.accountName.toLowerCase().includes(word.toLowerCase())
  // );
  const filteredsavedGroupList = savedGroupList.filter((group: any) =>
    group.groupName.toLowerCase().includes(word.toLowerCase())
  );

  return (
    <div className="w-full flex items-center justify-center">
      <ReusableCard
        variant="md:w-[48rem] w-full"
        cardTitle={title}
        handleBackNavigation={handleBackToFundsTransfer}
        showGoBackIcon
      >
        <div className="flex items-center w-full justify-center">
          <form className="flex flex-col gap-4 justify-center items-center">
            {/* <div className="text-[#666666]">
              Complete transaction details below
            </div> */}
            <div className="">
              <SearchComponent
                word={word}
                handleWordChange={handleWordChange}
                title="Search for group"
                // size={500}
              />

              {/* <div
                className="border-solid w-[2.7rem] border rounded-md cursor-pointer"
                onClick={handleShowScreen}
              >
                <PlusIcon fill="#828282" />
              </div> */}
            </div>

            <div className="mb-14">
              {filteredsavedGroupList.length > 0 ? (
                filteredsavedGroupList.map((bank: any, index: any) => (
                  <GroupCard
                    key={index}
                    // bankCode={bank.bankCode}
                    groupName={bank.groupName}
                    referenceId={bank.referenceId}
                    transferGroupDetails={bank.transferGroupDetails}
                    // currency={bank.currency}

                    handleSelectedBeneficiary={handleSelectedGroup}
                  />
                ))
              ) : (
                <div>No Groups</div>
              )}
            </div>
          </form>
        </div>
      </ReusableCard>
      {/* <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <div
              id="back_btn"
              onClick={handleBackToFundsTransfer}
              className="cursor-pointer"
            >
              <ChevronLeftIcon className="h-6 w-6 text-[#333333]" />
            </div>
            <div className="text-[#4D4D4D] text-[18px] font-semibold text-center">
              {title}
            </div>
            <div></div>
          </div>
        </div>
        <hr />
      </Card> */}
    </div>
  );
};

export default ListAllSavedGroups;
