export enum TransactionResponseStatusEnum {
  Rejected = 21,
  Processing = 20,
  Completed = 18,
  Retry = 17,
  Success = 11,
  Failed = 5,
  Pending = 1,
  None = 0,
}

export enum TransactionTypeEnum {
  SingleSignatorySelfTransfer = 1,
  SingleSignatoryIntraTransfer = 2,
  SingleSignatoryInterTransfer = 3,
  SingleSignatoryMultipleTransfer = 4,
  MultipleSignatorySelfTransfer = 5,
  MultipleSignatoryIntraTransfer = 6,
  MultipleSignatoryInterTransfer = 7,
  MultipleSignatoryMultipleTransfer = 8,
  SingleSignatoryBulkIntraTransfer = 9,
  SingleSignatoryBulkInterTransfer = 10,
  MultipleSignatoryBulkIntraTransfer = 11,
  MultipleSignatoryBulkInterTransfer = 12,
  SoftTokenDebit = 13,
  AlatPayDebit = 14,
  SingleSignatoryAirtimePurchase = 15,
  SingleSignatoryDataPurchase = 16,
  SingleSignatoryBillPayment = 18,
  SingleSignatoryCardRequest = 19,
  CustomerLegalSearchDebit = 20,
  SingleSignatoryCollectionPayment = 21,
  MultipleSignatoryCollectionPayment = 22,
  MultipleSignatoryBulkCombinationTransfer = 23,
  SingleSignatoryBulkCombinationTransfer = 24,
  MultipleSignatoryBillsPayment = 25,
  SingleSignatoryFlightPayment = 26,
  MultipleSignatoryFlightPayment = 27,
}

export enum TransactionOperation {
  All = 1,
  Debit = 2,
  Credit = 3,
}
export enum NetworkVendorsEnum {
  MTN = 1,
  GLO = 2,
  NINE_MOBILE = 3,
  AIRTEL = 4,
}
export enum TransactionStatusEnum {
  Rejected = 21,
  Processing = 20,
  Completed = 18,
  Retry = 17,
  Success = 11,
  Failed = 5,
  Pending = 1,
  None = 0,
}

export enum BeneficiaryTypesEnum {
  None = 0,
  Airtime,
  Data,
  Account,
  Card,
}
