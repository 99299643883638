import { redirectTo } from "../../../../utils/helperFunctions";
import { removeLoadingState } from "../../../../helpers/assitFunctions";
import { ToastNotify } from "../../../../helpers/toastNotify";
import { Storage } from "../../../../utils/inAppstorage";
import { AuthState } from "../interfaces";

export const triggerLogin = (state: AuthState, action: any) => {
  state.loading = removeLoadingState(state.loading, "POST_LOGIN");

  const isFirstTimeLogin: boolean =
    action?.payload?.data?.data?.responseCode === 19 ||
    action?.payload?.data?.data?.responseCode === 20;
  if (isFirstTimeLogin) {
    state.firstTimeLogin = {
      ...state.firstTimeLogin,
      responseCode: action.payload?.data?.data?.responseCode,
    };
    return redirectTo("/first-time/reset-password");
  }

  if (action?.payload?.data?.data?.hasError && !isFirstTimeLogin) {
    return ToastNotify({
      type: "error",
      message: `${action?.payload?.data?.data?.responseMessage?.title}. ${action?.payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }

  if (action?.payload?.status !== 200) return;

  state.user = action?.payload?.data?.data || null;
  state.token = action?.payload?.data?.data?.token || null;
  state.userRole =
    action?.payload?.data?.data?.role?.toLowerCase() || "soleproprietor";
  state.primaryAccountNumber =
    action?.payload?.data?.data?.primaryAccountNumber;

  //Store to local Storage
  // Storage.setItem("afb-app-user", action?.payload?.data?.data || null);
  Storage.setItem("afb-app-token", action?.payload?.data?.data?.token || null);
  Storage.setItem("token-timer", new Date());

  const isAdmin = action?.payload?.data?.data?.role?.toLowerCase() === "admin";
  isAdmin ? redirectTo("/admin-dashboard") : redirectTo("/user-dashboard");
};

export const triggerResetPassword = (state: AuthState, action: any) => {
  state.loading = removeLoadingState(state.loading, "POST_RESET_PASSWORD");
  //Prevent no success status

  // console.log("payload", action?.payload);
  // if (action?.payload?.data?.data?.responseCode === 3) return redirectTo("/logout");

  // if (action.payload.status !== 200) return;

  if (action?.payload?.data?.data?.hasError) {
    state.resetPassword = {
      ...state.resetPassword,
      successStatus: false,
    };
    state.resetPassword.errorMessage = {
      ...state.resetPassword.errorMessage,
      action: action?.payload?.data?.data?.responseMessage?.actionMessage,
      title: action?.payload?.data?.data?.responseMessage?.title,
    };
    return ToastNotify({
      type: "error",
      message: `${action?.payload?.data?.data?.responseMessage?.title}. ${action?.payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  } else {
    state.resetPassword = {
      ...state.resetPassword,
      successStatus: true,
      successMessage: action?.payload?.data?.data,
    };
    // state.resetPassword.successStatus = true;
    // state.resetPassword.successMessage = action?.payload?.data?.data
  }
};
export const triggerFirstTimeLogin = (state: AuthState, action: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_FIRST_TIME_RESET_PASSWORD"
  );
  //Prevent no success status
  // if (action.payload.status !== 200) return;

  if (action?.payload?.data?.data?.responseCode === 3)
    return redirectTo("/logout");

  if (action?.payload?.data?.data?.hasError) {
    state.firstTimeLogin = {
      ...state.firstTimeLogin,
      successStatus: false,
    };
    state.firstTimeLogin.errorMessage = {
      ...state.firstTimeLogin.errorMessage,
      action: action?.payload?.data?.data?.responseMessage?.actionMessage,
      title: action?.payload?.data?.data?.responseMessage?.title,
    };
    return ToastNotify({
      type: "error",
      message: `${action?.payload?.data?.data?.responseMessage?.title}. ${action?.payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  } else {
    state.firstTimeLogin = {
      ...state.firstTimeLogin,
      successStatus: true,
      successMessage: action?.payload?.data?.data,
    };
  }
};

// export const triggerResetPagination = (state: AuthState, action: any) => {
//    state.pagination = {
//       ...state.pagination,
//       sortedData: [],
//    };
// };

export const triggerLogout = () => {
  Storage.removeItem("token-timer");
  Storage.removeItem("afb-app-token");
  localStorage.clear();
  redirectTo("/logout");
};
