import React, { useEffect } from "react";
import FileUploadIcon from "../../../assets/svgs/file-upload.svg";
import { ReactComponent as FolderIcon } from "../../../assets/svgs/folder-icons.svg";
import { useUploadBlobToAzure } from "hooks/apis/useUploadBlobToAzure";
import { useField } from "formik";
import PageLoader from "components/Loader/PageLoader";
import { toast } from "react-toastify";
import { ToastNotify } from "helpers/toastNotify";

interface Props {
  id?: string;
  uploadTextId?: string;
  label?: string;
  uploadText?: string;
  error?: string;
  documentType?: string;
  className?: string;
  name: string;
  value?: string;
  info?: string;
  handleBlur?: any;
  maxSize?: number;
  DocumentType?: string;
  CacRegNumber?: string;
  Extension?: string;
  DocumentName?: string;
  accept?: string[];
  container?: string;
  defaultValue?: string;
}
const FileUpload: React.FC<Props> = ({
  label = "",
  error,
  info = "Document must be in PDF or JPEG and not more than 1 mb",
  uploadText = "Click here to upload",
  uploadTextId,
  documentType = "",
  className = "",
  id = "",
  name,
  handleBlur,
  maxSize = 1000000,
  DocumentType = "",
  CacRegNumber = "",
  Extension = "",
  container = "backoffice",
  DocumentName = name,
  accept = ["image/jpeg", "application/pdf"],
  defaultValue = null,
}) => {
  // Local state

  // const [blobUrl, setBlobUrl] = useState("");
  // const [fileDropped, setFileDropped] = useState(false);
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name);
  const { blobFiles, handleUploadBlob, isUploadingBlob } = useUploadBlobToAzure(
    name,
    container
  );

  const handleDragOver = (e: any) => {
    // Prevent file from being opened
    e.preventDefault();
    // setFileDropped(true);
  };

  //Handles drop event in the drop area.
  const handleDrop = (e: any) => {
    helpers.setTouched(true);
    e.preventDefault();
    if (e.target?.files) {
      // console.log("fileA", e.target.files[0].type);
      if (!accept?.includes(`${e.target.files[0]?.type}`)) {
        ToastNotify({
          type: "error",
          message: "Invalid file type",
        });
      } else if (e.target?.files?.length > 1) {
        ToastNotify({
          type: "error",
          message: "Unable to upload multiple files",
        });
      } else if (e?.target?.files[0]?.size > maxSize) {
        ToastNotify({
          type: "error",
          message: "File too large",
        });
      } else {
        if (e.target.files) {
          const file = e.target.files[0];
          const fileExtension = file.name.split(".").pop();
          handleUploadBlob(
            file,
            DocumentType,
            CacRegNumber,
            fileExtension,
            DocumentName
          );
        } else if (e.dataTransfer.items) {
          if (e.dataTransfer.items[0].kind === "file") {
            const file = e.dataTransfer.items[0].getAsFile();
            const fileExtension = file.name.split(".").pop();
            handleUploadBlob(
              file,
              DocumentType,
              CacRegNumber,
              fileExtension,
              DocumentName
            );
          }
        } else {
          [...e.dataTransfer.files].forEach((file, i) => {});
        }
        // setFileDropped(true);
        // setBlobUrl(blobFiles?.url);
      }
    } else if (e.dataTransfer.items) {
      // console.log("fileB", e.dataTransfer.items[0].getAsFile().type);
      if (e.dataTransfer.items[0].kind === "file") {
        const file = e.dataTransfer.items[0].getAsFile();
        if (!accept?.includes(`${file?.type}`)) {
          ToastNotify({
            type: "error",
            message: "Invalid file type",
          });
        } else if (file?.size > maxSize) {
          ToastNotify({
            type: "error",
            message: "File too large",
          });
        } else {
          handleUploadBlob(
            file,
            DocumentType,
            CacRegNumber,
            Extension,
            DocumentName
          );
        }
      }
    }
  };

  useEffect(() => {
    // console.log("Im right here");
    // console.log(`The two names ${field.name} and ${blobFiles.fieldName}`);

    if (blobFiles.fieldName === field.name) {
      helpers.setValue(blobFiles?.url);
    }
    // eslint-disable-next-line
  }, [blobFiles]);

  return (
    <>
      {isUploadingBlob && <PageLoader message="uploading document" />}

      <div className={className}>
        <label className="text-sm text-[#333333] -mb-1">{label}</label>
        <div
          className={`relative w-full h-32 flex flex-col justify-center items-center rounded-lg border-2 border-dashed border-spacing-4 bg-lib-alat-gray-input py-8 cursor-pointer overflow-hidden 
                ${error ? "border-red-500" : ""}`}
          // id="drop-zone"
          id={id}
          onDrop={handleDrop}
          onDragOverCapture={handleDragOver}
          onClick={() => document?.getElementById(name)?.click()}
        >
          <input
            type={"file"}
            className="hidden"
            id={name}
            onChange={handleDrop}
            onBlur={handleBlur}
            onClick={() => {
              helpers.setTouched(true);
            }}
            accept={accept.join()}
            maxLength={maxSize}
          />
          <img
            src={
              defaultValue === "" || !defaultValue
                ? FileUploadIcon
                : defaultValue
            }
            alt="upload"
            className={`cursor-pointer object-cover hover:scale-110 
                ${blobFiles?.url && field.value !== "" && "hidden"}`}
            // onClick={() => document.getElementById(name)?.click()}
          />
          {blobFiles?.url &&
            field.value !== "" &&
            !blobFiles?.url?.toLowerCase()?.includes("pdf") && (
              <img
                src={blobFiles?.url}
                alt="alt"
                className="h-32 w-full rounded-lg object-cover absolute"
              />
            )}
          {blobFiles?.url?.toLowerCase()?.includes("pdf") &&
            field.value !== "" && (
              <div className="h-12 w-16 rounded-lg relative flex flex-col">
                <div className="h-full w-full">
                  <FolderIcon className="h-full w-full text-black" />
                </div>
              </div>
            )}
          {blobFiles?.url && field.value !== "" && (
            <div className="mt-4 text-xs text-black">
              {blobFiles.name.split(".pdf")[0] + ".pdf"}
            </div>
          )}
          <div
            className={`text-lib-alat-dark-red text-center cursor-pointer 
                        ${blobFiles?.url && field.value !== "" && "hidden"}`}
            // onClick={() => document?.getElementById(name)?.click()}
          >
            <p id={uploadTextId}>{uploadText}</p>
            <p>{documentType}</p>
          </div>
        </div>
        <label className="text-xs text-lib-alat-dark-red -mt-2">{info}</label>
        <br />
        <label className="text-xs text-red-500">{error}</label>
      </div>
    </>
  );
};

export default FileUpload;
