import PendingCountBg from "../../../assets/images/pending-star.png";

const PendingDocumentsCard = ({
  handleRejectedDocuments,
  count,
}: {
  handleRejectedDocuments: () => void;
  count: number;
}) => {
  return (
    <>
      <div className="bg-white h-80 rounded-lg p-4 relative">
        <div className="w-full h-full pb-10 relative">
          <h4 className="text-left text-xl font-semibold">Pending Actions</h4>
          <div className="flex items-center w-full justify-between h-full p-4">
            <div
              className="flex items-center w-full justify-between h-full border border-lib-alat-red rounded-xl cursor-pointer"
              onClick={handleRejectedDocuments}
            >
              <div className="relative w-40 sm:w-1/3 md:w-60 aspect-square items-center justify-center flex">
                <img
                  src={PendingCountBg}
                  alt=""
                  className="absolute md:w-full md:h-full"
                />
                <div className="border-4 border-[#FF9900] h-2/4 w-2/4 rounded-full flex items-center justify-center text-[#FF9900] font-semibold text-lg">
                  {count ?? 0}
                </div>
              </div>
              <div className="w-full">
                <p className="text-[5vw] md:text-2xl font-semibold xsm:whitespace-nowrap">
                  Documents Rejected
                </p>
                <p className="text-xs xsm:text-[14px] md:text-[16px]">
                  Tap here to take action
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingDocumentsCard;
