import React from "react";
import ReactDOMServer from "react-dom/server";
import DudLetter, { PDFProps } from "./DudLetter";

export const staticHtmlString = ({
  bvnName,
  recordCount,
  businessName,
  signature,
}: PDFProps) => {
  return ReactDOMServer.renderToStaticMarkup(
    <DudLetter
      bvnName={bvnName}
      recordCount={recordCount}
      businessName={businessName}
      signature={signature}
    />
  );
};
