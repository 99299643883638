import { useNavigate } from "react-router-dom";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import SearchComponent from "components/Payments/BankTransfer/Reusables/SearchComponent";
import {
  setActiveSelectedCollection,
  useCollectionsState,
} from "react-wrappers/stores/redux/collections/slices";
import { useAppDispatch } from "hooks/useReduxHook";
import { GET_BILL_CATEGORIES_LIST_ACTION } from "react-wrappers/stores/redux/payments/actions";
import PageLoader from "alat-components-library/PageLoader";
import { usePayBillController } from "controllers/Payments/Paybill";
import { useGetCollections } from "hooks/apis/Collections/useGetCollections";
import { useCollectionsController } from "controllers/Collections";

const Remita = () => {
  const navigate = useNavigate();

  const handleSelected = () => {
    navigate(`/bills/collections/select-category`);
  };

  return (
    <div className="w-full">
      <div className="grid md:grid-cols-3 grid-cols-2 gap-6 py-5">
        <div
          onClick={() => navigate("/remita/withRRR")}
          className="flex md:justify-left justify-center cursor-pointer items-center bg-white h-24 w-full boxShadow px-10 py-6 rounded-md"
        >
          <p className="text-[#333]">With RRR</p>
        </div>
        <div
          onClick={() => handleSelected()}
          className="flex md:justify-left justify-center cursor-pointer items-center bg-white h-24 w-full boxShadow px-10 py-6 rounded-md"
        >
          <p className="text-[#333]">Without RRR</p>
        </div>
      </div>
    </div>
  );
};

export default Remita;
