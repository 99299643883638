import { useState } from "react";
import { useApiRequest } from "hooks/useApiRequest";
import { GET_E_REFEREE_INFO, GET_WEMA_REFEREE_INFO } from "utils/apis/routes";

export const useGetWemaRefereeInfo = () => {
  //Local States
  const [wemaRefereeLoading, setWemaRefereeLoading] = useState<boolean>(false);
  const [wemaRefereeError, setWemaRefereeError] = useState<string>("");
  const [wemaRefereeData, setWemaRefereeData] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const getWemaReferee = async (id: string, isEReferee = false) => {
    setWemaRefereeError("");
    setWemaRefereeLoading(true);
    const API_URL = isEReferee
      ? GET_E_REFEREE_INFO(id)
      : GET_WEMA_REFEREE_INFO(id);
    const REQUEST_TYPE = isEReferee ? "ereference" : "onboarding";

    try {
      const res = await makeRequest({
        method: "get",
        route: API_URL,
        originType: REQUEST_TYPE,
      });

      if (res?.data) {
        setWemaRefereeData(res?.data?.data);
        setWemaRefereeError("");
      } else {
        setWemaRefereeError("An error occured while fetching WemaReferee data");
      }
      setWemaRefereeLoading(false);
    } catch (error) {
      setWemaRefereeError(
        "An error occured while fetching branch sol ids, please referesh and try again"
      );
      setWemaRefereeLoading(false);
    }
  };
  return {
    wemaRefereeLoading,
    wemaRefereeError,
    wemaRefereeData,
    getWemaReferee,
  };
};
