import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import { GET_EXPORT_BULK_TRANSFER_SUMMARY } from "../../../utils/apis/routes";
import { ToastNotify } from "helpers/toastNotify";
import axiosStatus from "utils/apis/axios/errorStatus";

export const useExportBulkTransactions = () => {
  //Local States
  const [exportBulkStatus, setExportBulkStatus] = useState<boolean>(false);
  const [exportBulkLoading, setExportBulkLoading] = useState<boolean>(false);
  const [exportBulkData, setExportBulkData] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const handleExportBulkTransferSummary = async ({
    accountNumber,
    reference,
  }: {
    accountNumber: string;
    reference: string;
  }) => {
    try {
      setExportBulkLoading(true);

      const res = await makeRequest({
        method: "get",
        route: GET_EXPORT_BULK_TRANSFER_SUMMARY({
          accountNumber,
          referenceNumber: reference,
        }),
        originType: "transferservice",
      });
      //   console.log("res-bulk", res);
      //Map the payload
      if (res?.data?.hasError) {
        setExportBulkStatus(false);
        ToastNotify({
          type: "error",
          message: `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      } else if (res?.status === 500) {
        setExportBulkStatus(false);
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        setExportBulkStatus(true);
        setExportBulkData(res?.data?.data);
      }

      setExportBulkLoading(false);
    } catch (error) {
      ToastNotify({
        type: "error",
        message: `${axiosStatus(error)}`,
        position: "top-right",
      });
      setExportBulkLoading(false);
    }
  };

  return {
    handleExportBulkTransferSummary,
    exportBulkData,
    exportBulkLoading,
    exportBulkStatus,
  };
};
