import * as yup from "yup";

export function createYupSchema(schema: any, config: any) {
  const { fieldName, isRequired = [] } = config;

  let validator = yup["string"]();
  if (!isRequired) {
    return;
  }
  validator = validator["required"](`${fieldName} is required`);

  schema[fieldName] = validator;
  return schema;
}

export const dynamicFormValidationSchema = (form: any) =>
  yup.object().shape(
    form.map((f: any) => {
      return {
        [f.fieldName]: f.isRequired
          ? yup.string().trim().required(`Please enter ${f.fieldName}`)
          : yup.string().notRequired(),
      };
    })
  );
