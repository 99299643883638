import { TabsRef, Tabs, Flowbite } from "flowbite-react";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { setFundMyAcctSelectedAccout } from "react-wrappers/stores/redux/payments/slices";
import { useAppDispatch } from "hooks/useReduxHook";
import Billpayments from "./Billpayments";
import Collections from "components/Collections/Collections";
import Remita from "components/Remita/Remita";

const NewBillsTab = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userRole } = useAuthState();

  const activeTab = window.location.pathname;
  const tabsRef = useRef<TabsRef>(null);

  const theme = {
    tabs: {
      tablist: {
        base: "flex text-center gap-2 z-0",
        tabitem: {
          base: "flex text-center",
          variant: {
            underline: {
              base: "rounded-t-lg",
              active: {
                on: "active py-2 rounded-t-lg border-b-2 border-lib-alat-red text-lib-alat-red font-medium",
                off: "py-2 border-b-2 border-transparent text-lib-alat-black font-medium",
              },
            },
          },
        },
      },
    },
  };

  return (
    <div className="w-full">
      <Flowbite theme={{ theme }}>
        <Tabs
          aria-label="Default tabs"
          // eslint-disable-next-line react/style-prop-object
          variant="underline"
          ref={tabsRef}
          onActiveTabChange={(tab: React.SetStateAction<number>) => {
            let url = "/bills/payment";
            dispatch(setFundMyAcctSelectedAccout(null));
            navigate(url);
          }}
          className=" !border-[#E4DFDF]"
        >
          <Tabs.Item
            active={activeTab.includes("/payment")}
            title="New Bills"
            className=""
            id="bills_tab"
          >
            <Billpayments />
          </Tabs.Item>

          <Tabs.Item
            active={activeTab.includes("/payment")}
            title="Collections"
            className=""
            id="collections_tab"
          >
            <Collections />
          </Tabs.Item>
          <Tabs.Item
            active={activeTab.includes("/payment")}
            title="Remita"
            className=""
            id="collections_tab"
          >
            <Remita />
          </Tabs.Item>
        </Tabs>
      </Flowbite>
    </div>
  );
};

export default NewBillsTab;
