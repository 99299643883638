import { createAsyncThunk } from "@reduxjs/toolkit";
import { useApiRequest } from "../../../../hooks/useApiRequest";
import {
  GET_TRANSACTION_STATUS,
  POST_TRANSACTION_VALIDATE,
  POST_VALIDATE_PIN,
  GET_ACCOUNT_NAME_ENQUIRY,
  GET_BENEFICIARY_LIST,
  GET_GROUP_LIST,
  POST_FETCH_TRANSACTION_LIST,
  GET_PENDING_USERS_REQUEST_DETAILS,
  POST_FETCH_TRANSACTION_DETAIL,
  PUT_APROVE_OR_REJECT_TRANSACTION,
  POST_REVIEW_USER_REQUEST,
  POST_TRANSFER_TRANSACTION,
  PUT_COMPLETE_TRANSFER_TRANSACTION,
  POST_FETCH_COLLECTIONS_DETAIL,
  POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS,
  GET_RESEND_TRANSFER_OTP,
  POST_FETCH_ALL_BULK_TRANSFER,
  POST_FETCH_ONE_BULK_TRANSFER,
  POST_CREATE_ACCOUNT_STATEMENT,
  GET_BILL_CATEGORIES_LIST,
  POST_VALIDATE_BILL_REFERENCE,
  POST_CREATE_BILL,
  POST_FETCH_BILLS_DETAIL,
  POST_CREATE_CHEQUE_CLEARANCE_STATEMENT,
} from "../../../../utils/apis/routes";

export const POST_TRANSACTION_VALIDATE_ACTION = createAsyncThunk(
  "POST_TRANSACTION_VALIDATE",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_TRANSACTION_VALIDATE,
      body: payload,
      originType: "identity",
    });
  }
);
export const GET_TRANSACTION_STATUS_ACTION = createAsyncThunk(
  "GET_TRANSACTION_STATUS",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "get",
      route: GET_TRANSACTION_STATUS(payload),
      originType: "transferservice",
    });
  }
);
export const POST_VALIDATE_PIN_ACTION = createAsyncThunk(
  "POST_VALIDATE_PIN",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_VALIDATE_PIN,
      body: payload,
      originType: "identity",
    });
  }
);

export const GET_ACCOUNT_NAME_ENQUIRY_ACTION = createAsyncThunk(
  "GET_ACCOUNT_NAME_ENQUIRY",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "get",
      route: GET_ACCOUNT_NAME_ENQUIRY(payload.accountNumber, payload.bankCode),
      // body: payload,
      originType: "transferservice",
    });
  }
);

export const GET_BENEFICIARY_LIST_ACTION = createAsyncThunk(
  "GET_BENEFICIARY_LIST",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "get",
      route: GET_BENEFICIARY_LIST(
        payload?.pageSize,
        payload?.pageNumber,
        payload?.searchValue
      ),
      // body: payload,
      originType: "transferservice",
    });
  }
);

export const GET_GROUP_LIST_ACTION = createAsyncThunk(
  "GET_GROUP_LIST",
  async () => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "get",
      route: GET_GROUP_LIST,
      // body: payload,
      originType: "transferservice",
    });
  }
);

export const POST_FETCH_TRANSACTION_LIST_ACTION = createAsyncThunk(
  "POST_FETCH_TRANSACTION_LIST",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_FETCH_TRANSACTION_LIST,
      body: payload,
      originType: "transferservice",
    });
  }
);

export const GET_PENDING_USERS_REQUEST_DETAILS_ACTION = createAsyncThunk(
  "GET_PENDING_USERS_REQUEST_DETAILS",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: GET_PENDING_USERS_REQUEST_DETAILS,
      body: payload,
      originType: "transferservice",
    });
  }
);

export const POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS_ACTION = createAsyncThunk(
  "POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS,
      body: payload,
      originType: "transferservice",
    });
  }
);

export const POST_FETCH_TRANSACTION_DETAIL_ACTION = createAsyncThunk(
  "POST_FETCH_TRANSACTION_DETAIL",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_FETCH_TRANSACTION_DETAIL,
      body: payload,
      originType: "transferservice",
    });
  }
);

export const POST_FETCH_BILLS_PAYMENT_DETAIL_ACTION = createAsyncThunk(
  "POST_FETCH_BILLS_PAYMENT_DETAIL",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_FETCH_BILLS_DETAIL,
      body: payload,
      originType: "transferservice",
    });
  }
);

export const POST_FETCH_COLLECTIONS_DETAIL_ACTION = createAsyncThunk(
  "POST_FETCH_COLLECTIONS_DETAIL",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_FETCH_COLLECTIONS_DETAIL,
      body: payload,
      originType: "transferservice",
    });
  }
);

export const PUT_APROVE_OR_REJECT_TRANSACTION_ACTION = createAsyncThunk(
  "PUT_APROVE_OR_REJECT_TRANSACTION",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "put",
      route: PUT_APROVE_OR_REJECT_TRANSACTION,
      body: payload,
      originType: "transferservice",
    });
  }
);
export const POST_REVIEW_USER_REQUEST_ACTION = createAsyncThunk(
  "POST_REVIEW_USER_REQUEST",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_REVIEW_USER_REQUEST,
      body: payload,
      originType: "identity",
    });
  }
);

export const POST_TRANSFER_TRANSACTION_ACTION = createAsyncThunk(
  "POST_TRANSFER_TRANSACTION",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_TRANSFER_TRANSACTION,
      body: payload,
      originType: "identity",
    });
  }
);

export const PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION = createAsyncThunk(
  "PUT_COMPLETE_TRANSFER_TRANSACTION",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "put",
      route: PUT_COMPLETE_TRANSFER_TRANSACTION,
      body: payload,
      originType: "transferservice",
    });
  }
);

export const GET_RESEND_TRANSFER_OTP_ACTION = createAsyncThunk(
  "GET_RESEND_TRANSFER_OTP",
  async (correlationId: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "get",
      route: GET_RESEND_TRANSFER_OTP(correlationId),
      originType: "transferservice",
    });
  }
);

export const POST_FETCH_ALL_BULK_TRANSFER_ACTION = createAsyncThunk(
  "POST_FETCH_ALL_BULK_TRANSFER",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_FETCH_ALL_BULK_TRANSFER,
      body: payload,
      originType: "transferservice",
    });
  }
);

export const POST_FETCH_ONE_BULK_TRANSFER_ACTION = createAsyncThunk(
  "POST_FETCH_ONE_BULK_TRANSFER",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_FETCH_ONE_BULK_TRANSFER,
      body: payload,
      originType: "transferservice",
    });
  }
);

export const POST_CREATE_ACCOUNT_STATEMENT_ACTION = createAsyncThunk(
  "POST_CREATE_ACCOUNT_STATEMENT",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_CREATE_ACCOUNT_STATEMENT,
      body: payload,
      originType: "identity",
    });
  }
);

export const POST_CREATE_CHEQUE_CLEARANCE_STATEMENT_ACTION = createAsyncThunk(
  "POST_CREATE_CHEQUE_CLEARANCE_STATEMENT",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_CREATE_CHEQUE_CLEARANCE_STATEMENT,
      body: payload,
      originType: "identity",
    });
  }
);

export const GET_BILL_CATEGORIES_LIST_ACTION = createAsyncThunk(
  "GET_BILL_CATEGORIES_LIST",
  async () => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "get",
      route: GET_BILL_CATEGORIES_LIST,
      originType: "paymentservice",
    });
  }
);

export const POST_VALIDATE_BILL_REFERENCE_ACTION = createAsyncThunk(
  "POST_VALIDATE_BILL_REFERENCE",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_VALIDATE_BILL_REFERENCE,
      body: payload,
      originType: "paymentservice",
    });
  }
);

export const POST_CREATE_BILL_ACTION = createAsyncThunk(
  "POST_CREATE_BILL",
  async (payload: any) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_CREATE_BILL,
      body: payload,
      originType: "identity",
    });
  }
);
