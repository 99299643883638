import * as Yup from "yup";
const containsNumbersRegex = /^\d+$/;

export const collectionPaymentValidationSchema = (authType: string) =>
  Yup.object({
    amount: Yup.string().trim().required("Please enter amount"),
    account: Yup.object().required("Please select an account"),
    softToken:
      authType === "softToken"
        ? Yup.string()
            .trim("Your soft token cannot contain spaces")
            .required("Please enter your current soft token")
            .matches(containsNumbersRegex, {
              message: "Your soft token should only contain numbers",
              excludeEmptyString: true,
            })
            .test(
              "token",
              "Your soft token cannot be less than 6 digits",
              (value) => value?.length >= 6
            )
        : Yup.string().notRequired(),

    hardTokenOtp:
      authType === "hardTokenPin"
        ? Yup.string()
            .trim("Hard token otp cannot contain spaces")
            .required("Please enter your current hard token otp")
            .matches(containsNumbersRegex, {
              message: "Your hard token otp should only contain numbers",
              excludeEmptyString: true,
            })
            .test(
              "hardTokenOtp",
              "Your hard token otp cannot be less than 6 digits",
              (value) => value?.length >= 6
            )
        : Yup.string().notRequired(),

    hardTokenPin:
      authType === "hardTokenPin"
        ? Yup.string()
            .trim("PIN cannot contain spaces")
            .required("Please enter your hard token PIN")
            .length(4, "PIN must be 4 digits")
            .matches(containsNumbersRegex, {
              message: "Your hard token PIN should only contain numbers",
              excludeEmptyString: true,
            })
            .test(
              "hardTokenPin",
              "Your hard Token PIN can only contain numbers",
              (value) => !Number.isNaN(Number(value))
            )
        : Yup.string().notRequired(),

    pin:
      authType === "pinOtp"
        ? Yup.string()
            .trim("PIN cannot contain spaces")
            .required("Please enter your AFB PIN")
            .length(4, "Your AFB PIN must be 4 digits")
            .matches(containsNumbersRegex, {
              message: "Your AFB PIN should only contain numbers",
              excludeEmptyString: true,
            })
            .test(
              "pin",
              "Your AFB PIN can only contain numbers",
              (value) => !Number.isNaN(Number(value))
            )
        : Yup.string().notRequired(),
  });
