import React, { useState } from "react";

// import { ReactComponent as BankIcon } from "../../../../../assets/svgs/bank-building.svg";
import ListAllSavedBeneficiaries from "../Reusables/ListAllSavedBeneficiaries";
import SavedBeneficiaryComponent2 from "./SavedBeneficiaryComponent2";
import { moneyInputFormat } from "utils/helperFunctions";

interface IAddFromSavedBeneficiaryProps {
  handleBackToFundsTransfer: any;
  handleScreenChangeNext: any;
  savedBeneficiaryList: any;
  // handleSelectedBeneficiary: any;
  accountNumber: string;
  handleAddBeneficiary: any;
}
interface IBeneficiary {
  accountName: string;
  accountNumber: string;
  amount: string;
  bankName: string;
  bankCode: string;
}
const AddFromSavedBeneficiary = React.memo(
  ({
    accountNumber,
    handleBackToFundsTransfer,
    handleScreenChangeNext,
    savedBeneficiaryList,
    handleAddBeneficiary,
  }: // handleSelectedBeneficiary,
  IAddFromSavedBeneficiaryProps) => {
    const [showScreen, setShowScreen] = useState(1);
    const [selectedBeneficiary, setSelectedBeneficiary] =
      useState<IBeneficiary>({
        accountName: "",
        accountNumber: "",
        amount: "",
        bankName: "",
        bankCode: "",
      });
    // const [showScreen, setShowScreen] = useState(1);
    // const [showScreen, setShowScreen] = useState(1);
    // const [showScreen, setShowScreen] = useState(1);
    const handleSelectedBeneficiary = (accountNumber: any) => {
      // console.log("test", accountNumber)
      const filteredList = savedBeneficiaryList.filter(
        (acc: any) => acc?.accountNumber === accountNumber
      );
      const selectedCard = filteredList.length > 0 ? filteredList[0] : "";

      // console.log(selectedCard, "Selected card");

      const newBeneficiary = {
        accountName: selectedCard?.accountName,
        accountNumber: selectedCard?.accountNumber,
        amount: "0",
        bankName: selectedCard?.bankName,
        bankCode: selectedCard?.bankCode,
      };
      setSelectedBeneficiary(newBeneficiary);
      setShowScreen(2);

      // setRecipients((oldRecipients: any) => [...oldRecipients, newRecipient]);

      // const newRecipient = {
      //   referenceId: recipients.length + 1,
      //   accountName: selectedCard.accountName,
      //   amount: selectedCard.amount,
      //   bankName: selectedBank.bankName,
      //   bankCode: selectedBank.bankName,
      //   accountNumber: accountNumber,
      // };
      // const recipientExists = recipients.find(
      //   (one) => one.accountNumber === accountNumber
      // );
      // if (!recipientExists) {
      //   setRecipients((oldRecipients) => [...oldRecipients, newRecipient]);
      // }
      // setShowScreen(3);
      // setSelectedBeneficiary(selectedCard);
      // setSelectedBank({
      //   bankCode: selectedCard.bankCode,
      //   bankName: selectedCard.bankName,
      // });
      // setaccountName(selectedCard.accountName);
      // setAccountNumber(selectedCard.accountNumber);
    };
    const handleChange = (event: { target: { name: any; value: any } }) => {
      if (event.target.name === "amount") {
        // eslint-disable-next-line no-param-reassign
        const result = moneyInputFormat(event.target.value);
        // console.log("result", result)
        if (result) {
          event.target.value = result.withMoneyFormat.split(",").join("");
          setSelectedBeneficiary({
            ...selectedBeneficiary,
            amount: result.withMoneyFormat,
          });
        }
        // setAmount(formatNumber);
      }
    };
    const handleScreenChangePrev = () => {
      // setRecipientMinMaxError("");
      // // const screenToChange = showScreen++
      // if (showScreen === 2) {
      //   //clear everything
      //   setAccountNumber("");
      //   setAccountValidated(false);
      //   setSelectedBank({
      //     bankCode: "",
      //     bankName: "",
      //   });
      // }
      // if (showScreen === 4) {
      //   //clear everything
      //   setAccountToDebit({
      //     accountId: "",
      //     currency: "",
      //     balance: 0,
      //     accountName: "",
      //     accountNo: "",
      //   });
      //   setPin("");
      //   setIsPinValidated(false);
      //   setShowPinError("no");
      // }
      setShowScreen(1);
      setSelectedBeneficiary({
        accountName: "",
        accountNumber: "",
        amount: "",
        bankName: "",
        bankCode: "",
      });
    };

    return (
      <div>
        {/* {isLoading ? <Spinner /> : null} */}

        <div className="">
          {showScreen === 1 && (
            <ListAllSavedBeneficiaries
              title="Add from beneficiary"
              accountNumber={accountNumber}
              // handleChange={handleChange}
              handleBackToFundsTransfer={handleBackToFundsTransfer}
              handleScreenChangeNext={handleScreenChangeNext}
              savedBeneficiaryList={savedBeneficiaryList}
              handleSelectedBeneficiary={handleSelectedBeneficiary}
            />
          )}
          {showScreen === 2 && (
            <SavedBeneficiaryComponent2
              // selectedBank={selectedBank}
              // accountName={accountName}
              // accountNumber={accountNumber}
              // amount={amount}
              selectedBeneficiary={selectedBeneficiary}
              setSelectedBeneficiary={setSelectedBeneficiary}
              handleChange={handleChange}
              handleScreenChangePrev={handleScreenChangePrev}
              handleAddBeneficiary={handleAddBeneficiary}
            />
          )}
        </div>
      </div>
    );
  }
);

// {
//   showScreen === "main" ? (
//     <div>Main</div>
//   ) : showScreen == "successful" ? (
//     <div>Successful</div>
//   ) : showScreen == "failed" ? (
//     <div>Failed</div>
//   ) : (
//     <div>Pending</div>
//   );
// }
export default AddFromSavedBeneficiary;
