import SuccessScreen from "components/Cards/CardActivation/SuccessScreen";
import CardSelection from "components/Cards/CardSelection";
import ChangeCardPinScreen from "components/Cards/ChangeCardPinScreen";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PageHeader from "components/reusables/Page/PageHeader";
import { useState } from "react";

const ChangeCardPin = () => {
  const [screen, setScreen] = useState(0);

  return (
    <DashboardWrapper>
      <div className="container-fluid 2xl:container mx-auto ">
        <div className="container-fluid 2xl:container mx-auto">
          <main className="max-h-screen overflow-auto">
            <div className="pr-6 py-8">
              <div className="max-w-6xl mx-auto">
                <div className="rounded-3xl mb-5">
                  <div className="ml-4 pt-4 pb-20">
                    <PageHeader title="Change Card PIN" />
                  </div>
                  <div className="w-full">
                    {screen === 0 && (
                      <CardSelection
                        setScreen={setScreen}
                        isActiveCards
                        type={"change pin"}
                      />
                    )}
                    {screen === 1 && (
                      <ChangeCardPinScreen setScreen={setScreen} />
                    )}
                    {screen === 2 && (
                      <SuccessScreen
                        pageName={"Card Change PIN"}
                        setScreen={setScreen}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default ChangeCardPin;
