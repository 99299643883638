import XLSX from "xlsx";
/* generate an array of column objects */
export const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};

export const getSheetHeaders = (sheet) => {
  const headerRegex = new RegExp("^([A-Za-z]+)1='(.*)$");

  const cells = XLSX.utils.sheet_to_formulae(sheet);
  return cells
    .filter((item) => headerRegex.test(item))
    .map((item) => item.split("='")[1]);
};

export const getSheetHeadersKey = (list) => {
  return Object.values(list).filter((key) => key);
};
