import React from "react";
import { ReactComponent as Close } from "assets/svgs/Menu/Vector.svg";
import { MenuItems } from "./MenuItems";
import { useAppDispatch } from "hooks/useReduxHook";
import { setShowMobileSidebar } from "react-wrappers/stores/redux/app/slices";

export const Sidebar = () => {
  const dispatch = useAppDispatch();
  const sidebarRef = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      dispatch(setShowMobileSidebar(false));
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <aside
      ref={sidebarRef}
      className={`fixed left-0 top-0 z-50 h-full w-[375px] bg-lib-alat-black duration-200 p-5 space-y-4`}
    >
      <div className="flex items-end justify-end w-full">
        <div
          className="cursor-pointer"
          onClick={() => dispatch(setShowMobileSidebar(false))}
        >
          <Close />
        </div>
      </div>
      <div className=" overflow-y-auto h-full flex flex-col w-full">
        <MenuItems />
      </div>
    </aside>
  );
};
