import React, { lazy } from "react";
// import { AccountSetup } from "../pages/Dashboard/PendingActions/AccountSetup";
import { UploadId } from "../pages/Onboarding/UploadId";
import TermsAndConditions from "pages/Home/TermsAndConditions";

const CreateUser = lazy(() =>
  import("../pages/Profile/CreateUser").then(({ CreateUser }) => ({
    default: CreateUser,
  }))
);

const Home = lazy(() =>
  import("../pages/Home/Home").then(({ Home }) => ({ default: Home }))
);
const ComplianceAction = lazy(() =>
  import("../pages/Home/ComplianceAction").then(({ ComplianceAction }) => ({
    default: ComplianceAction,
  }))
);
const HOME_ROUTES = {
  COMPLIANCE_ACTION: "/compliance/license/:id",
};
export const homeRoutes = [
  {
    path: "/",
    isAuthenticated: false,
    element: <Home />,
  },
  {
    path: HOME_ROUTES.COMPLIANCE_ACTION,
    isAuthenticated: false,
    element: <ComplianceAction />,
  },
  // {
  //     path: "/account-setup",
  //     isAuthenticated: true,
  //     element: <AccountSetup />,
  // },
  {
    path: "/upload-id",
    isAuthenticated: true,
    element: <UploadId />,
  },
  {
    path: "/create-user",
    isAuthenticated: false,
    element: <CreateUser />,
  },

  {
    path: "/terms-and-conditions",
    isAuthenticated: false,
    element: <TermsAndConditions />,
  },
];
