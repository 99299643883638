import { Component } from "react";
import XLSX from "xlsx";
import { make_cols, getSheetHeadersKey } from "./MakeColumns";
import { SheetJSFT } from "./types";

class ExcelReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      data: [],
      cols: [],
    };
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // handleChange(e) {
  //   const files = e.target.files;
  //   if (files && files[0]) this.setState({ file: files[0] });
  // };

  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) {
      this.handleFile(files[0]);
    }
  }

  handleFile(file) {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      // const data = XLSX.utils.sheet_to_json(ws);
      const data = XLSX.utils.sheet_to_json(ws, {
        header: [...this.props.excelHeaders],
      });

      /* Update state */
      if (data) {
        // console.log(getSheetHeaders(ws))
        // return to the outer component
        this.props.onHandleResult({
          file,
          data: data.slice(1, data.length),
          col: make_cols(ws["!ref"]),
          headersCol: getSheetHeadersKey(data[0]),
        });
        const input = document.getElementById(this.props.fileName);
        input.value = "";
      }
      // this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
      //   console.log(JSON.stringify(this.state.data, null, 2));
      // });
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  }

  render() {
    return (
      <>
        <label htmlFor={this.props.fileName}>{this.props.children}</label>
        <input
          style={{ display: "none" }}
          type="file"
          className="form-control"
          id={this.props.fileName}
          accept={SheetJSFT}
          onChange={this.handleChange}
        />
      </>
    );
  }
}

export default ExcelReader;
