import { TextInput } from "alat-components-library";
import { Field, useField } from "formik";
import { useState, useRef, useEffect, useMemo } from "react";
import { HiChevronDown } from "react-icons/hi";
import ErrorIcon from "assets/icons/error-icon.svg";

export interface Option {
  id: number;
  name: any;
  value?: any;
  extras?: any;
}

interface DropdownProps {
  id?: string;
  name: string;
  options: Option[] | [];
  placeholder?: string;
  label?: string;
  onChange: (name: string, value: string) => void;
  value?: any;
  onSelectOption: (option: any) => void;
  disabled?: boolean;
  error?: any;
  handleBlur?: any;
  variant?: string;
  selectedLabel?: boolean;
  showSearchInput?: boolean;
  reset?: boolean;
  showOptionName?: boolean;
  switchToOptionValue?: boolean;
}

export const StateDropdown = ({
  variant = "w-full h-12 text-lg",
  value,
  name,
  handleBlur,
  options,
  id = "",
  placeholder = "Select an option",
  selectedLabel = true,
  label,
  onChange,
  onSelectOption,
  disabled,
  error,
  showSearchInput = false,
  reset = false,
  showOptionName = true,
  switchToOptionValue = false,
}: DropdownProps) => {
  const [word, setWord] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    Array.isArray(options)
      ? options.find((option) => option?.name === value) || null
      : null
  );

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [field, meta, helpers] = useField(name);

  const handleOptionSelect = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (switchToOptionValue) {
      helpers.setValue(option.value);
      onChange(name, option.value);
    } else {
      helpers.setValue(option.name);
      onChange(name, option.name);
    }
    onSelectOption(option);
  };

  const handleDropdownClick = () => {
    disabled ? setIsOpen(false) : setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const filteredOptions = useMemo(() => {
    if (word === "") return options;
    return options?.filter((x) =>
      x?.name?.toLowerCase()?.includes(word?.toLowerCase())
    );
  }, [options, word]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (meta.value === "") {
      setSelectedOption(null);
    }
  }, [meta.value]);

  // console.log("test", meta);

  return (
    <Field onBlur={handleBlur} name={name}>
      {({ field }: { field: any }) => (
        <div ref={dropdownRef} className="relative">
          <p className="text-sm mb-2.5 text-[#333333]">{label}</p>
          <div className="relative">
            <button
              type="button"
              className={`${variant} flex items-center overflow-hidden px-4 pr-6 py-2 text-left rounded-[5px] border bg-lib-alat-gray-input  focus:bg-white focus:outline-none text-[#333333] relative
              ${error ? "border-red-500" : "border-gray-300"}`}
              onClick={handleDropdownClick}
              id={id}
              disabled={disabled}
            >
              {showOptionName ? (
                <p className="truncate text-sm">
                  {selectedOption?.name || placeholder}
                </p>
              ) : (
                <p className="truncate text-sm">
                  {meta?.value === "" ? placeholder : meta.value}
                </p>
              )}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <HiChevronDown className="h-5 w-5" />
              </span>
            </button>
            {/* {error && <p className="text-xs text-lib-alat-dark-red">{error}</p>} */}
            {isOpen && (
              <ul className="absolute z-10 w-full py-1 mt-1 bg-gray-50 focus:bg-white rounded-lg shadow-lg max-h-[150px] overflow-auto">
                {showSearchInput && (
                  <div className="w-full sticky px-4 py-2">
                    <TextInput
                      id="select-search-input"
                      name="word"
                      type="text"
                      label=""
                      variant="border text-black w-full h-8 text-lg px-5"
                      placeHolder="Type to search"
                      handleChange={(e) => {
                        setWord(e?.target?.value);
                      }}
                      value={word}
                    />
                  </div>
                )}
                {(filteredOptions ? filteredOptions : []).map(
                  (option, index) => (
                    <li
                      key={index}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm"
                      onClick={() => handleOptionSelect(option)}
                    >
                      {option.name}
                    </li>
                  )
                )}
              </ul>
            )}
            {error ? (
              <div className="flex gap-2 mt-1">
                <img src={ErrorIcon} alt="Error" />
                <p className="text-red-500 font-[Inter-Regular] text-xs h-auto">
                  {error ?? meta.error}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </Field>
  );
};
