import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TransactionState } from "../interfaces";
import {
  FILTER_TRANSACTIONS_ACTION,
  GET_TRANSACTION_HISTORY_ACTION,
} from "./actions";
import { triggerSaveTransactions } from "./assit-funtions";
import { useAppSelector } from "hooks/useReduxHook";
import { RootState } from "../store";
import { TransactionHistoryPayload } from "types/global";
const dataObject = {
  loading: [],
  successMessage: "",
  errorMessage: "",
  pagination: {
    page: 1,
    pageSize: "10",
    from: "",
    to: "",
    accountNumber: "",
    transactionOperation: 1,
    currencyCode: "",
  },
  transactionsResponse: {
    allFilteredTransactions: [],
    filteredTransactions: [],
    hasNextPage: false,
    pageNumber: 0,
    totalItemCount: 0,
    count: 0,
  },
} as TransactionState;

export const initialState: TransactionState = { ...dataObject };

export const TransactionSlice = createSlice({
  name: "transactions",
  initialState,

  reducers: {
    resetTransactionsData: (): TransactionState => initialState,

    savePaginationData: (
      state: TransactionState,
      action: PayloadAction<TransactionHistoryPayload>
    ) => {
      state.pagination = action.payload;
    },

    goToNextPage: (state: TransactionState) => {
      state.pagination.page += 1;
    },

    goToPreviousPage: (state: TransactionState) => {
      state.pagination.page = state.pagination.page - 1;
    },

    goToPage: (state: TransactionState, action: PayloadAction<number>) => {
      state.pagination.page = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GET_TRANSACTION_HISTORY_ACTION.pending, (state) => {
        state.loading = ["GET_TRANSACTION_HISTORY"];
      })
      .addCase(FILTER_TRANSACTIONS_ACTION.pending, (state) => {
        state.loading = ["FILTER_TRANSACTIONS"];
      })
      .addCase(FILTER_TRANSACTIONS_ACTION.fulfilled, (state, action): void => {
        // console.log("Filtered transactions:", action.payload);
        triggerSaveTransactions(state, action);
      })
      .addCase(
        GET_TRANSACTION_HISTORY_ACTION.fulfilled,
        (state, action): void => {
          triggerSaveTransactions(state, action);
        }
      );
  },
});

// Selectors
const selectTransactions = (state: RootState) => state.transactions;

// Reducers and Actions
export const {
  resetTransactionsData,
  goToNextPage,
  goToPage,
  goToPreviousPage,
  savePaginationData,
} = TransactionSlice.actions;

// App Redux State
export const useTransactionState = () => useAppSelector(selectTransactions);

export default TransactionSlice.reducer;
