import React from "react";
import { DefaultImage } from "..";
import { LogoProps } from "../types";
import AlatWebTransparent from "../../assets/images/logos/logo192.png";
import AfbTransparentBg from "../../assets/images/logos/afb-logo-tranparent.svg";
import AlatForBusinessBlackBg from "../../assets/images/logos/afb-logo-blackbg.svg";
import AlatForBusinessPinkBg from "../../assets/images/logos/afb-logo-pinkbg.svg";
import AFBLogoWhiteBg from "../../assets/images/logos/AFBLogo.svg";

export const Logo = ({
  type = "aw-transparent",
  containerVariant = "h-[100%] px-4",
  variant = "w-14 h-14",
}: LogoProps) => {
  return (
    <div className={`w-full`}>
      {type === "aw-transparent" && (
        <div className="">
          <DefaultImage
            src={AlatWebTransparent}
            variant={variant}
            containerVariant={containerVariant}
            alt={"Alat Web Logo"}
            fallbackImageSrc={""}
          />
        </div>
      )}
      {type === "afb-transparent" && (
        <div className="">
          <DefaultImage
            src={AfbTransparentBg}
            variant={variant}
            containerVariant={containerVariant}
            alt={"Alat Business Logo"}
            fallbackImageSrc={""}
          />
        </div>
      )}
      {type === "afb-blackbg" && (
        <div className="">
          <DefaultImage
            src={AlatForBusinessBlackBg}
            variant={variant}
            containerVariant={containerVariant}
            alt={"Alat Business Logo"}
            fallbackImageSrc={""}
          />
        </div>
      )}
      {type === "afb-pinkbg" && (
        <div className="">
          <DefaultImage
            src={AlatForBusinessPinkBg}
            variant={variant}
            containerVariant={containerVariant}
            alt={"Alat Business Logo"}
            fallbackImageSrc={""}
          />
        </div>
      )}
      {type === "afb-whitebg" && (
        <div className="">
          <DefaultImage
            src={AFBLogoWhiteBg}
            variant={variant}
            containerVariant={containerVariant}
            alt={"Alat Business Logo"}
            fallbackImageSrc={""}
          />
        </div>
      )}
    </div>
  );
};

export default DefaultImage;
