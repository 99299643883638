import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card } from "flowbite-react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as LeftRightArrowIcon } from "../../../assets/svgs/arrows-left-right.svg";
import { ReactComponent as UserPlusIcon } from "../../../assets/svgs/user-plus.svg";
import { ReactComponent as FourUserIcon } from "../../../assets/svgs/four-user-icon.svg";
import { useAppDispatch } from "hooks/useReduxHook";
import { useEffect } from "react";
import { resetPaymentsData } from "react-wrappers/stores/redux/payments/slices";
import { ReusableCard } from "components/reusables/Page/ReusableCard";

export interface PopUpProps {
  onGenerateReceipt: any;
}

const MultipleTransferMain = ({ tabsRef }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const handleGotoDashboard = () => {
  //   navigate("/user-dashboard")
  // };
  // const [screen, setScreeen] = useState(0);
  const handleNewTransferChange = (event: any) => {
    navigate("/payments/multiple-transfer#0");
  };
  const handleSavedBeneficiaryChange = (event: any) => {
    navigate("/payments/multiple-transfer#1");
  };
  const handleSavedGroupChange = (event: any) => {
    navigate("/payments/multiple-transfer#2");
  };
  const handleBackToNewTransfer = () => {
    tabsRef.current?.setActiveTab(0);
  };

  useEffect(() => {
    dispatch(resetPaymentsData());
  }, []);
  return (
    <div className="w-full flex items-center justify-center">
      <ReusableCard
        variant="md:w-[768px] w-full"
        id="multiple-transfer_card"
        headingId="multiple-transfer_text"
        cardTitle=" Multiple Transfers"
        handleBackNavigation={handleBackToNewTransfer}
        showGoBackIcon
      >
        <div className="flex w-full items-center justify-center">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4 text-alat-grey py-10 mx-10">
            <Card
              id="add-new-recipient_card"
              className="cursor-pointer hover:bg-primary hover:text-white hover:fill-white  dark:bg-white dark:border-[#E4DFDF] border-[#E4DFDF] dark:shadow-white"
              onClick={handleNewTransferChange}
            >
              <LeftRightArrowIcon fill="inherit" />

              <h1 className="text-[14px]">New Recipient</h1>
              <h2 className="text-[12px]">Add a new recipient to a group</h2>
            </Card>
            <Card
              id="add-saved-beneficiary_card"
              className="cursor-pointer hover:bg-primary hover:text-white hover:fill-white dark:bg-white dark:border-[#E4DFDF] border-[#E4DFDF] dark:shadow-white"
              onClick={handleSavedBeneficiaryChange}
            >
              <UserPlusIcon fill="inherit" />

              <h1 className="text-[14px]">Add from beneficiary</h1>
              <h2 className="text-[12px] text-left">
                Select a new recipient from your saved beneficiaries
              </h2>
            </Card>
            <Card
              id="add-saved-group_card"
              className="cursor-pointer hover:bg-primary hover:text-white hover:fill-white  dark:bg-white dark:border-[#E4DFDF] border-[#E4DFDF] dark:shadow-white"
              onClick={handleSavedGroupChange}
            >
              <FourUserIcon fill="inherit" />

              <h1 className="text-[14px]">Saved group</h1>
              <h2 className="text-[12px]">Send to your saved groups</h2>
            </Card>
          </div>
        </div>
      </ReusableCard>
      {/* <Card
        className="md:w-[768px] w-full mx-auto  bg-white pb-14  dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white"
        id="multiple-transfer_card"
      >
        <div>
          <div className="flex items-center justify-between">
            <div>
              <ChevronLeftIcon
                id="back_btn"
                className="h-6 w-6 text-[#333333] cursor-pointer"
                onClick={handleBackToNewTransfer}
              />
            </div>
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="multiple-transfer_text"
            >
              Multiple Transfers
            </div>
            <div></div>
          </div>
        </div>
        <hr />
        <div className="grid md:grid-cols-3 grid-cols-1 gap-4 text-alat-grey pt-10 mx-10">
          <Card
            id="add-new-recipient_card"
            className="cursor-pointer hover:bg-primary hover:text-white hover:fill-white  dark:bg-white dark:border-[#E4DFDF] border-[#E4DFDF] dark:shadow-white"
            onClick={handleNewTransferChange}
          >
            <LeftRightArrowIcon fill="inherit" />

            <h1 className="text-[14px]">New Recipient</h1>
            <h2 className="text-[12px]">Add a new recipient to a group</h2>
          </Card>
          <Card
            id="add-saved-beneficiary_card"
            className="cursor-pointer hover:bg-primary hover:text-white hover:fill-white dark:bg-white dark:border-[#E4DFDF] border-[#E4DFDF] dark:shadow-white"
            onClick={handleSavedBeneficiaryChange}
          >
            <UserPlusIcon fill="inherit" />

            <h1 className="text-[14px]">Add from beneficiary</h1>
            <h2 className="text-[12px] text-left">
              Select a new recipient from your saved beneficiaries
            </h2>
          </Card>
          <Card
            id="add-saved-group_card"
            className="cursor-pointer hover:bg-primary hover:text-white hover:fill-white  dark:bg-white dark:border-[#E4DFDF] border-[#E4DFDF] dark:shadow-white"
            onClick={handleSavedGroupChange}
          >
            <FourUserIcon fill="inherit" />

            <h1 className="text-[14px]">Saved group</h1>
            <h2 className="text-[12px]">Send to your saved groups</h2>
          </Card>
        </div>
      </Card> */}
    </div>
  );
};

export default MultipleTransferMain;
