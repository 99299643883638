import { Button, Card } from "flowbite-react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../../assets/svgs/successScreen.svg";
import { PrimaryButton } from "alat-components-library";
import { useAppDispatch } from "hooks/useReduxHook";
import { resetPaymentsData } from "react-wrappers/stores/redux/payments/slices";

export const SuccessfulCollection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <div className="w-full">
      <Card className="bg-white md:w-[48rem] w-[1/3] mx-auto dark:bg-white dark:border-transparent border-transparent">
        <h6 className="text-center font-bold text-[20px]">Authentication</h6>
        <hr />
        <div className="flex flex-col items-center space-y-8">
          <div className="w-full mb-2">
            <div className="flex justify-center mb-2">
              <SuccessIcon fill="inherit" />
            </div>
            <h5 className="text-center font-bold my-4 text-[22px] leading-[37.2px]">
              Transaction Request Sent
            </h5>
            <p className="text-center text-[18px] leading-[30.6px]">
              Your transaction request has been sent for approval
            </p>
            <div className="flex items-center justify-center mt-6 space-x-2">
              <div className="bg-[#F5F5F5] p-5 w-full md:w-2/3  rounded-md text-center mb-2">
                <p className="text-sm leading-[16.94px]">
                  We will send you a notification as soon as the request has
                  been approved
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center mt-6 space-x-2">
              <PrimaryButton
                id="generate-receipt_btn"
                labelText={"Return to dashboard"}
                variant={
                  "font-medium text-xs text-white tracking-tighter leading-[28px]"
                }
                containerVariant=" md:w-1/2 w-full px-10 h-10 md:h-12 rounded-md flex justify-center border border-lib-alat-red cursor-pointer bg-lib-alat-red "
                handleClick={() => {
                  navigate("/user-dashboard");
                }}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
