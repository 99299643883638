import { removeLoadingState } from "../../../../helpers/assitFunctions";
import { ToastNotify } from "../../../../helpers/toastNotify";
import { AccountState } from "../interfaces";
import { redirectTo } from "../../../../utils/helperFunctions";

export const triggerGetAllAccounts = (state: AccountState, action: any) => {
  state.loading = removeLoadingState(state.loading, "GET_ALL_ACCOUNTS");
  // console.log(action.payload.data.payload)
  //Prevent no success status
  // if (action.payload.status !== 200) return;

  if (action.payload.data.hasError) {
    ToastNotify({
      type: "error",
      message:
        action?.payload?.data?.responseMessage?.title ||
        "Oops! something wrong just, please try login and try again",
      position: "top-right",
    });
  }

  state.allAccounts = action?.payload?.data?.data?.accounts;
};

export const triggerGetAccountSettings = (state: AccountState, action: any) => {
  state.loading = removeLoadingState(state.loading, "POST_ACCOUNT_SETTINGS");
  state.accountSettings = {
    ...state.accountSettings,
    successStatus: false,
  };

  //Prevent no success status
  // if (action?.payload?.status !== 200) return;

  if (action?.payload?.data?.httpStatusCode === 401) {
    return redirectTo("/logout");
  }

  if (action?.payload?.data?.hasError) {
    state.updateAccountSettings = {
      ...state.updateAccountSettings,
      successStatus: false,
    };
    return ToastNotify({
      type: "error",
      message: action?.payload?.data?.responseMessage?.title,
      position: "top-right",
    });
  } else {
    state.accountSettings = {
      ...state.accountSettings,
      successStatus: true,
      successMessage: action?.payload?.data?.data,
    };
    // state.accountSettings.successStatus = true;
    // state.accountSettings.successMessage = action?.payload?.data?.data;
  }
};
export const triggerUpdateAccountSettings = (
  state: AccountState,
  action: any
) => {
  state.loading = removeLoadingState(
    state.loading,
    "PUT_UPDATE_ACCOUNT_SETTINGS"
  );
  state.updateAccountSettings = {
    ...state.updateAccountSettings,
    successStatus: false,
  };

  // console.log("update-payload", action.payload)
  //Prevent no success status
  // if (action?.payload?.status !== 200) return;

  if (action?.payload?.data?.data?.httpStatusCode === 401)
    return redirectTo("/logout");

  if (action?.payload?.status === 500)
    return ToastNotify({
      type: "error",
      message: "Oops! Something went wrong",
      position: "top-right",
    });

  if (action?.payload?.data?.data?.hasError) {
    state.updateAccountSettings = {
      ...state.updateAccountSettings,
      successStatus: false,
    };
    return ToastNotify({
      type: "error",
      message: `${action?.payload?.data?.data?.responseMessage?.title}. ${action?.payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  } else {
    state.updateAccountSettings = {
      ...state.updateAccountSettings,
      successStatus: true,
      successMessage: action?.payload?.data?.data,
    };
    // state.updateAccountSettings.successStatus = true;
    // state.updateAccountSettings.successMessage = action?.payload?.data?.data;
  }
};
