import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import { ToastNotify } from "helpers/toastNotify";
import { RESUBMIT_REJECTED_DOCUMENTS } from "utils/apis/routes/pendingActions";

type ResubmitPayload = {
  pendingAction: number;
  actionData: string;
  cacRegNumber: string;
  pendingActionId: number;
};
export const useResubmitRejectedDocuments = () => {
  //Local States
  const [fetchSuccess, setFetchSuccess] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string>("");
  const [resubmittedResponse, setResubmittedResponse] = useState<string | null>(
    null
  );

  //API Request
  const makeRequest = useApiRequest();

  const handleResubmittedDocuments = async ({
    pendingAction,
    actionData,
    cacRegNumber,
    pendingActionId,
  }: ResubmitPayload) => {
    const payload = {
      pendingAction,
      actionData,
      cacRegNumber,
      pendingActionId,
    };
    setFetchLoading(true);
    try {
      const res = await makeRequest({
        method: "post",
        route: RESUBMIT_REJECTED_DOCUMENTS,
        originType: "solependingactions",
        body: payload,
      });

      // console.log("res",res);
      //Map the payload
      if (res.status === 200) {
        setFetchSuccess(true);
        setResubmittedResponse(res?.data);
      }
      if (res?.data?.data?.hasError) {
        setFetchSuccess(false);
        ToastNotify({
          type: "error",
          message: `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
          position: "top-center",
        });
      }
      if (res.status >= 400) {
        // console.log(res, "error")
        ToastNotify({
          type: "error",
          message: `${res?.data?.statusText ?? "An error occured. Please try again"}`,
          position: "top-center",
        });
      }
      setFetchLoading(false);
    } catch (error) {
      setFetchError("An error occured while fetching rejected documents");
      setFetchLoading(false);
    }
  };

  return {
    fetchSuccess,
    fetchLoading,
    fetchError,
    resubmittedResponse,
    handleResubmittedDocuments,
  };
};
