import React, { ReactNode } from "react";
import "./checkbox.css";

export interface Props {
  id?: any;
  children?: ReactNode;
  handleChange?: any;
  checked?: boolean;
  clickEvent?: () => void;
}

export const Checkbox: React.FC<Props> = ({
  id,
  checked,
  children,
  handleChange,
}) => {
  return (
    <label className="container">
      {children}
      <input
        id={id}
        type="checkbox"
        onChange={(e) => handleChange(e)}
        checked={checked}
      />
      <span className="checkmark"></span>
    </label>
  );
};
