import { useApiRequest } from "../../../useApiRequest";
import { ToastNotify } from "../../../../helpers/toastNotify";
import { useAppDispatch } from "hooks/useReduxHook";
import React, { useState } from "react";
import {
  setLoadingState,
  setPurchaseBillStatus,
} from "react-wrappers/stores/redux/payments/slices";
import { AirtimeOtpPayload } from "types/airtimerequest";
import { POST_OTP_AIRTIME } from "utils/apis/routes";
import { useNavigate } from "react-router-dom";

export const useCompleteBillPaymentWithOtp = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState<boolean>(false);
  //API Reques
  const makeRequest = useApiRequest();
  const handleBillPayment = async (
    payload: AirtimeOtpPayload,
    nextRoute: string
  ) => {
    try {
      dispatch(setLoadingState(["completing-bills-purchase"]));
      const res = await makeRequest({
        method: "PUT",
        route: POST_OTP_AIRTIME,
        body: payload,
        originType: "paymentservice",
      });
      if (res?.data?.httpStatusCode === 200) {
        setStatus(true);
        dispatch(setPurchaseBillStatus(res?.data?.data?.responseMessage));
        navigate(nextRoute);
      } else if (res?.data?.data?.httpStatusCode === 500) {
        setStatus(false);
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
        });
      } else {
        setStatus(false);
        ToastNotify({
          type: "error",
          message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
        });
        let codeList = [21, 22];
        if (!codeList.includes(res?.data?.data?.responseCode)) {
          navigate("/user-dashboard");
        }
      }
      dispatch(setLoadingState([]));
    } catch (error: any) {
      setStatus(false);
      dispatch(setLoadingState([]));
      ToastNotify({
        type: "error",
        message: error,
      });
    }
  };
  return {
    handleBillPayment,
    status,
  };
};
