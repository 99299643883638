import { useState } from "react";
import {
  AnonymousCredential,
  BlobServiceClient,
  BlockBlobClient,
} from "@azure/storage-blob";

const accountName = process.env.REACT_APP_AZURE_BLOB_STORAGE_NAME || "";
const sasString = process.env.REACT_APP_AZURE_SAS_TOKEN || "";

interface BlobFiles {
  name: string;
  url: string;
  fieldName: string;
}

interface UseUploadBlobToAzure {
  blobUploadError: string;
  blobFiles: BlobFiles;
  handleUploadBlob: (
    file: File,
    DocumentType: string,
    CacRegNumber: string,
    Extension: string,
    DocumentName: string
  ) => Promise<void>;
  isUploadingBlob: boolean;
  handleUploadBase64ToBlob: (
    base64String: string,
    DocumentType: string,
    CacRegNumber: string,
    Extension: string,
    DocumentName: string
  ) => Promise<void>;
}

export const useUploadBlobToAzure = (
  fieldName: string,
  container: string // backoffice, afb etc
): UseUploadBlobToAzure => {
  const containerName = (container: string): string => {
    switch (container) {
      case "backoffice":
        return `${process.env.REACT_APP_AZURE_BLOB_STORAGE_CONTAINER}`;
      default:
        return `${process.env.REACT_APP_AZURE_BLOB_STORAGE_CONTAINER_AFB}`;
    }
  };
  const blobServiceClient = new BlobServiceClient(
    `https://${accountName}.blob.core.windows.net/?${sasString}`,
    new AnonymousCredential()
  );

  const containerClient = blobServiceClient.getContainerClient(
    containerName(container)
  );

  const [isUploadingBlob, setIsUploadingBlob] = useState(false);
  const [blobUploadError, setBlobUploadError] = useState("");
  const [blobFiles, setBlobFiles] = useState<BlobFiles>({
    name: "",
    url: "",
    fieldName: "",
  });

  const handleUploadBlob = async (
    file: File,
    DocumentType: string,
    CacRegNumber: string,
    Extension: string,
    DocumentName: string
  ): Promise<void> => {
    setBlobUploadError("");
    // Modify file name before upload
    let newFileName = `${DocumentType}-${CacRegNumber || Math.random().toString(36)}-${file.name}`;

    try {
      setIsUploadingBlob(true);
      const promises: Promise<any>[] = [];
      const blockBlobClient: BlockBlobClient =
        containerClient.getBlockBlobClient(newFileName);
      const initialMeta = {
        DocumentType: "",
        DocumentName: "",
        Extension: "",
      };
      const cacRegNumber = CacRegNumber
        ? {
            CacRegNumber: `${CacRegNumber.replace("BNBN", "BN")}`,
          }
        : null;
      const metadata = Object.assign(initialMeta, {
        DocumentType: DocumentType,
        DocumentName: DocumentName,
        Extension: Extension,
        ...cacRegNumber,
      });
      // console.log("Metadata: ", metadata);
      const uploadPromise = blockBlobClient.uploadBrowserData(file);
      uploadPromise.then(() => {
        blockBlobClient.setMetadata(metadata);
      });

      await uploadPromise;
      // console.log("Blob with metadata: ", blockBlobClient.url);
      setBlobFiles({
        name: file.name,
        url: blockBlobClient.url?.split("?")[0],
        fieldName: fieldName,
      });
      setIsUploadingBlob(false);
    } catch (error: any) {
      setIsUploadingBlob(false);
      setBlobUploadError(error?.message);
    }
  };

  const handleUploadBase64ToBlob = async (
    base64String: string,
    DocumentType: string,
    CacRegNumber: string,
    Extension: string,
    DocumentName: string
  ): Promise<void> => {
    setBlobUploadError("");
    // Convert base64 string to File object
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    const file = new File(
      [arrayBuffer],
      `${DocumentType}-${CacRegNumber || Math.random().toString(36)}-${DocumentName}`,
      {
        type: mimeString,
      }
    );

    try {
      setIsUploadingBlob(true);
      const promises: Promise<any>[] = [];
      const blockBlobClient: BlockBlobClient =
        containerClient.getBlockBlobClient(file.name);
      const metadata = {
        DocumentType: DocumentType || "",
        CacRegNumber: `${CacRegNumber.replace("BNBN", "BN")}`,
        DocumentName: DocumentName || "",
        Extension: Extension || "",
      };
      // console.log("Metadata: ", metadata);
      const uploadPromise = blockBlobClient.uploadBrowserData(file);
      uploadPromise.then(() => {
        blockBlobClient.setMetadata(metadata);
      });

      await uploadPromise;
      // console.log("Blob with metadata b64: ", blockBlobClient.url);
      setBlobFiles({
        name: file.name,
        url: blockBlobClient.url?.split("?")[0],
        fieldName: fieldName,
      });
      setIsUploadingBlob(false);
    } catch (error: any) {
      setIsUploadingBlob(false);
      setBlobUploadError(error?.message);
    }
  };

  return {
    blobUploadError,
    blobFiles,
    handleUploadBlob,
    handleUploadBase64ToBlob,
    isUploadingBlob,
  };
};
