import { createAsyncThunk } from "@reduxjs/toolkit";
import { useApiRequest } from "hooks/useApiRequest";
import { TransactionHistoryPayload } from "types/global";
import { GET_TRANSACTION_HISTORY } from "utils/apis/routes";
import { goToPage, savePaginationData } from "./slices";
import { RootState } from "../store";
import { FilterTransactionsPayload } from "../interfaces";
import { ToastNotify } from "../../../../helpers/toastNotify";

export const GET_TRANSACTION_HISTORY_ACTION = createAsyncThunk(
  "GET_TRANSACTION_HISTORY",
  async (payload: TransactionHistoryPayload) => {
    try {
      const makeRequest = useApiRequest();
      const res = await makeRequest({
        method: "get",
        route: GET_TRANSACTION_HISTORY(payload),
        originType: "identity",
      });
      if (res?.data) {
        if (res?.status === 200) {
          return res;
        } else if (res?.status === 400) {
          const resMsg =
            res?.data?.data?.responseMessage?.actionMessage ||
            res?.data?.data?.responseMessage.title;

          ToastNotify({
            type: "error",
            message: resMsg,
            position: "top-right",
          });
        }
      }
    } catch (error) {
      ToastNotify({
        type: "error",
        message: "An error occured while fetching transactions",
        position: "top-right",
      });
    }
  }
);

export const fetchPage = createAsyncThunk(
  "GET_NEXT_PAGE_TRANSACTIONS",
  async (direction: "next" | "prev" | number | "", { dispatch, getState }) => {
    const state = getState() as RootState;
    const { pagination } = state.transactions;
    const nextPage = pagination.page + 1;
    const prevPage = pagination.page - 1;
    if (typeof direction === "number") {
      // console.log("I'm here", direction);

      await dispatch(goToPage(direction));
      await dispatch(
        GET_TRANSACTION_HISTORY_ACTION({
          ...pagination,
          page: direction,
        })
      );
    } else if (direction === "next") {
      dispatch(goToPage(nextPage));
      await dispatch(
        GET_TRANSACTION_HISTORY_ACTION({
          ...pagination,
          page: pagination.page + 1,
        })
      );
    } else if (direction === "prev") {
      await dispatch(goToPage(prevPage));
      await dispatch(
        GET_TRANSACTION_HISTORY_ACTION({
          ...pagination,
          page: pagination.page - 1,
        })
      );
    }
  }
);

export const FILTER_TRANSACTIONS_ACTION = createAsyncThunk(
  "GET_FILTERED_TRANSACTIONS",
  async (payload: FilterTransactionsPayload, { getState, dispatch }) => {
    const state = getState() as RootState;
    dispatch(
      savePaginationData({
        ...state.transactions.pagination,
        ...payload,
      })
    );
    const newPagination = {
      ...state.transactions.pagination,
      ...payload,
    };
    // console.log("I'm here");

    dispatch(GET_TRANSACTION_HISTORY_ACTION(newPagination));
  }
);
