// import PendingActions from "pages/PendingActions";
import PendingActionList from "pages/PendingActions/RejectedDocuments";
import CacUpload from "pages/PendingActions/RejectedDocuments/Stages/CacUpload";
import IdUpload from "pages/PendingActions/RejectedDocuments/Stages/IdUpload";
import TinUpload from "pages/PendingActions/RejectedDocuments/Stages/TinUpload";
import SignatureUpload from "pages/PendingActions/RejectedDocuments/Stages/SignatureUpload";
import BusinessAddressUpload from "pages/PendingActions/RejectedDocuments/Stages/BusinessAddressUpload";
import RejectedReferees from "pages/PendingActions/RejectedDocuments/Stages/RefereeSetup";
import UpdatedReferees from "pages/PendingActions/RejectedDocuments/Stages/RefereeSetup/UpdatedReferees";
import { RefereeForm } from "pages/PendingActions/RejectedDocuments/Stages/RefereeSetup/RefereeForm";
import { SuccessfulResubmission } from "pages/PendingActions/RejectedDocuments/components/SuccessfulResubmission";
import { Storage } from "utils/inAppstorage";
import PendingStatementDetail from "pages/Payments/AccountStatement/PendingStatementDetail";

export const routes = [
  {
    path: "/pending-actions/rejected-docs/pending-list",
    isAuthenticated: true,
    element: <PendingActionList />,
  },
  {
    path: "/pending-actions/rejected-docs/id-upload",
    isAuthenticated: true,
    element: <IdUpload />,
  },
  {
    path: "/pending-actions/rejected-docs/cac-upload",
    isAuthenticated: true,
    element: <CacUpload />,
  },
  {
    path: "/pending/account-statement/details",
    isAuthenticated: true,
    element: <PendingStatementDetail />,
  },
  {
    path: "/pending-actions/rejected-docs/signature-upload",
    isAuthenticated: true,
    element: <SignatureUpload />,
  },
  {
    path: "/pending-actions/rejected-docs/tin-upload",
    isAuthenticated: true,
    element: <TinUpload />,
  },
  {
    path: "/pending-actions/rejected-docs/businessaddress-upload",
    isAuthenticated: true,
    element: <BusinessAddressUpload />,
  },
  {
    path: "/pending-actions/rejected-docs/referee-upload",
    isAuthenticated: true,
    element: <RejectedReferees />,
  },
  {
    path: "/pending-actions/rejected-docs/add-referee",
    isAuthenticated: true,
    element: <RefereeForm />,
  },
  {
    path: "/pending-actions/rejected-docs/updated-referees",
    isAuthenticated: true,
    element: <UpdatedReferees />,
  },
  {
    path: "/pending-actions/successful-resubmission",
    isAuthenticated: true,
    element: <SuccessfulResubmission />,
  },
];
const isProtected = Storage.getItem("afb-app-token");
const unProtectedRoutes: any = [];

export const pendingActionsRoutes = isProtected ? routes : unProtectedRoutes;
