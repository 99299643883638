import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { Button, Card } from "flowbite-react";
import React from "react";
import { moneyInputFormat } from "utils/helperFunctions";

interface INewRecipientComponent2Props {
  bankList?: any;
  amount?: any;
  accountNumber?: any;
  accountValidated?: any;
  accountValidationError?: any;
  beneficiaryName?: any;
  handleChange?: any;
  handleBackToFundsTransfer?: any;
  handleAccountValidation?: any;
  handleScreenChangePrev: any;
  handleScreenChangeNext?: any;
  handleRemoveRecipient: any;
  handleEditRecipient: any;
  recipients: any;
  recipientMinMaxError: any;
  savedGroup?: boolean;
}
const NewRecipientComponent2 = ({
  bankList,
  amount,
  accountNumber,
  accountValidated,
  accountValidationError,
  beneficiaryName,
  handleChange,
  handleBackToFundsTransfer,
  handleAccountValidation,
  handleScreenChangePrev,
  handleScreenChangeNext,
  handleRemoveRecipient,
  handleEditRecipient,
  recipients,
  recipientMinMaxError,
}: INewRecipientComponent2Props) => {
  // const location = useLocation();
  // const navigate = useNavigate();

  return (
    <div className="w-full flex items-center justify-center">
      <ReusableCard
        variant="md:w-[48rem] w-full"
        cardTitle="Multiple transfer"
        handleBackNavigation={handleScreenChangePrev}
        showGoBackIcon
      >
        <div className="w-full flex items-center justify-center">
          <form className="flex w-full flex-col gap-4 justify-center items-center">
            <div className="text-[#666666]">Recipients</div>
            <div className="w-full">
              <div className="w-full">
                {recipients.length > 0 ? (
                  recipients.map((recipient: any, index: any) => (
                    <Card
                      id={`recipient-list-${index}_card`}
                      key={index}
                      className={`dark:border-white shadow-[0px] rounded-none border-white w-full ${
                        index % 2 === 0
                          ? "dark:!bg-[#F7F7F7] !bg-[#F7F7F7]"
                          : "dark:bg-white bg-white"
                      }`}
                    >
                      <div className="flex flex-row items-center justify-between w-full md:h-[100px]">
                        <div className="flex items-center gap-2">
                          <div className=" text-center text-[#333333] mb-auto mt-1">
                            {index + 1}
                          </div>
                          <div className="text-left text-[#333333]">
                            <div
                              className=""
                              id={`recipient-${index + 1}-accName_text`}
                            >
                              {recipient.accountName}
                            </div>
                            <div>
                              ₦
                              {moneyInputFormat(recipient.amount)
                                ?.withMoneyFormat || 0}
                            </div>
                            <div>{recipient.bankName} -</div>
                            <div id={`recipient-${index + 1}-accNumber_text`}>
                              {recipient.accountNumber}
                            </div>
                          </div>
                        </div>

                        <div className="flex items-center gap-2">
                          <p
                            className=" text-center text-primary cursor-pointer font-medium"
                            id={`recipient-${index + 1}-edit_btn`}
                            onClick={() => {
                              handleEditRecipient(recipient);
                            }}
                          >
                            Edit
                          </p>
                          <p
                            className=" text-center text-primary cursor-pointer font-medium mr-6"
                            id={`recipient-${index + 1}-remove_btn`}
                            onClick={() => {
                              handleRemoveRecipient(recipient.referenceId);
                            }}
                          >
                            Remove
                          </p>
                        </div>
                      </div>
                    </Card>
                  ))
                ) : (
                  <div className="text-center ml-52"> No recipients added</div>
                )}
                <div>
                  {recipientMinMaxError.length > 2 ? (
                    <div className="text-red-800">{recipientMinMaxError}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-center items-center flex-row w-full gap-10 mb-40 mt-10">
                <Button
                  id="add-another-account_btn"
                  onClick={handleScreenChangePrev}
                  className="hover:text-primary dark:hover:!text-white dark:hover:bg-primary  w-[180px] bg-primary hover:bg-white focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:boder-primary"
                >
                  Add another account
                </Button>
                <Button
                  id="proceed-to-payment_btn"
                  color="failure"
                  onClick={handleScreenChangeNext}
                  className="hover:text-primary dark:hover:!text-white dark:hover:bg-primary  w-[180px] bg-primary hover:bg-white focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:boder-primary"
                  disabled={recipients.length < 1}
                >
                  Proceed to payment
                </Button>
              </div>
            </div>
          </form>
        </div>
      </ReusableCard>
      {/* <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <div
              onClick={handleScreenChangePrev}
              className="cursor-pointer"
              id="back_btn"
            >
              <ChevronLeftIcon className="h-6 w-6 text-[#333333]" />
            </div>
            <div className="text-[#4D4D4D] text-xl text-center">
              Multiple transfer
            </div>
            <div></div>
          </div>
        </div>
        <hr />
      </Card> */}
    </div>
  );
};

// {
//   showScreen === "main" ? (
//     <div>Main</div>
//   ) : showScreen == "successful" ? (
//     <div>Successful</div>
//   ) : showScreen == "failed" ? (
//     <div>Failed</div>
//   ) : (
//     <div>Pending</div>
//   );
// }
export default NewRecipientComponent2;
