import { useState } from "react";
import {
  POST_REJECT_E_REFEREE,
  POST_REJECT_REFEREE,
} from "../../../utils/apis/routes";
// import { AcceptOtherBankRefereePayload } from "../../../types/global";
import { useApiRequest } from "hooks/useApiRequest";
import { ToastNotify } from "helpers/toastNotify";
import axiosStatus from "utils/apis/axios/errorStatus";

export const useRejectReferee = () => {
  //Local States
  const [refereeRejectionStatus, setRefereeRejectionStatus] =
    useState<boolean>(false);
  const [refereeRejectionLoading, setRefereeRejectionLoading] =
    useState<boolean>(false);
  const [refereeRejectionError, setRefereeRejectionError] =
    useState<string>("");
  const [refereeRejectionData, setRefereeRejectionData] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const handleRefereeRejection = async (payload: {
    correlationId: string;
    id: string;
    isEref: boolean;
  }) => {
    // console.log(payload)
    setRefereeRejectionLoading(true);
    setRefereeRejectionStatus(false);
    try {
      const API_URL = payload?.isEref
        ? POST_REJECT_E_REFEREE(payload.correlationId, payload.id)
        : POST_REJECT_REFEREE(payload.correlationId, payload.id);
      const REQUEST_TYPE = payload?.isEref ? "ereference" : "onboarding";
      const res = await makeRequest({
        method: "post",
        route: API_URL,
        // body: payload,
        originType: REQUEST_TYPE,
      });
      // console.log(res)
      //Map the payload
      if (res?.data?.httpStatusCode === 200) {
        setRefereeRejectionData(res?.data);
        setRefereeRejectionStatus(true);
      } else if (res?.status === 400) {
        const resMsg = res?.data?.data?.actionMessage;
        ToastNotify({
          type: "error",
          message: resMsg,
          position: "top-right",
        });
      } else {
        axiosStatus(res?.data);
      }
      setRefereeRejectionLoading(false);
    } catch (error) {
      setRefereeRejectionError(
        "An error occured while fetching branch sol ids, please referesh and try again"
      );
      setRefereeRejectionLoading(false);
    }
  };

  return {
    refereeRejectionStatus,
    refereeRejectionLoading,
    refereeRejectionError,
    refereeRejectionData,
    handleRefereeRejection,
  };
};
