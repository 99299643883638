import { useNavigate } from "react-router-dom";
import { SetStateAction, useMemo, useState } from "react";
import SearchComponent from "components/Payments/BankTransfer/Reusables/SearchComponent";
import { useAppDispatch } from "hooks/useReduxHook";
import PageLoader from "components/Loader/PageLoader";
import {
  setActiveSelectedCollectionMerchant,
  useCollectionsState,
} from "react-wrappers/stores/redux/collections/slices";
import { useCollectionsController } from "controllers/Collections";
import { useGetCollections } from "hooks/apis/Collections/useGetCollections";

const SelectedCollection = () => {
  // Local State
  const [searchTerm, setSearchTerm] = useState("");
  const { allCollectionMerchants, loading, activeSelectedCollection } =
    useCollectionsState();
  const { handleFilterMerchants } = useCollectionsController();
  const { handleFetchCollectionMerchantItems } = useGetCollections();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Assit Function
  const handleSelected = (payload: any) => {
    // Update the active bill state
    handleFetchCollectionMerchantItems(payload.collectionId);
    dispatch(setActiveSelectedCollectionMerchant(payload));
    // redirect to selected bill screen
    navigate(`/bills/collections/category-item`);
  };

  const listOfMerchants = useMemo(
    () =>
      handleFilterMerchants({
        merchants: activeSelectedCollection?.collections,
        searchWord: searchTerm,
      }),
    [activeSelectedCollection?.collections, handleFilterMerchants, searchTerm]
  );

  return (
    <div className="w-full">
      {!!loading.includes("fetch-collection-merchants") && (
        <PageLoader message="Loading collections" />
      )}
      <SearchComponent
        word={searchTerm}
        handleWordChange={(event: {
          target: { value: SetStateAction<string> };
        }) => setSearchTerm(event.target.value)}
        title="Search for collection"
        width="w-full"
        textPosition="text-left"
      />
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 py-10">
        {listOfMerchants?.length > 0 &&
          listOfMerchants?.map((item: any) => (
            <div
              key={item.merchantId}
              onClick={() => handleSelected(item)}
              className="flex md:justify-start cursor-pointer justify-center items-center bg-white min-h-24 w-full boxShadow px-10 py-6 rounded-md"
            >
              <p className="text-[#333] text-sm">{item.collectionName}</p>
            </div>
          ))}
        {listOfMerchants?.length === 0 && "No collection found"}
      </div>
    </div>
  );
};

export default SelectedCollection;
