import React from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/reusables/Page/PageHeader";
interface IHeaderComponentProps {
  categoryName: string;
}
export default function HeaderComponent({
  categoryName,
}: IHeaderComponentProps) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(-1)}
      className="ml-2 mt-6 mb-10 flex justify-left items-center"
    >
      <svg
        className="mr-3"
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path
          d="M17.525 21.9313L11.2062 15.6312C11.1312 15.5562 11.0782 15.475 11.0472 15.3875C11.0157 15.3 11 15.2063 11 15.1063C11 15.0063 11.0157 14.9125 11.0472 14.825C11.0782 14.7375 11.1312 14.6562 11.2062 14.5812L17.525 8.2625C17.7 8.0875 17.9188 8 18.1813 8C18.4438 8 18.6688 8.09375 18.8563 8.28125C19.0438 8.46875 19.1375 8.6875 19.1375 8.9375C19.1375 9.1875 19.0438 9.40625 18.8563 9.59375L13.3438 15.1063L18.8563 20.6188C19.0313 20.7938 19.1188 21.0093 19.1188 21.2653C19.1188 21.5218 19.025 21.7438 18.8375 21.9313C18.65 22.1188 18.4313 22.2125 18.1813 22.2125C17.9313 22.2125 17.7125 22.1188 17.525 21.9313Z"
          fill="#333333"
        />
      </svg>
      <PageHeader title={categoryName} />
    </div>
  );
}
