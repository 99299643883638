import DashboardWrapper from "../../components/Layouts/DashboardWrapper";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import FailedTransTab from "components/AirtimeAndData/FailedTrans";

const FailedTrans = () => {
  const navigate = useNavigate();
  return (
    <DashboardWrapper>
      <div className="container-fluid 2xl:container mx-auto ">
        <div className="container-fluid 2xl:container mx-auto">
          <main className="max-h-screen overflow-auto">
            <div className="pr-6 py-8">
              <div className="max-w-6xl mx-auto">
                <div className="rounded-3xl mb-5">
                  <div className="ml-1">
                    <div>
                      <div className="flex items-center gap-5">
                        <ChevronLeftIcon
                          onClick={() => navigate("/Data/BuyNewAirtime")}
                          id="back_btn"
                          className="h-6 w-6 text-[#333333] cursor-pointer"
                        />
                        <div>
                          <div
                            className="text-[#4D4D4D] text-xl"
                            id="banktransfer_heading"
                          >
                            Buy Data
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <FailedTransTab />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default FailedTrans;
