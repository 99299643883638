import React from "react";
import { ReactComponent as RefereeIcon } from "../../../assets/svgs/referee-home-icon.svg";
import { useNavigate } from "react-router-dom";

interface PendingItemProps {
  icon: string;
  title: string;
  subtitle: string;
  index: any;
  route: string;
  time: string;
}

const PendingItem: React.FC<PendingItemProps> = ({
  icon = "",
  title = "",
  subtitle = "",
  index = "",
  route = "",
  time = "",
}) => {
  const navigate = useNavigate();
  const handleClick = (title: string) => {
    if (title.toLowerCase().includes("referee")) {
      navigate("/referee-list");
      return;
    }
    if (title.toLowerCase().includes("upload")) {
      return;
    }
    return;
  };
  return (
    <div
      className="flex  border-b border-[#909090] w-full h-20 bg-white cursor-pointer"
      key={index}
      id={`pending-action-${index}_card`}
      onClick={() => handleClick(title)}
    >
      <div className="my-auto mr-4 w-auto">
        <RefereeIcon fill="red" />
      </div>
      <div className="my-auto w-full">
        <div className="font-semibold flex justify-between">
          <div>{title}</div>
          <div className="text-sm font-normal text-gray-400">{time}</div>
        </div>
        <div className="text-sm">{subtitle}</div>
      </div>
    </div>
  );
};

export default PendingItem;
