import CenterModal from "alat-components-library/Modal/CenterModal";
import React, { useEffect, useState } from "react";
import { ReactComponent as SuccessIcon } from "../../../assets/svgs/success-icon.svg";
import { ReactComponent as FailureIcon } from "../../../assets/svgs/failure-icon.svg";
import ReferenceLetterContainer from "../ReferenceLetter/ReferenceLetterContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useRejectDudCheque } from "hooks/apis/DudCheque/useRejectDudCheque";
import { useApproveDudCheque } from "hooks/apis/DudCheque/useApproveDudCheque";
import PageLoader from "components/Loader/PageLoader";

const ComplianceDudCheque = () => {
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);
  const navigate = useNavigate();
  const param = useParams();

  const {
    userDudRejectionStatus,
    userDudRejectionLoading,
    handleDudRejection,
  } = useRejectDudCheque();

  const { userDudApprovalStatus, userDudApprovalLoading, handleDudApproval } =
    useApproveDudCheque();

  const base64String = param.id as string;
  const decodedString = atob(base64String);
  const decodedArray = decodedString.split(":");
  const bvnName = decodedArray?.length === 3 ? decodedArray[0] : "";
  const businessName = decodedArray?.length === 3 ? decodedArray[1] : "";
  const correlationId = decodedArray?.length === 3 ? decodedArray[2] : "";

  const acceptDudRequest = () => {
    handleDudApproval(correlationId);
  };
  const rejectDudRequest = () => {
    handleDudRejection(correlationId);
  };

  useEffect(() => {
    if (userDudApprovalStatus) {
      setAccepted(true);
    }
    if (userDudRejectionStatus) {
      setRejected(true);
    }
  }, [userDudApprovalStatus, userDudRejectionStatus]);
  return (
    <ReferenceLetterContainer>
      {(userDudApprovalLoading || userDudRejectionLoading) && (
        <PageLoader message="Submitting request" />
      )}
      <div className="px-5 md:px-0 min-w-[50vw] max-w-[500px] mx-auto flex flex-col md:gap-[4vw] gap-10 my-10">
        <div className="flex flex-col gap-2">
          <h1 className="md:text-[3vw] text-xl font-bold tracking-tight">
            Dud Cheque <br />
            Records
          </h1>
        </div>

        <div className="flex flex-col md:gap-[1.8vw] gap-5">
          <div className="flex justify-between border-b items-center">
            <div className="text-left md:text-[1vw] text-xs">BVN NAME</div>
            <div
              className="text-right md:text-[1.3vw] text-sm"
              id="company-name_text"
            >
              {bvnName}
            </div>
          </div>
          <div className="flex justify-between border-b items-center">
            <div className="text-left md:text-[1vw] text-xs">BUSINESS NAME</div>
            <div
              className="text-right md:text-[1.3vw] text-sm"
              id="company-address_text"
            >
              {businessName}
            </div>
          </div>
        </div>

        <div className="w-full flex gap-2 items-center">
          <div
            className="w-full flex rounded-md bg-lib-alat-red justify-center h-12 cursor-pointer hover:bg-lib-alat-dark-red items-center text-sm font-semibold text-white"
            id="accept-wema-referee_btn"
            onClick={acceptDudRequest}
          >
            Accept
          </div>
          <div
            className="w-full rounded-md bg-white flex justify-center h-12 cursor-pointer border border-lib-alat-red items-center text-sm text-lib-alat-red font-semibold"
            id="reject-wema-referee_btn"
            onClick={rejectDudRequest}
          >
            Reject
          </div>
        </div>
      </div>
      {accepted && (
        <CenterModal
          id="success_modal"
          idButton1="success-continue_btn"
          idCloseModal="success_close_btn"
          icon={<SuccessIcon />}
          title="Success!"
          info={`You have successfully approved this customer to proceed with account opening`}
          btnText="Thank you"
          handleClick={() => navigate("/signin")}
          handleClose={() => navigate("/signin")}
        />
      )}
      {rejected && (
        <CenterModal
          id="reject_modal"
          idButton1="reject-continue_btn"
          idCloseModal="reject_close_btn"
          icon={<FailureIcon />}
          title="Rejected!"
          info={`You have successfully rejected this customer's account opening request`}
          btnText="Thank you"
          handleClick={() => navigate("/signin")}
          handleClose={() => navigate("/signin")}
        />
      )}
    </ReferenceLetterContainer>
  );
};

export default ComplianceDudCheque;
