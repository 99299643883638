import axios from "axios";
import axiosStatus from "../utils/apis/axios/errorStatus";
import { getAxiosConfig, MakeRequestType } from "../utils/config";

export const useApiRequest = () => {
  const makeRequest = async (data: MakeRequestType) => {
    let response: any = null;

    try {
      response = await axios({
        ...getAxiosConfig(data),
      });

      // console.log("testing-resp", response)

      return {
        status: response?.status,
        data: response?.data,
      };
    } catch (error: any) {
      // console.log("error", error);
      // axiosStatus(error?.response);
      return {
        status: error?.response?.status,
        data: error?.response,
      };
    }
  };

  return makeRequest;
};
