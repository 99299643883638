import React, { useState } from "react";

// import { ReactComponent as BankIcon } from "../../../../../assets/svgs/bank-building.svg";
// import { ReactComponent as BankIcon } from "../../../../assets/svgs/bank-building.svg";

// import ListAllSavedBeneficiaries from "../Reusables/ListAllSavedBeneficiaries";
import ListAllSavedGroups from "../Reusables/ListAllSavedGroups";
// import SavedBeneficiaryComponent2 from "./SavedBeneficiaryComponent2";

interface IAddFromSavedGroupProps {
  handleBackToFundsTransfer: any;
  handleScreenChangeNext: any;
  savedGroupList: any;
  // handleSelectedBeneficiary: any;
  accountNumber: string;
  handleAddGroup: any;
}
interface IBeneficiary {
  accountName: string;
  accountNumber: string;
  amount: string;
  bankName: string;
  bankCode: string;
}
const AddFromSavedGroup = React.memo(
  ({
    accountNumber,
    handleBackToFundsTransfer,
    handleScreenChangeNext,
    savedGroupList,
    handleAddGroup,
  }: // handleSelectedBeneficiary,
  IAddFromSavedGroupProps) => {
    // eslint-disable-next-line
    const [showScreen, setShowScreen] = useState(1);
    // eslint-disable-next-line
    const [selectedGroup, setSelectedGroup] = useState<IBeneficiary[]>([
      {
        accountName: "",
        accountNumber: "",
        amount: "",
        bankName: "",
        bankCode: "",
      },
    ]);
    // const [showScreen, setShowScreen] = useState(1);
    // const [showScreen, setShowScreen] = useState(1);
    // const [showScreen, setShowScreen] = useState(1);
    const handleSelectedGroup = (referenceId: any) => {
      // const selectedGroup = savedGroupList[index];
      const selectedGroup = savedGroupList.find(
        (group: any) => group.referenceId === referenceId
      );

      handleAddGroup(selectedGroup);
      // const newBeneficiary = {
      //   accountName: selectedCard.accountName,
      //   accountNumber: selectedCard.accountNumber,
      //   amount: "0",
      //   bankName: selectedCard.bankName,
      //   bankCode: selectedCard.bankCode,
      // };
      // setSelectedGroup(newBeneficiary);
      // setShowScreen(2);
      // setRecipients((oldRecipients: any) => [...oldRecipients, newRecipient]);

      // const newRecipient = {
      //   referenceId: recipients.length + 1,
      //   accountName: selectedCard.accountName,
      //   amount: selectedCard.amount,
      //   bankName: selectedBank.bankName,
      //   bankCode: selectedBank.bankName,
      //   accountNumber: accountNumber,
      // };
      // const recipientExists = recipients.find(
      //   (one) => one.accountNumber === accountNumber
      // );
      // if (!recipientExists) {
      //   setRecipients((oldRecipients) => [...oldRecipients, newRecipient]);
      // }
      // setShowScreen(3);
      // setSelectedBeneficiary(selectedCard);
      // setSelectedBank({
      //   bankCode: selectedCard.bankCode,
      //   bankName: selectedCard.bankName,
      // });
      // setaccountName(selectedCard.accountName);
      // setAccountNumber(selectedCard.accountNumber);
    };
    // const handleChange = (event: { target: { name: any; value: any } }) => {
    //   // console.log(event.target.name, event.target.value);

    //   if (event.target.name === "amount") {
    //     const formatNumber =
    //       parseFloat(event.target.value) > 0
    //         ? parseFloat(event.target.value.replace(/,/g, "")).toLocaleString()
    //         : "0";
    //     // setAmount(formatNumber);
    //     console.log(formatNumber);
    //     setSelectedGroup({
    //       ...selectedGroup,
    //       amount: formatNumber,
    //     });
    //   }
    // };
    // const handleScreenChangePrev = () => {
    //   // setRecipientMinMaxError("");
    //   // // const screenToChange = showScreen++
    //   // if (showScreen === 2) {
    //   //   //clear everything
    //   //   setAccountNumber("");
    //   //   setAccountValidated(false);
    //   //   setSelectedBank({
    //   //     bankCode: "",
    //   //     bankName: "",
    //   //   });
    //   // }
    //   // if (showScreen === 4) {
    //   //   //clear everything
    //   //   setAccountToDebit({
    //   //     accountId: "",
    //   //     currency: "",
    //   //     balance: 0,
    //   //     accountName: "",
    //   //     accountNo: "",
    //   //   });
    //   //   setPin("");
    //   //   setIsPinValidated(false);
    //   //   setShowPinError("no");
    //   // }
    //   setShowScreen(1);
    //   setSelectedBeneficiary({
    //     accountName: "",
    //     accountNumber: "",
    //     amount: "",
    //     bankName: "",
    //     bankCode: "",
    //   });
    // };

    return (
      <div>
        {/* {isLoading ? <Spinner /> : null} */}

        <div className="">
          {showScreen === 1 && (
            <ListAllSavedGroups
              title="Saved Group"
              accountNumber={accountNumber}
              // handleChange={handleChange}
              handleBackToFundsTransfer={handleBackToFundsTransfer}
              handleScreenChangeNext={handleScreenChangeNext}
              // savedBeneficiaryList={savedBeneficiaryList}
              // handleSelectedBeneficiary={handleSelectedBeneficiary}
              savedGroupList={savedGroupList}
              handleSelectedGroup={handleSelectedGroup}
            />
          )}
          {/* {showScreen === 2 && (
            <SavedBeneficiaryComponent2
              // selectedBank={selectedBank}
              // accountName={accountName}
              // accountNumber={accountNumber}
              // amount={amount}
              selectedBeneficiary={selectedBeneficiary}
              setSelectedBeneficiary={setSelectedBeneficiary}
              handleChange={handleChange}
              handleScreenChangePrev={handleScreenChangePrev}
              handleAddBeneficiary={handleAddBeneficiary}
            />
          )} */}
        </div>
      </div>
    );
  }
);

// {
//   showScreen === "main" ? (
//     <div>Main</div>
//   ) : showScreen == "successful" ? (
//     <div>Successful</div>
//   ) : showScreen == "failed" ? (
//     <div>Failed</div>
//   ) : (
//     <div>Pending</div>
//   );
// }
export default AddFromSavedGroup;
