import React from "react";
import { Table } from "flowbite-react";
import { formatNumberToCurrency } from "utils/helperFunctions";
import { ReactComponent as TransationListIcon } from "../../../../assets/svgs/transactions-list-icon.svg";
import {
  setBulkHistoryFilter,
  setOneBulkTransactionID,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { useAppDispatch } from "hooks/useReduxHook";

export const ShowAllBulkTransactionBody = ({ list }: any) => {
  const dispatch = useAppDispatch();
  const { bulkHistoryFilter } = usePaymentState();

  // console.log(list)
  return (
    <>
      {list?.length > 0 ? (
        <>
          {list?.map((item: any, index: number) => (
            <Table.Row
              onClick={() => {
                // eslint-disable-next-line
                dispatch(
                  setBulkHistoryFilter({
                    ...bulkHistoryFilter,
                    ["reference"]: item?.reference,
                  })
                );
                dispatch(setOneBulkTransactionID(item?.reference));
              }}
              key={index + item?.reference}
              className={`${index % 2 === 0 ? "bg-[#F7F7F7]" : "bg-white-500"} h-14 hover:bg-afb-gray-one dark:hover:bg-afb-gray-one cursor-pointer`}
            >
              <Table.Cell
                className="text-lib-alat-black text-[12px] flex"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                <TransationListIcon className="w-5 h-5" />
                &nbsp;&nbsp;
                {item?.narration || "No Description"}
              </Table.Cell>
              <Table.Cell
                className="text-lib-alat-black text-[12px]"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                {item?.dateCreated}
              </Table.Cell>
              <Table.Cell
                className="text-[#FF0000] text-[12px]"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                {formatNumberToCurrency({
                  currencyCode: "NGN",
                  number: item?.totalTransctionAmount,
                })}
              </Table.Cell>
            </Table.Row>
          ))}
        </>
      ) : (
        <>
          <div className="h-20  flex justify-center items-center ">
            <div className="text-center text-sm w-full absolute left-0 ">
              <div className="flex flex-col items-center gap-3">
                <p className="leading-4 text-[#C4C4C4]">
                  No transactions found
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
