import { ReactComponent as HomeIcon } from "../../assets/svgs/iconhome.svg";
import { ReactComponent as CardIcon } from "../../assets/svgs/card-icon.svg";
import { ReactComponent as Payments } from "../../assets/svgs/TransferMoney.svg";
import { ReactComponent as SettingsIcon } from "../../assets/svgs/SettingsIcon.svg";
import { ReactComponent as AccountsIcon } from "assets/svgs/accounts-nav-icon.svg";
import { ReactComponent as LoansIcon } from "assets/svgs/loans-icon.svg";
import { ReactComponent as MoreIcon } from "assets/svgs/more-icon.svg";
import { ReactComponent as TradeServicesIcon } from "../../assets/svgs/trade-services-icon.svg";

// It's not you, it's me. (If you run into color trouble while using your icon)
// Ensure you follow these step when you are importing your icon.
// 1. Ensure it's an svg file
// 2. Edit all the instances of the fill and/or stroke properties in the svg file from the color code
//    e.g "#C4C4C4" to "currentColor" that's it. Enjoy the rest of your journey :)

export const navItems = [
  {
    id: "dashboard_btn",
    icon: <HomeIcon />,
    text: "Dashboard",
    link: "/user-dashboard",
    hasDropdown: false,
  },
  {
    id: "payments_btn",
    icon: <Payments />,
    text: "Payments",
    link: "/payments/funds-transfer",
    hasDropdown: true,
  },
  // {
  //   id: "trade-services_btn",
  //   icon: <TradeServicesIcon />,
  //   text: "Trade Services",
  //   link: "/trade-services/sme-form-q/application",
  //   hasDropdown: true,
  // },
  {
    id: "cards_btn",
    icon: <CardIcon />,
    text: "Cards",
    link: "/cards",
    hasDropdown: true,
  },
  // {
  //   id: "loans_btn",
  //   icon: <LoansIcon />,
  //   text: "Loans",
  //   link: "/loans",
  //   hasDropdown: true,
  // },
  // {
  //     id: 1111,
  //     id: "", icon: <PosIcon/>,
  //     text: "POS Terminals",
  //     link: "/pos",
  //     hasDropdown: false,
  // },
  // {
  //     id: 999,
  //     id: "", icon: <BillsIcon/>,
  //     text: "Bills",
  //     link: "/bills",
  //     hasDropdown: false,
  // },
  {
    id: "settings_btn",
    icon: <SettingsIcon />,
    text: "Settings",
    link: "/profile/account-settings",
    hasDropdown: false,
  },
  {
    id: "more_btn",
    icon: <MoreIcon />,
    text: "More",
    link: "/more",
    hasDropdown: true,
  },
];

export const AdminNavItems = [
  {
    // id: 1,
    id: "dashboard_btn",
    icon: <HomeIcon />,
    text: "Dashboard",
    link: "/admin-dashboard",
    hasDropdown: false,
  },
  // {
  //     id: 2,
  //     id: "", icon: <UsersIcon/>,
  //     text: "Users",
  //     link: "/profile/users",
  //     hasDropdown: false,
  // },
  {
    id: "accounts_btn",
    icon: <AccountsIcon />,
    text: "Accounts",
    link: "/accounts/naira",
    hasDropdown: false,
  },
  // {
  //     id: 4,
  //     id: "", icon: <CardsIcon/>,
  //     text: "Cards",
  //     link: "/cards",
  //     hasDropdown: false,
  // },
  // {
  //     id: 5,
  //     id: "", icon: <PosIcon/>,
  //     text: "POS Terminals",
  //     link: "/pos",
  //     hasDropdown: false,
  // },
  // {
  //     id: 6,
  //     id: "", icon: <SettingsIcon/>,
  //     text: "Security",
  //     link: "/profile/security",
  //     hasDropdown: false,
  // },
];

export const accountsSubMenuLinks = [
  {
    id: "naira-accounts_btn",
    key: "accounts_btn",
    text: "Naira Account(s)",
    // tabNo: 0,
    route: "/accounts/naira",
  },
  {
    id: "dorm-accounts_btn",
    key: "accounts_btn",
    text: "Domiciliary Account(s)",
    // tabNo: 1,
    route: "/accounts/fx",
  },
];
export const paymentSubMenuLinks = [
  {
    id: "airtime_btn",
    key: "payments_btn",
    text: "Airtime and Data",
    route: "/Airtime/BuyAirtime",
  },
  {
    id: "bills_Payment_btn",
    key: "payments_btn",
    text: "Bills Payment",
    route: "/Bills/Payment",
  },

  {
    id: "funds-transfer_btn",
    key: "payments_btn",
    text: "Funds Transfer",
    route: "/payments/funds-transfer",
  },
  {
    id: "FxTransferTab",
    key: "payments_btn",
    text: "FX Transfer",
    route: "/payments/fx-transfer/new-transfer",
  },
];

export const cardSubMenuLinks = [
  {
    id: "card-request",
    key: "cards_btn",
    text: "Card Request",
    route: "/card/card-request",
  },
  {
    id: "card-activation",
    key: "cards_btn",
    text: "Card Activation",
    route: "/card/card-activation",
  },
  {
    id: "card-pin",
    key: "cards_btn",
    text: "Change Card Pin",
    route: "/card/card-pin",
  },
  {
    id: "card-control",
    key: "cards_btn",
    text: "Card Control",
    route: "/card/card-control",
  },
  {
    id: "hot-and-card-deactivation",
    key: "cards_btn",
    text: "Hotlist & Card Deactivation",
    route: "/card/hotlist-and-card-deactivation",
  },
];

// export const tradeServicesSubMenuLinks = [
//   {
//     id: "sme-form-q_btn",
//     text: "SME Form Q",
//     route: "/trade-services/sme-form-q/application",
//   },
//   {
//     id: "form-m_btn",
//     text: "Form M",
//     route: "/trade-services/form-m",
//   },
//   {
//     id: "paps_btn",
//     text: "PAPS",
//     route: "/trade-services/paps",
//   },
//   {
//     id: "bta_btn",
//     text: "BTA",
//     route: "/trade-services/bta",
//   },
// ];

export const loansSubMenuLinks = [
  {
    id: "current-loans_btn",
    key: "loans_btn",
    text: "Current Loan(s)",
    route: "/loans/current",
  },
  {
    id: "past-loans_btn",
    key: "loans_btn",
    text: "Past Loan(s)",
    route: "/loans/past",
  },
];

export const moreSubMenuLinks = [
  {
    id: "flights_btn",
    key: "more_btn",
    text: "Flights",
    route: "/flights/tickets",
  },
  {
    id: "pos-request_btn",
    key: "more_btn",
    text: "POS Request",
    route: "/request-pos/complete",
  },
];
