import React from "react";
import { ReactComponent as WemaLogo } from "../../../assets/svgs/wema-logo.svg";

export interface PDFProps {
  applicantName: string;
  duration: string;
  bankName: string;
  address: string;
  accountNo: string;
  phoneNumber: string;
  refereeName: string;
  refereeAddress: string;
  signature1: any;
  signature2?: string;
  date?: string;
}

const PdfComponent: React.FC<PDFProps> = ({
  applicantName,
  duration,
  bankName,
  address,
  accountNo,
  phoneNumber,
  refereeName,
  refereeAddress,
  signature1,
  signature2,
  date,
}) => {
  return (
    <div
      style={{ height: "1055px" }}
      className="relative flex flex-col justify-between text-[#6c1b53] text-sm"
    >
      <div className="absolute w-[300px] border-4 border-red-600 text-red-600 text-sm font-bold p-1 top-48 right-10">
        CAUTION: IT IS VERY DANGEROUS TO INTRODUCE ANY APPLICANT(S) WHO IS/ARE
        NOT WELL KNOWN TO YOU
      </div>
      <div className="p-2 px-8 leading-10">
        <div className="border-b-8 border-[#6c1b53] w-full">
          <div className="flex justify-between">
            <div></div>
            <div className="pr-10">
              <WemaLogo className="w-24 h-24" />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="font-semibold text-3xl">REFERENCE FORM</div>
            <div className="text-right">
              <p className="text-2xl font-semibold">WEMA BANK</p>
              <p className="text-[8px]">RC 575</p>
            </div>
          </div>
        </div>
        <div>
          <p>The Manager</p>
          <p>Wema Bank Plc</p>
        </div>
        <p>Dear Sir,</p>
        <div className="w-full border-b-2 py-1">
          <p className=" text-black">{applicantName}</p>
        </div>

        <p className="text-[8px] -mt-4">NAME OF APPLICANT(S)</p>
        <p>
          I/We wish to confirm that I/we have known the above named applicant(s)
          for a period of{" "}
          <span className="underline underline-offset-2 text-black">
            {duration}
          </span>{" "}
          and he/she/they is/are considered suitable to maintain a current
          account with the Bank.
        </p>
        <p>I/We maintain a current account with</p>
        <div className="w-full flex">
          <div className="w-auto">Name of bank</div>
          <div className="flex-grow border-b-2">
            <span className="text-black">{bankName}</span>
          </div>
        </div>
        <div className="w-full flex">
          <div className="w-auto">Address</div>
          <div className="flex-grow border-b-2">
            <span className="text-black">{address}</span>
          </div>
        </div>
        <div className="flex items-center gap-4 py-2">
          <div>Account No. </div>
          <div className="flex items-center">
            {accountNo.split("").map((account, index) => {
              return (
                <div
                  className="border-2 px-2 pb-10 h-4 w-6 text-center text-black"
                  key={index}
                >
                  {account}
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-full flex">
          <div className="w-auto">Contact No(s)</div>
          <div className="flex-grow border-b-2">
            <span className="text-black">{phoneNumber}</span>
          </div>
        </div>
        <div className="w-full flex">
          <div className="w-auto">Name of referee</div>
          <div className="flex-grow border-b-2">
            <span className="text-black">{refereeName}</span>
          </div>
        </div>
        <div className="w-full flex">
          <div className="w-auto">Address</div>
          <div className="flex-grow border-b-2">
            <span className="text-black">{refereeAddress}</span>
          </div>
        </div>
      </div>
      <div>
        <div className="flex gap-10 px-8 py-5">
          <div className="text-left w-full">
            <p>Authorised Signatory</p>
            <img
              src={signature1}
              alt="signature1"
              className="border-b-2 w-full h-24"
              height="120px"
            />
            <p className="text-[8px]">
              SIGNATURE & DATE{" "}
              <span className="text-black">{new Date().toUTCString()}</span>
            </p>
          </div>
          <div className="text-left w-full">
            <p>Authorised Signatory</p>
            <img
              src={signature2}
              alt="signature1"
              className="border-b-2 h-24 w-full"
              height="120px"
            />
            <p className="text-[8px]">
              SIGNATURE & DATE{" "}
              <span className="text-black">{new Date().toUTCString()}</span>
            </p>
          </div>
        </div>
        <div className="bg-[#f1aedc] h-28 w-full px-5 text-left text-xs text-[#6c1b53]">
          <p>Note:</p>
          <ol>
            <li>
              1. Referee <b>MUST</b> be a Current Account holder in either Wema
              Bank or any other Bank in Nigeria
            </li>
            <li>
              2. Referee's account <b>MUST NOT</b> be less than 6 (six) months
              old
            </li>
            <li>
              3. Salary Accounts are <b>NOT</b> suitable for references
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PdfComponent;
