import { Table } from "flowbite-react";

const DefaultBody = () => {
  return (
    <Table.Row className="bg-white">
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
        Apple MacBook Pro 17"
      </Table.Cell>
      <Table.Cell>Sliver</Table.Cell>
      <Table.Cell>Laptop</Table.Cell>
      <Table.Cell>$2999</Table.Cell>
      <Table.Cell>
        <a
          href="/tables"
          className="font-medium text-blue-600 hover:underline dark:text-blue-500"
        >
          Edit
        </a>
      </Table.Cell>
    </Table.Row>
  );
};

export default DefaultBody;
