import { createAsyncThunk } from "@reduxjs/toolkit";
import { useApiRequest } from "../../../../hooks/useApiRequest";
import {
  POST_LOGIN,
  POST_RESET_PASSWORD,
  POST_FIRST_TIME_RESET_PASSWORD,
} from "../../../../utils/apis/routes";
import {
  LoginType,
  ResetPasswordType,
  FirstTimeLoginType,
} from "../../../../types/auth";
export const POST_LOGIN_ACTION = createAsyncThunk(
  "POST_LOGIN",
  async (payload: LoginType) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_LOGIN,
      body: payload,
      originType: "identity",
    });
  }
);
export const POST_RESET_PASSWORD_ACTION = createAsyncThunk(
  "POST_RESET_PASSWORD",
  async (payload: ResetPasswordType) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_RESET_PASSWORD,
      body: payload,
      originType: "identity",
    });
  }
);
export const POST_FIRST_TIME_RESET_PASSWORD_ACTION = createAsyncThunk(
  "POST_FIRST_TIME_RESET_PASSWORD",
  async (payload: FirstTimeLoginType) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_FIRST_TIME_RESET_PASSWORD,
      body: payload,
      originType: "identity",
    });
  }
);
