import { combineReducers } from "@reduxjs/toolkit";
import appReducer from "./app/slices";
import authReducer from "./auth/slices";
// import userManagementReducer from "./userManagement/slices";
import onboardingReducer from "./onboarding/slices";
import profileReducer from "./profile/slices";
import paymentReducer from "./payments/slices";
import accountReducer from "./accounts/slices";
import transactionReducer from "./transactions/slices";
import tradeServicesReducer from "./tradeServices/slices";
import pendingActionsReducer from "./pendingActions/slices";
import adminReducer from "./admin/slices";
import cardReducer from "./cards/slices";
import loansReducer from "./loans/slices";
import accountReactivationReducer from "./accountReactivation/slices";
import notificationsReducer from "./notifications/slices";
import collectionsReducer from "./collections/slices";
import remitaReducer from "./remita/slices";
import flightsReducer from "./flights/slices";

export const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  admin: adminReducer,
  accountReactivation: accountReactivationReducer,
  // userManagement: userManagementReducer,
  onboarding: onboardingReducer,
  profile: profileReducer,
  payment: paymentReducer,
  accounts: accountReducer,
  transactions: transactionReducer,
  tradeServices: tradeServicesReducer,
  pendingActions: pendingActionsReducer,
  card: cardReducer,
  loans: loansReducer,
  notifications: notificationsReducer,
  collections: collectionsReducer,
  remita: remitaReducer,
  flights: flightsReducer,
});
