import { PrimaryButton } from "alat-components-library";
import { useNavigate } from "react-router-dom";
import { Card } from "flowbite-react";
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/success-icon.svg";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";

const SuccessScreen = ({ setActiveTab, screenName }: any) => {
  const navigate = useNavigate();
  const paymentState = usePaymentState();

  return (
    <div className="w-full pt-6" id="success_screen">
      <Card className="md:w-[768px] w-full bg-white dark:border-gray-200 mx-auto px-6">
        <div>
          <div className="text-center flex justify-center mt-10">
            <SuccessIcon />
          </div>
          {/* <div className="text-center text-2xl font-semibold mt-6">
            Transaction request sent
          </div> */}
          <h3 className="text-center mb-5 text-md font-normal text-black mt-10">
            {paymentState?.transferResponseMessage?.actionMessage}
          </h3>
          <div className="text-center mb-5 text-sm rounded-lg font-normal text-gray-500 light:text-gray-400 mt-10 mx-10 p-4 bg-[#F5F5F5]">
            {paymentState?.transferResponseMessage?.title}
          </div>
          <div className="flex justify-center flex-row gap-4 mb-40 mt-10">
            <PrimaryButton
              id="proceed-to-dashboard_btn"
              labelText={"Proceed to Dashboard"}
              variant="font-medium text-xs text-white"
              containerVariant="w-[188px] h-[45px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red"
              handleClick={() => navigate("/user-dashboard")}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SuccessScreen;
