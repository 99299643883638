import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card, Label, Button } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import useNewTransfer from "controllers/NewTransfer/useNewTransferController";
import { getOption, getAuthType } from "utils/helperFunctions";
import { useLoginController } from "controllers/Login/LoginController";
import {
  setCorrelationId,
  setValidateBillResponse,
  usePaymentState,
  setOtpTransactionType,
} from "react-wrappers/stores/redux/payments/slices";
import { TransactionTypeEnum } from "utils/enums";
import { POST_CREATE_BILL_ACTION } from "react-wrappers/stores/redux/payments/actions";
import PageLoader from "components/Loader/PageLoader";
import { ToastNotify } from "helpers/toastNotify";
import { usePurchaseBillStatus } from "hooks/apis/Payment/BillsPayment/usePurchaseBillStatus";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";

const BillConfirmation = () => {
  const {
    handleChange,
    dispatch,
    accountToDebit,
    allAccounts,
    setAccountToDebit,
    showPinError,
    pinError,
    pin,
    authToken,
    allowSubmit,
  } = useNewTransfer();

  const navigate = useNavigate();
  const location = useLocation();
  const { authState } = useLoginController();
  const { userRole } = useAuthState();
  const { activeSelectedBill, activeSelectedSubBill, loading, responseCode } =
    usePaymentState();
  const { billPurchaseStatus, handleFetchBillPurchaseStatus } =
    usePurchaseBillStatus();
  const isMultipleSignatory = userRole?.toLowerCase() !== "soleproprietor";
  const statePayload = location?.state?.payload;
  const amount = statePayload.amount;
  const authType = getAuthType(
    amount,
    responseCode,
    authState,
    isMultipleSignatory
  );
  const activeAccounts = allAccounts.filter(
    (account: any) => account.accountStatus === "Active"
  );

  const handleSubmit = () => {
    if (accountToDebit.accountNo && amount > accountToDebit.balance) {
      return ToastNotify({
        type: "error",
        message: `Insufficient Balance`,
      });
    }

    const apiPayload = {
      sourceAccountNumber: accountToDebit.accountNo,
      sourceAccountName: accountToDebit.accountName,
      sourceAccountCurrencyCode: accountToDebit.currency,
      narration: "",
      transactionType: isMultipleSignatory
        ? TransactionTypeEnum.MultipleSignatoryBillsPayment
        : TransactionTypeEnum.SingleSignatoryBillPayment,
      transactionPin: pin,
      token: authToken,
      isSoftToken: authState?.user?.hasSoftToken,
      // "isValidSingleSignatoryCustomer": true,
      billDestinations: [
        {
          categoryId: activeSelectedBill.categoryId,
          categoryName: activeSelectedBill.categoryName,
          billerId: activeSelectedSubBill.id,
          billerName: activeSelectedSubBill.name,
          packageId: statePayload.package.id,
          packageName: statePayload.package.name,
          amount: amount,
          charge: activeSelectedSubBill.charge,
          // identifier: activeSelectedSubBill.identifier,
          identifier: statePayload?.transactionId,
          saveBeneficiary: false,
          alias: "",
        },
      ],
    };
    dispatch(setOtpTransactionType(apiPayload.transactionType));
    //Call API to Validate the refrence or transaction ID
    dispatch(POST_CREATE_BILL_ACTION(apiPayload))
      .unwrap()
      .then((res: any) => {
        // console.log("res", res);
        if (res.status === 200) {
          // dispatch(setCorrelationId(res?.data?.data?.correlationId))
          dispatch(setValidateBillResponse(res?.data?.data));
          // redirect to the otp validation screen
          if (authType === "pinOtp")
            return navigate(`/bills/payment/pay-bill/otp`, {
              state: {
                AirtimeRequestData: res.data,
              },
            });

          return navigate("/bills/payment/pay-bill/status", {
            state: {
              resData: res.data,
            },
          });
        }

        return ToastNotify({
          type: "error",
          message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
        });
      });
  };

  return (
    <div className="w-full">
      {!!loading.includes("POST_CREATE_BILL") && (
        <PageLoader message="Processing Bill, please wait..." />
      )}
      <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              onClick={() => navigate(-1)}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            />
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="banktransfer_heading"
            >
              Pay bill
            </div>
            <div></div>
          </div>
        </div>
        <hr />
        <form className="flex flex-col gap-4 justify-center items-center mt-4">
          <div className="flex flex-wrap justify-center gap-4 ">
            <div className="text-alat-grey relative w-full lg:max-w-sm mb-2">
              <div className="w-full">
                <div className="text-[#666666] mb-2 text-sm font-normal mx-auto md:w-[400px] w-full">
                  Transaction details
                </div>

                <Card className="box-border md:w-[400px] mx-auto w-full h-[114px] rounded-lg text-white bg-[image:url('./assets/svgs/card-background.svg')] bg-no-repeat bg-cover">
                  <div className="grid grid-cols-2 gap-2">
                    {activeSelectedSubBill.requiredValidation && (
                      <div>
                        <div className="text-xs">
                          {activeSelectedSubBill?.identifier}
                        </div>
                        <div className="text-xs">
                          {statePayload?.transactionId}
                        </div>
                      </div>
                    )}
                    <div>
                      <div className="text-xs text-right">Profile name</div>
                      <div
                        className="text-xs text-right"
                        id="num-of-recipients_text"
                      >
                        {statePayload?.referenceData?.customerName ||
                          authState.user.fullName}
                      </div>
                    </div>
                    <div>
                      <div className="text-xs">Biller</div>
                      <div className="text-xs" id="total-amount_text">
                        {activeSelectedSubBill.name}
                      </div>
                    </div>
                    <div>
                      <div className="text-xs text-right">Amount</div>
                      <div className="text-xs text-right">
                        <span>₦</span>
                        <span id="text-xs">{statePayload?.amount}</span>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="relative w-full lg:max-w-sm mb-4">
                <div className="relative w-full lg:max-w-sm">
                  <div className="relative lg:max-w-sm md:w-[390px] mx-auto w-full pt-3">
                    <SelectDrop
                      id="accounttodebit_dropdown"
                      idList="accounttodebit"
                      variant="h-12 w-full text-sm"
                      name="accountNumberDebit"
                      label="Select account to debit"
                      placeholder="Select account to debit"
                      value={accountToDebit.accountNo}
                      options={getOption("debit", activeAccounts)}
                      onChange={(name: string, value: any) =>
                        setAccountToDebit(JSON.parse(value?.raw))
                      }
                    />
                    {accountToDebit.accountNo &&
                      amount > accountToDebit.balance && (
                        <div
                          className="text-sm text-red-800"
                          id="insufficient_text"
                        >
                          Insufficient Balance
                        </div>
                      )}
                  </div>
                </div>
              </div>
              {authType === "pinOtp" && (
                <div className="relative w-full mb-6">
                  <div className="mb-2 block text-left">
                    <Label
                      htmlFor="pin"
                      value="Enter pin"
                      className=" text-[#464646] dark:!text-[#464646] font-normal text-sm"
                    />
                  </div>

                  <input
                    id="pin_input"
                    name="pin"
                    type="password"
                    data-auth-type={authType}
                    className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                    value={pin}
                    onChange={handleChange}
                    maxLength={4}
                  />
                  {showPinError === "yes" && (
                    <div className="text-red-700">{pinError}</div>
                  )}
                </div>
              )}
              {authType === "softToken" && (
                <div className="relative w-full mb-6">
                  <div className="mb-2 block text-left">
                    <Label
                      htmlFor="authToken"
                      value="Enter soft token"
                      className=" !text-[#666666] mb-2 !text-sm !font-normal"
                    />
                  </div>

                  <input
                    id="softToken_input"
                    name="authToken"
                    type="password"
                    data-auth-type={authType}
                    className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                    value={authToken}
                    onChange={handleChange}
                    maxLength={6}
                  />
                  {showPinError === "yes" && (
                    <div className="text-red-700">{pinError}</div>
                  )}
                </div>
              )}
              {authType === "hardTokenPin" && (
                <div className="relative w-full mb-6">
                  <div className="mb-2 block text-left">
                    <Label
                      htmlFor="pin"
                      value="Enter hard token PIN"
                      className=" !text-[#666666] mb-2 !text-sm !font-normal"
                    />
                  </div>

                  <input
                    id="hardToken-pin_input"
                    name="pin"
                    type="password"
                    data-auth-type={authType}
                    className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                    value={pin}
                    onChange={handleChange}
                    maxLength={4}
                  />
                  {showPinError === "yes" && (
                    <div className="text-red-700">{pinError}</div>
                  )}

                  <div className="mb-2 mt-6 block text-left">
                    <Label
                      htmlFor="authToken"
                      value="Enter hard token OTP"
                      className=" !text-[#666666] mb-2 !text-sm !font-normal"
                    />
                  </div>

                  <input
                    id="hardToken_input"
                    name="authToken"
                    type="password"
                    data-auth-type={authType}
                    className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary "
                    value={authToken}
                    onChange={handleChange}
                    maxLength={6}
                  />
                </div>
              )}

              <div className="flex justify-center">
                {authType === "pinOtp" && (
                  <Button
                    type="button"
                    className="bg-primary hover:bg-transparent hover:border-primary mb-20  w-[188px]  hover:bg-primary focus:ring-1 focus:ring-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                    onClick={handleSubmit}
                    disabled={!allowSubmit && authType === "pinOtp"}
                    id="reviewtransfercont_btn"
                  >
                    Continue
                  </Button>
                )}
                {authType === "hardTokenPin" && (
                  <Button
                    type="button"
                    className="bg-primary hover:bg-transparent hover:text-primary hover:border-primary mb-20 mt-10 w-[188px] hover:bg-primary focus:ring-1 focus:ring-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                    onClick={handleSubmit}
                    disabled={
                      !allowSubmit ||
                      !authToken ||
                      (!authState?.user?.hasSoftToken && !pin)
                    }
                    id="submit_btn"
                  >
                    Submit
                  </Button>
                )}
                {authType !== "pinOtp" && authType !== "hardTokenPin" && (
                  <Button
                    type="button"
                    className="bg-primary hover:bg-transparent hover:text-primary hover:border-primary mb-20 mt-10 w-[188px] hover:bg-primary focus:ring-1 focus:ring-primary dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary disabled:bg-red-200 disabled:hover:bg-red-200 disabled:text-white disabled:focus:ring-red-200"
                    onClick={handleSubmit}
                    disabled={!allowSubmit}
                    id="reviewtransfercont2_btn"
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default BillConfirmation;
