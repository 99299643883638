import { useState } from "react";
import { useApiRequest } from "../../../useApiRequest";
import { POST_ACCOUNT_STATEMENT_DATA } from "../../../../utils/apis/routes";
import { ToastNotify } from "../../../../helpers/toastNotify";
import { useAppDispatch } from "hooks/useReduxHook";
import { setAccountStatementPDFURL } from "react-wrappers/stores/redux/payments/slices";

export const initialValues = {
  status: 0,
};

export const useTransactionStatus = () => {
  //Local States
  const [statusLoading, setSatusLoading] = useState<boolean>(false);
  const [statusData, setStatusData] = useState(initialValues);
  const [statementData, setStatementData] = useState("");

  //API Request
  const makeRequest = useApiRequest();
  const dispatch = useAppDispatch();

  const handleStatementStatus = async (correlationId: string) => {
    setStatusData(initialValues);
    setSatusLoading(true);

    try {
      const res = await makeRequest({
        method: "post",
        route: POST_ACCOUNT_STATEMENT_DATA,
        originType: "identity",
        body: { correlationId },
      });

      //Map the payload
      if (res?.status !== 200) {
        if (res?.data?.data?.hasError) {
          ToastNotify({
            type: "error",
            message: res?.data?.data?.responseMessage?.title,
            position: "top-right",
          });
        } else {
          ToastNotify({
            type: "error",
            message: "Something went wrong",
          });
        }
      }
      if (res?.status === 200) {
        setStatusData(res?.data?.data);
        setStatementData(res?.data?.responseMessage?.actionMessage);
        const resData = res?.data?.data;
        dispatch(setAccountStatementPDFURL(resData));
      } else {
        const errMsg =
          res?.data?.responseMessage?.title ||
          "An error occured while checking status";
        ToastNotify({
          type: "error",
          message: errMsg,
          position: "top-right",
        });
      }

      setSatusLoading(false);
    } catch (error) {
      const errMsg = "An error occured while checking status";
      ToastNotify({
        type: "error",
        message: errMsg,
        position: "top-right",
      });

      setSatusLoading(false);
    }
  };

  return {
    statusData,
    statusLoading,
    statementData,
    handleStatementStatus,
  };
};
