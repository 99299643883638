import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GET_ACCOUNT_NAME_ENQUIRY_ACTION,
  GET_BENEFICIARY_LIST_ACTION,
  GET_GROUP_LIST_ACTION,
  GET_TRANSACTION_STATUS_ACTION,
  POST_FETCH_TRANSACTION_LIST_ACTION,
  POST_FETCH_TRANSACTION_DETAIL_ACTION,
  POST_FETCH_COLLECTIONS_DETAIL_ACTION,
  POST_TRANSACTION_VALIDATE_ACTION,
  POST_VALIDATE_PIN_ACTION,
  PUT_APROVE_OR_REJECT_TRANSACTION_ACTION,
  GET_PENDING_USERS_REQUEST_DETAILS_ACTION,
  POST_REVIEW_USER_REQUEST_ACTION,
  POST_TRANSFER_TRANSACTION_ACTION,
  PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION,
  POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS_ACTION,
  GET_RESEND_TRANSFER_OTP_ACTION,
  POST_FETCH_ALL_BULK_TRANSFER_ACTION,
  POST_FETCH_ONE_BULK_TRANSFER_ACTION,
  POST_CREATE_ACCOUNT_STATEMENT_ACTION,
  GET_BILL_CATEGORIES_LIST_ACTION,
  POST_VALIDATE_BILL_REFERENCE_ACTION,
  POST_CREATE_BILL_ACTION,
  POST_FETCH_BILLS_PAYMENT_DETAIL_ACTION,
} from "./actions";
import { RootState } from "../store";
import { useAppSelector } from "../../../../hooks/useReduxHook";
import {
  triggerTransactionValidateFailed,
  triggerTransactionValidatePending,
  triggerTransactionValidateSuccess,
  triggerGetTransactionStatusFailed,
  triggerGetTransactionStatusPending,
  triggerGetTransactionStatusSuccess,
  triggerGetNameEquiryPending,
  triggerGetNameEquirySuccess,
  triggerGetNameEquiryFailed,
  triggerGetBeneficiaryListPending,
  triggerGetBeneficiaryListSuccess,
  triggerGetBeneficiaryListFailed,
  triggerGetGroupListPending,
  triggerGetGroupListSuccess,
  triggerGetGroupListFailed,
  triggerTransactionList,
  triggerTransactionDetail,
  triggerCollectionDetail,
  triggerAcceptAndRejectTransaction,
  triggerUserRequestDetails,
  triggerReviewUserRequest,
  triggerPostTransferTransaction,
  triggerPutCompleteTransferTransaction,
  triggerSoftTokenRequestDetails,
  triggerPostFetchAllBulkTransaction,
  triggerPostFetchOneBulkTransaction,
  triggerPostCreateAccountStatement,
  triggerValidateBillReference,
  triggerBillCategoriesList,
  triggerCreateBill,
  triggerBillsPaymentDetail,
} from "./assit-functions";
import { removeLoadingState } from "../../../../helpers/assitFunctions";
import { PaymentState } from "../interfaces";
import { accountStatementTypeEnum } from "utils/enums";

const dataObject = {
  loading: [],
  isLoading: false,
  isSuccess: true,
  response: {},
  sourceAccountNumber: "",
  sourceAccountName: "",
  sourceAccountCurrencyCode: "",
  transactionType: "",
  otpTransactionType: "",
  transferDestinations: [
    {
      beneficiaryAccountNumber: "",
      beneficiaryName: "",
      beneficiaryBankName: "WEMA",
      beneficiaryBankCode: "035",
      amount: "",
      beneficiaryCurrency: "",
      narration: "",
    },
  ],
  multipleTransferGroupName: "",
  transactionPin: "",
  token: "",
  isSoftToken: "",
  createMultipleTransferGroup: false,
  pendingTransactionsResponse: {
    data: false,
    httpStatusCode: 0,
    hasError: false,
    responseMessage: {
      title: "",
      actionMessage: "",
    },
    responseCode: 0,
  },
  pendingTransactionList: {
    sortedData: [],
    hasNextPage: false,
    currentPage: 1,
    count: 0,
    totalItems: 0,
    pageSize: 10,
    skip: 1,
    take: 10,
  },
  pendingCollectionsDetail: {
    categoryName: "",
    collectionItemName: "",
    collectionName: "",
    initiatedBy: "",
    initiatedByFullName: "",
    merchantName: "",
    sourceAccountBankCode: "",
    sourceAccountBankName: "",
    sourceAccountCurrency: "",
    sourceAccountName: "",
    sourceAccountNumber: "",
    totalAmount: "",
    totalCharge: "",
    type: "",
  },
  pendingTransactionDetail: {
    initiatedBy: "",
    sourceAccountNumber: "",
    sourceAccountName: "",
    sourceAccountBankName: "",
    type: "",
    totalCharge: "",
    totalAmount: "",
    sourceAccountCurrency: "",
    userRequest: {
      initiatedBy: "",
      initiatedByFullName: "",
      correlationId: "",
      profileFullName: "",
      profileUsername: "",
      profilePhonenumber: "",
      profileEmail: "",
      profileRole: "",
      onetimeTransactionLimit: 0,
      dailyTransactionLimit: 0,
      dateCreated: "",
      numberOfAccountsToBeMapped: 0,
      type: "",
    },
    tokenRequest: [],
  },
  pendingBillPaymentDetail: {
    billerName: "",
    billerPackage: "",
    initiatedBy: "",
    initiatedByFullName: "",
    principalAmount: 0,
    sourceAccountBankCode: "",
    sourceAccountBankName: "",
    sourceAccountCurrency: "",
    sourceAccountName: "",
    sourceAccountNumber: "",
    totalAmount: 0,
    totalCharge: 0,
    type: "",
  },
  approvePendingTransactionsOrActions: {
    successMessage: {},
    successStatus: false,
  },
  reviewUserRequest: {
    successMessage: {},
    successStatus: false,
  },
  nipCharges: [],
  bulkTransferType: "Wema",
  triggerMultipleAcctEnquiryAgain: false,
  validateBulkTransferAccounts: false,
  bulkMultipleAccountNameEnquiry: {
    data: "",
    httpStatusCode: 0,
    hasError: false,
    responseMessage: {
      title: "",
      actionMessage: "",
    },
    responseCode: 0,
  },
  bulkMultipleAccountNameEnquiryStatusResponse: [
    {
      acctName: "",
      status: "",
      acctNum: "",
      bankCode: "",
      bank: "",
      desc: "",
      refCode: "",
      amount: 0,
      curr: "",
      fee: 0,
      rowId: 0,
    },
  ],
  bulkTransferPayloadInter: {},
  bulkTransferPayloadIntra: {},
  bulkTransferPayloadReferenceIntra: {},
  prevBulkTransferDestinationsLength: 0,
  bulkListPageNumber: 1,
  bulkOtpMessage: "",
  bulkTransferFileHeaderOne: [
    "INSTITUTION",
    "ACCOUNT NUMBER",
    "ACCOUNT NAME",
    "DESCRIPTION",
    "AMOUNT",
    "REFERENCE CODE (opt.)",
  ],
  bulkTransferFileHeaderTwo: [
    "INSTITUTION",
    "ACCOUNT NUMBER",
    "ACCOUNT NAME",
    "DESCRIPTION",
    "AMOUNT",
  ],
  correlationId: null,
  bulkHistoryFilter: {
    startDate: "",
    endDate: "",
    accountNumber: "",
    reference: "",
    pageNumber: 1,
    pageSize: 10,
    skip: 1,
    take: 10,
  },
  allBulkTransactionList: null,
  oneBulkTransactionID: null,
  oneBulkTransactionList: null,
  transactionReference: "",
  referenceId: "",
  fundMyAcctSelectedAccout: null,
  accountStatementPayload: null,
  selectedAccountStatement: null,
  accountStatementPDFURL: null,
  accountStatmentType: accountStatementTypeEnum.AccountStatement,
  beneficiaryList: [
    {
      bankCode: "",
      bankName: "",
      accountNumber: "",
      accountName: "",
      currency: "",
    },
  ],
  selectedTransferBeneficiary: {
    bankCode: "",
    bankName: "",
    accountNumber: "",
    accountName: "",
    currency: "",
  },
  fxTransfer: {
    historyPagination: {
      totalItems: 0,
      currentPage: 1,
      skip: 1,
      take: 10,
    },
    newTransfer: {
      status: {
        successStatus: false,
        successMessage: {},
        errorMessage: "",
      },
      beneficiaryDetails: {
        iban: "",
        beneficiaryName: "",
        beneficiaryAddress: "",
        beneficiaryCountry: "",
      },
      beneficiaryBankDetails: {
        bankSwiftCode: "",
        bankName: "",
        bankAddress: "",
        sortCode: "",
        intermediaryBankSwiftCode: "",
        intermediaryBankName: "",
        intermediaryBankAddress: "",
        intermediaryBankAccountNo: "",
        intermediarySortCode: "",
      },
      purposesAndCharges: {},
      transferDetails: {
        email: "",
        amount: "",
        currency: "",
        purposeOfPayment: "",
        altPurposeOfPayment: "",
        supportingDocument: "",
        beneficiaryToPay: false,
      },
      fxExchangeRate: {
        principalAmount: 0,
        exchangeRate: 0,
      },
    },
    beneficiaries: {
      selectedBeneficiary: {
        id: "",
        customerId: "",
        currency: "",
        beneficiaryName: "",
        beneficiaryAlias: "",
        beneficiaryAddress: "",
        beneficiaryCountry: "",
        beneficiaryCountryName: "",
        beneficiaryPhone: "",
        beneficiaryEmail: "",
        beneficiaryBankName: "",
        beneficiaryBankAddress: "",
        beneficiaryAccountNumber: "",
        beneficiaryBankSwiftCode: "",
        beneficiaryBankRoutingNumber: "",
        intermediaryBankName: "",
        intermediaryBankSwiftCode: "",
        intermediaryBankAccountNumber: "",
        intermediaryRoutingNumber: "",
        dateCreated: "",
        isBeneficiarySaved: false,
      },
      list: {
        hasNextPage: false,
        pageNumber: 1,
        totalItemCount: 0,
        count: 0,
        data: [
          {
            id: "",
            customerId: "",
            currency: "",
            beneficiaryName: "",
            beneficiaryAlias: "",
            beneficiaryAddress: "",
            beneficiaryCountry: "",
            beneficiaryCountryName: "",
            beneficiaryPhone: "",
            beneficiaryEmail: "",
            beneficiaryBankName: "",
            beneficiaryBankAddress: "",
            beneficiaryAccountNumber: "",
            beneficiaryBankSwiftCode: "",
            beneficiaryBankRoutingNumber: "",
            intermediaryBankName: "",
            intermediaryBankSwiftCode: "",
            intermediaryBankAccountNumber: "",
            intermediaryRoutingNumber: "",
            dateCreated: "",
            isBeneficiarySaved: false,
          },
        ],
      },
    },
    history: {
      hasNextPage: false,
      pageNumber: 1,
      totalItemCount: 0,
      count: 0,
      data: [
        {
          referenceId: "",
          accountNumber: "",
          type: "",
          amount: 0,
          date: "",
          status: "",
          currency: "",
        },
      ],
    },
    selectedFxTransaction: {
      referenceId: "",
      accountNumber: "",
      type: "",
      amount: 0,
      date: "",
      status: "",
      currency: "",
    },
    fxTransactionDetails: {
      data: {
        transactionStatus: "",
        amount: 0,
        transactionDate: "",
        beneficiaryAccountNumber: "",
        beneficiaryBank: "",
        currency: "",
      },
      httpStatusCode: 0,
      hasError: false,
      responseMessage: {
        title: "",
        actionMessage: "",
      },
      responseCode: 0,
    },
    pendingFxTransferDetails: {
      initiatedBy: "",
      initiatedByFullName: "",
      type: "",
      amount: 0,
      destinationCurrency: "",
      commission: 0,
      offshoreCharge: 0,
      vat: 0,
      swiftCharge: 0,
      totalAmount: 0,
      dateCreated: "",
      beneficiaryName: "",
      beneficiaryAccountNumber: "",
    },
  },
  billCategoryList: {
    count: 0,
    categories: null,
  },
  purchaseBillStatus: {
    title: "",
    actionMessage: "",
  },
  validateBillResponse: {
    correlationId: "",
    transactionReference: "",
  },
  transferResponseMessage: {
    title: "",
    actionMessage: "",
  },
  activeSelectedBill: null,
  activeSelectedBillList: null,
  allBillList: null,
  activeSelectedSubBill: null,
  responseCode: 0,
  transactionStatusByCorrelationIdResponse: {},
} as PaymentState;

export const initialState: any = { ...dataObject };

export const paymentSlice = createSlice({
  name: "payment",
  initialState,

  reducers: {
    resetPaymentsData: (): any => initialState,

    setLoadingState: (state: any, action: PayloadAction<any>): void => {
      state.loading = action.payload;
    },
    setTransactionStatusByCorrelationIdResponse: (
      state: any,
      action: PayloadAction<any>
    ): void => {
      state.transactionStatusByCorrelationIdResponse = action.payload;
    },

    sendPaymentData: (state: any, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setRegularTransferBeneficiaries: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.beneficiaryList = action.payload;
    },
    setSelectedTransferBeneficiary: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.selectedTransferBeneficiary = action.payload;
    },
    setActiveSelectedBill: (state: any, action: PayloadAction<any>) => {
      state.activeSelectedBill = action.payload;
    },
    setSelectedBillList: (state: any, action: PayloadAction<any>) => {
      state.selectedBillList = action.payload;
    },
    setAllBillList: (state: any, action: PayloadAction<any>) => {
      state.allBillList = action.payload;
    },
    setActiveSelectedSubBill: (state: any, action: PayloadAction<any>) => {
      state.activeSelectedSubBill = action.payload;
    },
    setPurchaseBillStatus: (
      state: PaymentState,
      action: PayloadAction<any>
    ) => {
      state.purchaseBillStatus = action.payload;
    },
    setValidateBillResponse: (
      state: PaymentState,
      action: PayloadAction<any>
    ) => {
      state.validateBillResponse = action.payload;
    },
    setNipChargesResponse: (
      state: PaymentState,
      action: PayloadAction<any>
    ) => {
      state.nipCharges = action.payload;
    },
    setTransferResponseMessage: (
      state: PaymentState,
      action: PayloadAction<any>
    ) => {
      state.transferResponseMessage = action.payload;
    },
    setBulkTransferType: (state: any, action: PayloadAction<any>) => {
      state.bulkTransferType = action.payload;
    },
    setBulkListPageNumber: (
      state: PaymentState,
      action: PayloadAction<any>
    ) => {
      state.bulkListPageNumber = action.payload;
    },
    setTriggerMultipleAcctEnquiryAgain: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.triggerMultipleAcctEnquiryAgain = action.payload;
    },
    setValidateBulktransferAccounts: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.validateBulkTransferAccounts = action.payload;
    },
    setPrevBulkTransferDestinationsLength: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.prevBulkTransferDestinationsLength = action.payload;
    },
    setBulkMultipleAccountNameEnquiry: (
      state: PaymentState,
      action: PayloadAction<any>
    ) => {
      state.bulkMultipleAccountNameEnquiry = action.payload;
    },
    setBulkMultipleAccountNameEnquiryStatusResponse: (
      state: PaymentState,
      action: PayloadAction<any>
    ) => {
      state.bulkMultipleAccountNameEnquiryStatusResponse = action.payload;
    },
    setBulkTranferIntra: (state: any, action: PayloadAction<any>) => {
      state.bulkTransferPayloadIntra = action.payload;
    },
    setBulkOtpMessage: (state: any, action: PayloadAction<any>) => {
      state.bulkOtpMessage = action.payload;
    },
    setBulkTransferPayloadReferenceIntra: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.bulkTransferPayloadReferenceIntra = action.payload;
    },
    setBulkTranferInter: (state: any, action: PayloadAction<any>) => {
      state.bulkTransferPayloadInter = action.payload;
    },
    setOneBulkTransactionID: (state: any, action: PayloadAction<any>) => {
      state.oneBulkTransactionID = action.payload;
    },
    setBulkHistoryFilter: (state: any, action: PayloadAction<any>) => {
      state.bulkHistoryFilter = action.payload;
    },

    setAllBulkTransactionList: (state: any, action: PayloadAction<any>) => {
      state.allBulkTransactionList = action.payload;
    },
    setOneBulkTransactionList: (state: any, action: PayloadAction<any>) => {
      state.oneBulkTransactionList = action.payload;
    },
    setSkipAndTake: (state: any, action: PayloadAction<any>) => {
      state.pendingTransactionList = {
        ...state?.pendingTransactionList,
        skip: action.payload?.skip,
        take: action.payload?.take,
      };
    },
    setCorrelationId: (state: any, action: PayloadAction<any>) => {
      state.correlationId = action.payload;
    },
    setTransactionReference: (state: any, action: PayloadAction<any>) => {
      state.transactionReference = action.payload;
    },
    setFundMyAcctSelectedAccout: (state: any, action: PayloadAction<any>) => {
      state.fundMyAcctSelectedAccout = action.payload;
    },
    setAccountStatementPayload: (state: any, action: PayloadAction<any>) => {
      state.accountStatementPayload = action.payload;
    },
    setSelectedAccountStatement: (state: any, action: PayloadAction<any>) => {
      state.selectedAccountStatement = action.payload;
    },
    setAccountStatementPDFURL: (state: any, action: PayloadAction<any>) => {
      state.accountStatementPDFURL = action.payload;
    },
    setAccountStatementType: (state: any, action: PayloadAction<any>) => {
      state.accountStatmentType = action.payload;
    },
    // FX TRANSFER REDUCERS
    setBeneficiaryDetails: (state: any, action: PayloadAction<any>) => {
      state.fxTransfer.newTransfer.beneficiaryDetails = action.payload;
    },
    setBeneficiaryBankDetails: (state: any, action: PayloadAction<any>) => {
      state.fxTransfer.newTransfer.beneficiaryBankDetails = action.payload;
    },
    setPurposesAndCharges: (state: any, action: PayloadAction<any>) => {
      state.fxTransfer.newTransfer.purposesAndCharges = action.payload;
    },
    setTransferDetails: (state: any, action: PayloadAction<any>) => {
      state.fxTransfer.newTransfer.transferDetails = action.payload;
    },
    setFxExchangeRate: (state: any, action: PayloadAction<any>) => {
      state.fxTransfer.newTransfer.fxExchangeRate = action.payload;
    },
    setHistory: (state: any, action: PayloadAction<any>) => {
      state.fxTransfer.history = action.payload;
    },
    setBeneficiaries: (state: any, action: PayloadAction<any>) => {
      state.fxTransfer.beneficiaries.list = action.payload;
    },
    setSelectedBeneficiary: (state: any, action: PayloadAction<any>) => {
      state.fxTransfer.beneficiaries.selectedBeneficiary = action.payload;
    },
    setFxTransactionDetails: (state: any, action: PayloadAction<any>) => {
      state.fxTransfer.fxTransactionDetails = action.payload;
    },
    setSelectedFxTransaction: (state: any, action: PayloadAction<any>) => {
      state.fxTransfer.selectedFxTransaction = action.payload;
    },
    setHistorySkipAndTake: (state: any, action: PayloadAction<any>) => {
      state.fxTransfer.historyPagination = {
        ...state.fxTransfer.historyPagination,
        skip: action.payload.skip,
        take: action.payload.take,
      };
    },
    setFxTransferStatus: (state: any, action: PayloadAction<any>) => {
      state.fxTransfer.newTransfer.status = {
        ...state.fxTransfer.newTransfer.status,
        successStatus: action?.payload?.successStatus,
        successMessage: action?.payload?.successMessage,
        errorMessage: action?.payload?.errorMessage,
      };
    },
    setPendingFxTransferDetails: (state: any, action: PayloadAction<any>) => {
      state.fxTransfer.pendingFxTransferDetails = action.payload;
    },
    setUserRequest: (state: any, action: PayloadAction<any>) => {
      state.pendingTransactionDetail = {
        ...state.pendingTransactionDetail,
        userRequest: action.payload,
      };
    },
    setPendingBillPaymentDetails: (state: any, action: PayloadAction<any>) => {
      state.pendingBillPaymentDetail = action.payload;
    },
    setPendingCollectionDetails: (state: any, action: PayloadAction<any>) => {
      state.pendingCollectionsDetail = action.payload;
    },
    setResponseCode: (state: any, action: PayloadAction<any>) => {
      state.responseCode = action.payload;
    },
    setOtpTransactionType: (
      state: PaymentState,
      action: PayloadAction<any>
    ) => {
      state.otpTransactionType = action.payload;
    },
    setPendingTransactionsResponse: (
      state: PaymentState,
      action: PayloadAction<any>
    ) => {
      state.pendingTransactionsResponse = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(POST_TRANSACTION_VALIDATE_ACTION.pending, (state) => {
        triggerTransactionValidatePending(state);
      })
      .addCase(
        POST_TRANSACTION_VALIDATE_ACTION.fulfilled,
        (state, { payload }): void =>
          triggerTransactionValidateSuccess(state, payload)
      )
      .addCase(
        POST_TRANSACTION_VALIDATE_ACTION.rejected,
        (state, { payload }): void =>
          triggerTransactionValidateFailed(state, payload)
      )
      .addCase(GET_TRANSACTION_STATUS_ACTION.pending, (state) => {
        triggerGetTransactionStatusPending(state);
      })
      .addCase(
        GET_TRANSACTION_STATUS_ACTION.fulfilled,
        (state, { payload }): void =>
          triggerGetTransactionStatusSuccess(state, payload)
      )
      .addCase(
        GET_TRANSACTION_STATUS_ACTION.rejected,
        (state, { payload }): void =>
          triggerGetTransactionStatusFailed(state, payload)
      )
      .addCase(POST_VALIDATE_PIN_ACTION.pending, (state) => {
        triggerGetTransactionStatusPending(state);
      })
      .addCase(POST_VALIDATE_PIN_ACTION.fulfilled, (state, { payload }): void =>
        triggerGetTransactionStatusSuccess(state, payload)
      )
      .addCase(POST_VALIDATE_PIN_ACTION.rejected, (state, { payload }): void =>
        triggerGetTransactionStatusFailed(state, payload)
      )
      .addCase(GET_ACCOUNT_NAME_ENQUIRY_ACTION.pending, (state) => {
        triggerGetNameEquiryPending(state);
      })
      .addCase(
        GET_ACCOUNT_NAME_ENQUIRY_ACTION.fulfilled,
        (state, { payload }): void =>
          triggerGetNameEquirySuccess(state, payload)
      )
      .addCase(
        GET_ACCOUNT_NAME_ENQUIRY_ACTION.rejected,
        (state, { payload }): void => triggerGetNameEquiryFailed(state, payload)
      )
      .addCase(GET_BENEFICIARY_LIST_ACTION.pending, (state) => {
        state.loading = ["GET_BENEFICIARY_LIST"];
        triggerGetBeneficiaryListPending(state);
      })
      .addCase(
        GET_BENEFICIARY_LIST_ACTION.fulfilled,
        (state, { payload }): void =>
          triggerGetBeneficiaryListSuccess(state, payload)
      )
      .addCase(
        GET_BENEFICIARY_LIST_ACTION.rejected,
        (state, { payload }): void =>
          triggerGetBeneficiaryListFailed(state, payload)
      )
      .addCase(GET_GROUP_LIST_ACTION.pending, (state) => {
        triggerGetGroupListPending(state);
      })
      .addCase(GET_GROUP_LIST_ACTION.fulfilled, (state, { payload }): void =>
        triggerGetGroupListSuccess(state, payload)
      )
      .addCase(GET_GROUP_LIST_ACTION.rejected, (state, { payload }): void =>
        triggerGetGroupListFailed(state, payload)
      )
      .addCase(POST_FETCH_TRANSACTION_LIST_ACTION.pending, (state) => {
        state.loading = ["POST_FETCH_TRANSACTION_LIST"];
      })
      .addCase(
        POST_FETCH_TRANSACTION_LIST_ACTION.fulfilled,
        (state, { payload }): void => triggerTransactionList(state, payload)
      )
      .addCase(
        POST_FETCH_TRANSACTION_LIST_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_FETCH_TRANSACTION_LIST"
          );
        }
      )
      .addCase(POST_FETCH_TRANSACTION_DETAIL_ACTION.pending, (state) => {
        state.loading = ["POST_FETCH_TRANSACTION_DETAIL"];
      })
      .addCase(
        POST_FETCH_TRANSACTION_DETAIL_ACTION.fulfilled,
        (state, { payload }): void => triggerTransactionDetail(state, payload)
      )
      .addCase(
        POST_FETCH_TRANSACTION_DETAIL_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_FETCH_TRANSACTION_DETAIL"
          );
        }
      )
      .addCase(POST_FETCH_COLLECTIONS_DETAIL_ACTION.pending, (state) => {
        state.loading = ["POST_FETCH_COLLECTION_DETAIL"];
      })
      .addCase(
        POST_FETCH_COLLECTIONS_DETAIL_ACTION.fulfilled,
        (state, { payload }): void => triggerCollectionDetail(state, payload)
      )
      .addCase(
        POST_FETCH_COLLECTIONS_DETAIL_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_FETCH_TRANSACTION_DETAIL"
          );
        }
      )
      // Bills payment details
      .addCase(POST_FETCH_BILLS_PAYMENT_DETAIL_ACTION.pending, (state) => {
        state.loading = ["POST_FETCH_BILLS_PAYMENT_DETAIL"];
      })
      .addCase(
        POST_FETCH_BILLS_PAYMENT_DETAIL_ACTION.fulfilled,
        (state, { payload }): void => triggerBillsPaymentDetail(state, payload)
      )
      .addCase(
        POST_FETCH_BILLS_PAYMENT_DETAIL_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_FETCH_BILLS_PAYMENT_DETAIL"
          );
        }
      )
      // GET_PENDING_USERS_REQUEST_DETAILS
      .addCase(GET_PENDING_USERS_REQUEST_DETAILS_ACTION.pending, (state) => {
        state.loading = ["GET_PENDING_USERS_REQUEST_DETAILS"];
      })
      .addCase(
        GET_PENDING_USERS_REQUEST_DETAILS_ACTION.fulfilled,
        (state, { payload }): void => triggerUserRequestDetails(state, payload)
      )
      .addCase(
        GET_PENDING_USERS_REQUEST_DETAILS_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "GET_PENDING_USERS_REQUEST_DETAILS"
          );
        }
      )
      // POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS
      .addCase(
        POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS_ACTION.pending,
        (state) => {
          state.loading = ["POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS"];
        }
      )
      .addCase(
        POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS_ACTION.fulfilled,
        (state, { payload }): void =>
          triggerSoftTokenRequestDetails(state, payload)
      )
      .addCase(
        POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS"
          );
        }
      )
      .addCase(PUT_APROVE_OR_REJECT_TRANSACTION_ACTION.pending, (state) => {
        state.loading = ["PUT_APROVE_OR_REJECT_TRANSACTION"];
      })
      .addCase(
        PUT_APROVE_OR_REJECT_TRANSACTION_ACTION.fulfilled,
        (state, { payload }): void =>
          triggerAcceptAndRejectTransaction(state, payload)
      )
      .addCase(
        PUT_APROVE_OR_REJECT_TRANSACTION_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "PUT_APROVE_OR_REJECT_TRANSACTION"
          );
        }
      )
      //POST_REVIEW_USER_REQUEST
      .addCase(POST_REVIEW_USER_REQUEST_ACTION.pending, (state) => {
        state.loading = ["POST_REVIEW_USER_REQUEST"];
      })
      .addCase(
        POST_REVIEW_USER_REQUEST_ACTION.fulfilled,
        (state, { payload }): void => triggerReviewUserRequest(state, payload)
      )
      .addCase(
        POST_REVIEW_USER_REQUEST_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_REVIEW_USER_REQUEST"
          );
        }
      )

      //POST_TRANSFER_TRANSACTION
      .addCase(POST_TRANSFER_TRANSACTION_ACTION.pending, (state) => {
        state.loading = ["POST_TRANSFER_TRANSACTION"];
      })
      .addCase(
        POST_TRANSFER_TRANSACTION_ACTION.fulfilled,
        (state, { payload }): void =>
          triggerPostTransferTransaction(state, payload)
      )
      .addCase(
        POST_TRANSFER_TRANSACTION_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_TRANSFER_TRANSACTION"
          );
        }
      )

      //PUT_COMPLETE_TRANSFER_TRANSACTION
      .addCase(PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION.pending, (state) => {
        state.loading = ["PUT_COMPLETE_TRANSFER_TRANSACTION"];
      })
      .addCase(
        PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION.fulfilled,
        (state, { payload }): void =>
          triggerPutCompleteTransferTransaction(state, payload)
      )
      .addCase(
        PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "PUT_COMPLETE_TRANSFER_TRANSACTION"
          );
        }
      )

      //PUT_COMPLETE_TRANSFER_TRANSACTION
      .addCase(GET_RESEND_TRANSFER_OTP_ACTION.pending, (state) => {
        state.loading = ["GET_RESEND_TRANSFER_OTP"];
      })
      .addCase(
        GET_RESEND_TRANSFER_OTP_ACTION.fulfilled,
        (state, { payload }): void =>
          triggerPutCompleteTransferTransaction(state, payload)
      )
      .addCase(
        GET_RESEND_TRANSFER_OTP_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "GET_RESEND_TRANSFER_OTP"
          );
        }
      )

      //POST_FETCH_ALL_BULK_TRANSFER_ACTION
      .addCase(POST_FETCH_ALL_BULK_TRANSFER_ACTION.pending, (state) => {
        state.loading = ["POST_FETCH_ALL_BULK_TRANSFER"];
      })
      .addCase(
        POST_FETCH_ALL_BULK_TRANSFER_ACTION.fulfilled,
        (state, { payload }): void =>
          triggerPostFetchAllBulkTransaction(state, payload)
      )
      .addCase(
        POST_FETCH_ALL_BULK_TRANSFER_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_FETCH_ALL_BULK_TRANSFER"
          );
        }
      )

      //POST_FETCH_ONE_BULK_TRANSFER_ACTION
      .addCase(POST_FETCH_ONE_BULK_TRANSFER_ACTION.pending, (state) => {
        state.loading = ["POST_FETCH_ONE_BULK_TRANSFER"];
      })
      .addCase(
        POST_FETCH_ONE_BULK_TRANSFER_ACTION.fulfilled,
        (state, { payload }): void =>
          triggerPostFetchOneBulkTransaction(state, payload)
      )
      .addCase(
        POST_FETCH_ONE_BULK_TRANSFER_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_FETCH_ONE_BULK_TRANSFER"
          );
        }
      )

      //POST_FETCH_ONE_BULK_TRANSFER_ACTION
      .addCase(POST_CREATE_ACCOUNT_STATEMENT_ACTION.pending, (state) => {
        state.loading = ["POST_CREATE_ACCOUNT_STATEMENT"];
      })
      .addCase(
        POST_CREATE_ACCOUNT_STATEMENT_ACTION.fulfilled,
        (state, { payload }): void =>
          triggerPostCreateAccountStatement(state, payload)
      )
      .addCase(
        POST_CREATE_ACCOUNT_STATEMENT_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_CREATE_ACCOUNT_STATEMENT"
          );
        }
      )

      // GET_BILL_CATEGORIES_LIST_ACTION
      .addCase(GET_BILL_CATEGORIES_LIST_ACTION.pending, (state) => {
        state.loading = ["GET_BILL_CATEGORIES_LIST"];
      })
      .addCase(
        GET_BILL_CATEGORIES_LIST_ACTION.fulfilled,
        (state, { payload }): void => triggerBillCategoriesList(state, payload)
      )
      .addCase(
        GET_BILL_CATEGORIES_LIST_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "GET_BILL_CATEGORIES_LIST"
          );
        }
      )

      //POST_VALIDATE_BILL_REFERENCE_ACTION
      .addCase(POST_VALIDATE_BILL_REFERENCE_ACTION.pending, (state) => {
        state.loading = ["POST_VALIDATE_BILL_REFERENCE"];
      })
      .addCase(
        POST_VALIDATE_BILL_REFERENCE_ACTION.fulfilled,
        (state, { payload }): void =>
          triggerValidateBillReference(state, payload)
      )
      .addCase(
        POST_VALIDATE_BILL_REFERENCE_ACTION.rejected,
        (state, { payload }): void => {
          state.loading = removeLoadingState(
            state.loading,
            "POST_VALIDATE_BILL_REFERENCE"
          );
        }
      )

      //POST_CREATE_BILL_ACTION
      .addCase(POST_CREATE_BILL_ACTION.pending, (state) => {
        state.loading = ["POST_CREATE_BILL_ACTION"];
      })
      .addCase(POST_CREATE_BILL_ACTION.fulfilled, (state, { payload }): void =>
        triggerCreateBill(state, payload)
      )
      .addCase(POST_CREATE_BILL_ACTION.rejected, (state, { payload }): void => {
        state.loading = removeLoadingState(
          state.loading,
          "POST_CREATE_BILL_ACTION"
        );
      });
  },
});

// Selectors
const selectPayment = (state: RootState) => state.payment;

// Reducers and actions
export const {
  sendPaymentData,
  setTransactionStatusByCorrelationIdResponse,
  setTransferResponseMessage,
  resetPaymentsData,
  setSkipAndTake,
  setBulkTransferType,
  setTriggerMultipleAcctEnquiryAgain,
  setPrevBulkTransferDestinationsLength,
  setValidateBulktransferAccounts,
  setNipChargesResponse,
  setBulkMultipleAccountNameEnquiry,
  setBulkMultipleAccountNameEnquiryStatusResponse,
  setBulkTransferPayloadReferenceIntra,
  setBulkTranferIntra,
  setBulkOtpMessage,
  setBulkTranferInter,
  setPendingTransactionsResponse,
  setBulkListPageNumber,
  setCorrelationId,
  setAllBulkTransactionList,
  setOneBulkTransactionList,
  setOneBulkTransactionID,
  setBulkHistoryFilter,
  setTransactionReference,
  setFundMyAcctSelectedAccout,
  setAccountStatementPayload,
  setSelectedAccountStatement,
  setAccountStatementPDFURL,
  setAccountStatementType,
  setBeneficiaryDetails,
  setBeneficiaryBankDetails,
  setPurposesAndCharges,
  setTransferDetails,
  setFxExchangeRate,
  setHistory,
  setHistorySkipAndTake,
  setSelectedTransferBeneficiary,
  setRegularTransferBeneficiaries,
  setBeneficiaries,
  setSelectedBeneficiary,
  setSelectedFxTransaction,
  setFxTransferStatus,
  setFxTransactionDetails,
  setPendingFxTransferDetails,
  setLoadingState,
  setActiveSelectedBill,
  setAllBillList,
  setSelectedBillList,
  setActiveSelectedSubBill,
  setPurchaseBillStatus,
  setValidateBillResponse,
  setUserRequest,
  setResponseCode,
  setOtpTransactionType,
  setPendingCollectionDetails,
  setPendingBillPaymentDetails,
} = paymentSlice.actions;

//App Redux State
export const usePaymentState = () => useAppSelector(selectPayment);

export default paymentSlice.reducer;
