import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IFlightsState } from "../interfaces";
import { RootState } from "../store";
import { useAppSelector } from "../../../../hooks/useReduxHook";

const dataObject = {
  loading: [],
  flightType: "",
  searchFlightPayload: {
    flightSearchType: "",
    ticketclass: "",
    adults: 0,
    children: 0,
    infants: 0,
    itineraries: [
      {
        departure: "",
        destination: "",
        departureDate: "",
        ticketclass: "",
      },
    ],
    targetCurrency: "",
  },
  flightSearchResponse: {},
  selectFlightResponse: {},
  flightFilters: {
    prices: "asc",
    airline: [],
    stops: "all",
  },
  selectedPassenger: "",
  passengerFormDetails: [],
  authPayload: {},
  authType: "",
  validateFlightResponse: {},
  validateFlightPayload: {},
  completeWebTransferWithOtpPayload: {},
  completeWebTransferWithOtpResponse: {},
  receiptDetailsResponse: {},
} as IFlightsState;

export const initialState: IFlightsState = { ...dataObject };

export const flightsSlice = createSlice({
  name: "flights",
  initialState,

  reducers: {
    resetFlightsState: (): IFlightsState => initialState,
    setLoadingState: (
      state: IFlightsState,
      action: PayloadAction<any>
    ): void => {
      state.loading = action.payload;
    },
    setFlightType: (state: IFlightsState, action: PayloadAction<any>) => {
      state.flightType = action.payload;
    },
    setSearchFlightPayload: (
      state: IFlightsState,
      action: PayloadAction<any>
    ) => {
      state.searchFlightPayload = action.payload;
    },
    setFlightSearchResponse: (
      state: IFlightsState,
      action: PayloadAction<any>
    ) => {
      state.flightSearchResponse = action.payload;
    },
    setSelectFlightResponse: (
      state: IFlightsState,
      action: PayloadAction<any>
    ) => {
      state.selectFlightResponse = action.payload;
    },
    setFlightFilters: (state: IFlightsState, action: PayloadAction<any>) => {
      state.flightFilters = action.payload;
    },
    setSelectedPassenger: (
      state: IFlightsState,
      action: PayloadAction<any>
    ) => {
      state.selectedPassenger = action.payload;
    },
    setPassengerFormDetails: (
      state: IFlightsState,
      action: PayloadAction<any>
    ) => {
      state.passengerFormDetails = action.payload;
    },
    setAuthPayload: (state: IFlightsState, action: PayloadAction<any>) => {
      state.authPayload = action.payload;
    },
    setAuthType: (state: IFlightsState, action: PayloadAction<any>) => {
      state.authType = action.payload;
    },
    setValidateFlightResponse: (
      state: IFlightsState,
      action: PayloadAction<any>
    ) => {
      state.validateFlightResponse = action.payload;
    },
    setValidateFlightPayload: (
      state: IFlightsState,
      action: PayloadAction<any>
    ) => {
      state.validateFlightPayload = action.payload;
    },
    setCompleteWebTransferWithOtpPayload: (
      state: IFlightsState,
      action: PayloadAction<any>
    ) => {
      state.completeWebTransferWithOtpPayload = action.payload;
    },
    setCompleteWebTransferWithOtpResponse: (
      state: IFlightsState,
      action: PayloadAction<any>
    ) => {
      state.completeWebTransferWithOtpResponse = action.payload;
    },
    setReceiptDetailsResponse: (
      state: IFlightsState,
      action: PayloadAction<any>
    ) => {
      state.receiptDetailsResponse = action.payload;
    },
  },
});

// Selectors
const selectFlights = (state: RootState) => state.flights;

// Reducers and actions
export const {
  resetFlightsState,
  setLoadingState,
  setFlightType,
  setFlightFilters,
  setSearchFlightPayload,
  setFlightSearchResponse,
  setSelectFlightResponse,
  setSelectedPassenger,
  setPassengerFormDetails,
  setAuthPayload,
  setAuthType,
  setValidateFlightResponse,
  setValidateFlightPayload,
  setCompleteWebTransferWithOtpPayload,
  setCompleteWebTransferWithOtpResponse,
  setReceiptDetailsResponse,
} = flightsSlice.actions;

//App Redux State
export const useFlightsState = () => useAppSelector(selectFlights);

export default flightsSlice.reducer;
