import { ChevronLeftIcon } from "@heroicons/react/24/solid";

import { Button, Card } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as FourUserIcon } from "assets/svgs/FailedTrans.svg";
import { useAirtimeHandleRefresh } from "hooks/apis/AirtimeAndData/useAirtimePresetAmount";

const FailedTransData = () => {
  const {
    AirtimeHandleRefreshData,
    AirtimeHandleRefreshLoading,
    AirtimeHandleRefreshError,
    AirtimeHandleRefresh,
  } = useAirtimeHandleRefresh();
  const location = useLocation();
  const correlationId = location.state?.AirtimeRequestData?.data?.correlationId;
  const navigate = useNavigate();
  const handleRefresh: any = () => {
    AirtimeHandleRefresh(correlationId);
  };
  const handleGotoDashboard = () => {
    navigate("/user-dashboard");
  };
  return (
    <div>
      <div>
        <div className="grid grid-cols-1 md:grid-cols-8">
          <div className="col-span-1"></div>

          <Card className="w-full md:w-[768px] col-span-3 bg-white pb-14 dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
            <div>
              <div className="flex items-center justify-between">
                {/* <ChevronLeftIcon
              onClick={() => navigate(-1)}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            /> */}
                <div
                  className="text-[#4D4D4D] text-xl text-center"
                  id="banktransfer_heading"
                ></div>
                <div></div>
              </div>
            </div>
            <hr />
            <div>
              <div className="flex flex-wrap justify-center gap-4 ">
                <div className="flex justify-center relative w-full lg:max-w-sm mb-2">
                  <FourUserIcon fill="inherit" />
                </div>
                <h5 className="flex justify-center relative w-full lg:max-w-sm mb-1 text-red-500 font-bold text-2xl">
                  Transaction Failed
                </h5>
                <p className="flex justify-center relative w-full lg:max-w-sm mb-1 text-lg">
                  Please try again
                </p>

                <div className="relative lg:max-w-sm w-full flex items-center justify-center mt-2">
                  <Button
                    id="proceed-to-dashboard_btn"
                    onClick={handleGotoDashboard}
                    className="hover:text-white dark:hover:!text-white dark:hover:bg-primary w-full md:w-[188px] bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:border-primary focus:text-white"
                  >
                    Return to dashboard
                  </Button>

                  <div style={{ marginLeft: "12px" }}></div>

                  <Button
                    id="proceed-to-dashboard_btn"
                    onClick={() => navigate("/Data/NewDataPuchase")}
                    className="hover:text-white dark:hover:!text-white dark:hover:bg-primary w-full md:w-[188px] bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:border-primary focus:text-white"
                  >
                    Try Again
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FailedTransData;
