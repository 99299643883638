import {
  FormikButton,
  ReactSuspenceWrapper,
  TwoSplitColumn,
} from "../../alat-components-library";
import pageStyles from "../pages.module.scss";
import Arrow from "../../alat-components-library/assets/images/auth/arrow.svg";
import HeloWelcomeBack from "../../assets/svgs/hello-welcome-back.svg";
import BankIcon from "../../assets/icons/bank-icon.svg";
import InternetBankIcon from "../../assets/icons/internet-bank.svg";
import ReactivateAccountIcon from "../../assets/icons/reactivate-account.svg";
import {
  DefaultImage,
  PasswordInput,
  TextInput,
} from "../../alat-components-library";
import { useLoginController } from "../../controllers/Login/LoginController";
import CenteredModal from "../../alat-components-library/Modal/CenterModal";
import { Formik, Form } from "formik";
import { LoginValidation } from "../../utils/validations/auth";
import VideoBg from "../../components/Login/VideoBg";
import { ReactComponent as PendingIcon } from "../../alat-components-library/assets/images/modals/warning-error-icon.svg";
import { setCenteredModal } from "../../react-wrappers/stores/redux/app/slices";
import { useNavigate } from "react-router-dom";
import PageLoader from "components/Loader/PageLoader";
import { LoginForm } from "./components/LoginForm";
import CustomLink from "./components/CustomLink";
import { ONBOARDING_URL } from "routers/onboardingRoutes";
import { ACCOUNT_REACTIVATION_URL } from "routers/accountReactivationRoutes";
import { saveOnboardingData } from "react-wrappers/stores/redux/onboarding/slices";

const Login = () => {
  // Customer Hook Controller
  const { appState, authState, dispatch } = useLoginController();

  // hooks
  const navigate = useNavigate();

  const sigUpUserType = {
    newCustomer: false,
    existingCustomer: false,
  };
  const handleSignUpShortcut = (user: string) => {
    const isOldUser = user === "old";
    isOldUser
      ? (sigUpUserType.existingCustomer = true)
      : (sigUpUserType.newCustomer = true);
    dispatch(
      saveOnboardingData({
        ...sigUpUserType,
      })
    );
  };
  return (
    <div>
      {!!authState.loading.includes("POST_LOGIN") && (
        <PageLoader message="Authenticating..." />
      )}
      <ReactSuspenceWrapper>
        <TwoSplitColumn
          leftVariant={`${pageStyles.twosplit__authOne} hidden lg:flex w-full md:w-6/12 h-[100vh] flex justify-center overflow-hidden`}
          rightVariant={`${pageStyles.twosplit__auth} w-full flex lg:w-6/12 h-[100vh] bg-afb-gray-one md:overflow-y-scroll md:overflow-hidden`}
          // rightVariant={`${pageStyles.twosplit__auth} w-full flex lg:w-6/12 h-[100vh] bg-afb-gray-one overflow-hidden`}
        >
          <VideoBg />
          <div
            className={`animate__animated animate__fadeIn h-[85vh] w-full my-[3vh] sm:mx-[12vh] md:mx-[4vh] lg:mx-[10vh]`}
          >
            <DefaultImage
              src={Arrow}
              alt={"arrow"}
              containerVariant={"lg:-ml-[6vh]"}
              variant={"h-[7.5vh]"}
            />
            <div className="bg-white w-full rounded-xl mt-[4vh] px-[6vh] py-[5vh] h-[80vh]">
              <DefaultImage
                src={HeloWelcomeBack}
                alt={"arrow"}
                containerVariant={""}
                variant={"h-[9.5vh]"}
              />
              <p className="text-[2vh] my-[2vh] text-afb-grey-two">
                Enter your username and password to access your account
              </p>
              <LoginForm />
              <div className="pb-10 flex gap-x-2.5 justify-center">
                <CustomLink
                  path={"/signup/business-type"}
                  handleClick={() => handleSignUpShortcut("new")}
                  icon={
                    <DefaultImage
                      src={BankIcon}
                      containerVariant="flex justify-center h-8 sm:h-9 md:h-auto"
                      alt="Bank Icon"
                    />
                  }
                  text="Open a Corporate Account"
                />
                <CustomLink
                  path={"/signup/account-validation"}
                  handleClick={() => handleSignUpShortcut("old")}
                  icon={
                    <DefaultImage
                      src={InternetBankIcon}
                      alt="Internet Bank Icon"
                      containerVariant="flex justify-center h-8 sm:h-9 md:h-auto"
                    />
                  }
                  text="Register for Internet Banking"
                />
                <CustomLink
                  icon={
                    <DefaultImage
                      containerVariant="flex justify-center h-8 sm:h-9 md:h-auto"
                      src={ReactivateAccountIcon}
                      alt="Reactivate Account Icon"
                    />
                  }
                  path={ACCOUNT_REACTIVATION_URL.VALIDATE_ACCOUNT}
                  text="Reactivate your Account"
                />
              </div>
            </div>
          </div>
        </TwoSplitColumn>
        {appState?.centeredModal?.status === "error" && (
          <CenteredModal
            icon={<PendingIcon />}
            title={appState.centeredModal.title}
            info={appState.centeredModal.info}
            btnText={"Close"}
            showCloseIcon
            handleClick={(e) => {
              dispatch(
                setCenteredModal({
                  status: "",
                  title: "",
                  info: "",
                })
              );
            }}
            handleClose={(e: any) =>
              dispatch(
                setCenteredModal({
                  status: "",
                  title: "",
                  info: "",
                })
              )
            }
          />
        )}
      </ReactSuspenceWrapper>
    </div>
  );
};

export default Login;
