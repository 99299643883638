import React, { useEffect } from "react";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { ReactComponent as SuccessIcon } from "../../../../alat-components-library/assets/images/modals/success-icon.svg";
import { PrimaryButton } from "alat-components-library";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { useFetchRejectedDocuments } from "hooks/apis/pendingActions/useFetchRejectedDocuments";
import { useNextScreen } from "./NextScreen";
import PageLoader from "components/Loader/PageLoader";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";

export const SuccessfulResubmission = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const title = location?.state?.title;
  const {
    user: { regNumber, businessType },
  } = useAuthState();
  const { handleNextScreen } = useNextScreen();
  const { fetchLoading, rejectedDocuments, handleFetchRejectedDocuments } =
    useFetchRejectedDocuments();

  useEffect(() => {
    handleFetchRejectedDocuments({ regNumber, businessType });
  }, []);

  return (
    <DashboardWrapper>
      {fetchLoading && <PageLoader message="" />}
      <ReusableCard
        variant="md:w-[751px] w-full mx-auto"
        cardTitle={title ?? ""}
        showGoBackIcon
      >
        <div className="flex flex-col justify-center items-center w-full px-6 space-y-8 py-6">
          <SuccessIcon />
          <div className="space-y-4 flex flex-col justify-center items-center">
            <h2 className="text-xl font-semibold tracking-tighter leading-6">
              Success!
            </h2>
          </div>
          <div className="flex flex-col w-full items-center">
            {rejectedDocuments?.length > 0 && (
              <PrimaryButton
                id="proceed-to-dashboard_btn"
                containerVariant={
                  "w-auto h-10 rounded-md flex justify-center cursor-pointer bg-lib-alat-red px-2"
                }
                labelText={"Go to next pending action"}
                handleClick={() => {
                  handleNextScreen(rejectedDocuments);
                }}
                variant={
                  "text-white tracking-tighter text-sm leading-[28px] font-semibold "
                }
              />
            )}

            <PrimaryButton
              id="proceed-to-dashboard_btn"
              containerVariant={`w-auto px-2 h-10 rounded-md flex justify-center cursor-pointer ${rejectedDocuments?.length > 0 ? "bg-white" : "bg-lib-alat-red"}`}
              labelText={"Return to dashboard"}
              handleClick={() => {
                navigate("/user-dashboard");
              }}
              variant={`${rejectedDocuments?.length > 0 ? "text-lib-alat-red" : "text-white"} tracking-tighter text-sm leading-[28px] font-semibold `}
            />
          </div>
        </div>
      </ReusableCard>
    </DashboardWrapper>
  );
};
