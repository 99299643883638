import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import {
  POST_PAY_WITH_CARD,
  POST_PAY_WITH_CARD_UNREG_BIZ,
} from "../../../utils/apis/routes";
import { IPayWithCard } from "../../../types/global";
import axiosStatus from "utils/apis/axios/errorStatus";
import { ToastNotify } from "helpers/toastNotify";

export const usePayWithCard = () => {
  const [userPayWithCardStatus, setPayWithCardStatus] =
    useState<boolean>(false);
  const [userPayWithCardLoading, setPayWithCardLoading] =
    useState<boolean>(false);
  const [userPayWithCardData, setPayWithCardData] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const handlePayWithCard = async (payload: IPayWithCard) => {
    const url = !payload?.accountType
      ? POST_PAY_WITH_CARD_UNREG_BIZ
      : POST_PAY_WITH_CARD;
    try {
      setPayWithCardLoading(true);
      const res = await makeRequest({
        method: "post",
        route: url,
        body: payload,
        originType: "onboarding",
      });
      //Map the payload
      if (res?.status !== 200) {
        if (res?.data?.data?.hasError) {
          ToastNotify({
            type: "error",
            message:
              res?.data?.data?.responseMessage?.actionMessage ||
              res?.data?.data?.responseMessage?.title,
            position: "top-right",
          });
        } else {
          axiosStatus(res?.data);
        }
        return;
      }
      setPayWithCardStatus(true);
      setPayWithCardData(res);

      setPayWithCardLoading(false);
    } catch (error) {
      ToastNotify({
        type: "error",
        message: "An error occured while making payment, please try again",
        position: "top-right",
      });
      setPayWithCardLoading(false);
    }
  };

  return {
    userPayWithCardStatus,
    userPayWithCardLoading,
    userPayWithCardData,
    handlePayWithCard,
  };
};
