import React, { useEffect } from "react";
import { ReactComponent as NotFound } from "../../assets/svgs/page-not-found.svg";
import { FormikButton } from "alat-components-library";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";

const PageNotFound = () => {
  const navigate = useNavigate();
  const auth = useAuthState();
  const isAuthenticated = auth?.token !== null ? true : false;

  useEffect(() => {
    isAuthenticated && navigate("/signin");
  }, [isAuthenticated]);

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <center>
        <NotFound className="lg:h-72 lg:w-72 w-36 h-36" />
        <p className="mt-5 text-2xl lg:text-3xl font-semibold">
          Page not found!
        </p>
        <div className="mt-5" onClick={() => navigate(-1)}>
          <FormikButton
            labelText="Go Home"
            containerVariant="lg:w-72 w-36 h-10 rounded-md flex justify-center itmes-center"
          />
        </div>
      </center>
    </div>
  );
};

export default PageNotFound;
