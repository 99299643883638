import React from "react";
import ReactDOM from "react-dom/client";
import "./tailwind.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "./react-wrappers/stores/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactSuspenceWrapper } from "alat-components-library";
import App from "App";
import ErrorBoundary from "ErrorBoundary";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ReactSuspenceWrapper>
    <Provider store={store}>
      <ToastContainer />
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </ReactSuspenceWrapper>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
