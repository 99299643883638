import React from "react";
import { ReactComponent as PrevBtn } from "../../../assets/svgs/table-left.svg";
import { ReactComponent as PrevActiveBtn } from "../../../assets/svgs/table-active-left.svg";
import { ReactComponent as NextBtn } from "../../../assets/svgs/table-right.svg";
import { ReactComponent as NextActiveBtn } from "../../../assets/svgs/table-active-right.svg";

interface Props {
  paginationVariant?: string;
  dataToShow?: any;
  handleNext?: any;
  handlePrev?: any;
}

export const Pagination = ({
  paginationVariant = "bg-white h-20 w-full flex flex-row justify-between items-center px-4 rounded-b-sm",
  dataToShow,
  handleNext,
  handlePrev,
}: Props) => {
  // console.log("dataToShow", dataToShow);
  const totalNumber = dataToShow?.totalItems;

  // const count = dataToShow?.count <= 10 && 10

  const totalPages = Math.ceil(
    Number(dataToShow?.totalItems || dataToShow?.totalItemCount) / 10
  );

  const handleNextChange = () => handleNext(dataToShow);

  const handlePrevChange = () => handlePrev(dataToShow);

  const from = dataToShow?.skip;
  const to = dataToShow?.take;

  return (
    <div className={paginationVariant}>
      <div className="flex flex-row justify-start items-center space-x-2">
        <p className="text-sm text-[#606060]">
          {`Showing ${from} to ${to} of ${totalNumber} result(s)`}
        </p>
      </div>
      <div className="flex flex-row justify-start items-center space-x-2">
        <div className="flex gap-3 items-center">
          <div
            id="prev_page_btn"
            className="cursor-pointer disabled:cursor-none"
            onClick={() => {
              if (dataToShow?.currentPage > 1) return handlePrevChange();
              return null;
            }}
          >
            {dataToShow?.currentPage > 1 ? <PrevActiveBtn /> : <PrevBtn />}
          </div>
          <div>
            <p className="flex space-x-2 text-sm items-center">
              <span
                className="border rounded px-3.5 py-1.5"
                id="current_page_text"
              >
                {" "}
                {dataToShow?.currentPage}{" "}
              </span>
              <span>{"out of"}</span>
              <span className="text-[#A90836]" id="total_pages_text">
                {totalPages}
              </span>
            </p>
          </div>
          <div
            id="next_page_btn"
            className="cursor-pointer"
            onClick={() => {
              if (dataToShow?.currentPage < totalPages)
                return handleNextChange();
              return null;
            }}
          >
            {dataToShow?.currentPage < totalPages ? (
              <NextActiveBtn />
            ) : (
              <NextBtn />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
