export enum UserPermissionEnum {
  DownloadAccountStatement = 1,
  ViewCreditTransaction = 2,
  ViewDebitTransaction = 3,
  ViewBalance = 4,
}

export enum UserPermissionDisplayEnum {
  "Download Account Statement" = 1,
  "View Credit Transaction" = 2,
  "View Debit Transaction" = 3,
  "View Balance" = 4,
}
