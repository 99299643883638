import { handleAccountValidation } from "./../../components/Payments/BankTransfer/NewTransfer/utils/index";
import { useAppDispatch } from "hooks/useReduxHook";
import { useEffect, useState } from "react";
import {
  setBulkTranferIntra,
  setBulkTranferInter,
  usePaymentState,
  setTriggerMultipleAcctEnquiryAgain,
  setBulkTransferPayloadReferenceIntra,
} from "../../react-wrappers/stores/redux/payments/slices";
import { generateCharge } from "utils/helperFunctions";
import { useAccountValidation } from "hooks/apis/Payment/useAccountValidation";

export const useExcelModification = (
  activeTransaction: any,
  setEditModal: any,
  setDeleteModal: any,
  screenName?: string
) => {
  // console.log("active", activeTransaction)

  const paymentState = usePaymentState();
  const dispatch = useAppDispatch();

  const { handleAccountValidation } = useAccountValidation();

  let bulkTransferPayload = paymentState?.bulkTransferPayloadIntra;
  if (screenName === "inter")
    bulkTransferPayload = paymentState?.bulkTransferPayloadInter;

  const transactionType = paymentState?.bulkTransferType?.toLowerCase();
  const validateAccounts = paymentState?.validateBulkTransferAccounts;

  const [initialValueData, setInitialValueData] = useState({
    beneficiaryAccountNumber: "",
    beneficiaryName: "",
    beneficiaryBankName: "",
    narration: "",
    refCode: "",
    amount: "",
  });

  useEffect(() => {
    setInitialValueData({ ...initialValueData, ...activeTransaction });
  }, [activeTransaction]);

  const handleExcelEditSubmit = (values: any, setSubmitting: any) => {
    // console.log("values", values)
    const getUniqueId = `${activeTransaction.beneficiaryAccountNumber}${activeTransaction.beneficiaryName}`;
    const newPayload = bulkTransferPayload?.transferDestinations?.map(
      (item: any) => {
        const id = `${item.beneficiaryAccountNumber}${item.beneficiaryName}`;
        //Validate for Charege and generate
        const transactionFee = generateCharge(
          Number(values.amount),
          transactionType,
          paymentState?.nipCharges,
          item?.beneficiaryBankCode
        );

        if (id === getUniqueId) {
          return {
            ...values,
            amount: Number(values.amount),
            transactionFee: Number(transactionFee),
          };
        }
        return item;
      }
    );

    const payload = {
      transferDestinations: newPayload,
    };

    if (
      validateAccounts &&
      activeTransaction?.status?.toLowerCase() === "failed"
    ) {
      handleAccountValidation(
        {
          oldAccountNumber: activeTransaction?.beneficiaryAccountNumber,
          newAccountNumber: values?.beneficiaryAccountNumber,
          bankCode: activeTransaction?.beneficiaryBankCode,
          amount: Number(values?.amount) ?? 0,
        },
        "bulk"
      );
    } else if (
      validateAccounts &&
      activeTransaction?.status?.toLowerCase() === "pending"
    ) {
      dispatch(setBulkTranferIntra({ ...bulkTransferPayload, ...payload }));
      // Update the reference Bulk Transfer Payload
      dispatch(
        setBulkTransferPayloadReferenceIntra({
          ...paymentState.bulkTransferPayloadIntra,
          ...payload,
        })
      );
      dispatch(setTriggerMultipleAcctEnquiryAgain(true));
    } else {
      dispatch(setBulkTranferIntra({ ...bulkTransferPayload, ...payload }));
    }
    // if (screenName === "intra") dispatch(setBulkTranferIntra({ ...bulkTransferPayload, ...payload }))
    // if (screenName === "inter") dispatch(setBulkTranferInter({ ...bulkTransferPayload, ...payload }))
    setSubmitting(false);
    setEditModal(false);
  };

  const handleDeleteExcel = (item: any) => {
    // const getUniqueId = `${activeTransaction.beneficiaryAccountNumber}${activeTransaction.beneficiaryName}`;
    const getUniqueId = activeTransaction?.rowId;
    // console.log("active", activeTransaction)

    const newPayload = bulkTransferPayload?.transferDestinations?.filter(
      (item: any) => item?.rowId !== getUniqueId
    );
    // console.log("newPayload", newPayload)
    const payload = {
      transferDestinations: newPayload,
    };
    if (validateAccounts) {
      dispatch(setBulkTranferIntra({ ...bulkTransferPayload, ...payload }));
      // Update the reference Bulk Transfer Payload
      dispatch(
        setBulkTransferPayloadReferenceIntra({
          ...paymentState.bulkTransferPayloadIntra,
          ...payload,
        })
      );
      // newPayload?.length && dispatch(setTriggerMultipleAcctEnquiryAgain(true));
    } else {
      dispatch(setBulkTranferIntra({ ...bulkTransferPayload, ...payload }));
    }
    // if (screenName === "intra") dispatch(setBulkTranferIntra({ ...bulkTransferPayload, ...payload }))
    // if (screenName === "inter") dispatch(setBulkTranferInter({ ...bulkTransferPayload, ...payload }))
    setDeleteModal(false);
  };

  return {
    initialValueData,
    handleExcelEditSubmit,
    handleDeleteExcel,
  };
};
