import { Field, useField } from "formik";
import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { HiChevronDown } from "react-icons/hi";
import ErrorIcon from "../../../assets/icons/error-icon.svg";

interface Option {
  id?: number;
  label: string;
  value: any;
  raw?: any;
}

interface DropdownProps {
  name: string;
  options: Option[];
  placeholder?: string;
  label?: string;
  onChange: (name: string, value: any) => void;
  value?: any;
  error?: string;
  hasError?: boolean | any;
  variant?: string;
  disabled?: boolean;
  id?: string;
  idList?: string;
}

export const SelectDrop = ({
  id = "",
  idList = "",
  variant = "w-full h-12 text-lg",
  value,
  name,
  options,
  placeholder = "Select an option",
  label,
  hasError = false,
  error = "",
  disabled = false,
  onChange,
}: DropdownProps) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setSelectedOption(options.find((option) => option.value === value) || null);
  }, [options, value]);

  useLayoutEffect(() => {
    disabled && setIsOpen(false);
  }, [disabled]);

  const handleOptionSelect = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(name, option); // call onChange with the updated value
  };

  const handleDropdownClick = () => {
    disabled == false && setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative">
      <p className="text-sm mb-2.5 text-[#333333]">{label}</p>
      <div className="relative">
        <button
          type="button"
          className={`${variant} 
                flex items-center overflow-hidden px-4 pr-6 py-2 text-left rounded-[5px] border bg-lib-alat-gray-input 
                focus:bg-white focus:outline-none text-[#333333] relative
                border-gray-300
                ${error && "border-red-500"}
            `}
          id={id}
          onClick={handleDropdownClick}
        >
          <p className={`truncate text-sm ${disabled && "text-gray-300"}`}>
            {selectedOption?.label || placeholder}
          </p>
          {disabled === false && (
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <HiChevronDown className="h-5 w-5" />
            </span>
          )}
        </button>
        {error && <p className="text-xs text-red-500">{error}</p>}
        {isOpen && (
          <ul className="absolute z-10 w-full py-1 mt-1 bg-gray-50 focus:bg-white rounded-lg shadow-lg max-h-[150px] overflow-auto">
            {options &&
              options.length > 0 &&
              options.map((option, index) => (
                <li
                  id={`${idList}_${index + 1}`}
                  key={option.id}
                  className="px-4 py-2 text-xs py-4 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleOptionSelect(option)}
                >
                  {option.label}
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};
