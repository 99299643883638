import React, { useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
import { useUploadBlobToAzure } from "hooks/apis/useUploadBlobToAzure";
import PageLoader from "components/Loader/PageLoader";
import PdfComponent, { PDFProps } from "./PDFComponent";

export const staticHtmlString = ({
  applicantName,
  duration,
  bankName,
  address,
  accountNo,
  phoneNumber,
  refereeName,
  refereeAddress,
  signature1,
  signature2,
}: PDFProps) => {
  // console.log("I'm in 2: ", signature1);

  return ReactDOMServer.renderToStaticMarkup(
    <PdfComponent
      applicantName={applicantName}
      duration={duration}
      bankName={bankName}
      address={address}
      accountNo={accountNo}
      phoneNumber={phoneNumber}
      refereeName={refereeName}
      refereeAddress={refereeAddress}
      signature1={signature1}
      signature2={signature2}
    />
  );
};

export async function imageUrlToBase64(url: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const dataUrl = reader.result as string;
      const base64 = dataUrl.split(",")[1];
      resolve(`data:image/png;base64,${base64}`);
    };
    reader.onerror = reject;
  });
}

const PDFGenerator = () => {
  const { blobFiles, handleUploadBlob, isUploadingBlob } = useUploadBlobToAzure(
    "signature",
    "backoffice"
  );

  useEffect(() => {
    if (blobFiles?.url) {
      window.open(blobFiles?.url, "_blank");
    }
  }, [blobFiles]);

  const exportPDF = async () => {
    const pdfElement = document.getElementById("pdf");
    if (!pdfElement) return;

    const base64Url = await imageUrlToBase64("");
    // Render component as a static HTML string
    const staticHtmlString = ReactDOMServer.renderToStaticMarkup(
      <PdfComponent
        applicantName="string"
        duration="string"
        bankName="string"
        address="string"
        accountNo="string"
        phoneNumber="string"
        refereeName="string"
        refereeAddress="string"
        signature1={base64Url}
        signature2="string"
      />
    );

    const options = {
      filename: "newPDF.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2pdf: { scale: 1 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    try {
      const pdfBlob = await html2pdf()
        .set(options)
        .from(staticHtmlString)
        .outputPdf("blob");
      // const pdfUrl = URL.createObjectURL(pdfBlob);
      // console.log('PDF Blob URL: ', pdfUrl);

      // Upload the PDF
      handleUploadBlob(
        new File([pdfBlob], "newPDF.pdf", { type: "application/pdf" }),
        "REFEREE_PDF",
        "",
        "PDF",
        `REFEREE`
      );
    } catch (error) {
      console.error("Error exporting PDF: ", error);
    }
  };

  return (
    <div>
      {isUploadingBlob && <PageLoader message="" />}
      <button onClick={() => exportPDF()}>PRINT PDF</button>
      <div className="relative">
        <header id="pdf" className="absolute top-[-99999px] left-[-99999px]">
          <div className="w-7/8">
            <PdfComponent
              applicantName="Abraham Johnson"
              duration="string"
              bankName="string"
              address="string"
              accountNo="string"
              phoneNumber="string"
              refereeName="string"
              refereeAddress="string"
              signature1="https://fastly.picsum.photos/id/787/500/500.jpg?hmac=O2YdCkSwNnEfSC7ZZlenRggwtQd_93kSrJfdGLHcusg"
              signature2="string"
            />
          </div>
        </header>
      </div>
    </div>
  );
};

export default PDFGenerator;
