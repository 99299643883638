import { useState } from "react";
import { useApiRequest } from "../../../useApiRequest";
import { POST_PENDING_ACCOUNT_STATEMENT_DETAIL } from "../../../../utils/apis/routes";
import { ToastNotify } from "../../../../helpers/toastNotify";

export const initialValues = {
  correlationId: "",
  dateCreated: "",
  debitAccountName: "",
  debitAccountNumber: "",
  endDate: "",
  initiatedByFullName: "",
  initiatedByUserName: "",
  processingFee: 0,
  requestAccountName: "",
  requestAccountNumber: "",
  startDate: "",
  totalAmount: 0,
  type: "",
  vat: 0,
};
export const usePendingAccountStatementDetail = () => {
  //Local States
  const [pendingLoading, setPendingLoading] = useState<boolean>(false);
  const [pendingData, setPendingData] = useState(initialValues);

  //API Request
  const makeRequest = useApiRequest();

  const handlePendingStatementDetails = async (correlationId: string) => {
    setPendingData(initialValues);
    setPendingLoading(true);
    try {
      const res = await makeRequest({
        method: "post",
        route: POST_PENDING_ACCOUNT_STATEMENT_DETAIL,
        originType: "transferservice",
        body: { correlationId },
      });

      //Map the payload
      if (res?.status !== 200) {
        if (res?.data?.data?.hasError) {
          ToastNotify({
            type: "error",
            message: res?.data?.data?.responseMessage?.title,
            position: "top-right",
          });
        } else {
          ToastNotify({
            type: "error",
            message: "Something went wrong",
          });
        }
      }
      if (res?.status === 200) {
        setPendingData(res?.data?.data);
      } else {
        const errMsg =
          res?.data?.responseMessage?.title ||
          "An error occured while fetching details";
        ToastNotify({
          type: "error",
          message: errMsg,
          position: "top-right",
        });
      }

      setPendingLoading(false);
    } catch (error) {
      const errMsg = "An error occured while fetching details";
      ToastNotify({
        type: "error",
        message: errMsg,
        position: "top-right",
      });

      setPendingLoading(false);
    }
  };

  return {
    pendingData,
    pendingLoading,
    handlePendingStatementDetails,
  };
};
