import { createAsyncThunk } from "@reduxjs/toolkit";
import { useApiRequest } from "../../../../hooks/useApiRequest";
import {
  POST_ADMIN_CREATES_USER,
  POST_FETCH_USERS,
  POST_USER_VERIFIES_SECURITY_QUESTION,
  POST_USER_CHANGES_PIN,
  POST_USERS_CHANGE_PASSWORD,
  POST_USER_CREATES_PIN,
  POST_REQUEST_SOFT_TOKEN,
} from "../../../../utils/apis/routes";
import {
  AdminCreatesUserPayload,
  ChangePasswordPayload,
  ChangePinPayload,
  VerifySecurityQuestionPayload,
  FetchUsersPayload,
  CreatePinPayload,
  RequestSoftTokenPayload,
} from "../interfaces";

export const POST_ADMIN_CREATES_USER_ACTION = createAsyncThunk(
  "POST_ADMIN_CREATES_USER",
  async (payload: AdminCreatesUserPayload) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_ADMIN_CREATES_USER,
      body: payload,
      originType: "identity",
    });
  }
);

export const POST_USER_VERIFIES_SECURITY_QUESTION_ACTION = createAsyncThunk(
  "POST_USER_VERIFIES_SECURITY_QUESTION",
  async (payload: VerifySecurityQuestionPayload) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_USER_VERIFIES_SECURITY_QUESTION,
      body: payload,
      originType: "identity",
    });
  }
);

export const POST_USER_CHANGES_PIN_ACTION = createAsyncThunk(
  "POST_USER_CHANGES_PIN",
  async (payload: ChangePinPayload) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_USER_CHANGES_PIN,
      body: payload,
      originType: "identity",
    });
  }
);

export const POST_USER_CREATES_PIN_ACTION = createAsyncThunk(
  "POST_USER_CREATES_PIN",
  async (payload: CreatePinPayload) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_USER_CREATES_PIN,
      body: payload,
      originType: "identity",
    });
  }
);

export const POST_USERS_CHANGE_PASSWORD_ACTION = createAsyncThunk(
  "POST_USERS_CHANGE_PASSWORD",
  async (payload: ChangePasswordPayload) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_USERS_CHANGE_PASSWORD,
      body: payload,
      originType: "identity",
    });
  }
);

export const POST_FETCH_USERS_ACTION = createAsyncThunk(
  "POST_FETCH_USERS",
  async (payload: FetchUsersPayload) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_FETCH_USERS,
      body: payload,
      originType: "identity",
    });
  }
);
export const POST_REQUEST_SOFT_TOKEN_ACTION = createAsyncThunk(
  "POST_REQUEST_SOFT_TOKEN",
  async (payload: RequestSoftTokenPayload) => {
    const makeRequest = useApiRequest();
    return await makeRequest({
      method: "post",
      route: POST_REQUEST_SOFT_TOKEN,
      body: payload,
      originType: "identity",
    });
  }
);
