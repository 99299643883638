import { ToastNotify } from "../../../../helpers/toastNotify";
import { removeLoadingState } from "../../../../helpers/assitFunctions";

export const triggerCreateCardRequest = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "POST_SUBMIT_CARD_REQUEST");

  if (payload.status !== 200) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }

  state.statusMessage = `${payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`;
};

export const triggerCardActivation = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_SUBMIT_CARD_ACTIVATION"
  );

  if (payload.status !== 200) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }

  state.statusMessage = `${payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`;
};

export const triggerCardHotlist = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "POST_SUBMIT_CARD_HOTLIST");

  if (payload.status !== 200) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }

  state.statusMessage = `${payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`;
};

export const triggerCardChangePin = (state: any, payload: any) => {
  state.loading = removeLoadingState(
    state.loading,
    "POST_SUBMIT_CARD_CHANGE_PIN"
  );

  if (payload.status !== 200) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }

  state.statusMessage = `${payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`;
};

export const triggerGetCardAccounts = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "GET_ACCOUNT_CARDS");

  if (payload.status !== 200) {
    return ToastNotify({
      type: "error",
      message: `${payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }
};

export const triggerGetCardHotlistReason = (state: any, payload: any) => {
  state.loading = removeLoadingState(state.loading, "GET_CARD_HOTLIST_REASON");

  if (payload?.payload?.status !== 200) {
    return ToastNotify({
      type: "error",
      message: `${payload?.payload?.data?.data?.responseMessage?.title}. ${payload?.data?.data?.responseMessage?.actionMessage}`,
      position: "top-right",
    });
  }

  state.hotlist_reasons = payload?.payload?.data?.data?.reasons;
};
