import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PendingActionsState } from "../interfaces";

const dataObject = {
  rejectedDocuments: {
    pendingAction: 0,
    pendingActionId: 0,
    cacRegNumber: "",
    actionData: "",
  },
  rejectedRefereeCount: 0,
} as PendingActionsState;
export const initialState: PendingActionsState = { ...dataObject };

export const pendingActionsSlice = createSlice({
  name: "pendingActions",
  initialState,

  reducers: {
    resetPendingActionsData: (): PendingActionsState => initialState,
    savePendingActionsData: (
      state: PendingActionsState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setRejectedRefereeCount: (
      state: PendingActionsState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        rejectedRefereeCount: action.payload,
      };
    },
    saveRejectedDocuments: (
      state: PendingActionsState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        rejectedDocuments: action.payload,
      };
    },
  },
});

// Reducers and actions
export const {
  resetPendingActionsData,
  savePendingActionsData,
  setRejectedRefereeCount,
  saveRejectedDocuments,
} = pendingActionsSlice.actions;

//App Redux State
export default pendingActionsSlice.reducer;
