import PageNotFound from "pages/Dashboard/PageNotFound";
import React from "react";
import { ReactComponent as NotFound } from "./assets/svgs/page-not-found.svg";
import { PrimaryButton } from "alat-components-library";

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col justify-center items-center h-screen">
          <center>
            <NotFound className="lg:h-72 lg:w-72 w-36 h-36" />
            <p className="mt-5 text-2xl lg:text-3xl font-semibold">
              Oops! Something went wrong
            </p>
            <div className="mt-5">
              <PrimaryButton
                id="refresh_btn"
                labelText={"Try again"}
                handleClick={() => window.location.reload()}
                variant={"text-white font-medium text-xs"}
                containerVariant={`w-36 h-10 rounded-md flex justify-center cursor-pointer bg-lib-alat-red`}
              />
            </div>
          </center>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
