import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card, Label } from "flowbite-react";
import { useNavigate, useLocation } from "react-router-dom";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { getCurrencyFormat } from "utils/helperFunctions";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import React, { useEffect, useMemo, useState } from "react";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import { useAirtimeController } from "controllers/AirtimeAndData/AirtimeController";
import Spinners from "components/Spinner/Spinner";
import { FormikButton } from "alat-components-library";
import { Formik, Form } from "formik";
import { NetworkVendorsEnum, TransactionTypeEnum } from "utils/enums";
import { useAirtimeRequest } from "hooks/apis/AirtimeAndData/useAirtimePresetAmount";
import { airtimeValidationSchema } from "./airtimeValidation/airtimeValidation";
import PageLoader from "components/Loader/PageLoader";
import { useAccountsState } from "react-wrappers/stores/redux/accounts/slices";
import { useAppDispatch } from "hooks/useReduxHook";
import { GET_ALL_ACCOUNTS_ACTION } from "react-wrappers/stores/redux/accounts/actions";

interface INewAirtimePurchasesProps {
  amount: any;
  selectedNetwork: any;
  phoneNumber: any;
  pin: any;
  authToken: any;
}

const NewAirtimePurchases = ({
  amount,
  selectedNetwork,
  phoneNumber,
  pin,
  authToken,
}: INewAirtimePurchasesProps) => {
  let authType: any;
  const authState = useAuthState();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const airtimeData = location?.state?.airtimeData;
  const [showInput, setShowInput] = useState(false);

  if (authState?.user?.hasTransactionPin) {
    authType = "pinOtp";
  }

  if (authState?.user?.hasSoftToken) {
    authType = "softToken";
  } else {
    authType = "hardTokenPin";
  }

  if (
    authState?.user?.hasSoftToken === false &&
    authState?.user?.hasTransactionPin === false
  ) {
    authType = "hardTokenPin";
  }

  if (
    authState?.user?.hasSoftToken &&
    authState?.user?.hasTransactionPin === false
  ) {
    authType = "softToken";
  }

  const {
    AirtimeRequestData,
    AirtimeRequestLoading,
    AirtimeRequestError,
    handleAirtimeRequest,
  } = useAirtimeRequest();

  useEffect(() => {
    if (AirtimeRequestData?.data?.correlationId) {
      if (authType === "pinOtp") {
        return navigate("/Airtime/OtpVerification", {
          state: { AirtimeRequestData },
        });
      }
      navigate("/Airtime/ConfirmOtp", {
        state: { AirtimeRequestData },
      });
    }
  }, [AirtimeRequestData]);

  const { presetAmountLoading } = useAirtimeController();
  const { airtimeSelectedAccount } = usePaymentState();
  const accounts = useAccountsState();
  // const accounts = authState.user?.accounts;

  const [accountToDebit, setAccountToDebit] = useState({
    accountId: "",
    currency: "",
    balance: 0,
    accountName: "",
    accountNo: "",
  });
  const activeAccounts = accounts?.allAccounts?.filter(
    (account: any) =>
      account?.accountStatus === "Active" && account?.currency === "NGN"
  );

  const handleAccountError = (airtimeAmount: number, accountBalance: any) => {
    if (accountBalance?.accountNo !== "") {
      if (airtimeAmount > accountBalance?.balance) {
        return "insufficient balance";
      }
      return "";
    }
    return "";
  };

  const handleNetworkProvider = (network: string) => {
    if (network?.toLowerCase()?.includes("mtn")) {
      return NetworkVendorsEnum.MTN;
    }
    if (network?.toLowerCase()?.includes("9mobile")) {
      return NetworkVendorsEnum.NINE_MOBILE;
    }
    if (network?.toLowerCase()?.includes("glo")) {
      return NetworkVendorsEnum.GLO;
    }
    if (network?.toLowerCase()?.includes("airtel")) {
      return NetworkVendorsEnum.AIRTEL;
    }
    return 0;
  };

  // Event handler for input change
  const handleAirtimeOTP = (e: any) => {
    if (handleAccountError(airtimeData?.amount, accountToDebit) !== "") return;

    handleAirtimeRequest({
      sourceAccountName: accountToDebit?.accountName,
      sourceAccountCurrencyCode: accountToDebit?.currency,
      sourceAccountNumber: accountToDebit?.accountNo,
      narration: "",
      airtimeAndDataDestinations: [
        {
          alias: e?.beneficiary,
          amount: airtimeData?.amount,
          phoneNumber: airtimeData?.phonenumber,
          networkVendor: handleNetworkProvider(
            airtimeData?.networkProvidersName
          ),
          networkProviderId: 0,
          dataPackageName: "",
          dataPackageCode: 0,
          saveBeneficiary: showInput,
        },
      ],
      transactionType: TransactionTypeEnum.SingleSignatoryAirtimePurchase,
      transactionPin: e?.pin,
      token: e?.authToken,
      isSoftToken: authState?.user?.hasSoftToken,
    });
  };

  const getOption = (type: string, accounts: any) => {
    return accounts
      ?.map((account: any, index: number) => {
        const availableBalance = getCurrencyFormat(account);
        if (account?.currency?.toLowerCase() === "usd") return;
        if (
          type === "debit" &&
          airtimeSelectedAccount &&
          account?.accountNo === accountToDebit?.accountNo
        )
          return;

        return {
          label: `
        ${account?.accountName} (${account?.accountNo}) - 
        ${new Intl.NumberFormat("ja-JP", {
          style: "currency",
          currency: availableBalance?.currency,
        })
          .format(Number(account?.balance))
          .replace(
            availableBalance?.currency,
            availableBalance?.unicodeCharacter || availableBalance?.currency
          )
          .trim()}
        `,
          value: account?.accountNo,
          id: account?.accountNo,
          raw: JSON.stringify(account),
        };
      })
      ?.filter((x: any) => x !== undefined);
  };

  const accSelectErrorMessage = useMemo(
    () => handleAccountError(airtimeData?.amount, accountToDebit),
    [airtimeData?.amount, accountToDebit?.accountNo]
  );

  const handleRadioChange = () => {
    setShowInput(!showInput);
  };

  useEffect(() => {
    dispatch(GET_ALL_ACCOUNTS_ACTION());
  }, []);

  return (
    <div className="w-full">
      {accounts?.loading?.includes("GET_ALL_ACCOUNTS") && <PageLoader />}
      <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent p-3">
        <div>
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              onClick={() => navigate(-1)}
              //   onClick={() => navigate('/Bills/Airtime/NewAirTime')}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            />
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="banktransfer_heading"
            >
              Confirmation
            </div>
            <div></div>
          </div>
        </div>
        <hr />
        {AirtimeRequestLoading && <PageLoader />}
        <Formik
          initialValues={{
            amount: 0,
            pin: "",
            beneficiary: "",
            authToken: "",
          }}
          onSubmit={handleAirtimeOTP}
          validationSchema={airtimeValidationSchema(authType, showInput)}
          validateOnChange
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
          }) => (
            <Form>
              <div>
                <div className="flex flex-wrap justify-center gap-4 ">
                  <div className="relative w-full lg:max-w-sm mb-2">
                    <Card className="box-border relative w-full lg:max-w-sm mb-2 lg:w-[390px] h-[150px] lg:h-[200px] rounded-lg text-white bg-[image:url('./assets/svgs/card-background.svg')] bg-no-repeat bg-cover">
                      <div className="flex flex-col md:flex-row justify-between items-center">
                        <div className="mb-1 md:mb-0 mt-0 w-full">
                          <div className="text-xs">Phone Number</div>
                          <div className="text-xs" id="nameofbeneficiary_text">
                            {airtimeData?.phonenumber}
                          </div>
                        </div>
                        <div className="mb-1 md:mb-0 w-full">
                          <div className="text-xs text-right">Network</div>
                          <div className="text-xs text-right" id="network_text">
                            {airtimeData?.networkProvidersName}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row justify-between items-center">
                        <div className="mb-1 md:mb-0">
                          <div className="text-xs"></div>
                          <div
                            className="text-xs"
                            id="account-number_text"
                          ></div>
                        </div>
                        <div className="mb-1 md:mb-0 w-full">
                          <div className="text-xs text-right">Amount</div>
                          <div className="text-xs text-right" id="amount_text">
                            ₦{airtimeData?.amount}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>

                  <div className="relative w-full lg:max-w-sm">
                    <div className="mb-2 block text-left">
                      <Label
                        htmlFor="amount"
                        value="Amount"
                        className="!text-[#464646] font-normal text-lg leading-[1rem]"
                      />
                    </div>
                    <div className="relative lg:max-w-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-[#666666]">₦</span>
                      </div>
                      <input
                        name="amount"
                        type="number"
                        id="airtimeInput"
                        className="pl-6 w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm"
                        value={airtimeData?.amount}
                        disabled
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="relative w-full lg:max-w-sm ">
                    <SelectDrop
                      id="accounttodebit_dropdown"
                      idList="accounttodebit"
                      variant="h-12 w-full text-sm"
                      name="accountNumberDebit"
                      label="Select account to debit"
                      placeholder="Select account to debit"
                      value={accountToDebit?.accountNo}
                      options={getOption("debit", activeAccounts)}
                      onChange={(name: string, value: any) =>
                        setAccountToDebit(JSON.parse(value?.raw))
                      }
                      error={accSelectErrorMessage}
                      hasError={accountToDebit?.accountNo === ""}
                    />
                  </div>

                  <div
                    className="relative w-full lg:max-w-sm"
                    hidden={airtimeData?.savedBeneficiary}
                  >
                    <label>
                      <input
                        name="saveBeneficiaryCheck"
                        type="Checkbox"
                        onChange={() => {
                          setFieldValue("beneficiary", "");
                          handleRadioChange();
                        }}
                        checked={showInput}
                        style={{ marginRight: "10px" } as React.CSSProperties}
                      />

                      <Label
                        htmlFor="SaveBeneficiary"
                        value="Save Beneficiary"
                        className=" text-[#464646] dark:!text-[#464646] font-normal text-sm"
                      />
                    </label>

                    {showInput && (
                      <div>
                        <input
                          name="beneficiary"
                          type="text"
                          id="beneficiaryID"
                          onChange={handleChange}
                          value={values?.beneficiary}
                          onBlur={handleBlur}
                          className="pl-6 w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm"
                        />
                        <span className="text-sm text-red-600">
                          {errors?.beneficiary}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="relative w-full lg:max-w-sm">
                    <div className="relative lg:max-w-sm">
                      {authType === "pinOtp" && (
                        <div className="relative lg:max-w-sm">
                          <div className="mb-2 block text-left">
                            <Label
                              htmlFor="pin"
                              value="Enter PIN"
                              className=" text-[#464646] dark:!text-[#464646] font-normal text-sm"
                            />
                          </div>

                          <input
                            id="pin_input"
                            name="pin"
                            type="password"
                            data-auth-type={authType}
                            className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                            value={values?.pin}
                            onChange={handleChange}
                            maxLength={4}
                          />
                          <span className="text-sm text-red-600">
                            {errors?.pin}
                          </span>
                        </div>
                      )}
                      {authType === "softToken" && (
                        <div className="relative lg:max-w-sm">
                          <div className="mb-2 block text-left">
                            <Label
                              htmlFor="authToken"
                              value="Enter Soft Token"
                              className=" !text-[#666666] mb-2 !text-sm !font-normal"
                            />
                          </div>

                          <input
                            id="softToken_input"
                            name="authToken"
                            type="password"
                            data-auth-type={authType}
                            className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                            value={values?.authToken}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            maxLength={6}
                          />
                          <span className="text-sm text-red-600">
                            {errors?.authToken}
                          </span>
                          {""}
                        </div>
                      )}
                      {authType === "hardTokenPin" && (
                        <div className="relative lg:max-w-sm">
                          <div className="mb-2 block text-left">
                            <Label
                              htmlFor="pin"
                              value="Enter hard token PIN"
                              className=" !text-[#666666] mb-2 !text-sm !font-normal"
                            />
                          </div>
                          <input
                            id="hardToken-pin_input"
                            name="pin"
                            type="password"
                            data-auth-type={authType}
                            className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary lg:max-w-sm "
                            value={values?.pin}
                            onChange={handleChange}
                            maxLength={4}
                          />
                          <span className="text-sm text-red-600">
                            {errors?.pin}
                          </span>{" "}
                          <div className="relative lg:max-w-sm">
                            <div className="mb-2 block text-left">
                              <Label
                                htmlFor="authToken"
                                value="Enter hard token"
                                className=" !text-[#666666] mb-2 !text-sm !font-normal"
                              />
                            </div>

                            <input
                              id="hardToken_input"
                              name="authToken"
                              type="password"
                              // data-auth-type={authType}
                              className="w-full p-2.5 bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none appearance-none focus:border-primary focus:ring-primary "
                              value={values?.authToken}
                              onChange={handleChange}
                              maxLength={6}
                            />
                          </div>
                          <span className="text-sm text-red-600">
                            {errors?.authToken}
                          </span>
                          {""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="relative w-full lg:max-w-sm mb-2  flex items-center justify-center">
                    <FormikButton
                      id="proceed-to-summary"
                      labelText="Proceed"
                      isDisabled={!isValid && !dirty}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default NewAirtimePurchases;
