import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import { ValidateCardNumber } from "../../../types/global";
import {
  POST_VALIDATE_CARD,
  POST_VALIDATE_CARD_UNREG_BIZ,
} from "../../../utils/apis/routes";
import { ToastNotify } from "helpers/toastNotify";

export const useCardValidation = () => {
  //Local States
  const [userCardValidationStatus, setCardValidationStatus] =
    useState<boolean>(false);
  const [userCardValidationLoading, setCardValidationLoading] =
    useState<boolean>(false);
  const [userCardValidationData, setCardValidationData] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const handleCardValidation = async (payload: ValidateCardNumber) => {
    setCardValidationLoading(true);
    setCardValidationStatus(false);
    const url = !!payload?.correlationId
      ? POST_VALIDATE_CARD_UNREG_BIZ
      : POST_VALIDATE_CARD;
    try {
      const res = await makeRequest({
        method: "post",
        route: url,
        body: payload,
        originType: "onboarding",
      });

      //Map the payload
      if (res?.status === 200) {
        if (res?.data?.data) {
          setCardValidationData(res?.data?.data);
          setCardValidationStatus(true);
        }
      } else if (res?.status === 400 || res?.status === 404) {
        if (res?.data?.data?.hasError) {
          const resMsg =
            res?.data?.data?.responseMessage?.actionMessage ||
            res?.data?.data?.responseMessage?.title;
          ToastNotify({
            type: "error",
            message: resMsg,
            position: "top-right",
          });
        }
      } else {
        ToastNotify({
          type: "error",
          message: "An error occured, please try again.",
          position: "top-right",
        });
      }

      setCardValidationLoading(false);
    } catch (error) {
      ToastNotify({
        type: "error",
        message: "An error occured, please try again.",
        position: "top-right",
      });

      setCardValidationLoading(false);
    }
  };

  return {
    userCardValidationStatus,
    userCardValidationLoading,
    userCardValidationData,
    handleCardValidation,
  };
};
