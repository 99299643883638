import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import { PUT_DELETE_BENEFICIARY } from "../../../utils/apis/routes";
import { ToastNotify } from "helpers/toastNotify";
import axiosStatus from "utils/apis/axios/errorStatus";

export const useDeleteBeneficiaries = () => {
  //Local States
  const [deleteBeneficiaryStatus, setDeleteBeneficiaryStatus] =
    useState<boolean>(false);
  const [deleteBeneficiaryLoading, setDeleteBeneficiaryLoading] =
    useState<boolean>(false);
  const [deleteBeneficiaryData, setDeleteBeneficiaryData] = useState<any>({});

  //API Request
  const makeRequest = useApiRequest();

  const handleDeleteBeneficiary = async ({ id }: { id: number }) => {
    try {
      setDeleteBeneficiaryLoading(true);

      const res = await makeRequest({
        method: "put",
        route: PUT_DELETE_BENEFICIARY(id),
        originType: "transferservice",
      });
      // console.log("res-delete-bene", res);
      //Map the payload
      if (res?.data?.hasError) {
        setDeleteBeneficiaryStatus(false);
        ToastNotify({
          type: "error",
          message: `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      } else if (res?.status === 500) {
        setDeleteBeneficiaryStatus(false);
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        setDeleteBeneficiaryStatus(true);
        setDeleteBeneficiaryData(res?.data?.data);
        ToastNotify({
          type: "success",
          message: `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }

      setDeleteBeneficiaryLoading(false);
    } catch (error) {
      ToastNotify({
        type: "error",
        message: `${axiosStatus(error)}`,
        position: "top-right",
      });
      setDeleteBeneficiaryLoading(false);
    }
  };

  return {
    handleDeleteBeneficiary,
    deleteBeneficiaryData,
    deleteBeneficiaryLoading,
    deleteBeneficiaryStatus,
  };
};
