import { useAppDispatch } from "hooks/useReduxHook";
import { ReactSuspenceWrapper } from "../../alat-components-library";
import DashboardWrapper from "../../components/Layouts/DashboardWrapper";
import { NotificationsTabs } from "./components/NotificationsTabs";

export const ViewNotifications = () => {
  const dispatch = useAppDispatch();

  return (
    <ReactSuspenceWrapper>
      <DashboardWrapper>
        <div className="lg:px-20 space-y-4 mt-5">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold">Notifications</h2>
          </div>
          <div>
            <NotificationsTabs />
          </div>
        </div>
      </DashboardWrapper>
    </ReactSuspenceWrapper>
  );
};
