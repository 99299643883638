import React, { useState } from "react";
import { ToastNotify } from "../../../helpers/toastNotify";
import { useApiRequest } from "../../useApiRequest";
import { useAppDispatch } from "../../useReduxHook";
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_COUNT,
  MARK_ALL_AS_READ,
  MARK_AS_READ,
} from "utils/apis/routes/notifications";
import { NotificationsPayload } from "react-wrappers/stores/redux/interfaces";
import {
  setLoadingState,
  setAllNotifications,
  setUnreadNotifications,
  setReadNotifications,
  setNotificationsCount,
} from "react-wrappers/stores/redux/notifications/slices";

export const useGetNotifications = () => {
  const makeRequest = useApiRequest();
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<boolean>(false);

  const handleFetchAllNotifications = async (payload: NotificationsPayload) => {
    dispatch(setLoadingState(["getting-notifications"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_NOTIFICATIONS(
          payload.notificationState,
          payload.pageNumber,
          payload.pageSize,
          payload.from,
          payload.to
        ),
        originType: "adhocservice",
      });
      if (response?.data?.httpStatusCode === 200) {
        setStatus(true);
        dispatch(setAllNotifications(response?.data?.data));
      } else {
        dispatch(setAllNotifications([]));
        setStatus(false);
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.responseMessage?.title}. ${response?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  const handleFetchReadNotifications = async (
    payload: NotificationsPayload
  ) => {
    dispatch(setLoadingState(["getting-notifications"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_NOTIFICATIONS(
          "2",
          payload.pageNumber,
          payload.pageSize,
          payload.from,
          payload.to
        ),
        originType: "adhocservice",
      });
      if (response?.data?.httpStatusCode === 200) {
        setStatus(true);
        dispatch(setReadNotifications(response?.data?.data));
      } else {
        dispatch(setReadNotifications([]));
        setStatus(false);
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.responseMessage?.title}. ${response?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  const handleFetchUnReadNotifications = async (
    payload: NotificationsPayload
  ) => {
    dispatch(setLoadingState(["getting-notifications"]));
    try {
      const response = await makeRequest({
        method: "get",
        route: GET_NOTIFICATIONS(
          "3",
          payload.pageNumber,
          payload.pageSize,
          payload.from,
          payload.to
        ),
        originType: "adhocservice",
      });
      if (response?.data?.httpStatusCode === 200) {
        setStatus(true);
        dispatch(setUnreadNotifications(response?.data?.data));
      } else {
        dispatch(setUnreadNotifications([]));
        setStatus(false);
        ToastNotify({
          type: "error",
          message:
            response?.status === 500
              ? "Oops! Something went wrong"
              : `${response?.data?.responseMessage?.title}. ${response?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      dispatch(setLoadingState([]));
    } catch (err: any) {
      dispatch(setLoadingState([]));
      if (err?.response?.status === 500) {
        ToastNotify({
          type: "error",
          message: "Oops! Something went wrong",
          position: "top-right",
        });
      } else {
        ToastNotify({
          type: "error",
          message:
            err?.response?.data?.responseMessage?.title ||
            err?.response?.data?.responseMessage?.actionMessage,
          position: "top-right",
        });
      }
    }
  };

  const handleMarkAsRead = async (payload: any) => {
    const response = await makeRequest({
      method: "put",
      route: MARK_AS_READ,
      body: payload,
      originType: "adhocservice",
    });
    if (response?.data?.httpStatusCode === 200) {
      handleFetchAllNotifications({
        notificationState: "1",
        pageNumber: 1,
        pageSize: 20,
        from: "",
        to: "",
      });
      handleFetchReadNotifications({
        notificationState: "2",
        pageNumber: 1,
        pageSize: 20,
        from: "",
        to: "",
      });
      handleFetchUnReadNotifications({
        notificationState: "3",
        pageNumber: 1,
        pageSize: 20,
        from: "",
        to: "",
      });
      handleFetchNotificationsCount();
    }
  };

  const handleMarkAllAsRead = async () => {
    const response = await makeRequest({
      method: "put",
      route: MARK_ALL_AS_READ,
      originType: "adhocservice",
    });
    if (response?.data?.httpStatusCode === 200) {
      handleFetchAllNotifications({
        notificationState: "1",
        pageNumber: 1,
        pageSize: 20,
        from: "",
        to: "",
      });
      handleFetchReadNotifications({
        notificationState: "2",
        pageNumber: 1,
        pageSize: 20,
        from: "",
        to: "",
      });
      handleFetchUnReadNotifications({
        notificationState: "3",
        pageNumber: 1,
        pageSize: 20,
        from: "",
        to: "",
      });
      handleFetchNotificationsCount();
    }
  };

  const handleFetchNotificationsCount = async () => {
    const response = await makeRequest({
      method: "get",
      route: GET_NOTIFICATIONS_COUNT,
      originType: "adhocservice",
    });
    if (response?.data?.httpStatusCode === 200) {
      dispatch(setNotificationsCount(response.data?.data));
    }
  };

  return {
    handleFetchAllNotifications,
    handleFetchReadNotifications,
    handleFetchUnReadNotifications,
    handleMarkAsRead,
    handleMarkAllAsRead,
    handleFetchNotificationsCount,
    status,
  };
};
