import React from "react";
interface ISearchComponentProps {
  word: string;
  handleWordChange: any;
  title: any;
  width?: string;
  id?: string;
  textPosition?: string;
}
export default function SearchComponent({
  word,
  handleWordChange,
  title,
  width = "w-[450px]",
  id = "search_input",
  textPosition = "text-center",
}: ISearchComponentProps) {
  return (
    // className={`w-[450px] ${title === "Search for beneficiary" ? "max-w-sm": "w-[450px]" }`}
    <div className={`md:${width} w-full`}>
      <div className="relative">
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-0 bottom-0 w-6 h-6 my-auto text-[#828282] inset-y-0 left-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg> */}
        <input
          id={id}
          type="text"
          placeholder={title}
          className={`md:${width} ${textPosition} w-full text-sm font-normal py-3 pl-12 pr-4 text-gray-500 border rounded-md outline-none bg-gray-50 focus:bg-white focus:border-primary`}
          onChange={handleWordChange}
          name="word"
          value={word}
        />
      </div>
    </div>
  );
}
