import { useState } from "react";
import { useApiRequest } from "../../useApiRequest";
import { ToastNotify } from "helpers/toastNotify";
import { GET_REJECTED_DOCUMENTS } from "utils/apis/routes/pendingActions";

type RejectedDocumentsResponse = {
  pendingActionType: number;
  cacRegNumber: string;
  reason: string;
  email: null;
  actionData: string;
  pendingActionId: number;
};
export const useFetchRejectedDocuments = () => {
  //Local States
  const [fetchSuccess, setFetchSuccess] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string>("");
  const [rejectedDocuments, setRejectedDocuments] = useState<
    Array<RejectedDocumentsResponse>
  >([]);

  //API Request
  const makeRequest = useApiRequest();

  const handleFetchRejectedDocuments = async ({
    regNumber,
    businessType,
  }: {
    regNumber: string;
    businessType: number;
  }) => {
    const payload = {
      regNumber,
      businessType,
      // regNumber: "5644321" ,businessType:0 // TODO: CORRECT ONCE TESTING IS COMPLETED
    };
    setFetchLoading(true);
    try {
      const res = await makeRequest({
        method: "post",
        route: GET_REJECTED_DOCUMENTS,
        originType: "solependingactions",
        body: payload,
      });

      // console.log("res",res);
      //Map the payload
      if (res.status === 200) {
        setFetchSuccess(true);
        setRejectedDocuments(res?.data?.data);
      }
      if (res?.data?.data?.hasError) {
        setFetchSuccess(false);
        ToastNotify({
          type: "error",
          message: `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`,
          position: "top-center",
        });
      }
      setFetchLoading(false);
    } catch (error) {
      setFetchError("An error occured while fetching rejected documents");
      setFetchLoading(false);
    }
  };

  return {
    fetchSuccess,
    fetchLoading,
    fetchError,
    rejectedDocuments,
    handleFetchRejectedDocuments,
  };
};
