import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as RefereeIcon } from "assets/svgs/red-referee-icon.svg";
import { ReactComponent as ResendIcon } from "assets/svgs/resend-icon.svg";
import { ReactComponent as EditIcon } from "assets/svgs/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete-icon.svg";
import { useNavigate } from "react-router-dom";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import {
  deleteReferee,
  updateFirstReferee,
  updateSecondReferee,
  //   saveOnboardingData,
  //   updateFirstReferee,
  //   updateSecondReferee,
} from "react-wrappers/stores/redux/onboarding/slices";
import CenterModal from "alat-components-library/Modal/CenterModal";
import { ReactComponent as SuccessIcon } from "assets/svgs/success-icon.svg";
import { useResendRefereeEmail } from "hooks/apis/Referee/useResendRefereeEmail";
import PageLoader from "components/Loader/PageLoader";

interface RefereeCardProps {
  id?: string;
  deleteId?: string;
  editId?: string;
  actionId?: string;
  bank: string;
  email: string;
  firstName: string;
  lastName: string;
  index: number;
  filteredReferees: any;
  isRejectedReferees?: boolean;
}

export const UpdatedRefereeCard: React.FC<RefereeCardProps> = ({
  id,
  actionId,
  editId,
  deleteId,
  bank,
  firstName,
  lastName,
  email,
  index,
  filteredReferees,
  isRejectedReferees,
}) => {
  const { user } = useAppSelector((state) => state.auth);
  const [actions, setActions] = useState(false);
  // eslint-disable-next-line
  const [deleteAction, setDeleteAction] = useState(false);
  const actionsRef = useRef<HTMLDivElement>(null);
  const {
    resendRefereeEmailStatus,
    resendRefereeEmailLoading,
    resendRefereeEmailError,
    resendRefereeEmailData,
    resendRefereeEmail: postResendRefereeEmail,
  } = useResendRefereeEmail();
  const [successModal, setSuccessModal] = useState(false);
  const referees = useAppSelector((state) => state.onboarding.refereePayload);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // console.log("Referees: ", referees);
  const infoText = bank + " | " + email;
  const isRefereeModification = referees[index]?.id && !isRejectedReferees;
  const resendRefereeEmail = (id: any) => {
    const payload = {
      correlationId: user?.correlationId,
      id,
    };
    postResendRefereeEmail(payload);
  };
  // Closes the modal on click outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        actionsRef.current &&
        !actionsRef.current.contains(event.target as Node)
      ) {
        setActions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [actionsRef]);

  useEffect(() => {
    if (resendRefereeEmailStatus) {
      setSuccessModal(true);
    }
  }, [resendRefereeEmailStatus]);

  return (
    <div key={index}>
      {resendRefereeEmailLoading && <PageLoader message="" />}
      <div
        className={`w-full rounded-lg h-auto py-4 border items-center my-2 transition-all duration-1000
        ${deleteAction ? "animate-bounce" : ""}`}
      >
        <div className="flex gap-2">
          <div className="my-auto">
            <RefereeIcon className="md:h-20 md:w-16 w-10 h-10 text-[#606060] ml-2" />
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-lg font-semibold">
              {firstName} &nbsp;{lastName}
            </p>
            <p className="text-md md:hidden">
              {infoText.length > 25 ? infoText.slice(0, 25) + "..." : infoText}
            </p>
            <p className="text-md hidden md:block">
              {infoText.length > 30 ? infoText.slice(0, 30) + "..." : infoText}
            </p>
            <div className={`${isRefereeModification ? "flex" : "hidden"}`}>
              {referees[index]?.accepted ? (
                <div className="text-green-600 bg-green-200 text-xs p-2 rounded-lg font-semibold">
                  ACCEPTED
                </div>
              ) : (
                <div className="text-yellow-600 bg-yellow-200 text-xs p-2 rounded-lg font-semibold">
                  PENDING REVIEW
                </div>
              )}
            </div>
          </div>
          <div
            className={`md:relative ml-auto my-auto ${
              referees[index]?.accepted && !isRejectedReferees ? "hidden" : ""
            }`}
            ref={actionsRef}
          >
            <EllipsisVerticalIcon
              className="h-10 text-[#606060] cursor-pointer"
              onClick={() => setActions(!actions)}
              id={actionId}
            />
            <div
              className={`absolute right-0 bg-white rounded-lg w-48 flex flex-col z-2 text-[16px] shadow-lg 
                    ${!actions ? "hidden" : ""}`}
            >
              <div
                id={editId}
                className="cursor-pointer hover:bg-gray-50 flex gap-2 w-full items-center px-2 py-2"
                onClick={() =>
                  navigate(
                    `${
                      isRejectedReferees
                        ? "/pending-actions/rejected-docs/add-referee"
                        : "/referee-setup"
                    }`,
                    {
                      state: {
                        refereeToEdit: index + 1,
                        editData: referees[index],
                      },
                    }
                  )
                }
              >
                <div>
                  <EditIcon />
                </div>
                <div className="text-gray-500">
                  {isRefereeModification ? "Change Referee" : "Edit Referee"}
                </div>
              </div>
              {!isRefereeModification ? (
                <div
                  className={`cursor-pointer hover:bg-gray-50 flex gap-2 w-full items-center px-2 py-2`}
                  id={deleteId}
                  onClick={() => {
                    if (filteredReferees.length === 2) {
                      if (index === 0) {
                        dispatch(
                          updateFirstReferee({
                            ...referees[0],
                            bank: "",
                            firstName: "",
                            lastName: "",
                            emailAddress: "",
                            phoneNumber: "",
                            accountNumber: "",
                          })
                        );
                      } else {
                        dispatch(
                          updateSecondReferee({
                            ...referees[1],
                            bank: "",
                            firstName: "",
                            lastName: "",
                            emailAddress: "",
                            phoneNumber: "",
                            accountNumber: "",
                          })
                        );
                      }
                    } else {
                      dispatch(
                        updateFirstReferee({
                          ...referees[0],
                          bank: "",
                          firstName: "",
                          lastName: "",
                          emailAddress: "",
                          phoneNumber: "",
                          accountNumber: "",
                        })
                      );
                      dispatch(
                        updateSecondReferee({
                          ...referees[1],
                          bank: "",
                          firstName: "",
                          lastName: "",
                          emailAddress: "",
                          phoneNumber: "",
                          accountNumber: "",
                        })
                      );
                    }
                  }}
                >
                  <div>
                    <DeleteIcon />
                  </div>
                  <div className="text-red-500">Delete Referee</div>
                </div>
              ) : (
                <div
                  className={`cursor-pointer hover:bg-gray-50 flex gap-2 w-full items-center px-2 py-2`}
                  id={deleteId}
                  onClick={() => {
                    const refereeId = isRefereeModification;
                    resendRefereeEmail(refereeId);
                  }}
                >
                  <div>
                    <ResendIcon />
                  </div>
                  <div className="font-semibold">Resend Referee</div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/*Referee Email resent Successfully*/}
        {successModal && (
          <CenterModal
            icon={<SuccessIcon />}
            title="Success!"
            info={`A referee verification link has been sent to the email (${referees[index].emailAddress}), Kindly ensure your referee responds via the link sent.`}
            handleClick={() => {
              setSuccessModal(false);
              // navigate("/user-dashboard");
            }}
            handleClose={() => {
              setSuccessModal(false);
              // navigate("/user-dashboard");
            }}
            handleClick2={() => {
              setSuccessModal(false);
              // navigate("/user-dashboard");
            }}
            btnText="Okay"
            btnText2={"Return to dashboard"}
            showCloseIcon
          />
        )}
      </div>
    </div>
  );
};
