import React, { useState, useEffect } from "react";
import PendingCard from "./PendingCard";

type PendingActionTypes = {
  pendingActionId: string;
  activeTab?: number;
  currentTab?: number;
  icon: string;
  heading: string;
  info: string;
  handleClick: any;
};
const PendingActionCard = ({
  pendingActionId,
  activeTab = 0,
  currentTab = 0,
  icon,
  heading,
  info,
  handleClick,
}: PendingActionTypes) => {
  return (
    <>
      <div className="bg-white h-80 rounded-lg p-4 relative">
        <div className="w-full h-full pb-10 relative">
          <h4 className="text-left text-xl font-semibold">Pending Actions</h4>
          <div className="flex items-center w-full justify-between h-full gap-4">
            <div className="w-full h-full flex items-center justify-center">
              <PendingCard
                id={pendingActionId}
                key=""
                activeTab={activeTab}
                currentTab={currentTab}
                icon={icon}
                heading={heading}
                info={info}
                clickEvent={handleClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingActionCard;
