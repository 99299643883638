import * as Yup from "yup";

//Validation
export const IntraTransferValidation = Yup.object().shape({
  narrationInter: Yup.string(),
});

export const InterTransferValidation = Yup.object().shape({
  narrationInter: Yup.string(),
});

export const SelectAccountValidation = (
  balance: string,
  amount: string | number,
  charges: string | number
) =>
  Yup.object().shape({
    account:
      balance !== undefined
        ? Yup.string()
            .required()
            .test(
              "Above Balance",
              "Insufficient Funds",
              () => Number(amount) + Number(charges) <= Number(balance)
            )
        : Yup.string().required(),
    transactionPin: Yup.string(),
    softToken: Yup.string(),
    hardToken: Yup.string(),
    debitType: Yup.string().notRequired(),
  });

export const OtpValidationValidation = Yup.object().shape({
  otp: Yup.string().required(),
});

export const editExcelValidation = Yup.object().shape({
  beneficiaryAccountNumber: Yup.string().required(),
  beneficiaryName: Yup.string().required(),
  narration: Yup.string(),
  refCode: Yup.string(),
  amount: Yup.string().required(),
});

export const filterHistoryValidation = Yup.object().shape({
  startDate: Yup.string(),
  endDate: Yup.string(),
  reference: Yup.string(),
  accountNumber: Yup.string().required(),
});
