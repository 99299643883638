import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Card } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { FormikButton } from "alat-components-library";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import { useCorporateDebitCardController } from "controllers/Cards/CorporateDebitCardController";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { getOption } from "utils/helperFunctions";
import {
  setActiveCardRequestScreen,
  useCardState,
} from "react-wrappers/stores/redux/cards/slices";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import { useAddress } from "hooks/apis/FetchLocation/useAddress";
import { useState } from "react";
import {
  useBranches,
  useCities,
  useGetStates,
  useLgas,
} from "hooks/apis/FetchLocation/useBankLocation";
import { TextInput } from "../../../alat-components-library/Inputs";
import PageLoader from "components/Loader/PageLoader";
import { ReusableCard } from "components/reusables/Page/ReusableCard";

const SecondScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { cardRequestPayload } = useCardState();
  const [selectedState, setSelectedState] = useState<{
    id: string | null;
    name: string;
    code: string | null;
    key: string;
  }>({ id: "", name: "", key: "", code: "" });
  const [selectedCity, setSelectedCity] = useState({ name: "" });
  const [selectedLGA, setSelectedLGA] = useState({ name: "" });
  const [address, setAddress] = useState("");
  const [branch, setBranch] = useState({ name: "" });

  // Local State
  const {
    accountToDebit,
    accSelectErrorMessage,
    activeAccounts,
    deleveryOptionList,
    handleSubmit,
    setAccountToDebit,
    handleSubmitBranchPickup,
  } = useCorporateDebitCardController(
    address,
    selectedState,
    selectedCity,
    selectedLGA
  );

  const { statesData, statesLoading } = useGetStates();
  const { branchesData, branchesLoading } = useBranches(selectedState?.code);
  const { lgasData, lgasLoading } = useLgas(selectedState?.id);
  const { citiesData, citiesLoading } = useCities(selectedState?.id);

  const getStates = () => {
    return (
      statesData.length > 0 &&
      statesData?.map((option: any) => ({
        name: option?.name,
        id: option?.stateId,
        code: option?.finacleCode,
        key: option?.stateId,
      }))
    );
  };

  const getBranches = () => {
    return (
      branchesData.length > 0 &&
      branchesData?.map((option: any) => ({
        name: option?.branchName,
        id: option?.id,
        key: option?.id,
      }))
    );
  };

  const getCities = () => {
    return (
      citiesData.length > 0 &&
      citiesData?.map((option: any) => ({
        name: option?.name,
        id: option?.id,
        key: option?.id,
      }))
    );
  };

  const getLga = () => {
    return (
      lgasData.length > 0 &&
      lgasData?.map((option: any) => ({
        name: option?.name,
        id: option?.lgaId,
        key: option?.lgaId,
      }))
    );
  };

  const isLoading =
    statesLoading || branchesLoading || lgasLoading || citiesLoading;

  return (
    <div className="w-full">
      {isLoading && <PageLoader />}
      <ReusableCard
        cardTitle={
          cardRequestPayload.deliveryOption === 2
            ? "Office Delivery"
            : "Branch Pick up"
        }
        variant="bg-white md:w-[48rem] w-full mx-auto"
        handleBackNavigation={() => {
          dispatch(setActiveCardRequestScreen(0));
        }}
        showGoBackIcon
      >
        <Formik
          initialValues={{
            address: "",
            state: "",
            lga: "",
            city: "",
            preferredBranch: "",
          }}
          onSubmit={(values) => handleSubmitBranchPickup(values)}
          validateOnChange
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
            resetForm,
          }) => (
            <Form>
              <div>
                <div className="flex flex-wrap justify-center gap-4 ">
                  <div className="text-alat-grey relative w-full lg:max-w-sm mb-2 pb-5">
                    {cardRequestPayload.deliveryOption === 2 && (
                      <div className="relative w-full lg:max-w-sm my-4">
                        <TextInput
                          id="address_input"
                          label="Address"
                          name="address"
                          placeHolder="Enter address"
                          value={address}
                          handleChange={(value: any) =>
                            setAddress(value.target.value)
                          }
                          handleBlur={handleBlur}
                          autoComplete="off"
                        />
                      </div>
                    )}

                    <div className="relative w-full lg:max-w-sm my-4">
                      <StateDropdown
                        id="state_dropdown"
                        label="State"
                        placeholder="--Select State--"
                        options={getStates()}
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        onSelectOption={(option) => {
                          setSelectedCity({ name: "" });
                          setSelectedLGA({ name: "" });
                          setBranch({ name: "" });
                          setSelectedState(option);
                        }}
                      />
                    </div>
                    {cardRequestPayload.deliveryOption === 2 && (
                      <>
                        <div className="relative w-full lg:max-w-sm my-4">
                          <StateDropdown
                            id="city_dropdown"
                            label="City"
                            placeholder="--Select City--"
                            options={getCities()}
                            name="city"
                            value={selectedCity.name}
                            onChange={handleChange}
                            onSelectOption={(option) => setSelectedCity(option)}
                          />
                        </div>
                        <div className="relative w-full lg:max-w-sm my-4">
                          <StateDropdown
                            id="lga_dropdown"
                            label="LGA"
                            placeholder="--Select LGA--"
                            options={getLga()}
                            name="lga"
                            value={selectedLGA.name}
                            onChange={handleChange}
                            onSelectOption={(option) => setSelectedLGA(option)}
                          />
                        </div>
                      </>
                    )}

                    <div className="relative w-full lg:max-w-sm my-4">
                      <StateDropdown
                        id="preferredbranch_dropdown"
                        label="Preferred Branch"
                        name="preferredBranch"
                        placeholder="-- Select Branch --"
                        options={getBranches()}
                        value={values.preferredBranch}
                        onChange={handleChange}
                        onSelectOption={(option) => setBranch(option)}
                        disabled={selectedState.id === null}
                      />
                    </div>
                    <div className="relative w-full py-6 mb-2  flex items-center justify-center">
                      <FormikButton
                        id="proceed"
                        labelText="Proceed"
                        isDisabled={!isValid && !dirty}
                        containerVariant={`w-full h-[48px] rounded-md flex justify-center item-center`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ReusableCard>
      {/* <Card className=" bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div>
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              onClick={() => {
                dispatch(setActiveCardRequestScreen(0));
              }}
              id="back_btn"
              className="h-6 w-6 text-[#333333] cursor-pointer"
            />
            <div
              className="text-[#4D4D4D] text-xl text-center"
              id="banktransfer_heading"
            >
              {cardRequestPayload.deliveryOption === 2
                ? "Office Delivery"
                : "Branch Pick up"}
            </div>
            <div></div>
          </div>
        </div>
        <hr />
      </Card> */}
    </div>
  );
};

export default SecondScreen;
