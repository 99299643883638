import { memo } from "react";
import type { FC } from "react";
import TermsSubAccordion from "../TermsSubAccordion";

interface Props {
  className?: string;
}

export const TermsAndConditionsText: FC<Props> = memo(
  function TheCustomerHerebyConfirmAndAgr(props = {}) {
    return (
      <div className="flex flex-col gap-3 py-4 pb-10">
        <div className="px-6">
          (“the Customer”) hereby confirm and agree that the following terms and
          conditions shall govern my Electronic Banking transactions with WEMA
          Bank Plc (“the Bank”):
        </div>

        <TermsSubAccordion text="1. INTERPRETATION">
          <div className={`classes.textBlock2`}>
            <p></p>
          </div>
          <div className={`classes.textBlock4`}>
            <p className={`classes.labelWrapper2`}>
              1.1 Service means the Electronic Banking Services of the Bank,
              including Internet Banking, Secure message facility and bills
              payment services.
            </p>
          </div>
          <div className={`classes.textBlock5`}>
            <p></p>
          </div>
          <div className={`classes.textBlock6`}>
            1.2 Login Password means the enabling code with which the Customer
            accesses the system, and which is known only to the customer.
          </div>
          <div className={`classes.textBlock7`}>
            1.3 Transaction Password means the enabling code with which the
            Customer can transfer or move fund from once account to the other.
          </div>
          <div className={`classes.textBlock8`}>
            <p></p>
          </div>
          <div className={`classes.textBlock9`}>
            1.4 Account means a current or savings account or other account
            maintained by the Customer with the Bank at any of the Bank&#39;s
            branches in Nigeria.
          </div>
          <div className={`classes.textBlock10`}>
            <p></p>
          </div>
          <div className={`classes.textBlock11`}>
            1.5 Mailing Address means the customer&#39;s mailing address in the
            Bank&#39;s records.
          </div>
          <div className={`classes.textBlock12`}>
            <p></p>
          </div>
          <div className={`classes.textBlock13`}>
            1.6 Instruction means the customer&#39;s request to the Bank for the
            services.
          </div>
          <div className={`classes.textBlock14`}>
            <p></p>
          </div>
          <div className={`classes.textBlock15`}>
            1.8 Switch means an online electronic transaction processing payment
            infrastructure that connects different payment channels to the
            payment processors and enablers.
          </div>
          <div className={`classes.textBlock16`}>
            <p></p>
          </div>
        </TermsSubAccordion>
        <TermsSubAccordion text="2. SCOPE AND MODE OF SERVICE">
          <div className={`classes.textBlock18`}>
            <p className={`classes.labelWrapper4`}>
              <span className={`classes.label4`}>
                2.1. The service allows the customers to give the Bank
                instructions by use of username, login password, security answer
                to predefined questions and transaction password for the
                following:
              </span>
            </p>
          </div>
          <div className={`classes.textBlock19`}>
            <p></p>
          </div>
          <div className={`classes.textBlock20`}>
            2.1.1 Obtain information regarding customer&#39;s balance as at the
            last date of business with the Bank.
          </div>
          <div className={`classes.textBlock21`}>
            <p></p>
          </div>
          <div className={`classes.textBlock22`}>
            2.1.2 Obtain information with regards to any instrument in clearing
            or any credit standing in the customer’s account as at the last date
            of transaction on the customer&#39;s account.
          </div>
          <div className={`classes.textBlock23`}>
            <p></p>
          </div>
          <div className={`classes.textBlock24`}>
            2.1.3 Authorize the Bank to debit customer&#39;s account to pay a
            specified utility bill such as NITEL, PHCN, DSTV water rate and/or
            any other bills as specified by the customer subject however to
            availability of such bill payment under this service.
          </div>
          <div className={`classes.textBlock25`}>
            <p></p>
          </div>
          <div className={`classes.textBlock26`}>
            2.1.4 Authorizing the Bank to effect a transfer of funds from the
            customer&#39;s account to any other account with the Bank and
            outside the Bank.
          </div>
          <div className={`classes.textBlock27`}>
            <p></p>
          </div>
          <div className={`classes.textBlock28`}>
            2.1.5 Authorizing the Bank to effect any stop payment order.
          </div>
          <div className={`classes.textBlock29`}>
            <p></p>
          </div>
          <div className={`classes.textBlock30`}>
            2.2 On receipt of instructions, the Bank will endeavour to carry out
            the customer&#39;s instructions on best effort basis excepting all
            or any unforeseen circumstances such as Act of God, Force Majeure,
            and other causes beyond the Bank&#39;s control
          </div>
          <div className={`classes.textBlock31`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion
          text=" 3 FOR THE SERVICE TO BE AVAILABLE TO ANY CUSTOMER, HE/SHE MUST HAVE ANY ONE OR A COMBINATION OF THE
            FOLLOWING:"
        >
          <div className={`classes.textBlock33`}>
            <p className={`classes.labelWrapper6`}>
              <span className={`classes.label6`}>
                3.1 An account with the Bank;
              </span>
            </p>
          </div>
          <div className={`classes.textBlock34`}>
            <p></p>
          </div>
          <div className={`classes.textBlock35`}>
            3.2 A Username, login password, security answer and transaction
            password;
          </div>
          <div className={`classes.textBlock36`}>
            <p></p>
          </div>
          <div className={`classes.textBlock37`}>3.3 A computer;</div>
          <div className={`classes.textBlock38`}>
            <p></p>
          </div>
          <div className={`classes.textBlock39`}>
            3.4 An E-mail address, phone number, date of Birth
          </div>
          <div className={`classes.textBlock40`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion
          text=" 4 UNDER NO CIRCUMSTANCES SHALL THE CUSTOMER ALLOW ANY BODY ACCESS TO HIS/HER ACCOUNT THROUGH THE SERVICE"
          showIcon={false}
        ></TermsSubAccordion>

        <TermsSubAccordion text="5 TRANSACTION PASSWORD/ LOGIN PASSWORD/USER NAME">
          <div className={`classes.textBlock44`}>
            <p></p>
          </div>
          <div className={`classes.textBlock45`}>
            5.1 The Customer understands that his/her transaction password is
            used to give instructions to the Bank and accordingly undertakes
            that under no circumstances shall the transaction password be
            disclosed to anybody nor to write the transaction password in an
            open place in order to avoid third party coming across same.
          </div>
          <div className={`classes.textBlock46`}>
            <p></p>
          </div>
          <div className={`classes.textBlock47`}>
            5.2 The customer undertakes that login password, security answer and
            transaction password shall be kept away from third parties and shall
            not save his / her login password, security answer and transaction
            password in a recognizable form including the username or on any
            other item carried along with the internet services.
          </div>
          <div className={`classes.textBlock48`}>
            <p></p>
          </div>
          <div className={`classes.textBlock49`}>
            5.3 Once the Bank is instructed by means of the customer&#39;s
            transaction password, the Bank is entitled to assume that those are
            the instructions given by the customer and to rely on same.
          </div>
          <div className={`classes.textBlock50`}>
            <p></p>
          </div>
          <div className={`classes.textBlock51`}>
            5.4 The customer&#39;s password, security answer and transaction
            password must be changed immediately it becomes known to someone
            else.
          </div>
          <div className={`classes.textBlock52`}>
            <p></p>
          </div>
          <div className={`classes.textBlock53`}>
            5.5 The Bank is exempted from any form of liability whatsoever for
            complying with any or all instruction(s) given by means of the
            customer&#39;s password, security answer and transaction password if
            by any means the password, security answer and transaction password
            become known to a third party or otherwise become compromised.
          </div>
          <div className={`classes.textBlock54`}>
            <p></p>
          </div>
          <div className={`classes.textBlock55`}>
            5.6 Once a customer&#39;s password, security answer and transaction
            password are given, it shall be sufficient confirmation of the
            authenticity of the instruction given.
          </div>
          <div className={`classes.textBlock56`}>
            <p></p>
          </div>
          <div className={`classes.textBlock57`}>
            5.7 You shall be responsible for any instructions given by means of
            your Pass code/Access code. Accordingly, the bank shall not be
            responsible for any such fraudulent, duplicate, or erroneous
            information
          </div>
          <div className={`classes.textBlock58`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="6.0 LIMITATION OF LIABILITY">
          <div className={`classes.textBlock59`}>
            <p className={`classes.labelWrapper8`}>
              <span className={`classes.label9`}></span>
            </p>
          </div>
          <div className={`classes.textBlock60`}>
            <p className={`classes.labelWrapper9`}>
              <span className={`classes.label10`}>
                The bank makes no warranty that:
              </span>
            </p>
          </div>
          <div className={`classes.textBlock61`}>
            (a) The e-banking service will meet your requirements.
          </div>
          <div className={`classes.textBlock62`}>
            <p></p>
          </div>
          <div className={`classes.textBlock63`}>
            (b) The e-banking service will be uninterrupted, secure, or error
            free.
          </div>
          <div className={`classes.textBlock64`}>
            <p></p>
          </div>
          <div className={`classes.textBlock65`}>
            In any event, the total liability of the Bank for all claims of any
            kind arising as a result of or related to this service shall not
            exceed an amount equal to the amount earned by the Bank (commission
            earned) from transactions carried out by the customer in the last
            six months period preceding the date the claim arises.
          </div>
          <div className={`classes.textBlock66`}>
            <p></p>
          </div>
          <div className={`classes.textBlock67`}>
            (c) The e-banking platform is intended to be available through the
            public internet. The Bank is not liable and makes no warranties for
            access, speed or availability of the Internet or network services.
          </div>
          <div className={`classes.textBlock68`}>
            <p></p>
          </div>
          <div className={`classes.textBlock69`}>
            (d) The Bank makes no warranties that any of its e-banking channels
            shall be fit for purpose or as to merchantability.
          </div>
          <div className={`classes.textBlock70`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="7.0 LIABILITY FOR REFUNDS">
          <div className={`classes.textBlock72`}>
            <p className={`classes.labelWrapper11`}>
              <span className={`classes.label12`}>
                7.1 Where we receive a notification that a payment from your
                account was not authorized by you, we will carry out an
                investigation and, as soon as we are reasonably satisfied that
                you did not authorize the payment, and that the payment was
                wrongly implemented by an error on the part of the Bank, we will
                refund the amount deducted.
              </span>
            </p>
          </div>
          <div className={`classes.textBlock73`}>
            <p></p>
          </div>
          <div className={`classes.textBlock74`}>
            7.2 However, you will be liable for:
          </div>
          <div className={`classes.textBlock75`}>
            (a) All payments made from your account where you have acted
            fraudulently.
          </div>
          <div className={`classes.textBlock76`}>
            (b) Where the payment was made because you deliberately,
            negligently, or otherwise failed to keep your security information
            safe.
          </div>
          <div className={`classes.textBlock77`}>
            <p></p>
          </div>
          <div className={`classes.textBlock78`}>
            7.3 We would not be liable to you for any losses you suffer or costs
            you incur because:
          </div>
          <div className={`classes.textBlock79`}>
            (a) the details provided in the instruction provided were not
            correct;
          </div>
          <div className={`classes.textBlock80`}>
            (b) We cannot carry out our responsibilities under this agreement as
            a result of anything we cannot reasonably control. This shall also
            include, among other things, any machine, electronic device,
            hardware, or software failing to work.
          </div>
          <div className={`classes.textBlock81`}>
            (c) of our refusal to act on your instruction due to an order of
            court or a court judgement against you. However, a court order will
            not prevent us from using any right of set-off we may have using
            money which we hold for you.
          </div>
          <div className={`classes.textBlock82`}>
            <p></p>
          </div>
          <div className={`classes.textBlock83`}>
            7.4 You are informed that issuance of Dud Cheques constitutes a
            criminal offence under the Nigerian Law and we are obligated by
            virtue of Central Bank of Nigeria’s directive to submit details of
            customers who issue cheques on insufficiently funded accounts to the
            CBN for investigation and prosecution in line with the provisions of
            the Dishonoured Cheques (offences) Act LFN 2007.
          </div>
          <div className={`classes.textBlock84`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="8.0 INDEMNITY">
          <div className={`classes.textBlock86`}>
            <p className={`classes.labelWrapper13`}>
              <span className={`classes.label14`}>
                The Customer hereby undertake to indemnify the Bank against any
                damages, cost and expenses whatsoever including reasonable legal
                costs, charges, and other expenses suffered by the Bank that may
                arise from the Customer’s wrongful use of the service,
                negligence, or other faults of the Customer.
              </span>
            </p>
          </div>
          <div className={`classes.textBlock87`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="9.0 HANDLING OF PERSONAL INFORMATION">
          <div className={`classes.textBlock89`}>
            <p className={`classes.labelWrapper15`}>
              <span className={`classes.label16`}>
                9.1 Information which we receive from you will be managed in
                accordance with the Nigeria Data Protection Regulation (NDPR).
              </span>
            </p>
          </div>
          <div className={`classes.textBlock90`}>
            <p></p>
          </div>
          <div className={`classes.textBlock91`}>
            9.2 We will retain information about you after the closure of your
            account, if the banking relationship has terminated, or if your{" "}
          </div>
          <div className={`classes.textBlock92`}>
            application is declined or abandoned, for as long as permitted for
            legal, regulatory, fraud prevention and legitimate business
            purposes.
          </div>
          <div className={`classes.textBlock93`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="10. CLAIMS">
          <div className={`classes.textBlock95`}>
            <p className={`classes.labelWrapper17`}>
              <span className={`classes.label18`}>
                10.1 If another person makes a claim for any of the funds in
                your account (for example, if someone takes legal action to
                recover funds they believe belong to them), or if we know or
                believe that there is a dispute involving someone else who owns
                or controls funds in the account, we may: (a) Put a hold on your
                account and refuse to pay out any funds until we are satisfied
                that the dispute has ended or (b) Send the funds to the person
                who we have good reason to believe is legally entitled to them;
                or (c) Continue to rely on the current records we hold about
                you; apply for a court order; or take any other action we feel
                is necessary to protect us.
              </span>
            </p>
          </div>
          <div className={`classes.textBlock96`}>
            10.2 We will not be liable to you for taking any of the above steps
          </div>
          <div className={`classes.textBlock97`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="11. SET OFFS">
          <div className={`classes.textBlock99`}>
            <p className={`classes.labelWrapper19`}>
              <span className={`classes.label20`}>
                11.1 If any accounts you hold with us are in credit, we may use
                them to repay any amounts you owe us including but not limited
                to sums due on any other accounts you hold with us either in the
                same name(s) or in the case of corporate accounts, its
                affiliate, subsidiary or sister company’s accounts (whether or
                not in the same name), even if the accounts are in different
                currencies.
              </span>
            </p>
          </div>
          <div className={`classes.textBlock100`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="12. BANK CHARGES">
          <div className={`classes.textBlock102`}>
            <p className={`classes.labelWrapper21`}>
              <span className={`classes.label22`}>
                12.1 We will levy charges for the operation of the account in
                accordance with our Standard Tariff which shall be in the
                minimum, the maximum amount contained in the bankers tariff. We
                reserve the right to levy any reasonable charges for additional
                services whether or not contained in the bankers tariff in
                relation to managing your account in addition to those stated in
                the Standard Tariff or for providing you with more frequent
                information regarding the operation of your account.
              </span>
            </p>
          </div>
          <div className={`classes.textBlock103`}>
            <p></p>
          </div>
          <div className={`classes.textBlock104`}>
            12.2 We may take any charges or interest you owe us from any account
            you hold with us.
          </div>
          <div className={`classes.textBlock105`}>
            <p></p>
          </div>
          <div className={`classes.textBlock106`}>
            12.3 We may vary these charges from time to time in accordance with
            the terms stated herein.
          </div>
          <div className={`classes.textBlock107`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="13. ACCOUNT CLOSURE">
          <div className={`classes.textBlock109`}>
            <p className={`classes.labelWrapper23`}>
              <span className={`classes.label24`}>
                13.1 This agreement will continue until you or we cancel or end
                it.
              </span>
            </p>
          </div>
          <div className={`classes.textBlock110`}>
            <p></p>
          </div>
          <div className={`classes.textBlock111`}>
            13.2 We reserve the right to close the account and to end this
            agreement if we, at our absolute discretion, consider that it has
            not been operated in a manner satisfactory to us, or if we believe
            that you have contravened any of these terms and conditions.
          </div>
          <div className={`classes.textBlock112`}>
            <p></p>
          </div>
          <div className={`classes.textBlock113`}>
            13.3 We may take action to close your account without notice and to
            end this agreement immediately in exceptional circumstances such as
            if we reasonably believe that: (a) You are not eligible for an
            account; (b) You have given us any false information at any time;
            (c) You, or someone else, are using the account illegally or for
            criminal activity; (d) It is inappropriate for a person authorized
            to give instructions on your account to operate it; (e) Your
            behaviour means that it is inappropriate for us to maintain your
            account; (f) You have not met our reasonable conditions and requests
            relating to identification and the provision of information about
            yourself and the activity (past, present or future) on any account
            or proposed account; (g) By maintaining your account we might break
            a law, regulation, code or other duty which applies to us; (h) By
            maintaining your account we may damage our reputation; or (i) You
            are or have been in serious or persistent breach of these terms and
            conditions or any additional conditions which apply to an account.
          </div>
          <div className={`classes.textBlock114`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="14. KNOW YOUR CUSTOMER">
          <div className={`classes.textBlock116`}>
            <p className={`classes.labelWrapper25`}>
              <span className={`classes.label26`}>
                14.1 The Bank is obliged by law to confirm and verify the
                identity of each person who apply to open accounts with the
                Bank. Hence, as part of the Bank’s obligations to comply with
                applicable Anti-money laundering &amp; Know Your Customer
                legislation, the Bank, you may be required to provide certain
                Verification Documents (which shall typically include but are
                not limited to an identity card or passport, proof of
                residential address such as a recent utility bill, and proof of
                payment method and earnings).
              </span>
            </p>
          </div>
          <div className={`classes.textBlock117`}>
            <p></p>
          </div>
          <div className={`classes.textBlock118`}>
            14.2 The Bank is further obliged by law to conduct enhanced due
            diligence on you which may be triggered amongst other things by
            changes in your sources of income, unusual inflows into your
            account, negative news about your person or business etc.
          </div>
          <div className={`classes.textBlock119`}>
            <p></p>
          </div>
          <div className={`classes.textBlock120`}>
            14.3 You agree that the Bank has the right to stop place
            restrictions on this account with or without notice to you in the
            event the documentation provided by you does not meet the KYC
            requirement or that the enhanced due diligence conducted by on you
            is not satisfactory.
          </div>
          <div className={`classes.textBlock121`}>
            <p></p>
          </div>
          <div className={`classes.textBlock122`}>
            14.4 You agree that this account shall not be used to receive
            proceeds of fraud and that the Bank has the right to place
            restrictions on this account, as well as the sub-accounts (with or
            without notice to you) if it receives any report or has any
            suspicion that the account is being used for fraud.
          </div>
          <div className={`classes.textBlock123`}>
            <p></p>
          </div>
          <div className={`classes.textBlock124`}>
            14.5 You agree that whenever required by the Bank in the course of
            the banking relationship, you shall provide the Bank with the
            following documents (‘Verification Documents’) and information: ·
            Proof of Identity* (In case of legal persons, the Bank shall obtain
            adequate data and information so as to understand the ownership and
            control structure of the Customer). · Proof of the Client’s
            Residential Address* · Proof of source of earnings and inflows*. ·
            Information and data that are used for the construction of your
            economic profile.
          </div>
          <div className={`classes.textBlock125`}>
            <p></p>
          </div>
          <div className={`classes.textBlock126`}>
            14.6 The Bank will accept a prospective or potential request for
            account opening only when it becomes fully satisfied that the
            Customer complies with Know Your Customer and due diligence
            procedures to ensure that a new relationship with the potential
            Customer does not negatively affect the reputation of the Bank.
          </div>
          <div className={`classes.textBlock127`}>
            <p></p>
          </div>
          <div className={`classes.textBlock128`}>
            14.7 Where the Customer has been granted a waiver to allow time for
            the Customer to provide documents required in furtherance of any due
            diligence, the Bank has the right at any time after the expiration
            of the time allowed to suspend transaction on the account, including
            but not limited to immediately terminating the banking relationship.
          </div>
          <div className={`classes.textBlock129`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="15. FOREIGN CURRENCY">
          <div className={`classes.textBlock131`}>
            <p className={`classes.labelWrapper27`}>
              <span className={`classes.label28`}>
                15.1 If the currency of the Account is other than Nigeria Naira,
                funds may be deposited for the credit of the Account but the
                Customer accepts the risk of all legal or regulatory
                restrictions now or hereafter imposed with respect to exchange
                or transfer of such currency and all taxes or charges imposed by
                Nigeria or the Country of such currency.
              </span>
            </p>
          </div>
          <div className={`classes.textBlock132`}>
            <p></p>
          </div>
          <div className={`classes.textBlock133`}>
            15.2 If the currency of an Account is other than Nigeria Naira, cash
            withdrawals in that foreign currency account shall be subject to the
            availability of the currency and subject to fees to be determined by
            the Bank from time to time, even if cash in that foreign currency
            was deposited in the Account. In case foreign currency cash is not
            available, the Bank may at is sole discretion, issue a Foreign
            Currency Draft in lieu of cash or make payments in Naira at the
            official exchange rate as at the time of payment.{" "}
          </div>
          <div className={`classes.textBlock134`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="16. OVERDRAFTS">
          <div className={`classes.textBlock136`}>
            <p className={`classes.labelWrapper29`}>
              <span className={`classes.label30`}>
                16.1 If the Customer’s account balance becomes overdrawn for any
                reason whatsoever without the prior approval and grant of credit
                by the Bank to the Customer, the Bank shall apply a default rate
                on the overdrawn sum at the minimum rate of 45% per annum. The
                rate can be revised upwards at the sole discretion of the Bank.
              </span>
            </p>
          </div>
          <div className={`classes.textBlock137`}>
            <p></p>
          </div>
          <div className={`classes.textBlock138`}>
            16.2 The Customer shall promptly upon demand, pay to the Bank the
            amount of the overdraft plus, accrued interest until the Bank has
            been fully reimbursed.
          </div>
          <div className={`classes.textBlock139`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="17. ATM CARD/DEBIT CARD">
          <div className={`classes.textBlock141`}>
            <p className={`classes.labelWrapper31`}>
              <span className={`classes.label32`}>
                17.1 A Debit Card may be issued to the Customer at his/her
                request. A Debit Card enables the Customer to electronically
                access the Customer’s Account for various banking transactions.
                The Bank is authorized to carry out the designated transaction
                whenever the Customer’s Debit Card is used to access the
                Customer’s Account. Since the Debit Card offers a Customer
                another way to use his/her Account, any transaction made using
                the Debit Card will considered valid. The Customer hereby
                indemnifies the Bank and holds the Bank free of any liability
                for honouring transactions done with the Customer’s Debit Card.
              </span>
            </p>
          </div>
          <div className={`classes.textBlock142`}>
            <p></p>
          </div>
          <div className={`classes.textBlock143`}>
            17.2 In case the Customer orders issuance of a co-applicant
            (supplementary) card, the Bank will do so on full responsibility of
            the Customer.
          </div>
          <div className={`classes.textBlock144`}>
            <p></p>
          </div>
          <div className={`classes.textBlock145`}>
            17.3 The Bank reserves the right to at any time withdraw and/or
            cancel the card and/or any facilities provided by usage of the card,
            for any reason whatsoever.
          </div>
          <div className={`classes.textBlock146`}>
            <p></p>
          </div>
          <div className={`classes.textBlock147`}>
            17.4 The customer will select in person, his/her Personal
            Identification Number (PIN) using any of the Bank’s ATM and/or POS
            within the branch. The Customer commits to not share the PIN or any
            of the Customer’s Card details with anyone whatsoever.
          </div>
          <div className={`classes.textBlock148`}>
            <p></p>
          </div>
          <div className={`classes.textBlock149`}>
            17.5 The Customer hereby indemnifies and hold the Bank free of any
            liability if the Customer shares the Debit Card details and/or PIN
            with a third-party.
          </div>
          <div className={`classes.textBlock150`}>
            <p></p>
          </div>
          <div className={`classes.textBlock151`}>
            17.6 The Customer must promptly notify the Bank if the Debit Card is
            misplaced or stolen or if the PIN has been disclosed to any other
            person. The Bank shall not be responsible for any transaction that
            takes place on the Customer’s account prior to its receipt of notice
            of loss of the card or compromise of the card details.
          </div>
          <div className={`classes.textBlock152`}>
            <p></p>
          </div>
          <div className={`classes.textBlock153`}>
            17.7 The Bank shall debit the Customer’s Account with any withdrawal
            or transfer performed by the use of the Card. The Cardholder shall
            in all circumstances accept full responsibility for all transactions
            processed by the use of the Card, whether or not processed with the
            Cardholder’s knowledge or by his/her authority.
          </div>
          <div className={`classes.textBlock154`}>
            <p></p>
          </div>
          <div className={`classes.textBlock155`}>
            17.8 The Bank’s record of transactions processed by the use of the
            debit card shall be conclusive and binding on the customer for all
            purposes.
          </div>
          <div className={`classes.textBlock156`}>
            <p></p>
          </div>
          <div className={`classes.textBlock157`}>
            17.9 The Bank shall not be responsible for any loss arising directly
            or indirectly as a result of the misuse of the Debit Card or the ATM
            or POS.
          </div>
          <div className={`classes.textBlock158`}>
            <p></p>
          </div>
          <div className={`classes.textBlock159`}>
            17.10 The Bank accepts no responsibility for the refusal of any
            merchant establishment to honour the Debit Card on any POS terminal,
            nor shall the Bank be responsible in any way for any of the goods or
            services supplied to the Customer for which payment was made by use
            of the card.
          </div>
          <div className={`classes.textBlock160`}>
            <p></p>
          </div>
          <div className={`classes.textBlock161`}>
            17.11 The Bank shall at its sole discretion determine if the Card is
            eligible for foreign currency related transactions and where
            eligible, the Bank reserves the right to at any time stop the use of
            the card for foreign currency transaction where the Card is a Naira
            denominated card.
          </div>
          <div className={`classes.textBlock162`}>
            <p></p>
          </div>
          <div className={`classes.textBlock163`}>
            17.12 The card is usable on any POS and ATMs. The Bank does not give
            any guarantees or warranties that the POS, ATMs and/or the Card
            itself will always function. The Bank does not guarantee that
            transactions attempted with the card will be successful. The Bank
            disclaims any form of warranties, express or implied terms on the
            use of the card. The Bank shall not be liable for any transaction
            failures or the Customer’s inability to use the card for any web,
            POS and/or the ATM or any other portal for that matter.
          </div>
          <div className={`classes.textBlock164`}>
            17.13 The Customer shall not use the card for fraud or for any
            illegal purposes or any illegal transactions.
          </div>
          <div className={`classes.textBlock165`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="18. AUTHORISED SIGNATORIES">
          <div className={`classes.textBlock167`}>
            <p className={`classes.labelWrapper33`}>
              <span className={`classes.label34`}>
                18.1 A mandate issued by the Customer (whether on the Bank’s
                mandate form or by way of letter and for corporates, supported
                by Board Resolution of the Company) authorizing any person to
                operate the Account and to make other acts and thing will be
                binding on the Accountholder as stipulated in the mandate and
                the Accountholder hereby requests the Bank to rely on such
                mandate which will remain in force and effect until such time as
                the Bank receives from the Accountholder a written revocation of
                the mandate (for corporates, written revocation must be
                supported by a Board resolution). The Accountholder shall
                indemnify and hold the Bank harmless from any and all claims and
                liabilities paid or incurred by the Bank in connection with the
                acceptance of such mandate and the operation of the Account by
                the mandated signatories. The Bank shall be entitled to
                accept/reject the mandate in accordance with the rules governing
                the opening and operation of the Bank Accounts and general
                operational guidelines provided for by law or regulation.
              </span>
            </p>
          </div>
          <div className={`classes.textBlock168`}>
            <p></p>
          </div>
          <div className={`classes.textBlock169`}>
            18.2 Signatories shall have full powers and authority to act on the
            Customer’s behalf within the context of the mandate and subject to
            the terms as the Bank may from time to time prescribe.
          </div>
          <div className={`classes.textBlock170`}>
            <p></p>
          </div>
          <div className={`classes.textBlock171`}>
            18.3 The power and authority of Authorized Signatories shall not be
            affected by any change in the Customer’s ownership, status or
            constitution and Authorized Signatories will be deemed to have power
            and authority to act on behalf of the Customer unless and until the
            Customer advises the Bank otherwise in writing.
          </div>
          <div className={`classes.textBlock172`}>
            <p></p>
          </div>
          <div className={`classes.textBlock173`}>
            18.4 Subject to the Customer’s mandate, the Bank shall without
            inquiry, permit Authorised signatories to deposit in, deliver,
            withdraw, or transfer from the Customer’s Accouints any and all
            funds, instruments or other properties of the Customer and including
            without limitation any disposition for the personal credit or
            Account of an authorized signatory or in payment of the individual
            obligation of an Authorised signatory to the Bank or other persons.
          </div>
          <div className={`classes.textBlock174`}>
            <p></p>
          </div>
          <div className={`classes.textBlock175`}>
            18.5 Except stated in the mandate, the Bank shall have no
            obligations whatseoever to preconfirm an instruction which on the
            face of it is in conformity with the mandate. Provided that the Bank
            reserves the right at its sole discretion to request confirmation
            any instruction (withdrawal or otherwise) from the Customer whether
            or not a withdrawal instruction is within the Bank’s or customer’s
            confirmation threshold and whether or not the instructions appear to
            have been validly issued.
          </div>
          <div className={`classes.textBlock176`}>
            <p></p>
          </div>
          <div className={`classes.textBlock177`}>
            18.6 The Customer agrees and holds the Bank absolutely harmless for
            requesting confirmation of any instruction from the Customer and/or
            signatories before honouring any instruction whether or not on the
            face of it, the instruction appears to have been validly issued.The
            Customer hereby waives any right of objection and/or protestation
            arising from the Bank&#39;s refusal of an instruction subject to its
            receipt of the confirmation of the instructions.
          </div>
          <div className={`classes.textBlock178`}>
            <p></p>
          </div>
          <div className={`classes.textBlock179`}>
            18.7 The customer agrees that the Bank may seek and secure such
            confirmation either through any means, including surface mail, or
            via the customer’s email, or mobile line on the Bank’s records. The
            Customer agrees that the Bank shall not be liable for seeking
            confirmation through any of the available communication means either
            currently existing or that may hereafter come into use.
          </div>
          <div className={`classes.textBlock180`}>
            <p></p>
          </div>
          <div className={`classes.textBlock181`}>
            18.8 The customer agrees that he/she is responsible for the proper
            keep of any cheque book or instrument of withdrawal issued to the
            Customer by the Bank on this Account. The customer hereby
            indemnifies and holds the Bank free from any liability that may
            arise as a result of the Bank honouring any request on a cheque or
            any other withdrawal instrument appearing to be in line with its
            mandate.
          </div>
          <div className={`classes.textBlock182`}>
            <p></p>
          </div>
          <div className={`classes.textBlock183`}>
            18.9 The Customer agrees that in the event of loss of any cheque
            leave or the cheque book or any other instrument of withdrawal, it
            shall promptly notify the Bank of such loss. The Customer agrees
            that the Customer shall be responsible for all losses arising from
            any delay to promptly notify the Bank of the loss, theft and/or
            misplacement of any cheque leave or cheque book or withdrawal
            instrument of any kind in the Customer’s custody.
          </div>
          <div className={`classes.textBlock184`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="19 CHANGES TO T &amp; C">
          <div className={`classes.textBlock186`}>
            <p className={`classes.labelWrapper35`}>
              <span className={`classes.label36`}>
                We may, at our discretion, change these terms and conditions
                (including our charges) and introduce changes to and charges for
                our services at any time. Notice of such changes would be
                provided prior to implementation.
              </span>
            </p>
          </div>
          <div className={`classes.textBlock187`}>
            <p></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="20. INTELLECTUAL PROPERTY">
          <div className={`classes.textBlock189`}>
            <p className={`classes.labelWrapper37`}>
              <span className={`classes.label38`}>
                209.1 This Agreement does not transfer to the Merchant any
                ownership or proprietary rights in the Bank’s software used to
                provide the service or other intellectual property of the Bank.
                All rights, title, and interest in and to the Bank’s technology
                will remain solely with the Bank or its subcontractors.
              </span>
            </p>
          </div>
          <div className={`classes.textBlock190`}>
            <p></p>
          </div>
          <div className={`classes.textBlock191`}>
            20.2 The Merchant shall not or attempt to reverse engineer,
            disassemble, decompile, or otherwise manipulate the Bank’s systems.
          </div>
          <div className={`classes.textBlock192`}>
            <p className={`classes.labelWrapper38`}></p>
          </div>
        </TermsSubAccordion>

        <TermsSubAccordion text="21. GOVERNING LAW">
          <div className={`classes.textBlock194`}>
            <p className={`classes.labelWrapper39`}>
              <span className={`classes.label39`}>
                21.1 The agreement between you and us is governed by the laws of
                the Federal Republic of Nigeria.
              </span>
            </p>
          </div>
          <div className={`classes.textBlock195`}>
            <p></p>
          </div>
          <div className={`classes.textBlock196`}>
            21.2 Any dispute, difference or disagreement arising out of or in
            connection with the provisions of this terms and conditions or with
            the service offered by the Bank, shall be finally settled by
            arbitration in accordance with the Arbitration and Conciliation Act,
            Cap 19, 1990, by one arbitrator appointed in accordance with the
            provisions of the Act. Arbitration shall take place in Lagos and be
            conducted in English language.
          </div>
          <div className={`classes.textBlock197`}>
            <p></p>
          </div>
          <div className={`classes.textBlock198`}>
            21.3 No-one else apart from you will have any rights or be able to
            enforce these terms and conditions.
          </div>
        </TermsSubAccordion>
      </div>
    );
  }
);
