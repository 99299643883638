import { ReactComponent as ModalClose } from "alat-components-library/assets/images/icons/modal-close.svg";
import { useFetchNotificationDetails } from "hooks/apis/Notifications/useFetchNotificationDetails";
import { useGetNotifications } from "hooks/apis/Notifications/useGetNotifications";
import { useAppDispatch } from "hooks/useReduxHook";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  setSkipAndTake,
  useNotificationState,
} from "react-wrappers/stores/redux/notifications/slices";
import dayjs from "utils/dayjsLib";
import { NotificationStatusEnum, NotificationTypeEnum } from "utils/enums";
import CurrencyLogo from "../../../assets/icons/currency.svg";
import SoftTokenLogo from "../../../assets/icons/softToken.svg";
import UserLogo from "../../../assets/icons/user.svg";
import AirtimeLogo from "../../../assets/icons/airtime.svg";
import { Pagination } from "../../../components/Dashboard/User/Pagination";
import PageLoader from "components/Loader/PageLoader";

export const NotificationsList = ({
  notifications,
  handleViewNext,
  handleViewPrevious,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleMarkAsRead } = useGetNotifications();
  const { notificationsPagination, loading } = useNotificationState();
  const [showFailedTransactionModal, setShowFailedTransactionModal] =
    useState(false);
  const { transactionDetail, handleFetchTransactionDetails } =
    useFetchNotificationDetails();

  const isLoading = loading?.includes("getting-notifications");

  useEffect(() => {
    dispatch(
      setSkipAndTake({
        skip: 1,
        take: 10,
      })
    );
  }, []);

  const notificationsIcon = (notifications: any) => {
    if (
      notifications?.notificationType == NotificationTypeEnum["Transaction"] &&
      notifications?.title?.includes("Airtime")
    ) {
      return AirtimeLogo;
    }
    if (
      notifications?.notificationType == NotificationTypeEnum["Transaction"]
    ) {
      return CurrencyLogo;
    }
    if (
      notifications?.notificationType ==
      NotificationTypeEnum["SoftTokenRequest"]
    ) {
      return SoftTokenLogo;
    }
    if (
      notifications?.notificationType == NotificationTypeEnum["UserCreation"]
    ) {
      return UserLogo;
    }
    if (
      notifications?.notificationType ==
      NotificationTypeEnum["UserModification"]
    ) {
      return UserLogo;
    }

    return CurrencyLogo;
  };

  const handleFailedTransaction = async (correlationId: string) => {
    await handleFetchTransactionDetails(correlationId);
    setShowFailedTransactionModal(true);
  };

  const handleNotificationsClicked = (item: any) => {
    if (!item.isRead) {
      const payload = {
        notificationsIds: [item.id],
      };
      handleMarkAsRead(payload);
    }
    if (
      item.notificationType == NotificationTypeEnum["Transaction"] &&
      item.status == NotificationStatusEnum["Failed"]
    ) {
      handleFailedTransaction(item.correlationId);
      return;
    }
    navigate(
      `/notifications/details?correlationId=${item.correlationId}&notificationsType=${item.notificationType}&status=${item.status}`
    );
  };

  const formatNotificationTime = (date: string) => {
    const date1 = dayjs(date);
    const date2 = dayjs();

    let hours = date2.diff(date1, "hours");

    switch (true) {
      case hours < 24:
        return dayjs(date).format("hh:mm a");
      case hours > 24 && hours < 48:
        return "yesterday";
      default:
        return dayjs(date).format("DD/MM/YYYY");
    }
  };

  return (
    <div>
      {/* {isLoading && <PageLoader message="Please wait..." />} */}
      {notifications?.notifications?.length > 0 && (
        <div className="mx-auto bg-white md:pb-20 pb-10 md:px-10 px-2 pt-5">
          {notifications?.notifications.map((n: any) => (
            <div
              key={n.id}
              onClick={() => handleNotificationsClicked(n)}
              className={
                !n.isRead
                  ? "font-bold md:px-5 px-0 pt-5 pb-3 md:mx-10 mx-2 flex justify-between border-b-2 cursor-pointer"
                  : "md:px-5 px-0 pt-5 pb-3 md:mx-10 mx-2 flex justify-between border-b-2 cursor-pointer"
              }
            >
              <div className="flex gap-4">
                <img src={notificationsIcon(n)} alt="currency" />
                <div>
                  <p className="text-sm mb-2">{n.title}</p>
                  <p className="text-xs">{n.message}</p>
                </div>
              </div>
              <p className=" text-xs">{formatNotificationTime(n.dateAdded)}</p>
            </div>
          ))}
          <Pagination
            paginationVariant={
              "bg-white h-20 w-full flex flex-row justify-between items-center px-4 rounded-b-sm"
            }
            dataToShow={notificationsPagination}
            handleNext={handleViewNext}
            handlePrev={handleViewPrevious}
          />
          {showFailedTransactionModal && (
            <div
              className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-10 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm"
              style={{ margin: 0 }}
            >
              <center className="z-10">
                <div className="flex flex-col py-5 px-5 md:w-[700px] w-full h-[560px] relative mb-4 bg-white rounded-lg overflow-scroll">
                  <div className=" text-lg font-bold py-5">
                    Transaction details
                  </div>
                  <p
                    id="close-modal_btn"
                    className="text-right text-xs absolute right-5 top-5 cursor-pointer"
                    onClick={() => {
                      setShowFailedTransactionModal(false);
                    }}
                  >
                    <ModalClose />
                  </p>

                  <div className=" border-2 rounded-md w-full p-5">
                    <div className="w-full border-b border-b-[#E2E8F0] flex flex-col items-start justify-start p-3">
                      <p className="text-[10.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                        Transaction Date:
                      </p>
                      <p
                        className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                        id="transaction-type_text"
                      >
                        {transactionDetail?.date}
                      </p>
                    </div>

                    <div className="w-full border-b border-b-[#E2E8F0] flex flex-col items-start justify-start p-3">
                      <p className="text-[10.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                        Reference Number:
                      </p>
                      <p
                        className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                        id="transaction-type_text"
                      >
                        {transactionDetail?.reference}
                      </p>
                    </div>

                    <div className="w-full border-b border-b-[#E2E8F0] flex flex-col items-start justify-start p-3">
                      <p className="text-[10.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                        Transaction Status:
                      </p>
                      <p
                        className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                        id="transaction-type_text"
                      >
                        Failed
                      </p>
                    </div>

                    <div className="w-full border-b border-b-[#E2E8F0] flex flex-col items-start justify-start p-3">
                      <p className="text-[10.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                        Currency:
                      </p>
                      <p
                        className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                        id="transaction-type_text"
                      >
                        {transactionDetail?.currency}
                      </p>
                    </div>
                    <div className="w-full border-b border-b-[#E2E8F0] flex flex-col items-start justify-start p-3">
                      <p className="text-[10.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                        Transaction Amount:
                      </p>
                      <p
                        className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                        id="transaction-type_text"
                      >
                        {transactionDetail?.amount}
                      </p>
                    </div>
                  </div>

                  <table
                    style={{ height: "10px" }}
                    className="table-auto min-w-max md:w-full overflow-x-auto text-xs font-semibold text-center py-2 p-1 px-4"
                  >
                    <tbody></tbody>
                  </table>
                </div>
              </center>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
