import { FormikButton } from "alat-components-library";
import { useAppDispatch } from "hooks/useReduxHook";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CenterModal from "alat-components-library/Modal/CenterModal";
import { ReactComponent as SuccessIcon } from "assets/svgs/success-icon.svg";
import { RefereeCard } from "./RefereeCard";
import PendingActionsWrapper from "../../components/PendingActionsWrapper";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { Form, Formik } from "formik";
import {
  deleteReferee,
  updateFirstReferee,
  updateSecondReferee,
} from "react-wrappers/stores/redux/onboarding/slices";
import {
  saveRejectedDocuments,
  setRejectedRefereeCount,
} from "react-wrappers/stores/redux/pendingActions/slices";

type RefereeType = {
  Bank: string;
  EmailAddress: string;
  FirstName: string;
  LastName: string;
};

// Checks the state before destructuring occurs
const RejectedReferees = () => {
  const location = useLocation();
  if (!location?.state?.pendingData) {
    window.location.replace("/user-dashboard");
    return <div></div>;
  }
  return <RejectedRefereesInner />;
};

const RejectedRefereesInner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const referees = useAppSelector((state) => state.onboarding.refereePayload);
  // const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [successModal, setSuccessModal] = useState(false);
  const {
    reason,
    cacRegNumber,
    pendingActionType,
    actionData,
    pendingActionId,
  } = location?.state?.pendingData;
  const referees = JSON.parse(actionData);
  // console.log(referees);

  const filteredReferees = referees.filter(
    (referee: any) => referee?.Bank !== ""
  );

  const updateRejectedRefereesIds = (refs: any) => {
    dispatch(deleteReferee(0));
    dispatch(deleteReferee(1));

    if (refs?.lengh === 1) {
      dispatch(
        updateFirstReferee({
          id: refs[0]?.Id,
          bank: "",
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          accountNumber: "",
        })
      );
    }
    if (refs?.length === 2) {
      dispatch(
        updateFirstReferee({
          id: refs[0]?.Id,
          bank: "",
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          accountNumber: "",
        })
      );
      dispatch(
        updateSecondReferee({
          id: refs[1]?.Id,
          bank: "",
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          accountNumber: "",
        })
      );
    }
  };

  useEffect(() => {
    // console.log("Before uppdate: ", referees);
    updateRejectedRefereesIds(referees);
    dispatch(setRejectedRefereeCount(referees?.length));
    dispatch(
      saveRejectedDocuments({
        pendingAction: pendingActionType,
        pendingActionId,
        cacRegNumber,
        actionData,
      })
    );
  }, []);
  // console.log("The referees are: ", referees);
  return (
    <DashboardWrapper>
      <PendingActionsWrapper cardTitle="Rejected Referees">
        <div className="md:w-[500px] w-full mx-auto py-5">
          <div className="flex flex-col gap-1">
            {referees
              .filter((referee: RefereeType) => referee?.Bank !== "")
              .map(
                (
                  {
                    Bank: bank,
                    EmailAddress: emailAddress,
                    FirstName: firstName,
                    LastName: lastName,
                  }: RefereeType,
                  index: number
                ) => {
                  return (
                    // eslint-disable-next-line
                    <div key={index}>
                      <RefereeCard
                        id={`referee-${index}_card`}
                        actionId={`action-${index}_btn`}
                        deleteId={`delete-${index}_btn`}
                        editId={`edit-${index}_btn`}
                        index={index}
                        bank={bank}
                        email={emailAddress}
                        firstName={firstName}
                        lastName={lastName}
                        filteredReferees={filteredReferees}
                        reason={reason?.[index]}
                      />
                    </div>
                  );
                }
              )}
          </div>
          <Formik
            initialValues={{}}
            onSubmit={() => {
              // ToastNotify({
              //   type: "danger",
              //   message: "hello",
              //   position: "top-right",
              // });
              navigate("/pending-actions/rejected-docs/add-referee", {
                state: { referees },
              });
            }}
          >
            {() => (
              <Form>
                <FormikButton
                  id="submit-referees_btn"
                  labelText="Add new referees"
                  containerVariant="w-full h-12 rounded-md flex justify-center my-10"
                  // isDisabled={true}
                />
              </Form>
            )}
          </Formik>
        </div>

        {/*Referee Saved Successfully*/}
        {successModal && (
          <CenterModal
            icon={<SuccessIcon />}
            title="Success!"
            info={`A referee verification link has been sent to the emails (${referees[0].emailAddress} and ${referees[1].emailAddress}), Kindly ensure your referees respond via the link sent).`}
            handleClick={() => {
              // resetReferees();
              navigate("/user-dashboard");
            }}
            handleClose={() => {
              // resetReferees();
              setSuccessModal(false);
            }}
            handleClick2={() => {
              // resetReferees();
              navigate("/user-dashboard");
            }}
            btnText="Okay"
            btnText2={"Return to dashboard"}
            showCloseIcon
          />
        )}
      </PendingActionsWrapper>
    </DashboardWrapper>
  );
};

export default RejectedReferees;
