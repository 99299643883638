import DashboardWrapper from "components/Layouts/DashboardWrapper";
import BackArrow from "../../../assets/svgs/BackArrow.svg";
import { ReactComponent as PrevSlideBtn } from "../../../alat-components-library/assets/images/icons/back-arrow.svg";
import React from "react";
import { useNavigate } from "react-router-dom";
import PageLoader from "components/Loader/PageLoader";
import PendingItem from "./PendingItem";
import { pendingActionsList } from "./mockData";

const PendingActions = () => {
  const navigate = useNavigate();

  return (
    <DashboardWrapper>
      <div className="w-full">
        <div className="flex justify-start mt-4 md:mx-16 items-center">
          <img
            src={BackArrow}
            alt="<"
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          />
          <p className="text-[#333333] font-bold md:text-2xl text-lg">
            Pending Actions
          </p>
        </div>
        <div className="w-screen md:w-full h-full">
          {false && <PageLoader message="Fetching Pending Actions" />}
          <div className="w-screen md:w-[1000px] mx-auto my-5 bg-white h-auto rounded-lg min-h-[400px]">
            <div className="relative py-2 flex justify-between md:px-4">
              <div
                id="back_btn"
                onClick={() => navigate(-1)}
                className="cursor-pointer my-auto"
              >
                <PrevSlideBtn fill="black" height="30px" />
              </div>
              <div className="py-1">
                <p className="text-lg font-semibold">Pending Actions</p>
              </div>
              <div></div>
            </div>
            <hr />
            <div className="w-full px-10 py-4 pb-10">
              {pendingActionsList.map((item, index) => (
                <PendingItem
                  icon=""
                  title={item?.title}
                  subtitle={item?.subtitle}
                  index={index}
                  time={item?.time}
                  route={item.route}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default PendingActions;
