import React from "react";
// import AFBLogo from "../../../assets/icons/afb-white-logo.svg";
import AlatLogo from "../../../assets/svgs/alat-logo.svg";
import AFBLogo from "../../../assets/svgs/AFBLogo.svg";
import WemaLogo from "../../../assets/svgs/wema-color-logo.svg";
// import WemaLogo from "../../../assets/icons/wema-white-logo.svg";
import { ReactComponent as LinkedinLogo } from "../../../assets/icons/socials/Linkedin.svg";
import { ReactComponent as FacebookLogo } from "../../../assets/icons/socials/facebook.svg";
import { ReactComponent as InstagramLogo } from "../../../assets/icons/socials/instagram.svg";
import { ReactComponent as TwitterLogo } from "../../../assets/icons/socials/twitter.svg";
import { useNavigate } from "react-router-dom";
import { useAppState } from "react-wrappers/stores/redux/app/slices";
const Footer = () => {
  const { isInIndividualRefereeView } = useAppState();
  const navigate = useNavigate();

  return (
    <div className="w-[100vw] flex flex-col items-center justify-center bg-black text-white border-t border-white">
      <div className="md:w-[1118px] w-full md:px-0 px-5 pt-10 flex justify-between gap-4 pb-20">
        <div className="flex flex-col gap-3">
          <p className="font-bold text-[16.2px] leading-[21.88px]">Company</p>
          <p
            className="cursor-pointer text-[14.4px] leading-[19.16px] hover:text-lib-alat-red"
            onClick={() => navigate("/terms-and-conditions")}
          >
            Privacy Policy
          </p>
        </div>
        <div className="flex flex-col items-end gap-3">
          <p className="font-bold text-[16.2px] leading-[21.88px]">
            Connect with us
          </p>
          <p className="cursor-pointer hover:text-lib-alat-red text-[14.4px] leading-[19.16px]">
            <a href="mailto:help@alat.ng">Help@Alat.ng</a>
          </p>
          <div className="flex gap-2 items-center">
            <div className="flex gap-2 items-center">
              <FacebookLogo
                className="cursor-pointer hover:text-lib-alat-red"
                onClick={() =>
                  window.open("https://www.facebook.com/wemabankplc", "_blank")
                }
              />
              <TwitterLogo
                className="cursor-pointer hover:text-lib-alat-red"
                onClick={() =>
                  window.open("https://twitter.com/wemabank", "_blank")
                }
              />
              <LinkedinLogo
                className="cursor-pointer hover:text-lib-alat-red"
                onClick={() =>
                  window.open(
                    "https://ng.linkedin.com/company/wemabankplc",
                    "_blank"
                  )
                }
              />
              <InstagramLogo
                className="cursor-pointer hover:text-lib-alat-red"
                onClick={() =>
                  window.open("https://www.instagram.com/wemabank", "_blank")
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:hidden flex items-center justify-between p-5">
        <div>
          {isInIndividualRefereeView ? (
            <img src={AlatLogo} className="cursor-pointer" alt="alt" />
          ) : (
            <img src={AFBLogo} className="cursor-pointer " alt="alt" />
          )}
        </div>
        <div>
          <img src={WemaLogo} className="cursor-pointer" alt="alt" />
        </div>
      </div>
      <div className="md:w-[1118px] w-full border-t border-[#c4c4c4] py-5 md:mx-24">
        {/* <p className="text-center md:py-4">© 2023 All Rights Reserved.</p> */}
        <div className="flex items-center md:justify-between justify-center">
          <div className="md:block hidden">
            {isInIndividualRefereeView ? (
              <img src={AlatLogo} className="cursor-pointer" alt="alt" />
            ) : (
              <img src={AFBLogo} className="cursor-pointer " alt="alt" />
            )}
          </div>
          <div className="flex flex-col gap-1 items-center justify-center">
            <p className="text-[14px] text-center leading-[18.9px]">
              © ALAT 2023 All Rights Reserved.
            </p>
            <p className="text-[12px] text-center font-medium leading-[19.08px]">
              We’d love to let you know about our products, ALAT news and offers
              via email. You may withdraw your
            </p>
            <p className="text-[12px] text-center font-medium leading-[19.08px]">
              consent at any time by following the unsubscribe link.
            </p>
          </div>
          <div className="md:block hidden">
            <img src={WemaLogo} className="cursor-pointer" alt="alt" />
          </div>
        </div>

        {/* <p className="text-center md:py-4 text-xs">
          We’d love to let you know about our products, ALAT news and offers via
          email. You may withdraw your consent at any time by following the{" "}
          <span className="underline cursor-pointer" onClick={() => {}}>
            unsubscribe link.
          </span>
        </p> */}
      </div>
      {/* <div className="w-[100vw] md:px-24 px-5 pt-10 flex md:justify-between flex-col md:flex-row gap-4 pb-20">
        <div className="hidden md:flex flex-col gap-1">
          <div className="flex h-10 justify-start">
            {isInIndividualRefereeView ? (
              <img
                src={AlatLogo}
                className="cursor-pointer border-r"
                alt="alt"
              />
            ) : (
              <img
                src={AFBLogo}
                className="cursor-pointer border-r"
                alt="alt"
              />
            )}
            <img src={WemaLogo} className="cursor-pointer" alt="alt" />
          </div>
          <div className="flex text-[10px] justify-start pl-5">
            Alat©2023 | All rights reserved
          </div>
        </div>
        Mobile
        <div className="flex flex-col gap-4">
          <div className="flex">
            <div className="flex md:hidden justify-between text-xs">
              <div className="flex flex-col text-xs mr-2">
                <p className="font-bold cursor-pointer text-lg">Company</p>
                <p className="cursor-pointer hover:text-lib-alat-red">
                  About Alat
                </p>
                <p className="cursor-pointer hover:text-lib-alat-red">
                  Wema bank
                </p>
                <p className="cursor-pointer hover:text-lib-alat-red">
                  <a href="mailto:help@alat.ng">Help center</a>
                </p>
                <p className="cursor-pointer hover:text-lib-alat-red">
                  Security and fraud
                </p>
                <p className="cursor-pointer hover:text-lib-alat-red">
                  Website Accessibility
                </p>
                <p
                  className="cursor-pointer hover:text-lib-alat-red"
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  Privacy Policy
                </p>
              </div>
              <div className="hidden md:flex flex-col text-xs w-1/3">
                <div className="flex justify-between">
                  <div className="flex flex-col">
                    <div className="hidden md:flex flex-col text-xs gap-2">
                      <div className="flex flex-col">
                        <p className="font-bold cursor-pointer text-lg">
                          Need Help?
                        </p>
                        <p className="cursor-pointer hover:text-lib-alat-red">
                          <a href="mailto:help@alat.ng">Help@Alat.ng</a>
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="font-bold cursor-pointer text-lg">
                          Socials
                        </p>
                        <div className="flex gap-2 items-center">
                          <FacebookLogo
                            className="cursor-pointer hover:text-lib-alat-red"
                            onClick={() =>
                              window.open(
                                "https://www.facebook.com/wemabankplc",
                                "_blank"
                              )
                            }
                          />
                          <TwitterLogo
                            className="cursor-pointer hover:text-lib-alat-red"
                            onClick={() =>
                              window.open(
                                "https://twitter.com/wemabank",
                                "_blank"
                              )
                            }
                          />
                          <LinkedinLogo
                            className="cursor-pointer hover:text-lib-alat-red"
                            onClick={() =>
                              window.open(
                                "https://ng.linkedin.com/company/wemabankplc",
                                "_blank"
                              )
                            }
                          />
                          <InstagramLogo
                            className="cursor-pointer hover:text-lib-alat-red"
                            onClick={() =>
                              window.open(
                                "https://www.instagram.com/wemabank",
                                "_blank"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  Quicklinks
                  <div className="flex flex-col gap-2">
                    <p className="font-bold cursor-pointer text-lg">
                      Quick Links
                    </p>
                    <p
                      className="cursor-pointer hover:text-lib-alat-red"
                      onClick={() => navigate("/signup")}
                    >
                      Join Alat
                    </p>
                    <p className="cursor-pointer hover:text-lib-alat-red">
                      Developer portal
                    </p>
                  </div>
                </div>
                <div className="text-white"></div>
              </div>
            </div>
            <div className="md:hidden flex flex-col text-xs w-1/3">
              <div className="flex justify-between">
                Need Help?
                <div className="flex flex-col">
                  <div className="md:hidden flex flex-col text-xs gap-2">
                    <div className="flex flex-col">
                      <p className="font-bold cursor-pointer text-lg">
                        Need Help?
                      </p>
                      <p className="cursor-pointer hover:text-lib-alat-red">
                        <a href="mailto:help@alat.ng">Help@Alat.ng</a>
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-bold cursor-pointer text-lg">
                        Socials
                      </p>
                      <div className="flex gap-2 items-center">
                        <FacebookLogo
                          className="cursor-pointer hover:text-lib-alat-red"
                          onClick={() =>
                            window.open(
                              "https://www.facebook.com/wemabankplc",
                              "_blank"
                            )
                          }
                        />
                        <TwitterLogo
                          className="cursor-pointer hover:text-lib-alat-red"
                          onClick={() =>
                            window.open(
                              "https://twitter.com/wemabank",
                              "_blank"
                            )
                          }
                        />
                        <LinkedinLogo
                          className="cursor-pointer hover:text-lib-alat-red"
                          onClick={() =>
                            window.open(
                              "https://ng.linkedin.com/company/wemabankplc",
                              "_blank"
                            )
                          }
                        />
                        <InstagramLogo
                          className="cursor-pointer hover:text-lib-alat-red"
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/wemabank",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                Quicklinks
                <div className="flex-col gap-2 hidden">
                  <p className="font-bold cursor-pointer text-lg">
                    Quick Links
                  </p>
                  <p
                    className="cursor-pointer hover:text-lib-alat-red"
                    onClick={() => navigate("/signup")}
                  >
                    Join Alat
                  </p>
                  <p className="cursor-pointer hover:text-lib-alat-red">
                    Developer portal
                  </p>
                </div>
              </div>
              <div className="text-white"></div>
            </div>
            <div className="md:hidden flex flex-col text-xs w-1/3">
              <div className="flex justify-between">
                Need Help?
                <div className="flex flex-col">
                  <div className="hidden md:flex flex-col text-xs gap-2">
                    <div className="flex flex-col">
                      <p className="font-bold cursor-pointer text-lg">
                        Need Help?
                      </p>
                      <p className="cursor-pointer hover:text-lib-alat-red">
                        <a href="mailto:help@alat.ng">Help@Alat.ng</a>
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-bold cursor-pointer text-lg">
                        Socials
                      </p>
                      <div className="flex gap-2 items-center">
                        <FacebookLogo
                          className="cursor-pointer hover:text-lib-alat-red"
                          onClick={() =>
                            window.open(
                              "https://www.facebook.com/wemabankplc",
                              "_blank"
                            )
                          }
                        />
                        <TwitterLogo
                          className="cursor-pointer hover:text-lib-alat-red"
                          onClick={() =>
                            window.open(
                              "https://twitter.com/wemabank",
                              "_blank"
                            )
                          }
                        />
                        <LinkedinLogo
                          className="cursor-pointer hover:text-lib-alat-red"
                          onClick={() =>
                            window.open(
                              "https://ng.linkedin.com/company/wemabankplc",
                              "_blank"
                            )
                          }
                        />
                        <InstagramLogo
                          className="cursor-pointer hover:text-lib-alat-red"
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/wemabank",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                Quicklinks
                <div className="flex flex-col gap-2">
                  <p className="font-bold cursor-pointer text-lg">
                    Quick Links
                  </p>
                  <p
                    className="cursor-pointer hover:text-lib-alat-red"
                    onClick={() => navigate("/signup")}
                  >
                    Join Alat
                  </p>
                  <p className="cursor-pointer hover:text-lib-alat-red">
                    Developer portal
                  </p>
                </div>
              </div>
              <div className="text-white"></div>
            </div>
          </div>

          <div className="md:hidden flex flex-col gap-1 items-center">
            <div className="flex h-10 justify-start">
              <img
                src={AFBLogo}
                className="cursor-pointer border-r"
                alt="alt"
              />
              <img src={WemaLogo} className="cursor-pointer" alt="alt" />
            </div>
            <div className="flex text-[10px] justify-start pl-5">
              Alat©2023 | All rights reserved
            </div>
          </div>
        </div>
        <div className="hidden md:flex flex-col text-xs gap-2">
          <p className="font-bold cursor-pointer text-lg">Company</p>
          <p className="cursor-pointer hover:text-lib-alat-red">About Alat</p>
          <p className="cursor-pointer hover:text-lib-alat-red">Wema bank</p>
          <p className="cursor-pointer hover:text-lib-alat-red">
            <a href="mailto:help@alat.ng">Help center</a>
          </p>
          <p className="cursor-pointer hover:text-lib-alat-red">
            Security and fraud
          </p>
          <p className="cursor-pointer hover:text-lib-alat-red">
            Website Accessibility
          </p>
          <p
            className="cursor-pointer hover:text-lib-alat-red"
            onClick={() => navigate("/terms-and-conditions")}
          >
            Privacy Policy
          </p>
        </div>

        <div className="hidden md:flex flex-col text-xs w-1/3">
          <div className="flex justify-between">
            
            <div className="flex flex-col">
              <div className="hidden md:flex flex-col text-xs gap-2">
                <div className="flex flex-col">
                  <p className="font-bold cursor-pointer text-lg">Need Help?</p>
                  <p className="cursor-pointer hover:text-lib-alat-red">
                    <a href="mailto:help@alat.ng">Help@Alat.ng</a>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold cursor-pointer text-lg">Socials</p>
                  <div className="flex gap-2 items-center">
                    <FacebookLogo
                      className="cursor-pointer hover:text-lib-alat-red"
                      onClick={() =>
                        window.open(
                          "https://www.facebook.com/wemabankplc",
                          "_blank"
                        )
                      }
                    />
                    <TwitterLogo
                      className="cursor-pointer hover:text-lib-alat-red"
                      onClick={() =>
                        window.open("https://twitter.com/wemabank", "_blank")
                      }
                    />
                    <LinkedinLogo
                      className="cursor-pointer hover:text-lib-alat-red"
                      onClick={() =>
                        window.open(
                          "https://ng.linkedin.com/company/wemabankplc",
                          "_blank"
                        )
                      }
                    />
                    <InstagramLogo
                      className="cursor-pointer hover:text-lib-alat-red"
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/wemabank",
                          "_blank"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
           
            <div className="flex flex-col gap-2">
              <p className="font-bold cursor-pointer text-lg">Quick Links</p>
              <p
                className="cursor-pointer hover:text-lib-alat-red"
                onClick={() => navigate("/signup")}
              >
                Join Alat
              </p>
              <p className="cursor-pointer hover:text-lib-alat-red">
                Developer portal
              </p>
            </div>
          </div>
          <div></div>
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
