import React from "react";
import PageHeader from "../../components/reusables/Page/PageHeader";
// import FundsTransferTab from "../../components/Payments/FundsTransferTab";
import BankTransferTab from "../../components/Payments/BankTransferTab";
import DashboardWrapper from "../../components/Layouts/DashboardWrapper";

const BankTransfer = () => {
  return (
    <DashboardWrapper>
      <div className="lg:px-20 space-y-4 mt-5">
        <div className="flex justify-between items-center px-5">
          <PageHeader title="Bank Transfer" />
        </div>
        <BankTransferTab />
      </div>
    </DashboardWrapper>
  );
};

export default BankTransfer;
