import { useNavigate } from "react-router-dom";
import { Table } from "flowbite-react";
import { formatNumberToCurrency } from "utils/helperFunctions";
import { handlePendingActionNavigation } from "components/Dashboard/components/utils";

interface UserListBodyProps {
  list: any[];
}

export const PendingTransactionsBody = ({ list }: UserListBodyProps) => {
  const navigate = useNavigate();

  return (
    <>
      {list?.length > 0 ? (
        <>
          {list?.map((item: any, index) => (
            <Table.Row
              id={`request-${index}_btn`}
              onClick={() => {
                navigate(handlePendingActionNavigation(item));
                // [6, 7]?.includes(item?.type)
                //   ? navigate(
                //       `/payments/requests/details/${item?.correlationId}`
                //     )
                //   : navigate(
                //       `/payments/transaction/details?correlationId=${item?.correlationId}`
                //     );
              }}
              key={item?.correlationId}
              className={`h-14 bg-white hover:bg-afb-gray-one dark:hover:bg-afb-gray-one cursor-pointer request-${index}_btn`}
            >
              <Table.Cell className="whitespace-nowrap text-lib-alat-black flex flex-col text-xs">
                <p className="text-xs" id={`accountName-${index}_text`}>
                  {item?.sourceAccountName === ""
                    ? item?.initiatedByFullName
                    : item?.sourceAccountName}
                </p>
                <p className="text-xs" id={`initiatedBy-${index}_text`}>
                  {item?.initiatedBy}
                </p>
              </Table.Cell>
              <Table.Cell
                className={`text-lib-alat-black text-xs formattedAmount-${index}_text`}
                id={`formattedAmount-${index}_text`}
              >
                {formatNumberToCurrency({
                  currencyCode:
                    item?.sourceAccountCurrencyCode !== ""
                      ? item?.sourceAccountCurrencyCode
                      : 1,
                  number: item?.amount,
                })}
              </Table.Cell>
              <Table.Cell
                className={`text-lib-alat-black text-xs typename-${index}_text`}
                id={`typename-${index}_text`}
              >
                {item?.typeName}
              </Table.Cell>
              <Table.Cell
                className={`text-lib-alat-black text-xs narration-${index}_text`}
                id={`narration-${index}_text`}
              >
                {item?.narration}
              </Table.Cell>
              <Table.Cell
                className={`text-lib-alat-black text-xs dateCreated-${index}_text`}
                id={`dateCreated-${index}_text`}
              >
                <span>{item?.dateCreated}</span>
              </Table.Cell>
              <Table.Cell className="">
                <div
                  style={{ background: "rgba(255, 153, 0, 0.2)" }}
                  className="text-[#FF9900] cursor-pointer border border-[#FF9900] px-2 py-1 text-xs text-center rounded"
                  id={`status-${index}_text`}
                >
                  {item?.status ?? "Pending Approval"}
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </>
      ) : (
        <>
          <div className="h-20  flex justify-center items-center ">
            <div className="text-center text-sm w-full absolute left-0 ">
              <div className="flex flex-col items-center gap-3">
                <p
                  className="leading-4 text-[#C4C4C4]"
                  id="noPendingRequest_text"
                >
                  You do not have any pending request
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
