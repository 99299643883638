import { TextInput } from "alat-components-library";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { ReactNode, useEffect, useState } from "react";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { setPendingTransactionsResponse } from "react-wrappers/stores/redux/payments/slices";
import { PUT_APROVE_OR_REJECT_TRANSACTION_ACTION } from "react-wrappers/stores/redux/payments/actions";
import { formatNumberToCurrency } from "utils/helperFunctions";
import { useAppDispatch } from "hooks/useReduxHook";
import { ReactComponent as BackArrow } from "../../../alat-components-library/assets/images/icons/back-arrow-two.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as ModalClose } from "../../../alat-components-library/assets/images/icons/modal-close.svg";
import { ToastNotify } from "helpers/toastNotify";
import PageLoader from "components/Loader/PageLoader";
import { usePendingAccountStatementDetail } from "hooks/apis/Payment/AccountStatement/usePendingAccountStatementDetail";
import dayjs from "dayjs";

export const handleAmount = (amount: number) =>
  formatNumberToCurrency({
    number: amount,
  });
export const ListItem = ({
  name,
  value,
  classname = "",
}: {
  name: string;
  value: string | ReactNode;
  classname?: string;
}) => (
  <div
    className={`${classname} flex border-b border-[#E2E8F0] items-center justify-between text-lib-alat-black`}
  >
    <p className="text-[12.92px] py-6">{name}</p>
    <div className="text-right text-[15.28px]">{value}</div>
  </div>
);
const PendingStatementDetail = () => {
  const [formPayload, setFormPayload] = useState({
    transactionPin: "",
    token: "",
    remark: "",
  });
  const [rejectionModal, setRejectionModal] = useState(false);
  const [remarkMessage, setRemarkMessage] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const [isLoading, setIsLoading] = useState(false);

  const correlationId = searchParams.get("correlationId");

  const { pendingData, pendingLoading, handlePendingStatementDetails } =
    usePendingAccountStatementDetail();
  const isVerifier = user?.role === "Verifier";

  useEffect(() => {
    correlationId && handlePendingStatementDetails(correlationId);
    // eslint-disable-next-line
  }, [correlationId]);

  const handleApproveOrRejct = (approvalAction: string) => {
    if (
      user?.hasSoftToken === false &&
      (formPayload.transactionPin === "" || formPayload.token === "")
    ) {
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Please enter a pin and hard token to continue.",
      });
    }
    if (user?.hasSoftToken === true && formPayload.token === "") {
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Please enter a token to continue.",
      });
    }

    if (
      user?.hasSoftToken === false &&
      formPayload.transactionPin.length !== 4
    ) {
      //error
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Pin must be a 4 digit number.",
      });
    }

    if (formPayload.token.length !== 6) {
      //error
      setFormPayload({ ...formPayload, ...{ remark: "" } });
      setRemarkMessage("");
      setRejectionModal(false);
      return ToastNotify({
        type: "error",
        message: "Token must be a 6 digit number.",
      });
    }

    if (approvalAction === "Reject" && formPayload.remark === "") {
      return setRemarkMessage("Please enter a reason for rejection");
    }

    const body = {
      mappedAccounts: user.accounts.map((x: any) => x.accountNo),
      correlationId,
      isSoftToken: user?.hasSoftToken,
      approvalAction: approvalAction,
      ...formPayload,
    };

    setIsLoading(true);
    setRejectionModal(false);
    dispatch(PUT_APROVE_OR_REJECT_TRANSACTION_ACTION(body))
      .unwrap()
      .then((res) => {
        if (approvalAction === "Reject") {
          setFormPayload({ ...formPayload, ...{ remark: "" } });
          setRemarkMessage("");
          setRejectionModal(false);
        }
        setIsLoading(false);
        if (res.status === 200) {
          dispatch(setPendingTransactionsResponse(res?.data));
          setTimeout(() => {
            navigate(
              approvalAction === "Approve"
                ? "/payments/verify/transaction/approved"
                : "/payments/verify/transaction/reject",
              {
                state: {
                  isStamped: true,
                  isApprover: !isVerifier,
                },
              }
            );
          }, 500);
        } else {
          ToastNotify({
            type: "error",
            message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
          });
        }
      })
      .catch(() => {
        setRejectionModal(false);
        navigate("/payments/verify/transaction/reject", {
          state: {
            isStamped: true,
            isApprover: !isVerifier,
          },
        });
      });
  };
  const initiatorName = pendingData?.initiatedByFullName;
  const initiatorUsername = pendingData?.initiatedByUserName;
  const requestAccName = `${pendingData?.requestAccountName}`.toLowerCase();
  const requestAccNumber = pendingData?.requestAccountNumber;
  const startDate = pendingData?.startDate;
  const endDate = pendingData?.endDate;
  const amount = Number(pendingData?.processingFee || 0);
  const totalAmount = Number(pendingData?.totalAmount || 0);
  const vat = Number(pendingData?.vat || 0);
  const debitAcctName = `${pendingData?.debitAccountName}`.toLowerCase();
  const debitAcctNumber = pendingData?.debitAccountNumber;
  const handleDate = (dateString: string) => {
    if (!dateString) return "";
    const [date] = dateString.split(" ");
    const isFormattedDate = date.includes("/");
    const newDate = new Date(dateString);
    const isInvalid = String(newDate) === "Invalid Date";

    if (isInvalid || isFormattedDate) {
      return date;
    }
    return dayjs(newDate).format("DD/MM/YYYY");
  };

  return (
    <DashboardWrapper>
      {(pendingLoading || isLoading) && <PageLoader />}

      <div className="md:px-20">
        <div className="text-xl font-semibold leading-tight py-3">
          Account Statement
        </div>
        <div className="bg-white w-full mx-auto h-[1000px] mt-14 pt-1 px-2 px-md-0">
          <div className="flex justify-between items-center">
            <BackArrow
              id="back_btn"
              className="ml-5 cursor-pointer"
              onClick={() => navigate("/payments/pending-transactions")}
            />
            <h3 className="font-normal" id="overview_text">
              Overview
            </h3>
            <div></div>
          </div>
          <div className="max-w-[671px] mx-auto">
            <div className=" pb-8 pt-4 flex justify-between">
              <h3>Account Statement</h3>
              <p className="font-semibold text-sm px-3 py-2 text-[#F90] bg-[#FFEBCC]">
                Pending {isVerifier ? "Verification" : "Approval"}
              </p>
            </div>
            <div className="text-sm text-lib-alat-black font-medium flex gap-1 px-[28px] py-[15px]  bg-lib-alat-gray-input">
              <p className="text-sm/[30.14px]">Initiator:</p>
              <div>
                <h3 className="text-base/[30.14px]">{initiatorName}</h3>
                <p className="text-secondary">{initiatorUsername}</p>
              </div>
            </div>

            <div className=" border-b border-[#E2E8F0] text-base text-lib-alat-black font-medium py-5">
              Details
            </div>
            <ListItem
              name="STATEMENT OF ACCOUNT"
              value={
                <>
                  <p>{requestAccNumber}</p>
                  <p className="capitalize">{requestAccName}</p>
                </>
              }
            />
            <ListItem name="START DATE" value={handleDate(startDate)} />
            <ListItem name="END DATE" value={handleDate(endDate)} />
            <ListItem name="AMOUNT " value={handleAmount(amount)} />
            <ListItem name="VAT" value={handleAmount(vat)} />
            <ListItem name="TOTAL" value={handleAmount(totalAmount)} />
            <ListItem
              name="ACCOUNT TO DEBIT"
              value={
                <>
                  <p>{debitAcctNumber}</p>
                  <p className="capitalize">{debitAcctName}</p>
                </>
              }
            />

            <div className=" w-72 mt-10">
              {user?.hasSoftToken && (
                <TextInput
                  id="softToken_input"
                  label="Enter soft token"
                  name="softToken"
                  type="password"
                  isPin
                  maxLength={6}
                  handleChange={(event) => {
                    setFormPayload({
                      ...formPayload,
                      token: event.target.value,
                    });
                  }}
                  variant="border text-black w-full h-10 text-lg px-5"
                  value={formPayload.token}
                />
              )}
              {!user?.hasSoftToken && (
                <>
                  <TextInput
                    id="pin_input"
                    label="Enter PIN"
                    placeHolder="Enter pin"
                    type="password"
                    isPin
                    name="pin"
                    maxLength={4}
                    handleChange={(event) => {
                      setFormPayload({
                        ...formPayload,
                        transactionPin: event.target.value,
                      });
                    }}
                    variant="border text-black w-full h-10 text-lg px-5"
                    value={formPayload.transactionPin}
                  />
                  <div className="pt-4">
                    <TextInput
                      id="hardToken_input"
                      label="Enter hard token"
                      placeHolder="Enter hard token"
                      type="password"
                      isPin
                      name="hardToken"
                      maxLength={6}
                      handleChange={(event) => {
                        setFormPayload({
                          ...formPayload,
                          token: event.target.value,
                        });
                      }}
                      variant="border text-black w-full h-10 text-lg px-5"
                      value={formPayload.token}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="w-72 flex gap-5 mt-10 mr-10">
              <div
                id={isVerifier ? "verify_btn" : "approve_btn"}
                className="w-full h-10 text-white bg-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer"
                onClick={() => handleApproveOrRejct("Approve")}
              >
                {isVerifier ? "Verify" : "Approve"}
              </div>
              <div
                id="reject_btn"
                className="w-full h-10 text-lib-alat-dark-red bg-white border border-[#A90836] rounded-md flex justify-center items-center cursor-pointer"
                onClick={() => {
                  if (user?.hasSoftToken && formPayload.token !== "") {
                    return setRejectionModal(true);
                  }
                  if (
                    !user?.hasSoftToken &&
                    formPayload.token !== "" &&
                    formPayload.transactionPin !== ""
                  ) {
                    return setRejectionModal(true);
                  }

                  if (user?.hasSoftToken && formPayload.token === "") {
                    ToastNotify({
                      type: "error",
                      message: "Please enter token to continue.",
                    });
                  }

                  if (
                    !user?.hasSoftToken &&
                    (formPayload.token === "" ||
                      formPayload.transactionPin === "")
                  ) {
                    ToastNotify({
                      type: "error",
                      message: "Please enter pin and hard token to continue.",
                    });
                  }
                }}
              >
                Reject
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Rejection reason Modal */}
      {rejectionModal && (
        <div
          className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-10 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm"
          style={{ margin: 0 }}
          id="rejection_modal"
        >
          <center className="z-10">
            <div className="flex flex-col py-5 px-10 w-[700px] h-[560px] relative mb-4 bg-white rounded-lg relative">
              <p
                id="closeRejectionModal_btn"
                className="text-right text-xs absolute right-5 top-5 cursor-pointer"
                onClick={() => {
                  setFormPayload({ ...formPayload, ...{ remark: "" } });
                  setRejectionModal(false);
                }}
              >
                <ModalClose />
              </p>
              <div className="text-center text-lg font-bold py-5">
                Please provide a reason for rejection
              </div>
              <textarea
                name="rejectionReason"
                id="rejectionReason"
                cols={4}
                rows={7}
                maxLength={100}
                placeholder="Type your reason here.."
                className="bg-gray-50 p-4 border outline-none rounded-md"
                value={formPayload.remark}
                onChange={(event) =>
                  setFormPayload({
                    ...formPayload,
                    ...{ remark: event.target.value },
                  })
                }
              />
              <div className="mt-2 flex items-center justify-between">
                <p
                  className="text-red-500 text-sm text-left"
                  id="remarkMessage"
                >
                  {remarkMessage}
                </p>
                <label
                  className="text-right"
                  id="remarkLength"
                >{`${formPayload?.remark?.length} / 100`}</label>
              </div>
              <div className="w-full flex gap-5 mt-10 mx-auto">
                <div
                  id="returnToDashboard_btn"
                  className="w-full py-3 h-10 text-lib-alat-dark-red bg-white text-bold border border-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer text-xs"
                  onClick={() => navigate("/user-dashboard")}
                >
                  Return to dashboard
                </div>
                <div
                  id="confirmRejection_btn"
                  className="w-full py-3 h-10 text-white bg-lib-alat-dark-red rounded-md flex justify-center items-center cursor-pointer text-xs"
                  onClick={() => handleApproveOrRejct("Reject")}
                >
                  Confirm rejection
                </div>
              </div>
            </div>
          </center>
        </div>
      )}
    </DashboardWrapper>
  );
};

export default PendingStatementDetail;
