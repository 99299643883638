import { FormikButton } from "alat-components-library";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../../../../assets/svgs/BackArrow.svg";
import { ReactComponent as BackArrowIcon } from "../../../../assets/svgs/BackArrowIcon.svg";
import { useNavigate } from "react-router-dom";
import {
  deleteReferee,
  saveOnboardingData,
  updateFirstReferee,
  updateSecondReferee,
} from "react-wrappers/stores/redux/onboarding/slices";
import { useSaveNonWemaReferee } from "hooks/apis/Referee/useSaveNonWemaReferee";
import { useGetReferees } from "hooks/apis/Referee/useGetReferees";
import CenterModal from "alat-components-library/Modal/CenterModal";
import { ReactComponent as SuccessIcon } from "../../../../assets/svgs/success-icon.svg";
import PageLoader from "components/Loader/PageLoader";
import { OnboardingStageEnum } from "types/global";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useResendRefereeEmail } from "hooks/apis/Referee/useResendRefereeEmail";
import { RefereeCard } from "./RefereeCard";
import { AccountTypes } from "pages/Onboarding/utils";

const Referees = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onboardingData = useAppSelector((state) => state.onboarding);
  const referees = useAppSelector((state) => state.onboarding.refereePayload);
  const { user } = useAppSelector((state) => state.auth);
  const filteredReferees = referees.filter((referee) => referee?.bank !== "");
  const [successModal, setSuccessModal] = useState(false);

  const { refereesLoading, refereesError, refereesData, getAllReferees } =
    useGetReferees();
  const {
    saveWemaRefereeValidation,
    saveNonWemaRefereeData,
    saveNonWemaRefereeLoading,
    saveNonWemaRefereeStatus,
    saveNonWemaRefereeError,
  } = useSaveNonWemaReferee();
  // Check if it should have single refereee
  const accountType = user?.accountType;
  const isSingleReferee =
    AccountTypes.MBASAVVY === accountType ||
    AccountTypes.MBALITE === accountType;

  const resetReferees = () => {
    dispatch(
      updateFirstReferee({
        bank: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        accountNumber: "",
      })
    );
    dispatch(
      updateSecondReferee({
        bank: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        accountNumber: "",
      })
    );
  };
  useEffect(() => {
    // console.log(saveNonWemaRefereeData, "save referees");

    if (saveNonWemaRefereeStatus) {
      setSuccessModal(true);
      dispatch(
        saveOnboardingData({
          ...onboardingData,
          refereeStatus: true,
        })
      );
    }
    // eslint-disable-next-line
  }, [
    saveNonWemaRefereeStatus,
    saveNonWemaRefereeData,
    saveNonWemaRefereeError,
  ]);

  useEffect(() => {
    if (
      ![OnboardingStageEnum.AlatPay, OnboardingStageEnum.Refree].includes(
        user?.onboardingStage
      )
    ) {
      if (!user?.correlationId) {
        return navigate("/user-dashboard");
      }
      getAllReferees(user?.correlationId);
    }
  }, []);

  useEffect(() => {
    if (refereesData) {
      dispatch(updateFirstReferee(refereesData[0]));
      dispatch(updateSecondReferee(refereesData[1]));
    }
  }, [refereesData, refereesLoading, refereesError]);

  const handleSubmitText = () => {
    let isAddReferee =
      (isSingleReferee && referees[0].bank === "") ||
      (!isSingleReferee && referees[0].bank === "") ||
      (!isSingleReferee && referees[1].bank === "");
    if (isAddReferee) {
      return "Add Referee";
    } else {
      return "Submit";
    }
  };
  const submitText = handleSubmitText();
  // useEffect(()=>{
  //   if(referees[0]?.id || referees[1]?.id){
  //     resetReferees();
  //   }
  // },[]);

  const handleSubmitReferees = () => {
    let refereePayload;
    if (isSingleReferee) {
      refereePayload = referees[0];
      // For single referee
      if (refereePayload.bank === "") {
        navigate("/referee-setup");
      } else {
        saveWemaRefereeValidation({
          correlationId: user?.correlationId,
          referees: [refereePayload],
        });
      }
    } else {
      // For two referees
      refereePayload = referees.map(({ ...rest }) => rest);
      if (referees[1].bank === "" || referees[0].bank === "") {
        navigate("/referee-setup");
      } else {
        saveWemaRefereeValidation({
          correlationId: user?.correlationId,
          referees: refereePayload,
        });
      }
    }
  };
  // console.log("The referees are: ", referees);
  return (
    <div className="w-screen md:w-full h-full">
      {saveNonWemaRefereeLoading && <PageLoader message="Saving Referees" />}
      {refereesLoading && <PageLoader message="" />}
      <div className="w-screen md:w-[800px] mx-auto my-5 bg-white h-auto rounded-lg">
        <div className="relative py-2 flex justify-between md:px-4">
          <div
            id="back_btn"
            onClick={() => {
              if (
                user?.onboardingStage === OnboardingStageEnum.Refree ||
                (user?.onboardingStage === OnboardingStageEnum.AlatPay &&
                  onboardingData?.alatPay)
              ) {
                navigate("/referee-setup", {
                  state: { refereeToEdit: 1, editData: referees[0] },
                });
                return;
              }
              navigate(-1);
            }}
            className="cursor-pointer"
          >
            <BackArrow />
          </div>
          <div className="my-auto">
            <p className="text-lg">Referees</p>
          </div>
          <div></div>
        </div>
        <hr />
        <div className="w-full px-4">
          <div className="md:w-[500px] w-full mx-auto py-5">
            {!isSingleReferee && !referees[0].id && (
              <p className="text-center pb-7">You need to have two referees</p>
            )}
            <div className="flex flex-col gap-1">
              {referees
                .filter((referee) => referee?.bank !== "")
                .map(({ bank, emailAddress, firstName, lastName }, index) => {
                  return (
                    // eslint-disable-next-line
                    <div key={index}>
                      <RefereeCard
                        id={`referee-${index}_card`}
                        actionId={`action-${index}_btn`}
                        deleteId={`delete-${index}_btn`}
                        editId={`edit-${index}_btn`}
                        index={index}
                        bank={bank}
                        email={emailAddress}
                        firstName={firstName}
                        lastName={lastName}
                        filteredReferees={filteredReferees}
                      />
                    </div>
                  );
                })}
            </div>
            {(user?.onboardingStage === OnboardingStageEnum.Refree ||
              onboardingData?.alatPay) && (
              <div onClick={handleSubmitReferees} className="w-full">
                <FormikButton
                  id="submit-referees_btn"
                  labelText={submitText}
                  containerVariant="w-full h-12 rounded-md flex justify-center my-10"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/*Referee Saved Successfully*/}
      {successModal && (
        <CenterModal
          icon={<SuccessIcon />}
          title="Success!"
          info={`A referee verification link has been sent to the emails (${referees[0].emailAddress} and ${referees[1].emailAddress}), Kindly ensure your referees respond via the link sent).`}
          handleClick={() => {
            resetReferees();
            navigate("/user-dashboard");
          }}
          handleClose={() => {
            resetReferees();
            setSuccessModal(false);
          }}
          handleClick2={() => {
            resetReferees();
            navigate("/user-dashboard");
          }}
          btnText="Okay"
          btnText2={"Return to dashboard"}
          showCloseIcon
        />
      )}
    </div>
  );
};

export default Referees;
