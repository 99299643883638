import { PrimaryButton } from "alat-components-library";
import { useNavigate } from "react-router-dom";
import { Card } from "flowbite-react";
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/success-icon.svg";

const SuccessScreen = ({ setActiveTab, screenName }: any) => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-8 pt-6">
      <Card className="md:w-[768px] w-full col-span-full bg-white dark:border-gray-200 mx-auto px-6">
        <div>
          <div className="text-center flex justify-center mt-10">
            <SuccessIcon />
          </div>
          <div className="text-center text-2xl font-semibold mt-6">
            Transaction request sent
          </div>
          <h3 className="text-center mb-5 text-md font-normal text-black mt-10">
            Your transaction request has been sent for approval
          </h3>
          <div className="text-center mb-5 text-sm rounded-lg font-normal text-gray-500 light:text-gray-400 mt-10 mx-auto p-4 bg-[#F5F5F5]">
            We will send you a notification as soon as the request is approved
          </div>
          <div className="flex justify-center flex-row gap-4 mb-40 mt-10">
            <PrimaryButton
              labelText={"Proceed to Dashboard"}
              variant="font-medium text-xs text-white"
              containerVariant="w-[188px] h-[45px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red"
              handleClick={() => navigate("/user-dashboard")}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SuccessScreen;
