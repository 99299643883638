import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { FormikButton, TextInput } from "alat-components-library";
import { Card } from "flowbite-react";
import { Form, Formik } from "formik";
import { OtpValidationValidation } from "utils/validations/bulktransfer";
import { ReactComponent as VerificattionIcon } from "../../../../assets/icons/verification-icon.svg";
import { useTransactionTransferController } from "controllers/BulkTransfer/useTransactionTransferController";
import { ReactComponent as ResendOtpIcon } from "../../../../assets/svgs/resend-otp.svg";
import {
  paymentSlice,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { useAppDispatch } from "hooks/useReduxHook";
import { GET_RESEND_TRANSFER_OTP_ACTION } from "react-wrappers/stores/redux/payments/actions";
import { toast } from "react-toastify";

const OtpValidation = ({ setActiveTab, screenName }: any) => {
  // Redux State
  const paymentState = usePaymentState();
  const dispatch = useAppDispatch();

  const { authState, handleOtpValidationSubmit } =
    useTransactionTransferController(setActiveTab, screenName);

  const phoneNumberCast = `${authState?.user?.phoneNumber.slice(1, authState?.user?.phoneNumber?.length - 7)}*****${authState.user.phoneNumber.slice(authState.user.phoneNumber.length - 2, authState.user.phoneNumber.length)}`;

  return (
    <div className="grid grid-cols-8 pt-6 ">
      <Card className="md:w-[768px] col-span-full mx-auto bg-white pb-14  dark:bg-white dark:border-[#EDEDED] border-[#EDEDED] dark:shadow-white">
        <div className="flex items-center justify-between">
          <ChevronLeftIcon
            className="h-6 w-6 text-[#333333] cursor-pointer"
            onClick={() => setActiveTab(`select-account-${screenName}-tab`)}
          />
          <div className="text-[#4D4D4D] text-xl font-bold text-center">
            Verification
          </div>
          <div></div>
        </div>
        <hr />
        <Formik
          initialValues={{
            otp: "",
          }}
          validateSchema={OtpValidationValidation}
          onSubmit={handleOtpValidationSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            dirty,
            isValid,
            setFieldValue,
          }) => (
            <Form>
              <div className="md:w-[400px] w-full mx-auto">
                <div className="flex justify-center py-4">
                  <VerificattionIcon className="" />
                </div>
                <p className="text-[#666666] mb-2 text-sm font-normal text-center py-3">
                  {/* We just sent a verification code to your mobile number&nbsp;({`+234${phoneNumberCast}`}) */}
                  {paymentState?.bulkOtpMessage}
                </p>
                <div className="mt-5">
                  <TextInput
                    label=""
                    placeHolder="Enter OTP"
                    type="password"
                    isPin
                    name="otp"
                    handleChange={(event) =>
                      setFieldValue("otp", event.target.value)
                    }
                    variant="border text-black w-full h-[45px] text-lg px-5"
                    value={values.otp}
                    maxLength={6}
                  />
                </div>
                <div
                  className="flex items-center justify-end space-x-1 mt-2 cursor-pointer"
                  onClick={() => {
                    if (paymentState.correlationId) {
                      dispatch(
                        GET_RESEND_TRANSFER_OTP_ACTION(
                          paymentState.correlationId
                        )
                      )
                        .unwrap()
                        .then((res: any) => {
                          // console.log(res);
                          res.status === 200 && toast.success("OTP sent");
                        });
                    }
                  }}
                >
                  <ResendOtpIcon className="w-[12px] h-[12px]" />
                  <p className="text-[#A90836] text-sm text-right">
                    Resend OTP
                  </p>
                </div>
                <div className="flex justify-center mt-7">
                  <FormikButton
                    labelText={"Complete transfer"}
                    containerVariant={
                      "w-2/4 h-[45px] my-[3vh] rounded-md flex justify-center"
                    }
                    isDisabled={!isValid && !dirty}
                    isLoading={
                      !!paymentState.loading.includes(
                        "PUT_COMPLETE_TRANSFER_TRANSACTION"
                      )
                    }
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default OtpValidation;
