import * as Yup from "yup";

export const paybillValidationSchema = () =>
  Yup.object().shape({
    transactionId: Yup.string().trim(),
    package: Yup.string().required("Kindly select a package"),
    amount: Yup.number()
      .required("Amount is required")
      .moreThan(0, "Amount is required"),
  });

export const billConfirmationValidationSchema = (
  balance: string,
  amount: string | number
) =>
  Yup.object().shape({
    account:
      balance !== undefined
        ? Yup.string()
            .required()
            .test(
              "Above Balance",
              "Insufficient Funds",
              () => Number(amount) <= Number(balance)
            )
        : Yup.string().required(),
    transactionPin: Yup.string(),
    softToken: Yup.string(),
    hardToken: Yup.string(),
  });
