import React from "react";
import { ReactSuspenceWrapper } from "../../alat-components-library";
import Navbar from "../Navbar/Navbar";
import { TopHeader } from "../TopHeader";
import { useAppSelector } from "hooks/useReduxHook";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { CompletePaymentModal } from "../../pages/Onboarding/Components/CompletePaymentModal";
import { OnboardingStageEnum } from "types/global";
import PendingDudChequesModal from "pages/Home/DudCheque/components/PendingDudChequesModal";
import { useWindowSize } from "hooks/useWindowSize";
import { isMobile } from "third-party/facecapture/logic/deviceType";
import {
  setShowMobileSidebar,
  useAppState,
} from "react-wrappers/stores/redux/app/slices";
import { Sidebar } from "components/Sidebar";

const DashboardWrapper = ({ children }: any) => {
  const { showMobileSidebar } = useAppState();
  const { user } = useAuthState();
  const isClient = useWindowSize();
  const alatPay = useAppSelector((state) => state.onboarding.alatPay);

  const mobileScreen =
    (isClient?.width ?? 0) <= 768 || isMobile(navigator.userAgent);

  // console.log("mobileScreen", mobileScreen);
  return (
    <ReactSuspenceWrapper>
      {user?.onboardingStage === OnboardingStageEnum.AlatPay && !alatPay ? (
        <CompletePaymentModal />
      ) : user?.onboardingStage === OnboardingStageEnum.DudChequeFailed ? (
        <PendingDudChequesModal />
      ) : (
        <div className="bg-[#F5F5F5] min-h-screen w-full pb-20 overflow-hidden">
          <div className="container-fluid mx-auto">
            <TopHeader />
            <Navbar />
            {children}
          </div>
        </div>
      )}
      <div
        className={`${showMobileSidebar ? `flex` : "hidden"} md:hidden h-full flex w-full relative`}
      >
        <Sidebar />
      </div>
    </ReactSuspenceWrapper>
  );
};

export default DashboardWrapper;
