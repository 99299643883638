import {
  PrimaryButton,
  ReactSuspenceWrapper,
  TextInput,
} from "alat-components-library";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { usePaymentState } from "react-wrappers/stores/redux/payments/slices";
import {
  GET_PENDING_USERS_REQUEST_DETAILS_ACTION,
  POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS_ACTION,
  POST_REVIEW_USER_REQUEST_ACTION,
} from "react-wrappers/stores/redux/payments/actions";
import { useAppDispatch } from "hooks/useReduxHook";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { RejectionModal } from "components/Dashboard/components/RejectionModal";
import PageLoader from "components/Loader/PageLoader";
import { AccountsMapped } from "components/Dashboard/components/AccountsMapped";
import { pendingRequestsSchema } from "./validations/dashboardValidations";
import { ApprovedScreen } from "components/Dashboard/components/ApprovedScreen";
import { RejectionScreen } from "components/Dashboard/components/RejectionScreen";
import {
  approvalOperationTypeEnum,
  pendingActivityType,
  requestTypeTitle,
} from "pages/Payments/components/utils";
import {
  moneyInputFormat,
  formatNumberToCurrency,
} from "utils/helperFunctions";
import { usePendingUserModification } from "hooks/apis/Admin/usePendingUserModification";
import {
  UserPermissionEnum,
  UserPermissionDisplayEnum,
} from "utils/enums/permissions";
import { userPermissionsData } from "pages/Admin/utils";

interface PayloadData {
  correlationId: string;
  isSoftToken: boolean;
  token: string;
  remarks: string;
  approvalAction: number;
  approvalOperationType: number;
}

export const PendingRequests = () => {
  const navigate = useNavigate();
  const { correlationId } = useParams();
  const dispatch = useAppDispatch();
  const { userRole } = useAuthState();
  const { handleUserModificationDetails } = usePendingUserModification();
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [showAccountsMapped, setShowAccountsMapped] = useState(false);
  const [payload, setPayload] = useState<PayloadData>({
    correlationId: "",
    isSoftToken: false,
    token: "",
    remarks: "",
    approvalAction: 0,
    approvalOperationType: 0,
  });
  const permissionsNotToEditDownloadStatement = [
    "Approver",
    "InitiatorApprover",
  ];

  const {
    pendingTransactionDetail: { userRequest, tokenRequest },
    reviewUserRequest,
    pendingTransactionList,
    loading,
  } = usePaymentState();
  const formik = useFormik({
    initialValues: {
      token: "",
      rejectionReason: "",
    },

    onSubmit: async (values) => {
      const data = {
        ...payload,
        remarks:
          values?.rejectionReason === "" ? "APPROVED" : values?.rejectionReason,
      };
      dispatch(POST_REVIEW_USER_REQUEST_ACTION(data));
    },
    validationSchema: pendingRequestsSchema,
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    values,
    errors,
    touched,
  } = formik;

  const requestType = pendingTransactionList?.sortedData?.find(
    (item: any) => item?.correlationId === correlationId
  )?.type;

  const handleFormValues = (cta: string) => {
    setPayload({
      correlationId: `${correlationId}`,
      isSoftToken: values?.token?.length === 6 ? true : false,
      token: values?.token,
      remarks: "",
      approvalAction: cta === "approve" ? 2 : 3,
      // approvalOperationType: requestType === 6 ? 1 : 2,
      approvalOperationType: approvalOperationTypeEnum(requestType),
    });

    if (cta === "approve") {
      return handleSubmit();
    }
    return setShowRejectionModal(true);
  };

  const getPermissionName = (permission: any) => {
    const indexOfS = Object.values(UserPermissionEnum).indexOf(permission);

    const key = Object.keys(UserPermissionDisplayEnum)[indexOfS];

    return key;
  };

  useEffect(() => {
    const payload = {
      correlationId,
    };
    if (requestType === 6) {
      dispatch(GET_PENDING_USERS_REQUEST_DETAILS_ACTION(payload));
    } else if (requestType === 9) {
      handleUserModificationDetails(payload);
    } else {
      dispatch(POST_PENDING_SOFT_TOKEN_REQUEST_DETAILS_ACTION(payload));
    }
    // eslint-disable-next-line
  }, []);

  if (reviewUserRequest?.successStatus) {
    if (payload?.approvalAction === 2)
      return <ApprovedScreen data={reviewUserRequest} id={correlationId} />;
    return <RejectionScreen data={reviewUserRequest} id={correlationId} />;
  }

  if (showAccountsMapped) {
    return (
      <AccountsMapped
        pageTitle="Accounts Mapped"
        handleBack={() => setShowAccountsMapped(false)}
      />
    );
  }

  const showLoading =
    loading?.includes("POST_REVIEW_USER_REQUEST") ||
    loading?.includes("GET_PENDING_USERS_REQUEST_DETAILS") ||
    loading?.includes("modified-user-details");
  return (
    <>
      {showLoading && <PageLoader message="Please wait" />}
      <ReactSuspenceWrapper>
        <DashboardWrapper>
          <div className="lg:px-20 space-y-4 mt-5">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold" id="request-type_text">
                {pendingActivityType(requestType)}
              </h2>
            </div>
            <div className="flex items-center justify-center">
              <ReusableCard
                cardTitle="Overview"
                variant="w-[990px]"
                handleBackNavigation={() =>
                  navigate("/payments/pending-transactions")
                }
                showGoBackIcon
              >
                <div className="w-full flex flex-col items-center justify-center px-10 py-3 space-y-4">
                  <div className="w-full flex items-center justify-between p-3">
                    <div className="font-medium text-lg">
                      <p
                        className="leading-[22px] tracking-tighter text-lib-alat-black"
                        id="requestType_text"
                      >
                        {/* {requestType === 7 ? "Soft Token" : "New User"} */}
                        {requestTypeTitle(requestType)}
                      </p>
                    </div>
                    <div className=" bg-[#FFA41C]/[.15] p-2">
                      <p className="text-sm text-[#FF9900] leading-[9px] font-semibold  tracking-tighter">
                        Pending approval
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex bg-[#F7F7F7] p-3">
                    <div className="flex items-center gap-2">
                      <div>
                        <p
                          className="text-sm leading-[13px] tracking-tighter font-medium text-lib-alat-black"
                          id="initiator-role_text"
                        >
                          {requestType === 6 || requestType === 9
                            ? "Admin:"
                            : tokenRequest?.initiatedByRoleName ?? "Initiator:"}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p
                          className="text-lib-alat-black text-[17px] leading-[30px] tracking-tighter font-medium"
                          id="initiator-fullname_text"
                        >
                          {requestType === 6 || requestType === 9
                            ? userRequest?.initiatedByFullName
                            : tokenRequest?.accountName}
                        </p>
                        <p
                          className="text-[#606060] text-sm leading-[13px] tracking-tighter"
                          id="initiated-by_text"
                        >
                          {requestType === 6 || requestType === 9
                            ? userRequest?.initiatedBy?.toUpperCase()
                            : tokenRequest?.initiatedByFullName?.toUpperCase()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex-col space-y-4">
                    <p
                      className="tracking-tighter font-medium text-base leading-[19px] text-lib-alat-black"
                      id="requestType_text"
                    >
                      {requestType === 6 || requestType === 9
                        ? "User details"
                        : "Transaction details"}
                    </p>

                    <div className="w-full flex-col">
                      <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                        <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                          {requestType === 6 || requestType === 9
                            ? "NAME"
                            : "ACCOUNT NAME"}
                        </p>
                        <p
                          className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                          id="name_text"
                        >
                          {requestType === 6 || requestType === 9
                            ? userRequest?.profileFullName
                            : tokenRequest?.accountName}
                        </p>
                      </div>
                      <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                        <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                          {requestType === 6 || requestType === 9
                            ? "USERNAME"
                            : "ACCOUNT NO."}
                        </p>
                        <p
                          className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                          id="profile-identifier_text"
                        >
                          {requestType === 6 || requestType === 9
                            ? userRequest?.profileUsername
                            : tokenRequest?.accountNumber}
                        </p>
                      </div>
                      <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                        <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                          PHONE NO.
                        </p>
                        <p
                          className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                          id="phone_text"
                        >
                          {requestType === 6 || requestType === 9
                            ? userRequest?.profilePhonenumber
                            : tokenRequest?.phoneNumber}
                        </p>
                      </div>
                      <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                        <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                          EMAIL
                        </p>
                        <p
                          className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                          id="email_text"
                        >
                          {requestType === 6 || requestType === 9
                            ? userRequest?.profileEmail
                            : tokenRequest?.email}
                        </p>
                      </div>
                      {requestType === 7 && (
                        <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                          <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                            AMOUNT
                          </p>
                          <p
                            className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                            id="email_text"
                          >
                            {formatNumberToCurrency({
                              number: Number(tokenRequest?.amount ?? 0),
                            })}
                          </p>
                        </div>
                      )}
                      {(requestType === 6 || requestType === 9) && (
                        <>
                          <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                            <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                              ROLE
                            </p>
                            <p
                              className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                              id="role_text"
                            >
                              {userRequest?.profileRole}
                            </p>
                          </div>
                          <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                            <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                              ONE TIME TRANSACTION LIMIT
                            </p>
                            <p
                              className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                              id="transaction-limit_text"
                            >
                              {/* {formatNumberToCurrency({
                                number: userRequest?.onetimeTransactionLimit,
                                precision: 2,
                              })} */}
                              {new Intl.NumberFormat("en-NG").format(
                                userRequest?.onetimeTransactionLimit
                              )}
                            </p>
                          </div>
                          <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                            <p className="text-xs leading-[170%] tracking-[-0.02em] text-lib-alat-black">
                              DAILY TRANSACTION LIMIT
                            </p>
                            <p
                              className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                              id="dailyTransactionLimit_text"
                            >
                              {/* {formatNumberToCurrency({
                                number: userRequest?.dailyTransactionLimit,
                                precision: 2,
                              })} */}
                              {new Intl.NumberFormat("en-NG").format(
                                userRequest?.dailyTransactionLimit
                              )}
                            </p>
                          </div>
                          <div className="w-full border border-x-0 border-t-0 border-b-[#E2E8F0] flex items-center justify-between p-3">
                            <div
                              id="accounts-mapped_btn"
                              className="bg-[#A90836]/[0.1] cursor-pointer rounded-[6px] p-1"
                              onClick={() => setShowAccountsMapped(true)}
                            >
                              <p className="text-lib-alat-red tracking-tighter text-xs leading-[14px] font-semibold">
                                ACCOUNTS MAPPED
                              </p>
                            </div>
                            <p
                              className="text-[15px] leading-[160%] tracking-[-0.02em] text-lib-alat-black"
                              id="numberOfAccountsToBeMapped_text"
                            >
                              {userRequest?.numberOfAccountsToBeMapped}
                            </p>
                          </div>
                        </>
                      )}

                      {(requestType === 6 || requestType === 9) &&
                        !permissionsNotToEditDownloadStatement.includes(
                          userRequest?.profileRole
                        ) && (
                          <div>
                            <hr className="h-px  bg-gray-300 border-1 dark:bg-gray-700" />

                            <p className="font-bold text-md leading-[21.96px] tracking-[2%] mt-4 mb-5 p-3">
                              PERMISSIONS
                            </p>

                            {userPermissionsData.map((permission) => (
                              <div
                                key={permission.key}
                                className="w-full border-b py-4 border-b-[#E2E8F0] flex items-center justify-between p-3"
                              >
                                <p className="text-[12.92px] leading-[21.96px] tracking-[2%] text-lib-alat-black">
                                  {permission.name}
                                </p>
                                <p
                                  className="text-[15.28px] leading-[21.96px] tracking-[2%] text-lib-alat-black"
                                  id="staff-id_text"
                                >
                                  {userRequest?.userPermissions?.includes(
                                    permission.value
                                  )
                                    ? "YES"
                                    : "NO"}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                    <form
                      onSubmit={handleSubmit}
                      className="flex flex-col w-1/2"
                    >
                      <TextInput
                        id="token_input"
                        name={"token"}
                        type={"password"}
                        label={"Enter Token"}
                        placeHolder={""}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values?.token}
                        hasError={errors?.token && touched?.token}
                        error={errors?.token}
                        maxLength={10}
                      />
                      <div className="flex gap-5 mt-5 py-5 ">
                        <PrimaryButton
                          id="verify_btn"
                          labelText={
                            userRole === "verifier" ? "Verify" : "Approve"
                          }
                          handleClick={() => {
                            handleFormValues("approve");
                          }}
                          isDisabled={!(isValid && dirty)}
                          variant={"text-white font-medium text-xs"}
                          containerVariant="w-full h-10 rounded-md flex justify-center cursor-pointer bg-lib-alat-red"
                        />
                        <PrimaryButton
                          id="reject_btn"
                          labelText={"Reject"}
                          isDisabled={!(isValid && dirty)}
                          variant="font-medium text-xs"
                          containerVariant="w-full rounded-md flex justify-center border border-lib-alat-red cursor-pointer bg-white py-3 hover:bg-[#f2f5fc]"
                          handleClick={() => handleFormValues("reject")} //}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </ReusableCard>
              {showRejectionModal && (
                <RejectionModal
                  onClose={() => setShowRejectionModal(false)}
                  handleConfirmRejection={() => handleSubmit()}
                  wordCount={
                    values?.rejectionReason !== ""
                      ? values?.rejectionReason?.length?.toString()
                      : "0"
                  }
                  handleRejectionRemark={(e: any) => {
                    handleChange(e);
                  }}
                  remarkError={values?.rejectionReason === "" ? true : false}
                  fieldName={"rejectionReason"}
                />
              )}
            </div>
          </div>
        </DashboardWrapper>
      </ReactSuspenceWrapper>
    </>
  );
};
