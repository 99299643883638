import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AdminNavItems,
  navItems,
  accountsSubMenuLinks,
  paymentSubMenuLinks,
  // tradeServicesSubMenuLinks,
  cardSubMenuLinks,
  loansSubMenuLinks,
  moreSubMenuLinks,
} from "./navItems";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { setSelectedTab } from "react-wrappers/stores/redux/accounts/slices";
import { useAppDispatch } from "hooks/useReduxHook";

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { userRole } = useAuthState();
  const [open, setOpen] = useState(false);
  const [cursorIsOn, setCursorIsOn] = useState<string[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isAdmin = userRole?.toLowerCase() === "admin";
  const selectedNavItems = isAdmin ? AdminNavItems : navItems;

  const selectedSubMenuItems = (id: string) => {
    switch (id) {
      case "accounts_btn":
        return accountsSubMenuLinks;
      case "payments_btn":
        return paymentSubMenuLinks;
      case "cards_btn":
        return cardSubMenuLinks;
      case "loans_btn":
        return loansSubMenuLinks;
      case "more_btn":
        return moreSubMenuLinks;
      default:
        return;
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="w-full h-auto bg-white relative">
      <div className="w-4/6 mx-auto flex justify-center space-x-6 items-center">
        {selectedNavItems?.map((navItem) => {
          if (
            navItem?.text?.toLowerCase() === "payments" &&
            !["initiator", "soleproprietor", "initiatorapprover"].includes(
              userRole
            )
          ) {
            return false;
          }
          if (
            navItem?.text?.toLowerCase() === "loans" &&
            !["soleproprietor"].includes(userRole)
          ) {
            return false;
          }
          if (
            navItem?.text?.toLowerCase() === "cards" &&
            !["soleproprietor"].includes(userRole)
          ) {
            return false;
          }
          if (
            navItem?.text?.toLowerCase() === "more" &&
            !["soleproprietor"].includes(userRole)
          ) {
            return false;
          }

          return (
            <div
              key={navItem?.id}
              className="md:flex hidden items-center py-4 relative"
              onClick={() => {
                if (
                  navItem?.text?.toLowerCase() === "accounts" &&
                  !["/accounts/naira", "/accounts/fx"]?.includes(
                    location.pathname
                  )
                ) {
                  navigate("/accounts/naira");
                }
              }}
            >
              <div
                id={navItem?.id}
                className={`flex gap-4 items-center transition-all hover:text-lib-alat-dark-red ${
                  navItem.link === location.pathname
                    ? "text-lib-alat-dark-red"
                    : "text-[#A6A6A6]"
                }`}
                onClick={() => {
                  !navItem?.hasDropdown && navigate(navItem.link);
                }}
                onMouseOver={() => {
                  if (navItem.hasDropdown) {
                    setCursorIsOn([navItem?.text]);
                    setOpen(true);
                  }
                }}
              >
                <div className="w-3">{navItem?.icon}</div>
                <div className="text-xs cursor-pointer">
                  <p>{navItem?.text}</p>
                  {navItem?.hasDropdown &&
                    cursorIsOn?.includes(navItem.text) && (
                      <div
                        className={`bg-white w-[185px] left-[0px] h-auto top-[50px] absolute z-10 rounded-b-md shadow-lg shadow-black-500/50 ${
                          open ? "block" : "hidden"
                        }`}
                      >
                        <ul>
                          {selectedSubMenuItems(navItem?.id)?.map(
                            (item: any) => {
                              if (
                                userRole !== "soleproprietor" &&
                                navItem?.text?.toLowerCase() === "payments"
                              ) {
                                if (item?.id === "airtime_btn") {
                                  return false;
                                }
                                return (
                                  <li
                                    id={item?.id}
                                    key={item.id}
                                    onClick={() => {
                                      // dispatch(setSelectedTab(item?.tabNo));
                                      navigate(item.route);
                                    }}
                                    className="text-xs text-[#606060] flex items-center space-x-2 hover:bg-[#F5F5F5] cursor-pointer p-4 divide-y divide-[#A6A6A6]"
                                  >
                                    {item.text}
                                  </li>
                                );
                              }
                              return (
                                <li
                                  id={item?.id}
                                  key={item.id}
                                  onClick={() => {
                                    // dispatch(setSelectedTab(item?.tabNo));
                                    navigate(item.route);
                                  }}
                                  className="text-xs text-[#606060] flex items-center space-x-2 hover:bg-[#F5F5F5] cursor-pointer p-4 divide-y divide-[#A6A6A6]"
                                >
                                  {item.text}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Navbar;
