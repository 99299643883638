import { createBrowserRouter } from "react-router-dom";
import { authRoutes } from "./authRoutes";
import { onboardingRoutes } from "./onboardingRoutes";
import { paymentsRoutes } from "./paymentsRoutes";
import { dashboardRoutes } from "./dashboardRoutes";
import { homeRoutes } from "./homeRoutes";
import { otherRoutes } from "./others";
import { profileRoutes } from "./profileRoutes";
import { accountsRoutes } from "./accountsRoutes";
import { modalsRoutes } from "./modalsRoutes";
import { tradeServicesRoutes } from "./tradeServicesRoutes";
import { pendingActionsRoutes } from "./pendingActionsRoutes";
import { adminRoutes } from "./adminRoutes";
import { cardsRoutes } from "./cardsRoutes";
import { mailRoutes } from "./mailRoutes";
import { loansRoutes } from "./loansRoutes";
import { accountReactivationRoutes } from "./accountReactivationRoutes";
import { notificationsRoutes } from "./notificationsRoutes";
import { remitaRoutes } from "./remitaRoutes";
import { flightsRoutes } from "./flightsRoutes";
import { airtimeAndDataRoutes } from "./airtimeAndDataRoutes";
import { posRequestRoutes } from "./posRequestRoutes";

const router = createBrowserRouter([
  ...authRoutes,
  ...tradeServicesRoutes,
  ...accountsRoutes,
  ...accountReactivationRoutes,
  ...onboardingRoutes,
  ...paymentsRoutes,
  ...dashboardRoutes,
  ...homeRoutes,
  ...profileRoutes,
  ...modalsRoutes,
  ...pendingActionsRoutes,
  ...adminRoutes,
  ...cardsRoutes,
  ...mailRoutes,
  ...loansRoutes,
  ...notificationsRoutes,
  ...remitaRoutes,
  ...flightsRoutes,
  ...airtimeAndDataRoutes,
  ...posRequestRoutes,
  ...otherRoutes,
]);

export default router;
