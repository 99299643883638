import { useAppDispatch } from "../hooks/useReduxHook";
import { ToastNotify } from "../helpers/toastNotify";
import dayjs from "dayjs";
import {
  POST_CREATE_ACCOUNT_STATEMENT_ACTION,
  POST_CREATE_CHEQUE_CLEARANCE_STATEMENT_ACTION,
} from "react-wrappers/stores/redux/payments/actions";
import {
  setAccountStatementPayload,
  setAccountStatementPDFURL,
  setAccountStatementType,
  setLoadingState,
  setSelectedAccountStatement,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { accountStatementTypeEnum } from "utils/enums";

export const useAccountStatementController = () => {
  // Redux State
  const paymentState = usePaymentState();
  const authState = useAuthState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { accountStatementPayload, selectedAccountStatement } = paymentState;
  const isSoleProprietor = authState?.user?.role === "SoleProprietor";
  const isApprover = authState?.user?.role === "Approver";
  const isInitiatorApprover = authState?.user?.role === "InitiatorApprover";
  const isSuperapprover = authState?.user?.role === "SuperApprover";

  // Variable
  const initialValueForDetail = {
    account: {
      accountNo: "",
      balance: 0,
    },
    statementType: "",
    startDate: "",
    endDate: "",
    isStampedStatement: false,
  };

  const initialValueForPaymentSummary = {
    token: "",
    pin: "",
  };

  //Handler Functions
  const handleDetailSubmit = async (values: any) => {
    if (values.account?.accountNo === "") {
      return ToastNotify({
        type: "error",
        message: "Please select an account number to continue",
        position: "top-right",
      });
      // return toast.error("Please select an account number to continue")
    }

    if (values.startDate === "" || values.endDate === "") {
      return ToastNotify({
        type: "error",
        message: "Start date and end Date is required to continue",
        position: "top-right",
      });
    }

    if (values.startDate !== "" && values.endDate !== "") {
      const date1 = dayjs(values.startDate);
      const date2 = dayjs(values.endDate);
      const todayDate = dayjs();
      const sixMonthsRange = dayjs(values?.startDate).add(6, "month");
      if (date1 > date2) {
        return ToastNotify({
          type: "error",
          message: "Start date cannot be greater than end date",
          position: "top-right",
        });
        // return toast.error("Start date cannot be greater than end date");
      }
      if (date1 > todayDate) {
        return ToastNotify({
          type: "error",
          message: "Start date cannot be greater than today",
          position: "top-right",
        });
      }
      if (date2 > sixMonthsRange) {
        return ToastNotify({
          type: "error",
          message: "Kindly download your statements in six-month batches.",
          position: "top-right",
        });
      }
    }

    let payload = {
      requestingAccountNumber: values?.account?.accountNo,
      startDate: values?.startDate,
      endDate: values?.endDate,
      authorizationType: 1,
      debitAccountNumber: values?.account?.accountNo,
      pin: "",
      token: "",
      isStamped: false,
      // Backend request the below to be added
      debitAccountName: values?.account?.accountName,
      requestingAccountName: values?.account?.accountName,
    };

    if (values?.isStampedStatement) {
      payload = {
        ...payload,
        ...{ isStamped: true },
      };

      dispatch(setAccountStatementPayload(payload));
      dispatch(setSelectedAccountStatement({ accountInfo: values?.account }));
      return navigate("/payments/account-statement/payment-summary");
    }

    dispatch(POST_CREATE_ACCOUNT_STATEMENT_ACTION(payload))
      .unwrap()
      .then((res: any) => {
        // console.log(res, res.status)
        if (res.status === 200) {
          ToastNotify({
            type: "success",
            message:
              res.data.responseMessage.title ||
              "Account statement generated successfully",
            position: "top-right",
          });
          // STore the download url
          dispatch(setAccountStatementPDFURL(res.data.data));
          dispatch(
            setAccountStatementType(accountStatementTypeEnum.AccountStatement)
          );
          return navigate("/payments/account-statement/success");
        } else {
          return ToastNotify({
            type: "error",
            message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
            position: "top-right",
          });
        }
      });
  };

  const handleChequeClearanceSubmit = async (values: any) => {
    if (values.account?.accountNo === "") {
      return ToastNotify({
        type: "error",
        message: "Please select an account number to continue",
        position: "top-right",
      });
    }

    if (values.startDate === "" || values.endDate === "") {
      return ToastNotify({
        type: "error",
        message: "Start date and end Date is required to continue",
        position: "top-right",
      });
    }

    if (values.startDate !== "" && values.endDate !== "") {
      const date1 = dayjs(values.startDate);
      const date2 = dayjs(values.endDate);
      const todayDate = dayjs();
      const sixMonthsRange = dayjs(values?.startDate).add(6, "month");
      if (date1 > date2) {
        return ToastNotify({
          type: "error",
          message: "Start date cannot be greater than end date",
          position: "top-right",
        });
        // return toast.error("Start date cannot be greater than end date");
      }
      if (date1 > todayDate) {
        return ToastNotify({
          type: "error",
          message: "Start date cannot be greater than today",
          position: "top-right",
        });
      }
      if (date2 > sixMonthsRange) {
        return ToastNotify({
          type: "error",
          message: "Kindly download your statements in six-month batches.",
          position: "top-right",
        });
      }
    }

    let payload = {
      accountNo: values?.account?.accountNo,
      startDate: values?.startDate,
      endDate: values?.endDate,
    };
    dispatch(setLoadingState(["POST_CREATE_CHEQUE_CLEARANCE_STATEMENT"]));
    dispatch(POST_CREATE_CHEQUE_CLEARANCE_STATEMENT_ACTION(payload))
      .unwrap()
      .then((res: any) => {
        dispatch(setLoadingState([]));
        if (res.status === 200) {
          ToastNotify({
            type: "success",
            message:
              res.data.responseMessage.title ||
              "Cheque Clearance statement generated successfully",
            position: "top-right",
          });
          // STore the download url
          dispatch(setAccountStatementPDFURL(res.data.data));
          dispatch(
            setAccountStatementType(accountStatementTypeEnum.ChequeClearance)
          );
          return navigate("/payments/account-statement/success");
        } else {
          return ToastNotify({
            type: "error",
            message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
            position: "top-right",
          });
        }
      });
  };

  const handlePaymentSummarySubmit = async (values: any) => {
    if (!authState.user?.hasTransactionPin && values.token === "") {
      return ToastNotify({
        type: "error",
        message: "Please enter a token.",
        position: "top-right",
      });
    }
    if (!values?.account?.accountNo) {
      return ToastNotify({
        type: "error",
        message: "Please select account to debit.",
        position: "top-right",
      });
    }
    if (authState.user?.hasTransactionPin && values.pin === "") {
      return ToastNotify({
        type: "error",
        message: "Please enter a transaction pin.",
        position: "top-right",
      });
    }
    const account = values?.account;
    const requestAccount = selectedAccountStatement?.accountInfo;
    const transactionPin = values?.pin;
    const token = values?.token;
    const hasPin = authState.user?.hasTransactionPin;
    const hasSoftToken = authState.user?.hasSoftToken;
    const isHardToken = !hasPin && !hasSoftToken;
    if (isHardToken && (!token || !transactionPin)) {
      return ToastNotify({
        type: "error",
        message: "Transaction pin and hard token reqiured",
        position: "top-right",
      });
    }
    const payload = {
      ...accountStatementPayload,
      authorizationType:
        values?.authorizationType || accountStatementPayload.authorizationType,
      debitAccountNumber: account?.accountNo,
      token,
      requestingAccountName: requestAccount?.accountName,
      transactionPin,
      isSoftToken: isHardToken ? false : true,
      debitAccountName: account?.accountName,
    };
    dispatch(setAccountStatementPayload(payload));
    dispatch(POST_CREATE_ACCOUNT_STATEMENT_ACTION(payload))
      .unwrap()
      .then((res: any) => {
        if (res.status === 200) {
          if (
            isSoleProprietor ||
            isApprover ||
            isInitiatorApprover ||
            isSuperapprover
          ) {
            const resData = res?.data?.data;
            const correlationId = resData?.correlationId;
            dispatch(setAccountStatementPDFURL(resData));

            navigate("/payments/account-statement/pending", {
              state: {
                correlationId,
              },
            });
          } else {
            navigate("/payments/account-statement/success");
          }
        }
      });
  };

  return {
    authState,
    paymentState,
    initialValueForDetail,
    initialValueForPaymentSummary,
    handleDetailSubmit,
    handleChequeClearanceSubmit,
    handlePaymentSummarySubmit,
  };
};
