export const pendingActionsList = [
  {
    icon: "",
    title: "No Action from Referee",
    subtitle: "Your referees are yet to respond to your request",
    time: "5:00pm",
    route: "",
  },
  {
    icon: "",
    title: "Upload I.D Card",
    subtitle: "Upload a valid I.D Card",
    time: "3:30pm",
    route: "",
  },
  {
    icon: "",
    title: "Upload SCUML Certificate",
    subtitle: "Upload a valid SCUML Certificate",
    time: "2:50am",
    route: "",
  },
  {
    icon: "",
    title: "No Action from Referee",
    subtitle: "Your referees are yet to respond to your request",
    time: "5:00pm",
    route: "",
  },
  {
    icon: "",
    title: "Upload I.D Card",
    subtitle: "Upload a valid I.D Card",
    time: "3:30pm",
    route: "",
  },
  {
    icon: "",
    title: "Upload SCUML Certificate",
    subtitle: "Upload a valid SCUML Certificate",
    time: "2:50am",
    route: "",
  },
  {
    icon: "",
    title: "No Action from Referee",
    subtitle: "Your referees are yet to respond to your request",
    time: "5:00pm",
    route: "",
  },
  {
    icon: "",
    title: "Upload I.D Card",
    subtitle: "Upload a valid I.D Card",
    time: "3:30pm",
    route: "",
  },
  {
    icon: "",
    title: "Upload SCUML Certificate",
    subtitle: "Upload a valid SCUML Certificate",
    time: "2:50am",
    route: "",
  },
];
