import * as Yup from "yup";

export const AccountStatementDetailValidation = Yup.object().shape({
  statementType: Yup.object().required(),
  account: Yup.object().required(),
  startDate: Yup.string(),
  endDate: Yup.string(),
  isStampedStatement: Yup.boolean(),
});

export const AccountStatementPaymentSummaryValidation = Yup.object().shape({
  account: Yup.object().required(),
  token: Yup.string(),
  pin: Yup.string(),
});
