import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { useNavigate } from "react-router-dom";
import PendingAction from "./components/PendingAction";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import { useFetchRejectedDocuments } from "hooks/apis/pendingActions/useFetchRejectedDocuments";
import { useEffect, useState } from "react";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import PageLoader from "components/Loader/PageLoader";
import { deleteReferee } from "react-wrappers/stores/redux/onboarding/slices";
import { useAppDispatch } from "hooks/useReduxHook";

const PendingActionList = () => {
  const navigate = useNavigate();
  const {
    user: { regNumber, businessType },
  } = useAuthState();
  const dispatch = useAppDispatch();
  const { fetchLoading, rejectedDocuments, handleFetchRejectedDocuments } =
    useFetchRejectedDocuments();

  useEffect(() => {
    handleFetchRejectedDocuments({ regNumber, businessType });
  }, []);

  useEffect(() => {
    dispatch(deleteReferee(0));
    dispatch(deleteReferee(1));
  }, []);

  return (
    <DashboardWrapper>
      {fetchLoading && <PageLoader message="Fetching pending actions" />}
      <ReusableCard
        variant="md:w-[751px] w-full mx-auto"
        cardTitle="Pending Actions"
        handleBackNavigation={() => {
          navigate("/user-dashboard");
        }}
        showGoBackIcon
      >
        <div className="space-y-4 justify-center lg:w-[550px] mx-auto min-h-[300px]">
          {rejectedDocuments?.map((pendingAction: any, index: number) => {
            return (
              <PendingAction
                pendingAction={pendingAction}
                index={index}
                key={index}
              />
            );
          })}
        </div>
      </ReusableCard>
    </DashboardWrapper>
  );
};

export default PendingActionList;
