export const filterList = [
  {
    id: 181273,
    label: "All",
    value: 1,
  },
  {
    id: 14567,
    label: "Viewer",
    value: 2,
  },
  {
    id: 1010299,
    label: "Initiator",
    value: 3,
  },
  {
    id: 10102101,
    label: "Approver",
    value: 4,
  },
  {
    id: 10102100,
    label: "Verifier",
    value: 5,
  },
];
