import React from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "flowbite-react";
import { formatNumberToCurrency } from "utils/helperFunctions";
import { ReactComponent as TransationListIcon } from "../../../../assets/svgs/transactions-list-icon.svg";
import { setOneBulkTransactionID } from "react-wrappers/stores/redux/payments/slices";
import { useAppDispatch } from "hooks/useReduxHook";

export const ShowOneBulkTransactionBody = ({ list }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleViewReceipt = (singleTransaction: any) => {
    navigate("/payments/transaction-receipt", {
      state: {
        bulkPayload: singleTransaction,
      },
    });
  };
  return (
    <>
      {list?.length > 0 ? (
        <>
          {list?.map((item: any, index: number) => (
            <Table.Row
              onClick={() => dispatch(setOneBulkTransactionID(item?.reference))}
              key={index + item?.reference}
              className={`${index % 2 === 0 ? "bg-[#F7F7F7]" : "bg-white-500"} h-14 hover:bg-afb-gray-one dark:hover:bg-afb-gray-one cursor-pointer`}
            >
              <Table.Cell
                className="text-lib-alat-black text-[12px] flex space-x-2"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                <TransationListIcon />
                <span>{item?.narration || "No Description"}</span>
              </Table.Cell>
              <Table.Cell
                className="text-lib-alat-black text-[12px]"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                {item?.dateCreated}
              </Table.Cell>
              <Table.Cell
                className="text-[#FF0000] text-[12px] flex space-x-4"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                <span>+</span>
                {formatNumberToCurrency({
                  currencyCode: "NGN",
                  number: item?.amount,
                })}
              </Table.Cell>
              <Table.Cell
                className="text-[#FF0000] text-[12px]"
                style={{ padding: "10px 20px 10px 20px" }}
              >
                {item.status === "Failed" && (
                  <div
                    className="w-[144.12px] h-[29.02px] flex justify-center items-center text-[#FF0000] rounded-md"
                    style={{ background: "rgba(255, 0, 0, 0.2)" }}
                  >
                    Failed
                  </div>
                )}
                {item.status === "Retry" && (
                  <div
                    className="w-[144.12px] h-[29.02px] flex justify-center items-center text-[#FF0000] rounded-md"
                    style={{ background: "rgba(255, 0, 0, 0.2)" }}
                  >
                    Retry
                  </div>
                )}
                {item.status === "Success" && (
                  <div
                    className="w-[144.12px] h-[29.02px] flex justify-center items-center text-[#3BB54A] rounded-md"
                    style={{ background: "rgba(59, 181, 74, 0.2)" }}
                  >
                    Success
                  </div>
                )}
                {item.status === "Completed" && (
                  <div
                    className="w-[144.12px] h-[29.02px] flex justify-center items-center text-[#3BB54A] rounded-md"
                    style={{ background: "rgba(59, 181, 74, 0.2)" }}
                  >
                    Completed
                  </div>
                )}
                {item.status === "Reversed" && (
                  <div
                    className="w-[144.12px] h-[29.02px] flex justify-center items-center text-[#808080] rounded-md"
                    style={{ background: "rgba(128, 128, 128, 0.2)" }}
                  >
                    Reversed
                  </div>
                )}
                {item.status === "Rejected" && (
                  <div
                    className="w-[144.12px] h-[29.02px] flex justify-center items-center text-[#FF0000] rounded-md"
                    style={{ background: "rgba(255, 0, 0, 0.2)" }}
                  >
                    Rejected
                  </div>
                )}
                {(item.status === "Pending" ||
                  item.status?.toLowerCase() === "none") && (
                  <div
                    className="w-[144.12px] h-[29.02px] flex justify-center items-center text-[#FF9900] rounded-md"
                    style={{ background: "rgba(255, 153, 0, 0.2)" }}
                  >
                    Pending
                  </div>
                )}
                {item.status === "Processing" && (
                  <div
                    className="w-[144.12px] h-[29.02px] flex justify-center items-center text-[#FF9900] rounded-md"
                    style={{ background: "rgba(255, 153, 0, 0.2)" }}
                  >
                    Processing
                  </div>
                )}
              </Table.Cell>
              <Table.Cell
                className="text-lib-alat-black text-[12px] flex space-x-2 items-center"
                style={{ padding: "10px 20px 10px 20px" }}
                onClick={() => {
                  handleViewReceipt(item);
                }}
              >
                <span className="text-[#A90836] font-[600] underline underline-offset-2">
                  View receipt
                </span>
              </Table.Cell>
            </Table.Row>
          ))}
        </>
      ) : (
        <>
          <div className="h-20  flex justify-center items-center ">
            <div className="text-center text-sm w-full absolute left-0 ">
              <div className="flex flex-col items-center gap-3">
                <p className="leading-4 text-[#C4C4C4]">
                  No transactions found
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
