import { lazy } from "react";
import { Storage } from "utils/inAppstorage";
import { PendingRequests } from "pages/Dashboard/PendingRequests";
import BulkTransfer from "pages/Payments/BulkTransfer";
import PendingTransactions from "pages/Payments/PendingTransactions";
import TransactionDetail from "pages/Payments/TransactionDetail";
import CollectionDetail from "pages/Payments/CollectionDetail";
import { BankTransfer, FundTransfer, MultipleTransfer } from "../pages";
import TransactionHistoryTab from "pages/Payments/components/TransactionHistoryTab";
import TransactionReceipt from "pages/Payments/TransactionReceipt";
import AccountStatementDetail from "pages/Payments/AccountStatement/Details";
import AccountStatementPaymentSummary from "pages/Payments/AccountStatement/PaymentSummary";
import AccountStatementSuccess from "pages/Payments/modals/AccountStatementSuccess";
import AccountStatementPending from "pages/Payments/modals/AccountStatementPending";

import NewBills from "pages/BillPayment/NewBills";
import SelectedBill from "pages/BillPayment/SelectedBill";
import PayBill from "pages/BillPayment/PayBill";
import RecipentList from "pages/Payments/RecipentList";
import SelectedCollection from "pages/Collections/SelectedCollection";
import SelectedMerchant from "pages/Collections/SelectedMerchant";
import CollectionForm from "pages/Collections/CollectionForm";
import CollectionPayment from "pages/Collections/CollectionPayment";
import BillsPaymentDetail from "pages/Payments/BillsPaymentDetail";

const FxTransferIntro = lazy(() =>
  import("pages/Payments/FxTransfer/FxTransferIntro").then(
    ({ FxTransferIntro }) => ({ default: FxTransferIntro })
  )
);
const FxPendingRequestDetails = lazy(() =>
  import("pages/Dashboard/Fx/FxPendingRequestDetails").then(
    ({ FxPendingRequestDetails }) => ({ default: FxPendingRequestDetails })
  )
);

const SuccessfulBillPayment = lazy(() =>
  import("components/BillsPayment/SuccessfulBillPayment").then(
    ({ SuccessfulBillPayment }) => ({ default: SuccessfulBillPayment })
  )
);

const BillsPaymentFailureScreen = lazy(() =>
  import("components/BillsPayment/BillsPaymentFailureScreen").then(
    ({ BillsPaymentFailureScreen }) => ({ default: BillsPaymentFailureScreen })
  )
);
const BillPurchaseReceipt = lazy(() =>
  import("components/BillsPayment/BillPurchaseReceipt").then(
    ({ BillPurchaseReceipt }) => ({ default: BillPurchaseReceipt })
  )
);

const isProtected = Storage.getItem("afb-app-token");

export const routes = [
  {
    path: "/payments/funds-transfer",
    isAuthenticated: true,
    element: <FundTransfer />,
  },

  {
    path: "/payments/funds-transfer/:tabPath", //bank-transfer, fund-my-account, bulk-transfer
    isAuthenticated: true,
    element: <FundTransfer />,
  },

  {
    path: "/payments/bank-transfer",
    isAuthenticated: true,
    element: <BankTransfer />,
  },
  {
    path: "/payments/multiple-transfer",
    isAuthenticated: true,
    element: <MultipleTransfer />,
  },
  {
    path: "/payments/transaction/details",
    isAuthenticated: true,
    element: <TransactionDetail />,
  },
  {
    path: "/payments/collections/details",
    isAuthenticated: true,
    element: <CollectionDetail />,
  },

  {
    path: "/payments/requests/details/:correlationId",
    isAuthenticated: true,
    element: <PendingRequests />,
  },
  {
    path: "/payments/fx/details/:correlationId",
    isAuthenticated: true,
    element: <FxPendingRequestDetails />,
  },
  {
    path: "/payments/pending-transactions",
    isAuthenticated: true,
    element: <PendingTransactions />,
  },
  {
    path: "/payments/bulk/recipent-list",
    isAuthenticated: true,
    element: <RecipentList />,
  },
  {
    path: "/payments/bulk-transfer/:tabPath", //intra-transfer, inter-transfer, transaction-history
    isAuthenticated: true,
    element: <BulkTransfer />,
  },
  {
    path: "/payments/transaction-history",
    isAuthenticated: true,
    element: <TransactionHistoryTab />,
  },
  {
    path: "/payments/transaction-receipt",
    isAuthenticated: true,
    element: <TransactionReceipt />,
  },
  {
    path: "/payments/account-statement", //intra-transfer, inter-transfer, transaction-history
    isAuthenticated: true,
    element: <AccountStatementDetail />,
  },
  {
    path: "/payments/account-statement/payment-summary",
    isAuthenticated: true,
    element: <AccountStatementPaymentSummary />,
  },
  {
    path: "/payments/account-statement/success",
    isAuthenticated: true,
    element: <AccountStatementSuccess />,
  },
  {
    path: "/payments/account-statement/pending",
    isAuthenticated: true,
    element: <AccountStatementPending />,
  },
  {
    path: "/payments/bills/details",
    isAuthenticated: true,
    element: <BillsPaymentDetail />,
  },

  // FX - TRANSFER
  {
    path: "/payments/fx-transfer/:tabPath",
    isAuthenticated: true,
    element: <FxTransferIntro />,
  },
  //Bills Payment
  {
    path: "/Bills/Payment", //Buy airtime and data
    isAuthenticated: true,
    element: <NewBills />,
  },

  {
    path: "/bills/payment/selected-bill",
    isAuthenticated: true,
    element: <SelectedBill />,
  },
  {
    path: "/bills/payment/pay-bill",
    isAuthenticated: true,
    element: <PayBill />,
  },
  {
    path: "/bills/payment/pay-bill-confirmation",
    isAuthenticated: true,
    element: <PayBill />,
  },
  {
    path: "/bills/payment/pay-bill/otp",
    isAuthenticated: true,
    element: <PayBill />,
  },
  {
    path: "/bills/payment/pay-bill/status",
    isAuthenticated: true,
    element: <PayBill />,
  },
  {
    path: "/bills/payment/pay-bill/success",
    isAuthenticated: true,
    element: <SuccessfulBillPayment />,
  },
  {
    path: "/bills/payment/pay-bill/failure",
    isAuthenticated: true,
    element: <BillsPaymentFailureScreen />,
  },
  {
    path: "/bills/payment/pay-bill/receipt",
    isAuthenticated: true,
    element: <BillPurchaseReceipt />,
  },
  {
    path: "/bills/collections/select-category",
    isAuthenticated: true,
    element: <SelectedCollection />,
  },
  {
    path: "/bills/collections/category-item",
    isAuthenticated: true,
    element: <SelectedMerchant />,
  },
  {
    path: "/bills/collections/form",
    isAuthenticated: true,
    element: <CollectionForm />,
  },
  {
    path: "/bills/collections/simple-form",
    isAuthenticated: true,
    element: <CollectionForm />,
  },
  {
    path: "/bills/collections/success",
    isAuthenticated: true,
    element: <CollectionForm />,
  },
  {
    path: "/bills/collections/payment",
    isAuthenticated: true,
    element: <CollectionPayment />,
  },
];

const unProtectedRoutes: any = [];

export const paymentsRoutes = isProtected ? routes : unProtectedRoutes;
