import { LayerProps } from "../interfaces";
// import Header from "./DefaultHeader";

const LayoutWithHeader = ({ variant, children }: LayerProps) => {
  return (
    <section className={variant}>
      {/* <Header /> */}
      {children}
    </section>
  );
};

export default LayoutWithHeader;
