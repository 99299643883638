import { FormikButton } from "alat-components-library";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  updateFirstReferee,
  updateSecondReferee,
} from "react-wrappers/stores/redux/onboarding/slices";
import CenterModal from "alat-components-library/Modal/CenterModal";
import { ReactComponent as SuccessIcon } from "assets/svgs/success-icon.svg";
import PageLoader from "components/Loader/PageLoader";
import DashboardWrapper from "components/Layouts/DashboardWrapper";
import PendingActionsWrapper from "../../components/PendingActionsWrapper";
import { UpdatedRefereeCard } from "./UpdatedRefereeCard";
import { Form, Formik } from "formik";
import { useResubmitRejectedDocuments } from "hooks/apis/pendingActions/useResubmitRejectedDocuments";
import { useNextScreen } from "../../components/NextScreen";
import { useFetchRejectedDocuments } from "hooks/apis/pendingActions/useFetchRejectedDocuments";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";

type ResubmissionPayload = {
  pendingAction: number;
  pendingActionId: number;
  cacRegNumber: string;
  actionData: string;
};

const UpdatedReferees = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [successModal, setSuccessModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { handleNextScreen } = useNextScreen();

  // Store
  const referees = useAppSelector((state) => state.onboarding.refereePayload);

  const {
    user: { regNumber, businessType },
  } = useAuthState();

  // Apis
  const { rejectedRefereeCount } = useAppSelector(
    (state) => state.pendingActions
  );
  const rejectedDocuments = useAppSelector(
    (state) => state.pendingActions.rejectedDocuments
  );
  const {
    fetchLoading,
    rejectedDocuments: updatedPendingActions,
    handleFetchRejectedDocuments,
  } = useFetchRejectedDocuments();
  const filteredReferees = referees.filter((referee) => referee?.bank !== "");

  const {
    fetchLoading: submitLoading,
    resubmittedResponse,
    handleResubmittedDocuments,
  } = useResubmitRejectedDocuments();

  // Handles Resubmission of rejected document
  const handleSubmit = (payload: ResubmissionPayload) => {
    try {
      handleResubmittedDocuments(payload);
    } catch (error) {}
  };

  const handleSubmitText = () => {
    // console.log(
    //   referees[0].bank,
    //   referees[1].bank,
    //   rejectedRefereeCount,
    //   "counts"
    // );
    if (
      (referees[1].bank === "" || referees[0].bank === "") &&
      rejectedRefereeCount === 2
    ) {
      return "Add Another Referee";
    } else if (
      referees[1].bank === "" &&
      referees[0].bank === "" &&
      rejectedRefereeCount === 1
    ) {
      return "Add Another Referee";
    } else {
      return "Submit";
    }
  };

  const resetReferees = () => {
    dispatch(
      updateFirstReferee({
        bank: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        accountNumber: "",
      })
    );
    dispatch(
      updateSecondReferee({
        bank: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        accountNumber: "",
      })
    );
  };

  const handleSubmitReferees = async () => {
    // console.log(referees);
    let payload = JSON.parse(rejectedDocuments?.actionData);
    // console.log()
    // const rejectedDocuments =
    if (referees[1].bank === "" || referees[0].bank === "") {
      if (referees[0].bank !== "" && rejectedRefereeCount === 1) {
        // make call with one referee in the payload
        const { accepted, ...refereeObject } = referees[0];
        const payloadString = JSON.stringify([
          {
            ...refereeObject,
            correlationId: payload[0]?.CorrelationId,
            id: payload[0]?.Id,
          },
        ]);
        const refereePayload = {
          ...rejectedDocuments,
          actionData: payloadString,
        };
        await handleSubmit(refereePayload);
      } else if (referees[1].bank !== "" && rejectedRefereeCount === 1) {
        // make call with one referee in the payload
        const { accepted, ...refereeObject } = referees[1];
        const payloadString = JSON.stringify([
          {
            ...refereeObject,
            correlationId: payload[0]?.CorrelationId,
            id: payload[0]?.Id,
          },
        ]);
        const refereePayload = {
          ...rejectedDocuments,
          actionData: payloadString,
        };
        await handleSubmit(refereePayload);
      } else {
        navigate("/pending-actions/rejected-docs/add-referee");
      }
    } else {
      // make call with both referees in the payload
      const refPayload = referees.map((item, index) => {
        const {
          id,
          bank,
          firstName,
          lastName,
          emailAddress,
          phoneNumber,
          accountNumber,
        } = item;
        return {
          Id: id,
          Bank: bank,
          FirstName: firstName,
          LastName: lastName,
          EmailAddress: emailAddress,
          PhoneNumber: phoneNumber,
          AccountNumber: accountNumber,
          CorrelationId: payload[index]?.CorrelationId,
        };
      });

      const payloadString = JSON.stringify(refPayload);
      const refereePayload = {
        ...rejectedDocuments,
        actionData: payloadString,
      };
      await handleSubmit(refereePayload);
    }
  };

  useEffect(() => {
    if (resubmittedResponse) {
      navigate("/pending-actions/successful-resubmission", {
        state: { title: "Rejected Referees" },
      });
    }
  }, [resubmittedResponse]);

  return (
    <DashboardWrapper>
      {(submitLoading || fetchLoading) && (
        <PageLoader message="Submitting Referees" />
      )}
      <PendingActionsWrapper cardTitle="Rejected Referees">
        {false && <PageLoader message="Saving Referees" />}

        <div className="w-full px-4">
          <div className="md:w-[500px] w-full mx-auto py-5">
            {!referees[0].id && (
              <p className="text-center pb-7">You need to have two referees</p>
            )}
            <div className="flex flex-col gap-1">
              {referees
                .filter((referee) => referee?.bank !== "")
                .map(({ bank, emailAddress, firstName, lastName }, index) => {
                  return (
                    // eslint-disable-next-line
                    <div key={index}>
                      <UpdatedRefereeCard
                        id={`referee-${index}_card`}
                        actionId={`action-${index}_btn`}
                        deleteId={`delete-${index}_btn`}
                        editId={`edit-${index}_btn`}
                        index={index}
                        bank={bank}
                        email={emailAddress}
                        firstName={firstName}
                        lastName={lastName}
                        filteredReferees={filteredReferees}
                        isRejectedReferees
                      />
                    </div>
                  );
                })}
            </div>
            <Formik
              initialValues={{}}
              onSubmit={() => {
                handleSubmitReferees();
              }}
            >
              {() => (
                <Form>
                  <div className="w-full">
                    <FormikButton
                      id="submit-referees_btn"
                      labelText={handleSubmitText()}
                      containerVariant="w-full h-12 rounded-md flex justify-center my-10"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        {/*Referee Saved Successfully*/}
        {successModal && (
          <CenterModal
            icon={<SuccessIcon />}
            title="Success!"
            info={`A referee verification link has been sent to the emails (${referees[0].emailAddress} and ${referees[1].emailAddress}), Kindly ensure your referees respond via the link sent).`}
            handleClick={() => {
              resetReferees();
              navigate("/user-dashboard");
            }}
            handleClose={() => {
              resetReferees();
              setSuccessModal(false);
            }}
            handleClick2={() => {
              resetReferees();
              navigate("/user-dashboard");
            }}
            btnText="Okay"
            btnText2={"Return to dashboard"}
            showCloseIcon
          />
        )}
      </PendingActionsWrapper>
    </DashboardWrapper>
  );
};

export default UpdatedReferees;
