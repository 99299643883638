import { CheckIcon } from "@heroicons/react/24/solid";
import { Button, Card, Label } from "flowbite-react";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useBankLists } from "hooks/apis/Payment/useBankLists";
import PageLoader from "components/Loader/PageLoader";
import { ToastNotify } from "helpers/toastNotify";
import { useApiRequest } from "hooks/useApiRequest";
import {
  GET_ACCOUNT_NAME_ENQUIRY,
  POST_ADD_BENEFICIARY,
} from "utils/apis/routes";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { ReusableCard } from "components/reusables/Page/ReusableCard";
import { Form, Formik } from "formik";
import {
  FormikButton,
  PasswordInput,
  PrimaryButton,
  TextInput,
} from "alat-components-library";
import { addNewBeneficiarySchema } from "./validations/addNewBeneficiary.validations";
import { BeneficiarySuccess } from "./BeneficiarySuccess";

const AddNewBeneficiary = () => {
  const { bankLists, handleGetBankList } = useBankLists();
  //API Request
  const makeRequest = useApiRequest();
  const navigate = useNavigate();

  const [accountNumber, setAccountNumber] = useState("");
  const [selectedBank, setSelectedBank] = useState({
    bankCode: "",
    bankName: "",
  });
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [beneficiaryCurrency, setBeneficiaryCurrency] = useState("");
  const [accountValidated, setAccountValidated] = useState(false);
  const [accountValidationError, setAccountValidationError] = useState("");
  const [showScreen, setshowScreen] = useState(1);
  const [isLoading, setisLoading] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const auth = useAuthState();

  const handleBackToFundsTransfer = () => {
    navigate("/payments/funds-transfer/tab/");
  };

  const goBack = () => {
    setAccountValidated(false);
  };

  const handleChangeBank = (event: { target: { name: any; value: any } }) => {
    if (event.target.name === "selectedBank") {
      setAllowSubmit(false);
      setSelectedBank(JSON.parse(event.target.value));
      setAccountNumber("");
      setAccountValidated(false);
    }
  };

  useEffect(() => {
    handleGetBankList();
    // eslint-disable-next-line
  }, []);
  const handleAccountValidation = async (acctNumber: string) => {
    // console.log("Acc", acctNumber);
    try {
      setisLoading(true);
      const res = await makeRequest({
        method: "get",
        route: GET_ACCOUNT_NAME_ENQUIRY(acctNumber, selectedBank?.bankCode),
        originType: "transferservice",
      });

      if (res?.status === 200) {
        setAccountValidated(true);
        setBeneficiaryName(res?.data?.data?.accountName);
        setBeneficiaryCurrency(res?.data?.data?.currency);
      } else {
        ToastNotify({
          type: "error",
          message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
        setAccountValidationError(res?.data?.data?.responseMessage?.title);
      }
      setisLoading(false);
    } catch (e) {
      setisLoading(false);
      setAccountValidationError(
        "Unable to validate account, Kindly check the details and try again"
      );
    }
  };

  const hasPin = auth?.user?.hasTransactionPin;
  const hasSoftToken =
    auth?.user?.hasSoftToken && !auth?.user?.hasTransactionPin;
  const useHardToken =
    !auth?.user?.hasSoftToken && !auth?.user?.hasTransactionPin;

  // console.log("accountValidated", accountValidated);
  if (showScreen === 2) {
    return <BeneficiarySuccess cta={handleBackToFundsTransfer} />;
  }

  return (
    <>
      {isLoading && <PageLoader message="Please wait" />}
      <ReusableCard
        cardTitle={"Add a new beneficiary"}
        variant={"md:w-[48rem] w-full mx-auto"}
        handleBackNavigation={
          accountValidated ? goBack : handleBackToFundsTransfer
        }
        showGoBackIcon
      >
        <div className="w-full flex items-center justify-center">
          <p className="text-[14px] leading-[17px]">
            Complete the beneficiary details below
          </p>
        </div>
        <Formik
          initialValues={{
            accountNumber: "",
            softToken: "",
            hardTokenOtp: "",
            hardTokenPin: "",
            pin: "",
            alias: "",
          }}
          onSubmit={async (values, actions) => {
            const body = {
              beneficiaryId: 0,
              beneficiaryType: 3,
              beneficiaryManagementAction: 1,
              currencyCategory: 1,
              alias: values?.alias,
              bankCode: selectedBank.bankCode,
              bankName: selectedBank.bankName,
              accountNumber: values?.accountNumber,
              accountName: beneficiaryName,
              currency: beneficiaryCurrency,
              isSoftToken: auth?.user?.hasSoftToken,
              authenticationType: values?.pin === "" ? 2 : 1,
              transactionPin: values?.pin,
              token:
                values?.softToken === ""
                  ? `${values?.hardTokenPin}${values?.hardTokenOtp}`
                  : values?.softToken,
            };
            try {
              setisLoading(true);
              const res = await makeRequest({
                method: "post",
                route: POST_ADD_BENEFICIARY,
                body: body,
                originType: "transferservice",
              });

              // console.log("res", res);

              if (res.status === 200) {
                // setisLoading(false);
                setshowScreen(2);
                setSelectedBank({ bankCode: "", bankName: "" });
                setBeneficiaryName("");
              } else if (res.status === 500) {
                setisLoading(false);
                setAccountValidated(false);
                ToastNotify({
                  type: "error",
                  message: "Oops! Something went wrong",
                  position: "top-right",
                });
                setshowScreen(1);
              } else {
                // setisLoading(false);
                actions.setValues({
                  ...values,
                  softToken: "",
                  hardTokenOtp: "",
                  hardTokenPin: "",
                  pin: "",
                });
                setAccountValidated(false);
                ToastNotify({
                  type: "error",
                  message: `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
                  position: "top-right",
                });
                setshowScreen(1);
              }
              setisLoading(false);
              // setshowScreen(7);
            } catch (e: any) {
              setisLoading(false);
              // setshowScreen(3);

              if (e.response.status === 400) {
                ToastNotify({
                  type: "error",
                  message: e?.response?.data?.responseMessage?.title,
                  position: "top-right",
                });
              }
            }
          }}
          validationSchema={addNewBeneficiarySchema({
            hasPin,
            hasSoftToken,
            useHardToken,
          })}
        >
          {({
            handleChange,
            handleBlur,
            setValues,
            errors,
            touched,
            isValid,
            dirty,
            values,
          }) => {
            return (
              <Form className="mx-auto grid grid-cols-1 gap-8 py-5 md:px-20">
                <div className="w-full col-span-full">
                  <div className="mb-2 block text-left text-[#666666]">
                    <Label
                      value="Input or select a bank"
                      className=" !text-[#464646] font-normal text-lg leading-[1rem]"
                    />
                  </div>
                  <div className="w-full">
                    <select
                      name="selectedBank"
                      className="w-full px-4 py-3 truncate text-left bg-[#F7F7F7] text-[#666666] border rounded-md shadow-sm outline-none "
                      onChange={handleChangeBank}
                      required
                    >
                      <option hidden>-- Select --</option>
                      {bankLists.map((bank: any, index: any) => (
                        <option key={index} value={JSON.stringify(bank)}>
                          {bank.bankName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <TextInput
                  id="accountNumber"
                  label={"Account number"}
                  name={"accountNumber"}
                  placeHolder={""}
                  handleChange={(e) => {
                    // console.log("e", e);
                    handleChange(e);
                    if (e.target.value?.length === 10) {
                      handleAccountValidation(e?.target?.value);
                    }
                  }}
                  handleBlur={handleBlur}
                  value={values?.accountNumber}
                  hasError={errors?.accountNumber && touched?.accountNumber}
                  error={errors?.accountNumber}
                  isDisabled={accountValidated}
                  maxLength={10}
                  extraLabel={
                    <>
                      <p
                        className=" text-primary underline cursor-pointer font-normal text-sm"
                        onClick={() => {
                          if (values?.accountNumber?.length === 10) {
                            handleAccountValidation(values?.accountNumber);
                          }
                        }}
                      >
                        Validate account
                      </p>
                    </>
                  }
                  showExtras={accountValidated ? false : true}
                />

                {accountValidated && (
                  <div className="space-y-8">
                    <TextInput
                      label={"Name of beneficiary"}
                      name={"nameOfBeneficiary"}
                      placeHolder={""}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={beneficiaryName}
                      isDisabled
                    />
                    <TextInput
                      id="EnterAliasInput"
                      label={"Enter Alias"}
                      name={"alias"}
                      placeHolder={""}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values?.alias}
                      hasError={errors?.alias && touched?.alias}
                      error={errors?.alias}
                    />

                    {hasPin && (
                      <PasswordInput
                        name={"pin"}
                        label={"Enter PIN"}
                        placeHolder={""}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.pin}
                        hasError={errors?.pin && touched?.pin}
                        error={errors?.pin}
                        iconStyle={{
                          wrapper: "bottom-[2vh] right-[2vh]",
                          icon: "w-[3.5vh] h-[3.5vh]",
                        }}
                        maxLength={4}
                      />
                    )}

                    {hasSoftToken && (
                      <TextInput
                        id="EnterToken"
                        type={"password"}
                        label={"Enter Soft Token"}
                        name={"softToken"}
                        placeHolder={""}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values?.softToken}
                        hasError={errors?.softToken && touched?.softToken}
                        error={errors?.softToken}
                        maxLength={6}
                      />
                    )}
                    {useHardToken && (
                      <div className="grid grid-cols-1 gap-8">
                        <PasswordInput
                          name={"hardTokenPin"}
                          label={"Enter Hard Token PIN"}
                          placeHolder={""}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.hardTokenPin}
                          hasError={
                            errors?.hardTokenPin && touched?.hardTokenPin
                          }
                          error={errors?.hardTokenPin}
                          iconStyle={{
                            wrapper: "bottom-[2vh] right-[2vh]",
                            icon: "w-[3.5vh] h-[3.5vh]",
                          }}
                          maxLength={4}
                        />

                        <TextInput
                          id="EnterToken"
                          type={"password"}
                          label={"Enter Hard Token OTP"}
                          name={"hardTokenOtp"}
                          placeHolder={""}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.hardTokenOtp}
                          hasError={
                            errors?.hardTokenOtp && touched?.hardTokenOtp
                          }
                          error={errors?.hardTokenOtp}
                          maxLength={6}
                        />
                      </div>
                    )}
                  </div>
                )}

                {!accountValidated ? (
                  <PrimaryButton
                    labelText={"Proceed"}
                    handleClick={() => {
                      handleAccountValidation(values?.accountNumber);
                    }}
                    isDisabled={
                      values?.accountNumber?.length < 10 ||
                      selectedBank?.bankName === ""
                    }
                    variant={"text-white font-medium text-xs"}
                    containerVariant={`md:w-1/2 w-full h-10 rounded-md mx-auto flex justify-center cursor-pointer ${
                      values?.accountNumber === "" ||
                      selectedBank?.bankName === ""
                        ? "bg-[#A9083680]"
                        : "bg-lib-alat-red"
                    }  `}
                  />
                ) : (
                  <FormikButton
                    id="ProceedBtn"
                    labelText="Save beneficiary"
                    variant="md:w-1/2 w-full h-10 md:h-12 mx-auto text-sm tracking-tighter leading-[28px] font-semibold rounded-md text-white flex flex-row justify-center item-center pt-2"
                    containerVariant="relative w-full cursor-pointer rounded-md"
                    isDisabled={!(isValid && dirty)}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </ReusableCard>
    </>
  );
};

export default AddNewBeneficiary;
