import { Button, Card } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ToggleOnIcon from "../../assets/icons/toggle-on-icon.png";
import ToggleOffIcon from "../../assets/icons/toggle-off-icon.png";
import { moneyInputFormat } from "utils/helperFunctions";
import { ReactComponent as SuccessIcon } from "../../assets/svgs/success-icon.svg";
import { ReactComponent as PendingIcon } from "../../assets/svgs/pending-icon.svg";
import { ReactComponent as FailedIcon } from "../../assets/svgs/failed-icon.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel_icon.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { responseMessageHandler } from "alat-components-library/utils/responseMessageHandler";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import {
  FormikButton,
  PasswordInput,
  PrimaryButton,
  TextInput,
} from "alat-components-library";
// import { POST_VALIDATE_PIN_ACTION } from "react-wrappers/stores/redux/payments/actions";
import { useAppDispatch } from "hooks/useReduxHook";
import {
  resetPaymentsData,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { ToastNotify } from "helpers/toastNotify";
import { Toggle } from "components/reusables/Toggle/Toggle";
export interface PopUpProps {
  onRefresh?: any;
  type: string;
  onGenerateReceipt?: any;
  amount?: any;
  recipient?: any;
  saveBeneficiary?: boolean;
  saveGroup?: boolean;
  bankCode?: any;
  bankName?: any;
  accountNumber?: any;
  currency?: any;
  pin?: any;
  correlationId?: string;
  onRetry?: any;
  transaction?: any;
  handleTransactionBreakdown?: any;
  sumTotal?: any;
}
const ResponseStatus = ({
  onRefresh,
  type,
  onGenerateReceipt,
  amount,
  recipient = null,
  saveBeneficiary = false,
  saveGroup = false,
  bankCode,
  bankName,
  accountNumber,
  currency = "NGN",
  pin,
  correlationId,
  onRetry,
  transaction,
  handleTransactionBreakdown,
  sumTotal,
}: PopUpProps) => {
  const navigate = useNavigate();
  const authState = useAuthState();
  const paymentState = usePaymentState();
  const handleGotoDashboard = () => {
    navigate("/user-dashboard");
    dispatch(resetPaymentsData());
  };
  // const baseURL = "https://wema-afbdev-apim.azure-api.net";
  const baseURL = process.env.REACT_APP_API_ORIGIN;

  const token: any = localStorage.getItem("afb-app-token");
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const dispatch = useAppDispatch();
  const [toggle, setToggle] = useState(false);
  const [saveBeneficiaryPin, setSaveBeneficiaryPin] = useState("");
  const [saveBeneficiaryPinError, setSaveBeneficiaryPinError] = useState("");
  const [pinValidated, setPinValidated] = useState(false);
  const [beneficiaryAlias, setBeneficiaryAlias] = useState("");
  const [saveBeneficiaryPinModal, setSaveBeneficiaryPinModal] = useState(false);
  const [saveGroupModal, setSaveGroupModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [saveBeneficiaryError, setSaveBeneficiaryError] = useState("");
  const handleSaveBeneficiaryToggle = () => {
    setToggle(true);
    handleSaveBeneficiary();
  };
  const handleSaveGroupToggle = () => {
    setToggle(true);
    handleSaveGroup();
  };

  const saveBeneficiaryRef = useRef<HTMLDivElement>(null);

  const handleCloseModal = (event: MouseEvent) => {
    if (
      saveBeneficiaryRef.current &&
      !saveBeneficiaryRef.current.contains(event.target as Node)
    ) {
      setSaveBeneficiaryPinModal(false);
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleCloseModal);
    return () => {
      document.removeEventListener("mousedown", handleCloseModal);
    };
  });

  const handleSaveBeneficiary = async () => {
    // prevents the submit button from refreshing the page
    // console.log(pin, "Pin");

    // This block checks if the user's transaction was completed with a pin otherwise the user would be required to provide a pin.

    if (beneficiaryAlias === "") {
      setSaveBeneficiaryPinModal(true);
    } else {
      const body = {
        correlationId: correlationId || "",
        alias: beneficiaryAlias,
      };

      try {
        // const res = await makeRequest.post(validateTransfer, body);
        const response = await axios.post(
          `${baseURL}/afbtransferservice/api/v1/Beneficiary/AddBeneficiaryByCorrelationId`,
          body,
          options
        );

        setToggle(true);
        if (response.data.httpStatusCode === 200) {
          if (response.data.data) {
            // console.log(response);
            toast.success(responseMessageHandler({ response }));
            // setshowScreen(2);
          } else {
            // setshowScreen(3);
          }
        } else {
          // setshowScreen(2);
        }
        // setisLoading(false);
        // setshowScreen(7);
      } catch (error: any) {
        // setisLoading(false);
        // setshowScreen(3);
        ToastNotify({
          type: "error",
          message: responseMessageHandler({ error }),
        });
        // toast.error(responseMessageHandler({ error }));
        if (error.response.status === 400) {
          setSaveBeneficiaryError(error.response.data.responseMessage.title);
          ToastNotify({
            type: "error",
            message: error.response.data.responseMessage.title,
          });
        }
      }
      setBeneficiaryAlias("");
    }
  };
  const handleSaveGroup = async () => {
    // prevents the submit button from refreshing the page

    // setisLoading(true);
    const body = {
      correlationId: correlationId,
      groupName: groupName === "" ? "New Group" : groupName,
    };
    try {
      // setisLoading(false);
      // const res = await makeRequest.post(validateTransfer, body);

      const response = await axios.post(
        `${baseURL}/afbtransferservice/api/v1/Transfer/AddMultiplieTransferGroup`,
        body,
        options
      );

      setToggle(true);
      // console.log((responseMessageHandler({ response })))

      if (response?.status === 200) {
        ToastNotify({
          type: "success",
          message: `${response?.data?.responseMessage?.title}. ${response?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      } else {
        // setshowScreen(2);
        ToastNotify({
          type: "error",
          message: `${response?.data?.responseMessage?.title}. ${response?.data?.responseMessage?.actionMessage}`,
          position: "top-right",
        });
      }
      // setisLoading(false);
      // setshowScreen(7);
    } catch (error: any) {
      // console.log(responseMessageHandler({ error })?.responseMessage?.title);

      // setisLoading(false);
      // setshowScreen(3);
      ToastNotify({
        type: "error",
        message: responseMessageHandler({ error })?.responseMessage?.title,
        position: "top-right",
      });
      if (error.response.status === 400) {
        ToastNotify({
          type: "error",
          message: error.response.data.responseMessage.title,
          position: "top-right",
        });
        // setSaveBeneficiaryError(error.response.data.responseMessage.title);
        // alert(error.response.data.responseMessage.title);
      }
    }
  };
  return (
    <div>
      {type === "pending" && (
        <div id="pending_card">
          <div></div>
          <div>
            <Card className="md:w-[768px] w-full mx-auto bg-white dark:border-gray-200 dark:bg-white">
              <div>
                <div className="text-center flex justify-center mt-10">
                  {/* <div className="flex justify-center items-center w-16 h-16 bg-[#fbd090] rounded-full">
                    <div className=" flex justify-center items-center w-11 h-11 bg-[#ff990085] rounded-full">
                      <div className=" flex justify-center items-center w-6 h-6 bg-white rounded-full">
                        <ClockIcon className="mx-auto w-6 h-6 text-[#FF9900] opacity-100 light:text-gray-200" />
                      </div>
                    </div>
                  </div> */}
                  <PendingIcon fill="inherit" />
                </div>

                {/* <div className="text-center text-lg font-normal mt-5 text-[#FF9900]">
                  Thank You!
                </div> */}

                <h3 className="text-center mb-5 text-lg font-normal mt-5 text-gray-500 light:text-gray-400">
                  {/* Your transaction is being processed. */}
                  {`${paymentState?.transferResponseMessage?.title}`}
                </h3>

                <h3 className="text-center mb-5 text-lg font-normal mt-5 text-gray-500 light:text-gray-400 md:px-20 p-5">
                  {/* If you are not redirected in 30 seconds, use the refresh
                  button below to manually check for your transaction status. */}
                  {paymentState?.transferResponseMessage?.actionMessage}
                </h3>

                <div className="flex justify-center flex-row gap-4 mb-40 mt-10">
                  <Button
                    id="refresh_btn"
                    onClick={onRefresh}
                    className="hover:text-white dark:hover:!text-white dark:hover:bg-primary  w-[188px] bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:boder-primary  focus:text-white"
                  >
                    Refresh
                  </Button>
                  <Button
                    id="proceed-to-dashboard_btn"
                    onClick={handleGotoDashboard}
                    className="hover:text-white dark:hover:!text-white dark:hover:bg-primary  w-[188px] bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:boder-primary  focus:text-white"
                  >
                    Proceed to Dashboard
                  </Button>
                </div>
              </div>
            </Card>

            <div></div>
          </div>
        </div>
      )}
      {type === "successful" && (
        <div id="success_card">
          <div>
            <div>
              <Card className="md:w-[768px] mx-auto w-full bg-white dark:border-gray-200 dark:bg-white">
                <div>
                  <div className="text-center flex justify-center mt-10">
                    {/* <div className="flex justify-center items-center w-16 h-16 bg-green-200 rounded-full">
                      <div className="flex justify-center items-center w-10 h-10 bg-green-600 rounded-full">
                        <div className=" flex justify-center items-center w-8 h-8 bg-white rounded-full">
                          <div className=" flex justify-center items-center w-6 h-6 bg-green-600 rounded-full">
                            <CheckIcon className="mx-auto w-6 h-6 text-white opacity-100 light:text-gray-200" />
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <SuccessIcon fill="inherit" />
                  </div>
                  <div className="text-center text-3xl font-semibold text-green-600 mt-6">
                    {`${paymentState?.transferResponseMessage?.title}`}
                  </div>

                  {!saveBeneficiary && (
                    <div>
                      <h3 className="text-center mb-5 text-lg font-normal text-gray-500 light:text-gray-400 mt-10">
                        {`${paymentState?.transferResponseMessage?.actionMessage}`}
                      </h3>
                    </div>
                  )}
                  {saveBeneficiary && (
                    <div className=" md:px-32 p-5 mt-10">
                      <div className="text-center text-base mb-8">
                        You just transferred ₦
                        <span className="font-bold" id="amount-transfered_text">
                          {moneyInputFormat(amount)?.withMoneyFormat || 0}
                        </span>{" "}
                        to{" "}
                        <span className="font-bold" id="recipient_text">
                          {" "}
                          {recipient}
                        </span>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div>Save this beneficiary</div>
                        <div
                          id="save-beneficiary-modal_btn"
                          onClick={handleSaveBeneficiaryToggle}
                          className="cursor-pointer"
                        >
                          <div>
                            {toggle ? (
                              <img src={ToggleOnIcon} alt="on" width={40} />
                            ) : (
                              <img src={ToggleOffIcon} alt="off" width={40} />
                            )}
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )}
                  {saveGroup && (
                    <div className=" md:px-32 p-5 mt-10">
                      {/* <div className="text-center text-base mb-8">
                        You just transferred ₦
                        <span className="font-bold">{sumTotal}</span> to{" "}
                        <span className="font-bold">
                          {" "}
                          {transaction[0]?.destinationAccountName}
                        </span>
                      </div> */}
                      <div className="flex flex-row items-center justify-between">
                        <div>Save group</div>
                        <Toggle
                          id="savegroup_toggle"
                          handleChange={(e: any) => setSaveGroupModal(true)}
                          checked={saveGroupModal}
                        />
                        {/* <div
                          id="save-group_btn"
                          onClick={() => {

                          }}
                          className="cursor-pointer"
                        >
                          <div>
                            {toggle ? (
                              <img src={ToggleOnIcon} alt="on" width={40} />
                            ) : (
                              <img src={ToggleOffIcon} alt="off" width={40} />
                            )}
                          </div>
                        </div> */}
                      </div>
                      <hr />
                    </div>
                  )}

                  <div className="flex justify-center flex-row gap-4 mb-40 mt-10">
                    <Button
                      id="return-to-dashboard_btn"
                      onClick={handleGotoDashboard}
                      className="hover:text-primary text-primary dark:hover:!text-primary dark:hover:bg-white  w-[188px] bg-white hover:bg-white focus:ring-1 focus:ring-primary disabled:hover:bg-white dark:bg-white  dark:focus:ring-primary dark:disabled:hover:bg-white border border-primary  focus:text-primary"
                    >
                      Return to dashboard
                    </Button>
                    {transaction?.length > 1 && onGenerateReceipt !== null ? (
                      <Button
                        id="generate-receipt_btn"
                        onClick={() => {
                          handleTransactionBreakdown();
                        }}
                        className="hover:text-white dark:hover:!text-white dark:hover:bg-primary  w-[188px] bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:boder-primary  focus:text-white"
                      >
                        View Breakdown
                      </Button>
                    ) : (
                      (authState.userRole === "soleproprietor" ||
                        authState.userRole === "initiatorapprover") &&
                      recipient && (
                        <Button
                          id="generate-receipt_btn"
                          onClick={onGenerateReceipt}
                          className="hover:text-white dark:hover:!text-white w-[188px] bg-primary focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:boder-primary  focus:text-white"
                        >
                          Generate Receipt
                        </Button>
                      )
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
      {type === "failed" && (
        <div>
          <div>
            <div></div>
            <div>
              <div></div>

              <Card
                className="md:w-[768px] mx-auto w-full bg-white dark:border-gray-200 dark:bg-white"
                id="failed-transaction_card"
              >
                <div>
                  <div className="text-center flex justify-center mt-10">
                    {/* <div className="flex justify-center items-center w-16 h-16 bg-red-200 rounded-full">
                      <div className="flex justify-center items-center w-10 h-10 bg-red-600 rounded-full">
                        <div className=" flex justify-center items-center w-8 h-8 bg-red-600 rounded-full">
                          <div className=" flex justify-center items-center w-6 h-6 bg-red-600 rounded-full">
                            <XMarkIcon className="mx-auto w-6 h-6 text-white opacity-100 light:text-gray-200" />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <FailedIcon fill="inherit" />
                  </div>

                  <div className="text-center text-lg font-normal mt-5 text-red-600">
                    {`${paymentState?.transferResponseMessage?.title}`}
                  </div>

                  <h3 className="text-center mb-5 text-lg font-normal mt-5 text-gray-500 light:text-gray-400">
                    {`${paymentState?.transferResponseMessage?.actionMessage}`}
                  </h3>

                  <div className="flex justify-center flex-row gap-4 mb-40 mt-10">
                    <Button
                      id="return-to-dashboard_btn"
                      color="gray"
                      onClick={handleGotoDashboard}
                      className="bg-white text-lib-alat-red border-lib-alat-red w-[188px]"
                    >
                      Return to dashboard
                    </Button>
                    <Button
                      id="retry_btn"
                      onClick={onRetry}
                      className="  hover:text-white dark:hover:!text-white dark:hover:bg-primary  w-[188px] bg-primary hover:bg-red-700 focus:ring-1 focus:ring-primary disabled:hover:bg-primary dark:bg-primary  dark:focus:ring-primary dark:disabled:hover:bg-primary hover:border hover:boder-primary  focus:text-white"
                    >
                      Try again
                    </Button>
                  </div>
                </div>
              </Card>

              <div></div>
            </div>
          </div>
        </div>
      )}
      {/* Save group modal */}
      {saveGroupModal && (
        <div className="fixed top-0 left-0 flex justify-center items-center w-full h-full  bg-black/80 z-2000  overflow-hidden backdrop-filter backdrop-blur-sm">
          <div className="flex flex-col md:w-[600px] items-center w-full rounded-[20px] space-y-4  bg-white">
            <div className="flex w-full justify-center md:px-10 p-5 border-b border-[#E4DFDF]">
              <div className=" flex items-center justify-between md:w-[484px] w-full">
                <div> </div>
                <div className="w-full">
                  <p className="font-medium text-base leading-[19px] tracking-[-0.03em]">
                    Save Group
                  </p>
                </div>
                <div
                  // id={idCloseModal}
                  onClick={() => setSaveGroupModal(false)}
                  className="cursor-pointer flex justify-end w-full"
                >
                  <CancelIcon />
                </div>
              </div>
            </div>
            <div className="flex flex-col md:w-[484px] w-full items-center gap-3 p-5 ">
              {/* <div className="text-center text-xl my-3">gROUP</div> */}
              <TextInput
                label="Group Name/Alias"
                name="groupName"
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setGroupName(e?.target?.value);
                }}
                id="group-name_input"
              />

              <PrimaryButton
                id="saveGroup_btn"
                labelText={"Save Group"}
                variant="font-medium text-xs text-white"
                containerVariant="w-[200px] h-[45px] rounded-md flex justify-center cursor-pointer bg-lib-alat-red"
                handleClick={() => {
                  if (groupName !== "") {
                    handleSaveGroup();
                    setSaveGroupModal(false);
                  }
                }}
                isDisabled={groupName === ""}
              />
            </div>
          </div>
        </div>
      )}
      {/* Pin Input Modal For Saved Beneficiary */}
      {saveBeneficiaryPinModal && (
        <div
          className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-50 p-5 z-[999999] overflow-hidden backdrop-filter backdrop-blur-sm"
          style={{ margin: 0 }}
        >
          <center
            className="z-10 bg-white shadow-md rounded-xl"
            ref={saveBeneficiaryRef}
          >
            <div className="flex w-full flex-col justify-center items-center p-4 px-8 relative mb-4 gap-3">
              <div className="text-center text-xl my-3">Save Beneficiary</div>
              <TextInput
                label="Beneficiary Name/Alias"
                name="beneficiaryAlias"
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setBeneficiaryAlias(e?.target?.value);
                }}
                id="beneficiary-alias_input"
              />
              <div
                id="save-beneficiary_btn"
                onClick={() => {
                  if (beneficiaryAlias !== "") {
                    handleSaveBeneficiary();
                    setSaveBeneficiaryPinModal(false);
                  }
                }}
              >
                <FormikButton
                  containerVariant="w-32 h-10 rounded-md flex justify-center"
                  labelText="Save Beneficiary"
                  handleClick={() => {}}
                  isDisabled={beneficiaryAlias === ""}
                />
              </div>
              <p
                onClick={() => {
                  setSaveBeneficiaryPinModal(false);
                  setToggle(false);
                }}
                className="text-lib-alat-red text-sm cursor-pointer"
              >
                Go back
              </p>
            </div>
          </center>
        </div>
      )}
    </div>
  );
};

export default ResponseStatus;
