import { Button, Card } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as FourUserIcon } from "../../assets/svgs/Illustration.svg";
import { useAirtimeHandleRefresh } from "hooks/apis/AirtimeAndData/useAirtimePresetAmount";
import { useEffect } from "react";
import { TransactionStatusEnum } from "utils/enums";
import {
  resetPaymentsData,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import { PrimaryButton } from "alat-components-library";
import { useAppDispatch } from "hooks/useReduxHook";
import { usePurchaseBillStatus } from "hooks/apis/Payment/BillsPayment/usePurchaseBillStatus";
import PageLoader from "components/Loader/PageLoader";

export const ProcessingBillPaymentScreen = () => {
  const location = useLocation();
  const { validateBillResponse, loading } = usePaymentState();
  const resData = location?.state?.resData?.responseMessage;
  const msgTitle = resData?.title;
  const actionMessage = resData?.actionMessage;

  const { billPurchaseStatus, handleFetchBillPurchaseStatus } =
    usePurchaseBillStatus();
  const correlationId = validateBillResponse?.correlationId;
  // const correlationId = "3fa6df8e-f27b-400c-87c6-4cac5f385233";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleRefresh: any = () => {
    handleFetchBillPurchaseStatus(correlationId);
  };
  useEffect(() => {
    if (
      billPurchaseStatus?.data?.transactions[0]?.status ===
      TransactionStatusEnum.Success
    ) {
      return navigate("/bills/payment/pay-bill/success", {
        state: { billPurchaseStatus, correlationId },
      });
    }
    if (
      billPurchaseStatus?.data?.transactions[0]?.status ===
      TransactionStatusEnum.Failed
    ) {
      return navigate("/bills/payment/pay-bill/failure", {
        state: { billPurchaseStatus },
      });
    }
    if (
      billPurchaseStatus?.data?.transactions[0]?.status ===
      TransactionStatusEnum.Processing
    ) {
      handleRefresh();
    }
  }, [loading, billPurchaseStatus]);

  return (
    <div className="w-full">
      <Card className="bg-white md:w-[48rem] w-full mx-auto dark:bg-white dark:border-transparent border-transparent">
        <div className="flex flex-col items-center ">
          {loading?.includes("fetch-bill-purchase-status") && (
            <PageLoader message="" />
          )}
          <div className="w-full space-y-8">
            <div className="flex justify-center">
              <FourUserIcon fill="inherit" />
            </div>
            <h5 className="text-center text-[#FF9900] font-bold text-[22px] leading-[37.2px]">
              {msgTitle || "Thank You!"}
            </h5>
            <p className="text-center text-[18px] leading-[30.6px]">
              {actionMessage || "Your transaction is processing."}
            </p>
            <div className="bg-[#F5F5F5] p-5 rounded-md text-center mb-2">
              <p className="text-sm leading-[16.94px]">
                If you are not redirected in 20 seconds, use the <b>refresh</b>{" "}
                button below to manually check for your transaction status.
              </p>
            </div>
            <div className="flex md:flex-row flex-col gap-3 w-full items-center justify-center mt-2">
              <PrimaryButton
                labelText={"Refresh"}
                variant="font-medium text-xs text-lib-alat-red tracking-tighter leading-[28px]"
                containerVariant=" md:w-1/2 w-full px-5 h-10 md:h-12 rounded-md flex justify-center border border-lib-alat-red cursor-pointer bg-white hover:bg-[#f2f5fc]"
                handleClick={handleRefresh}
              />
              <PrimaryButton
                id="proceed-to-dashboard_btn"
                labelText={"Proceed to Dashboard"}
                variant={
                  "font-medium text-xs text-white tracking-tighter leading-[28px]"
                }
                containerVariant=" md:w-1/2 w-full px-10 h-10 md:h-12 rounded-md flex justify-center border border-lib-alat-red cursor-pointer bg-lib-alat-red "
                handleClick={() => {
                  dispatch(resetPaymentsData());
                  navigate("/user-dashboard");
                }}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
