import React, { useEffect, useState } from "react";
import ReferenceLetterContainer from "./ReferenceLetterContainer";
import CenterModal from "alat-components-library/Modal/CenterModal";
import { ReactComponent as SuccessIcon } from "../../../assets/svgs/success-icon.svg";
import { ReactComponent as FailureIcon } from "../../../assets/svgs/failure-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useAcceptWemaReferee } from "hooks/apis/Referee/useAcceptWemaReferee";
import PageLoader from "components/Loader/PageLoader";
import { useRejectReferee } from "hooks/apis/Referee/useRejectReferee";
import { setRefereeView } from "react-wrappers/stores/redux/app/slices";
import { useAppDispatch } from "hooks/useReduxHook";

const WemaRefereeForm = () => {
  const dispatch = useAppDispatch();
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const refState = location?.state;
  const business = refState?.wemaRefereeData;
  const isERef = refState?.isERef;
  const isIndividual = isERef
    ? refState?.type.toLowerCase() === "individual"
    : false;

  useEffect(() => {
    if (!location?.state?.wemaRefereeData) {
      navigate("/signin");
    }
    // eslint-disable-next-line
  }, []);

  const { wemaRefereeStatus, wemaRefereeLoading, handleWemaRefereeValidation } =
    useAcceptWemaReferee();

  const {
    refereeRejectionStatus,
    refereeRejectionLoading,
    handleRefereeRejection,
  } = useRejectReferee();
  const acceptRefereeRequest = () => {
    const defaultData = {
      id: "",
      emailAddress: "",
      isRefereeResubmission: false,
      isERef,
    };
    const requestData = Object.assign(defaultData, {
      id: business?.id || business?.refereeId,
      emailAddress: business?.refereeEmail || business?.email,
      [isERef ? "eReferenceId" : "correlationId"]:
        business?.correlationId || business?.eReferenceId,
      isRefereeResubmission: business?.isRefereeResubmission,
    });
    handleWemaRefereeValidation(requestData);
  };

  const rejectRefereeRequest = async () => {
    await handleRefereeRejection({
      correlationId: business?.correlationId || business?.eReferenceId,
      id: business?.id || business?.refereeId,
      isEref: isERef,
    });
  };

  useEffect(() => {
    if (isIndividual) {
      dispatch(setRefereeView(true));
    } else {
      dispatch(setRefereeView(false));
    }
  }, [dispatch, isIndividual]);

  //Check status of request
  useEffect(() => {
    if (wemaRefereeStatus) {
      setAccepted(true);
    }
  }, [wemaRefereeStatus]);

  //Check status for rejection
  useEffect(() => {
    if (refereeRejectionStatus) {
      setRejected(true);
    }
  }, [refereeRejectionStatus]);

  return (
    <ReferenceLetterContainer>
      {(wemaRefereeLoading || refereeRejectionLoading) && (
        <PageLoader
          message={wemaRefereeLoading ? "Submitting Form" : "Rejecting ..."}
        />
      )}
      <div className="px-5 md:px-0 min-w-[50vw] max-w-[500px] mx-auto flex flex-col md:gap-[4vw] gap-10">
        <div className="flex flex-col gap-2">
          <h1 className="md:text-[3vw] text-4xl font-bold tracking-tight">
            Customer Details
          </h1>
          <p className="md:text-[1.2vw] text-sm">See customer details below</p>
        </div>

        <div className="flex flex-col md:gap-[1.8vw] gap-5">
          <div className="flex justify-between border-b items-center">
            <div className="text-left md:text-[1vw] text-xs">
              {!isIndividual && "COMPANY"} NAME
            </div>
            <div
              className="text-right md:text-[1.3vw] text-sm"
              id="company-name_text"
            >
              {business?.companyName || business?.businessName}
            </div>
          </div>
          {!!business?.companyAddress && (
            <div className="flex justify-between border-b items-center">
              <div className="text-left md:text-[1vw] text-xs">
                {!isIndividual && "COMPANY"} ADDRESS
              </div>
              <div
                className="text-right md:text-[1.3vw] text-sm"
                id="company-address_text"
              >
                {business?.companyAddress}
              </div>
            </div>
          )}

          <div className="flex justify-between border-b items-center">
            <div className="text-left md:text-[1vw] text-xs">
              {!isIndividual && "COMPANY"} PHONE NO.
            </div>
            <div
              className="text-right md:text-[1.3vw] text-sm"
              id="company-phone_text"
            >
              {business?.companyPhoneNumber || business?.businessPhoneNumber}
            </div>
          </div>

          <div className="flex justify-between border-b items-center">
            <div className="text-left md:text-[1vw] text-xs">
              {!isIndividual && "COMPANY"} EMAIL ADDRESS
            </div>
            <div
              className="text-right md:text-[1.3vw] text-sm"
              id="company-email_text"
            >
              {business?.companyEmail || business?.email}
            </div>
          </div>
          {!!business?.refereeAccountNumber && (
            <div>
              <p className="text-left md:text-[1vw] text-xs mb-1">
                {!isIndividual ? "COMPANY" : "REFEREE"} ACCOUNT NUMBER
              </p>
              <input
                name="accountNumber"
                className="border text-black w-full md:h-[3.3vw] md:text-[1vw] h-12 text-sm px-3 outline-none rounded-md bg-gray-50 focus:border-black focus:bg-white"
                // placeholder="Enter your Wema bank corporate account number"
                placeholder=""
                maxLength={10}
                value={business?.refereeAccountNumber}
                onChange={(e: any) => {
                  const input = e.target.value;
                  const digitsOnly = input.replace(/[^0-9]/g, "");
                  if (digitsOnly !== input) {
                    e.target.value = digitsOnly;
                  }
                }}
                disabled
              />
            </div>
          )}
        </div>

        <div className="w-full flex gap-2 items-center">
          <div
            className="w-full flex rounded-md bg-lib-alat-red justify-center h-12 cursor-pointer hover:bg-lib-alat-dark-red items-center text-sm font-semibold text-white"
            id="accept-wema-referee_btn"
            onClick={acceptRefereeRequest}
          >
            Accept
          </div>
          <div
            className="w-full rounded-md bg-white flex justify-center h-12 cursor-pointer border border-lib-alat-red items-center text-sm text-lib-alat-red font-semibold"
            id="reject-wema-referee_btn"
            onClick={rejectRefereeRequest}
          >
            Reject
          </div>
        </div>
      </div>
      {accepted && (
        <CenterModal
          id="success_modal"
          idButton1="success-continue_btn"
          idCloseModal="success_close_btn"
          icon={<SuccessIcon />}
          title="Success!"
          info={`You have successfully completed the referee verification for ${business?.companyName}`}
          btnText="Thank you"
          handleClick={() => navigate("/signin")}
          handleClose={() => navigate("/signin")}
        />
      )}
      {rejected && (
        <CenterModal
          id="reject_modal"
          idButton1="reject-continue_btn"
          idCloseModal="reject_close_btn"
          icon={<FailureIcon />}
          title="Rejected!"
          info={`You have successfully rejected the referee verification for ${business?.companyName}`}
          btnText="Thank you"
          handleClick={() => navigate("/signin")}
          handleClose={() => navigate("/signin")}
        />
      )}
    </ReferenceLetterContainer>
  );
};

export default WemaRefereeForm;
