import { FormikButton } from "../../../alat-components-library/Buttons";
import { TextInput } from "../../../alat-components-library/Inputs";
import { useAppSelector } from "hooks/useReduxHook";
import React from "react";
import { Form, Formik } from "formik";
import { cardPaymentSchema } from "../Validation/onboardingValidation";
import { IPayWithCard, ValidateCardNumber } from "../../../types/global";
import { formatNumberToCurrency } from "../../../utils/helperFunctions";
import { useAuthState } from "../../../react-wrappers/stores/redux/auth/slices";

interface ICardPayment {
  userPayWithCardStatus: boolean;
  userPayWithCardLoading: boolean;
  handleCardValidation: (payload: ValidateCardNumber) => void;
  userCardValidationLoading: boolean;
  userCardValidationData: any;
  userPayWithCardData: any;
  payAmount?: number;
  correlationId?: string;
  handlePayWithCard: (payload: IPayWithCard) => void;
}
const CardPayment = ({
  userPayWithCardStatus,
  userPayWithCardLoading,
  userPayWithCardData,
  payAmount,
  correlationId,
  handlePayWithCard,
  handleCardValidation,
  userCardValidationLoading,
  userCardValidationData,
}: ICardPayment) => {
  const onboardingState = useAppSelector((state) => state.onboarding);
  const { user } = useAuthState();
  const isUnRegisteredBusiness = !!correlationId;
  const unRegBusinessId = correlationId;

  const handleValidateCard = (cardNumber: string) => {
    const businessRegistrationRequest = {
      businessRegistrationRequest: {
        reg_number:
          user?.regNumber ||
          onboardingState.businessPayload.infoRequest.businessRequest
            .reg_number,
        businessType:
          user?.businessType ||
          onboardingState.businessPayload.infoRequest.businessRequest
            .businessType,
      },
    };

    const bizInfo = isUnRegisteredBusiness
      ? { correlationId: unRegBusinessId }
      : businessRegistrationRequest;

    const payload = {
      cardNumber,
      ...bizInfo,
    };
    handleCardValidation(payload);
  };
  const amount = payAmount ?? onboardingState?.paymentAmount ?? 0;

  const formattedAmount = formatNumberToCurrency({
    number: amount,
  });
  return (
    <div>
      <p className="text-[#000000] text-xs pb-2">
        Enter debit card details below to make payment
      </p>
      <Formik
        initialValues={{ cardNumber: "", expiry: "", securityCode: "" }}
        onSubmit={async (values, { setSubmitting, validateForm }) => {
          setSubmitting(true);
          validateForm();
          const [cardMonth, cardYear] = values.expiry.split("/");
          if (
            typeof userCardValidationData !== "string" ||
            userCardValidationLoading
          ) {
            return;
          }
          // Payload for unregistered tracking
          const bizInfo = isUnRegisteredBusiness
            ? {}
            : {
                businessRegistrationRequest: {
                  reg_number:
                    user?.regNumber ||
                    onboardingState.businessPayload.infoRequest.businessRequest
                      .reg_number,
                  businessType:
                    user?.businessType ||
                    onboardingState.businessPayload.infoRequest.businessRequest
                      .businessType,
                },
                accountType: parseInt(
                  user?.accountType || onboardingState.accountType
                ),
              };
          const payload = {
            ...bizInfo,
            correlationId:
              unRegBusinessId ||
              user?.correlationId ||
              onboardingState.correlationId,
            cardNumber: values.cardNumber,
            cardMonth,
            cardYear,
            securityCode: values.securityCode,
            amount,
            transactionId: userCardValidationData,
          };
          handlePayWithCard(payload);
        }}
        validateOnChange
        validationSchema={cardPaymentSchema}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          values,
          setFieldValue,
          validateForm,
        }) => {
          return (
            <Form className="">
              <div className="mb-4 rounded-[5px] bg-[#F3F3F3] p-3 md:p-5">
                <TextInput
                  id="tax-number_input"
                  label="Card Number"
                  name="cardNumber"
                  type="text"
                  maxLength={16}
                  isOnlyNumber
                  variant="border text-black w-full h-[56px] text-lg px-5 border-[#808080]"
                  labelStyle="text-sm mb-3 text-[#9D0F3E] font-semibold"
                  placeHolder="5324 8838 8838 9939"
                  handleBlur={(e) => {
                    const value = e.target.value;
                    if (value?.length === 16) {
                      handleValidateCard(value);
                    }
                  }}
                  handleChange={(e: any) => {
                    const value = e.target.value.replace(/\D/g, "");
                    setFieldValue("cardNumber", value);
                  }}
                  value={values?.cardNumber}
                  error={errors?.cardNumber}
                  hasError={errors?.cardNumber}
                  isLoading={userCardValidationLoading}
                />

                <div className="grid gap-3 md:gap-4 grid-cols-[repeat(auto-fit,minmax(130px,1fr))] pt-4">
                  <div className="">
                    <TextInput
                      id="tax-number_input"
                      label="Expiration"
                      name="expiry"
                      type="text"
                      isCardExpiry
                      isAllowSpecialChar
                      isOnlyNumber
                      maxLength={5}
                      variant="border text-black  h-[56px] text-lg px-5 border-[#808080]"
                      labelStyle="text-sm mb-3 text-[#9D0F3E] font-semibold"
                      placeHolder="MM/YY"
                      handleChange={(e: any) => {
                        const value = e.target.value.replace(/\D/g, "");
                        let formattedInput;
                        if (value.length > 2) {
                          formattedInput =
                            value.substring(0, 2) + "/" + value.substring(2);
                        } else {
                          formattedInput = value;
                        }
                        setFieldValue("expiry", formattedInput);
                      }}
                      value={values?.expiry}
                      error={errors?.expiry}
                      hasError={errors?.expiry}
                    />
                  </div>
                  <div className="">
                    <TextInput
                      id="tax-number_input"
                      label="CVV"
                      name="securityCode"
                      type="password"
                      isOnlyNumber
                      variant="border text-black  h-[56px] text-lg px-5 border-[#808080]"
                      labelStyle="text-sm mb-3 text-[#9D0F3E] font-semibold"
                      placeHolder="***"
                      maxLength={3}
                      handleChange={(e: any) => {
                        const value = e.target.value.replace(/\D/g, "");
                        setFieldValue("securityCode", value);
                      }}
                      value={values?.securityCode}
                      error={errors?.securityCode}
                      hasError={errors?.securityCode}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <FormikButton
                  id="submit-card-payment_btn"
                  isLoading={isSubmitting}
                  labelText={`Pay ${formattedAmount}`}
                  containerVariant="w-full text-center h-12 rounded-md flex justify-center mb-3"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CardPayment;
