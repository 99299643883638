import Preloader from "../../Mics/Preloader";
import { FormikButtonType } from "../types";

export const FormikButton = ({
  id = "",
  labelText,
  variant = "font-medium text-sm",
  containerVariant = "w-32 h-10 rounded-md flex justify-center item-center",
  isDisabled = false,
  isLoading = false,
  icon,
}: FormikButtonType) => {
  return (
    <button
      id={id}
      type="submit"
      className={`${variant} shadow-md ${
        isDisabled
          ? "bg-[#A9083680] text-[#E0B8C4]  cursor-not-allowed flex items-center"
          : "bg-lib-alat-red hover:bg-lib-alat-red-hover text-white py-3 cursor-pointer"
      } ${containerVariant}`}
      disabled={isDisabled}
    >
      <div className="flex items-center">
        {icon?.active && (
          <div className={`${icon.variant}`}>{icon.preview}</div>
        )}
        {isLoading ? <Preloader variant="w-6 h-6" /> : labelText}
      </div>
    </button>
  );
};
