import { removeLoadingState } from "helpers/assitFunctions";
import { TransactionState } from "../interfaces";
import { ToastNotify } from "helpers/toastNotify";

export const triggerSaveTransactions = (
  state: TransactionState,
  action: any
) => {
  state.loading = removeLoadingState(state.loading, "GET_TRANSACTION_HISTORY");
  state.loading = removeLoadingState(state.loading, "FILTER_TRANSACTIONS");

  if (action?.payload?.status !== 200) return;

  if (action?.payload?.data?.hasError) {
    return ToastNotify({
      type: "error",

      message:
        action?.payload?.data?.responseMessage?.title ||
        "Unable to retrieve transaction history, please login and try again",
      position: "top-center",
    });
  } else {
    state.transactionsResponse.filteredTransactions =
      action?.payload?.data?.data;

    // console.log("Page Number", action?.payload?.data?.data?.pageNumber);
    // state.pagination.page = action?.payload?.data?.data?.pageNumber;
  }
};
