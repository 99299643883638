import { useAppDispatch } from "hooks/useReduxHook";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import {
  GET_ACCOUNT_CARDS_ACTION,
  POST_SUBMIT_CARD_HOTLIST_ACTION,
} from "react-wrappers/stores/redux/cards/actions";
import { useCardState } from "react-wrappers/stores/redux/cards/slices";

export const useCardHotlist = (setScreen: any) => {
  const location = useLocation();
  const authState = useAuthState();
  const { selectedActiveCard } = useCardState();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAuthState();
  const accounts = authState.user?.accounts;
  const airtimeData = location?.state?.airtimeData;

  const [accountToDebit, setAccountToDebit] = useState<any>({
    accountId: "",
    currency: "",
    balance: 0,
    accountName: "",
    accountNo: "",
    schemeCode: "",
  });

  useEffect(() => {
    if (accountToDebit.accountNo !== "" && accountToDebit.schemeCode !== "") {
      // console.log("enter");
      dispatch(
        GET_ACCOUNT_CARDS_ACTION({
          accountNumber: accountToDebit.accountNo,
          schemeCode: accountToDebit.schemeCode,
        })
      )
        .unwrap()
        .then((res: any) => {
          // console.log(res);
        }); //setCards(res.data.data.cards)
    }
  }, [accountToDebit.accountNo, accountToDebit.schemeCode]);

  const deleveryOptionList = [
    {
      id: 1,
      label: "Branch Pick up",
      name: "Branch Pick up",
    },
    {
      id: 2,
      label: "Office Delivery",
      name: "Office Delivery",
    },
  ];

  const activeAccounts = accounts?.filter(
    (account: any) => account.accountStatus === "Active"
  );

  const handleAccountError = (airtimeAmount: number, accountBalance: any) => {
    return accountBalance.accountNo !== "" &&
      airtimeAmount > accountBalance.balance
      ? "insufficient balance"
      : "";
  };

  const accSelectErrorMessage = useMemo(
    () => handleAccountError(airtimeData?.amount, accountToDebit),
    [airtimeData?.amount, accountToDebit?.accountNo]
  );

  const handleSubmit = (values: any) => {
    const data = {
      accountNumber: selectedActiveCard?.linkedAccounts?.[0],
      maskedPan: selectedActiveCard?.pan,
      reasonCode: values.reasonCode,
      comment: values?.comment,
      cardRouteId: selectedActiveCard?.cardRouteId,
      isSoftToken: values?.softToken === "" ? false : true,
      transactionPin: values.pin !== "" ? values.pin : values.hardTokenPin,
      token: values?.softToken !== "" ? values.softToken : values.hardTokenOtp,
    };

    //Call API to Validate the refrence or transaction ID
    dispatch(POST_SUBMIT_CARD_HOTLIST_ACTION(data))
      .unwrap()
      .then((res: any) => {
        if (res.status === 200) {
          setScreen(2);
        }
      });
    //Validate if account
    // if(accountToDebit.accountId === "") {
    // return ToastNotify({
    //     type: "error",
    //     message: "Please select an account",
    //     position: "top-center",
    // });
    // }
    // //Validate if account is no money
    // if(accountToDebit.balance === 0) {
    // return ToastNotify({
    //     type: "error",
    //     message: "Account is empty, please select another account",
    //     position: "top-center",
    // });
    // }
    // //Validate if delivery option is selected
    // if(payload.deliveryOption.label === "") {
    // return ToastNotify({
    //     type: "error",
    //     message: "Please select a delivery option",
    //     position: "top-center",
    // });
    // }

    // console.log(amount)
    // console.log(payload.deliveryOption.id-1);

    // dispatch(setCardRequestPayload({
    //   "sourceAccountName": accountToDebit.accountName,
    //   "sourceAccountCurrencyCode": accountToDebit.currency,
    //   "sourceAccountNumber": accountToDebit.accountNo,
    //   "sourceAccountNumberSchemeCode":accountToDebit.schemeCode,
    //   "transactionType": TransactionTypeEnum.SingleSignatoryCardRequest,
    //   "deliveryOption": payload.deliveryOption.id,
    //   "totalDebitAmount": amount[payload.deliveryOption.id-1].cardFees + amount[payload.deliveryOption.id-1].deliveryFee + amount[payload.deliveryOption.id-1].vat
    // }));

    // dispatch(setActiveCardRequestScreen(1));
  };

  return {
    accountToDebit,
    accSelectErrorMessage,
    activeAccounts,
    deleveryOptionList,
    handleSubmit,
    setAccountToDebit,
  };
};
