import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

const CustomLink = ({
  path,
  text,
  icon,
  handleClick,
}: {
  path: string;
  text: string;
  icon: ReactNode;
  handleClick?: () => void;
}) => {
  const handleLinkClick = () => {
    handleClick && handleClick();
  };
  return (
    <Link
      className="px-1.5 md:px-[9px] hover:bg-lib-alat-gray-card-one py-2.5 rounded-lg border border-lib-alat-gray-input-border bg-white inline-block"
      to={path}
      onClick={handleLinkClick}
    >
      <div className="text-center ">
        {icon}
        <p className="max-w-[112px] mx-auto pt-1 md:pt-[15px] text-lib-alat-black text-xs font-medium tracking-[-0.24px]">
          {text}
        </p>
      </div>
    </Link>
  );
};
export default CustomLink;
