import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { useAppSelector } from "../../../../hooks/useReduxHook";
import { removeLoadingState } from "../../../../helpers/assitFunctions";
import { CollectionsState } from "../interfaces";

const dataObject = {
  loading: [],
  collections: [],
  collectionsCategoryList: [],
  activeSelectedCollection: [],
  activeSelectedCollectionList: null,
  allCollectionList: null,
  allCollectionMerchants: [],
  activeSelectedCollectionMerchant: null,
  allCollectionMerchantItem: [],
  activeSelectedCollectionMerchantItem: null,
  dynamicFormItems: [],
  simpleFormItem: [],
  postValidationItem: null,
  collectionPaymentItem: null,
  validationReference: [],
  summaryFields: [{}],
} as unknown as CollectionsState;

export const initialState: any = { ...dataObject };

export const collectionsSlice = createSlice({
  name: "collections",
  initialState,

  reducers: {
    resetAllCollections: (): any => initialState,

    setLoadingState: (state: any, action: PayloadAction<any>): void => {
      state.loading = action.payload;
    },

    setAllCollections: (state: any, action: PayloadAction<any>): void => {
      state.collectionsCategoryList = action.payload;
    },

    setActiveSelectedCollection: (state: any, action: PayloadAction<any>) => {
      state.activeSelectedCollection = action.payload;
    },

    setSelectedCollectionList: (state: any, action: PayloadAction<any>) => {
      state.selectedCollectionList = action.payload;
    },
    setAllCollectionList: (state: any, action: PayloadAction<any>) => {
      state.allCollectionList = action.payload;
    },

    setAllCollectionMerchants: (state: any, action: PayloadAction<any>) => {
      state.allCollectionMerchants = action.payload;
    },
    setActiveSelectedCollectionMerchant: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.activeSelectedCollectionMerchant = action.payload;
    },
    setActiveSelectedCollectionMerchantItem: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.activeSelectedCollectionMerchantItem = action.payload;
    },
    setAllCollectionMerchantItem: (state: any, action: PayloadAction<any>) => {
      state.allCollectionMerchantItem = action.payload;
    },
    setDynamicFormItems: (state: any, action: PayloadAction<any>) => {
      state.dynamicFormItems = action.payload;
    },
    setSimpleFormItems: (state: any, action: PayloadAction<any>) => {
      state.simpleFormItem = action.payload;
    },
    setPostValidationItem: (state: any, action: PayloadAction<any>) => {
      state.postValidationItem = action.payload;
    },
    setCollectionPaymentItem: (state: any, action: PayloadAction<any>) => {
      state.collectionPaymentItem = action.payload;
    },
    setValidationReference: (state: any, action: PayloadAction<any>) => {
      state.validationReference = action.payload;
    },
    setSummaryFields: (state: any, action: PayloadAction<any>) => {
      state.summaryFields = action.payload;
    },
  },
});

// Selectors
const selectCollections = (state: RootState) => state.collections;

// Reducers and actions
export const {
  setLoadingState,
  setAllCollections,
  resetAllCollections,
  setActiveSelectedCollection,
  setSelectedCollectionList,
  setAllCollectionList,
  setAllCollectionMerchants,
  setActiveSelectedCollectionMerchant,
  setAllCollectionMerchantItem,
  setActiveSelectedCollectionMerchantItem,
  setDynamicFormItems,
  setSimpleFormItems,
  setPostValidationItem,
  setCollectionPaymentItem,
  setValidationReference,
  setSummaryFields,
} = collectionsSlice.actions;

//App Redux State
export const useCollectionsState = () => useAppSelector(selectCollections);

export default collectionsSlice.reducer;
